import PMPAPIService from "./PMPAPIService";

interface approvalPayLoad {
    approvalType: string;
    gperId: number;
    comments?: string;
};

interface renewalpayload {
    approvalType: string;
    lperId: number;
    comments?: string;
    adminEmails:any;
}

class ApprovalService extends PMPAPIService {

    async exceptionApproval(approvalpayload: approvalPayLoad) {
        const response = await this.PutAsync<approvalPayLoad>(
            '/localproceduretemplate/exceptionapproval', approvalpayload
        );

        return response;
    }
    async renewalApproval(approvalpayload: renewalpayload) {
        const response = await this.PutAsync<renewalpayload>(
            '/localproceduretemplate/renewalapproval', approvalpayload
        );

        return response;
    }



}
const approvalService = new ApprovalService();
export default approvalService;