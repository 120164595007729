import { RenewalForm } from "../Models/RenewalForm";
import PMPAPIService from "./PMPAPIService";

export interface RenewalData {
  renewalFormData: RenewalForm[];
  count: number;
}

interface RenewalFormPayload {
  approvers: any;
  renewalForm: RenewalForm;
}

class ExceptionRenewal extends PMPAPIService {

  async create(gper: RenewalFormPayload) {
    const response = await this.PostAsync<RenewalFormPayload>(
      `localproceduretemplate/exception_renewal`,
      gper
    );

    return response;
  }

  async getRenewalDataByExceptionId(exceptionId: number): Promise<RenewalForm[] | []> {
    const response = await this.GetAsync<RenewalForm[]>(
      `localproceduretemplate/getrenewalbyexceptionid/${exceptionId}`
    );

    let responseLPER: RenewalForm[] | [] = [];

    if (response.isSuccess) {
      responseLPER = response.data;
    }

    return responseLPER;
  }
  
  async saveAsDraft(gper: RenewalForm) {
    const response = await this.PostAsync<RenewalForm>(
      `localproceduretemplate/draft_renewal`,
      gper
    );
    return response;
  }
  async update(gper: RenewalForm): Promise<RenewalForm | null> {
    const response = await this.PostAsync<RenewalForm>(
      `localproceduretemplate/update_renewal`,
      gper
    );

    let responseGPER: RenewalForm | null = null;

    if (response.isSuccess) {
      responseGPER = response.data;
    }

    return responseGPER;
  }

  async findExceptionRenewalDataById(gperId:number): Promise<RenewalForm[] | [] > {
    const response = await this.GetAsync<RenewalForm[]>(
      `localproceduretemplate/findbygperid/${gperId}`, 
    );

    let responseGER: RenewalForm[] | [] = [];

    if (response.isSuccess) {
      responseGER = response.data;
    }

    return responseGER;
  };

  async getAllWithPagination(querySearch: any): Promise<RenewalData | null> {

    const response = await this.PostAsync<RenewalData>(
      `localproceduretemplate/getAllrenewal`, querySearch
    );

    let responseLPERs: RenewalData | null = null

    if (response.isSuccess) {
      responseLPERs = response.data;
    }
    return responseLPERs;
  }

  async getRenewalDataById(lperId: number): Promise<RenewalForm | null> {
    const response = await this.GetAsync<RenewalForm>(
      `localproceduretemplate/getrenewalbyid/${lperId}`
    );

    let responseLPER: RenewalForm | null = null;

    if (response.isSuccess) {
      responseLPER = response.data;
    }

    return responseLPER;
  };

  async setRenewalNotificationOff(gperId: number | undefined): Promise<any | null> {
    const response = await this.PostAsync<any>(
      `localproceduretemplate/setrenewalnotification`,{gperId}
    );

    let responseData: any | null = null

    if (response.isSuccess) {
      responseData = response.data;
    }
    return responseData;
  };

  getExceptionApproverKeys() {
    const exceptionApprovers = [
      'm4LegalAttorneySpEcoBlEmail', 'gmRegionalAffiliateVpEmail',
      'ibuHubGmEmail', 'associateVpEcoIbuEmail', 'associateVpLegalIbuEmail',
      'm4EcoBlEmail', 'm4LegalAttrEcoBlFrapEmail', 'gmFunctionVpr8Email', 'chiefAccOfficerEmail', 'chiefProcOfficerEmail',
      'localBusinessOwnerEmail', 'localChiefFinanOwnerEmail', 'seniorDirEnterTravExpenEmail', 'seniorVpChiefFinanOffcEmail',
      'localCfoEmail', 'm5F12BusinessLeaderEmail', 'createdByEmail'];
    return exceptionApprovers;
  }
}
const exceptionRenewService = new ExceptionRenewal();
export default exceptionRenewService;