import { Box } from "@mui/material";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import TabPanel from "../../Components/Tab/TabPanel";
import PageHeader from "../../Components/Text/PageHeader";
import TabBar from "./TabBar";
import { TabModel } from "./TabModel";
import ApprovedTab from  './Tabs/ApprovedTab'
import axios from "axios";
// import gptService from "../../Services/GPTService";
// import { EcoViewCountTab } from "../../Models/GlobalProcedureTemplateModel";
import ApprovalPendingTab from "./Tabs/ApprovalPendingTab";
import RejectedTab from "./Tabs/RejectedTab";
import gptService from "../../Services/GPTService";
import lptService from "../../Services/LPTService";
import { AuthProviderService } from "../../Services";
import Utils from "../../Common/Utils";


interface Props extends RouteComponentProps<any, any, any> { }
interface State {
    currentTab: TabModel;
    approverEmail: string;
    tabs:TabModel[];
}
class ProcedureApproval extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: "Pending Approval",
            type: "Approval Pending",
            index: 0,
            count:0,
        },
        {
            name: "Approved",
            type: "Approved",
            index: 1,
            count:0,
        },
        {
            name: "Rejected",
            type: "Rejected",
            index: 2,
            count:0
        }
    ];
    constructor(props: Props) {
        super(props);
        this.state = {
            currentTab: this.Tabs[0],
            approverEmail: "",
            tabs :this.Tabs
        };
    }

    getTabCount = async () => {
        try {
            let ApprovalPendingCount = 0;
            let ApprovedCount = 0;
            let RejectedCount = 0;
            const querySearchPending = {
                page: 0,
                limit: 0,
                searchtext: '',
                order: '',
                orderBy: '',
                approveremail: this.state.approverEmail,
                status: "Pending Approval",
            }
            const querySearchApproved = {
                page: 0,
                limit: 0,
                searchtext: '',
                order: '',
                orderBy: '',
                approveremail:this.state.approverEmail,
                status: "Approved",
            }
            const querySearchRejected = {
                page: 0,
                limit: 0,
                searchtext: '',
                order: '',
                orderBy: '',
                approveremail:this.state.approverEmail,
                status: "Rejected",
            }

            const dataApprovalPending = await gptService.getLocalProcedure(querySearchPending);
            if (dataApprovalPending?.count) {
                ApprovalPendingCount = dataApprovalPending.count;
            }

            const dataApproved = await gptService.getLocalProcedure(querySearchApproved);
            if (dataApproved?.count) {
                ApprovedCount = dataApproved.count;
            }

            const dataRejected = await gptService.getLocalProcedure(querySearchRejected);
            if (dataRejected?.count) {
                RejectedCount = dataRejected.count;
            }

            const updatedTabs = [...this.state.tabs];
            updatedTabs[0].count = ApprovalPendingCount;
            updatedTabs[1].count = ApprovedCount;
            updatedTabs[2].count = RejectedCount;
            this.setState({ tabs: updatedTabs });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message:", error.message);
                return error.message;
            } else {
                console.log("unexpected error during fecthing:", error);
                return "An unexpected error occured";
            }
        }
    };

    async componentDidMount(): Promise<void> {
        // let currentTab = localStorage.getItem("currentTab");
        let currentTab = sessionStorage.getItem("currentTab");
        if(currentTab)
        {
            this.setState({ currentTab: JSON.parse(currentTab) });
        }
        const { accessToken } = await AuthProviderService.getAccessToken();
        const loggedInUserToken = Utils.decodeJWT<any>(accessToken);
        this.setState({approverEmail: loggedInUserToken.preferred_username},()=>{
            this.getTabCount()
        })
        
    }

    handleTabChange = (selectedTab: TabModel) => {
        this.setState({ currentTab: selectedTab });
        sessionStorage.setItem("currentTab", JSON.stringify(selectedTab));
    }

    render(): React.ReactNode {
        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <PageHeader label="Procedure Approval" />
                </Box>
                <TabBar
                    currentTab={this.state.currentTab}
                    tabs={this.Tabs}
                    onTabChange={this.handleTabChange}
                />
                <TabPanel value={this.state.currentTab.index} index={0}>
                <ApprovalPendingTab />
                </TabPanel>

                <TabPanel value={this.state.currentTab.index} index={1}>
                <ApprovedTab />
                </TabPanel>

                <TabPanel value={this.state.currentTab.index} index={2}>
                    <RejectedTab />
                </TabPanel>
                
            </>
        );
    }
   
}

export default ProcedureApproval;
