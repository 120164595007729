import { Component, ReactNode } from "react";
import Table from "./Table";
import { isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconType } from "../Button/IconButton";
import {
  Card,
} from "@mui/material";

type SortType = {
  key: string;
  direction: string;
}

interface Props {
  isAdminUser?:boolean;
  sortConfig: SortType | null;
  badgeCount?: (data: number, name?: string) => void;
  onClickCheckBox?: (selectedRows: any) => void;
  tableData: any;
  tabLabel?: string;
  actionArray?: IconType[];
  IconType?: IconDefinition;
  totalRecordsCount?: number;
  onActionClick?: (action: IconType, recordId: any) => void;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  requestSort?: (key: string) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  rowsPerPage?: number;
  rowTotalCount?: number;
  page?: number;
  tab:number;
  onSelectChange?:(name: string[], id: number) => void;
}
interface State {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
    orderBy:string;
  }[];
  totalCount: number;
  showstep: boolean;
  caretHover: boolean;
}


class DataLoaderEcoView extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      headCellData: [],
      totalCount: 0,
      showstep: false,
      caretHover: false,
    };
  }

  componentDidMount = () => {
    console.log("tabs=",this.props.tab)
    this.changeHeadCellData()
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (this.props.hasOwnProperty('tableData') && prevProps.hasOwnProperty('tableData') &&
      Array.isArray(prevProps.tableData) &&
      Array.isArray(this.props.tableData) &&
      prevProps.tableData.length > 0 &&
      this.props.tableData.length > 0
    ) {
      if (!isEqual(prevProps?.tableData[0], this.props?.tableData[0])) {
        this.changeHeadCellData();
      }
    }
    if (
      prevState.totalCount !== this.state.totalCount &&
      this.props.badgeCount
    ) {
      this.props.badgeCount(this.state.totalCount, this.props.tabLabel);
    }
    if (prevProps.IconType !== this.props.IconType) {
      this.changeHeadCellData();
    }
  };

  onCaretHover = () => {
    this.setState({ caretHover: true });
  };


  geographicalScopeTab = () => {
    let headerData = [
      {
        key: "templateName",
        label: "Local Procedure Name",
        linkTo: "templateName",
        orderBy: "DESC",
      },
      {
        key: "procedureType",
        label: "Procedure Type",
        linkTo: null,
        orderBy: "DESC"
      }, 
      {
        key: "geoGraphyName",
        label: "Geographical Scope",
        linkTo: null,
        orderBy: "DESC",
      },
      {
        key: "owner",
        label: "Owner",
        linkTo: null,
        orderBy: "DESC",
      },{
        key: "approver",
        label: "Approver",
        linkTo: null,
        orderBy: "DESC",
      },  
      {
        key: "status",
        label: "Status",
        linkTo: null,
        orderBy: "DESC"
      },{
        key: "createdDate",
        label: "Created On",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "Action",
        label: "Action",
        linkTo: null,
        orderBy: "DESC"
      }
    ]
    if (this.props.sortConfig) {
      const itemPresent = headerData.findIndex((k) => k.key == this.props.sortConfig?.key)
      if (itemPresent !== -1) {
        const updatedTHeader = [...headerData];
        updatedTHeader[itemPresent].orderBy = this.props.sortConfig?.direction;
        this.setState({ headCellData: headerData })
      }
    } else {
      this.setState({ headCellData: headerData })
    }
  }

  AllDataTab = () => {
    let headerData = [
      {
        key: "templateName",
        label: "Local Procedure Name",
        linkTo: 'templateName',
        orderBy: "DESC"
      },
      {
        key: "fileName",
        label: "Translated Procedure",
        linkTo: "fileName",
        orderBy: "DESC",
      },
      {
        key: "geoGraphyName",
        label: "Geographical Scope",
        linkTo: null,
        orderBy: "DESC"
      }, 
      {
        key: "owner",
        label: "Owner",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "approver",
        label: "Approver",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "exception",
        label: "Exception",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "modifyBy",
        label: "Created By",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "modifiedDate",
        label: "Created On",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "status",
        label: "Status",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "Action",
        label: "Action",
        linkTo: null,
        orderBy: "DESC"
      }
    ]
    if (this.props.sortConfig) {
      const itemPresent = headerData.findIndex((k) => k.key == this.props.sortConfig?.key)
      if (itemPresent != -1) {
        const updatedTHeader = [...headerData];
        updatedTHeader[itemPresent].orderBy = this.props.sortConfig?.direction;
        this.setState({ headCellData: headerData })
      }
    } else {
      this.setState({ headCellData: headerData })
    }
  }

  ApprovalPendingTab = () => {
    let headerData = [
      {
        key: "templateName",
        label: "Local Procedure Name",
        linkTo: "templateName",
        orderBy: "DESC",
      },
      {
        key: "fileName",
        label: "Translated Procedure",
        linkTo: "fileName",
        orderBy: "DESC",
      },
      {
        key: "geoGraphyName",
        label: "Geographical Scope",
        linkTo: null,
        orderBy: "DESC",
      }, 
      {
        key: "modifyBy",
        label: "ECO Name",
        linkTo: null,
        orderBy: "DESC"
      },
      // {
      //   key: "owner",
      //   label: "Owner",
      //   linkTo: null,
      //   orderBy: "DESC"
      // }, 
      {
        key: "approverName",
        label: "Approver Name",
        linkTo: null,
        orderBy: "DESC"
      },{
        key: "modifiedDate",
        label: "Created On",
        linkTo: null,
        orderBy: "DESC"
      },{
        key: "accessedDate",
        label: "Accessed On",
        linkTo: null,
        orderBy: "DESC"
      },{
        key: "status",
        label: "Status",
        linkTo: null,
        orderBy: "DESC"
      },
    ]
    if(this.props.isAdminUser)
    {
      headerData.push({
        key: "Action",
        label: "Action",
        linkTo: null,
        orderBy: "DESC"
      })
    }
    if (this.props.sortConfig) {
      const itemPresent = headerData.findIndex((k) => k.key == this.props.sortConfig?.key)
      if (itemPresent !== -1) {
        const updatedTHeader = [...headerData];
        updatedTHeader[itemPresent].orderBy = this.props.sortConfig?.direction;
        this.setState({ headCellData: headerData })
      }
    } else {
      this.setState({ headCellData: headerData })
    }
  }

  ApprovedTab = () => {
    let headerData = [
      {
        key: "templateName",
        label: "Local Procedure Name",
        linkTo: "templateName",
        orderBy: "DESC",
      },
      {
        key: "fileName",
        label: "Translated Procedure",
        linkTo: "fileName",
        orderBy: "DESC",
      },
      // {
      //   key: "procedureType",
      //   label: "Procedure Type",
      //   linkTo: null,
      //   orderBy: "DESC"
      // }, 
      {
        key: "geoGraphyName",
        label: "Geographical Scope",
        linkTo: null,
        orderBy: "DESC",
      }, {
        key: "modifyBy",
        label: "ECO Name",
        linkTo: null,
        orderBy: "DESC"
      }, {
        key: "approverName",
        label: "Approver Name",
        linkTo: null,
        orderBy: "DESC"
      },{
        key: "modifiedDate",
        label: "Created On",
        linkTo: null,
        orderBy: "DESC"
      },{
        key: "approvalDate",
        label: "Approved On",
        linkTo: null,
        orderBy: "DESC"
      },{
        key: "status",
        label: "Status",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "Action",
        label: "Action",
        linkTo: null,
        orderBy: "DESC"
      },
      
    ]
    if (this.props.sortConfig) {
      const itemPresent = headerData.findIndex((k) => k.key == this.props.sortConfig?.key)
      if (itemPresent !== -1) {
        const updatedTHeader = [...headerData];
        updatedTHeader[itemPresent].orderBy = this.props.sortConfig?.direction;
        this.setState({ headCellData: headerData })
      }
    } else {
      this.setState({ headCellData: headerData })
    }
  }

  RejectedTab = () => {
    let headerData = [
      {
        key: "templateName",
        label: "Local Procedure Name",
        linkTo: 'templateName',
        orderBy: "DESC"
      },
      {
        key: "fileName",
        label: "Translated Procedure",
        linkTo: "fileName",
        orderBy: "DESC",
      },
      // {
      //   key: "owner",
      //   label: "Name",
      //   linkTo: null,
      //   orderBy: "DESC"
      // },
      {
        key: "geoGraphyName",
        label: "Geographical Scope",
        linkTo: null,
        orderBy: "DESC",
      }, 
      {
        key: "modifyBy",
        label: "ECO Name",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "approverName",
        label: "Approver Name",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "rejectionDate",
        label: "Rejected Date",
        linkTo: null,
        orderBy: "DESC"
      },
      // {
      //   key: "approver",
      //   label: "Approver",
      //   linkTo: null,
      //   orderBy: "DESC"
      // },
      {
        key: "modifiedDate",
        label: "Created On",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "status",
        label: "Status",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "Action",
        label: "Actions",
        linkTo: null,
        orderBy: "DESC",
      },
    ]
    if (this.props.sortConfig) {
      const itemPresent = headerData.findIndex((k) => k.key == this.props.sortConfig?.key)
      if (itemPresent != -1) {
        const updatedTHeader = [...headerData];
        updatedTHeader[itemPresent].orderBy = this.props.sortConfig?.direction;
        this.setState({ headCellData: headerData })
      }
    } else {
      this.setState({ headCellData: headerData })
    }
  }
  
  

  changeHeadCellData = () => {
    switch (this.props.tab) {
      case 1:
        this.geographicalScopeTab();
        break;
      case 2:
         this.AllDataTab();
         break;
      case 3:
        this.ApprovalPendingTab();
        break;
      case 4:
        this.ApprovedTab();
        break;
      case 5:
          this.RejectedTab();
          break;
      default:
        this.geographicalScopeTab();
        break;
    }
  };

  onChangePage = (page: number) => {
    this.props.onChangePage(page);
  };

  handleCaretClick = () => {
    this.setState({ showstep: true });
  };

  onChangeRow = (row: number) => {
    this.props.onChangeRow(row);
  };
  handleCaretCloseClick = () => {
    this.setState({ showstep: false });
  };

  render() {
    const { headCellData, showstep } = this.state;
    return (
      <>
        <Table
          onSelectChange={this.props.onSelectChange}
          requestSort={this.props.requestSort}
          onActionClick={this.props.onActionClick}
          actionArray={this.props.actionArray}
          data={this.props.tableData}
          onClickCheckBox={this.props.onClickCheckBox}
          totalRecordsCount={this.props.totalRecordsCount || 0}
          headCellData={headCellData}
          onChangePage={this.onChangePage}
          onChangeRow={this.onChangeRow}
          sortConfig={null}
          onClickLink={this.props.onClickLink}
          rowsPerPage={this.props.rowsPerPage}
          rowTotalCount={this.props.rowTotalCount}
          page={this.props.page}
        />
        {showstep ? (
          <Card className="Card">
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              onClick={this.handleCaretCloseClick}
              style={{ marginLeft: 800, marginTop: 12 }}
            />
          </Card>
        ) : null}
      </>
    );
  }
}

export default DataLoaderEcoView;
