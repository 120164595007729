import React, { DragEvent } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { FileType } from "../../Common/Enums";
import IconButton from "../Button/IconButton";
import { FileUploadForTranslated } from "../../Models/FileUploadModelForTranslated";


interface Props {
  disabled: boolean;
  isFR: boolean;
  isDocumentUpload: boolean;
  isDisabled: boolean;
  onChange: (file: FileUploadForTranslated) => void;
}
interface State {
  isDragOver: boolean;
}
class FileUpload extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,
    isFR: false,
    isDisabled: true,
    isDocumentUpload: false,
  };
  fileUploadInput: HTMLInputElement | null = null;

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      isDragOver: false,
    };
  }

  render() {
    const { isDragOver } = this.state;
    const { isFR, isDocumentUpload, disabled, isDisabled } = this.props;

    let border = 0;
    let borderColor = "";
    let color = "#D52B1E";
    let textColor = "#6E6E6E";
    let textSize = 16;
    let hintColor = "#2B2B2B";
    let fileUploadId = "cvUpload";
    let fileExplorerStyle: React.CSSProperties = { color, cursor: "pointer" };

    if (isDragOver) {
      border = 1;
      borderColor = isFR ? "#004693" : "#EE3A29";
    }

    if (isFR) {
      color = "#004693";
      textColor = "#EE3A29";
      textSize = 20;
      hintColor = "#1D1D1D";
      fileExplorerStyle = {
        color: textColor,
        cursor: "pointer",
        fontWeight: 500,
        fontSize: textSize,
      };
    }

    if (disabled) {
      return <Box border={border}></Box>;
    }

    if (isDocumentUpload) {
      fileUploadId = "otherUpload";
    }

    if (isDisabled) {
      fileExplorerStyle = { color: "#616161", cursor: "drop" };
    }

    return (
      <Box 
        border={border}
        borderColor={borderColor}
        p={2}
        data-html2canvas-ignore
        onDragOver={isDisabled ? ()=>{} : this.handleDragOver}
        onDrop={isDisabled ? ()=>{} : this.handleDrop}
        onDragLeave={isDisabled ? ()=>{} : this.handleDragLeave}
      >
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <IconButton
              isFR={isFR}
              isDisabled={isDisabled}
              IconType="FileUpload"
              onClick={this.handleCloudClick}
            />
          </Grid>
          <Grid item>
            {!isDragOver ? (
              <Typography
                style={{
                  color: textColor,
                  fontWeight: 400,
                  fontSize: textSize,
                }}
              >
                {isFR ? (
                  <label
                    style={{
                      color: textColor,
                      fontWeight: 500,
                      fontSize: textSize,
                    }}
                  >
                    Drag and drop
                  </label>
                ) : (
                  "Drag and drop"
                )}{" "}
                your file here or click the File icon above to select file.
                <input
                  ref={(input) => (this.fileUploadInput = input)}
                  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  style={{ display: "none" }}
                  id={fileUploadId}
                  type="file"
                  disabled={isDisabled}
                  onChange={this.handleChange}
                />
                {/* <label htmlFor={fileUploadId} style={fileExplorerStyle}>
                  &nbsp;Browse
                </label> */}
              </Typography>
            ) : (
              <Typography
                style={{
                  color: textColor,
                  fontWeight: 400,
                  fontSize: textSize,
                }}
              >
                Drop your file here
              </Typography>
            )}
          </Grid>
          {!isDocumentUpload ? (
            <Grid item>
              <Typography
                style={{ color: hintColor, fontWeight: 400, fontSize: 15 }}
              >
               docx, doc files are supported, Files cannot exceed 120MB.
              </Typography>
            </Grid>
          ) : null}
          
        </Grid>
      </Box>
    );
  }


  handleAndCreateFileUpload = (file: File) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      const { name, size, type } = file;

      const fileModel = {} as  FileUploadForTranslated;
      fileModel.name = name;
      fileModel.sizeInKB = Math.round(size / 1024);
      fileModel.sizeInMB = Math.round(size / 1024 / 1024);
      fileModel.type = type as FileType;
      fileModel.ext = type.replace(/(.*)\//g, "");
      fileModel.file = file;

      this.props.onChange(fileModel);

      
    };
  };

  


  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    if (target.files && target.files.length > 0 && this.fileUploadInput) {
      this.handleAndCreateFileUpload(target.files[0]);
    }
  };

  handleCloudClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (this.fileUploadInput) {
      this.fileUploadInput.value = "";
      this.fileUploadInput.click();
    }
  };

  handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isDragOver: true });
  };

  handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isDragOver: false }, () => {
      const { files } = event.dataTransfer;

      if (files && files.length > 0) {
        this.handleAndCreateFileUpload(files[0]);
        event.dataTransfer.clearData();
      }
    });
  };

  handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isDragOver: false });
  };
}

export default FileUpload;
