import { Component, ReactNode } from "react";

import { FIELDS_TO_LINK, HEADER_MAPPER } from "../../Common/TableConstants";
import { isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconType } from "../Button/IconButton";
import {
  Card,
} from "@mui/material";
import Table from "./Table";

type SortType = {
  key: string;
  direction: string;
}
interface Props {
  sortConfig: SortType | null;
  badgeCount?: (data: number, name?: string) => void;
  onClickCheckBox?: (selectedRows: any) => void;
  tableData: any;
  tabLabel?: string;
  actionArray?: IconType[];
  IconType?: IconDefinition;
  totalRecordsCount?: number;
  onActionClick?: (action: IconType, recordId: any) => void;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  requestSort?: (key: string) => void;
  rowsPerPage?: number;
  rowTotalCount?: number;
  page?: number;
  tab:number;
}
interface State {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
    orderBy: string;
  }[];
  totalCount: number;
  showstep: boolean;
  caretHover: boolean;
}


class DataLoader extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      headCellData: [],
      totalCount: 0,
      showstep: false,
      caretHover: false,
    };
  }

  componentDidMount = () => {
    this.changeHeadCellData()
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.tableData[0], this.props.tableData[0])) {
      this.changeHeadCellData();
    }
    if (
      prevState.totalCount !== this.state.totalCount &&
      this.props.badgeCount
    ) {
      this.props.badgeCount(this.state.totalCount, this.props.tabLabel);
    }
    if (prevProps.IconType !== this.props.IconType) {
      this.changeHeadCellData();
    }
  };

  onCaretHover = () => {
    this.setState({ caretHover: true });
  };

  changeHeadCellData = () => {
    if (this.props.tableData.length > 0) {
      console.log("tab, ", this.props.tableData[0]);

      let headerData = [
        {
          "key": "gpId",
          "label": "gpId",
          "linkTo": null,
          "orderBy": "DESC",
        },
        {
          "key": "templateName",
          "label": "Template Name",
          "linkTo": "templateName",
          "orderBy": "DESC",
        },
        // {
        //   "key": "subregion",
        //   "label": "Sub-Region/Hub",
        //   "linkTo": null,
        //   "orderBy": "DESC",
        // },
        // {
        //   "key": "country",
        //   "label": "Country",
        //   "linkTo": null,
        //   "orderBy": "DESC",
        // },
        // {
        //   "key": "affiliate",
        //   "label": "Affiliate/Region",
        //   "linkTo": null,
        //   "orderBy": "DESC",
        // },
        {
          "key": "procedureType",
          "label": "Procedure Type",
          "linkTo": null,
          "orderBy": "DESC",
        },
        {
          "key": "owner",
          "label": "Owner",
          "linkTo": null,
          "orderBy": "DESC",
        },
        {
          "key": "approver",
          "label": "Approver",
          "linkTo": null,
          "orderBy": "DESC",
        },
        {
          "key": "status",
          "label": "Status",
          "linkTo": null,
          "orderBy": "DESC",
        },
        {
          "key": "createdDate",
          "label": "Created On",
          "linkTo": null,
          "orderBy": "DESC",
        },
       
        {
          "key": "Action",
          "label": "Action",
          "linkTo": null,
          "orderBy": "DESC",
        }
      ]

      if (this.props.sortConfig) {
        const itemPresent = headerData.findIndex((k) => k.key == this.props.sortConfig?.key)
        if (itemPresent != -1) {
          const updatedTHeader = [...headerData];
          updatedTHeader[itemPresent].orderBy = this.props.sortConfig?.direction;
          this.setState({ headCellData: headerData })
        }
      } else {
        this.setState({ headCellData: headerData })
      }

    }
  };

  onChangePage = (page: number) => {
    this.props.onChangePage(page);
  };

  handleCaretClick = () => {
    this.setState({ showstep: true });
  };

  onChangeRow = (row: number) => {
    this.props.onChangeRow(row);
  };
  handleCaretCloseClick = () => {
    this.setState({ showstep: false });
  };

  render() {
    const { headCellData, showstep } = this.state;
    return (
      <>
        <Table
          onActionClick={this.props.onActionClick}
          actionArray={this.props.actionArray}
          data={this.props.tableData}
          onClickCheckBox={this.props.onClickCheckBox}
          totalRecordsCount={this.props.totalRecordsCount || 0}
          headCellData={headCellData}
          onChangePage={this.onChangePage}
          onChangeRow={this.onChangeRow}
          sortConfig={null}
          onClickLink={this.props.onClickLink}
          requestSort={this.props.requestSort}
          rowsPerPage={this.props.rowsPerPage}
          rowTotalCount={this.props.rowTotalCount}
          page={this.props.page}
        />
        {showstep ? (
          <Card className="Card">
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              onClick={this.handleCaretCloseClick}
              style={{ marginLeft: 800, marginTop: 12 }}
            />
          </Card>
        ) : null}
      </>
    );
  }
}

export default DataLoader;
