import {
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { $getRoot, $insertNodes, LexicalEditor } from "lexical";
import { EditorState } from "lexical/LexicalEditorState";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DatePicker from "../../Components/DatePicker/DatePicker";
import ImageUpload from "../../Components/ImageUpload/ImageUpload";
import RichTextEditor from "../../Components/LexicalEditor";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { LocalProcedureTemplate } from "../../Models/LocalProcedureTemplateModel";
import { LocalProcedureTemplateSection } from "../../Models/LocalProcedureTemplateSection";
import MultiSelectCheckBox from "../../Components/Select/MultiSelectCheckBox";
import { IBlackTextOption, BlackTextOptionModel } from "../../Models";
import ImageUploadFooter from "../../Components/ImageUpload/ImageUploadFooter";
import SearchableAutoComplete from "../../Components/SearchableAutoComplete";
import MultiAutoComplete from "../../Components/AutoComplete";
import RedButton from "../../Components/Button/RedButton";
import DocumentView from "../DocumentViewer";
import DocumentPreview from "../../Components/DocumentPreview";
import { QDDocsService } from "../../Services";
import axios from "axios";
import { $generateNodesFromDOM } from "@lexical/html";
import mammoth from "../../Utilities/mammoth/lib";
import Utils from "../../Common/Utils";
import FileUploadModel from "../RTTool/FileUploadModel";
import Constants from "../../Common/Constants";

const ProcedureTypeOptions = [

  { text: "Local Procedure", value: "Local Procedure" },
];


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    maxWidth: "none",
    cursor: "pointer",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    fontSize: 20,
  },
}));

interface Props extends RouteComponentProps {
  countryAgainstAffilateorSubRegion :string,
  template: LocalProcedureTemplate;
  sections: LocalProcedureTemplateSection[];
  onTemplateChange: (template: LocalProcedureTemplate) => void;
  editorState: EditorState;
  onBodyTextEditorChange: (_bodyText: EditorState, index?: number, editor?: LexicalEditor) => void;
  onBasicPrinciplesEditorChange: (_basicPrinciples: EditorState, index?: number, editor?: LexicalEditor) => void;
  onSectionBodyTextChange: (_bodyText: EditorState, index: number, editor?: LexicalEditor) => void;
  onHeaderEditorChange: (_header: EditorState, index?: number, editor?: LexicalEditor) => void;
  onEffectiveDateChange: (value: Date | null) => void;
  onProcedureTypeChange: (selected: SelectModel) => void;
  handleCountryDropDown?: (selected: SelectModel) => void;
  onHeaderImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFooterImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBodyTextAdd: () => void;
  handleKey: () => void;
  onBodyTextRemove: (i: number) => void;
  onHeaderCancel: () => void;
  onFooterCancel: () => void;
  setApproverInputValue: ((InputValue: any) => void);
  setOwnerInputValue: ((InputValue: any) => void);
  onApproverNameSelect: ((InputValue: any) => void);
  onOwnerNameSelect: ((InputValue: any) => void);
  createTemplateValidation?: boolean;
  imageUrlHeader?: string | null;
  approverNames: any[];
  ownerNames: any[];
  imageUrlFooter?: string | null;
  toggleUndo?: boolean;
  isAdminUser?: boolean;
  toggleUndoHeader?: boolean;
  toggleUndoSectionHeader?: boolean;
  toggleUndoBodyText?: boolean;
  toggleRedo?: boolean;
  toggleRedoHeader?: boolean;
  toggleRedoBodyText?: boolean;
  onSectionHeaderChange: (_header: EditorState, index: number, editor?: LexicalEditor) => void;
  DeviationOn: boolean;
  countryValue?: string[];
  limitedOptions?: BlackTextOptionModel[];
  limitedSelectOptions?: SelectModel[];
  onLimitedOptionChange?: ((selected: SelectModel, targetId?: string) => void);
  handleCommentChange: ((event: React.ChangeEvent<HTMLInputElement>) => void);
  selectedLimitedOptions?: IBlackTextOption[];
  approverSelectedItems: {};
  ownerSelectedItems: {};
  ApproverNameLoading: boolean,
  onTextSelected?: ((showBlackTextPopup: boolean) => void);
  handleSetGeographyChange: ((selectedValues: any) => void);
  setGeographyAllValues:any[];
  setGeographyDefault:any[];
  setGeographyValues:any[];
  
  
}

interface State {
  template: LocalProcedureTemplate;
  formValidationState: { name: string; isValid: boolean }[];
  formSize: number;
  documenSize: number;
  openDocument: boolean;
  editorRef: LexicalEditor | null;
  qdocsLoading: boolean;
  listLength: number;
  editorState: EditorState;
  files: FileUploadModel[];
  qdocsVersion: string;
  qdocsFileName: string;
}
class FormComponentECO extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    let isValid = false;
    this.state = {
      template: props.template,
      formValidationState: [{ name: "template", isValid }],
      formSize: 12,
      documenSize: 0,
      openDocument: false,
      editorRef: null,
      qdocsLoading: false,
      listLength: 1,
      editorState: Utils.getEditorState(),
      files: [],
      qdocsVersion: "0",
      qdocsFileName: '',
    };
  }

  addFormFields = () => {
    this.props.onBodyTextAdd();
  };

  removeFormFields(i: number) {
    this.props.onBodyTextRemove(i);
  }

  handleHeaderImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("formcompheader");
    this.props.onHeaderImageUpload(event);
  };
  handleFooterImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onFooterImageUpload(event);
  };

  onDropDownChange = (selected: SelectModel) => {
    this.props.onProcedureTypeChange(selected);
  };

  InputChange = (event: any) => {
    console.log("input event", event);

  }

  handleCountryDropDown = (selected: SelectModel) => {
    if (this.props.handleCountryDropDown) {
      this.props.handleCountryDropDown(selected);
    }
  };

  onSelectChange = (target: string[] | string) => {
    console.log("target==", target);
    if (this.props.handleCountryDropDown) {
      // this.props.handleCountryDropDown(target);
    }
  }


  render() {
    const { template, selectedLimitedOptions } = this.props;
    console.log("template", template);
    
    console.log("this update template _basicPrinciples", template._basicPrinciples);
    const basicPrincipleLimitedOption = selectedLimitedOptions?.find(p => p.id === 'LIMITED_OPTION_BASICPRINCIPLE');
    const headerLimitedOption = selectedLimitedOptions?.find(p => p.id === 'LIMITED_OPTION_HEADER');
    console.log("headerLimitedOption=", headerLimitedOption);
    const bodyLimitedOption = selectedLimitedOptions?.find(p => p.id === 'LIMITED_OPTION_BODY');
    


    return (
      <Box>
        <div>
          <Grid container spacing={3} mb={"1.5em"} mt={"0.1em"}>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Procedure Name</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the name of template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <TextInputField
                value={template.templateName}
                placeholder="Enter Template Header"
                onChange={this.handleTemplateFormChange}
                name="templateName"

              />
              <ImageUpload
                name="headerImageFile"
                onChange={this.handleHeaderImage}
                onCancel={this.onHeaderPreviewCancelClick}
                imageUrl={this.props.imageUrlHeader}
                isDisabled
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Procedure Type </InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the procedure Type of the template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>

              <SingleSelect
                name="procedureType"
                defaultValue="Local Procedure"
                isDisabled
                value={template.procedureType}
                values={ProcedureTypeOptions}
                onChange={this.onDropDownChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={"1.5em"} >
            <Grid item xs={4}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Version Number</InputLabel>
              <TextInputField
                type="number"
                min={1}
                placeholder="Enter Version Number"
                name="versionNumber"
                onChange={this.handleTemplateFormChange}
                value={template.versionNumber}
                isDisabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Effective Date </InputLabel>
              <DatePicker
                name="effectiveDate"
                value={template.effectiveDate}
                onChange={this.handleEffectiveDateChange}
                
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Set Geography ({ template.geoGraphyType  === 'subregion' ? "Sub-Region/Hub":template.geoGraphyType.charAt(0).toUpperCase() + template.geoGraphyType.slice(1)})</InputLabel>
              <MultiAutoComplete
                disabled={!this.props.isAdminUser || template.status === "Approved"}
                selectedItems={[...this.props.setGeographyValues]}
                options={this.props.setGeographyAllValues}
                onChange={this.props.handleSetGeographyChange}
              />

              {/* <MultiSelectCheckBox
                disabled={true}
                options={[]}
                values={this.props.countryValue ? this.props.countryValue : []}
                onSelectChange={this.onSelectChange}
              /> */}
            </Grid>


          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Owner Title</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the title/designation of the person who owns this template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <TextInputField
                placeholder="Enter Owner Title/Designation"
                onChange={this.handleTemplateFormChange}
                name="owner"
                value={template.owner}
                fieldStyle={{ color: "yellow" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Approver Title</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the title/designation of the person who approves this template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>

              <TextInputField
                placeholder="Enter Approver Title/Designation"
                onChange={this.handleTemplateFormChange}
                name="approver"
                value={template.approver}
              />
              {/* <SearchableAutoComplete
                setInputValue={this.props.setInputValue}
                onSelect={this.props.onSelect}
                options={this.props.approverNames}
                selectedItems={this.props.selectedItems}
              /> */}
            </Grid>
            {/* <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel>Owner Name</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the name of the person who owns this template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <SearchableAutoComplete
                setInputValue={this.props.setOwnerInputValue}
                onSelect={this.props.onOwnerNameSelect}
                options={this.props.ownerNames}
                selectedItems={this.props.ownerSelectedItems}
                placeholder="Select Owner Name"
              />
            </Grid> */}
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Approver Name</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the name of the person who approves this template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <SearchableAutoComplete
                setInputValue={this.props.setApproverInputValue}
                onSelect={this.props.onApproverNameSelect}
                options={this.props.approverNames}
                selectedItems={this.props.approverSelectedItems}
                loading={this.props.ApproverNameLoading}
                placeholder="Search by First/Last Name or Email"
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Footer</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the footer of the template document "
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <TextInputField
                placeholder="Enter Footer Name"
                onChange={this.handleTemplateFormChange}
                name="footer"
                value={template.footer}

              />
              <ImageUploadFooter
                name="footerImageFile"
                onCancel={this.onFooterPreviewCancelClick}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.props.onFooterImageUpload(event)
                }
                imageUrl={this.props.imageUrlFooter}
                isDisabled
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal"  style={{ height: 30 }} />
          {/* <Grid container marginTop='1em' justifyContent="flex-end" spacing={1}>
            <Grid item>
              <RedButton label="Open QDocs Procedure" onClick={this.onQdocsClick} />
            </Grid>
          </Grid> */}
          
          <Grid container spacing={1}>
          <Grid item xs={this.state.formSize}>
          <Grid container mt={"1.5em"} mb={"2em"}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={"1em"}>
                <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <InputLabel sx={{
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      }} required>Basic Principles</InputLabel>
                    </Grid>
                    <Grid item>
                      <LightTooltip
                        placement="bottom"
                        title="This is the principles of the template"
                        arrow
                      >
                        <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                      </LightTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {this.props.onLimitedOptionChange ?
                    <>
                      <SingleSelect id="LIMITED_OPTION_BASICPRINCIPLE" value={basicPrincipleLimitedOption?.selectedBlackTextOption?.value} values={this.props.limitedSelectOptions} onChange={this.props.onLimitedOptionChange} />
                      <br></br>
                      <TextField
                        disabled={!(basicPrincipleLimitedOption?.selectedBlackTextOption?.value === "5")}
                        fullWidth
                        size={"small"}
                        multiline
                        id="LIMITED_OPTION_BASICPRINCIPLE"
                        placeholder="If selecting other, provide rationale"
                        onChange={this.props.handleCommentChange}
                        name="comment"
                        value={basicPrincipleLimitedOption?.comment}
                      />
                    </>
                    : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                toogleRedo={this.props.toggleRedo}
                toogleUndo={this.props.toggleUndo!}
                onChange={this.props.onBasicPrinciplesEditorChange}
                editorState={template._basicPrinciples}
                handleKey={this.props.handleKey}
                onTextSelected={this.props.onTextSelected}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={"1em"}>
                <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <InputLabel sx={{
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      }} required>Header</InputLabel>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {this.props.onLimitedOptionChange ?
                    <SingleSelect id="LIMITED_OPTION_HEADER" value={headerLimitedOption?.selectedBlackTextOption?.value} values={this.props.limitedSelectOptions} onChange={this.props.onLimitedOptionChange} /> : null}
                  <br></br>
                  <TextField
                    disabled={!(headerLimitedOption?.selectedBlackTextOption?.value === "5")}
                    fullWidth
                    size={"small"}
                    multiline
                    id="LIMITED_OPTION_HEADER"
                    placeholder="If selecting other, provide rationale"
                    onChange={this.props.handleCommentChange}
                    name="comment"
                    value={headerLimitedOption?.comment}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                id="Header"
                defaultStyle={Constants.DocFontFamilyBold}
                skipDefaultFontFmily={true}
                toogleRedo={this.props.toggleRedoHeader}
                toogleUndo={this.props.toggleUndoHeader}
                onChange={this.handleHeaderEditorChange}
                editorState={template?._header}
                handleKey={this.props.handleKey}
                onTextSelected={this.props.onTextSelected}
              />
            </Grid>
          </Grid>
          <Grid container mt={"1em"} mb={"1em"}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={"1em"}>
                <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <InputLabel sx={{
                        '& .MuiFormLabel-asterisk': {
                          color: 'red',
                        },
                      }} required>Body Text</InputLabel>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {this.props.onLimitedOptionChange ?
                    <SingleSelect id="LIMITED_OPTION_BODY" value={bodyLimitedOption?.selectedBlackTextOption?.value} values={this.props.limitedSelectOptions} onChange={this.props.onLimitedOptionChange} /> : null}
                  <br></br>
                  <TextField
                    disabled={!(bodyLimitedOption?.selectedBlackTextOption?.value === "5")}
                    fullWidth
                    size={"small"}
                    multiline
                    id="LIMITED_OPTION_BODY"
                    placeholder="If selecting other, provide rationale"
                    onChange={this.props.handleCommentChange}
                    name="comment"
                    value={bodyLimitedOption?.comment}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                toogleUndo={this.props.toggleUndoBodyText!}
                toogleRedo={this.props.toggleRedoBodyText}
                onChange={this.handleBodyEditorChange}
                editorState={template?._bodyText}
                handleKey={this.props.handleKey}
                onTextSelected={this.props.onTextSelected}
              />
            </Grid>
          </Grid>
         
          {this.props.sections.map(
            (_element: LocalProcedureTemplateSection, index: number) => {
              let currentNumber = index + 1;

              if (_element.sectionId) {
                currentNumber = _element.sectionId;
              }

              const sectionHeaderLimitedOption = selectedLimitedOptions?.find(p => p.id === `LIMITED_OPTION_HEADER_${currentNumber}`);
              const sectionBodyLimitedOption = selectedLimitedOptions?.find(p => p.id === `LIMITED_OPTION_BODY_${currentNumber}`);

              return (
                <Grid key={`section_${currentNumber}`}>
                  {currentNumber ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {/* <Button
                        variant="outlined"
                        color="error"
                        startIcon={<FontAwesomeIcon icon={faXmark} />}
                        style={{ color: "red", textTransform: "capitalize" }}
                        onClick={() => this.removeFormFields(currentNumber)}
                      >
                        Remove
                      </Button> */}
                    </Box>
                  ) : null}
                  <Grid
                    container
                    marginTop="0.1em"
                    marginBottom={"1em"}
                    spacing={3}
                  >
                    {/* <Grid item xs={11.5}>
                      <InputLabel>Header {currentNumber}</InputLabel>
                      <RichTextEditor
                        toogleUndo={this.props.toggleUndo!}
                        editorState={this.props.sections[index]._header!}
                        index={index}
                        onChange={(editorState: EditorState, index?: number, editor?: LexicalEditor,) => {
                          this.props.onSectionHeaderChange(editorState, index!, editor)
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={"1em"}>
                        <Grid item>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <InputLabel>Header {currentNumber}</InputLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          {this.props.onLimitedOptionChange ?
                            <>

                              <SingleSelect id={`LIMITED_OPTION_HEADER_${currentNumber}`} value={sectionHeaderLimitedOption?.selectedBlackTextOption?.value} values={this.props.limitedSelectOptions} onChange={this.props.onLimitedOptionChange} />
                              <br></br>
                              <TextField
                                disabled={!(sectionHeaderLimitedOption?.selectedBlackTextOption?.value === "5")}
                                fullWidth
                                size={"small"}
                                multiline
                                id={`LIMITED_OPTION_HEADER_${currentNumber}`}
                                placeholder="If selecting other, provide rationale"
                                onChange={this.props.handleCommentChange}
                                name="footer"
                                value={sectionHeaderLimitedOption?.comment}
                              />
                            </>
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <RichTextEditor
                        id={`section_header_${currentNumber}`}
                        defaultStyle={Constants.DocFontFamilyBold}
                        skipDefaultFontFmily={true}
                        handleKey={this.props.handleKey}
                        toogleUndo={this.props.toggleUndo!}
                        editorState={this.props.sections[index]._header!}
                        index={index}
                        onChange={(editorState: EditorState, index?: number, editor?: LexicalEditor,) => {
                          this.props.onSectionHeaderChange(editorState, index!, editor)
                        }}
                        onTextSelected={this.props.onTextSelected}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    marginTop="0.1em"
                    marginBottom={"2em"}
                    spacing={3}
                  >
                    {/* <Grid item xs={11.5}>
                      <InputLabel style={{ marginBottom: '0.1rem' }}>Body Text {currentNumber}</InputLabel>
                      <RichTextEditor
                        index={index}
                        toogleUndo={this.props.toggleUndo!}
                        onChange={(editorState: EditorState, index?: number, editor?: LexicalEditor) =>
                          this.props.onSectionBodyTextChange(
                            editorState,
                            index!,
                            editor
                          )
                        }
                        editorState={this.props.sections[index]._bodyText}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={"1em"}>
                        <Grid item>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <InputLabel>Body Text {currentNumber}</InputLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          {this.props.onLimitedOptionChange ?
                            <SingleSelect id={`LIMITED_OPTION_BODY_${currentNumber}`} value={sectionBodyLimitedOption?.selectedBlackTextOption?.value} values={this.props.limitedSelectOptions} onChange={this.props.onLimitedOptionChange} /> : null}
                          <br></br>
                          <TextField
                            disabled={!(sectionBodyLimitedOption?.selectedBlackTextOption?.value === "5")}
                            fullWidth
                            size={"small"}
                            multiline
                            id={`LIMITED_OPTION_BODY_${currentNumber}`}
                            placeholder="If selecting other, provide rationale"
                            onChange={this.props.handleCommentChange}
                            name="footer"
                            value={sectionBodyLimitedOption?.comment}
                          />

                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <RichTextEditor
                        index={index}
                        toogleUndo={this.props.toggleUndo!}
                        onChange={(editorState: EditorState, index?: number, editor?: LexicalEditor) =>
                          this.props.onSectionBodyTextChange(
                            editorState,
                            index!,
                            editor
                          )
                        }
                        editorState={this.props.sections[index]._bodyText}
                        handleKey={this.props.handleKey}
                        onTextSelected={this.props.onTextSelected}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          )}
           </Grid>
           {this.state.openDocument ?
           <Grid item xs={this.state.documenSize} mt={"1.5em"} mb={"1em"} >
              {
                this.state.qdocsLoading ? <Grid container justifyContent={'center'} alignItems={'center'} ><CircularProgress /></Grid> : this.state.listLength === 0 ? (
                  <Grid container justifyContent={'center'} alignItems={'center'} ><Typography>No Document found !</Typography></Grid>
              ) : <>
                 <Grid container justifyContent={'center'} alignItems={'center'} marginBottom={1} ><Typography style={{ fontSize: 15, fontWeight: 'bold', wordWrap: 'break-word', wordBreak: 'break-all'}}>{this.state.qdocsFileName}&nbsp;&nbsp;&nbsp;(Version: {this.state.qdocsVersion})</Typography></Grid>
                <RichTextEditor id="rttooleditor" onChange={()=>{}} editorState={this.state.editorState}  onRefChange={this.handleRefChange} 
                        skipStrongStylePatch={true} handleCopyCommand={false} />
                        </>
              }
           </Grid>
           : null}
          </Grid>
        </div>
        {/* <Box style={{ marginTop: "1em" }}>
          <WhiteButton
            startIcon={<FontAwesomeIcon icon={faPlus} color="#D52B1E" />}
            onClick={this.addFormFields}
            label="Add More Section"
          />
        </Box> */}
      </Box>
      
    );
  }

  handleFileUpload = (file: FileUploadModel) => {
    this.setState(prevState => ({
        files: [...prevState.files, file],
    }));
}

  onQdocsClick = () => {
    const { template ,countryAgainstAffilateorSubRegion} = this.props;
    console.log("countryAgainstAffilateorSubRegion=",countryAgainstAffilateorSubRegion)
    this.setState({ openDocument: !this.state.openDocument }, async () => {
      if (this.state.openDocument) {
        this.setState({ formSize: 7.2, documenSize: 4.8, qdocsLoading: true })
        const latestDocument = await QDDocsService.searchDocumentForLocal({ geography: [countryAgainstAffilateorSubRegion], procedure_name: template.templateName });
        // if (list.length === 0) {
        //   // Set loading to false and return if the list is empty
        //   this.setState({ qdocsLoading: false, listLength: 0 });
        //   return;
        // }
        // console.log("qdocs list", list);
        // // Find the document with the latest modified date using reduce
        // const latestDocument = list.reduce((prev, current) => {
        //   return ((current.version) > (prev.version)) ? current : prev;
        // });
        
        if (latestDocument) {
          this.setState({qdocsVersion: latestDocument.version, qdocsFileName: latestDocument.fileName})
          const arrayBuffer = await this.downloadDocument(latestDocument.presigned_url);
          if (arrayBuffer) {
            await this.updateEditor(arrayBuffer);
          }
        }
        else {
          this.setState({ qdocsLoading: false, listLength: 0 });
        }
      }
      else {
        this.setState({ formSize: 12, documenSize: 0 })
      }
    })
  }

  
  // downloadDocument = async (fileKey: string) => {
  //   const presignedUrl = await QDDocsService.getDocument(fileKey);

  //   if (presignedUrl) {
  //     const response = await axios({ url: presignedUrl, method: 'GET', responseType: 'blob' });
  //     const blob = new Blob([response.data]);
  //     // return this.readFileAsArrayBuffer(blob);
  //     console.log("presignedUrl", presignedUrl);
  //     return this.readFileAsArrayBuffer(blob);
  //   }
  // }
  downloadDocument = async (presignedUrl: string) => {
    if (presignedUrl) {
      const response = await axios({ url: presignedUrl, method: 'GET', responseType: 'blob' });
      const blob = new Blob([response.data]);
      // return this.readFileAsArrayBuffer(blob);
      console.log("presignedUrl", presignedUrl);
      return this.readFileAsArrayBuffer(blob);
    }
  }


  handleRefChange = (editorRef: any) => {
    this.setState({ editorRef: editorRef.current });
  }

  updateEditor = async (arrayBuffer: ArrayBuffer) => {
    const { editorRef } = this.state;

    const mammothResult = await mammoth.convertToHtml({ arrayBuffer }, {
      ignoreEmptyParagraphs: false,
    });

    //this.setState({ mammothResult });
    const parser = new DOMParser();
    const dom = parser.parseFromString(`<div>${mammothResult.value}</div>`, 'text/html');

    if (editorRef) {
      editorRef.update(() => {
        const nodes = $generateNodesFromDOM(editorRef, dom);
        // Select the root
        $getRoot().select();

        // Insert them at a selection.
        $insertNodes(nodes);
      });
    }
  }

  readFileAsArrayBuffer = (file: File | Blob): Promise<ArrayBuffer> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        const arrayBuffer: any = loadEvent?.target?.result;
        this.setState({ qdocsLoading: false }, () => {
          resolve(arrayBuffer);
        });
      };

      reader.readAsArrayBuffer(file);
    });
  }
  

  handleHeaderEditorChange = (_header: EditorState, index?: number, editor?: LexicalEditor) => {
    this.props.onHeaderEditorChange(_header, index, editor)
  };

  handleBasicPrincipleChange = (
    _basicPrinciples: EditorState,
    index?: number
  ) => {
    console.log("index", index);
    // this.props.onBasicPrinciplesEditorChange(_basicPrinciples);
  };

  handleTemplateFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("even", event.target.name, event.target.value);
    if (event) {
      let template: any = Object.assign({}, this.props.template);
      template[event.target.name] = event.target.value;
      this.props.onTemplateChange(template);
    }
  };
  onHeaderPreviewCancelClick = () => {
    this.props.onHeaderCancel();
  }
  onFooterPreviewCancelClick = () => {
    this.props.onFooterCancel();
  }

  handleSectionHeaderChange = (
    _header: EditorState, index: number
  ) => {
    this.props.onSectionHeaderChange(_header, index)
  };

  handleEffectiveDateChange = (value: Date | null) => {
    this.props.onEffectiveDateChange(value);
  };

  handleBodyEditorChange = (_bodyText: EditorState, index?: number, editor?: LexicalEditor) => {
    this.props.onBodyTextEditorChange(_bodyText, index, editor);
  };

  handleSectionBodyTextEditor = (_bodyText: EditorState, index: number, editor?: LexicalEditor) => {
    this.props.onSectionBodyTextChange(_bodyText, index, editor);
  };
}
export default withRouter(FormComponentECO);