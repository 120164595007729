import React, { Context } from "react";
import { Route, RouteProps } from "react-router-dom";
import { AuthProviderService, AuthorizationService, LocalStorageService } from "../../Services";
import UnauthorizedRoute from "./UnauthorizedRoute";
import { UserRole } from "../../Common/Enums";
import { RoleContext } from "../../Contexts";

interface Props extends RouteProps {
    allowedRoles: UserRole[];
}
interface State { }
class UserRoute extends React.Component<Props, State> {
    static contextType?: Context<any> = RoleContext;
    context!: React.ContextType<typeof RoleContext>;

    async componentDidMount() {
        const { location } = this.props;

        if (location) {
            AuthProviderService.setCallbackPath(location.pathname);

            const isUserLoggedIn = LocalStorageService.isUserLoggedIn();

            if (!isUserLoggedIn) {
                const isLoggedIn = await AuthProviderService.isLoggedIn();

                if (!isLoggedIn) {
                    LocalStorageService.resetUserLoggedIn();
                    AuthProviderService.login();
                }
            }
        }
    }

    render() {
        const isAuthorize = AuthorizationService.isUserAuthorize(this.props.allowedRoles, this.context);

        if (isAuthorize) {
            return <Route {...this.props} />;
        } else {
            return <UnauthorizedRoute {...this.props} />;
        }
    }
}

export default UserRoute;