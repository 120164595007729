import "./ColorPicker.css";

import { ReactNode, useEffect, useRef, useState } from "react";
import DropDown from "./DropDown";
import { Box, Grid, InputLabel, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

interface ColorPickerProps {
  disabled?: boolean;
  buttonAriaLabel?: string;
  buttonClassName: string;
  buttonIconClassName?: string;
  buttonLabel?: string;
  color: string;
  children?: ReactNode;
  onChange?: (color: string) => void;
  title?: string;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    maxWidth: "none",
    cursor: "pointer",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    fontSize: 20,
  },
}));

const basicColors = [
  "yellow",
  "black",
  "red",
  "magenta",
  "blue",
  "darkMagenta",
  "lightGray",
  "darkYellow",
  "darkGray",
  "green",
  "darkBlue",
  "darkCyan",
  "cyan",
  "darkRed",
  "darkGreen",
];

const WIDTH = 190

export default function HighlightColorPicker({
  color,
  children,
  onChange,
  disabled = false,
  ...rest
}: Readonly<ColorPickerProps>): JSX.Element {
  const [selfColor, setSelfColor] = useState(transformColor("hex", color));
  const [inputColor, setInputColor] = useState(color);
  const innerDivRef = useRef(null);
  const [selfCustomColor, setSelfCustomColor] = useState(color);


  useEffect(() => {
    // Check if the dropdown is actually active
    if (innerDivRef.current !== null && onChange) {
      onChange(selfCustomColor);
      setInputColor(selfCustomColor);
    }
  }, [selfCustomColor, onChange]);

  useEffect(() => {
    if (color === undefined) return;
    const newColor = color;
    setSelfCustomColor(newColor);
    setInputColor(newColor);
  }, [color]);

  return (
    <DropDown {...rest} disabled={disabled}>
      <div
        className="color-picker-wrapper"
        style={{ width: WIDTH }}
        ref={innerDivRef}
      >
        <div className="color-picker-basic-color">
          {basicColors.map((basicColor) => (
            <LightTooltip
              placement="bottom"
              title={basicColor}
              arrow
            >
              <button
                className={basicColor === selfCustomColor ? " active" : ""}
                key={basicColor}
                style={{ backgroundColor: basicColor }}
                onClick={() => {
                  console.log("basic", basicColor);
                  setInputColor(basicColor);
                  setSelfCustomColor(basicColor);
                }}
              />
            </LightTooltip>
          ))}
        </div>
        <Grid container>
          <Grid item>

            <Box style={{ cursor: 'pointer', border: '2px solid grey', marginTop: '1rem', padding: '0.7rem', backgroundColor: 'whitesmoke' }} onClick={() => { setSelfCustomColor('') }}></Box>
          </Grid>
          <Grid item>

            <InputLabel onClick={() => { setSelfCustomColor('') }} style={{
              marginLeft: '1rem', cursor: 'pointer', marginTop: '1rem', fontSize: 18, fontWeight: 'bolder'
            }}>No Color</InputLabel>
          </Grid>
        </Grid>
      </div>
    </DropDown>
  );
}

export interface Position {
  x: number;
  y: number;
}

interface RGB {
  b: number;
  g: number;
  r: number;
}
interface HSV {
  h: number;
  s: number;
  v: number;
}
interface Color {
  hex: string;
  hsv: HSV;
  rgb: RGB;
}

export function toHex(value: string): string {
  if (!value.startsWith("#")) {
    const ctx = document.createElement("canvas").getContext("2d");

    if (!ctx) {
      throw new Error("2d context not supported or canvas already initialized");
    }

    ctx.fillStyle = value;

    return ctx.fillStyle;
  } else if (value.length === 4 || value.length === 5) {
    value = value
      .split("")
      .map((v, i) => (i ? v + v : "#"))
      .join("");

    return value;
  } else if (value.length === 7 || value.length === 9) {
    return value;
  }

  return "#000000";
}

function hex2rgb(hex: string): RGB {
  const rbgArr = (
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g) || []
  ).map((x) => parseInt(x, 16));

  return {
    b: rbgArr[2],
    g: rbgArr[1],
    r: rbgArr[0],
  };
}

function rgb2hsv({ r, g, b }: RGB): HSV {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const d = max - Math.min(r, g, b);

  const h = d
    ? (max === r
      ? (g - b) / d + (g < b ? 6 : 0)
      : max === g
        ? 2 + (b - r) / d
        : 4 + (r - g) / d) * 60
    : 0;
  const s = max ? (d / max) * 100 : 0;
  const v = max * 100;

  return { h, s, v };
}

function hsv2rgb({ h, s, v }: HSV): RGB {
  s /= 100;
  v /= 100;

  const i = ~~(h / 60);
  const f = h / 60 - i;
  const p = v * (1 - s);
  const q = v * (1 - s * f);
  const t = v * (1 - s * (1 - f));
  const index = i % 6;

  const r = Math.round([v, q, p, p, t, v][index] * 255);
  const g = Math.round([t, v, v, q, p, p][index] * 255);
  const b = Math.round([p, p, t, v, v, q][index] * 255);

  return { b, g, r };
}

function rgb2hex({ b, g, r }: RGB): string {
  return "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
}

function transformColor<M extends keyof Color, C extends Color[M]>(
  format: M,
  color: C
): Color {
  let hex: Color["hex"] = toHex("#121212");
  let rgb: Color["rgb"] = hex2rgb(hex);
  let hsv: Color["hsv"] = rgb2hsv(rgb);

  if (format === "hex") {
    const value = color as Color["hex"];

    hex = toHex(value);
    rgb = hex2rgb(hex);
    hsv = rgb2hsv(rgb);
  } else if (format === "rgb") {
    const value = color as Color["rgb"];

    rgb = value;
    hex = rgb2hex(rgb);
    hsv = rgb2hsv(rgb);
  } else if (format === "hsv") {
    const value = color as Color["hsv"];

    hsv = value;
    rgb = hsv2rgb(hsv);
    hex = rgb2hex(rgb);
  }

  return { hex, hsv, rgb };
}
