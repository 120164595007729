import React, { DragEvent } from "react";
import { Grid, Typography, Box, Card } from "@mui/material";

import FileUploadModel from './FileUploadModel';
import { FileType } from "../../Common/Enums";
import IconButton from "../../Components/Button/IconButton";

interface Props {
    onChange: ((file: FileUploadModel) => void);
}
interface State {
    isDragOver: boolean;
}
class FileUpload extends React.Component<Props, State> {
    fileUploadInput: HTMLInputElement | null = null;

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isDragOver: false,
        };
    }

    render() {
        const { isDragOver } = this.state;

        let border = 1;
        let borderColor = '#D52B1E';
        let borderStyle = 'dashed';
        let borderRadius = '20px';
        let textColor = '#1D1D1D';
        let textSize = 20;
        let hintColor = '#62686E';
        let fileUploadId = 'cvUpload';
        let fileExplorerStyle: React.CSSProperties = { color: '#D52B1E', cursor: 'pointer' };

        return (
            <Card style={{ border, borderColor, borderStyle, borderRadius }}>
                <Box p={2} data-html2canvas-ignore
                    onDragOver={this.handleDragOver} onDrop={this.handleDrop} onDragLeave={this.handleDragLeave}>
                    <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
                        <Grid item>
                            <IconButton
                                isFR={true}
                                isDisabled={false}
                                IconType="FileUpload"
                                onClick={this.handleCloudClick}
                            />
                        </Grid>
                        <Grid item>
                            {!isDragOver ? <Typography style={{ color: textColor, fontWeight: 400, fontSize: textSize }}>
                                Drag and Drop files, or
                                <input
                                    ref={input => this.fileUploadInput = input}
                                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    style={{ display: 'none' }}
                                    id={fileUploadId}
                                    type="file"
                                    onChange={this.handleChange}
                                    multiple
                                />
                                <label htmlFor={fileUploadId} style={fileExplorerStyle}>
                                    &nbsp;Browse
                                </label>
                            </Typography> :
                                <Typography style={{ color: textColor, fontWeight: 400, fontSize: textSize }}>
                                    Drop your file here
                                </Typography>
                            }
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: hintColor, fontWeight: 400, fontSize: 14 }}>
                                .docx files are supported.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        );
    }

    handleAndCreateFileUpload = (file: File) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);
        fileReader.onload = (e) => {
            const { name, size, type, } = file;

            const fileModel = new FileUploadModel();
            fileModel.name = name;
            fileModel.sizeInKB = Math.round(size / 1024);
            fileModel.sizeInMB = Math.round(size / 1024 / 1024);
            fileModel.type = type as FileType;
            fileModel.ext = type.replace(/(.*)\//g, '');
            fileModel.file = file;
            fileModel.fileStatus = 'LOCAL';

            this.props.onChange(fileModel);
        };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;

        if (target.files) {
            for (let index = 0; index < target.files.length; index++) {
                const element = target.files[index];
                this.handleAndCreateFileUpload(element);
            }
        }
    }

    handleCloudClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.fileUploadInput) {
            this.fileUploadInput.value = '';
            this.fileUploadInput.click();
        }
    }

    handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isDragOver: true });
    }

    handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isDragOver: false }, () => {
            const { files } = event.dataTransfer;

            if (files) {
                for (let index = 0; index < files.length; index++) {
                    const element = files[index];
                    this.handleAndCreateFileUpload(element);
                }
            }
        });
    }

    handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isDragOver: false });
    }
}

export default FileUpload;