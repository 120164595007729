export enum FileType {
    PDF = "application/pdf",
    DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    JPG = "image/jpeg",
    PNG = "image/png"
}

export enum DocumentType {
    GPT_HEADER = "gptheader",
    GPT_FOOTER = "gptfooter",
    GPT_DOCUMENT = "gptdocument"
}

export enum UserRole {
    ADMIN_USER = "ADMIN_USER",
    ECO_USER = "ECO_USER",
    READONLY_USER = "READONLY_USER",
    GLOBAL_USER = "GLOBAL_USER"
}

export enum LocalProcedureStatusType {
    BATCH_GENERATED = "Initiation Pending",
    PROCEDURE_GENERATED = "Generated",
    APPROVAL_PENDING = "Pending Approval",
    APPROVED = "Approved",
    DRAFTED = "Drafted",
    REJECTED = "Rejected",
}