var _ = require("underscore");

exports.readStylesXml = readStylesXml;
exports.Styles = Styles;
exports.defaultStyles = new Styles({}, {});

function Styles(paragraphStyles, characterStyles, tableStyles, numberingStyles, docDefaults) {
    return {
        findParagraphStyleById: function(styleId) {
            return paragraphStyles[styleId];
        },
        findCharacterStyleById: function(styleId) {
            return characterStyles[styleId];
        },
        findTableStyleById: function(styleId) {
            return tableStyles[styleId];
        },
        findNumberingStyleById: function(styleId) {
            return numberingStyles[styleId];
        },
        getDefaultSpacing: function() {
            return getDefaultSpacing(docDefaults);
        },
    };
}

Styles.EMPTY = new Styles({}, {}, {}, {}, []);

function readStylesXml(root) {
    var paragraphStyles = {};
    var characterStyles = {};
    var tableStyles = {};
    var numberingStyles = {};
    var docDefaults = [];

    var styles = {
        "paragraph": paragraphStyles,
        "character": characterStyles,
        "table": tableStyles
    };

    root.getElementsByTagName("w:docDefaults").forEach(function(styleElement) {
        styleElement.children.forEach(function(element) {
            docDefaults.push(element);
        });
    });

    root.getElementsByTagName("w:style").forEach(function(styleElement) {
        var style = readStyleElement(styleElement, docDefaults);
        if (style.type === "numbering") {
            numberingStyles[style.styleId] = readNumberingStyleElement(styleElement);
        } else {
            var styleSet = styles[style.type];
            if (styleSet) {
                styleSet[style.styleId] = style;
            }
        }
    });

    return new Styles(paragraphStyles, characterStyles, tableStyles, numberingStyles, docDefaults);
}

function readStyleElement(styleElement, docDefaults) {
    var rPrStyle = readStyleFromElement(styleElement, docDefaults);
    var type = styleElement.attributes["w:type"];
    var styleId = styleElement.attributes["w:styleId"];
    var name = styleName(styleElement);
    return _.extend({type: type, styleId: styleId, name: name}, rPrStyle);
}

function styleName(styleElement) {
    var nameElement = styleElement.first("w:name");
    return nameElement ? nameElement.attributes["w:val"] : null;
}

function readNumberingStyleElement(styleElement) {
    var numId = styleElement
        .firstOrEmpty("w:pPr")
        .firstOrEmpty("w:numPr")
        .firstOrEmpty("w:numId")
        .attributes["w:val"];
    return {numId: numId};
}

function readBooleanElement(element) {
    if (element) {
        var value = element.attributes["w:val"];
        return value !== "false" && value !== "0";
    } else {
        return false;
    }
}

function readUnderline(element) {
    if (element) {
        var value = element.attributes["w:val"];
        return value !== undefined && value !== "false" && value !== "0" && value !== "none";
    } else {
        return false;
    }
}

function getDefaultSpacing(docDefaults) {
    var defaultSpacing = null;
    docDefaults.forEach(function(element) {
        const wSpacing = element.firstOrEmpty("w:pPr").firstOrEmpty("w:spacing");

        if (Object.keys(wSpacing.attributes).length) {
            defaultSpacing = element.firstOrEmpty("w:pPr").firstOrEmpty("w:spacing");
        }
    });

    return defaultSpacing;
}

function readStyleFromElement(styleElement, docDefault) {
    var fontSizeString = styleElement.firstOrEmpty("w:rPr").firstOrEmpty("w:sz").attributes["w:val"];
    var fontSize = /^[0-9]+$/.test(fontSizeString) ? parseInt(fontSizeString, 10) / 2 : null;
    var docDefaults = getDefaultSpacing(docDefault);

    var spacingAfter = false;
    var spacingBefore = false;

    if (docDefaults) {
        spacingAfter = docDefaults.attributes["w:after"] ? true : false;
        spacingBefore = docDefaults.attributes["w:before"] ? true : false;
    }

    var wSpacing = styleElement.firstOrEmpty("w:pPr").firstOrEmpty("w:spacing");
    var wAfter = styleElement.firstOrEmpty("w:pPr").firstOrEmpty("w:spacing").attributes["w:after"];
    var wBefore = styleElement.firstOrEmpty("w:pPr").firstOrEmpty("w:spacing").attributes["w:before"];

    if (Object.keys(wSpacing.attributes).length) {
        if (wAfter) {
            if (wAfter === "0") {
                spacingAfter = false;
            } else {
                spacingAfter = true;
            }
        } else {
            // spacingAfter = false;
        }

        if (wBefore) {
            if (wBefore === "0") {
                spacingBefore = false;
            } else {
                spacingBefore = true;
            }
        } else {
            spacingBefore = false;
        }
    }

    var elementStyle = {
        verticalAlignment: styleElement.firstOrEmpty("w:rPr").firstOrEmpty("w:vertAlign").attributes["w:val"],
        font: styleElement.firstOrEmpty("w:rPr").firstOrEmpty("w:rFonts").attributes["w:ascii"],
        fontSize: fontSize,
        isBold: readBooleanElement(styleElement.firstOrEmpty("w:rPr").first("w:b")),
        isUnderline: readUnderline(styleElement.firstOrEmpty("w:rPr").first("w:u")),
        isItalic: readBooleanElement(styleElement.firstOrEmpty("w:rPr").first("w:i")),
        isStrikethrough: readBooleanElement(styleElement.firstOrEmpty("w:rPr").first("w:strike")),
        isAllCaps: readBooleanElement(styleElement.firstOrEmpty("w:rPr").first("w:caps")),
        isSmallCaps: readBooleanElement(styleElement.firstOrEmpty("w:rPr").first("w:smallCaps")),
        color: styleElement.firstOrEmpty("w:rPr").firstOrEmpty("w:color").attributes["w:val"],
        highlight: styleElement.firstOrEmpty("w:rPr").firstOrEmpty("w:highlight").attributes["w:val"],
        spacingAfter: spacingAfter,
        spacingBefore: spacingBefore,
    };
    return elementStyle;
}
