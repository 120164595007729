import { Box, Button, CircularProgress, Grid, Divider } from "@mui/material";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import BreadCrumb from "../Components/BreadCrumb";
import PageHeader from "../Components/Text/PageHeader";
import WhiteButton from "../Components/Button/WhiteButton";
import RedButton from "../Components/Button/RedButton";
import { FileUploadModel, GlobalProcedureTemplate } from "../Models";
import { ProcedureFileService } from "../Services";
import { FileType } from "../Common/Enums";
import DocumentPreview from "../Components/DocumentPreview";
import MyCustomSnackbar from "../Components/SnackBar/MyCustomSnackbar";
import IconButton from "../Components/Button/IconButton";
import BreadCrumbModel from "../Components/BreadCrumb/BreadCrumbModel";
import BackLinkButton from "../Components/Button/BackLinkButton";
import Utils from "../Common/Utils";


interface Props extends RouteComponentProps<any, any, any> { }

interface State {
  editTemplate: GlobalProcedureTemplate;
  documentUrl: string;
  open: boolean;
  breadCrumbs: BreadCrumbModel[];
  load: boolean;
  isError: boolean;
}

export class DocumentView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editTemplate: {
        templateName: "",
        basicPrinciples: "",
        bodyText: "",
        approver: "",
        approverName: "",
        approverEmail: "",
        effectiveDate: null,
        footer: "",
        footerImageFile: "",
        header: "",
        headerImageFile: "",
        owner: "",
        ownerName: "",
        ownerEmail: "",
        procedureType: "",
        versionNumber: "",
        _basicPrinciples: Utils.getEditorState(),
        _bodyText: Utils.getEditorState(),
        _headerImageFile: {} as FileUploadModel,
        _footerImageFile: {} as FileUploadModel,
        _gpTemplateFile: {} as FileUploadModel,
        _header: Utils.getEditorState(),
        policySubCommAppr:null,
      },
      isError: false,
      documentUrl: "",
      open: true,
      breadCrumbs: [
        {
          label: 'Procedure Template',
          onClick: () => {
            this.props.history.push('/procedure_templates');
          }
        },
        {
          label: 'Generate Procedure Template'
        }
      ],
      load: false

    };
  }

  componentDidMount(): void {
    console.log("history =", this.props.history);
    if (this.props.history.location.state.editTemplate) {
      this.setState({ editTemplate: JSON.parse(this.props.history.location.state.editTemplate) })
    }
    this.gettingDocUrl();
  }

  action = (
    <>
      {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ open: false });
        }}
      />
    </>
  );

  actionError = (
    <>
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ isError: false });
        }}
      />
    </>
  );
  render() {
    console.log("editTemplate docViewer=", this.state.editTemplate);
    // if (!this.state.documentUrl && !this.state.load) {
      return (
        <>
          {/* <Grid marginBottom={"2em"} container direction="row">
            <BreadCrumb breadCrumbs={this.state.breadCrumbs} />
          </Grid> */}
          <Grid
            container
            direction={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {this.props.history.length > 1 && <Grid item>
                  <BackLinkButton onClick={() => {
                    // window.location.assign(
                    //   `/view_template/${this.props.location.state?.editTemplate?.gpId}?mode=view`
                    // );

                    this.props.history.goBack()

                  }} />
                </Grid>}
                <Grid
                  item
                  sx={{
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    maxWidth: "48em",
                  }}
                >

                  <PageHeader
                    label={`TEMPLATE_PCoE_Pro_${JSON.parse(this.props.location.state?.editTemplate).templateName}_EN(v${JSON.parse(this.props.location.state?.editTemplate).versionNumber})`}
                  />

                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <WhiteButton label="Download" onClick={this.onDownloadClick} disabled={!this.state.documentUrl}/>
                </Grid>
                <Grid item>
                  <WhiteButton label="Edit"
                    onClick={() => {
                     this.props.history.push(
                        `/view_template/${JSON.parse(this.props.location.state?.editTemplate).gpId}?mode=edit`
                      );
                    }} />
                </Grid>
                {
                  this.state?.editTemplate?.procedureType !== "Global Procedure" &&
                  (<Grid item>
                    <RedButton label="Set Geography"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/setGeoGraphy",
                          state: { templateData: this.state.editTemplate }
                        })
                      }}
                    />
                  </Grid>)
                }

              </Grid>
            </Grid>
          </Grid>
          <Divider />
          {this.state.documentUrl ?
          <Grid>
          <DocumentPreview documentUrl={this.state.documentUrl} height="700px" />
          </Grid>
          :
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>

          }
        </>
      )

    }
    // return (
    //   <>
    //     <MyCustomSnackbar
    //       message="Procedure Template has been generated successfully."
    //       severity="success"
    //       actions={this.action}
    //       open={this.state.open}
    //       onClose={() => {
    //         this.setState({ open: false });
    //       }}
    //     />
    //     <MyCustomSnackbar
    //       actions={this.actionError}
    //       message="Download link has expired !! Please refresh the page to download."
    //       severity="warning"
    //       open={this.state.isError}
    //     />
    //     <Box
    //       style={{
    //         marginTop: "0.5em",
    //       }}
    //     >
    //       {/* <Grid marginBottom={"2em"} container direction="row">
    //         <BreadCrumb breadCrumbs={this.state.breadCrumbs} />
    //       </Grid> */}
    //       <Grid
    //         container
    //         direction={"row"}
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //           marginBottom: "1em",
    //           justifyContent: "space-between",
    //         }}
    //       >
    //         <Grid item>
    //           <Grid
    //             container
    //             spacing={2}
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //             }}
    //           >
    //             <Grid item>
    //               <BackLinkButton onClick={() => {
    //                 // window.location.assign(
    //                 //   `/view_template/${this.props.location.state?.editTemplate?.gpId}?mode=view`
    //                 // );

    //                 this.props.history.goBack()

    //               }} />
    //             </Grid>
    //             <Grid
    //               item
    //               sx={{
    //                 wordWrap: "break-word",
    //                 wordBreak: "break-all",
    //                 maxWidth: "48em",
    //               }}
    //             >

    //               <PageHeader
    //                 label={`TEMPLATE_PCoE_Pro_${this.props.location.state?.editTemplate.templateName}_EN(v${this.props.location.state?.editTemplate.versionNumber})`}
    //               />

    //             </Grid>
    //           </Grid>
    //         </Grid>
    //         <Grid item>
    //           <Grid container spacing={1}>
    //             <Grid item>
    //               <WhiteButton label="Download" onClick={this.onDownloadClick} />
    //             </Grid>
    //             <Grid item>
    //               <WhiteButton label="Edit"
    //                 onClick={() => {
    //                   window.location.assign(
    //                     `/view_template/${this.props.location.state?.editTemplate?.gpId}?mode=edit`
    //                   );
    //                 }} />
    //             </Grid>
    //             {
    //               this.state?.editTemplate?.procedureType !== "Global Procedure" &&
    //               (<Grid item>
    //                 <RedButton label="Set Geography"
    //                   onClick={() => {
    //                     this.props.history.push({
    //                       pathname: "/setGeoGraphy",
    //                       state: { templateData: this.state.editTemplate }
    //                     })
    //                   }}
    //                 />
    //               </Grid>)
    //             }
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       <Grid>
    //         <DocumentPreview documentUrl={this.state.documentUrl} height="700px" />
    //       </Grid>
    //     </Box>
    //   </>
    // )
  // }

  gettingDocUrl = async () => {
    try {
      if (this.props.location.state?.editTemplate) {
        const editTemplate = JSON.parse(this.props.location.state?.editTemplate);
        const data = await ProcedureFileService.docx2pdf(editTemplate.gpTemplateFile!, FileType.DOCX);
        this.setState({ documentUrl: data! });
      }
    } catch (error) {
      this.setState({ load: false });
      console.error("error getting DocURL", error);
    }
  };

  onDownloadClick = async () => {
    console.log("triigerr", this.props.location);
    if (this.props.location.state.generateSelectedValue === "word") {
      const docFile = await ProcedureFileService.getFile(
        JSON.parse(this.props.location.state?.editTemplate).gpTemplateFile!,
        FileType.DOCX
      )
      fetch(docFile!)
        .then((response) => {
          if (response.ok) {
            this.setState({ isError: false });
            return response.blob();
          }
          throw new Error('Network response was not OK.');
        })
        .then((blob) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `TEMPLATE_PCoE_Pro_${JSON.parse(this.props.location.state.editTemplate).templateName}_EN.docx`;
          document.body.appendChild(link); link.click();
          document.body.removeChild(link);
        }).catch((error) => {
          this.setState({ isError: true });
          console.error('Error:', error);
        });


    } else {
      fetch(this.state.documentUrl)
        .then((response) => {
          if (response.ok) {
            this.setState({ isError: false });
            return response.blob();
          }
          throw new Error('Network response was not OK.');
        })
        .then((blob) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `TEMPLATE_PCoE_Pro_${JSON.parse(this.props.location.state.editTemplate).templateName}_EN.pdf`;
          document.body.appendChild(link); link.click();
          document.body.removeChild(link);
        }).catch((error) => {
          this.setState({ isError: true });
          console.error('Error:', error);
        });

    }
  };
}

export default DocumentView;
