import React from 'react';
import { AppBar, Box, Grid, Tab, Tabs, Typography, Divider, Badge } from "@mui/material";
import { TabModel } from "./TabModel";


const TabLabelComponent: React.FC<{ tab: TabModel, isSelected: boolean, count?: any }> = ({ tab, isSelected, count }) => {
    return (
        <Grid container alignItems="center" justifyItems="center">
            <Grid item>
                <Typography style={{ fontSize: 15, fontWeight: 500, color: isSelected ? '#FFFFFF' : '#383838' }}>
                    {tab.name} 
                    <span style={{ marginLeft: 21 }}>{tab.count}</span>
                   
                    
{/* 
                    <Badge style={{ marginLeft: 21 }} sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: isSelected ? '#FF0000': '#DEDEDE',
                        },
                    }}
                        badgeContent={tab.count} max={count} color="error" /> */}

                </Typography>

            </Grid>
        </Grid>
    )
}

interface Props {
    currentTab: any;
    tabs: any;
    onTabChange: ((selectedTab: TabModel) => void);
    countObj?: any;
}
interface State { }

class TabBar extends React.Component<Props, State> {
    render() {
        const { tabs, currentTab } = this.props;
        return (
            <Box mt={2}>
                <AppBar
                    position='static'
                    color='default'
                    style={{ backgroundColor: '#F5F5F5' }}
                >
                    <Tabs
                        value={currentTab.index}
                        onChange={this.handleTabChange}

                        aria-label="Transactions Tabs"
                        TabIndicatorProps={{
                            style: {
                                display: 'none'


                            },
                        }}
                    >
                        {tabs?.map((tab: TabModel, i: any) => {
                            const tabProps = {
                                id: `full-width-tab-${i}`,
                                'aria-controls': `full-width-tabpanel-${i}`
                            };
                            const isSelected = currentTab.index === i;

                            const tabStyle: React.CSSProperties = {
                                backgroundColor: '#F5F5F5',
                                textTransform: 'none',
                                marginRight: 0,
                                border: '0px solid #D5D5D5',
                                borderRadius: '3px 3px 0px 0px',
                            };
                            if (isSelected) {
                                tabStyle.backgroundColor = '#D52B1E';
                                tabStyle.border = 'none';
                            }
                            return (
                                <Tab {...tabProps} key={tabProps.id}
                                    label={
                                        <TabLabelComponent
                                            tab={tab}
                                            count={this.props.countObj ? this.props.countObj[tab.type] : undefined}
                                            isSelected={isSelected}
                                        />
                                    }
                                    style={tabStyle} />
                            );
                        })}
                    </Tabs>
                </AppBar>
                <Divider orientation='horizontal' style={{ backgroundColor: '#D52B1E', height: 5, borderRadius: 'none' }} />
            </Box>
        )
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const { tabs } = this.props;

        const selectedTab = tabs?.find((p: { index: number; }) => p.index === newValue);

        if (selectedTab) {
            this.props.onTabChange(selectedTab);
        }
    }
}

export default TabBar;