import { TextRun, IRunOptions } from "docx";
import DocxUniqueIdAttribute from "./DocxUniqueIdAttribute";

export default class DocxTextRun extends TextRun {
    constructor(options: IRunOptions, uid: string | null) {
        super(options);

        if (uid) {
            this.root.push(new DocxUniqueIdAttribute({ uid }));
        }
    }
}