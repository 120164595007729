import { RESTClient } from '../Common/RESTClient';
import { ResponseModel } from '../Models';
import Utils from '../Common/Utils';
import PMPAPIService from "./PMPAPIService";
import { FileType } from '../Common/Enums';
import EmailDTO from '../Models/EmailDTO';



class EmailService extends PMPAPIService{

    // async sendEmail(params: any): Promise<string | null> {
    //     const response = await this.PostAsync<string>(
    //       `/emailer/sendemail`,
    //       params
    //     );
    
    //     let emailResponse: string | null = null;
    
    //     if (response.isSuccess) {
    //       emailResponse = response.data;
    //     }
    //     return emailResponse;
    //   }
      async sendEmail(Email: EmailDTO): Promise<EmailDTO[]> {
        console.log("sendEmail", Email);
        // CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
    
        const response = await this.PostAsync<EmailDTO[]>(
          "emailer/sendemail",
          Email
        );
    
        let email: EmailDTO[] = [];
    
        if (response.isSuccess) {
          email = response.data;
          // CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        }
    
        return email;
      }
}

const emailService = new EmailService();
export default emailService;