import React from "react";

interface Props { }
interface State { }
class Home extends React.Component<Props, State> {
    render(): React.ReactNode {
        return "Welcome to Home Page";
    }
}

export default Home;