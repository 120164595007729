import React from "react";
import { TextField, ClassNameMap } from "@mui/material";

interface Props {
    id: string;
    label: string;
    placeholder: string;
    isReadonly: boolean;
    isDisabled: boolean;
    isRequired: boolean;
    endAdornment?: React.ReactNode;
    classes?: Partial<ClassNameMap<string>>;
    value?: string | number;
    inputType: string;
    useDelay: boolean;
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void);
    onKeyDown?: ((event: React.KeyboardEvent<HTMLInputElement>) => void);
}
interface State {
    helperText: string;
    value: string;
}
class TextInputFieldDelay extends React.Component<Props, State> {
    public static defaultProps = {
        isReadonly: false,
        isDisabled: false,
        isRequired: true,
        inputType: 'text',
        useDelay: false,
        placeholder: '',
    };
    private timer?: NodeJS.Timeout = undefined;

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            helperText: '',
            value: props.value ? props.value.toString() : '',
        };
    }

    componentDidUpdate(prevProps: Props) {
        const { value: propsValue } = this.props;
        const { value: prevPropsValue } = prevProps;

        if (propsValue !== undefined && propsValue !== prevPropsValue) {
            this.setState({ value: propsValue ? propsValue.toString() : '' });
        }
    }

    render() {
        const { id, label, isReadonly, isDisabled, isRequired, endAdornment, classes, inputType, placeholder, onKeyDown } = this.props;
        const { helperText, value } = this.state;

        return (
            <TextField type={inputType} size="small" variant="outlined" fullWidth required={isRequired} helperText={helperText}
                InputProps={{ readOnly: isReadonly, endAdornment, classes }} disabled={isDisabled}
                id={id} label={label} placeholder={placeholder} value={value} onChange={this.handleChange} onKeyDown={onKeyDown} />
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const { useDelay } = this.props;

        if (useDelay) {
            if (this.timer) {
                clearTimeout(this.timer);
            }

            this.setState({ value, helperText: '' }, () => {
                this.timer = setTimeout(() => {
                    this.props.onChange(event);
                },);
            });
        } else {
            this.setState({ value, helperText: '' }, () => {
                this.props.onChange(event);
            });
        }
    }
}

export default TextInputFieldDelay;