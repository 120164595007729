import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

class PublicAPIService {
    private readonly _config: AxiosRequestConfig;

    constructor(baseURL: string = '') {
        this._config = {
            baseURL: baseURL,
        };
    }

    async GetAsync<T>(path: string): Promise<AxiosResponse<T>> {
        const response = await axios.get<T>(path, this._config);
        return response;
    }

    async PutAsync<T>(path: string, headers: any, data: any): Promise<AxiosResponse<T>> {
        let config: AxiosRequestConfig = {
            baseURL: this._config.baseURL,
            headers,
        };

        const response = await axios.put<T>(path, data, config);
        return response;
    }

    async DeleteAsync<T>(path: string): Promise<AxiosResponse<T>> {
        const response = await axios.delete<T>(path, this._config);
        return response;
    }
}

export default PublicAPIService;