import { FileType } from "../../Common/Enums";
import { QDocsListModel } from "../../Models";

export default class FileUploadModel {
    name: string = '';
    sizeInKB?: number = 0;
    sizeInMB?: number = 0;
    type?: FileType;
    ext?: string = '';
    file?: File | null = null;
    fileStatus: 'LOCAL' | 'QDOCS' = 'LOCAL';
    qDocs?: QDocsListModel;
}