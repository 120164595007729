import { DocumentType } from "../Common/Enums";
import Utils from "../Common/Utils";
import { CleanFileDTO, CompareDocxModel, FileDTO, GlobalProcedureTemplateFile } from "../Models";
import FileUploadModel from "../Models/FileUploadModel";
import FileUploadModelForLP from "../Models/FileUploadModelForLP";
import { FileUploadForTranslated, FileUploadForTranslatedWithoutApproval } from "../Models/FileUploadModelForTranslated";
import { LocalProcedureTemplateFile } from "../Models/LocalProcedureTemplateFile";
import { UploadTranslated, UploadTranslatedWithoutApproval } from "../Models/UploadTranslatedFile";
import PMPAPIService from "./PMPAPIService";
import S3FileService from "./S3FileService";

class ProcedureFileService extends PMPAPIService {
    async uploadHeaderLogo(file: FileUploadModel) {
        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.s3filekey}`,
            fileType: file.type,
        };


        const filePreSignedResponse = await this.PostAsync<FileDTO>('fileupload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            if (file.file) {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let gpTemplateFileDTO: GlobalProcedureTemplateFile = {
                        gpfId: 0,
                        gpId: file.gpId,
                        fileName: fileNameWithExt,
                        fileType: Utils.getFileTypeNumber(file.type),
                        documentType: DocumentType.GPT_HEADER,
                        s3FilePathKey: fileObj.key,
                        fileVersion: file.fileversion,
                    };

                    return gpTemplateFileDTO;
                }
            } else {
                throw new Error('No file to upload');
            }
        } else {
            console.log('uploadHeaderLogo', filePreSignedResponse);
        }

        return null;
    }
    async uploadHeaderLogoForLP(file: FileUploadModelForLP) {
        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.s3filekey}`,
            fileType: file.type,
        };


        const filePreSignedResponse = await this.PostAsync<FileDTO>('fileupload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            if (file.file) {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let lpTemplateFileDTO: LocalProcedureTemplateFile = {
                        lpfId: 0,
                        lpId: file.lpId,
                        fileName: fileNameWithExt,
                        fileType: Utils.getFileTypeNumber(file.type),
                        documentType: DocumentType.GPT_HEADER,
                        s3FilePathKey: fileObj.key,
                        fileVersion: file.fileversion!,
                    };

                    return lpTemplateFileDTO;
                }
            } else {
                throw new Error('No file to upload');
            }
        } else {
            console.log('uploadHeaderLogo', filePreSignedResponse);
        }

        return null;
    }

    async uploadFooterLogo(file: FileUploadModel) {
        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.s3filekey}`,
            fileType: file.type,
        };

        const filePreSignedResponse = await this.PostAsync<FileDTO>('fileupload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            if (file.file) {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let gpTemplateFileDTO: GlobalProcedureTemplateFile = {
                        gpfId: 0,
                        gpId: file.gpId,
                        fileName: fileNameWithExt,
                        fileType: Utils.getFileTypeNumber(file.type),
                        documentType: DocumentType.GPT_FOOTER,
                        s3FilePathKey: fileObj.key,
                        fileVersion: file.fileversion,
                    };

                    return gpTemplateFileDTO;
                }
            } else {
                throw new Error('No file to upload');
            }
        } else {
            console.log('uploadFooterLogo', filePreSignedResponse);
        }

        return null;
    }
    async uploadFooterLogoForLP(file: FileUploadModelForLP) {
        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.s3filekey}`,
            fileType: file.type,
        };

        const filePreSignedResponse = await this.PostAsync<FileDTO>('fileupload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            if (file.file) {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let lpTemplateFileDTO: LocalProcedureTemplateFile = {
                        lpfId: 0,
                        lpId: file.lpId,
                        fileName: fileNameWithExt,
                        fileType: Utils.getFileTypeNumber(file.type),
                        documentType: DocumentType.GPT_FOOTER,
                        s3FilePathKey: fileObj.key,
                        fileVersion: file.fileversion!,
                    };

                    return lpTemplateFileDTO;
                }
            } else {
                throw new Error('No file to upload');
            }
        } else {
            console.log('uploadFooterLogo', filePreSignedResponse);
        }

        return null;
    }

    async uploadDocument(file: FileUploadModel) {
        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.s3filekey}`,
            fileType: file.type,
        };

        const filePreSignedResponse = await this.PostAsync<FileDTO>('fileupload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            if (file.file) {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let gpTemplateFileDTO: GlobalProcedureTemplateFile = {
                        gpfId: 0,
                        gpId: file.gpId,
                        fileName: fileNameWithExt,
                        fileType: Utils.getFileTypeNumber(file.type),
                        documentType: DocumentType.GPT_DOCUMENT,
                        s3FilePathKey: fileObj.key,
                        fileVersion: file.fileversion,
                    };

                    return gpTemplateFileDTO;
                }
            } else {
                throw new Error('No file to upload');
            }
        } else {
            console.log('uploadDocument', filePreSignedResponse);
        }

        return null;
    }
    async uploadDocumentForLP(file: FileUploadModelForLP) {
        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.s3filekey}`,
            fileType: file.type,
        };

        const filePreSignedResponse = await this.PostAsync<FileDTO>('fileupload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            if (file.file) {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let lpTemplateFileDTO: LocalProcedureTemplateFile = {
                        lpfId: 0,
                        lpId: file.lpId,
                        fileName: fileNameWithExt,
                        fileType: Utils.getFileTypeNumber(file.type),
                        documentType: DocumentType.GPT_DOCUMENT,
                        s3FilePathKey: fileObj.key,
                        fileVersion: file.fileversion!,
                    };

                    return lpTemplateFileDTO;
                }
            } else {
                throw new Error('No file to upload');
            }
        } else {
            console.log('uploadDocument', filePreSignedResponse);
        }

        return null;
    }

    async getFile(key: string, fileType: string): Promise<string | null> {
        const fileObj = {
            key,
            fileType,
        };
        const filePreSignedResponse = await this.PostAsync<FileDTO>('filedownload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            return filePreSignedResponse.data.presigned_url;
        }
        return null;
    }
    async getPresignedUrl(key: string, fileType: string): Promise<string | null> {
        const fileObj = {
            key,
            fileType,
        };
        const filePreSignedResponse = await this.PostAsync<FileDTO>('generatepresignedurl', fileObj);
        console.log("genertaedurl", filePreSignedResponse)
        if (filePreSignedResponse.isSuccess) {
            return filePreSignedResponse.data.presigned_url;
        }
        return null;
    }

    async docx2pdf(key: string, fileType: string): Promise<string | null> {
        const fileObj = {
            key,
            fileType,
        };
        const filePreSignedResponse = await this.PostAsync<FileDTO>('docx2pdf', fileObj);

        if (filePreSignedResponse.isSuccess) {
            console.log("presigned_url", filePreSignedResponse)
            return filePreSignedResponse.data.presigned_url;
        }
        return null;
    }

    async cleandocument(key: string, fileType: string): Promise<string | null> {
        const fileObj = {
            key,
            fileType,
        };
        const filePathResponse = await this.PostAsync<CleanFileDTO>('cleandocument', fileObj);

        if (filePathResponse.isSuccess) {
            console.log("clean file path", filePathResponse)
            return filePathResponse.data.filepath;
        }
        return null;
    }

    async comparedocx(key1: string, key2: string): Promise<CompareDocxModel | null> {
        const fileObj = {
            key1,
            key2,
        };
        const response = await this.PostAsync<CompareDocxModel>('comparedocx', fileObj);

        if (response.isSuccess) {
            return response.data;
        }

        return null;
    }

    async comparepdf(key1: string, key2: string, procedureType: string): Promise<CompareDocxModel | null> {
        const fileObj = {
            key1,
            key2,
            procedureType,
        };
        const response = await this.PostAsync<CompareDocxModel>('comparepdf', fileObj);

        if (response.isSuccess) {
            return response.data;
        }

        return null;
    }

    async uploadLpApprovalFileUpload(file: FileUploadForTranslated) {
        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.s3filekey}`,
            fileType: file.type,

        };
        console.log("fileObj",fileObj);
        const filePreSignedResponse = await this.PostAsync<FileDTO>('fileupload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            if (file.file) {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let lpUploadTranslated: UploadTranslated = {
                        lpfuId: 0,
                        lpId: file.lpId!,
                        fileName: fileNameWithExt,
                        fileType: Utils.getFileTypeNumber(file.type),
                        language: file.language,
                        approvalDate: file.approvalDate,
                        approverName: file.approverName,
                        s3FilePathKey: fileObj.key,
                    };

                    return lpUploadTranslated;
                }
            } else {
                throw new Error('No file to upload');
            }
        } else {
            console.log('uploadDocument', filePreSignedResponse);
        }

        return null;
    }
    async uploadLpFileUpload(file: FileUploadForTranslatedWithoutApproval) {
        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.s3filekey}`,
            fileType: file.type,

        };
        console.log("fileObj",fileObj);
        const filePreSignedResponse = await this.PostAsync<FileDTO>('fileupload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            if (file.file) {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let lpUploadTranslated: UploadTranslatedWithoutApproval = {
                        tlpfuId: 0,
                        lpId: file.lpId!,
                        fileName: fileNameWithExt,
                        fileType:  Utils.getFileTypeNumber(file.type),
                        language: file.language,
                     
                        s3FilePathKey: fileObj.key,
                    };

                    return lpUploadTranslated;
                }
            } else {
                throw new Error('No file to upload');
            }
        } else {
            console.log('uploadDocument', filePreSignedResponse);
        }

        return null;
    }

    async uploadLpApprovalFileUploadTableEntry(obj: UploadTranslated) {
        const response = await this.PostAsync('/localproceduretemplate/lpapprovalfileupload', obj);

        let responseLPTUF = null;

        if (response.isSuccess) {
            responseLPTUF = response.data
        }
        return responseLPTUF;
    }
    async uploadLpFileUploadTableEntry(obj: UploadTranslatedWithoutApproval) {
        const response = await this.PostAsync('/localproceduretemplate/lpfileupload', obj);

        let responseLPTUF = null;

        if (response.isSuccess) {
            responseLPTUF = response.data
        }
        return responseLPTUF;
    }
}

const procedureFileService = new ProcedureFileService();
export default procedureFileService;