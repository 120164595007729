import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import BreadCrumb from "../../Components/BreadCrumb";
import PageHeader from "../../Components/Text/PageHeader";
import WhiteButton from "../../Components/Button/WhiteButton";
import { EmailDTO, LocalProcedureTemplate } from "../../Models";
import { AuthProviderService, LPTService, ProcedureFileService } from "../../Services";
import { FileType } from "../../Common/Enums";
import DocumentPreview from "../../Components/DocumentPreview";
import MyCustomSnackbar from "../../Components/SnackBar/MyCustomSnackbar";
import IconButton from "../../Components/Button/IconButton";
import BreadCrumbModel from "../../Components/BreadCrumb/BreadCrumbModel";
import RedButton from "../../Components/Button/RedButton";
import { faArrowUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import BackLinkButton from "../../Components/Button/BackLinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lptService from "../../Services/LPTService";
import { LocalProcedureTemplateSection } from "../../Models/LocalProcedureTemplateSection";
import EmailService from "../../Services/EmailService";
import { LocalProcedureApprovalFileUpload } from "../../Models/LocalProcedureApprovalFileUpload";
import { type } from "os";
import TextInputField from "../../Components/TextInputField/TextInputField";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInput";
import Utils from "../../Common/Utils";

interface Props extends RouteComponentProps<any, any, any> { }

interface State {
  documentUrl: string;
  open: boolean;
  receivedlpId: number;
  pageHeader: string | undefined;
  breadCrumbs1: BreadCrumbModel[];
  breadCrumbs2: BreadCrumbModel[];
  load: boolean;
  isSent: boolean;
  RejectDialog: boolean;
  ApproveDialog: boolean;
  isNoSelected: boolean;
  isError:boolean;
  isApproved: boolean;
  isRejected: boolean;
  status: string;
  reasonForRejection: string;
  dataFromLpId: any;
  loggedInEmail: string;
  translatedFileDetail: {
    lpfuId: number,
    lpId: number,
    fileName: string,
    s3FilePathKey: string,
    fileType: number,
    language: string,
    status: string,
    createdDate: any,
    updatedDate: any,
  };
}

class ApproverDocumentView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      documentUrl: "",
      pageHeader: "",
      status:"",
      dataFromLpId:"",
      reasonForRejection: "",
      receivedlpId:0,
      loggedInEmail: "",
      translatedFileDetail:{
        lpfuId: 0,
        lpId: 0,
        fileName: "",
        s3FilePathKey: "",
        fileType: 0,
        language: "",
        status: "",
        createdDate: null,
        updatedDate: null
      },
      open: false,
      isSent: false,
      RejectDialog:false,
      ApproveDialog:false,
      isNoSelected:false,
      isError:false,
      isApproved: false,
      isRejected: false,
      load: false,
      breadCrumbs1: [
        {
          label: 'Procedure Approval',
          onClick: () => {
            this.props.history.goBack();
          }
        },
        {
          label: 'View Procedure'
        }
      ],
      breadCrumbs2: [
        {
          label: 'Local Procedure',
          onClick: () => {
            this.props.history.push('/procedure_exception');
          }
        },
        {
          label: 'View Procedure'
        }
      ],
    };
  }

  async componentDidMount(): Promise<void> {
    console.log("received lpid",this.props.match.params.id);    
    console.log("history ==",this.props.history);    
    const receivedlpId =  Number(this.props.match.params.id)
    this.setState({receivedlpId: receivedlpId})
    this.gettingDocUrl(receivedlpId);
    const dataFromLpId = await lptService.getByLPId(receivedlpId);
    console.log("data from lpid",dataFromLpId);
    
    const { accessToken } = await AuthProviderService.getAccessToken();
    const loggedInUserToken = Utils.decodeJWT<any>(accessToken);
    this.setState({ loggedInEmail: loggedInUserToken.preferred_username })
    if (dataFromLpId!.localProcedure.accessedDate === null){
      const accessedDate = await lptService.approverProcedureAccess(receivedlpId)
      console.log("Access date",accessedDate);
    }
  }

  action = (
    <>
      {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ open: false, isError: false });
        }}
      />
    </>
  );

  actions = (
    <>
    </>
  );
  render() {
    console.log("props", this.props);
    if (!this.state.documentUrl && !this.state.load) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    return (
      <>
        <MyCustomSnackbar
              actions={this.action}
              message="Error"
              severity="warning"
              open={this.state.isError}
        />
        <MyCustomSnackbar
          message="Procedure has been approved successfully."
          severity="success"
          open={this.state.isApproved}
        />
        <MyCustomSnackbar
          message="Procedure has been Rejected."
          severity="warning"
          open={this.state.isRejected}
        />
        <MyCustomSnackbar
              message="Procedure sent For approval successfully."
              severity="success"
              actions={this.actions}
              open={this.state.isSent}
              onClose={() => {
                this.setState({ isSent: false });
              }}
            />
        <Box
          style={{
            marginTop: "0.5em",
          }}
        >
          <Grid
            container
            direction={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={7}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {this.props.history.length > 1 &&<Grid item>
                  <BackLinkButton
                    onClick={() => {
                      // this.props.history.push(
                      //   `/eco_localization/${this.props.location.state?.editTemplate?.gpId}?mode=view`
                      // );

                      this.props.history.goBack();

                    }}
                  />
                </Grid>}
                <Grid
                  item xs={10}
                  sx={{
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    // maxWidth: "48em",
                  }}
                >
                 <PageHeader
                    label={`${this.state.pageHeader}`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                {this.state.translatedFileDetail.s3FilePathKey !== "" ?
                  <Grid item>
                    <RedButton
                      label="View Translated Procedure"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/approver_translated_doc_viewer",
                          state: {
                            translatedFileDetail: this.state.translatedFileDetail,
                          },
                        });
                      }}
                    />
                  </Grid> : null}
                {this.state.status === 'Pending Approval' ?
                  <>
                    {this.state.loggedInEmail === this.state.dataFromLpId?.approverEmail ?
                      <>
                        <Grid item>
                          <RedButton
                            label="Adherence Report"
                            onClick={() => {
                              this.getAdherenceReportUrl()
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <RedButton
                            label="Approve"
                            onClick={() => {
                              this.setState({ ApproveDialog: true })
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <WhiteButton
                            label="Reject"
                            onClick={() => {
                              this.setState({ RejectDialog: true })
                            }}
                          />
                        </Grid>
                        <Grid item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5em"
                          }}>
                          <WhiteButton label="Cancel" onClick={() => {
                            this.props.history.push('/approve_procedure');
                          }} />
                        </Grid>

                      </>
                      : <Grid item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em"
                      }}>
                      <WhiteButton label="Cancel" onClick={() => {
                        this.props.history.push('/approve_procedure');
                      }} />
                    </Grid>}
                    {/* comment this section */}
                    {/* <Grid item>
                      <RedButton
                        label="Adherence Report"
                        onClick={() => {
                          this.getAdherenceReportUrl()
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <RedButton
                        label="Approve"
                        onClick={() => {
                          this.setState({ ApproveDialog: true })
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <WhiteButton
                        label="Reject"
                        onClick={() => {
                          this.setState({ RejectDialog: true })
                        }}
                      />
                    </Grid> */}

                    {/* comment till here */}
                  </> :
                  <Grid item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5em"
                    }}>
                    <WhiteButton label="Cancel" onClick={() => {
                      // this.props.history.goBack();
                      this.props.history.push('/approve_procedure');
                    }} />
                  </Grid>}

              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Dialog open={this.state.RejectDialog} fullWidth={true} maxWidth='md'>
            <Grid
              container
              direction={"row"}
              style={{
                display: "flex",
                alignItems: "center",
                // marginBottom: "1em",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <DialogTitle color={"red"}>
                  Rejection: Global Ethics and Compliance Procedure
                </DialogTitle>
              </Grid>
              <Grid item>
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  onClick={() => {
                    this.setState({ RejectDialog: false });
                  }}
                  style={{ marginRight: "1em", cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <DialogContent dividers>
              <Typography variant="body1" fontWeight="bold" marginTop={2}>
                Reason for Rejection
              </Typography>
              <TextAreaInputField
                placeholder="Enter your reason (optional)"
                onChange ={
                  this.onRejectionInputChange
                }
              />

              <DialogContent>
                <Grid container mt={2} ml={-3}>
                  <Grid item mr={2}>
                    <WhiteButton
                      label="Close"
                      onClick={() => {
                        this.setState({ RejectDialog: false });
                      }}
                    />
                  </Grid>
                  <Grid item >
                    <RedButton
                      label="Reject"
                      style={{
                        textTransform: "capitalize"
                      }}
                      onClick={this.onReject
                        
                        // this.props.history.push({
                        //   pathname: `/file_upload`, state: {
                        //     editTemplate: this.props.location?.state?.editTemplate,
                        //     lpId: this.props.location?.state?.lpId,
                        //     isUploaded: this.props.location.state.isUploaded
                        //   }
                        // });

                      }
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </DialogContent>
          </Dialog>
          <Dialog open={this.state.ApproveDialog}  maxWidth='md'>
            <Grid
              container
              direction={"row"}
              style={{
                display: "flex",
                alignItems: "center",
                // marginBottom: "1em",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <DialogTitle color={"red"}>
                  Approval: Global Ethics and Compliance Procedure
                </DialogTitle>
              </Grid>
              <Grid item>
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  onClick={() => {
                    this.setState({ ApproveDialog: false });
                  }}
                  style={{ marginRight: "1em", cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <DialogContent dividers>
              <Typography variant="body1" fontWeight="bold" marginTop={2}>
                Are you sure you want to approve? 
              </Typography>
              <DialogContent>
                <Grid container mt={3} ml={-3}>
                  <Grid item mr={2}>
                    <WhiteButton
                      label="Close"
                      onClick={() => {
                        this.setState({ ApproveDialog: false });
                      }}
                    />
                  </Grid>
                  <Grid item >
                    <RedButton
                      label="Approve"
                      style={{
                        textTransform: "capitalize"
                      }}
                      onClick={
                        this.onApprove
                      }
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </DialogContent>
          </Dialog>
          <Grid>
            <DocumentPreview documentUrl={this.state.documentUrl} height="700px" />
          </Grid>

        </Box>
        </>
    )

  }

  

  getTranslatedFileUrl = async () => {
    this.setState({ load: true });
    // if (this.props.location.state?.templateData?.s3FilePathKey) {
      try {
          
        
        const data = await ProcedureFileService.docx2pdf(this.state.translatedFileDetail.s3FilePathKey, FileType.DOCX);
        console.log("url", data);
        this.setState({ documentUrl: data!, load: false });
      } catch (error) {
        this.setState({ load: false });
        console.error(error)
      }
  };

  getAdherenceReportUrl = async () => {
    // this.setState({ load: true });
    // if (this.props.location.state?.templateData?.s3FilePathKey) {
      try {
          
        console.log(this.state.dataFromLpId);
        const docurl = this.state.dataFromLpId.ipFilePath;
        // Extract the directory path and append the new filename
        const directoryPath = docurl.substring(0, docurl.lastIndexOf("/") + 1);
        const newFileName = "AdherenceReport.pdf";
        const ARPath = directoryPath + newFileName;
        
        const data = await ProcedureFileService.getPresignedUrl(ARPath, FileType.PDF);
        console.log("url", data);
        this.setState({ documentUrl: data!, load: false });
      } catch (error) {
        this.setState({ load: false });
        console.error(error)
      }
  };

  gettingDocUrl = async (receivedlpId: number) => {
    const dataFromLpId = await lptService.getByLPId(receivedlpId);
    this.setState({dataFromLpId: dataFromLpId?.localProcedure! },()=>{
      this.setState({pageHeader: `${this.state.dataFromLpId.templateName}_${this.state.dataFromLpId.geoGraphyName}(v${this.state.dataFromLpId.versionNumber})` })
      console.log("page header", this.state.pageHeader);
      
    })
    console.log("page header", this.state.pageHeader);
    console.log("data",dataFromLpId);

    console.log("data received by approver doc viewer", this.props.location.state?.templateData);
    this.setState({status: dataFromLpId?.localProcedure.status!})
    if (dataFromLpId?.localProcedure.ipFilePath) {
      try {
        const cleanDoc = dataFromLpId?.localProcedure.ipFilePath!.split(".docx")
        const cleanDocPath = cleanDoc[0] + "_clean.pdf"
        const data = await ProcedureFileService.getPresignedUrl(cleanDocPath, FileType.PDF);
        console.log("url", data);
        this.setState({ documentUrl: data! });
      } catch (error) {
        this.setState({ load: false });
        console.error(error)
      }
    }
    if (dataFromLpId?.localProcedureFileUpload){
      if(dataFromLpId?.localProcedureFileUpload.length > 0){
        // Find the highest 'lpfuid' value using reduce
      const highestLpfuidEntry = dataFromLpId.localProcedureFileUpload.reduce(
      (acc, curr) => (curr.lpfuId > acc.lpfuId ? curr : acc)      
      )
      console.log("highlest lpfuid",highestLpfuidEntry);
      this.setState({translatedFileDetail: highestLpfuidEntry})
    }
    }
    
    
    // if (this.props.location.state?.templateData?.ipFilePath) {
    //   try {
    //     const data = await ProcedureFileService.docx2pdf(this.props.location.state?.templateData?.ipFilePath!, FileType.DOCX);
    //     console.log("url", data);
    //     this.setState({ documentUrl: data! });
    //   } catch (error) {
    //     this.setState({ load: false });
    //     console.error(error)
    //   }
    // }
    // else {
    //   try {
    //     const data = await ProcedureFileService.docx2pdf(this.props.location.state?.editTemplate?.ipFilePath!, FileType.DOCX);
    //     console.log("url", data);
    //     this.setState({ documentUrl: data! });
    //   } catch (error) {
    //     this.setState({ load: false });
    //     console.error(error)
    //   }
    // }


  };

  onRejectionInputChange = (event: any) => {
    this.setState({reasonForRejection: event.target.value})
  }

  onReject = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    // this.setState({RejectDialog:true})
    const rejectLPT = await lptService.rejectProcedure(this.state.receivedlpId,this.state.reasonForRejection)
    if(rejectLPT){
      const env = Utils.getEnvVars();
      const emailObj: EmailDTO = {
        to: this.state.dataFromLpId.modifyByEmail!.toLowerCase(),
        cc: "",
        subject: `${this.state.dataFromLpId.templateName} Procedure Review`,
        body: ` <p><a href="${env.UI_URL}/eco_localization/${this.state.dataFromLpId.lpId}?mode=view">Click here to view procedure.</a></p>
              <p>Hi ${this.state.dataFromLpId.modifyBy},</p>
              <p>
                As the local procedure author, you are receiving this message regarding ${this.state.dataFromLpId.templateName} procedure, version ${this.state.dataFromLpId.versionNumber}, for ${this.state.dataFromLpId.geoGraphyName}.
              </p>
              <p>
                This procedure has been <b>rejected</b> by ${this.state.dataFromLpId.approverName}. 
              </p>
              <p>
                As the procedure has been rejected, please follow up to make appropriate updates and re-send for approval.
                To view status of local procedures, you can access the <a href="${env.UI_URL}/local_procedures">dashboard</a>.
              </p>
              <p>
                Thank you for your time and diligence in completing updates of this procedure.   
              </p>
              <p>
                Regards,  
              </p>
              <p>
                Ethics and Compliance Policy Center of Expertise 
                <br>
                <b><i>DO NOT RESPOND TO THE SENDER OF THIS MESSAGE</i></b>
              </p>`
        // body: "testing"
      };
      await EmailService.sendEmail(emailObj);
      this.setState({ isRejected: true, RejectDialog: false }, () => {
        setTimeout( () => {
          // this.props.history.goBack()
          this.props.history.push(`/approve_procedure`)
        },2000) 
      })
    } else {
      this.setState({isError : true});
    }
    
  }

  onApprove = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    this.setState({ApproveDialog: false})
    const approveLPT = await lptService.approveProcedure(this.state.receivedlpId)
    if(approveLPT){
      const env = Utils.getEnvVars();
      const emailObj: EmailDTO = {
        to: this.state.dataFromLpId.modifyByEmail!.toLowerCase(),
        cc: "",
        subject: `${this.state.dataFromLpId.templateName} Procedure Review`,
        body: ` <p><a href="${env.UI_URL}/eco_doc_viewer_id/${this.state.dataFromLpId.lpId}">Click here to view procedure.</a></p>
              <p>Hi ${this.state.dataFromLpId.modifyBy},</p>
              <p>
                As the local procedure author, you are receiving this message regarding <b>${this.state.dataFromLpId.templateName}</b> procedure, version ${this.state.dataFromLpId.versionNumber}, for ${this.state.dataFromLpId.geoGraphyName}.
              </p>
              <p>
                This procedure has been <b>approved</b> by <b>${this.state.dataFromLpId.approverName}</b>. 
              </p>
              <p>
                As the procedure is now approved, please download the PDF version and upload to Quality Docs.
                To view status of local procedures, you can access the <a href="${env.UI_URL}/local_procedures">dashboard</a>.
              </p>
              <p>
                Thank you for your time and diligence in completing updates of this procedure.   
              </p>
              <p>
                Regards,  
              </p>
              <p>
                Ethics and Compliance Policy Center of Expertise 
                <br>
                <b><i>DO NOT RESPOND TO THE SENDER OF THIS MESSAGE</i></b>
              </p>`
        // body: "testing"
      };
      await EmailService.sendEmail(emailObj);
      this.setState({ isApproved: true }, () => {
        setTimeout( () => {
          // this.props.history.goBack()
          this.props.history.push(`/approve_procedure`)
        },2500) 
      })
    } else {
      this.setState({isError : true});
    }
  }


  onDownloadClick = async () => {
    console.log("triigerr", this.props.location);
    if (this.props.location.state.generateSelectedValue === "word") {
      const filePath = await ProcedureFileService.cleandocument(
        this.props.location.state?.editTemplate?.ipFilePath!,
        FileType.DOCX
      )

      const docFile = await ProcedureFileService.getFile(
        filePath!,
        FileType.DOCX
      )
      fetch(docFile!)
      .then( (response) => {
        if (response.ok) {
          this.setState({isError : false});
          return response.blob();
        }
        throw new Error('Network response was not OK.');
      })
      .then( (blob) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.props.location.state.editTemplate.templateName}_${this.props.location.state.editTemplate.geoGraphyName}.docx`;
        document.body.appendChild(link); link.click(); 
        document.body.removeChild(link);
      }).catch( (error) => {
        this.setState({isError : true});
        console.error('Error:', error); 
      });
      
    } else {
      fetch(this.state.documentUrl)
        .then( (response) => {
          if (response.ok) {
            this.setState({isError : false});
            return response.blob();
          }
          throw new Error('Network response was not OK.');
        })
        .then((blob) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.props.location.state.editTemplate.templateName}_${this.props.location.state.editTemplate.geoGraphyName}.pdf`;
          document.body.appendChild(link); link.click();
          document.body.removeChild(link);
        }).catch( (error) => {
          this.setState({isError : true});
          console.error('Error:', error);
        });

    }
  };

  onWordDownloadClick = async () => {
    console.log("triigerr", this.props.location);    
      const docFile = await ProcedureFileService.getFile(
        this.props.location.state?.templateData?.ipFilePath,
        FileType.DOCX
      )
      fetch(docFile!)
      .then( (response) => {
        if (response.ok) {
          // this.setState({isError : false});
          return response.blob();
        }
        throw new Error('Network response was not OK.');
      })
      .then( (blob) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.props.location.state?.templateData?.templateName}.docx`;
        document.body.appendChild(link); link.click(); 
        document.body.removeChild(link);
      }).catch( (error) => { 
        // this.setState({isError : true});
        console.error('Error:', error); 
      });
      
    
      
    
  };

  onPdfDownloadClick = async () => {
    console.log("triigerr", this.props.location);
      fetch(this.state.documentUrl)
      .then( (response) => {
        if (response.ok) {
          // this.setState({isError : false});
          return response.blob();
        }
        throw new Error('Network response was not OK.');
      })
      .then( (blob) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.props.location.state.templateData.templateName}.pdf`;
        document.body.appendChild(link); link.click(); 
        document.body.removeChild(link);
      }).catch( (error) => { 
        // this.setState({isError : true});
        console.error('Error:', error); 
      }); 
  };
}

export default ApproverDocumentView;