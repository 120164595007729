import React from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Divider
}
    from "@mui/material";
import IconButton from '../Button/IconButton';
import { FileUploadForTranslated } from '../../Models/FileUploadModelForTranslated';



interface Props {
    files: FileUploadForTranslated;
    tableData: any;
    isFR: boolean;
    deleted: boolean;
    onDeleteClick: () => void;
}

interface State {
    isTableDisabled: boolean;
    isRemoved: boolean;
    page: number;
    pages: number;
    rowsPerPage: number;
}

class FileUploadDetailCard extends React.Component<Props, State> {
    public static defaultProps = {
        isFR: false,
        // showDelete: true,
    };

    constructor(props: Props | Readonly<Props>) {
        super(props)

        this.state = {
            isTableDisabled: false,
            isRemoved: this.props.deleted,
            page: 0,
            pages: 0,
            rowsPerPage: 5,
        }
    }
    render() {
        const { files } = this.props;
        const { isRemoved } = this.state;
        console.log("detail card", this.props.deleted );
        return (
            <Grid  >
                <Grid
                    item xs={12}>
                    {this.props.deleted ? <></> :
                        <Card style={{ border: '1px solid #DDDDDD', boxShadow: '0px 2px 6px 0px', borderRadius: '6px', opacity: 1, marginTop: '2rem' }}>
                            <CardContent>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item >
                                        <Typography style={{ fontSize: 16, fontWeight: 400, color: '#0075A2', cursor: 'default' }} noWrap>
                                            {files.name}
                                        </Typography>
                                    </Grid>
                                    <Divider orientation='vertical' style={{ borderRightWidth: 2 }} />
                                    <Grid item marginLeft={2}>
                                        <IconButton IconType='Delete' onClick={this.handledeleteClick} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    }
                </Grid>

            </Grid>
        );
    }

    handledeleteClick = () => {
        this.props.onDeleteClick()
        // this.setState({ isRemoved: true });
    }


}

export default FileUploadDetailCard;