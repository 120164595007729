import { Component } from "react";
import { Button, Snackbar, SnackbarOrigin } from "@mui/material";
import { Alert, AlertProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "../Button/IconButton";

const MySnackbar = styled(Snackbar)(({ theme: Theme }) => ({
  "& .MuiAlert-root": {
    width: "100%",
  },
}));

interface SnackbarProps {
  message: string;
  severity: AlertProps["severity"];
  open: boolean;
  onClose?: () => void;
  onClick?: () => void;
  actions?:  React.ReactNode
}

interface State extends SnackbarOrigin {}

class MyCustomSnackbar extends Component<SnackbarProps, State> {
  constructor(props: SnackbarProps | Readonly<SnackbarProps>) {
    super(props);
    this.state = {
      vertical: "top",
      horizontal: "center",
    };
  }

  action = (
    <>
      <Button color="error" size="small" onClick={() => {}}>
        VIEW
      </Button>
      {/* <Button color="error" size="small" onClick={() => {}}>
        UNDO
      </Button> */}
      <IconButton IconType="Cancel" onClick={() => {}} />
    </>
  );
  render() {
    const { message,onClick, severity, open, onClose,actions } = this.props;

    return (
      <MySnackbar
        anchorOrigin={{
          vertical: this.state.vertical,
          horizontal: this.state.horizontal,
        }}
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        onClick={onClick}
      >
        <Alert onClose={onClose}  onClick={onClick} action={actions} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </MySnackbar>
    );
  }
}

export default MyCustomSnackbar;
