import React from "react";
import { Box, Typography } from "@mui/material";

class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear();

    return (
      <Box
        p={2}
        display="flex"
        justifyContent="center"
        style={{
          backgroundImage: "linear-gradient(to right, #D52B1E,#78105B,#273E69)",
          color: "#FFFFFF",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontSize: 14, fontWeight: 400 }}>
          Copyright ©{year} Eli Lilly and Company. All rights reserved.
        </Typography>
      </Box>
    );
  }
}

export default Footer;
