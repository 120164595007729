import { Box, Chip, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, FormLabel, Grid, Typography } from '@mui/material'
import { Component, Context } from 'react'
import CollapsibleCard from '../../Components/Card/CollapsibleCard'
import BackLinkButton from '../../Components/Button/BackLinkButton'
import { RouteComponentProps } from 'react-router-dom'
import PageHeader from '../../Components/Text/PageHeader'
import RedButton from '../../Components/Button/RedButton'
import moment from 'moment';
import { AuthProviderService, AuthorizationService } from '../../Services'
import lptService from '../../Services/LPTService'
import { ExceptionForm } from '../../Models/ExceptionForm'
import { RoleContext } from '../../Contexts'
import MyCustomSnackbar from "../../Components/SnackBar/MyCustomSnackbar";
import approvalService from '../../Services/ApprovalService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import WhiteButton from '../../Components/Button/WhiteButton'
import exceptionRenewService from '../../Services/ExceptionRenewal'
import UnauthorizedRoute from '../../Components/Route/UnauthorizedRoute'

interface Props extends RouteComponentProps<any, any, any> {

};

interface State {
  exceptionFormData: ExceptionForm;
  showPreviousVersion: boolean;
  exceptionType: string;
  page: number;
  order: string;
  orderBy: string;
  rows: number;
  limit: number;
  searchDebounce: string;
  procedureNames: any[];
  geographyScope: any[];
  businessScope: any[];
  search: string;
  errMsg: string;
  throwErr: boolean;
  successMsg: string;
  throwSuccess: boolean;
  RenewalthrowSuccess: boolean;
  documentUrl: string;
  load: boolean;
  gperId: number;
  exceptiongeography: any[];
  exceptionBusinessArea: any[];
  proceduresData: any[];
  approverNames: any[];
  approverLoading: boolean;
  m4LegalAttorneySpEcoBlVal: any;
  gmRegionalAffiliateVpVal: any;
  ibuHubGmVal: any;
  associateVpEcoIbuVal: any;
  associateVpLegalIbuVal: any;
  m4EcoBlVal: any;
  m4LegalAttrEcoBlFrapVal: any;
  gmFunctionVpr8Val: any;
  chiefAccOfficerVal: any;
  chiefProcOfficerVal: any;
  localBusinessOwnerVal: any;
  localChiefFinanOwnerVal: any;
  seniorDirEnterTravExpenVal: any;
  seniorVpChiefFinanOffcVal: any;
  localCfoVal: any;
  m5F12BusinessLeaderVal: any;
  approveDialog: boolean;
  rejectionDialog: boolean;
  isAuthorize: boolean;
  showButtons: boolean;
}


export default class ExceptionApproval extends Component<Props, State> {
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      showPreviousVersion: false,
      exceptionType: '',
      searchDebounce: "",
      page: 1,
      rows: 5,
      limit: 5,
      search: "",
      order: "",
      orderBy: "",
      gperId: 0,
      procedureNames: [],
      businessScope: [],
      geographyScope: [],
      exceptionFormData: {
        title: "",
        businessAreaScope: "",
        ecGeographicm4: "",
        exceptionClassification: "",
        exceptionCompletedBy: "",
        exceptionDescription: "",
        exceptionExpirationDate: null,
        exceptionImpactAfterGrant: "",
        exceptionRationale: "",
        exceptionRequestBy: "",
        exceptionTypeForApproval: "",
        geographyScopeException: "",
        procedure: "",
        requestedTextFromProcedure: "",
        ecAssociateVpOpConsultDate: null,
        ecoBlConsultDate: null,
        ecGeographicm4ConsultDate: null,
        exceptionEffectiveDate: null,
        legalConsultDate: null,
        procedureOwnerConsultDate: null,
      },
      errMsg: "",
      throwErr: false,
      documentUrl: "",
      load: true,
      successMsg: "",
      throwSuccess: false,
      RenewalthrowSuccess: false,
      exceptionBusinessArea: [],
      exceptiongeography: [],
      proceduresData: [],
      approverNames: [],
      approverLoading: false,
      associateVpEcoIbuVal: {},
      associateVpLegalIbuVal: {},
      chiefAccOfficerVal: {},
      chiefProcOfficerVal: {},
      gmFunctionVpr8Val: {},
      gmRegionalAffiliateVpVal: {},
      ibuHubGmVal: {},
      localBusinessOwnerVal: {},
      localCfoVal: {},
      localChiefFinanOwnerVal: {},
      m4EcoBlVal: {},
      m4LegalAttorneySpEcoBlVal: {},
      m4LegalAttrEcoBlFrapVal: {},
      m5F12BusinessLeaderVal: {},
      seniorDirEnterTravExpenVal: {},
      seniorVpChiefFinanOffcVal: {},
      approveDialog: false,
      rejectionDialog: false,
      isAuthorize: false,
      showButtons: false,
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({
      gperId: Number(this.props.match.params.id),
    });
  };

  async componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    _snapshot?: any
  ): Promise<void> {
    if (prevState.gperId !== this.state.gperId) {
      await this.getExceptionDataById();
    }
  }

  handleExceptionApprove = async () => {
    const emails = await AuthProviderService.getAdminEmails()
    const payload = {
      approvalType: "Approved",
      gperId: this.state.gperId,
      adminEmails: emails.join(',')
    };
    await approvalService.exceptionApproval(payload).then((res) => {
      if (res.isSuccess) {
        this.setState({
          approveDialog: false,
          successMsg: "The Exception form have been approved successfully.",
          throwSuccess: true
        });
        setTimeout(() => {
          this.props.history.push('/procedure_exception')
        }, 2500)
      } else {
        this.setState({
          approveDialog: false,
          throwErr: true,
          errMsg: "Something went wrong while Approving."
        })
      }
    })
  }

  handleExceptionReject = async () => {
    const emails = await AuthProviderService.getAdminEmails()
    const payload = {
      approvalType: "Rejected",
      gperId: this.state.gperId,
      adminEmails: emails.join(',')
    }
    await approvalService.exceptionApproval(payload).then((res) => {
      if (res.isSuccess) {
        this.setState({
          rejectionDialog: false,
          successMsg: "The Exception form have been rejected.",
          throwSuccess: true
        });
        setTimeout(() => {
          this.props.history.push('/procedure_exception')
        }, 2500)
      } else {
        this.setState({
          rejectionDialog: false,
          throwErr: true,
          errMsg: "Something went wrong while Rejecting."
        })
      }
    })

  };

  handleInputChange = (event: any) => {
    this.setState({
      exceptionFormData: { ...this.state.exceptionFormData, [event.target.name]: event.target.value }
    });
  };
  render() {
    const { exceptionFormData, errMsg, throwErr, throwSuccess, successMsg, approveDialog, rejectionDialog, isAuthorize, load, showButtons } = this.state;

    if (load) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }

    if (!isAuthorize) {
      return <UnauthorizedRoute />;
    }
  
    return (
      <Box>
        <Dialog open={approveDialog}>
          <Grid container>
            <Grid item>
              <DialogTitle color={'red'}>Approve Exception</DialogTitle>
            </Grid>
            <Grid item>
              <FontAwesomeIcon icon={faXmark} size='lg' onClick={() => {
                this.setState({ approveDialog: false })
              }} style={{ marginTop: 22, marginLeft: 125, color: 'red' }} />
            </Grid>
          </Grid>
          <DialogContent dividers>
            <DialogContentText marginTop={2} color={'black'}>Are you sure you want to approve exception?</DialogContentText>
            <DialogContent>
              <Grid container mt={3}>
                <Grid item mr={2}>
                  <WhiteButton label='No' onClick={() => { this.setState({ approveDialog: false }) }} />
                </Grid>
                <Grid item>
                  <RedButton label='Yes' onClick={this.handleExceptionApprove} />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
        </Dialog>
        <Dialog open={rejectionDialog}>
          <Grid container>
            <Grid item>
              <DialogTitle color={'red'}>Reject Exception</DialogTitle>
            </Grid>
            <Grid item>
              <FontAwesomeIcon icon={faXmark} size='lg' onClick={() => {
                this.setState({ rejectionDialog: false })
              }} style={{ marginTop: 22, marginLeft: 125, color: 'red' }} />
            </Grid>
          </Grid>
          <DialogContent dividers>
            <DialogContentText marginTop={2} color={'black'}>Are you sure you want to reject exception?</DialogContentText>
            <DialogContent>
              <Grid container mt={3}>
                <Grid item mr={2}>
                  <WhiteButton label='No' onClick={() => { this.setState({ rejectionDialog: false }) }} />
                </Grid>
                <Grid item>
                  <RedButton label='Yes' onClick={this.handleExceptionReject} />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
        </Dialog>

        <Grid
          container
          direction={"row"}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1em",
            justifyContent: "space-between",
          }}
        >
          <MyCustomSnackbar
            message={errMsg}
            severity="warning"
            actions={<></>}
            open={throwErr}
            onClose={() => {
              this.setState({ throwErr: false });
            }}
          />
          <MyCustomSnackbar
            message={successMsg}
            severity="success"
            actions={<></>}
            open={throwSuccess}
            onClose={() => {
              this.setState({ throwSuccess: false });
            }}
          />
          <Grid item>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item>
                <BackLinkButton onClick={() => {
                  this.props.history.push("/procedure_exception")
                  // this.props.history.go(0);
                }
                } />
              </Grid>
              <Grid
                item
                sx={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  maxWidth: "28em",
                }}
              >

                <PageHeader label={`${exceptionFormData.title}`} />

              </Grid>
              <Grid item>
                <Chip color='warning' label={`${exceptionFormData.status}`} />
              </Grid>
            </Grid>
          </Grid>
          {showButtons && exceptionFormData.status === 'Approval Pending'  ? <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <RedButton label="Approve" onClick={() => {
                  this.setState({ approveDialog: true })
                }} />
              </Grid>
              <Grid item>
                <RedButton label="Reject" onClick={() => { this.setState({ rejectionDialog: true }) }} />
              </Grid>
            </Grid>
          </Grid> : null}
        </Grid>
        <Grid container ml={"3.2em"} spacing={2} mb={"0.8em"}>
          <Grid item>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Created By :</span> {this.state.exceptionFormData.createdBy + " " + moment(this.state.exceptionFormData.createdDate).format("L hh:mm a")}
            </Typography>
          </Grid>
          {
            this.state.exceptionFormData.modifyBy ?
              <Grid item>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Last Modified By :</span> {this.state.exceptionFormData.modifyBy + " " + moment(this.state.exceptionFormData.modifiedDate).format("L hh:mm a")}
                </Typography>
              </Grid> :
              <></>
          }
        </Grid>
        <Divider style={{ marginBottom: '1em' }} />
        <>
          <CollapsibleCard
            title='Procedure Details'
            divider
            initiallyOpen
            subTitle="Provide information the approvers needs to evaluate the exception"
            titleStyle={{ fontSize: '26px' }}
            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
          >
            <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}}>Title</Typography>
                <Typography>{exceptionFormData.title}</Typography>
              </Grid>

              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}}> Exception Effective Date </Typography>
                {
                  exceptionFormData.exceptionEffectiveDate ?
                    <Typography>{moment(exceptionFormData.exceptionEffectiveDate).format("L")}</Typography> :
                    <Typography>__</Typography>

                }
              </Grid>
              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}}>Exception Requested By</Typography>
                <Typography>{exceptionFormData.exceptionRequestBy}</Typography>
              </Grid>
              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}}>Exception Form Completed By</Typography>
                <Typography>{exceptionFormData.exceptionCompletedBy}</Typography>
              </Grid>
              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}}>Exception Classification</Typography>
                <Typography>{exceptionFormData.exceptionClassification}</Typography>
              </Grid>

              <Grid item xs={2}  >

                <Typography style={{fontWeight:'bolder'}}>Exception Expiration Date </Typography>
                {exceptionFormData.exceptionExpirationDate ?

                  <Typography>{moment(exceptionFormData.exceptionExpirationDate).format("L")}</Typography> :
                  <Typography>__</Typography>
                }
              </Grid>
              <Grid item xs={2}  >

                <Typography style={{fontWeight:'bolder'}}>Procedure(s)</Typography>
                <Typography>{exceptionFormData.procedure}</Typography>

              </Grid>
              <Grid item xs={2}  >

                <Typography style={{fontWeight:'bolder'}}>Geography scope for the exception</Typography>
                <Typography>{exceptionFormData.geographyScopeException}</Typography>

              </Grid>
              <Grid item xs={2}  >

                <Typography style={{fontWeight:'bolder'}}>Business area scope for the exception</Typography>

                <Typography>{exceptionFormData.businessAreaScope}</Typography>

              </Grid>
              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}} >Describe the exception you are requesting</Typography>
                <Typography>{exceptionFormData.exceptionDescription}</Typography>
              </Grid>
             
              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}} >What is the impact on the business if the <br></br> exception is granted? If not granted?</Typography>
                <Typography>{exceptionFormData.exceptionImpactAfterGrant}</Typography>
              </Grid>
              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}}>What is the rationale for this exception?</Typography>
                <Typography>{exceptionFormData.exceptionRationale}</Typography>
              </Grid>
              <Grid item xs={2}  >
                <Typography style={{fontWeight:'bolder'}}>Insert text from the procedure for which you <br></br>are requesting an exception</Typography>
                <Typography>{exceptionFormData.requestedTextFromProcedure}</Typography>
              </Grid>
            </Grid>
          </CollapsibleCard>
          <CollapsibleCard
            title='Consultation: Ethics and Compliance'
            divider

            subTitle="Summarize the advice you received on this potential exception"
            titleStyle={{ fontSize: '26px' }}
            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
          >
            <Box>
              <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>ECO/BL</Typography>
                  <Typography>{exceptionFormData.ecoBl}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>ECO/BL Consult Date</Typography>
                  {exceptionFormData.ecoBlConsultDate ?

                    <Typography>{moment(exceptionFormData.ecoBlConsultDate).format("L")}</Typography>
                    : <Typography>____</Typography>
                  }
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Per ECO/BL: What do applicable international, regional or local industry association <br></br> code(s) say about the subject?</Typography>
                  <Typography>{exceptionFormData.ecoBlAssociationCodeSubjectDescription}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+</Typography>
                  <Typography>{exceptionFormData.ecGeographicm4}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+ Consult Date</Typography>
                  {exceptionFormData.ecGeographicm4ConsultDate ? <Typography>{moment(exceptionFormData.ecGeographicm4ConsultDate).format("L")}</Typography> :
                    <Typography>_____</Typography>}
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+ Comments</Typography>
                  <Typography>{exceptionFormData.ecGeographicm4Comments}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations</Typography>
                  <Typography>{exceptionFormData.ecAssociateVpOp}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations Consult Date</Typography>
                  {exceptionFormData.ecAssociateVpOpConsultDate ? <Typography>{moment(exceptionFormData.ecAssociateVpOpConsultDate).format("L")}</Typography>
                    : <Typography>__</Typography>}
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations Comments</Typography>
                  <Typography>{exceptionFormData.ecAssociateVpOpComments}</Typography>
                </Grid>
              </Grid>
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title='Consultation: Legal'
            divider
            subTitle="Summarize the advice you received on this potential exception. Legal consult applies to
                        IBU exceptions and is optional for other exceptions."
            titleStyle={{ fontSize: '26px' }}
            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>

            <Box>
              <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Assigned Legal Counsel</Typography>
                  <Typography>{exceptionFormData.assignedLegalCounsel}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Legal Consult Date</Typography>
                  {exceptionFormData.legalConsultDate ?
                    <Typography>{moment(exceptionFormData.legalConsultDate).format('L')}</Typography> :
                    <Typography>__</Typography>

                  }
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Per Legal: What are the risks associated with the exception?</Typography>
                  <Typography>{exceptionFormData.legalRiskWithException}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Per Legal:How is the exception compliant with applicable law(s),local and foreign laws applied <br></br> to the jurisdiction (e.g., U.S, FCPA,trade sanctions,other countries with extraterritorial reach)?</Typography>
                  <Typography>{exceptionFormData.exceptionCompliantWithLaws}</Typography>
                </Grid>
              </Grid>
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title='Consultation: Procedure Owner'
            divider

            subTitle="Summarize the advice you received on this potential exception"
            titleStyle={{ fontSize: '26px' }}
            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
            <Box>
              <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Procedure Owner</Typography>
                  <Typography>{exceptionFormData.procedureOwner}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Procedure Owner Consult Date</Typography>
                  {
                    exceptionFormData.procedureOwnerConsultDate ?
                      <Typography>{moment(exceptionFormData.procedureOwnerConsultDate).format('L')}</Typography>
                      : <Typography>___</Typography>
                  }
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Procedure Owner Comments</Typography>
                  <Typography>{exceptionFormData.procedureOwnerComments}</Typography>
                </Grid>

              </Grid>
            </Box>
          </CollapsibleCard>
          <CollapsibleCard title='Approval: Global Ethics and Compliance Procedure'
            divider

            subTitle="Summarize the advice you received on this potential exception"
            titleStyle={{ fontSize: '26px' }}
            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
            <Box>
              <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                <Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>Exception Type for Approval</Typography>
                  <Typography>{exceptionFormData.exceptionTypeForApproval}</Typography>
                </Grid>
                {exceptionFormData.exceptionTypeForApproval === "Global Ethics & Compliance Procedure" ? <><Grid item xs={2}>
                  <Typography style={{fontWeight:'bolder'}}>M4+ Legal Attorney Supporting ECO/BL (for E&C Procedure)</Typography>
                  <Typography>{exceptionFormData.m4LegalAttorneySpEcoBlName}</Typography>
                </Grid><Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Regional or Affiliate GM or Business VP at M5/R8+ Level</Typography>
                    <Typography>{exceptionFormData.gmRegionalAffiliateVpName}</Typography>
                  </Grid></> : exceptionFormData.exceptionTypeForApproval === "Lilly International Affiliate/Hub Local Procedure Requirements" ?
                  <><Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>IBU Hub GM</Typography>
                    <Typography>{exceptionFormData.ibuHubGmName}</Typography>
                  </Grid><Grid item xs={2}>
                      <Typography style={{fontWeight:'bolder'}}>Associate VP or Above, Ethics and Compliance, IBU</Typography>
                      <Typography>{exceptionFormData.associateVpEcoIbuName}</Typography>
                    </Grid><Grid item xs={2}>
                      <Typography style={{fontWeight:'bolder'}}>Associate VP or Above, Legal, IBU</Typography>
                      <Typography>{exceptionFormData.associateVpLegalIbuName}</Typography>
                    </Grid></> : exceptionFormData.exceptionTypeForApproval === "Global Procedure on Following FRAP" ?
                    <><Grid item xs={2}>
                      <Typography style={{fontWeight:'bolder'}}>M4+ ECO/BL</Typography>
                      <Typography>{exceptionFormData.m4EcoBlName}</Typography>
                    </Grid><Grid item xs={2}>
                        <Typography style={{fontWeight:'bolder'}}>M4+ Legal Attorney Supporting ECO/BL (for FRAP)</Typography>
                        <Typography>{exceptionFormData.m4LegalAttorneySpEcoBlName}</Typography>
                      </Grid><Grid item xs={2}>
                        <Typography style={{fontWeight:'bolder'}}>GM or Functional VP at M5/R8 Level</Typography>
                        <Typography>{exceptionFormData.gmFunctionVpr8Name}</Typography>
                      </Grid><Grid item xs={2}>
                        <Typography style={{fontWeight:'bolder'}}>Chief Accounting Officer </Typography>
                        <Typography>{exceptionFormData.chiefAccOfficerName}</Typography>
                      </Grid><Grid item xs={2}>
                        <Typography style={{fontWeight:'bolder'}}>Chief Procurement Officer </Typography>
                        <Typography>{exceptionFormData.chiefProcOfficerName}</Typography>
                      </Grid></>
                    : exceptionFormData.exceptionTypeForApproval === "Global Travel and Expense Procedure" ?
                      <><Grid item xs={2}>
                        <Typography style={{fontWeight:'bolder'}}>Local Business Owner</Typography>
                        <Typography>{exceptionFormData.localBusinessOwnerName}</Typography>
                      </Grid><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>Local Chief Financial Officer (CFO)</Typography>
                          <Typography>{exceptionFormData.localChiefFinanOwnerName}</Typography>
                        </Grid><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>Senior Director, Enterprise Travel and Expenser</Typography>
                          <Typography>{exceptionFormData.seniorDirEnterTravExpenName}</Typography>
                        </Grid><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>Senior Vice President and Chief Financial Officer</Typography>
                          <Typography>{exceptionFormData.seniorVpChiefFinanOffcName}</Typography>
                        </Grid></>
                      : <><Grid item xs={2}>
                        <Typography style={{fontWeight:'bolder'}}>Local CFO</Typography>
                        <Typography>{exceptionFormData.localCfoName}</Typography>
                      </Grid><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>M5/R12 Business Leader</Typography>
                          <Typography>{exceptionFormData.m5F12BusinessLeaderName}</Typography>
                        </Grid></>
                }
              </Grid>
            </Box>
          </CollapsibleCard>
        </>
      </Box>
    )
  }


  getExceptionDataById = async () => {
    try {
      let isAuthorize = false;
      let showButtons = false;
      const isGlobalonlyUser = AuthorizationService.isGlobalonlyUser(this.context);
      const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);
      const isAdminUser = AuthorizationService.isAdminUser(this.context);

      console.log('gperId', this.state.gperId)
      const data = await lptService.getExceptionDataById(this.state.gperId);
      console.log('data of exception', data)
      console.log('isGlobal', isReadonlyUser)
      const exceptionApprovers = exceptionRenewService.getExceptionApproverKeys();
      const loginUserEmail = this.context.userInfo.preferred_username as string;
      if (isGlobalonlyUser) {
        exceptionApprovers.forEach((exceptionApprover) => {
          if (!isAuthorize) {
            if (data) {
              const keyTyped = exceptionApprover as keyof typeof data;
              const value = data[keyTyped] as string;

              if (value && value.toLocaleLowerCase() === loginUserEmail.toLocaleLowerCase()) {
                isAuthorize = true;
                showButtons = true;

                if (exceptionApprover === 'createdByEmail') {
                  showButtons = false;
                }
              }
            }
          }
        });
      } else {
        if (isAdminUser) {
          isAuthorize = true;
          showButtons = true;
        } else {
         
          exceptionApprovers.forEach((exceptionApprover) => {
            if (!isAuthorize) {
              if (data) {
                const keyTyped = exceptionApprover as keyof typeof data;
                const value = data[keyTyped] as string;
  
                if (value && value.toLocaleLowerCase() === loginUserEmail.toLocaleLowerCase()) {
                  
                  isAuthorize = true;
                  showButtons = true;

                  if (exceptionApprover === 'createdByEmail') {
                      showButtons = false;
                  }
                }
              }
            }
          });
        }
      }

      const procedure = data?.procedure.split(",").map((str) => { return { text: str, value: str } })
      const geography = data?.geographyScopeException.split(",").map((str) => { return { text: str, value: str } })
      const businessScope = data?.businessAreaScope.split(",").map((str) => { return { text: str, value: str } })
      this.setState({ exceptionFormData: data!, procedureNames: procedure!, geographyScope: geography!, businessScope: businessScope!, isAuthorize, load: false, showButtons });
    } catch (error) {
      console.error("error while getting by id", error)
    }
  }
};