import { Component } from "react";
import RichTextEditor from "../../Components/LexicalEditor";
import {  LexicalEditor, UNDO_COMMAND } from "lexical";
import {EditorState} from "lexical";
import Utils from "../../Common/Utils";
import { Toolbar } from "@mui/material";
import RedButton from "../../Components/Button/RedButton";

interface Props {

}

interface State {
  flag:boolean;
  editor:EditorState;
  history:any;
  editorState?:LexicalEditor
  toogleUndo:boolean
}

export default class Test extends Component<Props,State> {
  constructor(props:Props) {
    super(props);
    
    this.state = {
      flag: true,
      editor:Utils.getEditorState(),
      history: [],
      toogleUndo:false
    };
  }

  render() {
    
    return (
      <div>
       <RichTextEditor toogleUndo={this.state.toogleUndo} onChange={this.handleChange} editorState={this.state.editor} />
       <Toolbar>
       <RedButton label="Undo" onClick={() => {
        this.setState({})
       }} />
       </Toolbar>
      </div>
    );
  }


  handleChange = (editor:EditorState) => {
    console.log("handler called")
    if (this.state.toogleUndo) {
      this.handleUndo()
    } else {
      this.setState({editor:editor});
    }
  };

  handleUndo = () => {
    console.log("undo called")
    this.setState({toogleUndo:!this.state.toogleUndo})
  }
}
