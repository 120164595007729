import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = [
  'India',
  'USA',
  'UK',
  'China',
  'Armenia',
  'Bosnia',
];

export default function MultiSelectCheckBox(props: { onSelectChange?: (name: string[] | string, id: number) => void | undefined; id?: number, options: string[], values: string[], mode?: string, disabled?: boolean }) {
  
  // const [values, setValue] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof props.values>) => {
    // let target =event.target.value;
    let target = event.target.value;
    const {
      target: { value },
    } = event;
    // setValue(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    if(props.onSelectChange && props.id)
    {
      props.onSelectChange(target,props.id);
    }
  };

  return (
    <div>
      <FormControl size={"small"} fullWidth>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          disabled={props.disabled}
          multiple
          value={props.values}
          onChange={handleChange}
          input={<OutlinedInput label=""/>}
          renderValue={props.mode === "setGeoGraphy"?(selected) => selected.filter(item=>item !== "All").join(', '):(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {props.options.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={props.values.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}