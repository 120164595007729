import { Box, CircularProgress, Divider, Grid } from "@mui/material";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import BreadCrumb from "../../Components/BreadCrumb";
import PageHeader from "../../Components/Text/PageHeader";
import WhiteButton from "../../Components/Button/WhiteButton";
import { LocalProcedureTemplate } from "../../Models";
import { ProcedureFileService } from "../../Services";
import { FileType } from "../../Common/Enums";
import DocumentPreview from "../../Components/DocumentPreview";
import MyCustomSnackbar from "../../Components/SnackBar/MyCustomSnackbar";
import IconButton from "../../Components/Button/IconButton";
import BreadCrumbModel from "../../Components/BreadCrumb/BreadCrumbModel";
import BackLinkButton from "../../Components/Button/BackLinkButton";
import { LocalProcedureApprovalFileUpload } from "../../Models/LocalProcedureApprovalFileUpload";

interface Props
  extends RouteComponentProps<
    any,
    any,
    {
      translatedFileDetail: LocalProcedureApprovalFileUpload;
    }
  > { }

interface State {
  documentUrl: string;
  open: boolean;
  breadCrumbs: BreadCrumbModel[];
  load:boolean;
  isError:boolean;
}

export class ApproverTranslatedFileViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      documentUrl: "",
      open: true,
      isError:false,
      breadCrumbs: [
        
      ],
      load:false
    };
  }

  componentDidMount(): void {
    console.log("props+++",this.props);
    
    this.gettingDocUrl();
  }

  action = (
    <>
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ isError: false });
        }}
      />
    </>
  );
  render() {
    console.log("props", this.props);
    if (!this.state.documentUrl && !this.state.load) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    return (
      <>
       <MyCustomSnackbar
              actions={this.action}
              message="Download link has expired !! Please refresh the page to download."
              severity="warning"
              open={this.state.isError}
            />
        <Box
          style={{
            marginTop: "0.5em",
          }}
        >
          <Grid
            container
            direction={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {this.props.history.length > 1 && <Grid item>
                 <BackLinkButton
                  onClick={() => {
                    this.props.history.goBack();  
                  }}
                 />
                </Grid>}
                <Grid
                  item
                  sx={{
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    maxWidth: "48em",
                  }}
                >

                    <PageHeader
                      label={this.props.location.state?.translatedFileDetail?.fileName}
                    />
                  
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={3}>
                {this.props.history.length > 1 && <Grid item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5em"
                  }}>
                 <WhiteButton label="Cancel" onClick={() => {
                        this.props.history.goBack();
                      }} />
                </Grid>}
                {/* <Grid item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5em"
                  }}>
                 <WhiteButton label="Download Word" onClick={this.onWordDownloadClick} />
                </Grid> */}
                {/* <Grid item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5em"
                  }}>
                 <WhiteButton label="Download Pdf" onClick={this.onPdfDownloadClick} />
                </Grid> */}
                
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid>
            <DocumentPreview documentUrl={this.state.documentUrl} height="700px" />
          </Grid>
         
        </Box>
      </>
    )

  }

  gettingDocUrl = async () => {
      try {
        console.log("data received by translated file view ",this.props.location.state?.translatedFileDetail.s3FilePathKey);
        const data = await ProcedureFileService.docx2pdf(this.props.location.state?.translatedFileDetail?.s3FilePathKey!, FileType.DOCX);
        console.log("url", data);
        this.setState({ documentUrl: data! });
      } catch (error) {
        this.setState({load:false});
        console.error(error)
      }
    
    

  };

  onWordDownloadClick = async () => {
    console.log("triigerr", this.props.location);    
      const docFile = await ProcedureFileService.getFile(
        this.props.location.state?.translatedFileDetail?.s3FilePathKey,
        FileType.DOCX
      )
      fetch(docFile!)
      .then( (response) => {
        if (response.ok) {
          this.setState({isError : false});
          return response.blob();
        }
        throw new Error('Network response was not OK.');
      })
      .then( (blob) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.props.location.state.translatedFileDetail.fileName}.docx`;
        document.body.appendChild(link); link.click(); 
        document.body.removeChild(link);
      }).catch( (error) => { 
        this.setState({isError : true});
        console.error('Error:', error); 
      });
      
    
      
    
  };

  onPdfDownloadClick = async () => {
    // console.log("triigerr", this.props.location);
      fetch(this.state.documentUrl)
      .then( (response) => {
        if (response.ok) {
          this.setState({isError : false});
          return response.blob();
        }
        throw new Error('Network response was not OK.');
      })
      .then( (blob) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.props.location.state.translatedFileDetail.fileName}.pdf`;
        document.body.appendChild(link); link.click(); 
        document.body.removeChild(link);
      }).catch( (error) => { 
        this.setState({isError : true});
        console.error('Error:', error); 
      });
      
    
  };
}

export default ApproverTranslatedFileViewer;