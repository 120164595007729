import React from "react";
import { Button } from "@mui/material";
import { red as redColor } from "@mui/material/colors";


interface Props {
    label: string;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    style?: React.CSSProperties;
    disabled?: boolean;
    startIcon?: React.ReactNode
}
interface State { }

class WhiteButton extends React.Component<Props, State> {
    render() {
        const { label,startIcon, onClick, style, disabled } = this.props;

        const defaultStyle: any = { color: redColor[500], borderColor: redColor[500], textTransform: 'none' };
        if(disabled) {
            defaultStyle.backgroundColor = '#ACACAC';
            defaultStyle.color = '#FFFFFF';
            defaultStyle.borderColor = '#ACACAC'
        }
        let mergeStyle = { ...defaultStyle, ...style };
        
        

        return (
            <Button startIcon={startIcon} disabled={disabled} size="medium" aria-disabled = {disabled} variant="outlined" style={mergeStyle}
                onClick={onClick}>{label}</Button>
        );
    }
}

export default WhiteButton;