import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthProviderService, LocalStorageService } from '../../Services';
import { Box, CircularProgress } from '@mui/material';
import Layout from '../../Components/Layout';

interface Props extends RouteComponentProps<any> { }
interface State { }
class AuthCallback extends React.Component<Props, State> {
    componentDidMount() {
        AuthProviderService.handlerPromise(this.processLogin);
    }

    render() {
        return <Layout>
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
            </Layout>
    }

    processLogin = () => {
        const path = AuthProviderService.getCallbackPath();

        try {
            LocalStorageService.setUserLoggedIn();
            this.props.history.push({ pathname: path });
            this.props.history.go(0);
        } catch (error) {
            console.log('processLogin-error', error)
        }
    }
}

export default withRouter(AuthCallback);