import { TextFormatType, DOMConversionOutput, $isTextNode, DOMConversion, TextNode, LexicalEditor } from "lexical";
import { rgbToHex } from "@mui/material";
import Constants from "../../../Common/Constants";
import Utils from "../../../Common/Utils";

const createMissedFormatConverter = (format: TextFormatType) => {
    return (): DOMConversionOutput => {
        return {
            forChild: lexicalNode => {
                if ($isTextNode(lexicalNode)) {
                    lexicalNode.toggleFormat(format);
                }

                return lexicalNode;
            },
            node: null,
        };
    };
};

const patchStyleConversion = (
    originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): ((node: HTMLElement) => DOMConversionOutput | null) => {
    return node => {
        const original = originalDOMConverter?.(node);
        if (!original) {
            return null;
        }

        const originalOutput = original.conversion(node);

        if (!originalOutput) {
            return originalOutput;
        }
        const skipStrongStylePatch = document.activeElement?.parentElement?.getAttribute('data-skipStrongStylePatch');

        if (skipStrongStylePatch && node.nodeName === 'STRONG') {
            return null;
        }

        const defaultEditorStyle = document.activeElement?.parentElement?.getAttribute('data-defaultStyle');

        const parentNode: any = node.parentNode;

        let backgroundColor = node.style.backgroundColor;
        let color: string | undefined = node.style.color;

        if (!Utils.isHexColor(color)) {
            const hexColor = Utils.getHexCode(color);

            if (!(hexColor === undefined)) {
                color = hexColor;
            }
        } else if (!Utils.isRGBColor(color)) {
            const hexColor = rgbToHex(color);

            if (!(hexColor === undefined)) {
                color = hexColor;
            }
        }

        console.log('patching.....');

        let fontFamilies = node.style.fontFamily.split(',').filter(p => [
            Constants.DocFontFamilyRegular,
            Constants.DocFontFamilyMedium,
            Constants.DocFontFamilyBold].includes(p));
        let nodeFontSize = node.style.fontSize;

        if (!nodeFontSize) {
            nodeFontSize = Constants.DefaultFontSize;
        }        

        if (fontFamilies.length < 1) {
            if (parentNode) {
                fontFamilies = parentNode.style.fontFamily.split(',').filter((p: any) => [
                    Constants.DocFontFamilyRegular,
                    Constants.DocFontFamilyMedium,
                    Constants.DocFontFamilyBold].includes(p)) ?? [];
            }
        }

        if (fontFamilies.length < 1) {
            fontFamilies.push(Constants.DocFontFamilyRegular);
        }

        if (defaultEditorStyle) {
            fontFamilies = [defaultEditorStyle];
        }

        return {
            ...originalOutput,
            forChild: (lexicalNode, parent) => {
                const originalForChild = originalOutput?.forChild ?? (x => x);
                const result = originalForChild(lexicalNode, parent);
                if ($isTextNode(result)) {
                    const style = [
                        backgroundColor ? `background-color: ${backgroundColor}` : null,
                        color ? `color: ${color}` : null,
                        fontFamilies.length > 0 ? `font-family: ${fontFamilies.join(';')}` : null,
                        `font-size: ${nodeFontSize}`
                    ]
                        .filter(p => {
                            if (p) {
                                return true;
                            }
                            return false;
                        })
                        .join(';');
                    if (style.length) {
                        return result.setStyle(style);
                    }
                }
                return result;
            },
        };
    };
};

export function applyHtmlToRichContentPatches() {
    const importers = TextNode.importDOM();
    TextNode.importDOM = function _() {
        return {
            ...importers,
            span: () => ({
                conversion: patchStyleConversion(importers?.span),
                priority: 0,
            }),
            sub: () => ({
                conversion: createMissedFormatConverter('subscript'),
                priority: 0,
            }),
            sup: () => ({
                conversion: createMissedFormatConverter('superscript'),
                priority: 0,
            }),
            s: () => ({
                conversion: createMissedFormatConverter('strikethrough'),
                priority: 0,
            }),
            mark: () => ({
                conversion: patchStyleConversion(importers?.span),
                priority: 0,
            }),
            strong: () => ({
                conversion: patchStyleConversion(importers?.strong),
                priority: 0,
            }),
            em: () => ({
                conversion: patchStyleConversion(importers?.em),
                priority: 0,
            }),
        };
    };

    const missedFormatTag: Array<[TextFormatType, string]> = [
        ['underline', 'u'],
        ['strikethrough', 's'],
    ];

    const exportDOM = TextNode.prototype.exportDOM;
    TextNode.prototype.exportDOM = function _(this: TextNode, editor: LexicalEditor) {
        const { element } = exportDOM.apply(this, [editor]);
        if (!element) {
            return { element };
        }

        let wrapped = element;

        for (const [format, tag] of missedFormatTag) {
            if ($hasFormat(this, format)) {
                const wrapper = document.createElement(tag);
                wrapper.appendChild(element);
                wrapped = wrapper;
            }
        }

        return { element: wrapped };
    };
}

export function $hasFormat(node: TextNode, format: TextFormatType): boolean {
    const currentFormat = node.getFormat();
    return node.getFormatFlags(format, null) < currentFormat;
}