import { Component, Context } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Button, Chip, Divider, Grid, InputLabel, Typography } from "@mui/material";
import BreadCrumb from '../Components/BreadCrumb';
import WhiteButton from '../Components/Button/WhiteButton';
import PageHeader from '../Components/Text/PageHeader';
import BreadCrumbModel from '../Components/BreadCrumb/BreadCrumbModel';
import moment from 'moment';
import BackLinkButton from '../Components/Button/BackLinkButton';
import gptService from '../Services/GPTService';
import MultiSelectCheckBox from '../Components/Select/MultiSelectCheckBox';
import MultiAutoComplete from '../Components/AutoCompleteSetGeography';
import MyCustomSnackbar from '../Components/SnackBar/MyCustomSnackbar';
import { AuthorizationService } from '../Services';
import UnauthorizedRoute from '../Components/Route/UnauthorizedRoute';
import { RoleContext } from '../Contexts';
import SingleSelect from '../Components/Select/SingleSelect';
import SelectModel from '../Components/Select/SelectModel';


interface Props extends RouteComponentProps<any, any, any> { }
interface Item {
  value: any;
  text: any;
}
interface State {
  selectedOption: any | undefined;
  breadCrumbs: BreadCrumbModel[];
  editTemplate: any;
  headerFile: string;
  footerFile: string;
  dropDownData: any[];
  subRegionHubValue: string[];
  geoGraphyByValue: any;
  affiliateValue: string[];
  countryValue: string[];
  gpId: string;
  success: boolean;
  error: boolean;
  errMsg: string;
  successMsg: string;
  subRegionDropDownOptions: any[];
  subRegionDropDownOptionsDefault: any[];
  countryDropDownOptions: any[];
  countryDropDownOptionsDefault: any[];
  affiliateDropDownOptions: any[];
  affiliateDropDownOptionsDefault: any[];
  defaultSubRegion: any[];
  defaultCountry: any[];
  defaultAffiliate: any[];
  disableSubmit:boolean;
}

const geoGraphyBy = [{ text: "Affiliate", value: "Affiliate" }, { text: "Sub-Region/Hub", value: "Sub-Region/Hub" }, { text: "Country", value: "Country" }];

class SetGeoGraphy extends Component<Props, State> {
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      defaultSubRegion: [],
      defaultCountry: [],
      defaultAffiliate: [],
      selectedOption: undefined,
      footerFile: "",
      headerFile: "",
      gpId: "",
      editTemplate: {},
      dropDownData: [],
      subRegionHubValue: [],
      geoGraphyByValue: [],
      affiliateValue: [],
      countryValue: [],
      success: false,
      error: false,
      errMsg: "",
      successMsg: "",
      subRegionDropDownOptions: [],
      subRegionDropDownOptionsDefault: [],
      countryDropDownOptions: [],
      countryDropDownOptionsDefault: [],
      affiliateDropDownOptions: [],
      affiliateDropDownOptionsDefault: [],
      disableSubmit:false,

      // editTemplate: {
      //   templateName: "",
      //   basicPrinciples: "",
      //   bodyText: "",
      //   approver: "",
      //   effectiveDate: null,
      //   footer: "",
      //   footerImageFile: "",
      //   header: "",
      //   headerImageFile: "",
      //   owner: "",
      //   procedureType: "",
      //   versionNumber: "",
      //   _basicPrinciples: Utils.getEditorState(),
      //   _bodyText: Utils.getEditorState(),
      //   _headerImageFile: {} as FileUploadModel,
      //   _footerImageFile: {} as FileUploadModel,
      //   _gpTemplateFile: {} as FileUploadModel,
      //   _header: Utils.getEditorState()
      // },
      breadCrumbs: [
        {
          label: 'Set Geography',
          onClick: () => {
            this.props.history.push('/setGeoGraphy');
          }
        },
        {
          label: 'Generate Procedure Template'
        }
      ]
    }
  };

  getGeoGraphy = async (gpId: any) => {
    try {
      const data = await gptService.getGeoGraphy(gpId);
      console.log("data=", data);
      const allGeography = data.allGeography;

      if (data.geographyMappingsOfGpId.length > 0) {
        const defaultCountryFilter = data.geographyMappingsOfGpId
          .filter((item: { geographyType: string; }) => item.geographyType === "Country");
        // const defaultCountry = defaultCountryFilter
        // .map((item: any)=>{
        //   return {
        //     value: item.geographyName,
        //     text:item.geographyName
        //   }
        // })
        console.log("defaultCountry= Filter=", defaultCountryFilter);

        const defaultCountry: Item[] = Array.from(new Set(defaultCountryFilter.map((item: { geographyName: any; }) => item.geographyName)))
          .map((geographyName) => {
            return {
              value: geographyName,
              text: geographyName,
            };
          });

        this.setState({ defaultCountry: defaultCountry })
        console.log("defaultCountry=")

        const defaultAffiliateFilter = data.geographyMappingsOfGpId.filter((item: { geographyType: string; }) => item.geographyType === "Affiliate");

        const defaultAffiliate: Item[] = Array.from(new Set(defaultAffiliateFilter.map((item: { geographyName: any; }) => item.geographyName)))
          .map((geographyName) => {
            return {
              value: geographyName,
              text: geographyName,
            };
          });

        // const defaultAffiliate = defaultAffiliateFilter
        // .map((item: any)=>{
        //   return {
        //     value: item.geographyName,
        //     text:item.geographyName
        //   }
        // })


        this.setState({ defaultAffiliate: defaultAffiliate })

        const defaultSubRegionFilter = data.geographyMappingsOfGpId.
          filter((item: { geographyType: string; }) => item.geographyType === "SubRegion");

        const defaultSubRegion: Item[] = Array.from(new Set(defaultSubRegionFilter.map((item: { geographyName: any; }) => item.geographyName)))
          .map((geographyName) => {
            return {
              value: geographyName,
              text: geographyName,
            };
          });
        this.setState({ defaultSubRegion: defaultSubRegion })
      }



      const affiliateAllEntries: Item[] = Array.from(new Set(allGeography.map((item: { affiliateName: any; }) => item.affiliateName)))
        .map((affiliateName) => {
          return {
            value: affiliateName,
            text: affiliateName,
          };
        });
      const countryAllEntries: Item[] = Array.from(new Set(allGeography.map((item: { countryName: any; }) => item.countryName)))
        .map((countryName) => {
          return {
            value: countryName,
            text: countryName,
          };
        });

      this.setState({ dropDownData: [...allGeography] });

      const subregionAllEntries: Item[] = Array.from(new Set(allGeography.map((item: { subRegionHubName: any; }) => item.subRegionHubName))).map(subRegionHubName => ({ value: subRegionHubName, text: subRegionHubName }));

      this.setState({ affiliateDropDownOptionsDefault: affiliateAllEntries.sort((a, b) => a.text.localeCompare(b.text)) });
      this.setState({ countryDropDownOptionsDefault: countryAllEntries.sort((a, b) => a.text.localeCompare(b.text)) });
      this.setState({ subRegionDropDownOptionsDefault: subregionAllEntries.sort((a, b) => a.text.localeCompare(b.text)) });
    } catch (error) {
      console.error("error =", error);
    }
  };

  componentDidMount(): void {
    console.log("this.props.history.location.state.templateData", this.props.history.location.state.templateData.gpId);
    const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

    if (!isReadonlyUser) {
      this.getGeoGraphy(this.props.history.location.state.templateData.gpId)
        ; console.log("history setGeographic", this.props.history);
      if (this.props.history.location.state) {
        this.setState({
          editTemplate: this.props.history.location.state.templateData ? this.props.history.location.state.templateData : {},
        });
      }
    }
  }


  filterEntriesForAffiliate(dropdown: any[], state: any[]) {
    return dropdown.filter((dropdownItem: { subRegionHubName: any; }) => {
      return state.some((subregionItem: { text: any; label: any; }) => {
        return dropdownItem.subRegionHubName === subregionItem.text;
      });
    });
  }

  filterEntriesForSubRegionCountries(dropdown: any[], state: any[]) {
    return dropdown.filter((dropdownItem: { subRegionHubName: any; }) => {
      return state.some((subregionItem: { text: any; label: any; }) => {
        return dropdownItem.subRegionHubName === subregionItem.text;
      });
    });
  }

  filterEntriesForCountry(dropdown: any[], state: any[]) {
    return dropdown.filter((dropdownItem: { affiliateName: any; }) => {
      return state.some((affiliateItem: { text: any; label: any; }) => {
        return dropdownItem.affiliateName === affiliateItem.text;
      });
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    // if (prevState.subRegionHubValue !== this.state.subRegionHubValue) {
    //   this.setState({ affiliateValue: [] });
    //   if (this.state.affiliateValue.length === 0) {
    //     const defaultCountry = Array.from(new Set(this.state.dropDownData.map((item: { countryName: any; }) => item.countryName)))
    //       .map((countryName) => {
    //         return {
    //           value: countryName,
    //           text: countryName,
    //         };
    //       });
    //     this.setState({ countryDropDownOptionsDefault: defaultCountry.sort((a, b) => a.text.localeCompare(b.text)) })
    //     this.setState({ countryDropDownOptions: defaultCountry.sort((a, b) => a.text.localeCompare(b.text)) })
    //   }
    //   if (this.state.affiliateValue.length === 0 && this.state.subRegionHubValue.length > 0) {
    //     const countryDropDownOptions = this.filterEntriesForSubRegionCountries(this.state.dropDownData, this.state.subRegionHubValue);
    //     const countryDropDownOptionsUnique = Array.from(new Set(countryDropDownOptions.map(item => item.countryName)))
    //       .map((countryName) => {
    //         return {
    //           value: countryName,
    //           text: countryName,
    //         }
    //       })
    //     this.setState({ countryDropDownOptionsDefault: countryDropDownOptionsUnique.sort((a, b) => a.text.localeCompare(b.text)) })
    //     this.setState({ countryDropDownOptions: countryDropDownOptionsUnique.sort((a, b) => a.text.localeCompare(b.text)) })
    //   }
    //   const affiliteDropDownOptions = this.filterEntriesForAffiliate(this.state.dropDownData, this.state.subRegionHubValue);

    //   // filter the duplicate affiliates if any
    //   const affiliteDropDownOptionsUnique = Array.from(new Set(affiliteDropDownOptions.map(item => item.affiliateName)))
    //     .map((affiliateName) => {
    //       return {
    //         value: affiliateName,
    //         text: affiliateName,
    //       }
    //     })
    //   this.setState({ affiliateDropDownOptions: affiliteDropDownOptionsUnique.sort((a, b) => a.text.localeCompare(b.text)) })
    // }

    // if (prevState.affiliateValue !== this.state.affiliateValue) {
    //   if (this.state.affiliateValue.length === 0 && this.state.subRegionHubValue.length > 0) {
    //     const countryDropDownOptions = this.filterEntriesForSubRegionCountries(this.state.dropDownData, this.state.subRegionHubValue);
    //     const countryDropDownOptionsUnique = Array.from(new Set(countryDropDownOptions.map(item => item.countryName)))
    //       .map((countryName) => {
    //         return {
    //           value: countryName,
    //           text: countryName,
    //         }
    //       })
    //     this.setState({ countryDropDownOptionsDefault: countryDropDownOptionsUnique.sort((a, b) => a.text.localeCompare(b.text)) })
    //     this.setState({ countryDropDownOptions: countryDropDownOptionsUnique.sort((a, b) => a.text.localeCompare(b.text)) })
    //     this.setState({ countryValue: [] });
    //     return;
    //   }

    //   if (this.state.affiliateValue.length === 0) {
    //     const defaultCountry = Array.from(new Set(this.state.dropDownData.map((item: { countryName: any; }) => item.countryName)))
    //       .map((countryName) => {
    //         return {
    //           value: countryName,
    //           text: countryName,
    //         };
    //       });
    //     this.setState({ countryDropDownOptionsDefault: defaultCountry.sort((a, b) => a.text.localeCompare(b.text)) })
    //     this.setState({ countryDropDownOptions: defaultCountry.sort((a, b) => a.text.localeCompare(b.text)) })
    //     this.setState({ countryValue: [] });
    //     return;
    //   }

    //   this.setState({ countryValue: [] });

    //   const countryDropDownOptions = this.filterEntriesForCountry(this.state.dropDownData, this.state.affiliateValue);
    //   // filter the duplicate country if any
    //   const countryDropDownOptionsUnique = Array.from(new Set(countryDropDownOptions.map(item => item.countryName)))
    //     .map((countryName) => {
    //       return {
    //         value: countryName,
    //         text: countryName,
    //       }
    //     })
    //   this.setState({ countryDropDownOptions: [...countryDropDownOptionsUnique] })

    // }

    if (this.state.selectedOption && (prevState.selectedOption !== this.state.selectedOption)) {
      if (this.state.selectedOption.text !== "Sub-Region/Hub" && this.state.subRegionHubValue.length > 0) {
        this.setState({ subRegionHubValue: [] });
      }
      if (this.state.selectedOption.text !== "Country" && this.state.countryValue.length > 0) {
        this.setState({ countryValue: [] });
      }
      if (this.state.selectedOption.text !== "Affiliate" && this.state.affiliateValue.length > 0) {
        this.setState({ affiliateValue: [] });
      }
    }
  }

  action = (
    <>
      <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => {
          this.setState({ error: false });
        }}>
        Ok
      </Button>
    </>
  );

  onSelectChangeAll = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      if (targetId === 'setGeography') {
        this.setState({ selectedOption: selected });
      }
    }
  }


  // onSelectChangeAll = (target: any, id: any) => {
  //   let index = target.findIndex((item: string) => item === 'All')
  //   let stateIndex = this.state.geoGraphyByValue.findIndex((item: string) => item === 'All')
  //   if (stateIndex !== -1) {
  //     if (this.state.geoGraphyByValue.length !== target.length) {
  //       if (index !== -1) {
  //         this.setState({ geoGraphyByValue: [...target.splice(index, 1)] })
  //       }
  //     }
  //   }
  //   if (index !== -1 && stateIndex === -1) {
  //     this.setState({ geoGraphyByValue: ['All', 'Affiliate', 'Sub-Region/Hub', 'Country'] });
  //   }
  //   else if (index === -1 && stateIndex !== -1) {
  //     this.setState({ geoGraphyByValue: [] });
  //   }
  //   else {
  //     this.setState({ geoGraphyByValue: [...target] });
  //   }
  // }

  onSelectChangeRegionHub = (selected: any) => {
    this.setState({ subRegionHubValue: [...selected] });
  }


  onSelectChangeAffiliate = (selected: any) => {
    this.setState({ affiliateValue: [...selected] })
  }

  onSelectChangeCountry = (selected: any) => {
    this.setState({ countryValue: [...selected] })
  }

  createPTGeoGraphyMapping = async (payLoad: any, localProcedurePayload: any) => {
    this.setState({ disableSubmit: true });
    try {
      const data = await gptService.createPTGeoGraphyMapping(payLoad, localProcedurePayload);
      console.log("data", data);
      if (data.success) {
        this.setState({ disableSubmit: true });
        setTimeout(() => {
          this.props.history.push("/local_procedures");
          // this.props.history.go(0);
        }, 3000)
        this.setState({ success: true, successMsg: "The Geographic Scope for procedure template has been submitted successfully." });
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ disableSubmit: false });
      this.setState({ error: true, errMsg: "Error from the database." });
    }
  }


  handleSubmit = () => {

    let localProcedureToBeCreated = [];
    let payload: { gpId: any; geographyName: any; geographyType: string; }[] = [];

    //Selection will be in the basic of single select dropdown.
    if (this.state.selectedOption && this.state.selectedOption.text === "Country") {
      if (this.state.countryValue.length > 0) {
        //Check the selected values with the default country.

        let countryArray: any[] = [];
        this.state.countryValue.forEach((item: any) => {
          const findIndex = this.state.defaultCountry.findIndex(row => row.text === item.text);
          if (findIndex === -1) {
            countryArray.push(item);
          }
        })


        countryArray.forEach((item: any) => {
          let countryValue = {
            "gpId": this.state.editTemplate.gpId,
            "geographyName": item.value,
            "geographyType": "Country"
          }
          payload.push(countryValue);
        })


        const name = countryArray.map((item: any) => item.text).join('_');
        let localProcedureItem = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": name,
          "geoGraphyType": "Country"
        }
        localProcedureToBeCreated.push(localProcedureItem);
      }
    }

    if (this.state.selectedOption && this.state.selectedOption.text === "Affiliate") {

      let affiliateArray: any[] = [];

      this.state.affiliateValue.forEach((item: any) => {
        const findIndex = this.state.defaultAffiliate.findIndex(row => row.text === item.text);
        if (findIndex === -1) {
          affiliateArray.push(item);
        }
      })
     console.log("this.state.affiliateValue",this.state.affiliateValue ,"affiliateArray=",affiliateArray, "this.state.defaultAffiliate=",this.state.defaultAffiliate);

      const name = affiliateArray.map((item: any) => item.text).join('_');

      let localProcedureItem = {
        "gpId": this.state.editTemplate.gpId,
        "geographyName": name,
        "geoGraphyType": "Affiliate"
      }
      localProcedureToBeCreated.push(localProcedureItem);

      //For payload also check for the countries entry.
      affiliateArray.forEach((item: any) => {
        let affilateValue = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Affiliate"
        }
        payload.push(affilateValue);
      })

      const countryAgainstAffiliate = this.filterEntriesForCountry(this.state.dropDownData, affiliateArray);
      const countryDropDownOptionsUnique = Array.from(new Set(countryAgainstAffiliate.map(item => item.countryName)))
        .map((countryName) => {
          return {
            value: countryName,
            text: countryName,
          }
        })

      countryDropDownOptionsUnique.forEach((item: any) => {
        let countryEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Country"
        }
        payload.push(countryEntry);
      })

    }

    if (this.state.selectedOption && this.state.selectedOption.text === "Sub-Region/Hub") {
      //For payload also check for the affilate and countries entries.
      let SubRegionArray: any[] = [];

      this.state.subRegionHubValue.forEach((item: any) => {
        const findIndex = this.state.defaultSubRegion.findIndex(row => row.text === item.text);
        if (findIndex === -1) {
          SubRegionArray.push(item);
        }
      })


      const name = SubRegionArray.map((item: any) => item.text).join('_');
      let localProcedureItem = {
        "gpId": this.state.editTemplate.gpId,
        "geographyName": name,
        "geoGraphyType": "SubRegion"
      }
      localProcedureToBeCreated.push(localProcedureItem);


      //make the paylaod ,attach the affilate and country in the paylaod 

      SubRegionArray.forEach((item: any) => {
        let subRegionValue = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "SubRegion"
        }
        payload.push(subRegionValue);
      })


      const affiliteDropDownOptions = this.filterEntriesForAffiliate(this.state.dropDownData, SubRegionArray);
      const affiliteDropDownOptionsUnique = Array.from(new Set(affiliteDropDownOptions.map(item => item.affiliateName)))
        .map((affiliateName) => {
          return {
            value: affiliateName,
            text: affiliateName,
          }
        })

      affiliteDropDownOptionsUnique.forEach((item: any) => {
        let countryEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Affiliate"
        }
        payload.push(countryEntry);
      })

      const countryAgainstAffiliate = this.filterEntriesForCountry(this.state.dropDownData, affiliteDropDownOptionsUnique);
      const countryDropDownOptionsUnique = Array.from(new Set(countryAgainstAffiliate.map(item => item.countryName)))
        .map((countryName) => {
          return {
            value: countryName,
            text: countryName,
          }
        })

      countryDropDownOptionsUnique.forEach((item: any) => {
        let countryEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Country"
        }
        payload.push(countryEntry);
      })
    }

    if (localProcedureToBeCreated.length > 0 && payload.length > 0) {
      console.log("localProcedureToBeCreated =", localProcedureToBeCreated, "payload=", payload);
      this.createPTGeoGraphyMapping(payload, localProcedureToBeCreated)

    } else {
      this.setState({ error: true, errMsg: "Please fill the atleast one dropdown." });
    }
  }

  render() {
    const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

    if (isReadonlyUser) {
      return <UnauthorizedRoute />;
    }

    const { breadCrumbs, subRegionHubValue, countryValue, affiliateValue, errMsg, successMsg } = this.state
    const { selectedOption } = this.state
    console.log("selectedOption=", selectedOption);
    return (
      <>
        <MyCustomSnackbar
          message={errMsg}
          severity="warning"
          actions={this.action}
          open={this.state.error}
        />
        <MyCustomSnackbar
          message={successMsg}
          severity="success"
          actions={<></>}
          open={this.state.success}
        />

        {/* <Box style={{ marginLeft: "1em", marginTop: "0.5em" }}> */}
        {/* <Grid marginBottom={"2em"} container direction="row">
            <BreadCrumb breadCrumbs={breadCrumbs} />
          </Grid> */}
        {/* <Box style={{ display: "flex", flexDirection: "row" }}> */}
        <>
          <Grid
            container
            direction={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={9}>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item>
                  <BackLinkButton
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  />
                </Grid>
                <Grid
                  item xs={11}
                  sx={{
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    // maxWidth: "39em",
                  }}
                >
                  <PageHeader
                    label={`TEMPLATE_PCoE_Pro_${this.state.editTemplate.templateName}_EN(v${this.state.editTemplate.versionNumber})`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <WhiteButton
                    label="Cancel"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  />
                </Grid>
                <Grid item>
                  <WhiteButton
                    label="Submit"
                    disabled={this.state.disableSubmit}
                    onClick={this.handleSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
        {/* </Box> */}
        <Divider />
        <Box>
          <>
            <Grid container mt={2} ml={2}>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  Template Name
                </Typography>
                <Typography>
                  {this.state.editTemplate.templateName}
                </Typography>
                {/* {this.state.editTemplate.headerImageFile !== "" ? <Chip
                    avatar={<img src={this.state.headerFile} alt="" />}
                  /> : <></>} */}
                <br></br>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  Procedure Type
                </Typography>
                <Typography>
                  {this.state.editTemplate.procedureType}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  Version
                </Typography>
                <Typography>
                  {this.state.editTemplate.versionNumber}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  Effective Date
                </Typography>
                <Typography>
                  {moment(this.state.editTemplate.effectiveDate).format(
                    "ll"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={2} ml={2} mb={1.5}>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  Owner
                </Typography>
                <Typography>{this.state.editTemplate.owner}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  Approver
                </Typography>
                <Typography>{this.state.editTemplate.approver}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  Footer
                </Typography>
                <Typography>{this.state.editTemplate.footer}</Typography>
                {/* {this.state.editTemplate.headerImageFile !== "" ? <Chip
                    // label={footerFileName}
                    avatar={<img src={this.state.footerFile} alt="" />}
                  /> : <></>} */}
                <br></br>
              </Grid>
            </Grid>
            <Divider />
            <Typography style={{ fontWeight: "bold", marginTop: "15px", marginBottom: "10px", fontSize: 19 }}>
              Set Geographic Scope
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box style={{ marginBottom: "7%" }}>
                  <SingleSelect
                    id='setGeography'
                    label='Select'
                    value={this.state.selectedOption?.value ?? ''}
                    values={geoGraphyBy}
                    onChange={this.onSelectChangeAll} />
                </Box>
              </Grid>

            </Grid>
            <Grid container spacing={2}>
              {(this.state.selectedOption && this.state.selectedOption.text === "Sub-Region/Hub") &&
                <Grid item xs={4}>
                  <InputLabel sx={{ fontWeight: "bold" }}>Sub-Region/Hub</InputLabel>
                  <Box style={{ marginBottom: "7%" }}>
                    <MultiAutoComplete
                      fixedOptions={this.state.defaultSubRegion}
                      selectedItems={[...subRegionHubValue]}
                      options={this.state.subRegionDropDownOptionsDefault}
                      onChange={this.onSelectChangeRegionHub}
                    />
                  </Box>
                </Grid>
              }

              {
                (this.state.selectedOption && this.state.selectedOption.text === "Affiliate") &&
                <Grid item xs={4}>
                  <InputLabel sx={{ fontWeight: "bold" }}>Affiliate</InputLabel>
                  <Box style={{ marginBottom: "7%" }}>
                    <MultiAutoComplete
                      fixedOptions={this.state.defaultAffiliate}
                      selectedItems={[...affiliateValue]}
                      options={this.state.subRegionHubValue.length === 0 ? this.state.affiliateDropDownOptionsDefault : this.state.affiliateDropDownOptions}
                      onChange={this.onSelectChangeAffiliate}
                    />
                  </Box>
                </Grid>
              }

              {
                (this.state.selectedOption && this.state.selectedOption.text === "Country") &&
                <Grid item xs={4}>
                  <Box style={{ marginBottom: "7%" }}>
                    <InputLabel sx={{ fontWeight: "bold" }}>Country</InputLabel>
                    <MultiAutoComplete
                      fixedOptions={this.state.defaultCountry}
                      selectedItems={[...countryValue]}
                      options={(this.state.subRegionHubValue.length === 0 && this.state.affiliateValue.length === 0) ? this.state.countryDropDownOptionsDefault : this.state.countryDropDownOptions}
                      onChange={this.onSelectChangeCountry}
                    />
                  </Box>
                </Grid>
              }
            </Grid>
          </>
        </Box>
        {/* </Box> */}
      </>
    )
  }
}

export default withRouter(SetGeoGraphy);
