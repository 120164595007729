import React from "react";

import { TextareaAutosize } from "@mui/material";

interface Props {
  id?: string;

  placeholder?: string;

  isDisabled?: boolean;

  isRequired?: boolean;

  value?: string | number;

  useDelay?: boolean;

  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

  backgroundColor?: string;

  focused?: boolean;

  width?: string;

  onFocus?: () => void;

  onBlur?: () => void;
  name?:string;
}

interface State {
  value: string;
}

class TextAreaInputField extends React.Component<Props, State> {
  public static defaultProps = {
    isDisabled: false,

    isRequired: true,

    useDelay: false,

    width: "96%",
  };

  private timer?: NodeJS.Timeout = undefined;

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      value: props.value ? props.value.toString() : "",
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { value: propsValue } = this.props;

    const { value: prevPropsValue } = prevProps;

    if (propsValue !== undefined && propsValue !== prevPropsValue) {
      this.setState({ value: propsValue ? propsValue.toString() : "" });
    }
  }

  render() {
    const {
      id,

      placeholder,

      isDisabled,

      isRequired,

      backgroundColor,

      focused,

      width,
      name,

      onFocus,

      onBlur,
    } = this.props;

    const { value } = this.state;

    const textAreaStyle: React.CSSProperties = {
      width: width,

      fontSize: "16px",

      fontFamily: "Helvetica",

      resize: "none",

      background: "#fff",

      color: "#000000de",

      borderRadius: "5px",

      padding: "15px",

      borderColor: "#0000003e",

      outlineColor: focused ? "#3f51b5" : "#0000003e",

      backgroundColor: backgroundColor,
    };

    return (
      <TextareaAutosize
        style={textAreaStyle}
        required={isRequired}
        disabled={isDisabled}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={this.handleChange}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    const { useDelay } = this.props;

    if (useDelay) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.setState({ value }, () => {
        this.timer = setTimeout(() => {
          this.props.onChange(event);
        }, 1000);
      });
    } else {
      this.setState({ value }, () => {
        this.props.onChange(event);
      });
    }
  };
}

export default TextAreaInputField;
