import { Box, Divider, Grid, InputLabel } from "@mui/material";
import React from "react";  
import SelectModel from "../../../../Components/Select/SelectModel";
import { FileUploadForTranslated } from "../../../../Models/FileUploadModelForTranslated";
import BackLinkButton from "../../../../Components/Button/BackLinkButton";
import PageHeader from "../../../../Components/Text/PageHeader";
import SingleSelect from "../../../../Components/Select/SingleSelect";
import { UploadTranslated } from "../../../../Models/UploadTranslatedFile";
import { RouteComponentProps } from "react-router";
import FileUpload from "../../../../Components/File/FileUpload";
import { DocumentType, FileType } from "../../../../Common/Enums";
import FileUploadDetailCard from "../../../../Components/File/FileUploadDetailCard";
import RedButton from "../../../../Components/Button/RedButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { EmailDTO, LocalProcedureTemplate } from "../../../../Models";
import { EmailService, ProcedureFileService } from "../../../../Services";
import { cloneDeep } from "lodash";
import Utils from "../../../../Common/Utils";
import MyCustomSnackbar from "../../../../Components/SnackBar/MyCustomSnackbar";
import IconButton from "../../../../Components/Button/IconButton";
import lptService from "../../../../Services/LPTService";

interface Props extends RouteComponentProps<any, any, {
  editTemplate?: LocalProcedureTemplate,
  lpId?: number,
  backButtonPressed?: boolean,
}> {
  
}

const procedureLanguages =  [
{ text: "Chinese Simplified", value: "Chinese Simplified" },
{ text: "Chinese Traditional", value: "Chinese Traditional" },
{ text: "Czech", value: "Czech" },
{ text: "French France", value: "French France" },
{ text: "French Canada", value: "French Canada" },
{ text: "German", value: "German" },
{ text: "Greek", value: "Greek" },
{ text: "Hungarian", value: "Hungarian" },
{ text: "Italian", value: "Italian" },
{ text: "Japanese", value: "Japanese" },
{ text: "Korean", value: "Korean" },
{ text: "Polish", value: "Polish" },
{ text: "Portuguese (BR)", value: "Portuguese (BR)" },
{ text: "Portuguese (PT)", value: "Portuguese (PT)" },
{ text: "Slovak", value: "Slovak" },
{ text: "Spanish (LATAM)", value: "Spanish (LATAM)" },
{ text: "Spanish (Spain)", value: "Spanish (Spain)" },
{ text: "Turkish", value: "Turkish" },
]
interface State {
  files: FileUploadForTranslated[];
  count: { [index: string]: number }
  isError: boolean;
  isSuccess: boolean;
  isEmailError: boolean;
  uploadMessage: string;
  totalRecordNo: number | null;
  isSubmitted: boolean;
  submittedMsg: string;
  disbldSubmit: boolean;
  submittedErrMsg: string;
  isSubmtdError: boolean;
  isLoading: boolean;
  uploadTranslated: UploadTranslated;
  isUploaded: boolean;
  submitDisable: boolean;
  isSentForApproval: boolean;
  backButtonPressed: boolean;
  deleted:boolean;
}

class FileUploadProcedure extends React.Component<Props, State> {

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      files: [],
      uploadTranslated: {
        fileName: "",
        language: "",
        s3FilePathKey: "",
        lpId: 0,
       _file: {} as FileUploadForTranslated
      },
      deleted:false,
      // allFilesData: {} as FilesUploadModel,
      isError: false,
      isEmailError: false,
      isSuccess: false,
      uploadMessage: "",
      totalRecordNo: null,
      isSubmitted: false,
      submittedMsg: "",
      disbldSubmit: false,
      submittedErrMsg: "",
      isSubmtdError: false,
      isSentForApproval: false,
      isLoading: false,
      isUploaded: false,
      submitDisable: true,
      backButtonPressed: false,
      count: {}
    };
  }

  componentDidMount() {


  }
  action = (
    <>
      {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
      <IconButton
        IconType="Cancel"
        onClick={() => {
         
        }}
      />
    </>
  );

  render() {
    const { editTemplate, lpId } = this.props.history.location?.state;
    console.log("editTempprop", editTemplate)
    console.log("lpId",lpId);
    
    console.log("isUploaded", this.state.isUploaded)

    return (
      <>
         <MyCustomSnackbar
              message="Translated procedure uploaded successfully."
              severity="success"
              // actions={this.action}
              open={this.state.isUploaded}
              onClose={() => {
                this.setState({isUploaded: false})
              }}
            />
            <MyCustomSnackbar
              message="Local Procedure sent for approval successfully."
              severity="success"
              // actions={this.action}
              open={this.state.isSentForApproval}
              onClose={() => {
                this.setState({isSentForApproval: false})
              }}
            />
        <MyCustomSnackbar
          message="You can only upload word document"
          severity="warning"
          actions={this.action}
          open={this.state.isError}
          onClose={() => {
            this.setState({ isError: false });
          }}
        />
        <MyCustomSnackbar
          message="Error submitting procedure !"
          severity="warning"
          // actions={this.action}
          open={this.state.isEmailError}
          onClose={() => {
            this.setState({ isEmailError: false });
          }}
        />
        <Grid container marginBottom={0.5}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}
          >
            <Grid item>
            <Grid container spacing={2} style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Grid item>
              <BackLinkButton onClick={() => {
                // this.props.history.push("/upload_procedures")
                // this.props.history.goBack();
                // this.setState({backButtonPressed: true},()=>{
                //   this.props.history.push({pathname: '/eco_doc_viewer', state:{
                //     editTemplate: this.props.location.state.editTemplate,
                //     lpId: this.props.location.state.editTemplate?.lpId, 
                //     backButtonPressed: true} })
                // })
                this.handleBackButtonClick()
                
              }
              } />
            </Grid>
            <Grid
              item
              sx={{
                wordWrap: "break-word",
                wordBreak: "break-all",
                maxWidth: "42em",
              }}
            >
              <PageHeader label="Upload File" />
            </Grid>
            </Grid>
            </Grid>


            <Grid item marginBottom={1}>
              <RedButton label="Submit for Approval" onClick={this.onSubmit} disabled={this.state.submitDisable} />
            </Grid>
          </Grid>

        </Grid>
        
        <Grid>
          <Divider />
          <Grid container spacing={2} marginTop={0.5}>
            <Grid item xs={6}><InputLabel>Select Language</InputLabel>
              <SingleSelect id='language' value={this.state.uploadTranslated.language}
                values={procedureLanguages}
                onChange={this.onDropDownChange} />
            </Grid>

          </Grid>
          <Box sx={{
            marginTop: '2em',
            border: '1px dashed Red',
            borderStyle: 'dashed',
            borderWidth: '3px',
            borderRadius: '20px',
          }
          }>
            <Grid container justifyContent="center">
              <Grid item style={{ height: '10em' }}>
                <FileUpload
                  onChange={this.handleFileUpload}
                  isDisabled={this.disableUpload()}
                />
              </Grid>
            </Grid>
            {/* {Object.keys(this.state.uploadTranslated?._file!).length !== 0? <Grid container justifyContent={"center"} style={{ marginBottom: '1rem' }}>
              <RedButton startIcon={<FontAwesomeIcon icon={faArrowUp} />} onClick={this.handleFileUploadToDB} label="Upload and Submit" />
            </Grid> : <></>} */}
          </Box>
          {this.state.isSubmitted
            ? <Grid container direction="row" justifyContent={'center'}>
              <Grid item container justifyContent={'center'}>
                <FileUploadDetailCard
                  tableData={`${this.state?.uploadTranslated.fileName}`}
                  files={this.state.uploadTranslated._file!}
                  onDeleteClick={this.handleDeleteClick}
                  deleted={this.state.deleted}
                />
              </Grid>
              <Grid item >
                {Object.keys(this.state.uploadTranslated?._file!).length !== 0 ? 
                <Grid marginTop={'2em'} >
                  <RedButton startIcon={<FontAwesomeIcon icon={faArrowUp} />} onClick={this.handleFileUploadToDB} label="Upload" />
                </Grid> 
                : <></>}
              </Grid>
            </Grid>
            : <></>}
        </Grid>
      </>
    );
  }

  handleFileUploadToDB = async () => {
    const { uploadTranslated } = this.state;
    const deepClone = cloneDeep(uploadTranslated);
    console.log("deepClone", deepClone._file!)
    const fileName = deepClone._file!.name.replace(/\s/g, "_")
    const s3FileKey = `localtranslated/${deepClone._file!.lpId}/${DocumentType.GPT_DOCUMENT}/${deepClone._file!.fileversion}/${fileName}`
    const uploadedFileName = deepClone._file!.name.replace('.docx', "");
    uploadTranslated.fileName = uploadedFileName;
    uploadTranslated.fileType = Utils.getFileTypeNumber(deepClone._file!.type);
    uploadTranslated.language = deepClone._file!.language;
    uploadTranslated.lpId = deepClone._file!.lpId;
    uploadTranslated.s3FilePathKey = s3FileKey;
    uploadTranslated._file!.s3filekey = s3FileKey;
    this.setState({ uploadTranslated: { ...uploadTranslated, file: deepClone._file!.file! } });

    const uploadResponse = await ProcedureFileService.uploadLpApprovalFileUpload(uploadTranslated._file!);
    const tableEntry = await ProcedureFileService.uploadLpApprovalFileUploadTableEntry(uploadTranslated)
    console.log("uploadResponse", uploadResponse);
    console.log("tableEntry", tableEntry);
    this.setState({isSubmitted: false, isUploaded: true, submitDisable: false},async ()=>{
      setTimeout(() => {
      ProcedureFileService.docx2pdf(this.state.uploadTranslated.s3FilePathKey, FileType.DOCX);
        }, 2500);
    })
  }

  onSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    console.log("on submit ", this.props);
    console.log("send for approval clicked");
    // this.setState({ submitDialog: true })

    const { editTemplate } = this.props.location.state
    const result = await lptService.sendForApproval(editTemplate?.lpId!);
      if (result) {
        const env = Utils.getEnvVars();
        // const approverEmail = editTemplate?.modifyByEmail!
        const emailObj: EmailDTO = {
          to: editTemplate?.approverEmail!,
          cc: editTemplate?.modifyByEmail!,
          subject: `ACTION REQUIRED: ${editTemplate?.templateName} Procedure Review`,
          body: ` <p><a href="${env.UI_URL}/approver_doc_viewer/${editTemplate?.lpId}">Review Draft Procedure</a></p>
                <p>Hi ${editTemplate?.approverName},</p>
                <p>
                  <b>${editTemplate?.templateName}</b> procedure, version ${editTemplate?.versionNumber}, for ${editTemplate?.geoGraphyName} requires your attention.
                  As the approver, you are receiving this message with a link to procedure documents for your review and approval. We request your review within
                  one week of receipt of the approval request.
                </p>
                <p>
                  In order to access the ${editTemplate?.templateName}, click on the "Review Draft Procedure” link at the top of this message.
                </p>
                <p>
                  Please contact your Ethics and Compliance liaison <b>${editTemplate?.modifyBy}</b> with any questions regarding this procedure localization or the process. To view all procedures pending your review, you can access your <a href="${env.UI_URL}/approve_procedure">dashboard</a>.
                </p>
                <p>
                  Thank you for your time and diligence in completing review of this procedure.  
                </p>
                <p>
                  Regards,  
                </p>
                <p>
                  Ethics and Compliance Policy Center of Expertise.
                  <br>
                  <b><i>DO NOT RESPOND TO THE SENDER OF THIS MESSAGE</i></b>
                </p>`
          // body: "testing"
        };
        const emailSent = await EmailService.sendEmail(emailObj);
        console.log("email sent",emailSent);

        if (emailSent) {
          this.setState({ isSentForApproval: true }, () => {
            setTimeout(async () => {
              this.props.history.push(`local_procedures`)
              // creating a clean doc,pdf and storing in s3 so eco can download, approver can view clean doc
              let filePath;
              if (editTemplate?.ipFilePath && editTemplate.ipFilePath.endsWith('.docx')) {
                ProcedureFileService.docx2pdf(editTemplate.ipFilePath, FileType.DOCX);
                await ProcedureFileService.cleandocument(
                  editTemplate.ipFilePath,
                  FileType.DOCX
                );
  
                const cleanDocPath = editTemplate.ipFilePath.split('.docx');
                filePath = cleanDocPath[0] + '_clean.docx';
                console.log("clean doc path", filePath);
              } else {
                console.log("Invalid input file path");
              }
  
              if (filePath) {
                ProcedureFileService.docx2pdf(filePath, FileType.DOCX);
              } else {
                console.log("File path is undefined");
              }
            }, 2000)
          }
          ) 
        } else {
          this.setState({isEmailError: true})
        }
      }
  }

  handleDeleteClick = () => {
    this.setState({uploadTranslated: {...this.state.uploadTranslated,_file: {} as FileUploadForTranslated, file: {} as File},deleted:true})
  }

 


  onDropDownChange = (selected: SelectModel) => {
    const { uploadTranslated } = this.state;
    const value = selected.value;
    this.setState({
      uploadTranslated: { ...uploadTranslated, language: value },
    });
  };

  handleFileUpload = (file: FileUploadForTranslated) => {
    const { uploadTranslated } = this.state
    const fileData = file.file;

    if (fileData) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(fileData);
      const fileModel = {} as FileUploadForTranslated
      fileReader.onload = async () => {
        const { name, size, type } = fileData;
        fileModel.name = name;
        fileModel.sizeInKB = Math.round(size / 1024);
        fileModel.sizeInMB = Math.round(size / 1024 / 1024);
        fileModel.type = type as FileType;
        fileModel.ext = type.replace(/(.*)\//g, "");
        fileModel.file = fileData;
        fileModel.language = uploadTranslated.language;
        fileModel.lpId = this.props.history.location?.state?.lpId!;
        fileModel.fileversion = this.props.history.location?.state?.editTemplate!.versionNumber!;
        console.log("fileData", fileModel);
        if (fileModel.ext === "vnd.openxmlformats-officedocument.wordprocessingml.document") {
          this.setState({ uploadTranslated: { ...uploadTranslated, _file: fileModel }, isSubmitted: true,deleted:false });
        } else {
          this.setState({ isError: true });
        }
      }
    };
  };

  disableUpload = () => {
    if (this.state.uploadTranslated.language === "") {
      return true
    }
    return false
  }

  handleSubmitClick = async () => {

  };

  handleBackButtonClick = () => {
    const { history, location } = this.props;

    console.log("on back button click",history);
    

    // You can use the following code to navigate back and send the state
    history.goBack(); // Navigate back
  //   const stateToSend = {
  //     ...location.state,
  //     backButtonPressed: true
  //   };
  //   history.replace({ state: stateToSend }); // Update the state of the previous page
  // };

  // this.setState({backButtonPressed: true},()=>{
  //                 this.props.history.push({pathname: `/eco_localization/${location.state.lpId}?mode=view`, state:{
  //                   // editTemplate: this.props.location.state.editTemplate,
  //                   // lpId: this.props.location.state.editTemplate?.lpId, 
  //                   backButtonPressed: true} })
  //               })
  }

  
}

export default FileUploadProcedure;