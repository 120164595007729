import React, { Context } from "react";
import { Redirect, RedirectProps } from "react-router-dom";
import * as H from 'history';
import { RoleContext } from "../../Contexts";
import { AuthorizationService } from "../../Services";

export class RedirectRoute extends React.Component<RedirectProps, any> {
    static contextType?: Context<any> = RoleContext;
    context!: React.ContextType<typeof RoleContext>;

    render(): React.ReactNode {
        let redirectTo: H.LocationDescriptor<unknown> = '';

        const isAdmin = AuthorizationService.isAdminUser(this.context);
        if (isAdmin) {
            redirectTo = this.props.to ?? '/procedure_templates';
        }

        const isECO = AuthorizationService.isECOUser(this.context);
        if (!redirectTo && isECO) {
            redirectTo = '/local_procedures';
        }

        const isReadonly = AuthorizationService.isReadonlyUser(this.context);
        if (!redirectTo && isReadonly) {
            redirectTo = '/procedure_templates';
        }

        const isGlobal = AuthorizationService.isGlobalonlyUser(this.context);
        if (!redirectTo && isGlobal) {
            redirectTo = '/procedure_exception';
        }

        return <Redirect from="/" to={redirectTo} />;
    }
}