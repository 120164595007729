import React, { Context } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import MenuModel from "./MenuModel";
import MenuItem from "./MenuItem";
import Menus from "./Menus";
import { AuthorizationService } from "../../../Services";
import { RoleContext } from "../../../Contexts";

interface Props extends RouteComponentProps<any> { }
interface State { }
class Menu extends React.Component<Props, State> {
  readonly Menus: MenuModel[] = Menus;
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;

  render() {
    const { location } = this.props;
    const { userRoles } = this.context;

    if (userRoles.length === 0) {
      return null;
    }

    if (location.pathname === "/proceduretool") {
      return null;
    }

    let menuItems: any[] = [];

    this.Menus.forEach((menu) => {
      const isAuthorize = AuthorizationService.isUserAuthorize(menu.userRoles, this.context);
      menu.isSelected = menu.path === location.pathname;

      if (isAuthorize) {
        menuItems.push(<MenuItem key={menu.name} menu={menu} onClick={this.handleMenuClick} />);
      }
    });

    return menuItems;
  }

  handleMenuClick = (menu: MenuModel) => {
    const { location } = this.props;
    // localStorage.setItem("currentTab", '');
    sessionStorage.setItem("currentTab", '');
    
    if (!(location.pathname === "/proceduretool")) {
      this.props.history.push(menu.path);
    }
    
    //this.props.history.go(0);
  };
}

export default withRouter(Menu);
