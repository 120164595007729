import { Box, Table as MaterialTable, styled, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Theme, Checkbox, CircularProgress, FormControlLabel, Pagination, Grid, FormControl, MenuItem, Select, Typography } from '@mui/material'
import { Component, ReactNode } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { isEqual, isNull } from 'lodash';
import { v4 as uuid } from "uuid";
import IconButton, { IconType } from '../Button/IconButton';
import { TableSingleView } from '../../Models/TableSingleView';
import MultiSelectCheckBox from '../Select/MultiSelectCheckBox';

type SortType = {
  key: string;
  direction: string;
}

interface Props extends RouteComponentProps<any, any, any> {
  headCellData: { key: string, label: string, linkTo: string | null, elementToLoad?: ReactNode, orderBy: string, type?: string }[];
  sortConfig: SortType | null;
  data: TableSingleView[];
  totalRecordsCount: number;
  onClickCheckBox?: ((selectedRows: any) => void);
  onChangeRow: ((row: number) => void);
  onChangePage: ((page: number) => void);
  requestSort?: ((key: string) => void);
  actionArray?: IconType[]
  onActionClick?: ((action: IconType, record: any) => void);
  onClickLink?: ((fieldClicked: string, record: any) => void);
  rowsPerPage?: number;
  rowTotalCount?: number;
  page?: number;
  onSelectChange?: ((name: string[], id: number) => void);
  tableMode?: string;
}

interface State {
  sortConfig: SortType | null;
  data: any;
  selectedData: TableSingleView[];
  selectAll: boolean;
  rowsPerPage: number;
  page: number;
  caretHover: boolean;
  open: boolean;
  openPopup: boolean,
  selectedCheckboxes: any[],
  currentExpenseId: number,
  checked: boolean[];
  anchorEl: any;
  down: boolean;
  countryValues: string[];
  countryOptions: string[];
  currentRowId: number;
}


const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class Table extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      sortConfig: null,
      data: [],
      selectAll: false,
      selectedData: [],
      rowsPerPage: 10,
      page: 0,
      caretHover: false,
      open: false,
      openPopup: false,
      selectedCheckboxes: [],
      currentExpenseId: 0,
      checked: [],
      anchorEl: null,
      down: false,
      countryValues: [],
      currentRowId: 0,
      countryOptions: [
        'India',
        'USA',
        'UK',
        'China',
        'Armenia',
        'Bosnia']
    }
  }

  componentDidMount = () => {
    this.setState({ data: this.props.data })
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    console.log("table componentDidUpdate callling")
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data })
    }
    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.setState({ rowsPerPage: this.state.rowsPerPage })
    }
    if (this.props.onClickCheckBox) {
      if (!isEqual(prevState.data, this.state.data)) {
        console.log("dadadta", this.state?.data);
        this.setState({ selectedData: this.state?.data?.filter((el: any) => el.checked === true) });
      }
      if (!isEqual(prevState.selectedData, this.state.selectedData)) {
        this.props.onClickCheckBox(this.state.selectedData);
      }
    }

  }

  requestSort = (key: string) => {
    // console.log("key==", key);
    // const { sortConfig } = this.state;
    // let direction = 'ascending';
    // if (sortConfig) {
    //   if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    //     direction = 'descending';
    //   }
    // }
    // console.log("sortdata =", sortConfig);
    // this.setState({ sortConfig: { key, direction } });
    // this.sorter()
    if (key != "Action") {
      if (this.props.requestSort) {
        this.props.requestSort(key);
      }
    }

  }

  sorter = () => {
    const { sortConfig, data } = this.state;
    let sortedData = data;
    if (sortConfig !== null) {
      sortedData = sortedData.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    this.setState({ data: sortedData })
    return sortedData;
  }

  handleChangePage = (_event: any, page: any) => {
    console.log("next page", page);
    this.setState({ page: page })
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value })
    this.props.onChangeRow(event.target.value);
  };

  defaultSortIconColor = '#959595';
  activeSortIconColor = '#000000';

  faCaretUpColor = this.activeSortIconColor;
  faCaretDownColor = this.activeSortIconColor;
  faUpDownColor = this.defaultSortIconColor;

  positionArrow = (key: string, orderBy: string) => {
    if (key != "Action") {
      if (orderBy === 'ASC')
        return <FontAwesomeIcon icon={faChevronUp} color={this.faCaretUpColor} size="xs" />
      if (orderBy === 'DESC')
        return <FontAwesomeIcon icon={faChevronDown} color={this.faCaretDownColor} size="xs" />
      return <FontAwesomeIcon icon={faChevronDown} color={this.faCaretDownColor} size="sm" />
    }
  }

  onClickCheckBox = (event: React.ChangeEvent<HTMLInputElement>, action: "single" | "all", singleRow?: TableSingleView) => {
    if (this.props.onClickCheckBox) {
      switch (action) {
        case 'single':
          const dataMapped = this.state.data.map((el: any) => {
            if (el.gpId === singleRow?.id) {
              return ({ ...el, checked: event.target.checked });
            }
            return el;
          });
          this.setState({ data: dataMapped });
          if (dataMapped.some((d: any) => d.checked === false) && this.state.selectAll) {
            this.setState({ selectAll: false });
          } else if (dataMapped.every((d: any) => d.checked === true) && !this.state.selectAll) {
            this.setState({ selectAll: true });
          }
          break;
        case 'all':
          this.setState({ data: this.state.data.map((d: any) => ({ ...d, checked: event.target.checked })) });
          this.setState({ selectAll: event.target.checked });
          break;
        default:
          break;
      }


    }
  }

  onClickButton = (action: "view" | "cancel" | "delete" | "back") => {
    switch (action) {
      case "view":
        this.props.history.push("")
        this.props.history.go(0);
        break;
      default:
        break;
    }
  }
  onClickLink = (fieldClicked: string, record: any) => {
    if (this.props.onClickLink) {
      this.props.onClickLink(fieldClicked, record);
    }
  }

  renderNormalCell = (el: any, expense: any) => {
    return (
      <TableCell key={uuid()} style={{ fontSize: '16px' }}>
        {
          !isNull(el?.linkTo) ?
            <Box sx={{ color: "#0075A2", cursor: "pointer" }} onClick={() => { this.onClickLink(el.key, expense) }} >{expense[el?.key]}</Box> :
            <>  {expense[el?.key]}{el.elementToLoad} 
            {el?.key === "Action" ? <Box style={{ display: "flex", gap: 2 }}>{this.props.actionArray?.map((action) => <IconButton IconType={action}
              onClick={() => {
                if (this.props.onActionClick) {
                  this.props.onActionClick(action, expense)
                }
              }} />)}</Box> : null}
            </>
        }
      </TableCell>
    )
  }

  handleTooltipOpen = () => {
    console.log("handleTooltipOpen");
    this.setState({ open: true });
  };

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleGeographicalScopeToggleDown = (id: any) => {
    // this.setState({ openPopup: true, currentExpenseId: id ,down:true});
    this.setState({ down: false, currentExpenseId: id, anchorEl: null });
  }

  handleGeographicalScopeToggleUp = (id: any, event: React.MouseEvent<HTMLElement>) => {
    // this.setState({ openPopup: false, currentExpenseId: id  , });
    console.log("event=", event);
    this.setState({ down: true, currentExpenseId: id, anchorEl: event.currentTarget });

  };

  handleClosePopup = () => {
    this.setState({ down: false });
  };

  handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    //  this.setState([event.target.checked, this.state.checked[1]]);
  };

  handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    //  this.setState([this.state.checked[0], event.target.checked]);
  };

  children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Child 1"
        control={<Checkbox checked={true} onChange={this.handleChange2} />}
      />
      <FormControlLabel
        label="Child 2"
        control={<Checkbox checked={true} onChange={this.handleChange3} />}
      />
    </Box>
  );

  handleCheckboxChange = (event: any) => {
    const checkboxValue = event.target.value;
    const { selectedCheckboxes } = this.state;

    if (selectedCheckboxes.includes(checkboxValue)) {
      this.setState((prevState) => ({
        selectedCheckboxes: prevState.selectedCheckboxes.filter(
          (checkbox) => checkbox !== checkboxValue
        ),
      }));
    } else {
      this.setState((prevState) => ({
        selectedCheckboxes: [...prevState.selectedCheckboxes, checkboxValue],
      }));
    }
  };


  handleClose = () => {
    this.setState({ down: false });
  };

  // shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
  //   // Compare the current state with the next state
  //   if (this.state.countryValues !== nextState.countryValues) {
  //     return false;
  //   }
  //   return true;
  // }


  onSelectChange = (target: string[], id: number) => {
    console.log("target==", target);
    this.setState({ currentRowId: id })
    this.setState({ countryValues: target });
    if (this.props.onSelectChange) {
      this.props.onSelectChange(target, id);
    }
  }

  renderCustomisedCell = (el: any, expense: any, onSelectChange: any) => {
    return (
      <TableCell key={uuid()} style={{ fontSize: '16px' }}>
        <Box style={{ marginBottom: "7%" }}>
          <MultiSelectCheckBox
            options={this.state.countryOptions}
            values={this.state.currentRowId == expense.gpId ? this.state.countryValues : []}
            id={expense.gpId}
            onSelectChange={onSelectChange}
          />
        </Box>
      </TableCell>
    )
  }



  render() {
    if (!this.props.data) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }

    const { headCellData, totalRecordsCount, onClickCheckBox } = this.props;
    const { data, selectAll } = this.state;
    console.log("data", data);
    const { rowTotalCount, rowsPerPage, page } = this.props;
    const isTotalCountLess = (rowTotalCount ?? 0) <= (rowsPerPage ?? 0);
    
    return (
      <Box>
        <>
          <TableContainer component={Paper} >
            <MaterialTable stickyHeader sx={{ minWidth: 'fit-content' }} aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* {onClickCheckBox ? <TableCell style={{ fontSize: '16px', borderBottom: '2px solid red' }}><Checkbox inputProps={{ 'aria-label': 'controlled' }} size='small' checked={selectAll} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onClickCheckBox(event, "all")} /></TableCell>
                    : null} */}
                  {headCellData.map((el, index) => {
                    if (el && el.key !== "gpId") {
                      return <TableCell draggable key={`${index}-head`} style={{ fontSize: '16px', borderBottom: '2px solid red', whiteSpace: "nowrap" }} onClick={() => this.requestSort(el.key)}>
                        {el.label} {" "}{this.positionArrow(el.key, el.orderBy)}</TableCell>
                    }
                    return null;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (data?.map((expense: any, id: any) => {
                  return (
                    <StyledTableRow key={id}>
                      {/* {onClickCheckBox ? <TableCell style={{ fontSize: '16px' }}><Checkbox size='small' inputProps={{ 'aria-label': 'controlled' }} checked={expense.checked || false} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onClickCheckBox(event, "single", expense)} /></TableCell>
                        : null} */}
                      {headCellData.map(el => {
                        if (el?.key !== "gpId") {
                          if (el?.type && el?.type == "dropDown") {
                            return this.renderCustomisedCell(el, expense, this.onSelectChange)
                          }
                          else {
                            return this.renderNormalCell(el, expense)
                          }
                        }
                        return null;
                      })}
                    </StyledTableRow>
                  )
                })) : null}
              </TableBody>
            </MaterialTable>
          </TableContainer>
        </>
        {
          this.props.tableMode !== "ActiveException" &&
          <>
            <br />
            <Grid container md={12} justifyContent="flex-end" alignItems="center" spacing={2}>
              <Grid item>
                <Pagination size='medium'
                  count={Math.ceil((rowTotalCount ?? 0) / (rowsPerPage ?? 1))} page={page} color="primary"
                  showFirstButton showLastButton onChange={this.handleChangePage} />
              </Grid>
              <Grid item>
                <FormControl size="small">
                  <Select
                    value={rowsPerPage}
                    onChange={this.handleChangeRowsPerPage}
                    autoWidth
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography>{`${isTotalCountLess ? rowTotalCount : (rowsPerPage ?? 0) * (page ?? 0)  >  (rowTotalCount ?? 0) ? rowTotalCount:(rowsPerPage ?? 0) * (page ?? 0) } of ${rowTotalCount} records per page`}</Typography>
              </Grid>
            </Grid>
          </>
        }
      </Box>
    )
  }
}

export default withRouter(Table);