import { Box, Typography } from '@mui/material'
import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle as infoIcon
} from "@fortawesome/free-solid-svg-icons";

interface Props {
    msg?: string;
 }

interface State { }

export default class NoRecords extends Component<Props, State> {
    state = {}

    render() {
        return (
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <FontAwesomeIcon icon={infoIcon} style={{ color: "#007c91" }} />
                <Typography>{ this.props.msg?? 'No Records to Display'}</Typography>
            </Box>
        )
    }
}