import React from "react";

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, Divider } from "@mui/material";

interface Props {
    isOpen: boolean;
    title?: string;
    dialogAction?: JSX.Element;
    dialogWidth: DialogProps["maxWidth"];
    onClose: (() => void);
    children?: React.ReactNode;
}
interface State {}

class ModalDialog extends React.Component<Props, State>{
    public static defaultProps = {
        isOpen: false,
        title: 'Dialog Modal',
        dialogWidth: 'lg',
    };

    render() {
        const { isOpen, onClose, title, children, dialogAction, dialogWidth } = this.props;
        return(
            <Dialog open={isOpen} fullWidth={true} maxWidth={dialogWidth} onClose={onClose}>
                <Divider orientation='horizontal' style={{marginBottom: 10, marginLeft: 20, marginRight: 20}}/>
                <DialogContent>{children}</DialogContent>
                <DialogActions>{dialogAction}</DialogActions>
            </Dialog>
        )
    }
}

export default ModalDialog;