import AxiosMiddleware from './AxiosMiddleware';

class Middleware {
    apply() {
        // Register axios interceptors
        AxiosMiddleware.registerInterceptors();
    }
}

const middleware = new Middleware();
export default middleware;