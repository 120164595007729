import { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import TextInputField from '../../Components/TextInputField/TextInputField'
import { RenewalForm } from '../../Models/RenewalForm'
import { Box, Grid, InputLabel, Typography } from '@mui/material'
import TextAreaInputField from '../../Components/TextInputField/TextAreaInput'
import DatePicker from '../../Components/DatePicker/DatePicker'
import CollapsibleCard from '../../Components/Card/CollapsibleCard'
import SingleSelect from '../../Components/Select/SingleSelect'
import SelectModel from '../../Components/Select/SelectModel'

interface Props extends RouteComponentProps<any, any, any> {
    renewalForm: RenewalForm;
    onTextInputChange: ((event: any) => void);
    handleExpirationDateChange: ((value: Date | null) => void);
    handleLegalConsultDateChange: ((value: Date | null) => void);
    handleProcedureOwnerDateChange: ((value: Date | null) => void);
    onDropDownChange: ((selected: SelectModel) => void);
}

const RenewalStatus = [
    { text: "Active", value: "Active" },
    { text: "Inactive", value: "Inactive" }
]

interface State {

}

class ExceptionRenewalFormComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {

        }
    }

    render() {

        const { renewalForm, onTextInputChange, handleExpirationDateChange, handleLegalConsultDateChange, handleProcedureOwnerDateChange } = this.props;
        return (
            <CollapsibleCard
                title='Renewal Form'
                divider
                initiallyOpen
                titleStyle={{ fontSize: '26px' }}

            >
                <Box
                    style={{
                        // marginTop: "1.5em",
                        marginLeft: "0.4em"
                    }}
                >
                    <Grid container style={{ display: 'flex', justifyContent: "flex-end" }}>
                        <Typography style={{ fontStyle: 'italic', fontSize: '14px' }} color={"red"}>All Fields Required</Typography>
                    </Grid>
                    <Grid container>
                        <Grid container>
                            <Grid item xs={6} marginBottom={"0.5em"}>
                                <InputLabel sx={{
                                    '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                    },
                                }} required>Title</InputLabel>
                                <TextInputField name="title" onChange={onTextInputChange} value={renewalForm.title} />
                            </Grid>
                        </Grid>
                        <Grid container marginBottom={"0.5em"}>
                            <InputLabel sx={{
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }} required>Exception Summary</InputLabel>
                            <TextAreaInputField onChange={onTextInputChange} name="exceptionSummary" value={renewalForm.exceptionSummary} />
                        </Grid>
                        <Grid container spacing={2} columns={6} marginBottom={"0.5em"}>
                            <Grid item xs={2}>
                                <InputLabel sx={{
                                    '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                    },
                                }} required>Renewal #</InputLabel>
                                <TextInputField isDisabled type="number" onChange={onTextInputChange} name="renewal" value={renewalForm.renewal} />
                            </Grid>
                            <Grid item xs={2}>
                                <InputLabel sx={{
                                    '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                    },
                                }} required> Renewal Status</InputLabel>
                                {/* <TextInputField isDisabled onChange={onTextInputChange} name="status" value={renewalForm.status} /> */}
                                <SingleSelect isDisabled defaultValue='InActive' name='exceptionStatus' value={renewalForm.exceptionStatus} values={RenewalStatus} onChange={this.props.onDropDownChange} />
                            </Grid>
                            <Grid item xs={1.89}>
                                <InputLabel sx={{
                                    '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                    },
                                }} required>Renewal Requested By</InputLabel>
                                <TextInputField onChange={onTextInputChange} name="renewalRequestedBy" value={renewalForm.renewalRequestedBy} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} columns={4} marginBottom={"0.5em"}>
                            <Grid item xs={2}>
                                <InputLabel sx={{
                                    '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                    },
                                }} required>Renewal Submitted By</InputLabel>
                                <TextInputField isDisabled onChange={onTextInputChange} name="renewalSubmittedBy" value={renewalForm.renewalSubmittedBy} />
                            </Grid>
                            <Grid item xs={1.93}>
                                <InputLabel sx={{
                                    '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                    },
                                }} required>Expiration Date</InputLabel>
                                <DatePicker minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)} maxDate={new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() + 2)} onChange={handleExpirationDateChange} name="status" value={renewalForm.expirationDate} />
                            </Grid>
                            {/* <Grid item xs={2}>
                            <InputLabel>Policie(s)</InputLabel>
                            <TextInputField onChange={onTextInputChange} name="policies" value={renewalForm.policies} />
                        </Grid> */}
                        </Grid>
                        <Grid container marginBottom={"0.5em"}>
                            <InputLabel sx={{
                                '& .MuiFormLabel-asterisk': {
                                    color: 'red',
                                },
                            }} required>Rationale for Renewal</InputLabel>
                            <TextAreaInputField onChange={onTextInputChange} name="rationaleForRenewal" value={renewalForm.rationaleForRenewal} />
                        </Grid>
                        <Grid container spacing={2} columns={6} marginBottom={"0.5em"}>
                            <Grid item xs={2}>
                                <InputLabel sx={{
                                    '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                    },
                                }} required>Legal Renewal Consult</InputLabel>
                                {/* <TextInputField onChange={onTextInputChange} name="legalRenewalConsult" value={renewalForm.legalRenewalConsult} /> */}
                                <DatePicker onChange={handleLegalConsultDateChange} name="legalRenewalConsultDate" value={renewalForm.legalRenewalConsultDate} />
                            </Grid>
                            {/* <Grid item xs={4}>
                            <InputLabel>Legal Consult Date</InputLabel>
                            <DatePicker onChange={handleLegalConsultDateChange} name="legalConsultDate" width='23em' value={renewalForm.legalConsultDate} />
                        </Grid> */}
                        </Grid>
                        {/* <Grid container marginBottom={"0.5em"}>
                        <InputLabel>Legal Comments</InputLabel>
                        <TextAreaInputField onChange={onTextInputChange} name="legalComments" value={renewalForm.legalComments} />
                    </Grid> */}
                        <Grid container spacing={2} columns={6} marginBottom={"0.5em"}>
                            {/* <Grid item xs={2}>
                            <InputLabel>Procedure Owner</InputLabel>
                            <TextInputField onChange={onTextInputChange} name='procedureOwner' value={renewalForm.procedureOwner} />
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabel>Procedure Owner Consult Date</InputLabel>
                            <DatePicker onChange={onTextInputChange} name='procedureOwnerConsultDate' width='23em' value={renewalForm.procedureOwnerConsultDate} />
                        </Grid> */}
                            <Grid item xs={2}>
                                <InputLabel sx={{
                                    '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                    },
                                }} required>Procedure Owner Renewal Consult</InputLabel>
                                <DatePicker onChange={handleProcedureOwnerDateChange} name='procedureOwnerRenewalDate' value={renewalForm.procedureOwnerRenewalDate} />
                            </Grid>
                        </Grid>
                        {/* <Grid container marginBottom={"0.5em"}> 
                        <InputLabel>Procedure Owner Comments</InputLabel>
                        <TextAreaInputField onChange={onTextInputChange} value={renewalForm.procedureOwnerComments} name="procedureOwnerComments" />
                    </Grid> */}
                    </Grid>
                </Box>
            </CollapsibleCard>
        )
    }
}

export default withRouter(ExceptionRenewalFormComponent);