import { ImageRun, IImageOptions } from "docx";
import DocxUniqueIdAttribute from "./DocxUniqueIdAttribute";

export default class DocxImageRun extends ImageRun {
    constructor(options: IImageOptions, uid: string | null) {
        super(options);

        if (uid) {
            this.root.push(new DocxUniqueIdAttribute({ uid }));
        }
    }
}