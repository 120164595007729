import React, { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom';
import { Box, Button, Divider, Grid, InputLabel, Typography } from "@mui/material";
import SelectModel from '../../Components/Select/SelectModel';
import WhiteButton from '../../Components/Button/WhiteButton';
import SingleSelect from '../../Components/Select/SingleSelect';
import TextInputField from '../../Components/TextInputField/TextInputField';
import RedButton from '../../Components/Button/RedButton';
import BreadCrumb from '../../Components/BreadCrumb';
import PageHeader from '../../Components/Text/PageHeader';
import { LocalProcedureDeviation, LocalProcedureTemplate } from '../../Models';
import BreadCrumbModel from '../../Components/BreadCrumb/BreadCrumbModel';
import { LPTService } from '../../Services';
import BackLinkButton from '../../Components/Button/BackLinkButton';
import { LocalProcedureTemplateSection } from '../../Models/LocalProcedureTemplateSection';
import IconButton from '../../Components/Button/IconButton';
import MyCustomSnackbar from '../../Components/SnackBar/MyCustomSnackbar';
import FileUploadModelForLP from '../../Models/FileUploadModelForLP';
import Utils from '../../Common/Utils';
import lptService from '../../Services/LPTService';

interface Props extends RouteComponentProps<any, any, {
  editTemplate: LocalProcedureTemplate, sections: LocalProcedureTemplateSection[]
}> {

}
interface State {
  errMsg: string;
  successMag: string;
  throwErr: boolean;
  throwSuccess: boolean;
  affiliateRegion: string;
  name: string;
  selectProcedure: string;
  exceptionLink: string;
  deviationReason: string;
  localProcedureVersionNumber: string;
  globalProcedureVersionNumber: string;
  tab: string;
  showDeviation: boolean;
  breadCrumbs: BreadCrumbModel[];
  localizeTemplate: LocalProcedureTemplate;
  localizeSections: LocalProcedureTemplateSection[];

}

const AffiliateRegionOptions = [
  { text: "Japan", value: "Japan" },
  { text: "India", value: "India" },
  { text: "USA", value: "Usa" },
];

const NameOptions = [
  { text: "Satoshi Nakamoro", value: "Satoshi Nakamoro" },
  { text: "Satish", value: "Satish" },
  { text: "Kunal", value: "Kunal" },
];

const SelectProcedureOptions = [
  { text: "Communicating Externally", value: "Communicating Externally" },
  { text: "Communicating Internally", value: "Communicating Internally" },
];

const ReasonDeviationOptions = [
  { text: "Activity is entirely prohibited in this country", value: "Activity is entirely prohibited in this country" },
  { text: "Certain requirements are more restricted based on the local laws,codes,business", value: "Certain requirements are more restricted based on the local laws,codes,business" },
  { text: "Requirements are instead contained in the local SOPS(eg. CIQ) or other local documentation(eg. guidance ,job,aids)", value: "Requirements are instead contained in the local SOPS(eg. CIQ) or other local documentation(eg. guidance ,job,aids)" },
  { text: "Deviation is allowed according to the Global Template", value: "Deviation is allowed according to the Global Template" },
  { text: "False Positive", value: "False Positive" },
];

export default class DeviationPage extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      localizeTemplate: {
        templateName: "",
        basicPrinciples: "",
        basicPrinciples_blackTextChangeType: "",
        bodyText: "",
        bodyText_blackTextChangeType: "",
        approver: "",
        approverEmail: "",
        approverName: "",
        effectiveDate: null,
        footer: "",
        footerImageFile: "",
        header: "",
        header_blackTextChangeType: "",
        headerImageFile: "",
        owner: "",
        ownerName: "",
        ownerEmail: "",
        procedureType: "",
        versionNumber: "",
        ipFilePath: "",
        _basicPrinciples: Utils.getEditorState(),
        _bodyText: Utils.getEditorState(),
        _headerImageFile: {} as FileUploadModelForLP,
        _footerImageFile: {} as FileUploadModelForLP,
        _ipFilePath: {} as FileUploadModelForLP,
        _header: Utils.getEditorState(),
        createdBy: ""
      },
      localizeSections: [],
      affiliateRegion: '',
      selectProcedure: '',
      exceptionLink: '',
      deviationReason: '',
      localProcedureVersionNumber: '',
      globalProcedureVersionNumber: '',
      tab: '3',
      showDeviation: false,
      breadCrumbs: [
        {
          label: 'Local Procedures',
          onClick: () => {
            this.props.history.push('/local_procedures');
          }
        },
        {
          label: 'Deviation Form'
        }
      ],
      errMsg: "",
      successMag: "",
      throwErr: false,
      throwSuccess: false
    }
  };

  componentDidMount(): void {
    this.setState({
      localizeTemplate: this.props.location?.state?.editTemplate,
      localizeSections: this.props.location?.state?.sections
    })

  }

  affiliateChange = async (selected: SelectModel) => {
    this.setState({ affiliateRegion: selected.value });
  };

  nameChange = (selected: SelectModel) => {
    this.setState({ name: selected.value });
  };

  reasonDeviationChange = (selected: SelectModel) => {
    this.setState({ deviationReason: selected.value });
  };


  localProcedureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("even", event.target.name, event.target.value);
    if (event) {
      // let template: any = Object.assign({}, this.props.template);
      // template[event.target.name] = event.target.value;
      // this.props.onTemplateChange(template);
      this.setState({ localProcedureVersionNumber: event.target.value });
    }
  };

  globalProcedureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("event.target.value =", event.target.value);
    console.log("even", event.target.name, event.target.value);
    if (event) {
      // let template: any = Object.assign({}, this.props.template);
      // template[event.target.name] = event.target.value;
      // this.props.onTemplateChange(template);
      this.setState({ globalProcedureVersionNumber: event.target.value });
    }
  };


  handleCreateForm = async () => {
    this.setState({ showDeviation: true })
  };

  validationChecker = () => {
    const { affiliateRegion, deviationReason, globalProcedureVersionNumber, localProcedureVersionNumber, selectProcedure, name } = this.state;
    let final: boolean = true;

    if (affiliateRegion === "") {
      final = false
      this.setState({ errMsg: "Please select affiliate region" })
    }
    else if (name === "") {
      final = false
      this.setState({ errMsg: "Please select name" })
    }
    else if (selectProcedure === "") {
      final = false
      this.setState({ errMsg: "Please select procedure" })
    }
    else if (deviationReason === "") {
      final = false
      this.setState({ errMsg: "Please provide reason for deviation" })
    }
    else if (globalProcedureVersionNumber === "") {
      final = false
      this.setState({ errMsg: "Please provide globalProcedureVersionNumber" })
    }
    else if (localProcedureVersionNumber === "") {
      final = false
      this.setState({ errMsg: "Please provide localProcedureVersionNumber" })
    }
    this.setState({ throwErr: !final });
    return final
  }

  buttonClick = (tabNumber: string) => {
    this.setState({ tab: tabNumber })
  }

  renderDeviationFormShow = () => {
    const { errMsg, successMag, throwErr, throwSuccess } = this.state
    console.log("tabNumber ==")
    return (
      <>
        <MyCustomSnackbar
          message={errMsg}
          severity="warning"
          actions={<></>}
          open={throwErr}
          onClose={() => {
            this.setState({ throwErr: false });
          }}
        />
        <MyCustomSnackbar
          message={successMag}
          severity="success"
          actions={this.action}
          open={throwSuccess}
          onClose={() => {
            this.setState({ throwSuccess: false });
          }}
        />
        <Box sx={{
          height: '400px',
          paddingTop: '1em',
        }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Affiliate/Region</InputLabel>
              <Box style={{ marginBottom: "7%" }}>
                {this.state.affiliateRegion}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Name</InputLabel>
              <Box style={{ marginBottom: "7%" }}>
                {this.state.name}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box style={{ marginBottom: "7%" }}>
                <InputLabel sx={{
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }} required>Age</InputLabel>
                {this.state.selectProcedure}
              </Box>
            </Grid>

          </Grid>

          <Grid item xs={4}>
            <InputLabel>
              Approved Exception Link
            </InputLabel>
            {this.state.exceptionLink}
          </Grid>
          <br></br>

          <Grid item xs={4}>

            <InputLabel sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }} required>Reason For Deviation</InputLabel>
            <Box>
              {this.state.deviationReason}
            </Box>
          </Grid>


          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>Enter the verisoin number of the local procedure used when the deviation<br></br> was created</InputLabel>
              {this.state.localProcedureVersionNumber}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>Enter the verisoin number of the Global procedure Template used when the<br></br> deviation was created</InputLabel>
              {this.state.globalProcedureVersionNumber}
            </Grid>
          </Grid>
        </Box>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item><WhiteButton onClick={this.cancelButton} label="Cancel" /></Grid>
            <Grid item><WhiteButton label="Save" onClick={this.handleSave} /></Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  exceptionLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("even", event.target.name, event.target.value);
    if (event) {
      this.setState({ exceptionLink: event.target.value });
    }
  };

  selectProcedureChange = (selected: SelectModel) => {
    this.setState({ selectProcedure: selected.value });
  };

  cancelButton = () => {
    this.setState({ showDeviation: false })
  }

  handleLocalizeTemplate = async () => {
    const { localizeTemplate, localizeSections } = this.state;
    const lptresult = await lptService.update({
      localProcedure: localizeTemplate,
      localProcedureSections: localizeSections,
      localProcedureFiles: [],
    });
  }

  handleSave = async () => {
    const { affiliateRegion, deviationReason, exceptionLink, globalProcedureVersionNumber, localProcedureVersionNumber, name, selectProcedure } = this.state;
    const lpd: LocalProcedureDeviation = {
      affiliateRegion,
      approvedExceptionLink: exceptionLink,
      gpVersionNumber: Number.parseInt(globalProcedureVersionNumber),
      lpVersionNumber: Number.parseInt(localProcedureVersionNumber),
      name,
      reasonForDeviation: deviationReason,
      selectedProcedure: selectProcedure,
      gpId: this.props.location.state.editTemplate.gpId,
      lpId: this.props.location.state.editTemplate.lpId,
    };
    await this.handleLocalizeTemplate()
    await LPTService.createDeviation(lpd);
    this.setState({ throwSuccess: true, successMag: "Deviation has been created successfully!" })
  }

  action = (
    <>
      <Button
        size="small"
        style={{ textDecoration: "underline", color: "#0075A2" }}
        onClick={() => { }}
      >
        VIEW
      </Button>
      {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ throwSuccess: false });
        }}
      />
    </>
  );

  renderDeviationForm = () => {
    return (
      <>
        <form onSubmit={this.handleCreateForm}>
          <Box sx={{
            height: '400px',
            paddingTop: '1em',
          }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputLabel sx={{
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }} required>Affiliate/Region</InputLabel>
                <Box style={{ marginBottom: "7%" }}>
                  <SingleSelect
                    required={true}
                    name="affiliateRegion"
                    value={this.state.affiliateRegion}
                    values={AffiliateRegionOptions}
                    onChange={this.affiliateChange}
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <InputLabel sx={{
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }} required>Name</InputLabel>
                <Box style={{ marginBottom: "7%" }}>
                  <SingleSelect
                    required={true}
                    name="name"
                    value={this.state.name}
                    values={NameOptions}
                    onChange={this.nameChange}
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box style={{ marginBottom: "7%" }}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Age</InputLabel>
                  <SingleSelect
                    required={true}
                    name="selectProcedure"
                    value={this.state.selectProcedure}
                    values={SelectProcedureOptions}
                    onChange={this.selectProcedureChange}
                  />
                </Box>
              </Grid>

            </Grid>

            <Grid item xs={8}>
              <InputLabel>
                Approved Exception Link
              </InputLabel>
              <TextInputField
                placeholder="Enter a Link to the approved Exception"
                onChange={this.exceptionLinkChange}
                name="approver"
                value={this.state.exceptionLink}
              />

            </Grid>
            <br></br>
            <Grid item xs={8}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Reason For Deviation</InputLabel>
              <Box>
                <SingleSelect
                  required={true}
                  name="affiliateRegion"
                  value={this.state.deviationReason}
                  values={ReasonDeviationOptions}
                  onChange={this.reasonDeviationChange}
                />
              </Box>
            </Grid>


            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel sx={{
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }} required>Enter the version number of the local procedure used when the deviation<br></br> was created</InputLabel>
                <TextInputField
                  type='number'
                  onChange={this.localProcedureChange}
                  name="approver"
                  value={this.state.localProcedureVersionNumber}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel sx={{
                  '& .MuiFormLabel-asterisk': {
                    color: 'red',
                  },
                }} required>Enter the version number of the global procedure Template used when <br></br> the deviation was created</InputLabel>
                <TextInputField
                  id="outlined-number"
                  type='number'
                  onChange={this.globalProcedureChange}
                  name="approver"
                  value={this.state.globalProcedureVersionNumber}
                />
              </Grid>
            </Grid>
          </Box>


          <Grid item>
            <Grid container spacing={1}>
              <Grid item><WhiteButton label="Cancel" onClick={() => { this.props.history.goBack() }} /></Grid>
              <Grid item><WhiteButton label="Save" onClick={this.handleSave} /></Grid>
              <Grid item><RedButton label='Generate' type="submit" /></Grid>
            </Grid>
          </Grid>
        </form>
      </>

    )
  }

  render() {
    return (
      <Box
        style={{
          marginTop: "0.5em",
        }}
      >
        {/* <Grid marginBottom={"2em"} container direction="row">
          <BreadCrumb breadCrumbs={this.state.breadCrumbs} />
        </Grid> */}
        <Grid container direction={'row'} style={{
          alignItems: 'center',
          marginBottom: '1em',
          justifyContent: 'space-between'
        }}>
          <Grid item>
            <Grid container spacing={2} sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <Grid item>
                <Typography style={{ color: "#0d98ba" }}>
                  <BackLinkButton
                    onClick={() => {
                      //this.props.history.goBack();
                      let state: any = this.props.location.state;
                      this.props.history.push(`/eco_localization/${this.props.history?.location?.state?.editTemplate?.lpId}/mode=edit`, { ...state, "from": "/deviation" });
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                maxWidth: '42em'
              }}>
                <PageHeader label={`PCOE_Pro_${this.props?.location?.state?.editTemplate.templateName}_EN_Japan(v${this.props?.location?.state?.editTemplate.versionNumber})`} />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Grid>
        {!this.state.showDeviation && this.renderDeviationForm()}
        {this.state.showDeviation && this.renderDeviationFormShow()}
      </Box>
    )
  }
}