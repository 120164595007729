import React from 'react';
import Reassign from './ReAssign.svg'
import Edit from './Edit.svg'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Grid, IconButton as IButton, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCloudUploadAlt as CloudUploadIcon,
    faFileLines as FileUpload,
    faTrash as DeleteForeverIcon,
    faPlus as AddIcon,
    faPaperclip as AttachIcon,
    faExternalLinkAlt as PopupIcon,
    faCheck as checkIcon,
    faXmark as cancelIcon,
    faEnvelopeCircleCheck as EmailApprover,
    faUserPen as AssignForCorrection,
    faUserPlus as AssigntoApprover,
    faArrowRotateForward as ArrowRotate,
    faCopy as Copy,
    faPlusCircle as AddPlus,
    faCircleInfo as Info,
} from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import WhiteButton from "./WhiteButton";
import BlueButton from "./BlueButton";
import ModalDialog from '../Modal/ModelDialog';
import RedButton from './RedButton';

export type IconType = 'Add' | 'Edit' | 'FileUpload' | 'CloudUpload'| 'AssigntoApprover' | 'Delete' | 'EmailOwner' | 'EmailApprover' | 'DeleteDialog' | 'AttachIcon' | 'Popup' | 'ReAssign' | 'Approve' | 'Cancel' | 'AssignForCorrection' | 'ArrowRotate' | 'Copy' | 'AddPlus' | 'Info' ;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 15,
        maxWidth: "none",
        cursor: "pointer",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "white",
        fontSize: 20,
    },
}));
interface Props {
    id?: string;
    IconType: IconType;
    isFR: boolean;
    isDisabled: boolean;
    onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}
interface State {
    isDialogOpen: boolean;
}

class IconButton extends React.Component<Props, State> {
    public static defaultProps = {
        isFR: false,
        isDisabled: true
    }
    constructor(props: Props | Readonly<Props>) {
        super(props)

        this.state = {
            isDialogOpen: false
        }
    };
    render() {
        const { id, IconType, isFR, onClick, isDisabled } = this.props;
        const { isDialogOpen } = this.state;

        const buttonStyle: React.CSSProperties = { color: '#EE3A29' };
        const btnStyle: React.CSSProperties = { color: '#EE3A29',cursor:'pointer' };
        let CloudIconSize: SizeProp = 'xl';

        if (isFR) {
            buttonStyle.backgroundColor = '#004693';
            CloudIconSize = 'xs';
        }

        if (isDisabled) {
            btnStyle.color = '#616161';
        }
        switch (IconType) {
            case 'Add':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Add"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={AddIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'CloudUpload':
                return (
                    <IButton style={btnStyle} disabled={isDisabled} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={CloudUploadIcon} size={CloudIconSize} />
                    </IButton>
                );
            case 'FileUpload':
                return (
                    <IButton style={btnStyle} disabled={isDisabled} onClick={onClick}>
                         <LightTooltip
                            placement="bottom"
                            title="Upload"
                            arrow
                        >

                        <FontAwesomeIcon id={id} icon={FileUpload} size={"2x"} />
                        </LightTooltip>
                    </IButton>
                );
            case 'Edit':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Edit"
                            arrow
                        >
                            <img src={Edit} alt='Edit' />
                        </LightTooltip>
                    </IButton>
                );
            case 'Delete':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Delete"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={DeleteForeverIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'ReAssign':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="ReAssign"
                            arrow
                        >
                            <img src={Reassign} alt="Reassign" width='80%' />
                        </LightTooltip>
                    </IButton>
                );
            case 'AssigntoApprover':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Assign to Approver"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={AssigntoApprover} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'Approve':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Approve"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={checkIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'EmailOwner':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        {/* <FontAwesomeIcon id={id} icon={EmailOwner} size="xs" /> */}
                        <LightTooltip
                            placement="bottom"
                            title="Email Owner"
                            arrow
                        >
                            <ContactMailIcon />
                        </LightTooltip>
                    </IButton>
                );
            case 'EmailApprover':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Email Approver"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={EmailApprover} size="sm" />
                        </LightTooltip>
                    </IButton>
                );
            case 'AssignForCorrection':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Assign For Correction"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={AssignForCorrection} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'Cancel':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Close"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={cancelIcon} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'DeleteDialog':
                return (
                    <>
                        <ModalDialog title={`Delete Action`} dialogWidth="sm"
                            isOpen={isDialogOpen} onClose={this.handleCancel}
                            dialogAction={
                                <Grid container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{ marginRight: 30 }}>
                                    <Grid item >
                                        <WhiteButton label="Cancel" onClick={this.handleCancel} />
                                    </Grid>
                                    <Grid item >
                                        {isFR ? <BlueButton label="Delete" onClick={this.onDeleteClick} />
                                            : <RedButton label="Delete" onClick={this.onDeleteClick} />}
                                    </Grid>
                                </Grid>
                            }>
                            <Typography style={{ fontSize: 16, fontWeight: 400, color: '#383838' }}>
                                Are you sure you want to delete ?
                            </Typography>
                        </ModalDialog>
                        <IButton style={buttonStyle} onClick={this.handleDelete}>
                            <FontAwesomeIcon id={id} icon={DeleteForeverIcon} size="xs" />
                        </IButton>
                    </>
                );
            case 'AttachIcon':
                return (
                    <IButton style={btnStyle} disabled={isDisabled} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={AttachIcon} color={'#D52B1E'} size={CloudIconSize} />
                    </IButton>
                );
            case 'Popup':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={PopupIcon} size='xs' />
                    </IButton>
                );
            case 'ArrowRotate':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Exception Renewal"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={ArrowRotate} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'Copy':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Copy"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={Copy} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
            case 'AddPlus':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <LightTooltip
                            placement="bottom"
                            title="Add"
                            arrow
                        >
                            <FontAwesomeIcon id={id} icon={AddPlus} size="xs" />
                        </LightTooltip>
                    </IButton>
                );
                case 'Info':
                    return (
                        <IButton style={buttonStyle} onClick={onClick}>
                            <LightTooltip
                                placement="bottom"
                                title="View Comments"
                                arrow
                            >
                                <FontAwesomeIcon id={id} icon={Info} size="xs" />
                            </LightTooltip>
                        </IButton>
                    );

            default:
                return <></>;

        }
    }

    onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.handleCancel();
        this.props.onClick(event);
    }

    handleDelete = () => {
        this.setState({ isDialogOpen: true });
    }

    handleCancel = () => {
        this.setState({ isDialogOpen: false });
    }
}

export default IconButton;