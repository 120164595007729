import { faPaperclip, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { Component } from "react";
import { FileType } from "../../Common/Enums";
import MyCustomSnackbar from "../SnackBar/MyCustomSnackbar";
interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>,file: File | null, imagePreview: string | null) => void;
  name?: string;
  label?: string;
  accept?: string;
  maxWidth?: string;
  file?: File | null;
  imageUrl?: string | null;
  isDisabled?: boolean;
  onCancel?: () => void
}
interface State {
  file?: File | null;
  imagePreview?: string | null;
  imageUrl?: string | null;
  throwErr:boolean;
  errMsg: string;
}
class ImageUploadFooter extends Component<Props, State> {
  fileUploadInput: HTMLInputElement | null = null;
  constructor(props: Props) {
    super(props);
    this.state = { file: this.props.file, imagePreview: this.props.imageUrl || null,
      throwErr:false,
    errMsg:"",
      imageUrl: this.props.imageUrl || null };
  }

  componentDidUpdate(prevProps: Props) { if (prevProps.imageUrl !== this.props.imageUrl) { this.setState({ imagePreview: this.props.imageUrl, imageUrl: this.props.imageUrl, }); } }
  render() {
    const { name, label ,isDisabled} = this.props;
    const { file, imagePreview,errMsg,throwErr } = this.state;
    return (
      <Box>
        <MyCustomSnackbar
              message={errMsg}
              severity="warning"
              actions={<></>}
              open={throwErr}
              onClose={() => {
                this.setState({ throwErr: false });
              }}
            />
        <Grid container flexDirection={"row"} xs={12}>
          <Grid item container xs={3}>
            <Grid item>
              <label htmlFor={name}>
                <IconButton aria-label="upload picture" component="span">
                  <FontAwesomeIcon icon={faPaperclip} color="#D52B1E" />
                </IconButton>
              </label>
            </Grid>
            <Grid item>
              <label htmlFor={name}>
                <Typography  onChange={this.handleChange} style={{ marginTop: "0.5em", color: "#D52B1E" }}>
                  {label || "Attach Image"}
                </Typography>
                <input
                  accept="image/jpeg image/png"
                  id={name}
                  type="file"
                  onChange={this.handleChange}
                  style={{ display: 'none' }}
                  ref={(input) => {
                    this.fileUploadInput = input;
                  }}
                  name={name}
                  disabled={isDisabled}
                />

              </label>
            </Grid>
          </Grid>
          {imagePreview && (
            <Grid item container xs={9} justifyContent={"flex-end"}>
              <Grid item mt={"0.5em"}>
                <img
                  src={imagePreview}
                  alt="Logo"
                  style={{
                    marginRight: "1em",
                    maxHeight: "30px",
                    
                  }}
                    
                />
              </Grid>
              <Grid item mt={"0.5em"}>
                <Typography style={{ marginRight: "1em" }}>
                  {file?.name}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={this.handleCancel}
                  disabled={isDisabled}
                >
                  <FontAwesomeIcon icon={faTimesCircle} color="red" />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if((file.type === FileType.JPG || file.type === FileType.PNG)) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.setState({
          file,
          imagePreview: event.target?.result?.toString() ?? null,
          imageUrl:null
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({ file: null, imagePreview: null, imageUrl: null,throwErr:true,errMsg:"Footer logo should be in PNG or JPG format" });
    }
    this.props.onChange(event,file, this.state.imagePreview!);
  }
  };
  handleCancel = () => {
    if (this.fileUploadInput) {
      this.fileUploadInput.value = "";
    }
    this.setState({ file: null, imagePreview: null, imageUrl: null }, () => {
      this.props.onCancel!() 
    });
  };
}

export default ImageUploadFooter;
