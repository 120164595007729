import axios from "axios";
import { omit } from "lodash";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import gptService from '../../../Services/GPTService'
import { Box, CircularProgress, Grid, InputLabel } from "@mui/material";
import SearchFilter from "../../../Components/Search/SearchFilter";
import { IconType } from "../../../Components/Button/IconButton";
import moment from "moment";
import DataLoaderApproverView from "../../../Components/Table/DataLoaderApproverView";
import lptService from "../../../Services/LPTService";
import { AuthProviderService } from "../../../Services";
import Utils from "../../../Common/Utils";

interface Props extends RouteComponentProps<any, any, any> { }

type SortType = {
  key: string;
  direction: string;
}

interface State {
  templateData: any;
  page: number;
  order:string;
  orderBy:string;
  rows: number;
  limit:number;
  gptId?: number;
  search: string;
  searchedData: any
  isloading: boolean;
  searchDebounce: string;
  approverEmail: string;
  sortConfig: SortType | null;
  rowTotalCount: number | null;
  tab:number;
}

class ApprovalPendingTab extends Component<Props, State> {
  private searchTimeout : number | null = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      tab:0,
      sortConfig: null,
      templateData: [],
      page: 1,
      rows: 5,
      limit:5,
      search: "",
      searchDebounce:"",
      order:"",
      orderBy:"",
      approverEmail: "",
      searchedData: [],
      isloading: false,
      rowTotalCount: 0
    };
  }
  async componentDidMount(): Promise<void> {
    const { accessToken } = await AuthProviderService.getAccessToken();
    const loggedInUserToken = Utils.decodeJWT<any>(accessToken);
    this.setState({ approverEmail: loggedInUserToken.preferred_username }, () => {
      const querySearch = {
        page: this.state.page,
        limit: this.state.limit,
        searchtext: this.state.searchDebounce,
        order: this.state.order,
        orderBy: this.state.orderBy,
        approveremail: this.state.approverEmail,
        status: "Pending Approval",
      }
      this.getLocalProcedure(querySearch);
    })
    console.log("loggedInUserToken=", loggedInUserToken.preferred_username);

  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      (prevState.page !== this.state.page ||
        prevState.limit !== this.state.limit ||
        prevState.searchDebounce !== this.state.searchDebounce
        || prevState.sortConfig !== this.state.sortConfig)
    ) {
      const querySearch = {
        page: this.state.page,
        limit: this.state.limit,
        searchtext: this.state.searchDebounce,
        order: this.state.sortConfig?.key,
        orderBy: this.state.sortConfig?.direction,
        approveremail: this.state.approverEmail,
        status: 'Pending Approval',
      }
      this.getLocalProcedure(querySearch);
    }
    // if (prevState.search !== this.state.search) { this.searchfield() }
  }

  searchfield = () => {
    const { templateData, search } = this.state
    let searcher = templateData.filter((el: any) =>
      el.templateName.startsWith(search.toString()))

    if (search.length > 0) {
      this.setState({ searchedData: searcher })
    } else {
      console.log(searcher, 'else'); this.setState({ searchedData: templateData })
    }
  };


  handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ search: event.target.value }, () => {
      console.log("search", this.state.search);
      if (this.state.search.length === 0) {
        this.setState({ searchDebounce: this.state.search });
      }
    })
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.setState({ searchDebounce: this.state.search });
    }
  };

  mappingLocalProcedureData(localProcedures: any, localProcedureFileUpload: any, count: number) {
    // Iterate over templateDataArray and update properties 
    localProcedures.forEach((localProcedure: {s3FilePathKey: any;fileName: any; lpId: any}) => {
      const matchingMapping = localProcedureFileUpload.filter((mapping: { lpId: any; }) => mapping.lpId === localProcedure.lpId);
      console.log("match",matchingMapping);
      
      if (matchingMapping.length > 0) {
        // Get the highest lpfuid from matchingMapping
        const highestLpfuid = matchingMapping.reduce((prev: any, current: any) => (prev.lpfuid > current.lpfuid ? prev : current));
        localProcedure.fileName = highestLpfuid.fileName;
        localProcedure.s3FilePathKey = highestLpfuid.s3FilePathKey;
        // localProcedure.fileName = matchingMapping[0].fileName
        // localProcedure.s3FilePathKey = matchingMapping[0].s3FilePathKey
      }
      else{
        localProcedure.fileName =""
        localProcedure.s3FilePathKey = ''
      }
    });
    console.log("loacl peocedure",localProcedures);
    this.setState({ templateData: localProcedures });
  }

  getLocalProcedure = async (querySearch: any) => {
    this.setState({ isloading: true });
    try {
      const data = await gptService.getLocalProcedure(querySearch);
      // console.log("here____",data);
      const formatedData = data?.localProcedures.map((el) => {
        const accessedDate = el.accessedDate ? moment(el.accessedDate).format("L") : "Not Accessed";
        return { ...el, modifiedDate: moment(el.modifiedDate).format("L"),accessedDate, templateName: `${el.templateName}_${el.geoGraphyName ? el.geoGraphyName:""}(v${el.versionNumber})` };
      });
      const omitData = formatedData?.map((el) =>
        omit(el, [
          "headerImageFile",
          "versionNumber",
          "effectiveDate",
          "footer",
          "footerImageFile",
          "basicPrinciples",
          "header",
          "bodyText",
          "gpTemplateFile",
          "gpUniqueId",
          "createdBy",
          "_bodyText",
          "_basicPrinciples",
          "_headerImageFile",
          "_footerImageFile",
        ])
      );
      this.mappingLocalProcedureData(omitData, data?.localProcedureFileUpload, data?.count!);
      
      this.setState({ isloading: false });
      if (data) {
        this.setState({ rowTotalCount: data.count });
      }
    } catch (error) {
      this.setState({ isloading: true });
      if (axios.isAxiosError(error)) {
        this.setState({ isloading: false });
        console.log("error message:", error.message);
        return error.message;
      } else {
        this.setState({ isloading: false });
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }
      
    }
  };



  requestSort = (key: string) => {
    const { sortConfig } = this.state;
    let direction = 'ASC';
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === 'ASC') {
        direction = 'DESC';
      }
    }
    this.setState({ sortConfig: { key, direction }});
    // this.sorter()
  }

  onChangePage = (page: number) => {
    if(page == 0)
    {
      this.setState({ page :1 });
    }else{
      this.setState({ page });
    }
  };
  
  onChangeRow = (row: number) => {
    this.setState({ limit: row });
  };

  clickId = async (_fieldClicked: string, record: any) => {
    console.log("field clicked",record);
    if (_fieldClicked === 'fileName')
    {
      this.props.history.push({pathname : '/approver_translated_doc_viewer', state:{
        translatedFileDetail : record,
        // view:"approver  view"
      }})
    }
    else{
      console.log("record",record);
      if (record.accessedDate === 'Not Accessed'){
        const accessedDate = await lptService.approverProcedureAccess(record.lpId)
        console.log("Access date",accessedDate);
      }
      
      
      this.props.history.push({
        pathname: `/approver_doc_viewer/${record.lpId}`, state: {
        }
      });
      // this.props.history.go(0);
      // window.location.assign(`/approver_doc_viewer/${record.lpId}`);
    }
    
  };

  onActionClick = (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        window.location.assign(`/eco_localization/${record.lpId}?mode=edit`);
        break;
    }
  };

  render() {
    
    if (this.state.isloading) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    return (
      <>
        <Grid container spacing={2}>
          <Grid item>
            <InputLabel>Search Procedure or Geography</InputLabel>
            <SearchFilter value={this.state.search} onChange={this.handleSearch} onKeyDown={this.onKeyDown} />
          </Grid>

        </Grid>
        <DataLoaderApproverView
         tab={this.state.tab}
          sortConfig = {this.state.sortConfig}
          requestSort={this.requestSort}
          rowsPerPage={this.state.limit}
          tableData={this.state.templateData}
          actionArray={["Edit","ArrowRotate","Copy"]}
          onChangePage={this.onChangePage}
          onChangeRow={this.onChangeRow}
          onClickLink={this.clickId}
          rowTotalCount={this.state.rowTotalCount ? this.state.rowTotalCount:0}
          onClickCheckBox={() => { }}
          onActionClick={this.onActionClick}
          page={this.state.page}
        />
      </>
    );
  }
}

export default withRouter(ApprovalPendingTab);