import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { TextField, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface Props {
    selectedItems: any;
    fixedOptions: any;
    options: any;
    onChange: ((selectedValues: any) => void);
    disabled?: boolean;
}
interface State {
    // selectedItems: any;
}
class MultiAutoComplete extends React.Component<Props, State> {

    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            // selectedItems: []
        };
    }

    handleChange = (selectedValues: any) => {
        let newAddedValue :any[] = [];
        selectedValues.forEach((element: { text: any; }) => {
            const index = this.props.fixedOptions.findIndex((item:any)=>item.text === element.text);
            if(index === -1)
            {
                newAddedValue.push(element);
            }
        });
        this.props.onChange([...this.props.fixedOptions,
            ...newAddedValue]);
    }

    componentDidMount(): void {
         this.handleChange(this.props.selectedItems);
    }

    render() {
        const { options, selectedItems, disabled } = this.props;
        console.log("options ", options);
        console.log("options selectedItems =", selectedItems);
        let chipStyle: React.CSSProperties = { borderColor: '#EE3A29' };
        let disabledChipStyle: React.CSSProperties = { borderColor: 'red' }
        let cancelIconStyle: React.CSSProperties = { color: '#EE3A29', height: "15px" };

        return (
            <Autocomplete
                size={"small"}
                multiple
                autoComplete={false}
                disableCloseOnSelect
                disabled={disabled}
                options={options}
                getOptionLabel={(option) => option.text}
                value={selectedItems}
                onChange={(event, newValue) => {
                    console.log("newValue", newValue);
                    this.handleChange(newValue);
                }}

                renderOption={(props, option, { selected, inputValue, index }) => {
                    // if (selectedItems.includes('Immunology')) {

                    console.log("selected props ", props)
                    // }
                    return (<li {...props}>
                        <Checkbox
                            
                            checked={selectedItems?.some((el: any) => el.value === option?.value)}
                            onChange={() => {
                                console.log("checkbox render")
                                const selectedIndex = selectedItems?.findIndex((item: { value: any; }) => item.value === option.value);
                                let newSelectedItems = [...selectedItems];
                                if (selectedIndex === -1) {
                                    newSelectedItems.push(option);
                                } else {
                                    newSelectedItems.splice(selectedIndex, 1);
                                }
                                this.handleChange(newSelectedItems);
                            }}
                        />
                        {option.text }
                    </li>)
                }}

                renderInput={(params: any) => {
                    console.log("params", params);
                    return (<TextField {...params} />)
                }
            }

                renderTags={(value: any, getTagProps) =>
                    value.map((option: any, index: number) => (
                        <Chip variant="outlined" label={option.text} {...getTagProps({ index })}
                            disabled={this.props.fixedOptions?.indexOf(option) !== -1}
                            style={disabled ? disabledChipStyle : chipStyle}
                            deleteIcon={<FontAwesomeIcon
                                onClick={() => {
                                    const selectedIndex = selectedItems?.findIndex((item: { value: any; }) => item.value === option.value);
                                    let newSelectedItems = [...selectedItems];
                                    newSelectedItems.splice(selectedIndex, 1);
                                    // this.handleChange(newSelectedItems);
                                }}
                                size="lg" style={cancelIconStyle} icon={faClose} />} />
                    ))
                }
            />
        );
    }


}

export default MultiAutoComplete;