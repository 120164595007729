import React from "react";

import { Badge, Card, CardContent } from "@mui/material";

interface Props {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: React.CSSProperties;
  element?: React.ReactNode;
}
interface State {}

class TabPanel extends React.Component<Props, State> {
  render() {
    const { children, index, value, ...other } = this.props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Card
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "none",
            }}
          >
            <CardContent>{children}</CardContent>
          </Card>
        )}
      </div>
    );
  }
}

export default TabPanel;
