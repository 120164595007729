import React, { ChangeEvent } from "react";
import { Box, InputBaseComponentProps, TextField } from "@mui/material";
import { isNull, isUndefined } from "lodash";

interface Props {
  id?: string;
  label?: string;
  isReadonly: boolean;
  isDisabled: boolean;
  endAdornment?: React.ReactNode;
  value?: string | number;
  width?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  isRequired?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fieldStyle?: React.CSSProperties
  min?: number;
  max?: number
}
interface State { }
class TextInputField extends React.Component<Props, State> {
  public static defaultProps = {
    isReadonly: false,
    isDisabled: false,
    placeholder: "",
  };

  render() {
    const {
      id,
      min, max,
      label,
      isRequired,
      value,
      onChange,
      isDisabled,
      placeholder,
      width,
      name,
      type,
    } = this.props;

    let inputType = "text";
    let inputValue: string | number = "";
    const inputProps: InputBaseComponentProps = {};

    if (!isUndefined(min) && !isNull(min)) {

      inputProps.min = min;

    }

    if (!isUndefined(max) && !isNull(max)) {

      inputProps.max = max;

    }
    if (typeof value === "number") {
      inputType = "number";
    }

    if (value) {
      inputValue = value;
    }

    // onKeyDown = (event:any) {

    // }

    return (
      <Box style={{ width: width }}>
        <TextField
          type={type || inputType}
          variant="outlined"
          required={isRequired}
          size="small"
          name={name}
          disabled={isDisabled}
          fullWidth
          InputProps={{ onKeyDown: this.handleKeyDown }}
          inputProps={{ ...inputProps, required: isRequired, readOnly: this.props.isReadonly }}
          placeholder={placeholder}
          id={id}
          label={label}
          value={inputValue}
          autoComplete="off"
          
          // onKeyDown={this.}
          onChange={onChange}
          style={this.props.fieldStyle}
        />
      </Box>
    );
  }



  handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (this.props.name === "number") {
      const reg = new RegExp('^[0-9.]+$');
      if (!reg.test(event.key) && event.key !== "Backspace" && event.key !== "." && event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
        event.preventDefault();
      }
    } else if (this.props.id === 'versionNumber') {
      const reg = new RegExp('^[0-9]+$');
      if(!reg.test(event.key) && event.key !== "Backspace" && event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
        event.preventDefault()
      }
    }
  };

}

export default TextInputField;
