import {
  faInfoCircle,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Grid,
  InputLabel,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { EditorState } from "lexical/LexicalEditorState";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DatePicker from "../../Components/DatePicker/DatePicker";
import ImageUpload from "../../Components/ImageUpload/ImageUpload";
import RichTextEditor from "../../Components/LexicalEditor";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { GlobalProcedureTemplate } from "../../Models/GlobalProcedureTemplateModel";
import { GlobalProcedureTemplateSection } from "../../Models/GlobalProcedureTemplateSection";
import WhiteButton from "../../Components/Button/WhiteButton";
import ImageUploadFooter from "../../Components/ImageUpload/ImageUploadFooter";
import { LexicalEditor } from "lexical";
import Constants from "../../Common/Constants";
import { NumericFormat } from "react-number-format";


const ProcedureTypeOptions = [
  { text: "Global Procedure", value: "Global Procedure" },
  { text: "Local Procedure", value: "Local Procedure" },
];

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    maxWidth: "none",
    cursor: "pointer",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    fontSize: 20,
  },
}));


const materialUITextFieldProps = {

 id:'versionNumber'

};
interface Props extends RouteComponentProps {
  template: GlobalProcedureTemplate;
  sections: GlobalProcedureTemplateSection[];
  onTemplateChange: (template: GlobalProcedureTemplate) => void;
  onVersionNumberChange: (template: GlobalProcedureTemplate) => void;
  editorState: EditorState;
  onBodyTextEditorChange: (_bodyText: EditorState) => void;
  onBasicPrinciplesEditorChange: (_basicPrinciples: EditorState) => void;
  onHeaderEditorChange: (_header: EditorState, index?: number, editor?: LexicalEditor) => void;
  onSectionBodyTextChange: (_bodyText: EditorState, index: number) => void;
  onEffectiveDateChange: (value: Date | null) => void;
  onPolicyCommiteeAppr: (value: Date | null) => void;
  onProcedureTypeChange: (selected: SelectModel) => void;
  onHeaderImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFooterImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBodyTextAdd: () => void;
  onBodyTextRemove: (i: number) => void;
  onHeaderCancel: () => void;
  onFooterCancel: () => void;
  setApproverInputValue: ((InputValue: any) => void);
  setOwnerInputValue: ((InputValue: any) => void);
  onApproverNameSelect: ((InputValue: any) => void);
  onOwnerNameSelect: ((InputValue: any) => void);
  approverNames: any[];
  ownerNames: any[];
  approverSelectedItems: {};
  ownerSelectedItems: {}
  createTemplateValidation?: boolean;
  imageUrlHeader?: string | null;
  imageUrlFooter?: string | null;
  onSectionHeaderChange: (_header: EditorState, index: number, editor?: LexicalEditor) => void;
  ApproverNameLoading: boolean;
}

interface State {
  template: GlobalProcedureTemplate;
  formValidationState: { name: string; isValid: boolean }[];
}
class FormComponent extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    let isValid = false;
    if (props.template && props.template.gpId) {
      isValid = true;
    }
    this.state = {
      template: props.template,
      formValidationState: [{ name: "template", isValid }],
    };
  };
  addFormFields = () => {
    this.props.onBodyTextAdd();
  };

  removeFormFields(i: number) {
    this.props.onBodyTextRemove(i);
  }

  handleHeaderImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("formcompheader");
    this.props.onHeaderImageUpload(event);
  };
  handleFooterImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onFooterImageUpload(event);
  };

  handleHeaderEditorChange = (_header: EditorState, index?: number, editor?: LexicalEditor) => {
    this.props.onHeaderEditorChange(_header, index, editor);
  };

  onDropDownChange = (selected: SelectModel) => {
    this.props.onProcedureTypeChange(selected);
  };

  onHeaderPreviewCancelClick = () => {
    this.props.onHeaderCancel();
  }
  onFooterPreviewCancelClick = () => {
    this.props.onFooterCancel();
  }

  render() {
    const { template } = this.props;
    console.log("template gloval", template);
    return (
      <Box>
        <div>
          <Grid container spacing={3} mb={"1.5em"} mt={"0.1em"}>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Template Name</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the name of template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <TextInputField
                value={template.templateName}
                placeholder="Enter Template Header"
                onChange={this.handleTemplateFormChange}
                name="templateName"
              />
              <ImageUpload
                name="headerImageFile"
                onCancel={this.onHeaderPreviewCancelClick}
                onChange={this.handleHeaderImage}
                imageUrl={this.props.imageUrlHeader}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Procedure Type </InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the procedure Type of the template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <SingleSelect
                name="procedureType"
                defaultValue="Global Procedure"
                value={template.procedureType}
                values={ProcedureTypeOptions}
                onChange={this.onDropDownChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={"1.5em"}>
            <Grid item xs={4}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Version Number </InputLabel>

              {/* <TextInputField
                type="number"
                min={1}
                placeholder="Enter Version Number"
                name="versionNumber"
                onChange={this.handleVersionNumberChange}
                value={template.versionNumber}
              /> */}
              <NumericFormat
                name="versionNumber"
                value={template.versionNumber}
                onChange={this.handleVersionNumberChange}
                placeholder="Enter Version Number"
                min={1}
                customInput={TextInputField}
                suffix=".0" isDisabled={false} isReadonly={false}
                {...materialUITextFieldProps} 
                             />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Effective Date </InputLabel>
              <DatePicker
                name="effectiveDate"
                value={template.effectiveDate}
                onChange={this.handleEffectiveDateChange}
              // slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Policy Sub-Committee Approval</InputLabel>
              <DatePicker
                name="effectiveDate"
                value={template.policySubCommAppr}
                onChange={this.handlePolicyCommitteeAppr}
              // slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {template.procedureType === "Local Procedure" ? <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Owner Title</InputLabel> :
                    <InputLabel >Owner Title</InputLabel>}
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the title/designation of the person who owns this template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>

              <TextInputField
                placeholder="Enter Owner Title/Designation"
                onChange={this.handleTemplateFormChange}
                name="owner"
                value={template.owner}
                fieldStyle={{ color: "yellow" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {template.procedureType === "Local Procedure" ? <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Approver Title</InputLabel> :
                    <InputLabel >Approver Title</InputLabel>}

                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the title/designation of the person who approves this template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>

              <TextInputField
                placeholder="Enter Approver Title/Designation"
                onChange={this.handleTemplateFormChange}
                name="approver"
                value={template.approver}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel>Owner Name</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the name of the person who owns this template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <SearchableAutoComplete
                setInputValue={this.props.setOwnerInputValue}
                onSelect={this.props.onOwnerNameSelect}
                options={this.props.ownerNames}
                selectedItems={this.props.ownerSelectedItems}
                placeholder="Select Owner Name"
              />
            </Grid> */}
            {/* <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel>Approver Name</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the name of the person who approves this template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <SearchableAutoComplete
                setInputValue={this.props.setApproverInputValue}
                onSelect={this.props.onApproverNameSelect}
                options={this.props.approverNames}
                selectedItems={this.props.approverSelectedItems}
                loading={this.props.ApproverNameLoading}
                placeholder="Select Approver Name"
              />
            </Grid> */}
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Footer</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the footer of the template document "
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <TextInputField
                placeholder="Enter Footer Name"
                onChange={this.handleTemplateFormChange}
                name="footer"
                value={template.footer}
              />
              <ImageUploadFooter
                name="footerImageFile"
                onCancel={this.onFooterPreviewCancelClick}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.props.onFooterImageUpload(event)
                }
                imageUrl={this.props.imageUrlFooter}
              />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" style={{ height: 30 }} />
          <Grid container mt={"1.5em"} mb={"2em"}>
            <Grid item xs={11.5}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                    },
                  }} required>Basic Principles</InputLabel>
                </Grid>
                <Grid item xs={6} justifyContent={"flex-end"} container>
                  <LightTooltip
                    placement="bottom"
                    title="This is the principles of the template"
                    arrow
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                  </LightTooltip>
                </Grid>
              </Grid>
              <RichTextEditor
                onChange={this.handleBasicPrincipleChange}
                editorState={template._basicPrinciples}
                id="BasicPrinciples"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={11.5}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Header</InputLabel>
              <RichTextEditor
                defaultStyle={Constants.DocFontFamilyBold}
                skipDefaultFontFmily={true}
                onChange={this.handleHeaderEditorChange}
                editorState={this.state.template._header}
                id="Header"
              />
            </Grid>
          </Grid>
          <Grid container mt={"1em"} mb={"1em"}>
            <Grid item xs={11.5}>
              <InputLabel sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }} required>Body Text</InputLabel>

              <RichTextEditor
                onChange={this.handleBodyEditorChange}
                editorState={this.state.template._bodyText}
                id="BodyText"
              />
            </Grid>
          </Grid>
          {this.props.sections.map(
            (_element: GlobalProcedureTemplateSection, index: number) => {
              let currentNumber = index + 1;

              if (_element.sectionId) {
                currentNumber = _element.sectionId;
              }

              return (
                <Grid key={`section_${currentNumber}`}>
                  {currentNumber ? (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<FontAwesomeIcon icon={faXmark} />}
                        style={{ color: "red", textTransform: "capitalize" }}
                        onClick={() => this.removeFormFields(currentNumber)}
                      >
                        Remove
                      </Button>
                    </Box>
                  ) : null}
                  <Grid
                    container
                    marginTop="0.1em"
                    marginBottom={"1em"}
                    spacing={3}
                  >
                    <Grid item xs={11.5} >
                      <InputLabel >Header {currentNumber}</InputLabel>
                      <RichTextEditor
                        id={`section_header_${currentNumber}`}
                        defaultStyle={Constants.DocFontFamilyBold}
                        skipDefaultFontFmily={true}
                        editorState={this.props.sections[index]._header!}
                        index={index} onChange={(editorState: EditorState, index?: number, editor?: LexicalEditor) => {
                          this.props.onSectionHeaderChange(editorState, index!, editor)
                        }} />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    marginTop="0.1em"
                    marginBottom={"2em"}
                    spacing={3}
                  >
                    <Grid item xs={11.5}>
                      <InputLabel style={{ marginBottom: '0.1rem' }}>Body Text {currentNumber}</InputLabel>
                      <RichTextEditor
                        index={index}
                        onChange={(editorState: EditorState, index?: number) =>
                          this.props.onSectionBodyTextChange(
                            editorState,
                            index!
                          )
                        }
                        id={`section_body_${currentNumber}`}
                        editorState={this.props.sections[index]._bodyText}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          )}
        </div>
        <Box style={{ marginTop: "1em" }}>
          <WhiteButton
            startIcon={<FontAwesomeIcon icon={faPlus} color="#D52B1E" />}
            onClick={this.addFormFields}
            label="Add More Section"
          />
        </Box>
      </Box>
    );
  }

  handleBasicPrincipleChange = (
    _basicPrinciples: EditorState,
    index?: number
  ) => {
    console.log("index", index);
    this.props.onBasicPrinciplesEditorChange(_basicPrinciples);
  };

  handleTemplateFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("even", event.target.name, event.target.value);
    if (event) {
      let template: any = Object.assign({}, this.props.template);

      template[event.target.name] = event.target.value;

      this.props.onTemplateChange(template);
    }
  }
  handleVersionNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      let template: any = Object.assign({}, this.props.template);

      template[event.target.name] = event.target.value;

      this.props.onTemplateChange(template);
    }
  }

  handleSectionHeaderChange = (
    _header: EditorState, index: number
  ) => {
    this.props.onSectionHeaderChange(_header, index)
  };

  handleEffectiveDateChange = (value: Date | null) => {
    this.props.onEffectiveDateChange(value);
  };

  handlePolicyCommitteeAppr = (value: Date | null) => {
    this.props.onPolicyCommiteeAppr(value);
  }

  handleBodyEditorChange = (_bodyText: EditorState) => {
    this.props.onBodyTextEditorChange(_bodyText);
  };

  handleSectionBodyTextEditor = (_bodyText: EditorState, index: number) => {
    this.props.onSectionBodyTextChange(_bodyText, index);
  };
}
export default withRouter(FormComponent);
