import {
  AlignmentType,
  Document,
  HeadingLevel,
  IParagraphOptions,
  IRunOptions,
  ISectionOptions,
  Packer,
  UnderlineType,
  Header,
  TabStopType,
  TabStopPosition,
  PageNumber,
  IImageOptions,
  TextWrappingType,
  TextWrappingSide,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
  TableRow,
  ITableOptions,
  TableCell,
  Paragraph,
  WidthType,
  LevelFormat,
  BorderStyle,
  VerticalAlign,
  ITableCellBorders,
  HeightRule,
  ILevelsOptions,
  HorizontalPositionAlign,
  VerticalPositionAlign,
} from "docx";
import { IPropertiesOptions } from "docx/build/file/core-properties";
import _ from "lodash";
import rgb2hex from 'rgb2hex';

import { DocumentConfigModel, TextTypeModel } from "../Models";
import { DocxImageRun, DocxParagraph, DocxTextRun, DocxExternalLink, DocxTable } from "../Components/Docx";
import Utils from "../Common/Utils";

class DocxService {
  async createDocument(configs: DocumentConfigModel[]) {
    const headers: (DocxParagraph | DocxTable)[] = [];
    const footers: (DocxParagraph | DocxTable)[] = [];
    const childrens: (DocxParagraph | DocxTable)[] = [];

    const rowHeaderConfigs = configs.filter(
      (p) => p.sectionType === "HEADER" && p.elementType === "ROW"
    );
    const rowFooterConfigs = configs.filter(
      (p) => p.sectionType === "FOOTER" && p.elementType === "ROW"
    );

    if (rowHeaderConfigs.length > 0) {
      const rowParagraph = this.getRowParagraph(rowHeaderConfigs);
      headers.push(rowParagraph);
    }

    if (rowFooterConfigs.length > 0) {
      const rowParagraph = this.getRowParagraph(rowFooterConfigs);
      footers.push(rowParagraph);
    }

    configs
      .filter(
        (p) =>
          p.sectionType === "HEADER" &&
          (p.elementType === undefined || p.elementType === null)
      )
      .forEach((config) => {
        const paragraph = this.getParagraph(config);
        headers.push(paragraph);
      });

    configs
      .filter(
        (p) =>
          p.sectionType === "FOOTER" &&
          (p.elementType === undefined || p.elementType === null)
      )
      .forEach((config) => {
        const paragraph = this.getParagraph(config);
        footers.push(paragraph);
      });

    let childrenRowConfigs: DocumentConfigModel[] = [];

    configs
      .filter((p) => p.sectionType === "CHILDREN")
      .forEach((config) => {
        if (config.elementType === "ROW") {
          childrenRowConfigs.push(config);
        } else {
          if (childrenRowConfigs.length > 0) {
            const rowIdGroups: any = _.groupBy(
              childrenRowConfigs,
              (r) => r.elementId
            );

            for (const rowIdGroup in rowIdGroups) {
              const rowConfigs: DocumentConfigModel[] = rowIdGroups[rowIdGroup];
              const rowParagraph = this.getRowParagraph(rowConfigs);
              childrens.push(rowParagraph);
            }

            childrenRowConfigs = [];
          }

          const paragraph = this.getParagraph(config);
          childrens.push(paragraph);
        }
      });

    if (childrenRowConfigs.length > 0) {
      const rowIdGroups: any = _.groupBy(
        childrenRowConfigs,
        (r) => r.elementId
      );

      for (const rowIdGroup in rowIdGroups) {
        const rowConfigs: DocumentConfigModel[] = rowIdGroups[rowIdGroup];
        const rowParagraph = this.getRowParagraph(rowConfigs);
        childrens.push(rowParagraph);
      }

      childrenRowConfigs = [];
    }

    const sections: ISectionOptions[] = [
      {
        headers: {
          default: new Header({
            children: headers,
          }),
        },
        footers: {
          default: new Header({
            children: footers,
          }),
        },
        children: childrens,
        properties: {
          page: {
            size: {
              height: `${11}in`,
              width: `${8.5}in`,
            },
            margin: {
              header: `${0.5}in`,
              footer: `${0.5}in`
            }
          }
        }
      },
    ];

    const numLevels: ILevelsOptions[] = [
      {
        level: 0,
        format: LevelFormat.DECIMAL,
        text: "%1.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 720, hanging: 260 },
          },
        },
      },
      {
        level: 1,
        format: LevelFormat.UPPER_LETTER,
        text: "%2.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 1440, hanging: 260 },
          },
        },
      },
      {
        level: 2,
        format: LevelFormat.LOWER_LETTER,
        text: "%3.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 2160, hanging: 260 },
          },
        },
      },
      {
        level: 3,
        format: LevelFormat.UPPER_ROMAN,
        text: "%4.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 2880, hanging: 260 },
          },
        },
      },
      {
        level: 4,
        format: LevelFormat.LOWER_ROMAN,
        text: "%5.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 3600, hanging: 260 },
          },
        },
      },
      {
        level: 5,
        format: LevelFormat.DECIMAL,
        text: "%6.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 4320, hanging: 260 },
          },
        },
      },
      {
        level: 6,
        format: LevelFormat.UPPER_LETTER,
        text: "%7.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 5040, hanging: 260 },
          },
        },
      },
      {
        level: 7,
        format: LevelFormat.LOWER_LETTER,
        text: "%8.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 5760, hanging: 260 },
          },
        },
      },
      {
        level: 8,
        format: LevelFormat.UPPER_ROMAN,
        text: "%9.",
        alignment: AlignmentType.START,
        style: {
          paragraph: {
            indent: { left: 6480, hanging: 260 },
          },
        },
      },
      // {
      //   level: 9,
      //   format: LevelFormat.LOWER_ROMAN,
      //   text: "%10.",
      //   alignment: AlignmentType.START,
      //   style: {
      //     paragraph: {
      //       indent: { left: 7200, hanging: 260 },
      //     },
      //   },
      // },
    ];

    const options: IPropertiesOptions = {
      sections,
      numbering: {
        config: [
          {
            reference: "NUMBER_LIST",
            levels: numLevels,
          },
        ],
      },
    };

    const document = new Document(options);
    return Packer.toBlob(document);
  }

  private getParagraph(config: DocumentConfigModel): DocxParagraph | DocxTable {
    const paragraphOptions = this.getParagraphOptions(config, false, true);

    let uniqueId: string | null = null;

    if (config.uid) {
      uniqueId = config.uid;
    }

    if (config.type === "TEXT") {
      const linkType = config.textTypes.find((p) => p.key === "LINK");

      const textInputOptions = this.getTextInputOptions(config);
      const docxTextRun = new DocxTextRun(
        textInputOptions as IRunOptions,
        uniqueId
      );

      if (linkType) {
        paragraphOptions.children = [
          new DocxExternalLink(
            {
              children: [docxTextRun],
              link: linkType.value.url,
            },
            uniqueId
          ),
        ];
      } else {
        paragraphOptions.children = [docxTextRun];
      }
    } else if (config.type === "IMAGE") {
      const imageOptions = this.getImageOptions(config);
      paragraphOptions.children = [
        new DocxImageRun(imageOptions as IImageOptions, uniqueId),
      ];
    } else if (config.type === "TABLE") {
      const tableOptions = this.getTableOptions(config);
      return new DocxTable(tableOptions as ITableOptions, uniqueId);
    }

    const paragraph = new DocxParagraph(
      paragraphOptions as IParagraphOptions,
      null
    );
    return paragraph;
  }

  private getRowParagraph(configs: DocumentConfigModel[]): DocxParagraph {
    const paragraphOptions = this.getParagraphOptions(configs[0], true);

    const runs: any[] = [];

    configs.forEach((config) => {
      let uniqueId: string | null = null;

      if (config.uid) {
        uniqueId = config.uid;
      }

      if (config.type === "TEXT") {
        const linkType = config.textTypes.find((p) => p.key === "LINK");

        const textInputOptions = this.getTextInputOptions(config);
        const docxTextRun = new DocxTextRun(
          textInputOptions as IRunOptions,
          uniqueId
        );

        if (linkType) {
          const docxExternalLink = new DocxExternalLink(
            {
              children: [docxTextRun],
              link: linkType.value.url,
            },
            uniqueId
          );
          runs.push(docxExternalLink);
        } else {
          runs.push(docxTextRun);
        }
      } else if (config.type === "IMAGE") {
        const imageOptions = this.getImageOptions(config);
        runs.push(new DocxImageRun(imageOptions as IImageOptions, uniqueId));
      }
    });
    paragraphOptions.children = runs;

    const paragraph = new DocxParagraph(
      paragraphOptions as IParagraphOptions,
      null
    );
    return paragraph;
  }

  private getTextInputOptions(config: DocumentConfigModel): any {
    const textInputOptions: any = {
      children: undefined,
      text: config.value,
      color: "000000",
      bold: false,
      italics: false,
      strike: false,
      superScript: false,
      subScript: false,
      underline: undefined,
      size: undefined,
      font: undefined,
      break: undefined,
      style: undefined,
      highlight: undefined,
    };

    config.textTypes.forEach((textType: TextTypeModel) => {
      const { key, value } = textType;

      switch (key) {
        case "FONT_SIZE":
          textInputOptions.size = Number(value) * 2;
          break;
        case "FONT_FAMILY":
          textInputOptions.font = value;
          break;
        case "COLOR":
          let color: string | undefined = undefined;
          if (value) {
            const isRGBColor = Utils.isRGBColor(value);

            if (isRGBColor) {
              color = rgb2hex(value).hex;
            } else {
              color = value;
            }
          }
          textInputOptions.color = color;
          break;
        case "BG_COLOR":
          let bgcolor: string | undefined = undefined;
          if (value) {
            const isRGBColor = Utils.isRGBColor(value);
            const isHexColor = Utils.isHexColor(value);

            if (isRGBColor) {
              const colors = Utils.getColorName(rgb2hex(value).hex);
              bgcolor = colors.basic[0].name;
            } else if (isHexColor) {
              const colors = Utils.getColorName(value);
              bgcolor = colors.basic[0].name;
            } else {
              bgcolor = value;
            }
          }
          textInputOptions.highlight = bgcolor;
          break;
        case "BOLD":
          textInputOptions.bold = true;
          break;
        case "ITALIC":
          textInputOptions.italics = true;
          break;
        case "STRIKETHROUGH":
          textInputOptions.strike = true;
          break;
        case "SUPERSCRIPT":
          textInputOptions.superScript = true;
          break;
        case "SUBSCRIPT":
          textInputOptions.subScript = true;
          break;
        case "UNDERLINE":
          textInputOptions.underline = {
            type: UnderlineType.SINGLE,
            color: "000000",
          };
          break;
        case "break":
          textInputOptions.break = value;
          break;
        case "pagenumber":
          textInputOptions.children = [
            "Page ",
            PageNumber.CURRENT,
            " of ",
            PageNumber.TOTAL_PAGES,
          ];
          break;
        case "LINK":
          textInputOptions.style = "Hyperlink";
          break;
        default:
          break;
      }
    });

    return textInputOptions;
  }

  private getParagraphOptions(
    config: DocumentConfigModel,
    row: boolean = false,
    addLineSpacing: boolean = true
  ): any {
    const paragraphOptions: any = {
      children: undefined,
      heading: undefined,
      bullet: undefined,
      alignment: undefined,
      shading: undefined,
      tabStops: undefined,
      border: undefined,
      numbering: undefined,
      spacing: undefined,
    };

    if (addLineSpacing) {
      paragraphOptions.spacing = {
        line: 275 // paragraph line spacing 1.15 (multiple)
      };
    }

    if (row) {
      paragraphOptions.tabStops = [
        {
          type: TabStopType.RIGHT,
          position: TabStopPosition.MAX,
        },
      ];
    }

    config.textTypes.forEach((textType: TextTypeModel) => {
      const { key, value } = textType;

      switch (key) {
        case "header-one":
          paragraphOptions.heading = HeadingLevel.HEADING_1;
          break;
        case "header-two":
          paragraphOptions.heading = HeadingLevel.HEADING_2;
          break;
        case "header-three":
          paragraphOptions.heading = HeadingLevel.HEADING_3;
          break;
        case "header-four":
          paragraphOptions.heading = HeadingLevel.HEADING_4;
          break;
        case "header-five":
          paragraphOptions.heading = HeadingLevel.HEADING_5;
          break;
        case "header-six":
          paragraphOptions.heading = HeadingLevel.HEADING_6;
          break;
        case "unordered-list-item":
          paragraphOptions.bullet = {
            level: value.depth,
          };
          break;
        case "number-list":
          paragraphOptions.numbering = {
            reference: "NUMBER_LIST",
            level: value.depth,
          };
          break;
        case "text-align":
          if (value === "center") {
            paragraphOptions.alignment = AlignmentType.CENTER;
          } else if (value === "right") {
            paragraphOptions.alignment = AlignmentType.RIGHT;
          } else if (value === "justify") {
            paragraphOptions.alignment = AlignmentType.JUSTIFIED;
          } else if (value === "left") {
            paragraphOptions.alignment = AlignmentType.LEFT;
          }
          break;
        case "HORIZONTAL_LINE":
          paragraphOptions.border = {
            bottom: {
              color: "auto",
              space: 1,
              style: "single",
              size: 6,
            },
          };
          break;
        default:
          break;
      }
    });

    return paragraphOptions;
  }

  private getImageOptions(config: DocumentConfigModel): any {
    const imageOptions: any = {
      data: config.value,
      transformation: {
        width: 100,
        height: 100,
      },
      floating: {
        horizontalPosition: {
          relative: HorizontalPositionRelativeFrom.MARGIN,
          align: HorizontalPositionAlign.LEFT,
        },
        verticalPosition: {
          relative: VerticalPositionRelativeFrom.PARAGRAPH,
          align: VerticalPositionAlign.INSIDE,
        },
        wrap: {
          type: TextWrappingType.TOP_AND_BOTTOM,
          side: TextWrappingSide.BOTH_SIDES,
        }
      },
    };

    const floating: any = {};

    config.textTypes.forEach((textType: TextTypeModel) => {
      const { key, value } = textType;

      switch (key) {
        case "width":
          imageOptions.transformation.width = value;
          break;
        case "height":
          imageOptions.transformation.height = value;
          break;
        case "horizontalOffset":
          floating.horizontalPosition = {
            relative: HorizontalPositionRelativeFrom.PAGE,
            offset: value,
          };
          break;
        case "verticalOffset":
          floating.verticalPosition = {
            relative: VerticalPositionRelativeFrom.PAGE,
            offset: value,
          };
          break;
        case "wrap":
          floating.wrap = {
            type: TextWrappingType.SQUARE,
            side: TextWrappingSide.BOTH_SIDES,
          };
          break;
        case "text-align":
          imageOptions.floating.horizontalPosition.align = value;
          break;
        default:
          break;
      }
    });

    if (Object.keys(floating).length) {
      imageOptions.floating = floating;
    }

    return imageOptions;
  }

  private getTableOptions(config: DocumentConfigModel) {
    const tableRows: TableRow[] = [];
    let borders: ITableCellBorders | undefined = undefined;
    const borderConfig = config.textTypes.find(p => p.key === 'BORDER');

    if (borderConfig) {
      if (borderConfig.value === 'NO_BORDER') {
        borders = {
          top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
        };
      }
    }

    config.documentConfigs?.forEach((rowConfig) => {
      const tableCells: TableCell[] = [];
      const height = rowConfig.textTypes.find(p => p.key === 'HEIGHT');

      rowConfig.documentConfigs?.forEach((cellConfig) => {
        const paragraphs: Paragraph[] = [];

        cellConfig.documentConfigs?.forEach((docConfig) => {
          const paragraph = this.getParagraph(docConfig);
          paragraphs.push(paragraph as DocxParagraph);
        });

        const rowSpan = cellConfig.textTypes.find(p => p.key === 'ROW_SPAN');
        const colSpan = cellConfig.textTypes.find(p => p.key === 'COL_SPAN');
        const cellWidth = cellConfig.textTypes.find(p => p.key === 'WIDTH');
        const backgroundColorTextType = cellConfig.textTypes.find(p => p.key === 'BG_COLOR');
        let backgroundColor: string | undefined = undefined;
        if (backgroundColorTextType) {
          const isRGBColor = Utils.isRGBColor(backgroundColorTextType.value);

          if (isRGBColor) {
            backgroundColor = rgb2hex(backgroundColorTextType.value).hex;
          } else {
            backgroundColor = backgroundColorTextType.value;
          }
        }

        const tableCell = new TableCell({
          children: paragraphs,
          width: {
            size: cellWidth ? cellWidth.value : 4505,
            type: WidthType.DXA,
          },
          rowSpan: rowSpan ? rowSpan.value : undefined,
          columnSpan: colSpan ? colSpan.value : undefined,
          borders,
          shading: { fill: backgroundColor ? backgroundColor : undefined }
          // verticalAlign: VerticalAlign.CENTER,
        });

        tableCells.push(tableCell);
      });

      const tableRow = new TableRow({
        children: tableCells,
        height: height ? { value: height.value, rule: HeightRule.AUTO } : undefined,
      });

      tableRows.push(tableRow);
    });

    const tableOptions: ITableOptions = {
      rows: tableRows,
      columnWidths: [4505, 4505],
      borders,
    };

    return tableOptions;
  }
}

const docxService = new DocxService();
export default docxService;
