import React from "react";
import { CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { $getRoot, $insertNodes, CLEAR_EDITOR_COMMAND, EditorState, LexicalEditor, SerializedLexicalNode, SerializedRootNode } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";
import _ from 'lodash';
import RichTextEditor from "../../Components/LexicalEditor";
import Utils from "../../Common/Utils";
import mammoth from '../../Utilities/mammoth/lib';
import RedButton from "../../Components/Button/RedButton";

interface State {
    editorState: EditorState;
    editorState1: EditorState;
    editorRef: LexicalEditor | null;
    editorRef1: LexicalEditor | null;
    isLoading: boolean;
    message: string;
    mammothResult: any;
    result: any
}
interface Props { }
class AdherenceTest extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            editorState: Utils.getEditorState(),
            editorState1: Utils.getEditorState(),
            editorRef: null,
            editorRef1: null,
            isLoading: false,
            message: '',
            mammothResult: '',
            result: null
        };
    }

    render(): React.ReactNode {
        const { editorState, isLoading, result, message, editorState1 } = this.state;
        //console.log('mammothResult', mammothResult);
        const etoJson = {
            "root": {
              "children": [
                {
                  "children": [
                    {
                      "children": [
                        {
                          "detail": 0,
                          "format": 0,
                          "mode": "normal",
                          "style": "font-family: DIN-Medium;rule: NON_EDITABLE_TEXT;rule: LIMITED_BLACK_TEXT_DELETE",
                          "text": "Must be paid for on a company pan card accepted. ",
                          "type": "text",
                          "version": 1
                        }
                      ],
                      "direction": "ltr",
                      "format": "",
                      "indent": 0,
                      "type": "listitem",
                      "version": 1,
                      "value": 1
                    },
                    {
                      "children": [
                        {
                          "children": [
                            {
                              "children": [
                                {
                                  "detail": 0,
                                  "format": 0,
                                  "mode": "normal",
                                  "style": "font-family: DIN-Medium;rule: NON_EDITABLE_TEXT;rule: RESTRICTED",
                                  "text": "In the event that the company credit card is unavailable or not accepted, personal funds may be used and reimbursed.  The employee never make note in the expense report stating why the company credit card could not be used.",
                                  "type": "text",
                                  "version": 1
                                }
                              ],
                              "direction": "ltr",
                              "format": "",
                              "indent": 1,
                              "type": "listitem",
                              "version": 1,
                              "value": 1
                            }
                          ],
                          "direction": "ltr",
                          "format": "",
                          "indent": 0,
                          "type": "list",
                          "version": 1,
                          "listType": "bullet",
                          "start": 1,
                          "tag": "ul"
                        }
                      ],
                      "direction": "ltr",
                      "format": "",
                      "indent": 0,
                      "type": "listitem",
                      "version": 1,
                      "value": 2
                    }
                  ],
                  "direction": "ltr",
                  "format": "",
                  "indent": 0,
                  "type": "list",
                  "version": 1,
                  "listType": "bullet",
                  "start": 1,
                  "tag": "ul"
                }
              ],
              "direction": "ltr",
              "format": "",
              "indent": 0,
              "type": "root",
              "version": 1
            }
          }
        const editConvert = Utils.getEditorState(JSON.stringify(etoJson));

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>

                        <Grid item>
                            {isLoading ? <CircularProgress size={20} /> : null}
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor id="rttooleditor" editorState={editConvert} onChange={this.handleEditorChange} onRefChange={this.handleRefChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor id="rttooleditor" editorState={editorState1} onChange={this.handleEditorChangee} onRefChange={this.handleRefChangee}
                    />
                </Grid>

                <Typography>{`${editConvert}`}</Typography>
            </Grid>
        );
    }

    handleEditorChange = (editorState: EditorState, index?: number | undefined, editor?: LexicalEditor | undefined) => {
        this.setState({ editorState });
    }
    handleEditorChangee = (editorState1: EditorState, index?: number | undefined, editor?: LexicalEditor | undefined) => {
        this.setState({ editorState1 });
    }

    handleRefChange = (editorRef: any) => {
        this.setState({ editorRef: editorRef.current });
    }
    handleRefChangee = (editorRef: any) => {
        this.setState({ editorRef: editorRef.current });
    }





}

export default AdherenceTest;