import { QDocsLPModel, QDocsListModel } from "../Models";
import PMPAPIService from "./PMPAPIService";

interface QDocsDTO {
    procedure_name: string;
    geography: string[];
}

interface QDocsOutputModel {
    files: QDocsListModel[];
}

interface QDocsPresignedModel {
    presigned_url: string;
}

class QDocsService extends PMPAPIService {
    async searchDocuments(qdocsDTO: QDocsDTO) {
        const response = await this.PostAsync<QDocsOutputModel>(
            `qdocslist`,
            qdocsDTO
        );

        let responseQDocsList: QDocsListModel[] = [];

        if (response.isSuccess && response.data) {
            responseQDocsList = response.data.files;
        }
        return responseQDocsList;
    }

    async searchDocumentForLocal(qdocsDTO: QDocsDTO): Promise<QDocsLPModel | null> {
        const response = await this.PostAsync<QDocsLPModel>(
            `qdocslatestfile`,
            qdocsDTO
        );

        if (response.isSuccess) {
            return response.data;
        }
        return null;
    }

    async getDocument(key: string) {
        const response = await this.PostAsync<QDocsPresignedModel>(
            `qdocsgeneratepresignedurl`,
            { file_key: key }
        );

        if (response.isSuccess) {
            return response.data.presigned_url;
        }
        return null;
    }
}

const qdocsService = new QDocsService();
export default qdocsService;