import AuthProvider from '../Common/AuthProvider';
import AdminAuthProvider from '../Common/AdminAuthProvider';
import { AuthClaimModel, AuthTokenModel } from '../Models';
import Utils from '../Common/Utils';
import { UserRole } from '../Common/Enums';

class AuthProviderService {
    private readonly _CallbackPathName = 'callbackPath';

    login() {
        AuthProvider.login();
    }

    logout() {
        AuthProvider.signout();
    }

    setCallbackPath(path?: string | readonly string[]) {
        if (typeof path === 'string') {
            if (path === '/auth/login') {
                localStorage.setItem(this._CallbackPathName, '/');
            } else {
                localStorage.setItem(this._CallbackPathName, path);
            }
        } else {
            localStorage.setItem(this._CallbackPathName, '/');
        }
    }

    getCallbackPath(): string {
        const path = localStorage.getItem(this._CallbackPathName);

        if (path) {
            return path;
        }

        return '/';
    }

    handlerPromise(callback: () => void) {
        AuthProvider.handlePromiseRedirectCallback(callback);
    }

    async isLoggedIn(): Promise<boolean> {
        return AuthProvider.isLoggedIn();
    }

    async getAccessToken(): Promise<AuthTokenModel> {
        return AuthProvider.getAccessToken();
    }

    getClaims(): AuthClaimModel {
        return AuthProvider.getIdTokenClaims();
    }

    async getAdminEmails(): Promise<string[]> {
        const emails: string[] = [];
        const { accessToken } = await this.getAdminAccessToken();
        const env = Utils.getEnvVars();
    
        const headers = new Headers();
        const bearer = "Bearer " + accessToken;
        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual");
        const options = {
            method: "GET",
            headers: headers
        };
    
        const graphAdminEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.groups.EC_PMP_ADMIN_GROUP_ID}/members`;
        const responseAdmin = await fetch(graphAdminEndpoint, options);
        const adminData = await responseAdmin.json();
    
        if (adminData && adminData.value) {
            adminData.value.forEach((groupmember: any) => {
                emails.push(groupmember.mail);
            });
        }
    
        return emails;
    }

    async getAdminAccessToken(): Promise<AuthTokenModel> {
        return AdminAuthProvider.getAccessToken();
    }

    async getUserRoles(): Promise<UserRole[]> {
        const userRoles: UserRole[] = [UserRole.GLOBAL_USER];
        const { accessToken } = await this.getAdminAccessToken();
        const loggedInUserToken = Utils.decodeJWT<any>(accessToken);
        const env = Utils.getEnvVars();

        const headers = new Headers();
        const bearer = "Bearer " + accessToken;
        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual");
        const options = {
            method: "GET",
            headers: headers
        };

        const graphAdminEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.groups.EC_PMP_ADMIN_GROUP_ID}/members`;
        const graphECOEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.groups.EC_PMP_ECO_GROUP_ID}/members`;
        const graphReadonlyEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.groups.EC_PMP_READONLY_GROUP_ID}/members`;

        const [responseAdmin, responseECO, responseReadonly] = await Promise.all([
            fetch(graphAdminEndpoint, options), fetch(graphECOEndpoint, options), fetch(graphReadonlyEndpoint, options)]);

        const adminData = await responseAdmin.json();
        const ecoData = await responseECO.json();
        const readonlyData = await responseReadonly.json();

        if (adminData && adminData.value) {
            adminData.value.forEach((groupmember: any) => {
                if (loggedInUserToken.unique_name === groupmember.mail) {
                    userRoles.push(UserRole.ADMIN_USER);
                }
            });
        }

        if (ecoData && ecoData.value) {
            ecoData.value.forEach((groupmember: any) => {
                if (loggedInUserToken.unique_name === groupmember.mail) {
                    userRoles.push(UserRole.ECO_USER);
                }
            });
        }

        if (readonlyData && readonlyData.value) {
            readonlyData.value.forEach((groupmember: any) => {
                if (loggedInUserToken.unique_name === groupmember.mail) {
                    userRoles.push(UserRole.READONLY_USER);
                }
            });
        }

        return userRoles;

  

       
    }
}

const authProviderService = new AuthProviderService();
export default authProviderService;