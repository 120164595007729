import { BlackTextOptionModel, LocalProcedureDeviation } from "../Models";
import { EcoViewCountTab, LocalProcedure } from "../Models/GlobalProcedureTemplateModel";
import { LocalProcedureTemplateFile } from "../Models/LocalProcedureTemplateFile";
import { LocalProcedureTemplate } from "../Models/LocalProcedureTemplateModel";
import { LocalProcedureTemplateSection } from "../Models/LocalProcedureTemplateSection";
import { LocalProcedureApprovalFileUpload } from "../Models/LocalProcedureApprovalFileUpload"
import PMPAPIService from "./PMPAPIService";
import { ExceptionBusinessArea, ExceptionData, ExceptionForm, ExceptionGeography } from "../Models/ExceptionForm";
import { LocalProcedureVersionModel } from "../Models/LocalProcedureVersionModel";

interface ApproverDataService {
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  SystemLogonId: string;
  
}

interface ExceptionResponse {
  count: number;
  rows: any;
  message:string;
  renewalFormData:any;
}

interface EditPayload {
  lpId:number;
  deletedPayload:any[];
  addedPayload:any[];
  updatedName:string;
}
interface ILPTInterfaceDTO {
  localProcedure: LocalProcedureTemplate;
  localProcedureFiles: LocalProcedureTemplateFile[];
  localProcedureSections: LocalProcedureTemplateSection[];
  localProcedureFileUpload?: LocalProcedureApprovalFileUpload[];
}
interface ILPSendForAprovalInterfaceDTO {
  localProcedure: LocalProcedureTemplate;
  localProcedureFiles: LocalProcedureTemplateFile[];
  localProcedureSections?: LocalProcedureTemplateSection[];
};

interface ExceptionSubmitPayload {

  exceptionForm: ExceptionForm;
}


class LPTService extends PMPAPIService {
  async create(lpt: ILPTInterfaceDTO): Promise<ILPTInterfaceDTO | null> {
    console.log("lpt", lpt)
    const response = await this.PostAsync<ILPTInterfaceDTO>(
      `localproceduretemplate/create`,
      lpt
    );

    let responseLPT: ILPTInterfaceDTO | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async getAll(): Promise<LocalProcedureTemplate[]> {
    const response = await this.GetAsync<LocalProcedureTemplate[]>(
      `localproceduretemplate/getallwithpagination`
    );

    let responseLPTs: LocalProcedureTemplate[] = [];

    if (response.isSuccess) {
      responseLPTs = response.data;
    }
    return responseLPTs;
  };


  async getEcoTabCountLocalProcedure(): Promise<EcoViewCountTab[]> {
    const response = await this.GetAsync<EcoViewCountTab[]>(
      `localproceduretemplate/getallstatuswithcount`
    );

    let responseGPTs: EcoViewCountTab[] = [];

    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

async compareSections(paraload:any, comment?: any, header?: any) {
  const response = await this.PostAsync<any>(
    `/comparesections`,paraload
  );
  if (comment) {
    response.data.comment = comment;
  }
  if (header) {
    response.data.header = header;
  }
  return response.data
}
  async saveAsDraft(lpt: ILPTInterfaceDTO): Promise<ILPTInterfaceDTO | null> {
    const response = await this.PostAsync<ILPTInterfaceDTO>(
      `localproceduretemplate/saveasdraft`,
      lpt
    );

    let responseLPT: ILPTInterfaceDTO | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async update(lpt: ILPTInterfaceDTO): Promise<ILPTInterfaceDTO | null> {
    const response = await this.PostAsync<ILPTInterfaceDTO>(
      `localproceduretemplate/update`, lpt
    );

    let responseLPT: ILPTInterfaceDTO | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async updateGeography(lpt: EditPayload): Promise<any | null> {
    const response = await this.PostAsync<any>(
      `localproceduretemplate/updatesavegeography`, lpt
    );

    let responseLPT: any | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async deleteLocalProcedure(lpId: number): Promise<any | null> {
    const response = await this.PostAsync<any>(
      `localproceduretemplate/delete`, {lpId}
    );

    let responseLPT: ILPTInterfaceDTO | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }
  

  async updatePreviousLPT(lpt: ILPTInterfaceDTO): Promise<ILPTInterfaceDTO | null> {
    const response = await this.PostAsync<ILPTInterfaceDTO>(
      `localproceduretemplate/updatepreviousLPT`, lpt
    );

    let responseLPT: ILPTInterfaceDTO | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async approveProcedure(lpId: number): Promise<number | null> {
    const response = await this.PostAsync<number>(
      `localproceduretemplate/approve`, {lpId}
    );

    let responseLPT: number | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async approverProcedureAccess(lpId: number): Promise<number | null> {
    const response = await this.PostAsync<number>(
      `localproceduretemplate/accessedon`, {lpId}
    );

    let responseLPT: number | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async rejectProcedure(lpId: number,reasonforrejection: string) {
    const response = await this.PostAsync<any>(
      `localproceduretemplate/reject`, {lpId, reasonforrejection}
    );

    let responseLPT: any = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async sendForApproval(lpId: number){
    const response = await this.PostAsync<ILPSendForAprovalInterfaceDTO>(
      `localproceduretemplate/sendforapproval`, {lpId}
    );

    let responseLPT = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }

  async getApprover(searchText: {}): Promise<ApproverDataService[]> {
    const response = await this.PostAsync<ApproverDataService[]>(
      `/employee/search`, searchText
    );

    let approverData : ApproverDataService[] = [];

    if (response.isSuccess) {
      
      approverData = response.data;
    }

    return approverData;
  }

  async getByLPId(lpId: number): Promise<ILPTInterfaceDTO | null> {
    const response = await this.GetAsync<ILPTInterfaceDTO>(
      `localproceduretemplate/getbylpid/${lpId}`
    );

    let responseLPT: ILPTInterfaceDTO | null = null;

    if (response.isSuccess) {
      responseLPT = response.data;
    }

    return responseLPT;
  }
  async updateFilesPath(filePathDTO: {
    headerImageFile: string,
    footerImageFile: string,
    ipFilePath: string,
    lpId: number
  }): Promise<{ success: boolean } | null> {
    const response = await this.PostAsync<{ success: boolean }>(`localproceduretemplate/updatefilespath`, filePathDTO);

    let responseGPT: { success: boolean } | null = null;

    if (response.isSuccess) {
      responseGPT = response.data;
    }

    return responseGPT;
  }


  async getAllBlackTextOptions(): Promise<BlackTextOptionModel[]> {
    const response = await this.GetAsync<BlackTextOptionModel[]>(
      `localproceduretemplate/getallblacktextoptions`
    );

    let responseOptions: BlackTextOptionModel[] = [];

    if (response.isSuccess) {
      responseOptions = response.data;
    }
    return responseOptions;
  }
  async getAllExceptionGeography(): Promise<ExceptionGeography[]> {
    const response = await this.GetAsync<ExceptionGeography[]>(
      `localproceduretemplate/exceptiongeography`
    );

    let responseOptions: ExceptionGeography[] = [];

    if (response.isSuccess) {
      responseOptions = response.data;
    }
    return responseOptions;
  }
  async getAllExceptionBusinessArea(): Promise<ExceptionBusinessArea[]> {
    const response = await this.GetAsync<ExceptionBusinessArea[]>(
      `localproceduretemplate/exceptionbusinessarea`
    );

    let responseOptions: ExceptionBusinessArea[] = [];

    if (response.isSuccess) {
      console.log("res",response)
      responseOptions = response.data;
    }
    return responseOptions;
  }

  async createLocalExceptionMapping(payload:any) {
    const response = await this.PostAsync<any>(
      `/localproceduretemplate/setlocalprocedureexception`,
      payload,
    );
    return response;
  }

  async createDeviation(lpd: LocalProcedureDeviation): Promise<LocalProcedureDeviation | null> {
    const response = await this.PostAsync<LocalProcedureDeviation>(
      `localproceduretemplate/createdeviation`,
      lpd
    );

    let responseLPD: LocalProcedureDeviation | null = null;

    if (response.isSuccess) {
      responseLPD = response.data;
    }

    return responseLPD;
  }

  async createExceptionForm(obj: ExceptionSubmitPayload) {
    const response = await this.PostAsync<ExceptionForm>(
      `localproceduretemplate/createexception`,
      obj
    );

    return response;
  }

  async createExceptionNoAppr(obj: ExceptionSubmitPayload) {
    const response = await this.PostAsync<ExceptionForm>(
      `localproceduretemplate/createexceptionnoappr`,
      obj
    );

    return response;
  }

  async getExceptionDataById(gperId: number): Promise<ExceptionForm | null> {
    const response = await this.GetAsync<ExceptionForm>(
      `localproceduretemplate/getexceptionbyid/${gperId}`
    );

    let responseEFD: ExceptionForm | null = null;

    if (response.isSuccess) {
      responseEFD = response.data;
    }

    return responseEFD;
  };

  async getAllExceptionDataByPagination(querySearch:any): Promise<ExceptionResponse | null> {

    const response = await this.PostAsync<ExceptionResponse>(
      `localproceduretemplate/getallexception`, querySearch
    );

    let responseEFs: ExceptionResponse | null = null

    if (response.isSuccess) {
      responseEFs = response.data;
    }
    return responseEFs;
  }
  

  async saveAsDraftException(lpt: ExceptionForm) {
    const response = await this.PostAsync<ExceptionForm>(
      `localproceduretemplate/saveasdraftexception`,
      lpt
    );

    return response;
  }
  async updateException(gperd: ExceptionForm) {
    const response = await this.PostAsync<ExceptionForm>(
      `localproceduretemplate/updateexception`,
      gperd
    );
    return response;
  }

  async getExceptionByLPID(lpId:number): Promise<ExceptionForm | null> {
    const response = await this.GetAsync<ExceptionForm> (
      `localproceduretemplate/getallexception/${lpId}`
    );

    let responseLPID: ExceptionForm | null = null;

    if(response.isSuccess) {
      responseLPID = response.data;
    }

    return responseLPID;
  }

  async setLptException(payload:any): Promise<any> {
    const response = await this.PostAsync<any>(
      `localproceduretemplate/setlocalprocedureexception`,payload,
    );
    let responseGPTs: any
    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

  async getLptExceptionMapping(): Promise<any> {
    const response = await this.GetAsync<any>(
      "localproceduretemplate/getlocalprocedureexception",
    );
    let responseGPTs: any
    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

  async getVersionsByLPId(lpId: number): Promise<LocalProcedureVersionModel | null> {
    const response = await this.GetAsync<LocalProcedureVersionModel>(
      `/localproceduretemplate/getversionsbylpid/${lpId}`
    );

    let responseLPT: LocalProcedureVersionModel | null = null;
    console.log("responseLPT", lpId);
    
    if (response.isSuccess) {
      responseLPT = response.data;
      const localProcedureHistories = responseLPT.localProcedureHistory.localProcedureHistories.filter(pHistory => {
        if (pHistory.gpId === responseLPT?.localProcedure.localProcedure.lpId) {
          return false;
        }

        return true;
      });
      responseLPT.localProcedureHistory.localProcedureHistories = localProcedureHistories;
    }

    return responseLPT;
  }

}

const lptService = new LPTService();
export default lptService;
