import React from 'react';
import { Box, Card, CardContent } from '@mui/material';

import Header from './Header';
import Footer from './Footer';

interface Props {
    children?: React.ReactNode
}
class Layout extends React.Component<Props> {
    render() {
        return (
            <div style={{ backgroundColor: '#F5F5F5' }}>
                <Header />
                <Box>
                    <Card style={{ margin: 20 }}>
                        <CardContent style={{ minHeight: '100vh' }}>
                            {this.props.children}
                        </CardContent>
                    </Card>
                    <Footer />
                </Box>
            </div>
        )
    }
}

export default Layout;