import moment from "moment";
import { DocumentConfigModel, GlobalProcedureTemplate, LocalProcedureTemplate, } from "../Models";
import Constants from "../Common/Constants";
import { ExceptionForm } from "../Models/ExceptionForm";

class DocumentService {
    async getGPTHeaderConfigs(gpt: GlobalProcedureTemplate | LocalProcedureTemplate) {
        console.log("gpt=",gpt);
        const headerImageBuffer = await gpt?._headerImageFile?.file?.arrayBuffer();

        const row1: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'HEADER',
            value: 'Row',
            textTypes: [{
                key: 'HEIGHT', value: 500
            }],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'HEADER',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 2,
                        },
                        {
                            key: 'WIDTH', value: 1505,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'IMAGE',
                            sectionType: 'HEADER',
                            value: headerImageBuffer,
                            textTypes: [
                                { key: 'width', value: '50' },
                                { key: 'height', value: '50' },
                                { key: 'horizontalOffset', value: '200000' },
                                { key: 'verticalOffset', value: '80000' },
                                { key: 'wrap', value: 'square' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'HEADER',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 2,
                        },
                        {
                            key: 'WIDTH', value: 4505,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            value: gpt.templateName,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 14 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
                                { key: 'COLOR', value: '#000000' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'HEADER',
                    value: '',
                    textTypes: [
                        {
                            key: 'WIDTH', value: 2505,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            value: 'Insert Country Procedure',
                            textTypes: [
                                { key: 'FONT_SIZE', value: 12 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
                                { key: 'COLOR', value: '#000000' },
                                { key: 'text-align', value: 'right' },
                            ],
                        }
                    ]
                }
            ]
        };

        const row2: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'HEADER',
            value: 'Row',
            textTypes: [{
                key: 'HEIGHT', value: 500
            }],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'HEADER',
                    value: '',
                    textTypes: [],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            value: `Version ${gpt.versionNumber}`,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 10 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' },
                                { key: 'text-align', value: 'right' },
                            ],
                        },
                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            value: `${moment(gpt.effectiveDate).format('MMMM DD, YYYY')}`,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 10 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' },
                                { key: 'text-align', value: 'right' },
                            ],
                        }
                    ]
                }
            ]
        };

        const row: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'HEADER',
            value: 'Row',
            textTypes: [{
                key: 'HEIGHT', value: 1000
            }],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'HEADER',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 1205,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'IMAGE',
                            sectionType: 'HEADER',
                            value: headerImageBuffer,
                            textTypes: [
                                { key: 'width', value: '50' },
                                { key: 'height', value: '50' },
                                { key: 'horizontalOffset', value: '000000' },
                                { key: 'verticalOffset', value: '00000' },
                                { key: 'wrap', value: 'square' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'HEADER',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 4505,
                        },


                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            value: gpt.templateName,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 14 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
                                { key: 'COLOR', value: '#000000' },
                            ],
                        },

                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'HEADER',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 4305,
                        }
                    ],
                    documentConfigs: [

                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            ...(gpt.geoGraphyName !== undefined) && { value: `${gpt.geoGraphyName} Procedure` },
                            ...(gpt.geoGraphyName === undefined) && { value: `Insert Country Procedure` },
                            textTypes: [
                                { key: 'FONT_SIZE', value: 12 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
                                { key: 'COLOR', value: '#000000' },
                                { key: 'text-align', value: 'right' },
                            ],
                        },
                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            value: '',
                            textTypes: [
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
                                { key: 'COLOR', value: '#000000' },
                                { key: 'text-align', value: 'right' },
                            ],
                        },
                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            value: `Version ${gpt.versionNumber}`,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 10 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' },
                                { key: 'text-align', value: 'right' },
                            ],
                        },
                        {
                            type: 'TEXT',
                            sectionType: 'HEADER',
                            value: `${moment(gpt.effectiveDate).format('MMMM DD, YYYY')}`,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 10 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' },
                                { key: 'text-align', value: 'right' },
                            ],
                        }
                    ]
                }
            ]
        };

        const lineBreakConfig: DocumentConfigModel = {
            type: 'TEXT',
            value: ' ',
            textTypes: [
                { key: "FONT_SIZE", value: 11 },
            ],
            sectionType: 'HEADER',
        };

        const documentTableConfig: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'HEADER',
            value: '',
            textTypes: [
                {
                    key: 'BORDER', value: 'NO_BORDER'
                }
            ],
            documentConfigs: [
                // row1,
                // row2,
                row
            ]
        };

        return [lineBreakConfig, documentTableConfig, lineBreakConfig];
    }

    async getGPTFooterConfigs(gpt: GlobalProcedureTemplate | LocalProcedureTemplate) {
        const footerImageBuffer = await gpt?._footerImageFile?.file?.arrayBuffer();

        const row1: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'FOOTER',
            value: '',
            textTypes: [],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'FOOTER',
                    value: '',
                    textTypes: [
                        {
                            key: 'WIDTH', value: 3960,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'FOOTER',
                            value: gpt.footer,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 9 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' }
                            ],
                        },
                        {
                            type: 'TEXT',
                            sectionType: 'FOOTER',
                            value: `© ${new Date().getFullYear()} Eli Lilly and Company Confidential`,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 9 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' }
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'FOOTER',
                    value: '',
                    textTypes: [
                        {
                            key: 'WIDTH', value: 3510,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'FOOTER',
                            value: '',
                            textTypes: [],
                        },
                        {
                            type: 'TEXT',
                            sectionType: 'FOOTER',
                            value: '',
                            textTypes: [
                                { key: 'pagenumber' },
                                { key: 'FONT_SIZE', value: 9 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'FOOTER',
                    value: '',
                    textTypes: [
                        {
                            key: 'WIDTH', value: 1890,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'IMAGE',
                            sectionType: 'FOOTER',
                            value: footerImageBuffer,
                            textTypes: [
                                { key: 'width', value: '80' },
                                { key: 'height', value: '45' },
                                { key: 'horizontalOffset', value: '800000' },
                                { key: 'verticalOffset', value: '0' },
                                { key: 'wrap', value: 'square' },
                            ],
                        }
                    ]
                }
            ]
        };

        const lineBreakConfig: DocumentConfigModel = {
            type: 'TEXT',
            value: '',
            textTypes: [],
            sectionType: 'FOOTER',
        };

        const documentTableConfig: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'FOOTER',
            value: '',
            textTypes: [
                {
                    key: 'BORDER', value: 'NO_BORDER'
                }
            ],
            documentConfigs: [
                row1,
            ]
        };

        return [lineBreakConfig, lineBreakConfig, documentTableConfig, lineBreakConfig];
    }

    getGPTBasicPrincipleTextConfigs() {
        const documentConfigs: DocumentConfigModel[] = [];

        documentConfigs.push({
            type: "TEXT",
            sectionType: "CHILDREN",
            textTypes: [
                { key: 'FONT_SIZE', value: 10 },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
                { key: 'COLOR', value: '#000000' },
            ],
            value: "Basic Principles"
        });

        documentConfigs.push({
            type: 'TEXT',
            value: '',
            textTypes: [],
            sectionType: 'CHILDREN',
        });

        return documentConfigs;
    }

    async getExceptionTitleTextConfigs(gpet: any) {
        const row: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'CHILDREN',
            value: 'Row',
            textTypes: [{
                key: 'HEIGHT', value: 400,

            },
            { key: 'break', value: 2 }],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 2950,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: 'Exception Title:',
                            textTypes: [

                                {
                                    key: 'FONT_SIZE', value: '11'
                                },

                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

                                { key: 'BOLD' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 2505,
                        },


                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: gpet.title,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 11 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' },
                            ],
                        },

                    ]
                },

            ]
        };

        const row1: DocumentConfigModel = {
            type: 'TABLE_CELL',
            sectionType: 'CHILDREN',
            value: 'Row',
            textTypes: [{
                key: 'HEIGHT', value: 500,

            },
            ],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 2950,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: 'Exception Rationale:',
                            textTypes: [

                                {
                                    key: 'FONT_SIZE', value: '11'
                                },

                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

                                { key: 'BOLD' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 4505,
                        },


                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: gpet.exceptionRationale,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 11 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' },
                            ],
                        },

                    ]
                },

            ]
        };
        const row2: DocumentConfigModel = {
            type: 'TABLE_CELL',
            sectionType: 'CHILDREN',
            value: 'Row',
            textTypes: [{
                key: 'HEIGHT', value: 500,

            },
            ],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 2,
                        },
                        {
                            key: 'WIDTH', value: 2950,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: 'Date of Exception Approval:',
                            textTypes: [

                                {
                                    key: 'FONT_SIZE', value: '11'
                                },

                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

                                { key: 'BOLD' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 5000,
                        },


                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: gpet.approvalTimeStamp ? `${moment(gpet.approvalTimeStamp).format('MMMM DD, YYYY')}`: 'Not Yet Approved',
                            textTypes: [
                                { key: 'FONT_SIZE', value: 11 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' },
                            ],
                        },

                    ]
                },

            ]
        };



        const row3: DocumentConfigModel = {
            type: 'TABLE_CELL',
            sectionType: 'CHILDREN',
            value: 'Row',
            textTypes: [{
                key: 'HEIGHT', value: 500,

            },
            ],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 2,
                        },
                        {
                            key: 'WIDTH', value: 2950,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: 'Exception Requested by:',
                            textTypes: [

                                {
                                    key: 'FONT_SIZE', value: '11'
                                },

                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

                                { key: 'BOLD' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 2000,
                        },


                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: gpet.exceptionRequestBy,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 11 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' },
                            ],
                        },

                    ]
                },

            ]
        };

        const documentTableConfig2: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [
                {
                    key: 'BORDER', value: 'NO_BORDER'
                }
            ],
            documentConfigs: [

                row2,

            ]
        }
        const documentTableConfig3: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [
                {
                    key: 'BORDER', value: 'NO_BORDER'
                }
            ],
            documentConfigs: [

                row3,

            ]
        }


        const documentTableConfig1: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [
                {
                    key: 'BORDER', value: 'NO_BORDER'
                }
            ],
            documentConfigs: [

                row1,

            ]
        }

        const documentTableConfig: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [
                {
                    key: 'BORDER', value: 'NO_BORDER'
                }
            ],
            documentConfigs: [

                row,

            ]
        };

        return [documentTableConfig, documentTableConfig1, documentTableConfig2, documentTableConfig3];
    }
    async LocalReqTextConfig(data:any) {
        const row: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'CHILDREN',
            value: 'Row',
            textTypes: [{
                key: 'HEIGHT', value: 300,

            }],

            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 605,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: '',
                            textTypes: [

                                {
                                    key: 'FONT_SIZE', value: '11'
                                },

                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

                                { key: 'BOLD' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 4505,
                        },


                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: 'Local Requirement',
                            textTypes: [
                                { key: 'FONT_SIZE', value: 11 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'BOLD' },
                            ],
                        },

                    ]
                },

            ]
        };
        const row1: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'CHILDREN',
            value: 'Row',
            textTypes: [{
               key: 'HEIGHT', value: 1000,

            }],

            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 405,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: '',
                            textTypes: [

                                {
                                    key: 'FONT_SIZE', value: '11'
                                },

                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

                                { key: 'BOLD' },
                            ],
                        }
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'ROW_SPAN', value: 3,
                        },
                        {
                            key: 'WIDTH', value: 7505,
                        },


                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: data,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 11 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                              
                            ],
                        },

                    ]
                },

            ]
        };

        const documentTableConfig: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [
                {
                    key: 'BORDER', value: 'NO_BORDER'
                }
            ],
            documentConfigs: [

                row,

            ]
        };
        const documentTableConfig1: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [
                {
                    key: 'BORDER', value: 'NO_BORDER'
                }
            ],
            documentConfigs: [

                row1,

            ]
        };

        return [documentTableConfig,documentTableConfig1];
    }

    getOwnerApproverTableConfig(gpt: GlobalProcedureTemplate | LocalProcedureTemplate, type: string) {
        const breakConfig: DocumentConfigModel = {
            type: "TEXT",
            value: "",
            textTypes: [],
            sectionType: "CHILDREN",
        };

        const row1: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'WIDTH', value: 1505,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: 'Owner:',
                            textTypes: [
                                { key: 'FONT_SIZE', value: 14 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' }
                            ],
                        },
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'WIDTH', value: 8505,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: gpt.owner,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 12 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyRegular },
                                { key: 'COLOR', value: '#000000' }
                            ],
                        },
                    ]
                },
            ]
        };

        const row2: DocumentConfigModel = {
            type: 'TABLE_ROW',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [],
            documentConfigs: [
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'WIDTH', value: 1505,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: 'Approver:',
                            textTypes: [
                                { key: 'FONT_SIZE', value: 14 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                                { key: 'COLOR', value: '#000000' }
                            ],
                        },
                    ]
                },
                {
                    type: 'TABLE_CELL',
                    sectionType: 'CHILDREN',
                    value: '',
                    textTypes: [
                        {
                            key: 'WIDTH', value: 8505,
                        }
                    ],
                    documentConfigs: [
                        {
                            type: 'TEXT',
                            sectionType: 'CHILDREN',
                            value: gpt.approver,
                            textTypes: [
                                { key: 'FONT_SIZE', value: 12 },
                                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyRegular },
                                { key: 'COLOR', value: '#000000' }
                            ],
                        },
                    ]
                },
            ]
        };

        const documentTableConfig: DocumentConfigModel = {
            type: 'TABLE',
            sectionType: 'CHILDREN',
            value: '',
            textTypes: [],
            documentConfigs: [
                row1,
                row2,
            ]
        };

        return [breakConfig, documentTableConfig];
    }



    getHeaderConfigs(text: string) {
        const documentConfigs: DocumentConfigModel[] = [];

        documentConfigs.push({
            type: "TEXT",
            value: "",
            textTypes: [],
            sectionType: "CHILDREN",
        });
        documentConfigs.push({
            type: "TEXT",
            sectionType: "CHILDREN",
            textTypes: [
                { key: "FONT_SIZE", value: 10 },
                { key: "FONT_FAMILY", value: Constants.DocFontFamilyBold },
                { key: "COLOR", value: "#000000" },
            ],
            value: text,
        });
        documentConfigs.push({
            type: "TEXT",
            value: "",
            textTypes: [],
            sectionType: "CHILDREN",
        });

        return documentConfigs;
    }
}

const documentService = new DocumentService();
export default documentService;