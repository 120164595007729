import { Component, ReactNode } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Card, CardContent, CircularProgress, Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import BreadCrumb from '../Components/BreadCrumb';
import WhiteButton from '../Components/Button/WhiteButton';
import RedButton from '../Components/Button/RedButton';
import PageHeader from '../Components/Text/PageHeader';
import { GPTService, LPTService, ProcedureFileService } from '../Services';
import { CompareDocxModel, GlobalProcedureTemplate, GlobalProcedureVersionModel, ComparePdfModel } from '../Models';
import SelectModel from '../Components/Select/SelectModel';
import SingleSelect from '../Components/Select/SingleSelect';
import Utils from '../Common/Utils';
import BreadCrumbModel from '../Components/BreadCrumb/BreadCrumbModel';
import BackLinkButton from '../Components/Button/BackLinkButton';
import MyCustomSnackbar from '../Components/SnackBar/MyCustomSnackbar';
import IconButton from '../Components/Button/IconButton';
import { FileType } from '../Common/Enums';
import { LocalProcedureVersionModel } from '../Models/LocalProcedureVersionModel';

interface Props extends RouteComponentProps<any, any, {
  editTemplate: any
}> { }
interface State {
  gptVersions: GlobalProcedureVersionModel | null;
  lptVersions: LocalProcedureVersionModel | null;
  selectedVersion1: SelectModel | undefined;
  selectedVersion2: SelectModel | undefined;
  versions1: SelectModel[];
  versions2: SelectModel[];
  procedureType: string;
  compareDocx: CompareDocxModel | null;
  comparePdf: ComparePdfModel | null;
  breadCrumbs: BreadCrumbModel[];
  loading:boolean;
  isError:boolean;
  isCompareError: boolean;
  isCompared:boolean;
  pageHeader: string;
  fileName: string;
}
class DocumentCompare extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      gptVersions: null,
      lptVersions: null,
      loading:false,
      isError:false,
      isCompareError: false,
      isCompared:false,
      pageHeader: "",
      fileName:"file",
      versions1: [],
      versions2: [],
      procedureType: "",
      selectedVersion1: undefined,
      selectedVersion2: undefined,
      compareDocx: null,
      comparePdf: null,
      breadCrumbs: [
        {
          label: 'Procedure Template',
          onClick: () => {
            this.props.history.push('/procedure_templates');
          }
        },
        {
          label: 'View Compared Document'
        }
      ]
    };
  }

  async componentDidMount(): Promise<void> {
    console.log("props in doc compare",this.props);
    this.setState({isCompareError: false})
    if (this.props.match.path === "/local_document_compare/:id"){
      const lpId = this.props.match.params.id;
      const isNumber = Utils.isNumber(lpId);

      console.log("lpid :",lpId);
      

      if (isNumber) {
        const lptVersions = await LPTService.getVersionsByLPId(lpId);

        const versions1: SelectModel[] = [];
        const versions2: SelectModel[] = [];

        if (lptVersions) {
          const lpt = lptVersions.localProcedure.localProcedure;
          versions2.push({ text: `${lpt.templateName} (v${lpt.versionNumber})`, value: lpt.lpId?.toString() ?? '' });

          lptVersions.localProcedureHistory.localProcedureHistories.forEach((lpt) => {
            versions1.push({ text: `${lpt.templateName} (v${lpt.versionNumber})`, value: lpt.lpId?.toString() ?? '' });
          });
        }
        this.setState({ 
          lptVersions, 
          versions1, 
          versions2, 
          procedureType: 'local', 
          fileName: `PCoE_Pro_${lptVersions?.localProcedure.localProcedure.templateName}_EN_${lptVersions?.localProcedure.localProcedure.geoGraphyName}`,
          pageHeader:`PCoE_Pro_${lptVersions?.localProcedure.localProcedure.templateName}(v${lptVersions?.localProcedure.localProcedure.versionNumber})` });
      }

    } else {
      const gpId = this.props.match.params.id;
    const isNumber = Utils.isNumber(gpId);

    if (isNumber) {
      const gptVersions = await GPTService.getVersionsByGPId(gpId);

      const versions1: SelectModel[] = [];
      const versions2: SelectModel[] = [];

      if (gptVersions) {
        const gpt = gptVersions.globalProcedure.globalProcedure;
        versions2.push({ text: `${gpt.templateName} (v${gpt.versionNumber})`, value: gpt.gpId?.toString() ?? '' });

        gptVersions.globalProcedureHistory.globalProcedureHistories.forEach((gpt) => {
          versions1.push({ text: `${gpt.templateName} (v${gpt.versionNumber})`, value: gpt.gpId?.toString() ?? '' });
        });
      }
      this.setState({ 
        gptVersions, 
        versions1, 
        versions2, 
        procedureType:'global', 
        fileName: `TEMPLATE_PCoE_Pro_${gptVersions?.globalProcedure.globalProcedure.templateName}`,
        pageHeader: `TEMPLATE_PCoE_Pro_${gptVersions?.globalProcedure.globalProcedure.templateName}v(${gptVersions?.globalProcedure.globalProcedure.versionNumber})` });
    }
    }
  }

  render(): ReactNode {
    const { breadCrumbs, gptVersions, lptVersions, loading, versions1, versions2, selectedVersion1, selectedVersion2, comparePdf } = this.state;
    console.log(versions1, versions2, selectedVersion1, selectedVersion2);
    console.log("data in doc compare",this.props.location.state.editTemplate);
    
    
    let differencesCount = 0;

    // if (compareDocx) {
    //   differencesCount = compareDocx.SentenceAdded.length + compareDocx.SentenceRemoved.length;
    // }
    if (comparePdf) {
      differencesCount = comparePdf.SentenceAdded.length + comparePdf.SentenceRemoved.length;
    }
    if (loading) {
      return (<><Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box></>)
    }

    return (
      <>
      <MyCustomSnackbar
              actions={this.action}
              message="Download link has expired !! Please compare again to download."
              severity="warning"
              open={this.state.isError}
            />
          <MyCustomSnackbar
              actions={this.action}
              message="Error in Comparison, please try again."
              severity="warning"
              open={this.state.isCompareError}
            />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <Grid marginBottom={"2em"} container direction="row">
            <BreadCrumb breadCrumbs={breadCrumbs} />
          </Grid> */}
          <Grid container direction={'row'} style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1em',
            justifyContent: 'space-between'
          }}>
            <Grid item xs={8}>
              <Grid container spacing={2} sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                
               { this.props.history.length > 1 && <Grid item>
                  <BackLinkButton onClick={() => {
                    // const gpId = this.props.match.params.id;
                    // this.props.history.push(`/view_template/${gpId}?mode=view`);
                    this.props.history.goBack();
                  }} />
                </Grid>}
                <Grid item xs={11} sx={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-all',
                  // maxWidth: '42em'
                }}>
                  <PageHeader label={this.state.pageHeader} />
                  {/* {gptVersions 
                   ?<PageHeader label={`TEMPLATE_PCoE_Pro_${gptVersions?.globalProcedure.globalProcedure.templateName}v(${gptVersions?.globalProcedure.globalProcedure.versionNumber})`} />
                   :<PageHeader label={`PCoE_Pro_${lptVersions?.localProcedure.localProcedure.templateName}(v${lptVersions?.localProcedure.localProcedure.versionNumber})`}/>
                   } */}
                  
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item><WhiteButton label="Download Clean" onClick={this.onDownloadClick} disabled={!this.state.isCompared}/></Grid>
                <Grid item><WhiteButton label="Download Compared" onClick={this.handleDownload} disabled={!this.state.isCompared}/></Grid>
                  <Grid item>
                    <WhiteButton
                      label="Edit"
                      onClick={() => {
                        if (this.state.procedureType === 'global') {
                          window.location.assign(
                            `/view_template/${this.props.location.state?.editTemplate?.gpId}?mode=edit`
                          );
                        } else {
                          window.location.assign(
                            `/eco_localization/${this.props.location.state?.editTemplate?.lpId}?mode=edit`
                          );
                        }
                      }}
                    />
                  </Grid>
                <Grid item><RedButton label='Compare' onClick={this.handleCompare} disabled={this.blankFieldValidation()}/></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4.5}>
              <Grid container flexDirection='column' spacing={2}>
                <Grid item xs={12}>
                  <SingleSelect id='selectedVersion1' label='Select' value={selectedVersion1?.value ?? ''} values={versions1} onChange={this.handleSelectChange} />
                </Grid>
              
                {comparePdf ? <Grid item xs={12}>
                  <iframe title='Version 1' src={`${comparePdf.key1}#toolbar=0&navpanes=0`} style={{ display: 'block', width: '100%', height: '75vh' }} />
                </Grid> : null}
              </Grid>
            </Grid>
            <Grid item xs={4.5}>
              <Grid container flexDirection='column' spacing={2}>
                <Grid item xs={12}>
                  <SingleSelect id='selectedVersion2' label='Select' value={selectedVersion2?.value ?? ''} values={versions2} onChange={this.handleSelectChange} />
                </Grid>
                {/* {compareDocx ? <Grid item xs={12}>
                  <iframe title='Version 2' src={`${compareDocx.key2}#toolbar=0&navpanes=0`} style={{ display: 'block', width: '100%', height: '75vh' }} />
                </Grid> : null} */}
                {comparePdf ? <Grid item xs={12}>
                  <iframe title='Version 1' src={`${comparePdf.key2}#toolbar=0&navpanes=0`} style={{ display: 'block', width: '100%', height: '75vh' }} />
                </Grid> : null}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Card variant='outlined'>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography fontSize={16} style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                      }}>Differences Found: {differencesCount}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}>Text Added</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List>
                        {/* {compareDocx && compareDocx.SentenceAdded.map((text, i) => {
                          return <ListItem key={`txtAdd-${i}`} sx={{
                            display: 'flex',
                            alignItems: 'baseline',
                          }}>
                            <FontAwesomeIcon
                              style={{ color: "lightgreen", paddingRight: '0.5em', fontSize: '10' }}
                              icon={faCircle}
                            />
                            <ListItemText
                              primary={text}
                            />
                          </ListItem>
                        })} */}
                        {comparePdf && comparePdf.SentenceAdded.map((text, i) => {
                          return <ListItem key={`txtAdd-${i}`} sx={{
                            display: 'flex',
                            alignItems: 'baseline',
                          }}>
                            <FontAwesomeIcon
                              style={{ color: "lightgreen", paddingRight: '0.5em', fontSize: '10' }}
                              icon={faCircle}
                            />
                            <ListItemText
                              primary={text}
                            />
                          </ListItem>
                        })}
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}>Text Edited</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List>
                        {/* {compareDocx && compareDocx.SentenceRemoved.map((text, i) => {
                          return <ListItem key={`txtEdi-${i}`} sx={{
                            display: 'flex',
                            alignItems: 'baseline',
                          }}>
                            <FontAwesomeIcon
                              style={{ color: "red", paddingRight: '0.5em', fontSize: '10' }}
                              icon={faCircle}
                            />
                            <ListItemText
                              primary={text}
                            />
                          </ListItem>
                        })} */}
                        {comparePdf && comparePdf.SentenceRemoved.map((text, i) => {
                          return <ListItem key={`txtEdi-${i}`} sx={{
                            display: 'flex',
                            alignItems: 'baseline',
                          }}>
                            <FontAwesomeIcon
                              style={{ color: "red", paddingRight: '0.5em', fontSize: '10' }}
                              icon={faCircle}
                            />
                            <ListItemText
                              primary={text}
                            />
                          </ListItem>
                        })}
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </>
    );
  }

  handleSelectChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      if (targetId === 'selectedVersion1') {
        this.setState({ selectedVersion1: selected });
      } else if (targetId === 'selectedVersion2') {
        this.setState({ selectedVersion2: selected });
      }
    }
  }

  blankFieldValidation = () => {
    const { selectedVersion1, selectedVersion2 } = this.state;
    if (selectedVersion1 === undefined || selectedVersion2 === undefined){
      return true
    }
    return false
  }

  handleCompare = async () => {
    this.setState({ isError: false });
    const { selectedVersion1, selectedVersion2, procedureType } = this.state;
    console.log("procedure type selected :",procedureType);
    
     this.setState({loading:true})
    if (selectedVersion1 && selectedVersion2) {
      const response = await ProcedureFileService.comparepdf(selectedVersion1.value, selectedVersion2.value, procedureType);
      if (response) {
        this.setState({ comparePdf: response, loading: false, isCompared: true, isCompareError: false });
      } else {
        const response2 = await ProcedureFileService.comparepdf(selectedVersion1.value, selectedVersion2.value, procedureType);
        if (response2) {
          this.setState({ comparePdf: response, loading: false, isCompared: true, isCompareError: false });
        } else {
          this.setState({ isCompareError: true, loading: false })
        }
      }
    }
  }
  action = (
    <>
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ isError: false, isCompareError: false });
        }}
      />
    </>
  );
  
  handleDownload = async () => {
    const { comparePdf } = this.state;  
    this.setState({loading: true})  
    if (comparePdf) {
      // const file = await ProcedureFileService.getFile(comparePdf.key2, FileType.PDF);
      // if (file) {
        fetch(comparePdf.key2)
        
        
        .then( (response) => {
          console.log(response,"response");
          
          if (response.ok) {
            this.setState({isError : false});
            return response.blob();
          }
          throw new Error('Network response was not OK.');
        })
        .then( (blob) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.state.fileName}_EN.pdf`;
          document.body.appendChild(link); link.click(); 
          document.body.removeChild(link);
          this.setState({loading:false})
        }).catch( (error) => { 
          this.setState({isError : true, loading: false});
          console.error('Error:', error);
          
        });
      // }
    }
  }

  onDownloadClick = async () => {
    console.log("triigerr", this.props.location);
    this.setState({loading: true})
    let filePath;
    if (this.state.procedureType === 'global'){
      filePath = await ProcedureFileService.cleandocument(
        this.props.location.state?.editTemplate?.gpTemplateFile!,
          FileType.DOCX
        )
        console.log("filepath == ",filePath);
        console.log("gpt path == ",this.props.location.state?.editTemplate?.gpTemplateFile!);
    } else {
      filePath = await ProcedureFileService.cleandocument(
        this.props.location.state?.editTemplate?.ipFilePath!,
          FileType.DOCX
        )
        console.log("filepath == ",filePath);
        console.log("lpt path == ",this.props.location.state?.editTemplate?.ipFilePath!);
    }
    
      
    
    const docFile = await ProcedureFileService.getFile(
        filePath!,
          FileType.DOCX
        )
      
      
      fetch(docFile!)
      .then( (response) => {
        console.log("response == ",response);
        
        if (response.ok) {
          this.setState({isError : false});
          return response.blob();
        }
        throw new Error('Network response was not OK.');
      })
      .then((blob) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.state.fileName}_EN.docx`;
        document.body.appendChild(link); link.click();
        document.body.removeChild(link);
        this.setState({loading:false})
      }).catch( (error) => {
        this.setState({isError : true, loading:false});
        console.error('Error:', error);
      });
    }
}

export default withRouter(DocumentCompare);