import "./Lexical.css";
import React from "react";
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, EditorState, KEY_DOWN_COMMAND, LexicalEditor, RangeSelection, SELECTION_CHANGE_COMMAND, TextNode, COPY_COMMAND, COMMAND_PRIORITY_CRITICAL, CLICK_COMMAND, ParagraphNode } from "lexical";
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
} from '@lexical/selection';
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { Box } from "@mui/material";
import ToolbarPlugin from "./ToolbarPlugin";
import TableCellActionMenuPlugin from "./TableActionMenuPlugin";
import PlaygroundEditorTheme from "./Theme/PlayGroundEditorTheme";
import { ImageNode } from "./nodes/ImageNode";
import LexicalContentEditable from "./ui/ContentEditable";
import { TableContext } from "./TablePlugin";
import ImagesPlugin from "./ImagesPlugin";
import LexicalLinkPlugin from "./LinkPlugin";
import FloatingTextFormatToolbarPlugin from "./FloatingTextFormatToolbarPlugin";
import FloatingLinkEditorPlugin from "./FloatingLinkEditorPlugin";
import ClickableLinkPlugin from "./ClickableLinkPlugin";
import Constants from "../../Common/Constants";
import { applyHtmlToRichContentPatches } from "./patches/stylePatch";
import BulletListPlugin from "./BulletListPlugin";

applyHtmlToRichContentPatches();

interface Props {
  index?: number;
  editorState: EditorState;
  onChange: (editorState: EditorState, index?: number, editor?: LexicalEditor) => void;
  id?: string;
  isRequired?: boolean;
  handleKey?: () => void;
  toogleUndo?: boolean;
  toogleRedo?: boolean;
  skipDefaultFontFmily?: boolean;
  defaultStyle?: string;
  skipStrongStylePatch?: boolean;
  handleCopyCommand?: boolean;
  ignoreClickCommand?: boolean;
  onRefChange?: ((editorRef: any) => void);
  onTextSelected?: ((showBlackTextPopup: boolean) => void);
}
interface State {
  editorState: EditorState;
  _floatingAnchorElem: HTMLDivElement | null | any;
  isSmallWidthViewport: boolean;
  initialConfig: InitialConfigType;
}
class RichTextEditor extends React.Component<Props, State> {
  ref: any;
  constructor(props: Props | Readonly<Props>) {

    super(props);
    this.ref = React.createRef();

    const initialConfig: InitialConfigType = {

      namespace: "RTEditor" + props.id,
      nodes: [
        TableCellNode,
        TableNode,
        TableRowNode,
        ImageNode,
        HorizontalRuleNode,
        AutoLinkNode,
        LinkNode,
        HeadingNode,
        QuoteNode,
        ListNode,
        ListItemNode,
      ],
      onError: (err: any) => console.log("RTEditor", err),
      editorState: props.editorState,
      theme: PlaygroundEditorTheme,
    };
    this.state = {
      editorState: props.editorState,
      _floatingAnchorElem: null,
      isSmallWidthViewport: false,
      initialConfig,
    };
  }



  onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      this.setState({ _floatingAnchorElem });
    }
  };

  componentDidMount(): void {
    console.log("this ref=", this.ref);
    if (this.props.onRefChange) {
      this.props.onRefChange(this.ref);
    }
    this.ref?.current?.registerCommand(KEY_DOWN_COMMAND, (event: KeyboardEvent) => {
      // eslint-disable-next-line no-lone-blocks
      // {this.props.onKeyChange() && this.props.onKeyChange();}
      const selection = $getSelection() as RangeSelection;

      if ($isRangeSelection(selection)) {
        const defaultFontFamily = this.props.defaultStyle ?? Constants.DocFontFamilyRegular;
        const fontFamilyStyle = 'font-family';
        const fontSizeStyle = 'font-size';
        const ffSelectionStyle = $getSelectionStyleValueForProperty(selection, fontFamilyStyle);

        if (!ffSelectionStyle) {
          $patchStyleText(selection, {
            [fontFamilyStyle]: defaultFontFamily,
            [fontSizeStyle]: Constants.DefaultFontSize,
          });
        }
      }
      this.handleKeyChange();
      console.log("key pressed")
      return false;
    }, COMMAND_PRIORITY_LOW)

    // keep this code
    this.ref?.current?.registerCommand(COPY_COMMAND, (event: ClipboardEvent) => {
      if (!(this.props.handleCopyCommand === undefined)) {
        return this.props.handleCopyCommand;
      }
      return true;
    }, COMMAND_PRIORITY_CRITICAL);
    // keep this code

    if (!this.props.ignoreClickCommand) {
      this.ref?.current?.registerCommand(CLICK_COMMAND, (event: ClipboardEvent) => {
        const selection = $getSelection() as RangeSelection;

        if ($isRangeSelection(selection)) {
          const defaultFontFamily = this.props.defaultStyle ?? Constants.DocFontFamilyRegular;
          const fontFamilyStyle = 'font-family';
          const fontSizeStyle = 'font-size';
          const ffSelectionStyle = $getSelectionStyleValueForProperty(selection, fontFamilyStyle);

          if (!ffSelectionStyle) {
            $patchStyleText(selection, {
              [fontFamilyStyle]: defaultFontFamily,
              [fontSizeStyle]: Constants.DefaultFontSize,
            });
          }
        }
        return false;
      }, COMMAND_PRIORITY_CRITICAL);
    }

    this.ref?.current?.registerCommand(SELECTION_CHANGE_COMMAND, (event: KeyboardEvent) => {
      this.ref?.current?.update(() => {
        let showPopup = false;
        const selection = $getSelection() as RangeSelection;

        if (selection) {
          const nodes = selection.getNodes();          
          
          let isFirstBlackTextNode = false;
          let isLastBlackTextNode = false;
          let areAllBlackTextNodes = false;
          let areAllEditableTextNodes = false;
          let lastNodeLength = 0;
          let lastNodeKey = '';

          nodes.forEach((node, i) => {
            const isTextNode = node instanceof TextNode;
            const isParagraphNode = node instanceof ParagraphNode;

            if (isTextNode) {
              const textNode = node as TextNode;

              if (textNode && typeof textNode.getStyle === 'function') {
                const style = textNode.getStyle();
                const isLimitedBlackTextDeleteRule = style.includes('LIMITED_BLACK_TEXT_DELETE');
  
                if (i === 0 && isLimitedBlackTextDeleteRule) {
                  const text = textNode.getTextContent();
                  isFirstBlackTextNode = text.startsWith('[');
                }
  
                if (nodes.length > 1 && i === (nodes.length - 1) && isLimitedBlackTextDeleteRule) {
                  const text = textNode.getTextContent();
                  lastNodeKey = textNode.getKey();
                  isLastBlackTextNode = text.startsWith('[Delete');
                  lastNodeLength = text.length;
                }
  
                areAllBlackTextNodes = isLimitedBlackTextDeleteRule;
                areAllEditableTextNodes = style.includes('EDITABLE_TEXT');
  
                if (!showPopup) {
                  showPopup = style.includes('NON_EDITABLE_TEXT');
                }
              }
            }

            if (isParagraphNode) {              
              showPopup = true;
            }            
          });

          if (areAllBlackTextNodes) {
            if (isFirstBlackTextNode && isLastBlackTextNode) {
              showPopup = false;

              if (selection.focus.key === lastNodeKey) {
                if (!(selection.anchor.offset === 0 &&
                  selection.focus.offset === lastNodeLength)) {
                  showPopup = true;
                }
              } else if (selection.anchor.key === lastNodeKey) {
                if (!(selection.focus.offset === 0 &&
                  selection.anchor.offset === lastNodeLength)) {
                  showPopup = true;
                }
              }
            } else {
              if (!areAllEditableTextNodes) {
                showPopup = true;
              }
            }
          }

          if (this.props.onTextSelected) {
            this.props.onTextSelected(showPopup);
          }
        }
      });
      return false;
    }, COMMAND_PRIORITY_LOW);
  }


  // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
  //   console.log("componentDidUpdate...");
  //   // this.onRef()
  // }

  render(): React.ReactNode {
    const { initialConfig } = this.state;

    // removeTransform = this.props.editorState.registerNodeTransform(TextNode, (textNode) => {
    //   if (textNode.getTextContent() === 'blue') {
    //     textNode.setTextContent('green');
    //   }
    // });


    const text = "Enter some rich text...";

    return (
      <Box style={{ border: "1px solid lightgray" }}>
        <LexicalComposer initialConfig={initialConfig}>
          <TableContext>
            <>
              <ToolbarPlugin toggleUndo={this.props.toogleUndo!} toogleRedo={this.props.toogleRedo!} />
              <div className={`editor-container tree-view`}>
                <RichTextPlugin
                  contentEditable={
                    <div className="editor-scroller">
                      <div
                        className="editor"
                        id={this.props.id}
                        style={{ marginTop: "2.5rem", zoom: "130%" }}
                        ref={this.onRef}
                        aria-required='true'
                        data-defaultStyle={this.props.defaultStyle}
                        data-skipStrongStylePatch={this.props.skipStrongStylePatch}
                      >
                        <LexicalContentEditable />
                      </div>
                    </div>
                  }
                  placeholder={
                    <div
                      style={{
                        marginTop: "-6rem",
                        marginBottom: "6rem",
                        marginLeft: "1.5rem",
                        color: "grey",
                      }}

                    >
                      {text}
                    </div>
                  }
                  ErrorBoundary={LexicalErrorBoundary}
                />
              </div>
              <TablePlugin />
              {this.state._floatingAnchorElem &&
                !this.state.isSmallWidthViewport && (
                  <>
                    <TableCellActionMenuPlugin
                      anchorElem={this.state._floatingAnchorElem}
                    />
                  </>
                )}
              <OnChangePlugin ignoreSelectionChange onChange={this.handleChange} />
            </>
          </TableContext>
          <FloatingTextFormatToolbarPlugin />
          <ImagesPlugin captionsEnabled={false} />
          <HorizontalRulePlugin /> <ClearEditorPlugin /> <ClickableLinkPlugin />
          <LexicalLinkPlugin /> <FloatingLinkEditorPlugin /> <ListPlugin />
          <HistoryPlugin />
          {/* <BulletListPlugin /> */}
          <EditorRefPlugin editorRef={this.ref} ></EditorRefPlugin>
        </LexicalComposer>
      </Box>
    );
  }
  handleKeyChange = () => {
    if (this.props.handleKey) {
      this.props.handleKey();
    }
  }

  handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    const { index, skipDefaultFontFmily } = this.props;

    if (!skipDefaultFontFmily) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const fontFamilyStyle = 'font-family';
          const fontSizeStyle = 'font-size';
          const ffSelectionStyle = $getSelectionStyleValueForProperty(selection, fontFamilyStyle);

          if (!ffSelectionStyle) {
            $patchStyleText(selection, {
              [fontFamilyStyle]: Constants.DocFontFamilyRegular,
              [fontSizeStyle]: Constants.DefaultFontSize,
            });
          }
        }
      });
    }

    this.props.onChange(editorState, index, editor);
  };
}
export default RichTextEditor;

