import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import React, { Component } from 'react'
import { RadioButtonValuesModel } from './RadioButtonValuesModel';

interface Props {
    direction?: "row" | "column";
    buttonValues: RadioButtonValuesModel[];
    formLabel?: string;
    name?: string;
    value?: any;
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void);
}

interface State { }

class RadioButtons extends Component<Props, State> {
    render() {
        const { buttonValues, formLabel, onChange, direction, name, value } = this.props;
        return (
            <FormControl>
                <FormLabel id="radio-buttons-group-label">{formLabel}</FormLabel>
                <RadioGroup
                    row={direction ? direction === "row" : true}
                    name={name}
                    onChange={onChange}
                    value={value}
                >
                    {buttonValues.map((button, index) => {
                        return (
                            <FormControlLabel value={button.value} disabled={(button.disabled || button.isReadOnly) ?? false} control={<Radio />} label={button.label} key={button.key ?? index} />
                        )
                    })}
                </RadioGroup>
            </FormControl>
        )
    }
}

export default RadioButtons;
