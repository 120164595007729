import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid } from "@mui/material";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import BreadCrumb from "../../Components/BreadCrumb";
import PageHeader from "../../Components/Text/PageHeader";
import WhiteButton from "../../Components/Button/WhiteButton";
import { LocalProcedureTemplate, EmailDTO } from "../../Models";
import { ProcedureFileService } from "../../Services";
import { FileType } from "../../Common/Enums";
import DocumentPreview from "../../Components/DocumentPreview";
import MyCustomSnackbar from "../../Components/SnackBar/MyCustomSnackbar";
import IconButton from "../../Components/Button/IconButton";
import BreadCrumbModel from "../../Components/BreadCrumb/BreadCrumbModel";
import RedButton from "../../Components/Button/RedButton";
import { faArrowUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import BackLinkButton from "../../Components/Button/BackLinkButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lptService from "../../Services/LPTService";
import { LocalProcedureTemplateSection } from "../../Models/LocalProcedureTemplateSection";
import EmailService from "../../Services/EmailService";
import Utils from "../../Common/Utils";
interface Props
  extends RouteComponentProps<
    any,
    any,
    {
      templateUrl?: string;
      templateBlob?: Blob | null;
      editTemplate: LocalProcedureTemplate;
      sections?: LocalProcedureTemplateSection[] | undefined;
      generateSelectedValue?: string;
      templateData?: any;
      lpId: number;
      ApprovalViewData?: any,
      isUploaded: boolean,
      backButtonPressed: boolean,
      view?: string
    }
  > { }

interface State {
  documentUrl: string;
  open: boolean;
  breadCrumbs: BreadCrumbModel[];
  load: boolean;
  isSent: boolean;
  submitDialog: boolean;
  isNoSelected: boolean;
  isError: boolean;
  view: string;
  templateData: any;
}

export class EcoDocumentView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      documentUrl: "",
      open: false,
      isSent: false,
      submitDialog: false,
      view: "",
      isNoSelected: false,
      isError: false,
      templateData: {
        // templateName: "",
        // basicPrinciples: "",
        // bodyText: "",
        // approver: "",
        // effectiveDate: null,
        // footer: "",
        // footerImageFile: "",
        // header: "",
        // headerImageFile: "",
        // owner: "",
        // procedureType: "",
        // versionNumber: "",
        // _basicPrinciples: Utils.getEditorState(),
        // _bodyText: Utils.getEditorState(),
        // _headerImageFile: {} as FileUploadModel,
        // _footerImageFile: {} as FileUploadModel,
        // _gpTemplateFile: {} as FileUploadModel,
        // _header: Utils.getEditorState(),
      },
      breadCrumbs: [],
      load: false
    };
  }

  componentDidMount(): void {
    this.gettingDocUrl();
  }

  action = (
    <>
      {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ open: false, isError: false });
        }}
      />
    </>
  );

  actions = (
    <>
    </>
  );
  render() {
    console.log("props", this.props);
    if (!this.state.documentUrl && !this.state.load) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    return (
      <>
        <MyCustomSnackbar
          actions={this.action}
          message="Error downloading."
          severity="warning"
          open={this.state.isError}
        />
        <MyCustomSnackbar
          message="Local Procedure has been generated successfully."
          severity="success"
          actions={this.action}
          open={this.state.open}
          onClose={() => {
            this.setState({ open: false });
          }}
        />
        <MyCustomSnackbar
          message="Local Procedure sent For approval successfully."
          severity="success"
          actions={this.actions}
          open={this.state.isSent}
          onClose={() => {
            this.setState({ isSent: false });
          }}
        />
        <Box
          // style={{
          //   marginTop: "0.5em",
          // }}
        >
          {(this.state.view) ? (
            <>
              {/* <Grid marginBottom={"2em"} container direction="row">
                <BreadCrumb breadCrumbs={this.state.breadCrumbs} />
              </Grid> */}
              <Grid
                container
                direction={"row"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1em",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={8.2}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                {   this.props.history.length > 1 && <Grid item>
                      <BackLinkButton
                        onClick={() => {
                          // this.props.history.push(
                          //   `/eco_localization/${this.props.location.state?.editTemplate?.gpId}?mode=view`
                          // );
                          // window.location.assign(`/eco_localization/${this.props.location?.state?.lpId}?mode=view`);

                          this.props.history.goBack();

                        }}
                      />
                    </Grid>}
                    <Grid
                      item xs={10}
                      sx={{
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                        // maxWidth: "48em",
                      }}
                    >
                      {this.props.match.params.id ?
                        <PageHeader
                          label={`${this.state.templateData.templateName}`}
                        />
                        :
                        <PageHeader
                          label={`${this.props.location.state?.templateData.templateName}`}
                        />
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                   {this.props.history.length > 1 &&  <Grid item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em"
                      }}>
                      <WhiteButton label="Cancel" onClick={() => {
                        this.props.history.goBack();

                      }} />
                    </Grid>}
                    {/* <Grid item>
                      <WhiteButton
                        label="Download Word"

                        onClick={() => {
                          this.onWordDownloadClick()
                        }}
                      />
                    </Grid> */}
                    <Grid item>
                      <WhiteButton
                        label="Download PDF"

                        onClick={() => {
                          this.onPdfDownloadClick()
                        }}
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </>)
            : (<>
              {/* <Grid marginBottom={"2em"} container direction="row">
                <BreadCrumb breadCrumbs={this.state.breadCrumbs} />
              </Grid> */}
              <Grid
                container
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1em",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={8.5}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {this.props.history.length > 1 && <Grid item>
                      <BackLinkButton
                        onClick={() => {
                          // this.props.history.push(
                          //   `/eco_localization/${this.props.location.state?.editTemplate?.gpId}?mode=view`
                          // );

                          this.props.history.goBack();

                        }}
                      />
                    </Grid>}
                    <Grid
                      item xs={10.8}
                      sx={{
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                        // maxWidth: "47em",
                        // flexGrow: 1
                      }}
                    >
                      <PageHeader
                        label={`PCoE_Pro_${this.props.location.state?.editTemplate.templateName}_${this.props.location.state?.editTemplate.geoGraphyName}(v${this.props.location.state?.editTemplate.versionNumber})`}
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em"
                      }}>
                      <WhiteButton label="Cancel" onClick={() => {
                        this.props.history.push(
                          `/eco_localization/${this.props.location.state?.editTemplate?.lpId}?mode=view`
                        );

                      }} />
                    </Grid>
                    <Grid item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em"
                      }}>
                      <WhiteButton label="Download" onClick={this.onDownloadClick} />
                    </Grid>
                    <Grid item>
                      <WhiteButton label="Edit"
                        onClick={() => {
                          this.props.history.push(
                            `/eco_localization/${this.props.location.state?.editTemplate?.lpId}?mode=edit`
                          );
                        }} />
                    </Grid>
                    {/* <Grid item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em"
                      }}>
                      <RedButton label="Submit" onClick={this.onSubmit} />
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </>)}

          <Divider />
          <Dialog open={this.state.submitDialog}>
            <Grid
              container
              direction={"row"}
              style={{
                display: "flex",
                alignItems: "center",
                // marginBottom: "1em",
                justifyContent: "space-between"
              }}>
              <Grid item>
                <DialogTitle color={"red"}>
                  Do you want to upload a translation?
                </DialogTitle>
              </Grid>
              <Grid item>
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  onClick={() => {
                    this.setState({ submitDialog: false });
                  }}
                  style={{ marginRight: "1em", cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <DialogContent dividers>
              <DialogContentText marginTop={2} color={"black"}>
                Upload the local translation document for this procedure, if applicable.
              </DialogContentText>
              <DialogContentText marginTop={1} color={"black"}>
                This translation will be routed for approval.
              </DialogContentText>
              {/* <DialogContentText marginTop={2} color={"black"}>
                Any previously uploaded translation will be submitted...
              </DialogContentText> */}
              <DialogContent>
                <Grid container mt={2} ml={-3}>
                  <Grid item mr={2}>
                    <Button
                      size="medium"
                      variant="outlined"
                      color="error"
                      style={{
                        textTransform: "capitalize"
                      }}
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/file_upload`, state: {
                            editTemplate: this.props.location?.state?.editTemplate,
                            lpId: this.props.location?.state?.lpId,
                            isUploaded: this.props.location.state.isUploaded,
                            backButtonPressed: this.props.location.state.backButtonPressed,
                          }
                        });

                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item>
                    <RedButton
                      label="No"
                      onClick={() => {
                        this.setState({ isNoSelected: true, submitDialog: false });
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </DialogContent>
          </Dialog>
          <Grid>
            <DocumentPreview documentUrl={this.state.documentUrl} height="700px" />
          </Grid>

        </Box>
      </>
    )

  }

  gettingDocUrl = async () => {
    console.log("thsi.props", this.props.match.params.id);
    
    if (this.props.match.params.id) {
      const templateData = await lptService.getByLPId(this.props.match.params.id)
      this.setState({templateData: templateData?.localProcedure})
      console.log("templateData", templateData);
      this.setState({
        breadCrumbs: [
          {
            label: 'Local Procedure',
            onClick: () => {
              this.props.history.goBack();
            }
          },
          {
            label: 'View Procedure'
          },
          {
            label: `PCoE_Pro_${templateData?.localProcedure.templateName}_${templateData?.localProcedure.geoGraphyName}(v${templateData?.localProcedure.versionNumber})` ,
          },
        ],
      })
      this.setState({ view: "EcoView" })
      try {
        const filePath = templateData?.localProcedure.ipFilePath!.split('.docx')
        const pdfFilePath = filePath![0] + '.pdf'
        const data = await ProcedureFileService.getPresignedUrl(pdfFilePath, FileType.PDF);
        console.log("url", data);
        this.setState({ documentUrl: data!, load: false });
      } catch (error) {
        this.setState({ load: false });
        console.error(error)
      }
    }
    else {
      console.log("data received by eco doc viewer", this.props.location.state?.editTemplate);
      // console.log("view==", this.props.location.state.view);
      // if (this.props.location.state?.editTemplate){
      //   this.setState({
      //     breadCrumbs: [
      //       {
      //         label: 'Local Procedure Dashboard',
      //         onClick: () => {
      //           this.props.history.goBack();
      //         }
      //       },
      //       {
      //         label: 'View Procedure Document'
      //       },
      //       {
      //         label: `PCoE_Pro_${this.props.location.state?.editTemplate.templateName}_${this.props.location.state?.editTemplate.geoGraphyName}(v${this.props.location.state?.editTemplate.versionNumber})`,
      //       },
      //     ],
      //   })
      // } else if (this.props.location.state?.templateData){
      //   this.setState({
      //     breadCrumbs: [
      //       {
      //         label: 'Local Procedure Dashboard',
      //         onClick: () => {
      //           this.props.history.goBack();
      //         }
      //       },
      //       {
      //         label: 'View Procedure Document'
      //       },
      //       {
      //         label: `PCoE_Pro_${this.props.location.state?.templateData.templateName}`,
      //       },
      //     ],
      //   })
      // }
      if (this.props.location.state.view === 'EcoView') {
        try {
          this.setState({view: 'EcoView'})
          const templateData = await lptService.getByLPId(this.props.location.state.templateData.lpId)
          this.setState({templateData: templateData?.localProcedure},()=>{
            console.log("templateData",this.state.templateData)
          })
          const filePath = this.props.location.state?.templateData?.ipFilePath!.split('.docx')
          const pdfFilePath = filePath[0] + '.pdf'
          const data = await ProcedureFileService.getPresignedUrl(pdfFilePath, FileType.PDF);
          console.log("url1", data);
          this.setState({ documentUrl: data!, load: false });
        } catch (error) {
          this.setState({ load: false });
          console.error(error)
        }
      }
      // else if (this.props.location.state?.templateData?.ipFilePath) {
      //   try {
      //     const data = await ProcedureFileService.docx2pdf(this.props.location.state?.templateData?.ipFilePath!, FileType.DOCX);
      //     console.log("url2", data);
      //     this.setState({ documentUrl: data! });
      //   } catch (error) {
      //     this.setState({ load: false });
      //     console.error(error)
      //   }
      // }
      else {
        try {
          const data = await ProcedureFileService.docx2pdf(this.props.location.state?.editTemplate?.ipFilePath!, FileType.DOCX);
          console.log("url3", data);
          this.setState({ 
            load: false, 
            documentUrl: data!, 
            // open: true,
            open: this.props.location.state.isUploaded || this.props.location.state.backButtonPressed ? false : true
          });
        } catch (error) {
          this.setState({ load: false });
          console.error(error)
        }
      }
      
    }


  };

  onSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (this.state.isNoSelected || this.props.location.state.isUploaded) {
      const { editTemplate, sections } = this.props.location.state;
      console.log("Sent for approval", editTemplate);

      // // creating a clean doc and storing in s3 so approver can view clean doc
      // const cleanDocPath = await ProcedureFileService.cleandocument(
      //   this.props.location.state?.editTemplate?.ipFilePath!,
      //   FileType.DOCX
      // )

      // ProcedureFileService.docx2pdf(cleanDocPath!, FileType.DOCX)

      const result = await lptService.sendForApproval(editTemplate.lpId!);
      if (result) {
        const env = Utils.getEnvVars();
        const approverEmail = editTemplate.modifyByEmail!
        const emailObj: EmailDTO = {
          to: approverEmail.toLowerCase(),
          cc: editTemplate.modifyByEmail!,
          subject: `ACTION REQUIRED: ${editTemplate.templateName} Procedure Review`,
          body: ` <p><a href="${env.UI_URL}/approver_doc_viewer/${editTemplate.lpId}">Review Draft Procedure</a></p>
                <p>Hi ${editTemplate.approverName},</p>
                <p>
                  ${editTemplate.templateName} procedure, version ${editTemplate.versionNumber}, for ${editTemplate.geoGraphyName} requires your attention.
                  As the approver, you are receiving this message with a link to procedure documents for your review and approval. We request your review within
                  one week of receipt of the approval request.
                </p>
                <p>
                  In order to access the ${editTemplate.templateName}, click on the "Review Draft Procedure” link at the top of this message.
                </p>
                <p>
                  Please contact your Ethics and Compliance liaison ${editTemplate.modifyBy} with any questions regarding this procedure localization or the process. To view all procedures pending your review, you can access your <a href="${env.UI_URL}/approve_procedure">dashboard</a>.
                </p>
                <p>
                  Thank you for your time and diligence in completing review of this procedure.  
                </p>
                <p>
                  Regards,  
                </p>
                <p>
                  Ethics and Compliance Policy Center of Expertise.
                  <br>
                  <b><i>DO NOT RESPOND TO THE SENDER OF THIS MESSAGE</i></b>
                </p>`
          // body: "testing"
        };
        await EmailService.sendEmail(emailObj);
        this.setState({ isSent: true }, () => {
          setTimeout(() => {
            this.props.history.push(`local_procedures`)
          }, 2000)
        }
        )
      }
    } else {
      this.setState({ submitDialog: true })
    }
    // const { editTemplate, sections } = this.props.location.state;
    // const result = await lptService.sendForApproval({
    //   localProcedure: editTemplate,
    //   localProcedureSections: sections,
    //   localProcedureFiles: [],
    // });
    // if(result){
    //   this.setState({ isSent: true }, () => {
    //     setTimeout( () => {
    //   this.props.history.push(`local_procedures`)
    //     },1500) 
    //   }
    // )}

  }

  onDownloadClick = async () => {
    console.log("triigerr", this.props.location);
    const cleanDoc = this.props.location.state.editTemplate.ipFilePath!.split('.docx')
    const cleanDocWordPath = cleanDoc[0] + '_clean.docx'
    const cleanDocPdfPath = cleanDoc[0] + '_clean.pdf'
    if (this.props.location.state.generateSelectedValue === "word") {
      // const filePath = await ProcedureFileService.cleandocument(
      //   this.props.location.state?.editTemplate?.ipFilePath!,
      //   FileType.DOCX
      // )    
      const docFile = await ProcedureFileService.getFile(
        cleanDocWordPath!,
        FileType.DOCX
      )
      fetch(docFile!)
        .then((response) => {
          if (response.ok) {
            this.setState({ isError: false });
            return response.blob();
          }
          throw new Error('Network response was not OK.');
        })
        .then((blob) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `PCoE_Pro_${this.props.location.state.editTemplate.templateName}_EN_${this.props.location.state.editTemplate.geoGraphyName}.docx`;
          document.body.appendChild(link); link.click();
          document.body.removeChild(link);
        }).catch((error) => {
          this.setState({ isError: true });
          console.error('Error:', error);
        });

    } else {
      const filePath = await ProcedureFileService.getPresignedUrl(cleanDocPdfPath, FileType.PDF)
      console.log(filePath);
      fetch(filePath!)
        .then((response) => {
          if (response.ok) {
            this.setState({ isError: false });
            return response.blob();
          }
          throw new Error('Network response was not OK.');
        })
        .then((blob) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `PCoE_Pro_${this.props.location.state.editTemplate.templateName}_EN_${this.props.location.state.editTemplate.geoGraphyName}.pdf`;
          document.body.appendChild(link); link.click();
          document.body.removeChild(link);
        }).catch((error) => {
          this.setState({ isError: true });
          console.error('Error:', error);
        });

    }
  };

  onWordDownloadClick = async () => {
    console.log("triigerr", this.props.location);
    const cleanDoc = this.props.location.state?.templateData?.ipFilePath.split(".docx")
    const cleanDocPath = cleanDoc[0] + "_clean.docx"
    const filePath = await ProcedureFileService.getFile(
      cleanDocPath,
      FileType.DOCX
    )
    fetch(filePath!)
      .then((response) => {
        if (response.ok) {
          // this.setState({isError : false});
          return response.blob();
        }
        throw new Error('Network response was not OK.');
      })
      .then((blob) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `PCoE_Pro_${this.props.location.state?.templateData?.templateName}.docx`;
        document.body.appendChild(link); link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        // this.setState({isError : true});
        console.error('Error:', error);
      });




  };

  onPdfDownloadClick = async () => {
    console.log("triigerr", this.state.templateData);
    let cleanDoc
    let fileName: string
    if(this.props.match.params.id){
       cleanDoc = this.state.templateData.ipFilePath!.split('.docx')
       fileName = `PCoE_Pro_${this.state.templateData.templateName}_EN_${this.state.templateData.geoGraphyName}.pdf`

    } else {
       cleanDoc = this.state.templateData.ipFilePath!.split('.docx')
       fileName = `PCoE_Pro_${this.state.templateData.templateName}_EN_${this.state.templateData.geoGraphyName}.pdf`
    }

    console.log("filename",fileName);
    
    
    const cleanDocPath = cleanDoc[0] + '_clean.pdf'
    const filePath = await ProcedureFileService.getPresignedUrl(cleanDocPath, FileType.PDF)
    console.log(filePath);

    fetch(filePath!)
      .then((response) => {
        if (response.ok) {
          // this.setState({isError : false});
          return response.blob();
        }
        throw new Error('Network response was not OK.');
      })
      .then((blob) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link); link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        // this.setState({isError : true});
        console.error('Error:', error);
      });
  };
}

export default EcoDocumentView;