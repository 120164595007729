import React from "react";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft as LeftArrowIcon } from "@fortawesome/free-solid-svg-icons";
import LinkButton from "./LinkButton";

interface Props {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
interface State { }
class BackLinkButton extends React.Component<Props, State> {
    render() {
        const { onClick } = this.props;

        const linkStyle: any = { color: '#00749E', fontSize: 16, fontWeight: '600' };

        return (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <FontAwesomeIcon icon={LeftArrowIcon} size="sm" style={{ paddingTop: 3 }} />
                </Grid>
                <Grid item>
                    <LinkButton label="Back" style={linkStyle} onClick={onClick} />
                </Grid>
            </Grid>
        );
    }
}

export default BackLinkButton;