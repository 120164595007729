import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import axios from "axios";
import { $getSelection, $isRangeSelection, EditorState, LexicalEditor, createEditor } from "lexical";
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
} from '@lexical/selection';
import { cloneDeep } from "lodash";
import moment from "moment";
import React, { Component, Context } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DocumentType, FileType } from "../../Common/Enums";
import Utils from "../../Common/Utils";
import GenerateButton from "../../Components/Button/GenerateButton";
import RedButton from "../../Components/Button/RedButton";
import WhiteButton from "../../Components/Button/WhiteButton";
import SelectModel from "../../Components/Select/SelectModel";
import PageHeader from "../../Components/Text/PageHeader";
import {
  DocumentConfigModel,
  FileUploadModel,
  GlobalProcedureTemplate,
  GlobalProcedureTemplateFile,
  GlobalProcedureTemplateSection,
} from "../../Models";
import {
  AuthorizationService,
  DocumentService,
  DocxService,
  LPTService,
  ProcedureFileService,
  RTEditorService,
} from "../../Services";
import {
  default as GPTService,
  default as gptService,
} from "../../Services/GPTService";
import FormComponent from "./FormComponent";
import MyCustomSnackbar from "../../Components/SnackBar/MyCustomSnackbar";
import IconButton from "../../Components/Button/IconButton";
import BreadCrumbModel from "../../Components/BreadCrumb/BreadCrumbModel";
import BackLinkButton from "../../Components/Button/BackLinkButton";
import UnauthorizedRoute from "../../Components/Route/UnauthorizedRoute";
import { RoleContext } from "../../Contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Constants from "../../Common/Constants";
import '../../Assets/rthtml.css';

const editorStateEmptyJSONString =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
const editorStateContentJSONString =
  '{ "root": { "children": [{ "children": [{ "detail": 0, "format": 0, "mode": "normal", "style": "", "text": "abdc", "type": "text", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "paragraph", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "root", "version": 1 } }';
const editor = createEditor();
const useEmpty = true;
let editorStateJSONString = editorStateEmptyJSONString;
if (!useEmpty) {
  editorStateJSONString = editorStateContentJSONString;
}
interface IGPTInterfaceDTO {
  globalProcedureTemplate: GlobalProcedureTemplate;
  globalProcedureTemplateFiles: GlobalProcedureTemplateFile[];
  globalProcedureTemplateSections: GlobalProcedureTemplateSection[];
}

type ScreenMode = "view" | "edit";

interface Props extends RouteComponentProps<any, any, any> {

}

interface State {
  confirmCreateFormSubmitFlag: boolean;
  sections: GlobalProcedureTemplateSection[];
  editTemplate: GlobalProcedureTemplate;
  policySubCommApprValidation:boolean;
  gpId?: number;
  mode: ScreenMode;
  editorState: EditorState;
  afterUpdateButtons: boolean;
  imagePreview: string | null;
  templateUrl: string;
  openSuceessMessage: boolean;
  openSuceessMessageUpdate: boolean,
  templateBlob: Blob;
  pdfTemplateUrl: string;
  editableContentOfBasicPrinciples: any;
  editableContentOfBodyText: any;
  editableYellowTextsOfBasicPrinciples: any;
  editableYellowTextsOfBodyText: any;
  templateData: any;
  headerFile: string;
  footerFile: string;
  disableCreateButton: boolean;
  headerFileFromDB: FileUploadModel;
  footerFileFromDB: FileUploadModel;
  isLoading: boolean;
  headerImageValidation: boolean;
  footerImageValidation: boolean;
  createTemplateValidation: boolean;
  templateNameValidation: boolean;
  versionNumberValidation: boolean;
  versionNumberPreviousValidation: boolean;
  effectiveDateValidation: boolean;
  procedureTypeValidation: boolean;
  ownerValidation: boolean;
  ownerNameValidation: boolean;
  throwErr:boolean;
  errMsg:string;
  approverValidation: boolean;
  approverNameValidation: boolean;
  footerValidation: boolean;
  basicPrincValidation: boolean;
  headerValidation: boolean;
  bodyTextValidation: boolean;
  breadCrumbs: BreadCrumbModel[];
  load: boolean;
  draftSuccess: boolean;
  approverNames: any[];
  ownerNames: any[];
  approverSelectedItems: {};
  ownerSelectedItems: {};
  previousVersionNumber: string;
  ApproverNameLoading: boolean;
  confirmDeleteSection: boolean;
  removeSectionId: number;

}

class ViewTemplateData extends Component<Props, State> {
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;

  constructor(props: Props) {
    super(props);
    const editorState = editor.parseEditorState(editorStateJSONString);
    this.state = {
      confirmCreateFormSubmitFlag:false,
      confirmDeleteSection: false,
      removeSectionId: 0,
      previousVersionNumber: "",
      sections: [],
      templateBlob: new Blob(),
      headerFileFromDB: {} as FileUploadModel,
      footerFileFromDB: {} as FileUploadModel,
      footerFile: "",
      headerFile: "",
      openSuceessMessage: false,
      openSuceessMessageUpdate: false,
      policySubCommApprValidation:false,
      editorState: editorState,
      isLoading: true,
      draftSuccess: false,
      approverNames: [],
      approverSelectedItems: {},
      ownerNames: [],
      ownerSelectedItems: {},
      ApproverNameLoading: false,
      errMsg: "",
      throwErr: false,
      templateData: {
        templateName: "",
        basicPrinciples: "",
        bodyText: "",
        approver: "",
        approverName: "",
        approverEmail: "",
        effectiveDate: null,
        footer: "",
        footerImageFile: "",
        header: "",
        headerImageFile: "",
        owner: "",
        ownerName: "",
        ownerEmail: "",
        procedureType: "",
        versionNumber: "",
        gpUniqueId: null,
        policySubCommAppr:null,
        _basicPrinciples: Utils.getEditorState(),
        _bodyText: Utils.getEditorState(),
        _headerImageFile: {} as FileUploadModel,
        _footerImageFile: {} as FileUploadModel,
        _gpTemplateFile: {} as FileUploadModel,
        _header: Utils.getEditorState(),

      },
      disableCreateButton: true,
      editTemplate: {
        templateName: "",
        basicPrinciples: "",
        bodyText: "",
        approver: "",
        approverName: "",
        approverEmail: "",
        effectiveDate: null,
        footer: "",
        footerImageFile: "",
        header: "",
        headerImageFile: "",
        owner: "",
        ownerName: "",
        ownerEmail: "",
        procedureType: "",
        versionNumber: "",
        _basicPrinciples: Utils.getEditorState(),
        _bodyText: Utils.getEditorState(),
        _headerImageFile: {} as FileUploadModel,
        _footerImageFile: {} as FileUploadModel,
        _gpTemplateFile: {} as FileUploadModel,
        _header: Utils.getEditorState(),
        policySubCommAppr:null,
      },
      mode: "view",
      afterUpdateButtons: false,
      imagePreview: null,
      templateUrl: "",
      pdfTemplateUrl: "",
      editableContentOfBasicPrinciples: [],
      editableYellowTextsOfBasicPrinciples: [],
      editableContentOfBodyText: [],
      editableYellowTextsOfBodyText: [],
      headerImageValidation: false,
      footerImageValidation: false,
      createTemplateValidation: false,
      approverValidation: false,
      approverNameValidation: false,
      basicPrincValidation: false,
      effectiveDateValidation: false,
      footerValidation: false,
      ownerValidation: false,
      ownerNameValidation: false,
      procedureTypeValidation: false,
      templateNameValidation: false,
      versionNumberValidation: false,
      versionNumberPreviousValidation: false,
      headerValidation: false,
      bodyTextValidation: false,
      breadCrumbs: [
        {
          label: 'Procedure Template',
          onClick: () => {
            this.props.history.push('/procedure_templates');
          }
        },
        {
          label: 'Generate Procedure Template'
        }
      ],
      load: false
    };
  }

  componentDidMount(): void {
    const searchParams = new URLSearchParams(this.props.location.search);
    try {
      this.setState({
        gpId: Number(this.props.match.params.id),
        mode: searchParams.get("mode") as ScreenMode,
        openSuceessMessage: this.props?.location?.state?.msg,
       draftSuccess:this.props?.location?.state?.draftmsg
      });
     
    } catch (error) {
      console.error("err", error)
    }
  }
  async componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    _snapshot?: any
  ): Promise<void> {
    const { mode } = this.state;
    let loadData = true;
    const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

    if (isReadonlyUser && mode === 'edit') {
      loadData = false;
    }

    if (loadData) {
      if (prevState.gpId !== this.state.gpId) {
        await this.getTemplateDateById();
      }
      if (this.state.mode === 'edit') {

        const dataUpdate = await this.handleUrltoFile(this.state.editTemplate.headerImageFile);
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(dataUpdate);
        const inputField: any = document.getElementById("headerImageFile");
        if(inputField)
        {
          inputField.files = dataTransfer?.files
        }
       
        // console.log("file after update", inputField.files);

        const footerDataUpdate = await this.handleUrltoFile(this.state.editTemplate.footerImageFile);
        const dataTransferforFooter = new DataTransfer();
        dataTransferforFooter.items.add(footerDataUpdate);
        const inputFieldForFooter: any = document.getElementById("footerImageFile");
        if(inputFieldForFooter)
        {
          // inputField.files = dataTransfer?.files
          inputFieldForFooter.files = dataTransferforFooter?.files
        }

        // inputFieldForFooter.files = dataTransferforFooter?.files
        // console.log("file after update of footer", inputFieldForFooter.files);
      }
    }
  }

  validationsForSections = () => {
    const { sections } = this.state;
    let final = true;
    let emptyFields = [];
  
    for (let ind = 0; ind < sections.length; ind++) {
      const el = sections[ind];
      if (Utils.isEditorStateEmpty(el._bodyText) === true) {
        final = false;
        emptyFields.push(`Section ${ind + 1} Body Text`);
      }
      if (Utils.isEditorStateEmpty(el._header!) === true) {
        final = false;
        emptyFields.push(`Section ${ind + 1} Header`);
      }
    }
  
    if (!final) {
      const errMsg = `Please enter mandatory data for ${emptyFields.join(", ")} or remove sections.`;
      this.setState({ errMsg, throwErr: true });
    }
  
    return final;
  };
  

  getTemplateDateById = async () => {
    try {
      console.log("calling by ID")
      this.setState({ load: true })
      const data = await gptService.getByGPId(this.state.gpId!);
       //get the previous version number;
      if (data && data?.globalProcedureTemplate?.versionNumber !== null) {
        this.setState({ previousVersionNumber: data.globalProcedureTemplate.versionNumber });
      }
      if (data?.globalProcedureTemplate?.approverName !== null) {
        this.setState({
          approverSelectedItems: {
            text: data?.globalProcedureTemplate?.approverName,
            value: data?.globalProcedureTemplate?.approverName,
            email: data?.globalProcedureTemplate?.approverEmail,
          },
        });
      } else {
        this.setState({
          approverSelectedItems: { text: "", value: "", email: "" },
        });
      }
      if (data?.globalProcedureTemplate?.ownerName !== null) {
        this.setState({
          ownerSelectedItems: {
            text: data?.globalProcedureTemplate?.ownerName,
            value: data?.globalProcedureTemplate?.ownerName,
            email: data?.globalProcedureTemplate?.ownerEmail,
          },
        });
      } else {
        this.setState({
          ownerSelectedItems: { text: "", value: "", email: "" },
        });
      }

      if (data?.globalProcedureTemplate) {
        //set the currentVersionNumber
        // if (this.state.mode === 'edit' && data.globalProcedureTemplate.status !== 'Drafted') {
        //   // this.setState({previousVersionNumber:data?.globalProcedureTemplate?.versionNumber});
        //   data.globalProcedureTemplate.versionNumber = String(parseFloat(data.globalProcedureTemplate.versionNumber) + 1)
        // }
        this.setState(
          {
            editTemplate: data.globalProcedureTemplate,
            sections: data.globalProcedureTemplateSections.sort((a, b) => (a.sectionId ?? 0) - (b.sectionId ?? 0)),
            templateData: data.globalProcedureTemplate,
          },
          async () => {
            try {
              const dataDeepCopy = cloneDeep(data.globalProcedureTemplate);
              const _bodyText = Utils.getEditorState(dataDeepCopy.bodyText);
              const _basicPrinciples = Utils.getEditorState(
                dataDeepCopy.basicPrinciples
              );
              const _header = Utils.getEditorState(
                dataDeepCopy.header
              );
              console.log("editor conversion", _bodyText)
              let copiedSections: GlobalProcedureTemplateSection[] = [];
              data.globalProcedureTemplateSections.forEach((el) => {
                let sectionData = el.bodyText!;
                let sectionHeader = el.header
                let editorConversion = Utils.getEditorState(sectionData);
                let headerConversion = Utils.getEditorState(sectionHeader);
                copiedSections.push({
                  ...el,
                  _bodyText: editorConversion,
                  _header: headerConversion
                })
              });
              this.setState({ sections: copiedSections })
              const bodyText = await Utils.getHTML(
                data.globalProcedureTemplate.bodyText, true
              );
              const basicPrinciples = await Utils.getHTML(
                data.globalProcedureTemplate.basicPrinciples, true
              );
              
              const header = await Utils.getHTML(
                data.globalProcedureTemplate.header, true
              );
              let headerFile: any | null = null;
              let footerFile: any | null = null;
              if (data!.globalProcedureTemplate.headerImageFile!) {
                headerFile = await ProcedureFileService.getFile(
                  data!.globalProcedureTemplate.headerImageFile!,
                  FileType.DOCX
                );
                const headerFileData = await this.handleUrltoFile(headerFile!);
                const fileReader = new FileReader();
                fileReader.readAsDataURL(headerFileData);
                const fileModel = {} as FileUploadModel;
                fileReader.onload = async () => {
                  const { name, size, type } = headerFileData;
                  fileModel.name = name;
                  fileModel.sizeInKB = Math.round(size / 1024);
                  fileModel.sizeInMB = Math.round(size / 1024 / 1024);
                  fileModel.type = type as FileType;
                  fileModel.ext = type.replace(/(.*)\//g, "");
                  fileModel.file = headerFileData;
                  fileModel.fileversion = data.globalProcedureTemplate.versionNumber;
                  this.setState({
                    editTemplate: { ...this.state.editTemplate, _headerImageFile: fileModel }
                  });
                };
                fileReader.onloadend = () => {
                  this.setState({ imagePreview: fileReader.result as string });
                };
              }
              if (data!.globalProcedureTemplate.footerImageFile!) {
              footerFile = await ProcedureFileService.getFile(
                  data!.globalProcedureTemplate.footerImageFile!,
                  FileType.DOCX
                );
                const footerFileData = await this.handleUrltoFile(footerFile!);
                const fileReaderFooter = new FileReader();
                fileReaderFooter.readAsDataURL(footerFileData);
                const fileModelFooter = {} as FileUploadModel;
                fileReaderFooter.onload = async () => {
                  const { name, size, type } = footerFileData;
                  fileModelFooter.name = name;
                  fileModelFooter.sizeInKB = Math.round(size / 1024);
                  fileModelFooter.sizeInMB = Math.round(size / 1024 / 1024);
                  fileModelFooter.type = type as FileType;
                  fileModelFooter.ext = type.replace(/(.*)\//g, "");
                  fileModelFooter.file = footerFileData;
                  fileModelFooter.fileversion = this.state.editTemplate.versionNumber;
                  this.setState({
                    editTemplate: { ...this.state.editTemplate, _footerImageFile: fileModelFooter },
                  });
                };
                fileReaderFooter.onloadend = () => {
                  this.setState({ imagePreview: fileReaderFooter.result as string });
                };
              }
              console.log("editor conversion till editTemplate", _bodyText)
              this.setState({
                editTemplate: {
                  ...this.state.editTemplate,
                  _bodyText: _bodyText,
                  _basicPrinciples: _basicPrinciples,
                  _header: _header

                },
                headerFile: headerFile!,
                footerFile: footerFile!,
                load: false
              }, () => {
                console.log("headerFile==", this.state.headerFile ,"footerFile==",this.state.footerFile);
                const bodyEl = document.getElementById("bodyText");
                const headerEl = document.getElementById("header");

                const basicPrinciplesEl =
                  document.getElementById("basicPrinciples");
                if (bodyEl) {
                  bodyEl.innerHTML = bodyText;
                }
                if (headerEl) {
                  headerEl.innerHTML = header;
                }
                if (basicPrinciplesEl) {
                  basicPrinciplesEl.innerHTML = basicPrinciples;
                }
                this.state.sections.forEach((el, ind) => {
                  Utils.getHTML(el.bodyText, true).then((res) => {
                    const secEl = document.getElementById(`section-${ind}`);

                    if (secEl) {
                      secEl.innerHTML = res;
                    }
                  });
                  Utils.getHTML(el.header, true).then((res) => {
                    const headerSecEl = document.getElementById(`sectionHeader-${ind}`);
                    if (headerSecEl) {
                      headerSecEl.innerHTML = res
                    }
                  });
                });
               
              });




            } catch (error) {
              console.log("err", error);
              this.setState({ load: false});

            }
          }
        );

      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message:", error.message);
        this.setState({ load: false});
        return error.message;
      } else {
        this.setState({ load: false});
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }
    }
  };

  validationForSaveAsDraft = () => {
    const { editTemplate } = this.state;
    let final: boolean = true
    if (!editTemplate.templateName) {
      final = false
      this.setState({ templateNameValidation: true });
    }
    
    return final
  }

  handlePreviewCancelClickHeader = () => {
    this.setState({ editTemplate: { ...this.state.editTemplate, _headerImageFile: {} as FileUploadModel } })
  }
  handlePreviewCancelClickFooter = () => {
    this.setState({ editTemplate: { ...this.state.editTemplate, _footerImageFile: {} as FileUploadModel } })
  }
  handleVersionNumberChange = (template: GlobalProcedureTemplate) => {
    this.setState({
      editTemplate: {...this.state.editTemplate, versionNumber: template.versionNumber}
    });
  
  };

  handleSaveAsDraftSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const { editTemplate, sections } = this.state;
    if (this.validationForSaveAsDraft()) {
      this.setState({ load: true });
     
      editTemplate.basicPrinciples = Utils.appendRules(
        editTemplate._basicPrinciples.toJSON()
      );
      editTemplate.bodyText = Utils.appendRules(
        editTemplate._bodyText.toJSON()
      );
      editTemplate.header = Utils.appendRules(
        editTemplate._header.toJSON()
      );
      let copiedSections: GlobalProcedureTemplateSection[] = [];
      sections.forEach((el) => {
        let sectionBodyText = el._bodyText;
        let sectionHeader = el._header;
        const bodyText = Utils.appendRules(sectionBodyText.toJSON());
        const header = Utils.appendRules(sectionHeader?.toJSON())
        copiedSections.push({ ...el, bodyText: bodyText, header: header });
      });
      this.setState({ sections: copiedSections });

      if (editTemplate.gpUniqueId === null) {
        console.log("Condition found", editTemplate.gpId);
        editTemplate.gpUniqueId = editTemplate.gpId
      }
      let gptresult: IGPTInterfaceDTO | null;
      if (editTemplate.status === "Submitted") {
        console.log("SUbmitCase")
        // let omittedId = copiedSections.map(el => {
        //   return omit(el, ["gpId", "gpsId"])
        // });
        gptresult = await GPTService.saveAsDraft({
          globalProcedureTemplate:editTemplate,
            // omit(editTemplate, ["gpId"]),
          globalProcedureTemplateSections: copiedSections,
          globalProcedureTemplateFiles: [],
        });
      }
      else {
        console.log("Draft Case")
        gptresult = await GPTService.saveAsDraft({
          globalProcedureTemplate:
            editTemplate,
          globalProcedureTemplateSections: copiedSections,
          globalProcedureTemplateFiles: [],
        });
      }
      console.log("gpt", gptresult);
      const gpId = gptresult!.globalProcedureTemplate!.gpId;
      if (gpId && (editTemplate._headerImageFile?.file || editTemplate._footerImageFile?.file)) {
        if(editTemplate._headerImageFile?.file)
        {
          editTemplate._headerImageFile.gpId = gpId;
        }
        if(editTemplate._footerImageFile?.file)
        {
          editTemplate._footerImageFile.gpId = gpId;
        }
       
        if(editTemplate._headerImageFile?.file)
        {
          editTemplate._headerImageFile.gpId = gpId;
        }

        let s3FileKeyForHeader = "";
        let s3FileKeyForFooter = "";
        if(editTemplate._headerImageFile?.file)
        {
           s3FileKeyForHeader = `globalproceduretemplate/${gpId}/${DocumentType.GPT_HEADER}/${editTemplate.versionNumber}/${editTemplate._headerImageFile?.name}`;
           editTemplate._headerImageFile.s3filekey = s3FileKeyForHeader;
        }

        if(editTemplate._footerImageFile?.file)
        {
          s3FileKeyForFooter = `globalproceduretemplate/${gpId}/${DocumentType.GPT_FOOTER}/${editTemplate.versionNumber}/${editTemplate._headerImageFile?.name}`;
          editTemplate._footerImageFile.s3filekey = s3FileKeyForFooter;
        }
        
        if(editTemplate._headerImageFile?.file)
        {
        await ProcedureFileService.uploadHeaderLogo(editTemplate._headerImageFile)
        }
        if(editTemplate._footerImageFile?.file)
        {
        await ProcedureFileService.uploadFooterLogo(editTemplate._footerImageFile)
        }
        const blobData = await this.handleGenerate();
        this.setState({ templateBlob: blobData });
        const fileName = editTemplate.templateName.replace(/\s/g, "_")
        const file = new File([blobData], `${fileName}_v${editTemplate.versionNumber}.docx`, {
          type: blobData.type,
        });
        const documents3FileKey = `globalproceduretemplate/${gpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate.versionNumber}/${file.name}`;
        editTemplate._gpTemplateFile = {} as FileUploadModel;
        editTemplate._gpTemplateFile.s3filekey = documents3FileKey;
        editTemplate._gpTemplateFile.file = file;
        editTemplate._gpTemplateFile.name = editTemplate.templateName;
        editTemplate._gpTemplateFile.fileversion = editTemplate.versionNumber;
        editTemplate._gpTemplateFile.type = file.type as FileType;
        editTemplate._gpTemplateFile.ext = "docx";
        editTemplate._gpTemplateFile.gpId = gpId!;
        editTemplate._gpTemplateFile.sizeInKB = Math.round(file.size / 1024);
        editTemplate._gpTemplateFile.sizeInMB = Math.round(
          file.size / 1024 / 1024
        );
        const fileupload = await ProcedureFileService.uploadDocument(
          editTemplate._gpTemplateFile
        );
        console.log("fileupload", fileupload);
        await GPTService.updateFilesPath({ headerImageFile: s3FileKeyForHeader, footerImageFile: s3FileKeyForFooter, gpTemplateFile: documents3FileKey, gpId })
      };
      // this.setState({ draftSuccess: true });
      // setTimeout(() => {
      //   this.props.history.push(`/view_template/${gpId}?mode=view`, { from: `/view_template/${gpId}?mode=edit` });
      //   // this.props.history.go(0);
      // }, 2000)

      this.props.history.push(`/view_template/${gpId}?mode=view`, { from: `/view_template/${gpId}?mode=edit` })
        // this.props.history.go(0)

        this.setState(
          {
            mode: "view",
            afterUpdateButtons: true,
            gpId: gpId,
            draftSuccess: true
          },
          async () => {
            await this.getTemplateDateById();
          }
        );


    }
  };

  validationChecker = (): boolean => {
    const { editTemplate } = this.state;
    let final: boolean = true
    let emptyFields = [];
    if (!editTemplate.templateName) {
      final = false
      emptyFields.push("Template Name")

    }
     if (Object.keys(editTemplate._headerImageFile).length === 0) {
      final = false
      emptyFields.push("Header Image")

    }
    if (Object.keys(editTemplate._footerImageFile).length === 0) {
      final = false
      emptyFields.push("Footer Image")
      
    }
     if (!editTemplate.procedureType) {
      final = false
      emptyFields.push("Procedure Type")

    }
   
     if (!editTemplate.versionNumber) {
      final = false
      emptyFields.push("Version Number")

    }
    
     if (!editTemplate.effectiveDate) {
      final = false
      emptyFields.push("Effective Date")

    }
     if (!editTemplate.policySubCommAppr) {
      final = false
      emptyFields.push("Policy Sub Committee Approval")
 
    }
     if (!editTemplate.owner && editTemplate.procedureType === "Local Procedure") {
      final = false
      emptyFields.push("Owner")

    }
     if (!editTemplate.approver && editTemplate.procedureType === "Local Procedure") {
      final = false
      emptyFields.push("Approver")

    }
   
     if (!editTemplate.footer) {
      final = false
      emptyFields.push("Footer")

    }
    if (Utils.isEditorStateEmpty(editTemplate._basicPrinciples) === true) {
      final = false
      emptyFields.push("Basic Principles")

    }
    
     if (Utils.isEditorStateEmpty(editTemplate._header) === true) {
      final = false
      emptyFields.push("Header")
    }
     if (Utils.isEditorStateEmpty(editTemplate._bodyText) === true) {
      final = false
      emptyFields.push("Body Text")
    }
    const errMsg = `Please enter mandatory data for ${emptyFields.join(", ")}.`
    this.setState({errMsg,throwErr:!final });
    return final
  }

   handleCreateFormSubmitConfirm = async () => {
    this.setState({ confirmCreateFormSubmitFlag: true });
  }

  handleCreateFormSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    this.setState({ confirmCreateFormSubmitFlag: false });
    const { editTemplate, sections } = this.state;
    if (this.validationChecker() && this.validationsForSections()) {
      this.setState({ load: true });
      editTemplate.bodyText = Utils.appendRules(editTemplate._bodyText.toJSON());
      editTemplate.header = Utils.appendRules(editTemplate._header.toJSON());
      editTemplate.basicPrinciples = Utils.appendRules(
        editTemplate._basicPrinciples.toJSON()
      );
      
      let copiedSections: GlobalProcedureTemplateSection[] = [];
      sections.forEach((el) => {
        let sectionBodyText = el._bodyText;
        let sectionHeader = el._header;
        const bodyText = Utils.appendRules(sectionBodyText.toJSON());
        const header = Utils.appendRules(sectionHeader!.toJSON());
        copiedSections.push({ ...el, bodyText: bodyText, header: header });
      });
      if (editTemplate.gpUniqueId === null && editTemplate.status !== 'Drafted') {
        console.log("Condition found", editTemplate.gpId);
        editTemplate.gpUniqueId = editTemplate.gpId
      }
      const gptresult = await GPTService.update({
        globalProcedureTemplate: {
          ...editTemplate,
        },
        globalProcedureTemplateSections: copiedSections,
        globalProcedureTemplateFiles: [],
      });
      const gpId = gptresult?.globalProcedureTemplate.gpId;
      console.log("versionNumber", editTemplate.versionNumber);
      const blobData = await this.handleGenerate();
      const s3FileKey = `globalproceduretemplate/${gpId}/${DocumentType.GPT_HEADER}/${gptresult?.globalProcedureTemplate.versionNumber}/${editTemplate._headerImageFile.name}`;
      const s3FileKeyForFooter = `globalproceduretemplate/${gpId}/${DocumentType.GPT_FOOTER}/${gptresult?.globalProcedureTemplate.versionNumber}/${editTemplate._footerImageFile.name}`;
      editTemplate._headerImageFile.s3filekey = s3FileKey;
      editTemplate._footerImageFile.s3filekey = s3FileKeyForFooter;
      this.setState({
        editTemplate: {
          ...editTemplate,
          headerImageFile: s3FileKey,
          footerImageFile: s3FileKeyForFooter
        },
        sections: copiedSections,
        templateBlob: blobData
      })
      await ProcedureFileService.uploadHeaderLogo(editTemplate._headerImageFile);
      await ProcedureFileService.uploadFooterLogo(editTemplate._footerImageFile);

      const fileName = editTemplate.templateName.replace(/\s/g, "_")
      const file = new File([blobData], `${fileName}_v${editTemplate.versionNumber}.docx`, {
        type: blobData.type,
      });
      console.log("file of document", file, editTemplate.versionNumber);
      const documents3FileKey = `globalproceduretemplate/${gpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate.versionNumber}/${file.name}`;
      const updateFile = await GPTService.updateFilesPath({
        headerImageFile: s3FileKey,
        footerImageFile: s3FileKeyForFooter,
        gpTemplateFile: documents3FileKey,
        gpId: gpId!,
      });
      console.log("updated", updateFile)
      editTemplate._gpTemplateFile = {} as FileUploadModel;
      editTemplate._gpTemplateFile.s3filekey = documents3FileKey;
      editTemplate._gpTemplateFile.file = file;
      editTemplate._gpTemplateFile.name = editTemplate.templateName;
      editTemplate._gpTemplateFile.fileversion = editTemplate.versionNumber;
      editTemplate._gpTemplateFile.type = file.type as FileType;
      editTemplate._gpTemplateFile.ext = "docx";
      editTemplate._gpTemplateFile.gpId = gpId!;
      editTemplate._gpTemplateFile.sizeInKB = Math.round(file.size / 1024);
      editTemplate._gpTemplateFile.sizeInMB = Math.round(
        file.size / 1024 / 1024
      );
      await ProcedureFileService.uploadDocument(editTemplate._gpTemplateFile);

      // this.setState({
      //   mode: "view",
      //   afterUpdateButtons: true,
      //   gpId: gpId,
      //   openSuceessMessage: true
      // });
      // setTimeout(() => {
      //   this.props.history.push(`/view_template/${gpId}?mode=view`, { from: `/view_template/${gpId}?mode=edit` });
      //   this.props.history.go(0);
      // }, 2000);
      // this.setState({
      //     mode: "view",
      //     afterUpdateButtons: true,
      //     gpId: gpId,
      //     openSuceessMessage: true
      //   },async ()=>{
      //       // await this.getTemplateDateById()
      //       this.props.history.push(`/view_template/${gpId}?mode=view`, { from: `/view_template/${gpId}?mode=edit` });
          
      //     // this.setState({openSuceessMessage: true})
      //     });
          // setTimeout(() => {
          //   this.setState({ openSuceessMessage: true });
          // }, 2000);
          this.props.history.push(`/view_template/${gpId}?mode=view`, { from: `/view_template/${gpId}?mode=edit` })
          // this.props.history.go(0)
  
          this.setState(
            {
              mode: "view",
              afterUpdateButtons: true,
              gpId: gpId,
              
            },
            async () => {
              if(this.state.previousVersionNumber === editTemplate.versionNumber){
                this.setState({ openSuceessMessageUpdate: true })
                }else{
                  this.setState({ openSuceessMessage: true })
                }
              await this.getTemplateDateById();
            }
          );
    }
  };

  handleUrltoFile = async (url: string) => {
    const extension = this.state.editTemplate.headerImageFile.substring(this.state.editTemplate.headerImageFile.lastIndexOf(".") + 1)
    let blob = await fetch(url).then(r => r.blob());
    let file = new File([blob], this.state.editTemplate.headerImageFile.split('/').pop()!, { type: extension });
    console.log("file og", file)
    return file
  }

  handleBodyTextEditorChange = (_bodyText: EditorState) => {
    this.setState({
      editTemplate: { ...this.state.editTemplate, _bodyText: _bodyText },
    });
  };

  handleBasicPrinciplesChange = (_basicPrinciples: EditorState) => {
    this.setState({
      editTemplate: {
        ...this.state.editTemplate,
        _basicPrinciples: _basicPrinciples,
      },
    });
  };

  handleTemplateFormChange = (template: GlobalProcedureTemplate) => {
    this.setState({
      editTemplate: template,
    });
  };

  onDropDownChange = (selected: SelectModel) => {
    const { editTemplate } = this.state;
    const value = selected.value;
    this.setState({
      editTemplate: { ...editTemplate, procedureType: value },
    });
  };

  handleEffectiveDateChange = (value: Date | null) => {
    this.setState({
      editTemplate: { ...this.state.editTemplate, effectiveDate: value },
    });
  };

  handleSectionBodyTextChange = (_bodyText: EditorState, index: number) => {
    const { sections } = this.state;
    const updatedSections = sections.map((section, i) => {
      if (i === index) {
        return { ...section, _bodyText: _bodyText };
      }
      return section;
    });
    console.log("updatedSect", updatedSections);
    this.setState({ sections: updatedSections });
  };

  addFormFields = () => {
    const { sections } = this.state;
    let maxSectionId = 0;

    if (sections.length > 0) {
      const sectionIds = sections.map(p => p.sectionId as number);
      maxSectionId = Math.max(...sectionIds);
    }

    const sectionsData: GlobalProcedureTemplateSection = {
      _bodyText: Utils.getEditorState(),
      _header: Utils.getEditorState(),
      sectionId: maxSectionId + 1,
    };
    this.setState((prevState) => ({
      sections: [...prevState.sections, sectionsData],
    }));
  };

  removeFormFields = (sectionId: number) => {
    this.setState({ removeSectionId: sectionId, confirmDeleteSection: true, });
    // this.setState({
    //   sections: this.state.sections.filter((_el) => _el.sectionId !== sectionId),
    // });
  };

  handleHeaderImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("header Image onCha", event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      let { editTemplate } = this.state;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      const fileModel = {} as FileUploadModel;

      fileReader.onload = async (_e) => {
        const { name, size, type } = file;
        fileModel.name = name;
        fileModel.sizeInKB = Math.round(size / 1024);
        fileModel.sizeInMB = Math.round(size / 1024 / 1024);
        fileModel.type = type as FileType;
        fileModel.ext = type.replace(/(.*)\//g, "");
        fileModel.file = file;
        fileModel.fileversion = editTemplate.versionNumber;
        this.setState({
          editTemplate: { ...editTemplate, _headerImageFile: fileModel },
        });
      };
    }
  };

  handleFooterImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log("footerfile", file);
      let { editTemplate } = this.state;
      const fileReader = new FileReader();
      const fileModel = {} as FileUploadModel;
      fileReader.readAsDataURL(file);
      fileReader.onload = (_e) => {
        const { name, size, type } = file;
        fileModel.name = name;
        fileModel.sizeInKB = Math.round(size / 1024);
        fileModel.sizeInMB = Math.round(size / 1024 / 1024);
        fileModel.type = type as FileType;
        fileModel.ext = type.replace(/(.*)\//g, "");
        fileModel.file = file;
        fileModel.fileversion = editTemplate.versionNumber;
        this.setState({
          editTemplate: { ...editTemplate, _footerImageFile: fileModel },
        });
      };
    }
  };
  handleSectionHeaderChange = (_header: EditorState,
    index: number, editor?: LexicalEditor) => {
    editor?.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const fontFamilyStyle = 'font-family';
        const fontSizeStyle = 'font-size';
        const ffSelectionStyle = $getSelectionStyleValueForProperty(selection, fontFamilyStyle);

        if (!ffSelectionStyle) {
          $patchStyleText(selection, {
            [fontFamilyStyle]: Constants.DocFontFamilyBold,
            [fontSizeStyle]: Constants.DefaultFontSize,
          });
        }
      }

      const { sections } = this.state;
      const updatedSections = sections.map((section, i) => {
        if (i === index) {
          return { ...section, _header: _header };
        }
        return section;
      });

      this.setState({ sections: updatedSections });
    });
  }

  FieldsValidationaction = (<></>);



  action = (
    <>
      <Button
        size="small"
        style={{ textDecoration: "underline", color: "#0075A2" }}
        onClick={() => {
          this.props.history.push('/procedure_templates');
          this.props.history.go(0);
        }}
      >
        VIEW
      </Button>
      {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ openSuceessMessage: false, draftSuccess: false });
        }}
      />
    </>
  );

  setApproverInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ ApproverNameLoading: true })
    } else {
      this.setState({ ApproverNameLoading: false })
    }

    if (inputValue.length > 3 && isData === undefined) {
      let response = await LPTService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          return {
            text: `${el.FirstName} ${el.LastName} (${el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, ApproverNameLoading: false });
      }
    }
  };

  onApproverNameSelect = (event: any) => {
    this.setState({ ApproverNameLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          approverName: event.value,
          approverEmail: event.email,
        },
        approverSelectedItems: event,
      });
      this.setState({ approverSelectedItems: event });
    }
  };

  onOwnerNameSelect = (event: any) => {
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          ownerName: event.value,
          ownerEmail: event.email,
        },
        ownerSelectedItems: event,
      });
      this.setState({ ownerSelectedItems: event });
    }
  };

  setOwnerInputValue = async (inputValue: any) => {
    this.setState({ ownerNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.ownerNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue.length > 3 && isData === undefined) {
      let response = await LPTService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const ownerValues: SelectModel[] = response.map((el) => {
          return {
            text: `${el.FirstName} ${el.LastName} (${el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ ownerNames: ownerValues });
      }
    }
  };
  handlePolicyCommApprDateChange = (value: Date | null) => {
    this.setState({
      editTemplate: { ...this.state.editTemplate, policySubCommAppr: value },
    });
  };

  render() {
    const { breadCrumbs, load, mode } = this.state;
    const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

    if (isReadonlyUser) {
      if (!(mode && mode.toLocaleLowerCase() === 'view')) {
        return <UnauthorizedRoute />;
      }
    }

    console.log("Data By ID", this.state.editTemplate, this.state.sections);
    const headerFileName = this.state.editTemplate.headerImageFile.split('/').pop();
    const footerFileName = this.state.editTemplate.footerImageFile.split('/').pop();
    if (load) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }


    return (
      <>
        <MyCustomSnackbar
          message="Procedure Template has been created successfully."
          severity="success"
          actions={<></>}
          open={this.state.openSuceessMessage}
          onClose={() => {
            this.setState({ openSuceessMessage: false });
          }}
        />
         <MyCustomSnackbar
          message="Procedure Template has been updated successfully."
          severity="success"
          actions={<></>}
          open={this.state.openSuceessMessageUpdate}
          onClose={() => {
            this.setState({ openSuceessMessageUpdate: false });
          }}
        />
        <MyCustomSnackbar
          message="Procedure Template has been updated successfully."
          severity="success"
          actions={<></>}
          open={this.state.openSuceessMessageUpdate}
          onClose={() => {
            this.setState({ openSuceessMessageUpdate: false });
          }}
        />
        <MyCustomSnackbar
          message="Procedure Template has been drafted successfully."
          severity="success"
          actions={<></>}
          open={this.state.draftSuccess}
          onClose={() => {
            this.setState({ draftSuccess: false });
          }}
        />
        {this.state.mode === "view" ? (
          <Box >
            {!load && <> 
            {/* <Grid marginBottom={"2em"} container direction="row">
              <BreadCrumb breadCrumbs={breadCrumbs} />
            </Grid> */}
            </>}
            {/* <Box style={{ display: "flex", flexDirection: "row" }}> */}
              {!load ? (
                <>
                  <Grid
                    container
                    direction={"row"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1em",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xs={8}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item>
                          <BackLinkButton
                            onClick={() => {
                              //window.location.assign(`/view_template/${this.state.gpId}?mode=edit`);
                              const { state } = this.props.location;                        
                              if (state && state.from) {
                                window.location.assign(state.from);
                              } else {
                                this.props.history.push("/procedure_templates");
                              }
                            }}
                          />
                        </Grid>
                        <Grid
                          item xs={10}
                          sx={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            // maxWidth: "28em",
                          }}
                        >
                         {this.state.editTemplate.versionNumber ?  <PageHeader
                            label={`TEMPLATE_PCoE_Pro_${this.state.editTemplate.templateName}_EN(v${this.state.editTemplate.versionNumber})`}
                          />:
                          <PageHeader
                          label={`TEMPLATE_PCoE_Pro_${this.state.editTemplate.templateName}`}
                        /> }
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    {(this.state.editTemplate.gpUniqueId === null && (this.state.editTemplate.status === "Submitted" || this.state.editTemplate.status === "Drafted") && !load) ?
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <WhiteButton
                              label="Cancel"
                              onClick={() => {
                                const { state } = this.props.location;
                                if (state && state.from) {
                                  this.props.history.push(state.from);
                                } else {
                                  this.props.history.push("/procedure_templates");
                                }
                                this.props.history.go(0);
                              }}
                            />
                          </Grid>

                          <Grid item>
                            <WhiteButton
                              label="Edit"
                              onClick={() => {
                                // if (this.state.editTemplate.status === 'Submitted') {
                                //   this.setState({ mode: "edit", editTemplate: { ...this.state.editTemplate, versionNumber: String((parseFloat(this.state.editTemplate.versionNumber) + 1)) } });
                                // } else {
                                //   this.setState({ mode: "edit" })
                                // }
                                this.setState({ mode: "edit" })
                                this.props.history.push(
                                  `/view_template/${this.state?.editTemplate.gpId}?mode=edit`, { from: `/view_template/${this.state?.editTemplate.gpId}?mode=view` }
                                );
                              }} />
                          </Grid>
                          {!load && this.state.editTemplate.status !== "Drafted" && <Grid item>
                          <GenerateButton
                            handleGenerate={this.handleGenerate}
                            onClick={(selected) =>
                              this.onGenerateButtonActions(selected)
                            }
                          />
                        </Grid>}
                        </Grid>
                      </Grid>
                      : !load &&
                      <Grid item>
                        <Grid container spacing={1}>
                          {<Grid item>
                            <WhiteButton
                              label="Cancel"
                              onClick={() => {
                                const { state } = this.props.location;
                                if (state && state.from) {
                                  this.props.history.push(state.from);
                                } else {
                                  this.props.history.push("/procedure_templates");
                                }
                                this.props.history.go(0);
                              }}
                            />
                          </Grid>}
                          
                          {this.state.editTemplate.gpId === this.state.editTemplate.gpUniqueId ? null : <Grid item>
                            <WhiteButton label="Compare" onClick={() => {
                              this.props.history.push({
                                pathname: `/document_compare/${this.state.editTemplate.gpId}`,
                                state: {
                                  editTemplate: {
                                    gpId: this.state.editTemplate.gpId,
                                    gpTemplateFile: this.state.editTemplate.gpTemplateFile,
                                  }
                                }
                              });
                            }} />
                          </Grid>}
                          {<Grid item>
                            <RedButton
                              label="Edit"
                              onClick={() => {
                                // if (this.state.editTemplate.status === 'Submitted') {
                                //   this.setState({ mode: "edit", editTemplate: { ...this.state.editTemplate, versionNumber: String((parseFloat(this.state.editTemplate.versionNumber) + 1)) } });
                                  
                                // } else {
                                //   this.setState({ mode: "edit" })
                                // }
                                this.setState({ mode: "edit" })
                                this.props.history.push(
                                  `/view_template/${this.state?.editTemplate.gpId}?mode=edit`, { from: `/view_template/${this.state?.editTemplate.gpId}?mode=view` }
                                );
                              }}
                            />
                          </Grid>}
                          {!load && this.state.editTemplate.status !== "Drafted" && <Grid item>
                          <GenerateButton
                            handleGenerate={this.handleGenerate}
                            onClick={(selected) =>
                              this.onGenerateButtonActions(selected)
                            }
                          />
                        </Grid>}
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </>
              ) : !load && (
                <>
                  <Grid
                    container
                    direction={"row"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1em",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xs={8}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item>
                          <BackLinkButton
                            onClick={() => {
                              // window.location.assign(`/view_template/${this.state.gpId}?mode=view`);
                              this.props.history.push(`/view_template/${this.state.gpId}?mode=view`);
                            }}
                          />
                        </Grid>
                        <Grid
                          item xs={10}
                          sx={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            // maxWidth: "39em",
                          }}
                        >
                          {
                            this.state.editTemplate.versionNumber ? 
                            <PageHeader
                            label={`TEMPLATE_PCoE_Pro_${this.state.editTemplate.templateName}_EN(v${this.state.editTemplate.versionNumber})`}
                          />
                            : 
                            <PageHeader
                            label={`TEMPLATE_PCoE_Pro_${this.state.editTemplate.templateName}`}
                          />
                          }
                          
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <WhiteButton
                            label="Cancel"
                            onClick={() => {
                              const { state } = this.props.location;
                              if (state && state.from) {
                                this.props.history.push(state.from);
                              } else {
                                this.props.history.push("/procedure_templates");
                              }
                              this.props.history.go(0);
                              // this.setState({ mode: 'edit' });
                              // this.props.history.push(
                              //   `/view_template/${this.state.editTemplate.gpId}?mode=edit`
                              // );

                            }}
                          />
                        </Grid>
                        
                        {/* <Grid item>
                          <WhiteButton
                            label="Save as Draft"
                            onClick={this.handleSaveAsDraftSubmit}
                          />
                        </Grid> */}
                        <Grid item>
                          <RedButton
                            label="Edit"
                            onClick={() => {
                              // if (this.state.editTemplate.status === 'Submitted') {
                              //   this.setState({ mode: "edit", editTemplate: { ...this.state.editTemplate, versionNumber: String((parseFloat(this.state.editTemplate.versionNumber) + 1)) } });
                              // } else {
                              //   this.setState({ mode: "edit" })
                              // }
                              this.setState({ mode: "edit" })
                              this.props.history.push(
                                `/view_template/${this.state.editTemplate.gpId}?mode=edit`
                              );
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <GenerateButton
                            handleGenerate={this.handleGenerate}
                            onClick={(selected) =>
                              this.onGenerateButtonActions(selected)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </>
              )}
            {/* </Box> */}

            {!load && <Grid container ml={"3.7em"} spacing={2} mb={"0.2em"}>
              <Grid item mr={2}>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Created By :</span> {this.state.editTemplate.createdBy + " " + moment(this.state.editTemplate.createdDate).format("L hh:mm a")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Last Modified By :</span> {(this.state.editTemplate.modifyBy) ? this.state.editTemplate.modifyBy + " " + moment(this.state.editTemplate.modifiedDate).format("L hh:mm a") : ""}
                </Typography>
              </Grid>
            </Grid>}

            <Divider />
            <Box>
              <>

                {!load &&
                  <><Grid container mt={2} ml={2}>
                    <Grid item xs={3}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Template Name
                      </Typography>
                      <Typography>
                        {this.state.editTemplate.templateName}
                      </Typography>
                      {this.state.editTemplate.headerImageFile !== "" ? <Chip
                        label={headerFileName}
                        avatar={<img src={this.state.headerFile} alt="" />}
                      /> : <></>}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Procedure Type
                      </Typography>
                      <Typography>
                        {this.state.editTemplate.procedureType}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Version
                      </Typography>
                      <Typography>
                        {this.state.editTemplate.versionNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Effective Date
                      </Typography>
                      <Typography>
                        {moment(this.state.editTemplate.effectiveDate).format(
                          "ll"
                        )}
                      </Typography>
                    </Grid>
                   {this.state.editTemplate.procedureType === "Global Procedure" ?  <Grid item xs={3} mt={2}>
                      <Typography style={{ fontWeight: "bold" }}>
                       Policy Sub-Committee Approval
                      </Typography>
                      <Typography>
                        {moment(this.state.editTemplate.policySubCommAppr).format(
                          "ll"
                        )}
                      </Typography>
                    </Grid> : <></>}
                  </Grid>
                    <Grid container mt={2} ml={2} mb={1.5}>
                      <Grid item xs={3}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Owner
                        </Typography>
                        <Typography>{this.state.editTemplate.owner}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Approver
                        </Typography>
                        <Typography>{this.state.editTemplate.approver}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography style={{ fontWeight: "bold" }}>
                          Footer
                        </Typography>
                        <Typography>{this.state.editTemplate.footer}</Typography>
                        {this.state.editTemplate.footerImageFile !== "" ? <Chip
                          label={footerFileName}
                          avatar={<img src={this.state.footerFile} alt="" />}
                        /> : <></>}
                      </Grid>
                    </Grid>
                  </>}
                <Divider />
                {<> <Grid container mt={2} ml={2}>
                  <Grid style={{ width: "99%" }} item>
                    <Typography style={{ fontWeight: "bold" }}>
                      Basic Principles
                    </Typography>
                    <div style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }} id="basicPrinciples"></div>
                  </Grid>
                </Grid>
                  <Grid container mt={2} ml={2}>
                    <Grid style={{ width: "99%" }} item>
                      <Typography style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }} id="header"></Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2} ml={2}>
                    <Grid style={{ width: "99%" }} item>
                      <Typography style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }} id="bodyText"></Typography>
                    </Grid>
                  </Grid></>}
                {this.state.sections.map((_el, ind) => {
                  return (
                    <>
                      <Grid container mt={2} ml={2}>
                        <Grid style={{ width: "99%" }} item>
                          <Typography style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }} id={`sectionHeader-${ind}`}></Typography>
                        </Grid>
                      </Grid>
                      <Grid container mt={2} ml={2}>
                        <Grid style={{ width: "99%" }} item>
                          <Typography style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }} id={`section-${ind}`}></Typography>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </>
            </Box>
          </Box>
        ) : !load && this.state.mode === "edit" && (
          <div>
            {/* <Grid marginBottom={"1em"} container direction="row">
              <BreadCrumb breadCrumbs={breadCrumbs} />
            </Grid> */}
            <Dialog open={this.state.confirmDeleteSection}>
              <Grid container>
                <Grid item>
                  <DialogTitle color={"red"}>
                    Remove Section
                  </DialogTitle>
                </Grid>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="lg"
                    onClick={() => {
                      this.setState({ confirmDeleteSection: false });
                    }}
                    style={{ marginTop: 22, marginLeft: 290 ,marginRight:16}}
                  />
                </Grid>
              </Grid>
              <DialogContent dividers>
                <DialogContentText marginTop={2} color={"black"}>
                  Are you sure you want to delete this section?
                  <br></br>

                </DialogContentText>
                <DialogContent>
                  <Grid container mt={3} ml={-3}>
                    <Grid item mr={2}>
                      <WhiteButton
                        label="No"
                        onClick={() => {
                          this.setState({ confirmDeleteSection: false });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <RedButton
                        label="Yes"
                        onClick={() => {
                          this.setState({
                            sections: this.state.sections.filter((_el) => _el.sectionId !== this.state.removeSectionId),
                            confirmDeleteSection: false
                          },
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </DialogContent>
            </Dialog>
            
            <Dialog open={this.state.confirmCreateFormSubmitFlag}>
              <Grid container>
                <Grid item>
                  <DialogTitle color={"red"}>
                  Changing Version Numbers
                  </DialogTitle>
                </Grid>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="lg"
                    onClick={() => {
                      this.setState({ confirmCreateFormSubmitFlag: false });
                    }}
                    style={{ marginTop: 22, marginLeft: 250 }}
                  />
                </Grid>
              </Grid>
              <DialogContent dividers>
                <DialogContentText marginTop={2} color={"black"}>
                Changing the version number will create a new version of the template.
                The superseded version will be archived with the Procedure Management Portal.
                If you don't change the version number, you will be editing the prior version.
                  <br></br>

                </DialogContentText>
                <DialogContent>
                  <Grid container mt={3} ml={-3}>
                    {/* <Grid item mr={2}>
                      <WhiteButton
                        label="No"
                        onClick={() => {
                          this.setState({ confirmCreateFormSubmitFlag: false });
                        }}
                      />
                    </Grid> */}
                    <Grid item>
                      <RedButton
                        label="Close"
                        onClick={this.handleCreateFormSubmit}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </DialogContent>
            </Dialog>
            <MyCustomSnackbar
              message= {this.state.errMsg}
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.throwErr}
              onClose={() => {
                this.setState({ throwErr: false });
              }}
            />
            
            <Grid
              container
              direction={"row"}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1em",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={8}>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item>
                    <BackLinkButton
                      onClick={() => {
                        //window.location.assign(`/view_template/${this.state.gpId}?mode=view`);
                        const { state } = this.props.location;                        
                        if (state && state.from) {
                          window.location.assign(state.from);
                        } else {
                          this.props.history.push("/procedure_templates");
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item xs={11}
                    sx={{
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      // maxWidth: "42em",
                    }}
                  >
                    {this.state.editTemplate.versionNumber ? <PageHeader
                      label={`TEMPLATE_PCoE_Pro_${this.state.editTemplate.templateName}_EN(v${this.state.editTemplate.versionNumber})`}
                    />: 
                    <PageHeader
                      label={`TEMPLATE_PCoE_Pro_${this.state.editTemplate.templateName}`}
                    />}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <WhiteButton
                      label="Open Reference Procedure"
                      onClick={() => {
                        window.open('/proceduretool', '_blank');
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <WhiteButton
                      label="Cancel"
                      onClick={() => {
                        // this.props.history.push(
                        //   `/view_template/${this.state.gpId}?mode=view`
                        // );
                        // this.props.history.go(0);
                        const { state } = this.props.location;
                        if (state && state.from) {
                          this.props.history.push(state.from);
                        } else {
                          this.props.history.push("/procedure_templates");
                        }
                        this.props.history.go(0);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <RedButton
                      label="Save as Draft"
                      onClick={this.handleSaveAsDraftSubmit}
                    />
                  </Grid>
                  <Grid item>
                    <RedButton
                      label="Create"
                      onClick={this.handleCreateFormSubmitConfirm}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <FormComponent
              onHeaderCancel={this.handlePreviewCancelClickHeader}
              onPolicyCommiteeAppr={this.handlePolicyCommApprDateChange}
              onFooterCancel={this.handlePreviewCancelClickFooter}
              onVersionNumberChange={this.handleVersionNumberChange}
              template={this.state.editTemplate}
              imageUrlHeader={this.state.headerFile}
              imageUrlFooter={this.state.footerFile}
              sections={this.state.sections}
              onHeaderEditorChange={this.handleHeaderEditorChange}
              editorState={this.state.editorState}
              onSectionHeaderChange={this.handleSectionHeaderChange}
              onTemplateChange={this.handleTemplateFormChange}
              onBodyTextEditorChange={this.handleBodyTextEditorChange}
              onBasicPrinciplesEditorChange={this.handleBasicPrinciplesChange}
              onSectionBodyTextChange={this.handleSectionBodyTextChange}
              onEffectiveDateChange={this.handleEffectiveDateChange}
              onProcedureTypeChange={this.onDropDownChange}
              onFooterImageUpload={this.handleFooterImage}
              onHeaderImageUpload={this.handleHeaderImage}
              onBodyTextAdd={this.addFormFields}
              onBodyTextRemove={this.removeFormFields}
              setApproverInputValue={this.setApproverInputValue}
              onApproverNameSelect={this.onApproverNameSelect}
              approverNames={this.state.approverNames}
              approverSelectedItems={this.state.approverSelectedItems}
              setOwnerInputValue={this.setOwnerInputValue}
              onOwnerNameSelect={this.onOwnerNameSelect}
              ownerNames={this.state.ownerNames}
              ownerSelectedItems={this.state.ownerSelectedItems}
              ApproverNameLoading={this.state.ApproverNameLoading}
            />
          </div>
        )}
      </>
    );
  }

  handleHeaderEditorChange = (_header: EditorState, index?: number, editor?: LexicalEditor) => {
    editor?.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const fontFamilyStyle = 'font-family';
        const fontSizeStyle = 'font-size';
        const ffSelectionStyle = $getSelectionStyleValueForProperty(selection, fontFamilyStyle);

        if (!ffSelectionStyle) {
          $patchStyleText(selection, {
            [fontFamilyStyle]: Constants.DocFontFamilyBold,
            [fontSizeStyle]: Constants.DefaultFontSize,
          });
        }
      }

      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          _header: _header
        }
      });
    });    
  }
  handleGenerate = async () => {
    const { editTemplate, sections } = this.state;
    let type = 'global';
    const configs: DocumentConfigModel[] = [];

    const gptHeaderConfigs = await DocumentService.getGPTHeaderConfigs(
      editTemplate
    );
    configs.push(...gptHeaderConfigs);

    const basicPrincipleTextConfigs =
      DocumentService.getGPTBasicPrincipleTextConfigs();
    configs.push(...basicPrincipleTextConfigs);

    const basicPricipleConfigs = RTEditorService.getDocConfigs(
      editTemplate._basicPrinciples,
      "basicPrinciple"
    );
    configs.push(...basicPricipleConfigs);


    const headerConfigs = RTEditorService.getDocConfigs(
      editTemplate._header,
      "header"
    );
    configs.push(...headerConfigs);
    configs.push({
      type: 'TEXT',
      value: '',
      textTypes: [],
      sectionType: 'CHILDREN',
    });
    const bodyTextConfigs = RTEditorService.getDocConfigs(
      editTemplate._bodyText,
      "bodyText"
    );
    configs.push(...bodyTextConfigs);
    configs.push({
      type: 'TEXT',
      value: '',
      textTypes: [],
      sectionType: 'CHILDREN',
    });

    sections.forEach((section, i) => {
      const headerSectionConfigs = RTEditorService.getDocConfigs(
        section._header!,
        `section-header-${section.sectionId}`
      );
      configs.push(...headerSectionConfigs);
      configs.push({
        type: 'TEXT',
        value: '',
        textTypes: [],
        sectionType: 'CHILDREN',
      });
      const bodyTextSectionConfigs = RTEditorService.getDocConfigs(
        section._bodyText,
        `section-body-${section.sectionId}`
      );
      configs.push(...bodyTextSectionConfigs);
      configs.push({
        type: 'TEXT',
        value: '',
        textTypes: [],
        sectionType: 'CHILDREN',
      });
    });

    const ownerApproverTableConfig = DocumentService.getOwnerApproverTableConfig(editTemplate, type);
    configs.push(...ownerApproverTableConfig);

    const footerConfigs = await DocumentService.getGPTFooterConfigs(
      editTemplate
    );
    configs.push(...footerConfigs);

    console.log("configs", configs);
    const blob = await DocxService.createDocument(configs);

    return blob;
  };

  onGenerateButtonActions = (selected: string) => {
    if (selected === "word") {
      this.props.history.push({
        pathname: "/document_view",
        state: {
          editTemplate: JSON.stringify(this.state.editTemplate),
          generateSelectedValue: selected,
          sections: JSON.stringify(this.state.sections)
        },
      });
    } else {
      this.props.history.push({
        pathname: "/document_view",
        state: {
          editTemplate: JSON.stringify(this.state.editTemplate),
          generateSelectedValue: selected,
          sections: JSON.stringify(this.state.sections)
        },
      });
    }
  };
}

export default withRouter(ViewTemplateData);