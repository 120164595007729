import axios from "axios";
import { omit } from "lodash";
import { Component, Context } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import gptService from "../../../../Services/GPTService";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel } from "@mui/material";
import SearchFilter from "../../../../Components/Search/SearchFilter";
import { IconType } from "../../../../Components/Button/IconButton";
import moment from "moment";
import DataLoaderEcoView from "../../../../Components/Table/DataLoaderEcoView";
import lptService from "../../../../Services/LPTService";
import MyCustomSnackbar from "../../../../Components/SnackBar/MyCustomSnackbar";
import { RoleContext } from "../../../../Contexts";
import { AuthorizationService } from "../../../../Services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import RedButton from "../../../../Components/Button/RedButton";

interface Props extends RouteComponentProps<any, any, any> { 
  handleProcedureDeleteCountUpdate: () => void;
}

type SortType = {
  key: string;
  direction: string;
}

interface State {
  deleteSuccessPopup:boolean;
  templateData: any;
  page: number;
  order: string;
  orderBy: string;
  rows: number;
  limit: number;
  gptId?: number;
  search: string;
  searchDebounce: string;
  searchedData: any;
  isloading: boolean;
  sortConfig: SortType | null;
  rowTotalCount: number | null;
  tab:number;
  countryArray :string[];
  isAdminUser:boolean;
  deleteLptPopup:boolean;
  deleteId:number;
}

class GraphicalScope extends Component<Props, State> {
  private searchTimeout : number | null = null;
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteSuccessPopup:false,
      sortConfig: null,
      templateData: [],
      page: 1,
      rows: 5,
      limit: 5,
      search: "",
      searchDebounce:"",
      order: "",
      orderBy: "",
      searchedData: [],
      isloading: false,
      rowTotalCount: 0,
      tab:1,
      countryArray:[],
      isAdminUser:false,
      deleteId:0,
      deleteLptPopup:false,
    };
  }
  componentDidMount(): void {
    const isAdminUser = AuthorizationService.isAdminUser(this.context);
    if(isAdminUser)
    {
      this.setState({isAdminUser:true});
    }
    const querySearch = {
      page: this.state.page,
      limit: this.state.limit,
      searchtext: this.state.searchDebounce,
      order: this.state.order,
      orderBy: this.state.orderBy,
      status:"Initiation Pending",
    }
    this.getLocalProcedure(querySearch);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
    // Compare the current state with the next state
    if (this.state.countryArray !== nextState.countryArray) {
      return false;
    }
    return true;
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    if (
      (prevState.page !== this.state.page ||
        prevState.limit !== this.state.limit ||
        prevState.searchDebounce !== this.state.searchDebounce
        || prevState.sortConfig  !== this.state.sortConfig)
    ) {
      const querySearch = {
        page: this.state.page,
        limit: this.state.limit,
        searchtext: this.state.searchDebounce,
        order: this.state.sortConfig?.key,
        orderBy: this.state.sortConfig?.direction,
        status:"Initiation Pending",
      }
      this.getLocalProcedure(querySearch);
    }
    // if (prevState.search !== this.state.search) { this.searchfield() }
  }

  searchfield = () => {
    const { templateData, search } = this.state
    let searcher = templateData.filter((el: any) =>
      el.templateName.startsWith(search.toString().toUpperCase()))

    if (search.length > 0) {
      this.setState({ searchedData: searcher })
    } else {
      console.log(searcher, 'else'); this.setState({ searchedData: templateData })
    }
  };


  handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ search: event.target.value }, () => {
      console.log("search", this.state.search);
      if (this.state.search.length === 0) {
        this.setState({ searchDebounce: this.state.search });
      }
    })
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.setState({ searchDebounce: this.state.search });
    }
  };

  getLocalProcedure = async (querySearch: any) => {
    try {
      this.setState({ isloading: true });
      const data = await gptService.getLocalProcedure(querySearch);
      const formatedData = data?.localProcedures.map((el) => {
        return { ...el, createdDate: moment(el.createdDate).format("L"), templateName: `${el.templateName}_${el.geoGraphyName ? el.geoGraphyName:""}(v${el.versionNumber})` };
      });
      const omitData = formatedData?.map((el) =>
        omit(el, [
          "headerImageFile",
          "versionNumber",
          "effectiveDate",
          "footer",
          "footerImageFile",
          "basicPrinciples",
          "header",
          "bodyText",
          "gpTemplateFile",
          "gpUniqueId",
          "createdBy",
          "modifyBy",
          "_bodyText",
          "_basicPrinciples",
          "_headerImageFile",
          "_footerImageFile",
          "modifiedDate",
        ])
      );
      this.setState({ templateData: omitData });
      this.setState({ isloading: false });
      if (data) {
        this.setState({ rowTotalCount: data.count });
      }
    } catch (error) {
      this.setState({ isloading: false });
      if (axios.isAxiosError(error)) {
        console.log("error message:", error.message);
        return error.message;
      } else {
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }
    }
  };

  requestSort = (key: string) => {
    const { sortConfig } = this.state;
    let direction = 'ASC';
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === 'ASC') {
        direction = 'DESC';
      }
    }
    this.setState({ sortConfig: { key, direction } } );
  }

  onChangePage = (page: number) => {
    if(page == 0)
    {
      this.setState({ page :1 });
    }else{
      this.setState({ page });
    }
  };
  onChangeRow = (row: number) => {
    this.setState({ limit: row });
  };

  clickId = (_fieldClicked: string, record: any) => {
    // window.location.assign(`/eco_localization/${record.lpId}?mode=view`);
    this.props.history.push(`/eco_localization/${record.lpId}?mode=view`);
  };
  
  handleDeleteLpt = async () => {
    let deleteLpt = await lptService.deleteLocalProcedure(this.state.deleteId);
    console.log("deleteLpt=", deleteLpt);
    if (deleteLpt?.deleted) {
      this.setState({ deleteLptPopup: false, deleteSuccessPopup: true });
      const querySearch = {
        page: this.state.page,
        limit: this.state.limit,
        searchtext: this.state.searchDebounce,
        order: this.state.order,
        orderBy: this.state.orderBy,
        status: "Initiation Pending",
      }
      this.props.handleProcedureDeleteCountUpdate();
      this.getLocalProcedure(querySearch);
    }
  }

  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        // window.location.assign(`/eco_localization/${record.lpId}?mode=edit`);
        this.props.history.push(`/eco_localization/${record.lpId}?mode=edit`);
        break;
        case "Delete":
          this.setState({ deleteLptPopup: true, deleteId: record.lpId });
          break;
    }
  };

  onSelectChange = (name: string[], id: number) => {
    this.setState({ countryArray: name },()=>{
      console.log("countryArray",this.state.countryArray)
    });
    console.log("name =, id=", name, id);
  }

  render() {

    if (this.state.isloading) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    return (
      <>
      <Dialog open={this.state.deleteLptPopup}>
          <Grid container>
            <Grid item>
              <DialogTitle color="red">
                {" "}
              Delete Procedure.
              </DialogTitle>
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                icon={faXmark}
                size="lg"
                onClick={() => {
                  this.setState({ deleteLptPopup: false });
                }}
                style={{ marginTop: 22, marginLeft: 134 }}
              />
            </Grid>
          </Grid>
          <DialogContent dividers>
            <DialogContentText marginTop={2} color={"black"}>
              Are you sure you want to delete this procedure.
            </DialogContentText>
            <DialogContent>
              <Grid container mt={3} ml={-3}>
                <Grid item mr={2}>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      this.setState({ deleteLptPopup: false });
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <RedButton
                    label="Yes"
                    onClick={() => {
                      this.handleDeleteLpt()
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
        </Dialog>
      <MyCustomSnackbar
          message="Local Procedure Template has been deleted successfully."
          severity="success"
          actions={<></>}
          open={this.state.deleteSuccessPopup}
          onClose={() => {
            this.setState({ deleteSuccessPopup: false });
          }}
        />
        <Grid container spacing={2}>
          <Grid item>
            <InputLabel>Search Procedure or Geography</InputLabel>
            <SearchFilter value={this.state.search} onChange={this.handleSearch}  onKeyDown={this.onKeyDown} />
          </Grid>
        </Grid>
        <DataLoaderEcoView
          onSelectChange={this.onSelectChange}
          tab={this.state.tab}
          sortConfig = {this.state.sortConfig}
          requestSort={this.requestSort}
          rowsPerPage={this.state.limit}
          tableData={this.state.templateData}
          page={this.state.page}
          actionArray={this.state.isAdminUser ? ["Edit","Delete"] :["Edit"]}
          onChangePage={this.onChangePage}
          onChangeRow={this.onChangeRow}
          onClickLink={this.clickId}
          rowTotalCount={this.state.rowTotalCount ? this.state.rowTotalCount:0}
          onClickCheckBox={() => { }}
          onActionClick={this.onActionClick}
        />
      </>
    );
  }
}

export default withRouter(GraphicalScope);
