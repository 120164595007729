import React from "react";
import { Box, Typography } from "@mui/material";

import MenuModel from "./MenuModel";

interface Props {
  menu: MenuModel;
  onClick: (menu: MenuModel) => void;
}
interface State { }
class MenuItem extends React.Component<Props, State> {
  render() {
    const { menu, onClick } = this.props;

    let selectedStyle: any = { padding: 10 };
    let menuStyle: any = { fontSize: 14, fontWeight: 400, color: "#FFFFFF" };
    if (menu.isSelected) {
      selectedStyle = {
        backgroundColor: "#9b3363",
        opacity: "85%",
        padding: 10,
        borderBottom: "5px solid #FFFFFF"
      };
      menuStyle = {
        fontSize:14,
        fontWeight: 500,
        color: "#FFFFFF",
      };
    }

    return (
      <Box
        style={selectedStyle}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        onClick={() => onClick(menu)}
      >
        <Box
          display="flex"
          justifyContent="center"
          style={{ color: "#FFFFFF", marginBottom: 5 }}
        ></Box>
        <Box display="flex" justifyContent="center" >
          <Typography
            style={menuStyle}
          >
            {menu.name}
          </Typography>   
        </Box>
      </Box>
    );
  }
}

export default MenuItem;
