import { nanoid } from "nanoid";
import React, { Component } from "react";
import Utils from '../../Common/Utils';

interface Props {
  documentUrl: string;
  height?: string;
}
interface State { }

export default class DocumentPreview extends Component<Props, State> {
  state = {};

  generateShortUrl = (documentUrl: any) => {
    const id = nanoid(6);
    const shortUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${id}`;
    return shortUrl;
  };

  render() {
    const { documentUrl, height } = this.props;
    const shortUrl = Utils.getEnvVars().API_URL + "test-document-view/" + documentUrl;
    console.log("docurl", shortUrl);

    return (
      <iframe
        src={documentUrl + `#toolbar=0`}
        title="My Document Viewer"
        width="100%"
        height={height}
        frameBorder={'0px'}
      />
    );
  }
}
