import { Component, Context } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RenewalForm } from '../../Models/RenewalForm'
import ExceptionRenewalFormComponent from './ExceptionRenewalFormComponent'
import { Box, CircularProgress, Divider, Grid } from '@mui/material'
import BackLinkButton from '../../Components/Button/BackLinkButton'
import PageHeader from '../../Components/Text/PageHeader'
import WhiteButton from '../../Components/Button/WhiteButton'
import RedButton from '../../Components/Button/RedButton'
import exceptionRenewService from '../../Services/ExceptionRenewal'
import MyCustomSnackbar from '../../Components/SnackBar/MyCustomSnackbar'
import ExceptionFormComponent from './ExceptionFormComponent'
import SelectModel from '../../Components/Select/SelectModel'
import lptService from '../../Services/LPTService'
import gptService from '../../Services/GPTService'
import { AuthProviderService, AuthorizationService } from '../../Services'
import Utils from '../../Common/Utils'
import IconButton from '../../Components/Button/IconButton'
import UnauthorizedRoute from '../../Components/Route/UnauthorizedRoute'
import { RoleContext } from '../../Contexts'


interface Props extends RouteComponentProps<any, any, any> { }

interface State {
  renewalForm: RenewalForm;
  throwErr: boolean;
  throwSuccess: boolean;
  errMsg: string;
  successMsg: string;
  procedureNames: any[];
  geographyScope: any[];
  businessScope: any[];
  exceptiongeography: any[];
  exceptionBusinessArea: any[];
  proceduresData: any[];
  load: boolean;
  loading: boolean;
  approverNames: any[];
  m4LegalAttrLoading: boolean;
  gmRegionalLoading: boolean;
  ibuHubGmLoading: boolean;
  assocVpECOLoading: boolean;
  m4EcoBlLoading: boolean;
  m4LegalAttrFRAPLoading: boolean;
  gmFuncVPLoading: boolean;
  assocVpIBULoading: boolean;
  chiefAccOffLoading: boolean;
  chiefProcOfcLoading: boolean;
  localBusOwnerLoading: boolean;
  localChiefFinOfcLoading: boolean;
  SenrEntrLoading: boolean;
  SeniorVPCFOLoading: boolean;
  localCFOLoading: boolean;
  m5F12BLLoading: boolean;
  m4LegalAttorneySpEcoBlVal: any;
  gmRegionalAffiliateVpVal: any;
  ibuHubGmVal: any;
  associateVpEcoIbuVal: any;
  associateVpLegalIbuVal: any;
  m4EcoBlVal: any;
  m4LegalAttrEcoBlFrapVal: any;
  gmFunctionVpr8Val: any;
  chiefAccOfficerVal: any;
  chiefProcOfficerVal: any;
  localBusinessOwnerVal: any;
  localChiefFinanOwnerVal: any;
  seniorDirEnterTravExpenVal: any;
  seniorVpChiefFinanOffcVal: any;
  localCfoVal: any;
  m5F12BusinessLeaderVal: any;
  lperId?: number;
  exceptionApprovers: any;
  isAuthorize: boolean;
  showButtons: boolean;
}

class ExceptionRenewal extends Component<Props, State> {
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props)
    this.state = {
      renewalForm: {
        exceptionSummary: "",
        expirationDate: null,
        legalRenewalConsultDate: null,
        procedureOwnerRenewalDate: null,
        rationaleForRenewal: "",
        renewal: 0,
        renewalRequestedBy: "",
        renewalSubmittedBy: "",
        exceptionStatus: "",
        title: "",
        businessAreaScope: "",
        ecGeographicm4: "",
        exceptionClassification: "",
        exceptionCompletedBy: "",
        exceptionDescription: "",
        exceptionExpirationDate: null,
        exceptionImpactAfterGrant: "",
        exceptionRationale: "",
        exceptionRequestBy: "",
        exceptionTypeForApproval: "",
        geographyScopeException: "",
        procedure: "",
        requestedTextFromProcedure: "",
        ecAssociateVpOpConsultDate: null,
        ecoBlConsultDate: null,
        ecGeographicm4ConsultDate: null,
        exceptionEffectiveDate: null,
        legalConsultDate: null,
        procedureOwnerConsultDate: null,
      },
      procedureNames: [],
      load: false,
      loading: true,
      businessScope: [],
      geographyScope: [],
      errMsg: "",
      successMsg: "",
      throwErr: false,
      throwSuccess: false,
      exceptionBusinessArea: [],
      exceptiongeography: [],
      proceduresData: [],
      approverNames: [],
      m4LegalAttrLoading: false,
      assocVpECOLoading: false,
      assocVpIBULoading: false,
      chiefAccOffLoading: false,
      chiefProcOfcLoading: false,
      gmFuncVPLoading: false,
      gmRegionalLoading: false,
      ibuHubGmLoading: false,
      localBusOwnerLoading: false,
      localCFOLoading: false,
      localChiefFinOfcLoading: false,
      m4EcoBlLoading: false,
      m4LegalAttrFRAPLoading: false,
      m5F12BLLoading: false,
      SeniorVPCFOLoading: false,
      SenrEntrLoading: false,
      associateVpEcoIbuVal: {},
      associateVpLegalIbuVal: {},
      chiefAccOfficerVal: {},
      chiefProcOfficerVal: {},
      gmFunctionVpr8Val: {},
      gmRegionalAffiliateVpVal: {},
      ibuHubGmVal: {},
      localBusinessOwnerVal: {},
      localCfoVal: {},
      localChiefFinanOwnerVal: {},
      m4EcoBlVal: {},
      m4LegalAttorneySpEcoBlVal: {},
      m4LegalAttrEcoBlFrapVal: {},
      m5F12BusinessLeaderVal: {},
      seniorDirEnterTravExpenVal: {},
      seniorVpChiefFinanOffcVal: {},
      exceptionApprovers: [],
      isAuthorize: false,
      showButtons: false,
    }
  }

  async componentDidMount(): Promise<void> {
    const { renewalForm } = this.props?.history?.location?.state;
    const { accessToken } = await AuthProviderService.getAccessToken();
    const loggedInUserToken = Utils.decodeJWT<any>(accessToken);
    console.log("loggedInUserToken=", loggedInUserToken);
    try {

      const exceptionRenew: RenewalForm[] = await exceptionRenewService.findExceptionRenewalDataById(
        this.props?.history?.location?.state?.renewalForm?.gperId
      );
      const querySearchGlobal = {
        page: 1,
        limit: 5000,
        searchtext: "",
        order: "",
        orderBy: "",
        status: "Submitted",
      }
      const querySearchLocal = {
        page: 1,
        limit: 5000,
        searchtext: "",
        order: "",
        orderBy: "",
        status: "Initiation Pending",
      }
      let data
      if (renewalForm.formType === "GlobalForm") {
        data = await this.getProcedureNamesGlobal(querySearchGlobal);
      } else {
        data = await this.getProcedureNamesLocal(querySearchLocal);
      }
      if (renewalForm?.associateVpEcoIbuName !== null) {
        this.setState({
          associateVpEcoIbuVal: {
            text: this.props?.history?.location?.state?.renewalForm?.associateVpEcoIbuName,
            value: this.props?.history?.location?.state?.renewalForm?.associateVpEcoIbuName,
            email: this.props?.history?.location?.state?.renewalForm?.associateVpEcoIbuEmail,
          },
        });
      } else {
        this.setState({
          associateVpEcoIbuVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.associateVpLegalIbuName !== null) {
        this.setState({
          associateVpLegalIbuVal: {
            text: renewalForm?.associateVpLegalIbuName,
            value: renewalForm?.associateVpLegalIbuName,
            email: renewalForm?.associateVpLegalIbuEmail,
          },
        });
      } else {
        this.setState({
          associateVpLegalIbuVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.m4LegalAttrEcoBlFrapName !== null) {
        this.setState({
          m4LegalAttrEcoBlFrapVal: {
            text: renewalForm?.m4LegalAttrEcoBlFrapName,
            value: renewalForm?.m4LegalAttrEcoBlFrapName,
            email: renewalForm?.m4LegalAttrEcoBlFrapEmail,
          },
        });
      } else {
        this.setState({
          m4LegalAttrEcoBlFrapVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.chiefAccOfficerName !== null) {
        this.setState({
          chiefAccOfficerVal: {
            text: renewalForm?.chiefAccOfficerName,
            value: renewalForm?.chiefAccOfficerName,
            email: renewalForm?.chiefAccOfficerEmail,
          },
        });
      } else {
        this.setState({
          chiefAccOfficerVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.chiefProcOfficerName !== null) {
        this.setState({
          chiefProcOfficerVal: {
            text: renewalForm?.chiefProcOfficerName,
            value: renewalForm?.chiefProcOfficerName,
            email: renewalForm?.chiefProcOfficerEmail,
          },
        });
      } else {
        this.setState({
          chiefProcOfficerVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.gmFunctionVpr8Name !== null) {
        this.setState({
          gmFunctionVpr8Val: {
            text: renewalForm?.gmFunctionVpr8Name,
            value: renewalForm?.gmFunctionVpr8Name,
            email: renewalForm?.gmFunctionVpr8Email,
          },
        });
      } else {
        this.setState({
          gmFunctionVpr8Val: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.gmRegionalAffiliateVpName !== null) {
        this.setState({
          gmRegionalAffiliateVpVal: {
            text: renewalForm?.gmRegionalAffiliateVpName,
            value: renewalForm?.gmRegionalAffiliateVpName,
            email: renewalForm?.gmRegionalAffiliateVpEmail,
          },
        });
      } else {
        this.setState({
          gmRegionalAffiliateVpVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.ibuHubGmName !== null) {
        this.setState({
          ibuHubGmVal: {
            text: renewalForm?.ibuHubGmName,
            value: renewalForm?.ibuHubGmName,
            email: renewalForm?.ibuHubGmEmail,
          },
        });
      } else {
        this.setState({
          ibuHubGmVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.localBusinessOwnerName !== null) {
        this.setState({
          localBusinessOwnerVal: {
            text: renewalForm?.localBusinessOwnerName,
            value: renewalForm?.localBusinessOwnerName,
            email: renewalForm?.localBusinessOwnerEmail,
          },
        });
      } else {
        this.setState({
          localBusinessOwnerVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.localCfoName !== null) {
        this.setState({
          localCfoVal: {
            text: renewalForm?.localCfoName,
            value: renewalForm?.localCfoName,
            email: renewalForm?.localCfoEmail,
          },
        });
      } else {
        this.setState({
          localCfoVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.localChiefFinanOwnerName !== null) {
        this.setState({
          localChiefFinanOwnerVal: {
            text: renewalForm?.localChiefFinanOwnerName,
            value: renewalForm?.localChiefFinanOwnerName,
            email: renewalForm?.localChiefFinanOwnerEmail,
          },
        });
      } else {
        this.setState({
          localChiefFinanOwnerVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.m4EcoBlName !== null) {
        this.setState({
          m4EcoBlVal: {
            text: renewalForm?.m4EcoBlName,
            value: renewalForm?.m4EcoBlName,
            email: renewalForm?.m4EcoBlEmail,
          },
        });
      } else {
        this.setState({
          m4EcoBlVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.m4LegalAttorneySpEcoBlName !== null) {
        this.setState({
          m4LegalAttorneySpEcoBlVal: {
            text: renewalForm?.m4LegalAttorneySpEcoBlName,
            value: renewalForm?.m4LegalAttorneySpEcoBlName,
            email: renewalForm?.m4LegalAttorneySpEcoBlEmail,
          },
        });
      } else {
        this.setState({
          m4LegalAttorneySpEcoBlVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.m5F12BusinessLeaderName !== null) {
        this.setState({
          m5F12BusinessLeaderVal: {
            text: renewalForm?.m5F12BusinessLeaderName,
            value: renewalForm?.m5F12BusinessLeaderName,
            email: renewalForm?.m5F12BusinessLeaderEmail,
          },
        });
      } else {
        this.setState({
          m5F12BusinessLeaderVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.seniorDirEnterTravExpenName !== null) {
        this.setState({
          seniorDirEnterTravExpenVal: {
            text: renewalForm?.seniorDirEnterTravExpenName,
            value: renewalForm?.seniorDirEnterTravExpenName,
            email: renewalForm?.seniorDirEnterTravExpenEmail,
          },
        });
      } else {
        this.setState({
          seniorDirEnterTravExpenVal: { text: "", value: "", email: "" },
        });
      }
      if (renewalForm?.seniorVpChiefFinanOffcName !== null) {
        this.setState({
          seniorVpChiefFinanOffcVal: {
            text: renewalForm?.seniorVpChiefFinanOffcName,
            value: renewalForm?.seniorVpChiefFinanOffcName,
            email: renewalForm?.seniorVpChiefFinanOffcEmail,
          },
        });
      } else {
        this.setState({
          seniorVpChiefFinanOffcVal: { text: "", value: "", email: "" },
        });
      };
      const procedure = this.props?.history?.location?.state?.renewalForm?.procedure.split(",").map((str: any) => { return { text: str, value: str } })
      const geography = this.props?.history?.location?.state?.renewalForm?.geographyScopeException.split(",").map((str: any) => { return { text: str, value: str } })
      const businessScope = this.props?.history?.location?.state?.renewalForm?.businessAreaScope.split(",").map((str: any) => { return { text: str, value: str } })
      await this.getExceptionBusinessAreaData();
      await this.getExceptionGeographyData();
      const lastEntry = exceptionRenew ? exceptionRenew[exceptionRenew.length - 1] : null
      this.setState({
        renewalForm: {
          ...this.props?.history?.location?.state?.renewalForm, title: this.props?.history?.location?.state?.renewalForm?.title,
          gperId: this.props?.history?.location?.state?.renewalForm?.gperId,
          gpId: this.props?.history?.location?.state?.renewalForm?.gpId,
          lpId: this.props?.history?.location?.state?.renewalForm?.lpId,
          renewal: lastEntry ? lastEntry.renewal + 1 : 1,
          exceptionStatus: "Inactive",
          exceptionTypeForApproval: this.props?.history?.location?.state?.renewalForm?.exceptionTypeForApproval,
          associateVpEcoIbu: this.props?.history?.location?.state?.renewalForm?.associateVpEcoIbu,
          associateVpLegalIbu: this.props?.history?.location?.state?.renewalForm?.associateVpLegalIbu,
          chiefAccOfficer: this.props?.history?.location?.state?.renewalForm?.chiefAccOfficer,
          chiefProcOfficer: this.props?.history?.location?.state?.renewalForm?.chiefProcOfficer,
          gmFunctionVpr8: this.props?.history?.location?.state?.renewalForm?.gmFunctionVpr8,
          gmRegionalAffiliateVp: this.props?.history?.location?.state?.renewalForm?.gmRegionalAffiliateVp,
          ibuHubGm: this.props?.history?.location?.state?.renewalForm?.ibuHubGm,
          localBusinessOwner: this.props?.history?.location?.state?.renewalForm?.localBusinessOwner,
          localCfo: this.props?.history?.location?.state?.renewalForm?.localCfo,
          localChiefFinanOwner: this.props?.history?.location?.state?.renewalForm?.localChiefFinanOwner,
          m4EcoBl: this.props?.history?.location?.state?.renewalForm?.m4EcoBl,
          m4LegalAttorneySpEcoBl: this.props?.history?.location?.state?.renewalForm?.m4LegalAttorneySpEcoBl,
          m4LegalAttrEcoBlFrap: this.props?.history?.location?.state?.renewalForm?.m4LegalAttrEcoBlFrap,
          m5F12BusinessLeader: this.props?.history?.location?.state?.renewalForm?.m5F12BusinessLeader,
          seniorDirEnterTravExpen: this.props?.history?.location?.state?.renewalForm?.seniorDirEnterTravExpen,
          seniorVpChiefFinanOffc: this.props?.history?.location?.state?.renewalForm?.seniorVpChiefFinanOffc,
          gperuniqueId: this.props?.history?.location?.state?.renewalForm?.gperuniqueId === null ? this.props?.history?.location?.state?.renewalForm?.gperId : this.props?.history?.location?.state?.renewalForm?.gperuniqueId,
          renewalSubmittedBy: loggedInUserToken.name
        },
        proceduresData: data!,
        procedureNames: procedure!, geographyScope: geography!, businessScope: businessScope!,
        loading: false
      });
    } catch (error) {
      console.error('err', error)
    }
  };

  handleInputChange = (event: any) => {
    this.setState({
      renewalForm: {
        ...this.state.renewalForm, [event.target.name]: event.target.value
      }
    })
  };

  handleExpirationDateChange = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, expirationDate: value! },
    });
  };
  handleLegalConsultDateChangeRenewal = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, legalRenewalConsultDate: value! },
    });
  };
  handleProcedureOwnerDateChangeRenewal = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, procedureOwnerRenewalDate: value! },
    });
  };
  onDropDownChangeRenewal = (selected: SelectModel) => {
    const { renewalForm } = this.state;
    const value = selected.value;
    this.setState({
      renewalForm: { ...renewalForm, status: value },
    });
  };
  action = (
    <>

      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ throwSuccess: false });
        }}
      />
    </>
  );

  render() {

    const { renewalForm, successMsg, throwSuccess, errMsg, throwErr, loading } = this.state;
    if (loading) {
      return (<><Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box></>)
    }
    const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);
    if (isReadonlyUser) {
      return <UnauthorizedRoute />;
    }
    console.log('renewalForm', renewalForm);
    return (
      <Box>
        <MyCustomSnackbar
          message={errMsg}
          severity="warning"
          actions={<></>}
          open={throwErr}
          onClose={() => {
            this.setState({ throwErr: false });
          }}
        />
        <MyCustomSnackbar
          message={successMsg}
          severity="success"
          actions={this.action}
          open={throwSuccess}
          onClose={() => {
            this.setState({ throwSuccess: false });
          }}
        />
        <Grid container
          direction={"row"}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1em",
            justifyContent: "space-between",
          }}>
          <Grid item>
            <Grid container spacing={2} style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <Grid item>
                <BackLinkButton
                  onClick={() => {
                    let state: any = this.props.location.state;
                    // eslint-disable-next-line no-lone-blocks
                    state?.from
                      ?
                      this.props.history.push(`/raised_exception/${this.props.history?.location?.state?.renewalForm?.gperId}?mode=view`)
                      :
                      this.props.history.push('/procedure_exception')
                  }}
                />
              </Grid>
              <Grid style={{ display: "flex", justifyContent: "flex-start" }} item sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                maxWidth: '42em'
              }}>
                <PageHeader label={`Procedure Exception Renewal Form`} />
              </Grid>

            </Grid>
          </Grid>
          <Grid item style={{ display: "flex", justifyContent: 'flex-end' }}>
            <Grid container spacing={1}>
              <Grid item>
                <WhiteButton label="Cancel" onClick={() => {
                  this.props.history.push(`/procedure_exception`)
                }} />
              </Grid>
              <Grid item>
                <WhiteButton label="Save as Draft" onClick={this.handleSaveAsDraft} />
              </Grid>
              <Grid item>
                <RedButton label="Submit for Approval" onClick={this.handleSubmitForApproval} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Box>
          <ExceptionRenewalFormComponent
            renewalForm={renewalForm}
            onTextInputChange={this.handleInputChange}
            handleExpirationDateChange={this.handleExpirationDateChange}
            handleLegalConsultDateChange={this.handleLegalConsultDateChangeRenewal}
            handleProcedureOwnerDateChange={this.handleProcedureOwnerDateChangeRenewal}
            onDropDownChange={this.onDropDownChangeRenewal}
          />
          <ExceptionFormComponent
            exceptionForm={this.state.renewalForm}
           
            businessScope={this.state.businessScope}
            geographyScope={this.state.geographyScope}
            handleEcAssociateVPDateChange={this.handleEcAssociateVPDateChange}
            handleEcAssociateVpOpRadioButtons={this.handleEcAssociateVpOpRadioButtons}
            handleEcBlConsultDateChange={this.handleEcBlConsultDateChange}
            handleEcGeographicDateChange={this.handleEcGeographicDateChange}
            handleExceptionEffDateChange={this.handleExceptionEffDateChange}
            handleExceptionExpiryDateChange={this.handleExceptionExpiryDateChange}
            handleLegalConsultDateChange={this.handleLegalConsultDateChange}
            handleProcedureOwnerDateChange={this.handleProcedureOwnerDateChange}
            handleexceptionClassificationRadioButtons={this.handleexceptionClassificationRadioButtons}
            onDropDownChange={this.onDropDownChange}
            onSelectBusinessScope={this.onSelectBusinessScope}
            onSelectGeography={this.onSelectGeographyScope}
            onSelectProcedure={this.onSelectProcedure}
            load={this.state.load}
            onTextInputChange={this.handleTextInputChange}
            procedureNames={this.state.procedureNames}
            exceptionBusinesData={this.state.exceptionBusinessArea}
            geographyScopeData={this.state.exceptiongeography}
            procedureNameData={this.state.proceduresData}
            ibuHubGmLoading={this.state.ibuHubGmLoading}
            localBusOwnerLoading={this.state.localBusOwnerLoading}
            localCFOLoading={this.state.localCFOLoading}
            localChiefFinOfcLoading={this.state.localChiefFinOfcLoading}
            m4EcoBlLoading={this.state.m4EcoBlLoading}
            m4LegalAttrFRAPLoading={this.state.m4LegalAttrFRAPLoading}
            m4LegalAttrLoading={this.state.m4LegalAttrLoading}
            SeniorVPCFOLoading={this.state.SeniorVPCFOLoading}
            SenrEntrLoading={this.state.SenrEntrLoading}
            assocVpECOLoading={this.state.assocVpECOLoading}
            assocVpIBULoading={this.state.assocVpIBULoading}
            chiefAccOffLoading={this.state.chiefAccOffLoading}
            chiefProcOfcLoading={this.state.chiefProcOfcLoading}
            gmRegionalLoading={this.state.gmRegionalLoading}
            gmFuncVPLoading={this.state.gmFuncVPLoading}
            m5F12BLLoading={this.state.m5F12BLLoading}
            associateVpEcoIbuVal={this.state.associateVpEcoIbuVal}
            associateVpLegalIbuVal={this.state.associateVpLegalIbuVal}
            chiefAccOfficerVal={this.state.chiefAccOfficerVal}
            chiefProcOfficerVal={this.state.chiefProcOfficerVal}
            gmFunctionVpr8Val={this.state.gmFunctionVpr8Val}
            gmRegionalAffiliateVpVal={this.state.gmRegionalAffiliateVpVal}
            ibuHubGmVal={this.state.ibuHubGmVal}
            approverNames={this.state.approverNames}
            localBusinessOwnerVal={this.state.localBusinessOwnerVal}
            localCfoVal={this.state.localCfoVal}
            localChiefFinanOwnerVal={this.state.localChiefFinanOwnerVal}
            m4EcoBlVal={this.state.m4EcoBlVal}
            m4LegalAttorneySpEcoBlVal={this.state.m4LegalAttorneySpEcoBlVal}
            m4LegalAttrEcoBlFrapVal={this.state.m4LegalAttrEcoBlFrapVal}
            m5F12BusinessLeaderVal={this.state.m5F12BusinessLeaderVal}
            seniorDirEnterTravExpenVal={this.state.seniorDirEnterTravExpenVal}
            seniorVpChiefFinanOffcVal={this.state.seniorVpChiefFinanOffcVal}
            setAssociateVpEcoIbuValue={this.setAssociateVpEcoIbuInputValue}
            setAssociateVpLegalIbuValue={this.setAssociateVpLegalIbuInputValue}
            setChiefAccOfficerValue={this.setChiefAccOfficerInputValue}
            setChiefProcOfficerValue={this.setChiefProcOfficerInputValue}
            setGmFunctionVpr8Value={this.setGmFunctionVpr8InputValue}
            setGmRegionalAffiliateVpValue={this.setGmRegionalAffiliateVpInputValue}
            setIbuHubGmValue={this.setIbuHubGmInputValue}
            setLocalBusinessOwnerValue={this.setLocalBusinessOwnerValue}
            setLocalCfoValue={this.setLocalCfoValue}
            setLocalChiefFinanOwnerValue={this.setLocalChiefFinanOwnerValue}
            setM4EcoBlValue={this.setM4EcoBlValue}
            setSeniorDirEnterTravExpenValue={this.setSeniorDirEnterTravExpenValue}
            setM4LegalAttorneySpEcoBlValue={this.setM4LegalAttorneySpEcoBlValue}
            setM4LegalAttrEcoBlFrapValue={this.setM4LegalAttrEcoBlFrapValue}
            setM5F12BusinessLeaderValue={this.setM5F12BusinessLeaderValue}
            setSeniorVpChiefFinanOffcValue={this.setSeniorVpChiefFinanOffcValue}
            onAssociateVpEcoIbuSelect={this.onAssociateVpEcoIbuSelect}
            onAssociateVpLegalIbuSelect={this.onAssociateVpLegalIbuSelect}
            onChiefAccOfficerSelect={this.onChiefAccOfficerSelect}
            onChiefProcOfficerSelect={this.onChiefProcOfficerSelect}
            onGmFunctionVpr8Select={this.onGmFunctionVpr8Select}
            onGmRegionalAffiliateVpSelect={this.onGmRegionalAffiliateVpSelect}
            onIbuHubGmSelect={this.onIbuHubGmSelect}
            onLocalBusinessOwnerSelect={this.onLocalBusinessOwnerSelect}
            onLocalCfoSelect={this.onLocalCfoSelect}
            onLocalChiefFinanOwnerSelect={this.onLocalChiefFinanOwnerSelect}
            onM4EcoBlSelect={this.onM4EcoBlSelect}
            onM4LegalAttorneySpEcoBlSelect={this.onM4LegalAttorneySpEcoBlSelect}
            onM4LegalAttrEcoBlFrapSelect={this.onM4LegalAttrEcoBlFrapSelect}
            onM5F12BusinessLeaderSelect={this.onM5F12BusinessLeaderSelect}
            onSeniorDirEnterTravExpenSelect={this.onSeniorDirEnterTravExpenSelect}
            onSeniorVpChiefFinanOffcSelect={this.onSeniorVpChiefFinanOffcSelect}

          />
        </Box>
      </Box>
    )

  }

  handleSaveAsDraft = async () => {
    const { renewalForm, procedureNames, geographyScope, businessScope } = this.state;
    let procedure = (procedureNames.map((item: any) => item.text)).join(',');
    let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
    let businessSp = (businessScope.map((item: any) => item.text)).join(',');

    renewalForm.procedure = procedure;
    renewalForm.geographyScopeException = geographySp;
    renewalForm.businessAreaScope = businessSp;

    await exceptionRenewService.saveAsDraft(renewalForm).then((res) => {

      if (res.isSuccess) {

        this.setState({ lperId: res.data.lperId }, () => {
          this.props.history.push({
            pathname: `/exception_renew/${res.data.lperId}?mode=view`, state: {
              renewalForm: res.data.lperId,
              success: true,
              msg: "The Procedure Exception Renewal Form has been drafted successfully."
            }
          });
          this.props.history.go(0);
        })
      } else {
        this.setState({
          errMsg: 'Something went wrong.',
          throwErr: true
        })
      }
    })
  }

  validationCheck = () => {
    const { renewalForm } = this.state;
    let final: boolean = true;
    let emptyFields = [];
    if (!renewalForm.title) {
      final = false;
      emptyFields.push("Title");

    } 
     if (!renewalForm.exceptionSummary) {
      final = false;
      emptyFields.push("Exception Summary");
    }
     if (!renewalForm.rationaleForRenewal) {
      final = false;
      emptyFields.push("Rationale for Renewal");
    } 
     if (!renewalForm.renewalRequestedBy) {
      final = false;
      emptyFields.push("Renewal Requested By");
    } 
     if (!renewalForm.renewalSubmittedBy) {
      final = false;
      emptyFields.push("Renewal Submitted By");
    } 
     if (!renewalForm.expirationDate) {
      final = false;
      emptyFields.push("Expiration Date");

    } 
     if (!renewalForm.legalRenewalConsultDate) {
      final = false;
      emptyFields.push("Legal Renewal Consult Date");

    } 
     if (!renewalForm.procedureOwnerRenewalDate) {
      final = false;
      emptyFields.push("Procedure Owner Renewal Consult Date");
    } 
    const errMsg = `Please enter the mandatory data for ${emptyFields.join(", ")} `
    this.setState({errMsg, throwErr: !final});
    return final
  }

  getExceptionGeographyData = async () => {
    try {
      const data = await lptService.getAllExceptionGeography();
      // const sortedData = [...data].sort((a: any, b: any) => {return a - b})
      const sortedData = [...data].sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropdowndata = sortedData.map((el) => {
        return {
          id: el.id,
          text: el.text,
          value: el.value
        }
      })

      this.setState({ exceptiongeography: [...dropdowndata] })
    } catch (error) {

    }
  }
  getExceptionBusinessAreaData = async () => {
    try {
      const data = await lptService.getAllExceptionBusinessArea();
      const sortedData = [...data].sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropdowndata = sortedData.map((el) => {
        return {
          id: el.id,
          text: el.text,
          value: el.value
        }
      })

      this.setState({ exceptionBusinessArea: [...dropdowndata] })
    } catch (error) {

    }
  };

  getProcedureNamesLocal = async (querySearch: any) => {
    try {
      const data = await gptService.getLocalProcedure(querySearch);
      const sortedData = data!.localProcedures.sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropDownData = sortedData.map((el) => {
        return {
          id: el.lpId,
          text: `${el.templateName}(v${el.versionNumber})`,
          value: `${el.templateName}(v${el.versionNumber})`
        }
      });

      this.setState({ proceduresData: dropDownData });
      return dropDownData;
    } catch (error) {
      console.log("error", error)
    }
  }
  getProcedureNamesGlobal = async (querySearch: any) => {
    try {
      const data = await gptService.getAllWithPagination(querySearch);
      const sortedData = data!.globalProcedures.sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropDownData = sortedData.map((el) => {
        return {
          id: el.gpId,
          text: `${el.templateName}(v${el.versionNumber})`,
          value: `${el.templateName}(v${el.versionNumber})`
        }
      });

      this.setState({ proceduresData: dropDownData });
      return dropDownData;
    } catch (error) {
      console.log("error", error)
    }
  };

  handleSubmitForApproval = async () => {
    const { renewalForm, procedureNames, geographyScope, businessScope, exceptionApprovers } = this.state;
    if (renewalForm.gperuniqueId === null) {
      renewalForm.gperuniqueId = renewalForm.lperId;
    }
    let procedure = (procedureNames.map((item: any) => item.text)).join(',');
    let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
    let businessSp = (businessScope.map((item: any) => item.text)).join(',');
    renewalForm.procedure = procedure;
    renewalForm.geographyScopeException = geographySp;
    renewalForm.businessAreaScope = businessSp;
    let approvers = [];
    if (this.validationCheck() && this.validationChecker()) {
      if (renewalForm.exceptionTypeForApproval === "Global Ethics & Compliance Procedure") {
        approvers.push(renewalForm.gmRegionalAffiliateVpEmail);
        approvers.push(renewalForm.m4LegalAttorneySpEcoBlEmail);
      } else if (renewalForm.exceptionTypeForApproval === "Lilly International Affiliate/Hub Local Procedure Requirements") {
        approvers.push(renewalForm.ibuHubGmEmail);
        approvers.push(renewalForm.associateVpEcoIbuEmail)
        approvers.push(renewalForm.associateVpLegalIbuEmail)
      } else if (renewalForm.exceptionTypeForApproval === "Global Procedure on Following FRAP") {
        approvers.push(renewalForm.m4EcoBlEmail)
        approvers.push(renewalForm.m4LegalAttrEcoBlFrapEmail)
        approvers.push(renewalForm.gmFunctionVpr8Email)
        approvers.push(renewalForm.chiefAccOfficerEmail)
        approvers.push(renewalForm.chiefProcOfficerEmail)
      } else if (renewalForm.exceptionTypeForApproval === "Global Travel and Expense Procedure") {
        approvers.push(renewalForm.localBusinessOwnerEmail)
        approvers.push(renewalForm.localChiefFinanOwnerEmail)
        approvers.push(renewalForm.seniorDirEnterTravExpenEmail)
        approvers.push(renewalForm.seniorVpChiefFinanOffcEmail)
      } else {
        approvers.push(renewalForm.localCfoEmail)
        approvers.push(renewalForm.m5F12BusinessLeaderEmail)
      }
      this.setState({ exceptionApprovers: approvers });
      await exceptionRenewService.create({ renewalForm: renewalForm, approvers: exceptionApprovers }).then((res) => {
        if (res.isSuccess) {
          this.setState({ throwSuccess: true, successMsg: "The Procedure Exception Renewal Form has been submitted for approval." })
          setTimeout(() => {
            this.props.history.push('/procedure_exception');
          }, 1200);
        } else {
          this.setState({
            throwErr: true,
            errMsg: 'Something went wrong.'
          })
        }
      })
    }
  }

  onDropDownChange = (selected: SelectModel) => {
    const { renewalForm } = this.state;
    const value = selected.value;
    this.setState({
      renewalForm: { ...renewalForm, exceptionTypeForApproval: value },
    });
  };

  handleEcAssociateVPDateChange = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, ecAssociateVpOpConsultDate: value! },
    });
  };
  handleEcGeographicDateChange = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, ecGeographicm4ConsultDate: value! },
    });
  };
  handleEcBlConsultDateChange = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, ecoBlConsultDate: value! },
    });
  };
  handleExceptionEffDateChange = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, exceptionEffectiveDate: value! },
    });
  };
  handleExceptionExpiryDateChange = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, exceptionExpirationDate: value },
    });
  };
  handleLegalConsultDateChange = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, legalConsultDate: value! },
    });
  };
  handleProcedureOwnerDateChange = (value: Date | null) => {
    this.setState({
      renewalForm: { ...this.state.renewalForm, procedureOwnerConsultDate: value! },
    });
  };

  validationChecker = (): boolean => {
    const { renewalForm } = this.state;
    let final: boolean = true;
    let emptyFields = [];
    if (!renewalForm.title) {
      final = false;
      emptyFields.push("Title");
     
    } 
    if (!renewalForm.exceptionExpirationDate) {
      final = false;
      emptyFields.push("Exception Expiration Date");
     
    }
    if (!renewalForm.exceptionClassification) {
      final = false;
      emptyFields.push("Exception Classification");
     
    } 
     if (!renewalForm.exceptionRequestBy) {
      final = false;
      emptyFields.push("Exception Requested By");
     
    }
     if (!renewalForm.exceptionCompletedBy) {
      final = false;
      emptyFields.push("Exception Completed By");
      
    }
     if (!renewalForm.procedure) {
      final = false;
      emptyFields.push("Procedure(s)");
     
    } 
     if (!renewalForm.businessAreaScope) {
      final = false;
      emptyFields.push("Business Area Scope for Exception");
      
    } 
     if (!renewalForm.geographyScopeException) {
      final = false;
      emptyFields.push("Geography Scope for Exception");
      
    } 
     if (!renewalForm.exceptionDescription) {
      final = false;
      emptyFields.push("Exception Description");
      
    } 
     if (!renewalForm.exceptionImpactAfterGrant) {
      final = false;
      emptyFields.push("Impact of Exception if Granted");
     
    } 
     if (!renewalForm.exceptionRationale) {
      final = false;
      emptyFields.push("Exception Rationale");
    } 
     if (!renewalForm.exceptionTypeForApproval) {
      final = false;
      emptyFields.push("Exception Type for Approval");
     
    } 
     if (!renewalForm.requestedTextFromProcedure) {
      final = false;
      emptyFields.push("Text from Procedure for which Exception is Requested"); 
     
    } 
    if(renewalForm.exceptionTypeForApproval) {
      // Check fields based on exceptionTypeForApproval
      switch (renewalForm.exceptionTypeForApproval) {
        case "Global Ethics & Compliance Procedure":
          if (this.areObjectValuesEmpty(this.state.gmRegionalAffiliateVpVal)) {
            final = false;
            emptyFields.push("GM or Regional/Affiliate VP at M5/R8+ Level");
            
          } 
           if (this.areObjectValuesEmpty(this.state.m4LegalAttorneySpEcoBlVal)) {
            final = false;
            emptyFields.push("M4+ Legal Attorney Supporting ECO/BL");
           
          }
          break;
        case "Lilly International Affiliate/Hub Local Procedure Requirements":
          if (this.areObjectValuesEmpty(this.state.ibuHubGmVal)) {
            final = false;
            emptyFields.push("IBU Hub GM");
            
          } 
           if (this.areObjectValuesEmpty(this.state.associateVpEcoIbuVal)) {
            final = false;
            emptyFields.push("Associate VP or Above, Ethics and Compliance, IBU");
         
          } 
           if (this.areObjectValuesEmpty(this.state.associateVpLegalIbuVal)) {
            final = false;
            emptyFields.push("Associate VP or Above, Legal, IBU");
           
          }
          break;
        case "Global Procedure on Following FRAP":
          if (this.areObjectValuesEmpty(this.state.m4EcoBlVal)) {
            final = false;
            emptyFields.push("M4+ ECO/BL");
       
          } 
           if (this.areObjectValuesEmpty(this.state.m4LegalAttrEcoBlFrapVal)) {
            final = false;
            emptyFields.push("M4+ Legal Attorney Supporting ECO/BL (for FRAP)");
         
          } 
           if (this.areObjectValuesEmpty(this.state.gmFunctionVpr8Val)) {
            final = false;
            emptyFields.push("GM or Functional VP at M5/R8+ Level");
            
          } 
           if (this.areObjectValuesEmpty(this.state.chiefAccOfficerVal)) {
            final = false;
            emptyFields.push("Chief Accounting Officer");
            
          } 
           if (this.areObjectValuesEmpty(this.state.chiefProcOfficerVal)) {
            final = false;
            emptyFields.push("Chief Procurement Officer");
           
          }
          break;
        case "Global Travel and Expense Procedure":
          if (this.areObjectValuesEmpty(this.state.localBusinessOwnerVal)) {
            final = false;
            emptyFields.push("Local Business Owner");
            
          } 
           if (this.areObjectValuesEmpty(this.state.localChiefFinanOwnerVal)) {
            final = false;
            emptyFields.push("Local Chief Financial Officer (CFO)");
            
          } 
           if (this.areObjectValuesEmpty(this.state.seniorDirEnterTravExpenVal)) {
            final = false;
            emptyFields.push("Senior Director, Enterprise Travel and Expense");
           
          } 
           if (this.areObjectValuesEmpty(this.state.seniorVpChiefFinanOffcVal)) {
            final = false;
            emptyFields.push("Senior Vice President and Chief Financial Officer");
           
          }
          break;
        case "Recognition Expense":
          console.log("switch case")
          if (this.areObjectValuesEmpty(this.state.localCfoVal)) {
           
            final = false;
            emptyFields.push("Local CFO");
       
          } 
           if (this.areObjectValuesEmpty(this.state.m5F12BusinessLeaderVal)) {
            final = false;
            emptyFields.push("M5/R12 Business Leader");
            
          }
          break;
        default:
          // Handle other exceptionTypeForApproval values if needed
          break;
      }
    }

    const errMsg = `Please enter the mandatory data for ${emptyFields.join(", ")}.`;

    this.setState({ errMsg,throwErr: !final });

    return final;
  }

  areObjectValuesEmpty(obj: any) {
    return Object.values(obj).every(x => (x === null || x === '' || x === undefined));
  }


  handleTextInputChange = (event: any) => {
    console.log("ecent", [event.target.name], event.target.value)
    this.setState({
      renewalForm: {
        ...this.state.renewalForm, [event.target.name]: event.target.value
      }
    })
  };

  handleexceptionClassificationRadioButtons = (event: any) => {
    console.log("exceptionClassification", event.target.value)
    this.setState({
      renewalForm: {
        ...this.state.renewalForm, exceptionClassification: event.target.value
      }
    });
  }
  handleEcAssociateVpOpRadioButtons = (event: any) => {
    this.setState({
      renewalForm: {
        ...this.state.renewalForm, ecAssociateVpOp: event.target.value
      }
    });
  }
  onSelectProcedure = (selected: any) => {
    this.setState({ procedureNames: [...selected] })
  }
  onSelectBusinessScope = (selected: any) => {
    this.setState({ businessScope: [...selected] })
  }
  onSelectGeographyScope = (selected: any) => {
    this.setState({ geographyScope: [...selected] })
  }

  setAssociateVpEcoIbuInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ assocVpECOLoading: true })
    } else {
      this.setState({ assocVpECOLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, assocVpECOLoading: false });
      }
    }
  };
  setAssociateVpLegalIbuInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ assocVpIBULoading: true })
    } else {
      this.setState({ assocVpIBULoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, assocVpIBULoading: false });
      }
    }
  };
  setChiefAccOfficerInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ chiefAccOffLoading: true })
    } else {
      this.setState({ chiefAccOffLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, chiefAccOffLoading: false });
      }
    }
  };
  setChiefProcOfficerInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ chiefProcOfcLoading: true })
    } else {
      this.setState({ chiefProcOfcLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, chiefProcOfcLoading: false });
      }
    }
  };
  setGmFunctionVpr8InputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ gmFuncVPLoading: true })
    } else {
      this.setState({ gmFuncVPLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, gmFuncVPLoading: false });
      }
    }
  };
  setGmRegionalAffiliateVpInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ gmRegionalLoading: true })
    } else {
      this.setState({ gmRegionalLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, gmRegionalLoading: false });
      }
    }
  };
  setIbuHubGmInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ ibuHubGmLoading: true })
    } else {
      this.setState({ ibuHubGmLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, ibuHubGmLoading: false });
      }
    }
  };
  setLocalBusinessOwnerValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localBusOwnerLoading: true })
    } else {
      this.setState({ localBusOwnerLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localBusOwnerLoading: false });
      }
    }
  };
  setLocalCfoValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localCFOLoading: true })
    } else {
      this.setState({ localCFOLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localCFOLoading: false });
      }
    }
  };
  setLocalChiefFinanOwnerValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localChiefFinOfcLoading: true })
    } else {
      this.setState({ localChiefFinOfcLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localChiefFinOfcLoading: false });
      }
    }
  };
  setM4EcoBlValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4EcoBlLoading: true })
    } else {
      this.setState({ m4EcoBlLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4EcoBlLoading: false });
      }
    }
  };
  setM4LegalAttorneySpEcoBlValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4LegalAttrLoading: true })
    } else {
      this.setState({ m4LegalAttrLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4LegalAttrLoading: false });
      }
    }
  };
  setM4LegalAttrEcoBlFrapValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4LegalAttrFRAPLoading: true })
    } else {
      this.setState({ m4LegalAttrFRAPLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4LegalAttrFRAPLoading: false });
      }
    }
  };
  setM5F12BusinessLeaderValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m5F12BLLoading: true })
    } else {
      this.setState({ m5F12BLLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m5F12BLLoading: false });
      }
    }
  };
  setSeniorDirEnterTravExpenValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ SenrEntrLoading: true })
    } else {
      this.setState({ SenrEntrLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, SenrEntrLoading: false });
      }
    }
  };
  setSeniorVpChiefFinanOffcValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ SeniorVPCFOLoading: true })
    } else {
      this.setState({ SeniorVPCFOLoading: false })
    }
    if (inputValue?.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, SeniorVPCFOLoading: false });
      }
    }
  };

  onAssociateVpEcoIbuSelect = (event: any) => {
    this.setState({ assocVpECOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          associateVpEcoIbuName: event.value,
          associateVpEcoIbuEmail: event.email,
        },
        associateVpEcoIbuVal: event,
      });
      this.setState({ associateVpEcoIbuVal: event });
    } else {
      this.setState({ associateVpEcoIbuVal: { text: "", value: "", email: "" } })
    }
  };
  onAssociateVpLegalIbuSelect = (event: any) => {
    this.setState({ assocVpIBULoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          associateVpLegalIbuName: event.value,
          associateVpLegalIbuEmail: event.email,
        },
        associateVpLegalIbuVal: event,
      });
      this.setState({ associateVpLegalIbuVal: event });
    } else {
      this.setState({ associateVpLegalIbuVal: { text: "", value: "", email: "" } })
    }
  };
  onChiefAccOfficerSelect = (event: any) => {
    this.setState({ chiefAccOffLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          chiefAccOfficerName: event.value,
          chiefAccOfficerEmail: event.email,
        },
        chiefAccOfficerVal: event,
      });
      this.setState({ chiefAccOfficerVal: event });
    } else {
      this.setState({ chiefAccOfficerVal: { text: "", value: "", email: "" } })
    }
  };
  onChiefProcOfficerSelect = (event: any) => {
    this.setState({ chiefProcOfcLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          chiefProcOfficerName: event.value,
          chiefProcOfficerEmail: event.email,
        },
        chiefProcOfficerVal: event,
      });
      this.setState({ chiefProcOfficerVal: event });
    } else {
      this.setState({ chiefProcOfficerVal: { text: "", value: "", email: "" } })
    }
  };
  onGmFunctionVpr8Select = (event: any) => {
    this.setState({ gmFuncVPLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          gmFunctionVpr8Name: event.value,
          gmFunctionVpr8Email: event.email,
        },
        gmFunctionVpr8Val: event,
      });
      this.setState({ gmFunctionVpr8Val: event });
    } else {
      this.setState({ gmFunctionVpr8Val: { text: "", value: "", email: "" } })
    }
  };
  onGmRegionalAffiliateVpSelect = (event: any) => {
    this.setState({ gmRegionalAffiliateVpVal: false, gmRegionalLoading:false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          gmRegionalAffiliateVpName: event.value,
          gmRegionalAffiliateVpEmail: event.email,
        },
        gmRegionalAffiliateVpVal: event,
      });
      this.setState({ gmRegionalAffiliateVpVal: event });
    } else {
      this.setState({ gmRegionalAffiliateVpVal: { text: "", value: "", email: "" } })
    }
  };
  onIbuHubGmSelect = (event: any) => {
    this.setState({ ibuHubGmLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          ibuHubGmName: event.value,
          ibuHubGmEmail: event.email,
        },
        ibuHubGmVal: event,
      });
      this.setState({ ibuHubGmVal: event });
    } else {
      this.setState({ ibuHubGmVal: { text: "", value: "", email: "" } })
    }
  };
  onLocalBusinessOwnerSelect = (event: any) => {
    this.setState({ localBusOwnerLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          localBusinessOwnerName: event.value,
          localBusinessOwnerEmail: event.email,
        },
        localBusinessOwnerVal: event,
      });
      this.setState({ localBusinessOwnerVal: event });
    } else {
      this.setState({ localBusinessOwnerVal: { text: "", value: "", email: "" } })
    }
  };
  onLocalCfoSelect = (event: any) => {
    this.setState({ localCFOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          localCfoName: event.value,
          localCfoEmail: event.email,
        },
        localCfoVal: event,
      });
      this.setState({ localCfoVal: event });
    } else {
      this.setState({ localCfoVal: { text: "", value: "", email: "" } })
    }
  };
  onLocalChiefFinanOwnerSelect = (event: any) => {
    this.setState({ localChiefFinOfcLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          localChiefFinanOwnerName: event.value,
          localChiefFinanOwnerEmail: event.email,
        },
        localChiefFinanOwnerVal: event,
      });
      this.setState({ localChiefFinanOwnerVal: event });
    } else {
      this.setState({ localChiefFinanOwnerVal: { text: "", value: "", email: "" } })
    }
  };
  onM4EcoBlSelect = (event: any) => {
    this.setState({ m4EcoBlLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          m4EcoBlName: event.value,
          m4EcoBlEmail: event.email,
        },
        m4EcoBlVal: event,
      });
      this.setState({ m4EcoBlVal: event });
    } else {
      this.setState({ m4EcoBlVal: { text: "", value: "", email: "" } })
    }
  };
  onM4LegalAttorneySpEcoBlSelect = (event: any) => {
    this.setState({ m4LegalAttrLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          m4LegalAttorneySpEcoBlName: event.value,
          m4LegalAttorneySpEcoBlEmail: event.email,
        },
        m4LegalAttorneySpEcoBlVal: event,
      });
      this.setState({ m4LegalAttorneySpEcoBlVal: event });
    } else {
      this.setState({ m4LegalAttorneySpEcoBlVal: { text: "", value: "", email: "" } })
    }
  };
  onM4LegalAttrEcoBlFrapSelect = (event: any) => {
    this.setState({ m4LegalAttrFRAPLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          m4LegalAttrEcoBlFrapName: event.value,
          m4LegalAttrEcoBlFrapEmail: event.email,
        },
        m4LegalAttrEcoBlFrapVal: event,
      });
      this.setState({ m4LegalAttrEcoBlFrapVal: event });
    } else {
      this.setState({ m4LegalAttrEcoBlFrapVal: { text: "", value: "", email: "" } })
    }
  };
  onM5F12BusinessLeaderSelect = (event: any) => {
    this.setState({ m5F12BLLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          m5F12BusinessLeaderName: event.value,
          m5F12BusinessLeaderEmail: event.email,
        },
        m5F12BusinessLeaderVal: event,
      });
      this.setState({ m5F12BusinessLeaderVal: event });
    } else {
      this.setState({ m5F12BusinessLeaderVal: { text: "", value: "", email: "" } })
    }
  };
  onSeniorDirEnterTravExpenSelect = (event: any) => {
    this.setState({ SenrEntrLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          seniorDirEnterTravExpenName: event.value,
          seniorDirEnterTravExpenEmail: event.email,
        },
        seniorDirEnterTravExpenVal: event,
      });
      this.setState({ seniorDirEnterTravExpenVal: event });
    } else {
      this.setState({ seniorDirEnterTravExpenVal: { text: "", value: "", email: "" } })
    }
  };
  onSeniorVpChiefFinanOffcSelect = (event: any) => {
    this.setState({ SeniorVPCFOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        renewalForm: {
          ...this.state.renewalForm,
          seniorVpChiefFinanOffcName: event.value,
          seniorVpChiefFinanOffcEmail: event.email,
        },
        seniorVpChiefFinanOffcVal: event,
      });
      this.setState({ seniorVpChiefFinanOffcVal: event });
    } else {
      this.setState({ seniorVpChiefFinanOffcVal: { text: "", value: "", email: "" } })
    }
  };
}

export default withRouter(ExceptionRenewal)