import axios from 'axios';

import { AuthProviderService } from '../Services';
import Utils from '../Common/Utils';

class AxiosMiddleware {
    registerInterceptors() {
        const env = Utils.getEnvVars();

        axios.interceptors.request.use(async (config) => {
            if (config.baseURL === env.API_URL) {
                const authTokenModel = await AuthProviderService.getAccessToken();
                config.headers.Authorization = `Bearer ${authTokenModel.accessToken}`;
            }

            return config;
        });
    }
}

const axiosMiddleware = new AxiosMiddleware();
export default axiosMiddleware;