import React from "react";
import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { $getRoot, $insertNodes, CLEAR_EDITOR_COMMAND, EditorState, LexicalEditor } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";
import _ from 'lodash';
import axios from "axios";
import RichTextEditor from "../../Components/LexicalEditor";
import Utils from "../../Common/Utils";
import mammoth from '../../Utilities/mammoth/lib';
import FileUpload from "./FileUpload";
import FileUploadModel from "./FileUploadModel";
import ProcedureTable from "./ProcedureTable";
import { GPTService, QDDocsService } from "../../Services";
import PageHeader from "../../Components/Text/PageHeader";
import MultiAutoComplete from "./MultiAutocomplete";
import SelectModel from "./SelectModel";
import RedButton from "../../Components/Button/RedButton";

interface State {
    editorState: EditorState;
    editorRef: LexicalEditor | null;
    isLoading: boolean;
    isTableLoading: boolean;
    isRTELoading: boolean;
    message: string;
    mammothResult: any;
    files: FileUploadModel[];
    selectedFile: FileUploadModel | null;
    search: string;
    geographies: SelectModel[];
    selectedGeographies: SelectModel[];
}
interface Props { }
class RTTool extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            editorState: Utils.getEditorState(),
            editorRef: null,
            isLoading: false,
            isTableLoading: false,
            isRTELoading: false,
            message: '',
            mammothResult: '',
            files: [],
            selectedFile: null,
            search: '',
            geographies: [],
            selectedGeographies: [],
        };
    }

    async componentDidMount(): Promise<void> {
        const allGeographies = await GPTService.getAllGeographies();
        const countries = allGeographies.map(p => p.countryName).sort();
        const geographies: SelectModel[] = countries.map((country) => {
            return {
                text: country,
                value: country,
            };
        });
        this.setState({ geographies });
    }

    render(): React.ReactNode {
        const { editorState, isLoading, isTableLoading, isRTELoading, message, files, selectedFile, search, geographies, selectedGeographies } = this.state;
        //console.log('mammothResult', this.state.mammothResult);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PageHeader label="Upload Local File" />
                </Grid>
                <Grid item xs={12}>
                    <FileUpload onChange={this.handleFileUpload} />
                </Grid>
                {/* <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <PageHeader label="OR" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PageHeader label="Search Documents From Quality Docs" />
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                                        {/* <Grid item xs={2}>
                                            <TextField type={"text"} size="small" variant="outlined" fullWidth placeholder="Search by procedure"
                                                value={search} onChange={this.handleTextChange} onKeyDown={this.onKeyDown} />
                                        </Grid> */}
                                        {/* <Grid item xs={2}>
                                            <MultiAutoComplete
                                                label="Select geographies"
                                                values={geographies}
                                                selectedValues={selectedGeographies.map(p => p.value)}
                                                onChange={this.handleGeographyChange}
                                            />
                                        </Grid> */}
                                        {/* <Grid item>
                                            <RedButton
                                                label="Search"
                                                onClick={this.handleSearchButtonClick}
                                            />
                                        </Grid> */}
                                        <Grid item>
                                            {isLoading ? <CircularProgress size={20} /> : null}
                                        </Grid>
                                        <Grid item>
                                            {message ? <Typography fontSize={16}>{message}</Typography> : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {isTableLoading ? <Grid container justifyContent="center">
                                        <Grid item>
                                            <CircularProgress size={20} />
                                        </Grid>
                                    </Grid> : <ProcedureTable files={files} selectedFile={selectedFile}
                                        onTableRowClick={this.handleTableRowClick} onDeleteRowClick={this.handleTableDeleteRowClick} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {isRTELoading ? <Grid container justifyContent="center">
                        <Grid item>
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid> : null}
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor id="rttooleditor" editorState={editorState} onChange={this.handleEditorChange} onRefChange={this.handleRefChange}
                        skipStrongStylePatch={true} handleCopyCommand={false} ignoreClickCommand={true} />
                </Grid>
                {/* {mammothResult ? <Grid item xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: mammothResult.value }}></div>
                </Grid> : null} */}
            </Grid>
        );
    }

    handleFileUpload = (file: FileUploadModel) => {
        this.setState(prevState => ({
            files: [...prevState.files, file],
        }));
    }

    handleTableRowClick = async (selectedFile: FileUploadModel) => {
        const { editorRef } = this.state;
        editorRef?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        let isValid = false;

        if (selectedFile.fileStatus === 'LOCAL') {
            const file = selectedFile.file;
            if (file) {
                isValid = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            }

        } else if (selectedFile.fileStatus === 'QDOCS') {
            isValid = true;
        }

        this.setState({ editorState: Utils.getEditorState(), selectedFile, message: isValid ? '' : 'Invalid file type. Only .docx files are supported.', isRTELoading: isValid }, async () => {
            if (isValid) {
                if (selectedFile.fileStatus === 'LOCAL') {
                    const file = selectedFile.file;
                    if (file) {
                        const arrayBuffer = await this.readFileAsArrayBuffer(file);
                        await this.updateEditor(arrayBuffer);
                    }
                } else if (selectedFile.fileStatus === 'QDOCS') {
                    const arrayBuffer = await this.downloadDocument(selectedFile);

                    if (arrayBuffer) {
                        await this.updateEditor(arrayBuffer);
                    }
                }

                this.setState({ isRTELoading: false }, () => {
                    const element = document.getElementById('table');

                    if (element) {
                        const x = element.getBoundingClientRect().x;

                        window.scrollTo({
                            behavior: 'smooth',
                            top: x + 200,
                        });
                    }
                });
            }
        });
    }

    handleTableDeleteRowClick = async (selectedFile: FileUploadModel) => {
        const { editorRef, selectedFile: file } = this.state;

        if (file === selectedFile) {
            editorRef?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }

        const files = this.state.files.filter(file => !(file === selectedFile));
        this.setState({ files });
    }

    handleEditorChange = (editorState: EditorState, index?: number | undefined, editor?: LexicalEditor | undefined) => {
        this.setState({ editorState });
    }

    handleRefChange = (editorRef: any) => {
        this.setState({ editorRef: editorRef.current });
    }

    handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({ search: event.target.value });
    }

    handleGeographyChange = (selected: SelectModel[], targetId?: string | undefined) => {
        this.setState({ selectedGeographies: selected });
    }

    handleSearchButtonClick = () => {
        this.search();
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            this.search();
        }
    }

    downloadDocument = async (selectedFile: FileUploadModel) => {
        if (selectedFile.qDocs?.fileKey) {
            const presignedUrl = await QDDocsService.getDocument(selectedFile.qDocs?.fileKey);

            if (presignedUrl) {
                const response = await axios({ url: presignedUrl, method: 'GET', responseType: 'blob' });
                const blob = new Blob([response.data]);
                return this.readFileAsArrayBuffer(blob);
            }
        }
    }

    updateEditor = async (arrayBuffer: ArrayBuffer) => {
        const { editorRef } = this.state;

        const mammothResult = await mammoth.convertToHtml({ arrayBuffer }, {
            ignoreEmptyParagraphs: false,
        });

        //this.setState({ mammothResult });
        const parser = new DOMParser();
        const dom = parser.parseFromString(`<div>${mammothResult.value}</div>`, 'text/html');

        if (editorRef) {
            editorRef.update(() => {
                const nodes = $generateNodesFromDOM(editorRef, dom);
                // Select the root
                $getRoot().select();

                // Insert them at a selection.
                $insertNodes(nodes);
            });
        }
    }

    readFileAsArrayBuffer = (file: File | Blob): Promise<ArrayBuffer> => {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (loadEvent) => {
                const arrayBuffer: any = loadEvent?.target?.result;
                return resolve(arrayBuffer);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    search = () => {
        const { editorRef, files, selectedGeographies, search } = this.state;

        if (selectedGeographies.length > 0 || search) {
            const localFiles = files.filter(p => p.fileStatus === 'LOCAL');
            editorRef?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);

            this.setState({ isLoading: true, files: localFiles, selectedFile: null, message: '' }, async () => {
                const geographies: string[] = selectedGeographies.map(p => p.value);
                const list = await QDDocsService.searchDocuments({ geography: geographies, procedure_name: search });
                let noProcedureMessage = `Sorry no procedures found`;

                const docs: FileUploadModel[] = [];

                list.forEach((item) => {
                    docs.push({ name: item.fileName, qDocs: item, fileStatus: 'QDOCS' });
                });

                this.setState(prevState => ({
                    files: [...prevState.files, ...docs],
                    isLoading: false,
                    message: list.length > 0 ? '' : noProcedureMessage,
                }));
            });
        }
    }
}

export default RTTool;