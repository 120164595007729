import React, { Component } from 'react'
import gptService from '../../../Services/GPTService';
import moment from 'moment';
import { omit } from 'lodash';
import axios from 'axios';
import { IconType } from '../../../Components/Button/IconButton';
import DataLoader from '../../../Components/Table/DataLoader';
import { Box, CircularProgress, Grid, InputLabel } from '@mui/material';
import SearchFilter from '../../../Components/Search/SearchFilter';
import { RouteComponentProps, withRouter } from 'react-router-dom';


interface Props extends RouteComponentProps<any, any, any> {}
type SortType = {
  key: string;
  direction: string;
}

interface State  {
  templateData: any;
  page: number;
  rows: number;
  gptId?: number;
  search: string;
  searchedData: any
  sortConfig: SortType | null;
  rowTotalCount: number | null;
  searchDebounce: string;
  tab:number;
  isLoading: boolean;
  limit: number;
  order: string;
  orderBy: string;
  status: string;
}

class SubmittedTab extends Component<Props, State> {
  private searchTimeout : number | null = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      templateData: [],
      page: 1,
      rows: 5,
      search: "",
      searchedData: [],
      limit: 5,
      searchDebounce:"",
      isLoading: false,
      order: "",
      orderBy: "",
      tab:2,
      rowTotalCount: 0,
      sortConfig: null,
      status:"Submitted",
    };
  }
  componentDidMount(): void {
    const querySearch = {
      page: this.state.page,
      limit: this.state.limit,
      searchtext: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
      status:"Submitted"
    }
    this.getTemplateData(querySearch);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if ((
      prevState.page !== this.state.page ||
      prevState.limit !== this.state.limit ||
      prevState.searchDebounce !== this.state.searchDebounce
      || prevState.sortConfig  !== this.state.sortConfig)
    ) {
      const querySearch = {
        page: this.state.page,
        limit: this.state.limit,
        searchtext: this.state.search,
        order: this.state.sortConfig?.key,
        orderBy: this.state.sortConfig?.direction,
        status:"Submitted"
      }
      this.getTemplateData(querySearch);
    }
    // if (prevState.search !== this.state.search) { this.searchfield() }
  }

  searchfield = () => {
    const { templateData, search } = this.state
    let searcher = templateData.filter((el: any) =>
     el.templateName.startsWith(search.toString().toUpperCase())) 
      
      if(search.length > 0) {
        console.log(templateData, 'executing');
      this.setState({ searchedData: searcher })
    } else {
      console.log(searcher, 'else'); this.setState({ searchedData: templateData })
    }
  };


  handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ search: event.target.value }, () => {
      console.log("search", this.state.search);
      if (this.state.search.length === 0) {
        this.setState({ searchDebounce: this.state.search });
      }
    })
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.setState({ searchDebounce: this.state.search });
    }
  };

  mappingGeoGraphyData(templateData: any, geoGraphyMappingData: any, count: number) {
    // Iterate over templateDataArray and update properties 
    templateData.forEach((template: { gpId: any; country: any; subregion: any; affiliate: any; }) => {
      const matchingMapping = geoGraphyMappingData.filter((mapping: { gpId: any; }) => mapping.gpId === template.gpId);
      if (matchingMapping.length > 0) {
        const allCountry = matchingMapping.filter(((country: { geographyType: string; }) => country.geographyType === "Country")).map((item: { geographyName: any; }) => item.geographyName).join(',')
        const uniqueCountries = Array.from(new Set(allCountry.split(','))).join('_')
        const allRegions = matchingMapping.filter(((country: { geographyType: string; }) => country.geographyType === "SubRegion")).map((item: { geographyName: any; }) => item.geographyName).join(',')
        const uniqueRegions = Array.from(new Set(allRegions.split(','))).join('_')
        const allAffiliate = matchingMapping.filter(((country: { geographyType: string; }) => country.geographyType === "Affiliate")).map((item: { geographyName: any; }) => item.geographyName).join(',')
        const uniqueAffilaite = Array.from(new Set(allAffiliate.split(','))).join('_')
        template.subregion = uniqueRegions;
        template.country = uniqueCountries;
        template.affiliate = uniqueAffilaite;
      } else {
        template.subregion = "";
        template.country = "";
        template.affiliate = "";
      }
    });

    console.log(" final ==", templateData);

     this.setState({ templateData: templateData,rowTotalCount:count });
  }

  getTemplateData = async (querySearch: any) => {
    try {
      this.setState({ isLoading: true });
      const data = await gptService.getAllWithPagination(querySearch);
      console.log("data", data);
      const formatedData = data?.globalProcedures.map((el) => {
        return { ...el, createdDate: moment(el.createdDate).format("L") , templateName: `${el.templateName}(v${el.versionNumber})`};
      });
      // const filterdData = formatedData.filter(obj => obj.status === "Submitted")
      const omitData = formatedData?.map((el) =>
        omit(el, [
          "headerImageFile",
          "versionNumber",
          "effectiveDate",
          "footer",
          "footerImageFile",
          "basicPrinciples",
          "header",
          "bodyText",
          "gpTemplateFile",
          "gpUniqueId",
          "createdBy",
          "modifyBy",
          "_bodyText",
          "_basicPrinciples",
          "_headerImageFile",
          "_footerImageFile",
          "modifiedDate",
        ])
      );
      console.log("omit", omitData);
      this.mappingGeoGraphyData(omitData, data?.geographyMappings, data?.count!);
      this.setState({ isLoading: false });
      
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message:", error.message);
        return error.message;
      } else {
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }
    }
  };

  onChangePage = (page: number) => {
    if(page == 0)
    {
      this.setState({ page :1 });
    }else{
      this.setState({ page });
    }
  };
  onChangeRow = (row: number) => {
    this.setState({ limit: row });
  };

  clickId = (_fieldClicked: string, record: any) => {
    // window.location.assign(`/view_template/${record.gpId}?mode=view`);
    this.props.history.push(`/view_template/${record.gpId}?mode=view`);
  };

  onActionClick = (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        // window.location.assign(`/view_template/${record.gpId}?mode=edit`);
        this.props.history.push(`/view_template/${record.gpId}?mode=edit`);
        break;
    }
  };

  requestSort = (key: string) => {
    const { sortConfig } = this.state;
    let direction = 'ASC';
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === 'ASC') {
        direction = 'DESC';
      }
    }
    this.setState({ sortConfig: { key, direction } } );
  }

  render() {
    if (this.state.isLoading) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    return (
      <>
      <Grid container spacing={2}>
        <Grid item>
          <InputLabel>Search Name or Procedure Type</InputLabel>
          <SearchFilter value={this.state.search} onChange={this.handleSearch} onKeyDown={this.onKeyDown} />
        </Grid>

      </Grid>
      <DataLoader
          tableData={this.state.templateData}
          actionArray={["Edit"]}
          onChangePage={this.onChangePage}
          onChangeRow={this.onChangeRow}
          onClickLink={this.clickId}
          onClickCheckBox={() => { } }
          onActionClick={this.onActionClick}
          sortConfig={null} 
          rowTotalCount={this.state.rowTotalCount ? this.state.rowTotalCount:0}
          rowsPerPage={this.state.limit}
          tab={this.state.tab}
          page={this.state.page}
          requestSort={this.requestSort}
          
          />
    </>
    )
  }
}

export default withRouter(SubmittedTab);