import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import {
  EditorState,
  UNDO_COMMAND,
  createEditor,
} from "lexical";
import { cloneDeep } from "lodash";
import moment from "moment";
import React, { Component, Context } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DocumentType, FileType } from "../../Common/Enums";
import Utils from "../../Common/Utils";
import RedButton from "../../Components/Button/RedButton";
import WhiteButton from "../../Components/Button/WhiteButton";
import SelectModel from "../../Components/Select/SelectModel";
import PageHeader from "../../Components/Text/PageHeader";
import {
  DocumentConfigModel,
  IBlackTextOption,
  BlackTextOptionModel,
  EmailDTO,
  GlobalProcedureTemplate,
  GlobalProcedureTemplateSection,
} from "../../Models";
import {
  AuthorizationService,
  DocumentService,
  DocxService,
  EmailService,
  LPTService,
  ProcedureFileService,
  RTEditorService,
} from "../../Services";
import FormComponentECO from "./FormComponentECO";
import MyCustomSnackbar from "../../Components/SnackBar/MyCustomSnackbar";
import IconButton from "../../Components/Button/IconButton";
import lptService from "../../Services/LPTService";
import { LocalProcedureTemplate } from "../../Models/LocalProcedureTemplateModel";
import { LocalProcedureTemplateSection } from "../../Models/LocalProcedureTemplateSection";
import FileUploadModelForLP from "../../Models/FileUploadModelForLP";
import GenerateButton from "../../Components/Button/GenerateButton";
import BackLinkButton from "../../Components/Button/BackLinkButton";
import BreadCrumbModel from "../../Components/BreadCrumb/BreadCrumbModel";
import {
  faInfoCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LexicalEditor } from "lexical";
import UnauthorizedRoute from "../../Components/Route/UnauthorizedRoute";
import { RoleContext } from "../../Contexts";
import MultiSelectCheckBox from "../../Components/Select/MultiSelectCheckBox";
import Table from "../../Components/Table/Table";
import gptService from "../../Services/GPTService";
import ActiveExceptionViewPage from "./ActiveExceptionViewPage";
import Constants from "../../Common/Constants";
import '../../Assets/rthtml.css';

const editorStateEmptyJSONString =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
const editorStateContentJSONString =
  '{ "root": { "children": [{ "children": [{ "detail": 0, "format": 0, "mode": "normal", "style": "", "text": "abdc", "type": "text", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "paragraph", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "root", "version": 1 } }';
const editor = createEditor();
const useEmpty = true;
let editorStateJSONString = editorStateEmptyJSONString;
if (!useEmpty) {
  editorStateJSONString = editorStateContentJSONString;
}


type ScreenMode = "view" | "edit";

interface Props extends RouteComponentProps<any, any, any> {
  isUploaded: boolean,
  backButtonPressed: boolean,
}

interface Item {
  value: any;
  text: any;
}

interface State {
  countryAgainstAffilateorSubRegion: string;
  showOtherRationalePopup: boolean;
  isAdminUser: boolean;
  geoGraphyBlankError: boolean;
  showGeoGrahpyPopupValue: string;
  showGeoGrahpyPopup: boolean;
  dropDownData: any[];
  geoGraphydataAgainstgpID: any[];
  setGeographyAllValues: any[];
  setGeographyDefault: any[];
  setGeographyValues: any[];
  defaultSubRegion: any[];
  defaultCountry: any[];
  defaultAffiliate: any[];
  linkExceptionMsg: boolean;
  allAffiliate: any[];
  allCountry: any[];
  allSubRegion: any[];
  activeExceptionButton: boolean;
  createExceptionBack: string;
  ActiveExceptinID: number;
  keyPressed: boolean;
  confirmLimitedPopup: boolean;
  dropDownTargetId: string | undefined;
  sections: LocalProcedureTemplateSection[];
  editTemplate: LocalProcedureTemplate;
  revertBPDialog: boolean;
  revertHTDialog: boolean;
  revertBTDialog: boolean;
  gpId?: number;
  lpId?: number;
  mode: ScreenMode;
  editorState: EditorState;
  afterUpdateButtons: boolean;
  imagePreview: string | null;
  openSuceessMessage: boolean;
  submitDialog: boolean;
  isSentForApproval: boolean;
  reportDownload: boolean;
  isEmailError: boolean;
  draftSuccess: boolean
  templateBlob: Blob;
  templateData: any;
  headerFile: string;
  footerFile: string;
  cantEditBlackTextWarning: boolean;
  headerFileFromDB: string | null;
  isLoading: boolean;
  approverNames: any[];
  ownerNames: any[];
  headerImageValidation: boolean;
  footerImageValidation: boolean;
  createTemplateValidation: boolean;
  templateNameValidation: boolean;
  versionNumberValidation: boolean;
  effectiveDateValidation: boolean;
  procedureTypeValidation: boolean;
  ownerValidation: boolean;
  ownerNameValidation: boolean;
  approverValidation: boolean;
  approverNameValidation: boolean;
  footerValidation: boolean;
  basicPrincValidation: boolean;
  headerValidation: boolean;
  bodyTextValidation: boolean;
  limBlkValid: boolean;
  deviationOn: boolean;
  generateSelectedValue: string;
  tab: number;
  countryArray: string[];
  countryValue: string[];
  breadCrumbs: BreadCrumbModel[];
  activeExceptionbreadCrumbs: BreadCrumbModel[];
  toggleUndo: boolean;
  toogleUndoHeader: boolean;
  toogleUndoBodyText: boolean;
  toggleRedo: boolean;
  toggleRedoHeader: boolean;
  toggleRedoBodyText: boolean;
  limitedOptions: BlackTextOptionModel[];
  limitedSelectOptions: SelectModel[];
  selectedLimitedDropdown: SelectModel;
  selectedLimitedOptions: IBlackTextOption[];
  approverSelectedItems: {};
  ownerSelectedItems: {};
  approverSearchValue: string;
  routeArray: any;
  basicPrincipleHTMLText: string;
  headerHTMLText: string;
  bodyTextHTMLText: string;
  sectionHTMLs: any[];
  attachRows: any[];
  ApproverNameLoading: boolean;
  stepTwoExceptionList: boolean;
  exceptionDropDownList: any;
  exceptionDropDownValue: any;
  exceptionData: any;
  tableRows: any;
  defaultTableRow: any;
  errMsg: string,
  error: boolean,
  dropDownListWithGpID: any;
  throwSuccess: boolean;
  successMsg: string;
  previousSections: any;
  geoGraphyType: string;
  errorMsg: string;
  isError: boolean;
}

class EcoLocalization extends Component<Props, State> {
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  // errorAction: JSX.Element | undefined;
  keypressed1 = true;
  showBlackTextPopup = false;
  constructor(props: Props) {
    super(props);
    const editorState = editor.parseEditorState(editorStateJSONString);
    this.state = {
      countryAgainstAffilateorSubRegion :"",
      showOtherRationalePopup: false,
      isAdminUser: false,
      geoGraphyBlankError: false,
      showGeoGrahpyPopupValue: "",
      showGeoGrahpyPopup: false,
      geoGraphydataAgainstgpID: [],
      linkExceptionMsg: false,
      setGeographyAllValues: [],
      setGeographyValues: [],
      geoGraphyType: "",
      setGeographyDefault: [],
      dropDownData: [],
      defaultSubRegion: [],
      defaultCountry: [],
      defaultAffiliate: [],
      allAffiliate: [],
      allCountry: [],
      allSubRegion: [],
      createExceptionBack: "",
      activeExceptionButton: false,
      ActiveExceptinID: 0,
      keyPressed: false,
      dropDownTargetId: "",
      confirmLimitedPopup: false,
      sections: [],
      previousSections: [],
      attachRows: [],
      countryArray: [],
      countryValue: [],
      templateBlob: new Blob(),
      headerFileFromDB: null,
      revertBPDialog: false,
      revertHTDialog: false,
      revertBTDialog: false,
      approverNames: [],
      approverSelectedItems: {},
      ownerNames: [],
      ownerSelectedItems: {},
      approverSearchValue: "",
      footerFile: "",
      headerFile: "",
      limBlkValid: true,
      openSuceessMessage: false,
      submitDialog: false,
      isSentForApproval: false,
      reportDownload: false,
      isEmailError: false,
      draftSuccess: false,
      editorState: editorState,
      isLoading: true,
      generateSelectedValue: "",
      ApproverNameLoading: false,
      templateData: {},
      cantEditBlackTextWarning: true,
      editTemplate: {
        templateName: "",
        basicPrinciples: "",
        basicPrinciples_blackTextChangeType: "",
        bodyText: "",
        bodyText_blackTextChangeType: "",
        approver: "",
        approverName: "",
        approverEmail: "",
        effectiveDate: null,
        footer: "",
        footerImageFile: "",
        header: "",
        header_blackTextChangeType: "",
        headerImageFile: "",
        owner: "",
        ownerName: "",
        ownerEmail: "",
        procedureType: "",
        versionNumber: "",
        ipFilePath: "",
        _basicPrinciples: Utils.getEditorState(),
        _bodyText: Utils.getEditorState(),
        _headerImageFile: {} as FileUploadModelForLP,
        _footerImageFile: {} as FileUploadModelForLP,
        _ipFilePath: {} as FileUploadModelForLP,
        _header: Utils.getEditorState(),
        createdBy: "",
      },
      mode: "view",
      afterUpdateButtons: false,
      imagePreview: null,
      headerImageValidation: false,
      footerImageValidation: false,
      createTemplateValidation: false,
      approverNameValidation: false,
      approverValidation: false,
      basicPrincValidation: false,
      effectiveDateValidation: false,
      footerValidation: false,
      ownerValidation: false,
      ownerNameValidation: false,
      procedureTypeValidation: false,
      templateNameValidation: false,
      versionNumberValidation: false,
      headerValidation: false,
      bodyTextValidation: false,
      deviationOn: true,
      tab: 1,
      toggleUndo: false,
      toggleRedo: false,
      breadCrumbs: [],
      activeExceptionbreadCrumbs: [
        {
          label: "Local Procedures",
          onClick: () => {
            this.props.history.push("/local_procedures");
          },
        },
        {
          label: "Select Active Exceptions",
        },
      ],
      toggleRedoBodyText: false,
      toggleRedoHeader: false,
      toogleUndoBodyText: false,
      toogleUndoHeader: false,
      limitedOptions: [],
      limitedSelectOptions: [],
      selectedLimitedDropdown: {
        text: "",
        value: ""
      },
      selectedLimitedOptions: [
        {
          id: "LIMITED_OPTION_BASICPRINCIPLE",
          selectedBlackTextOption: {
            text: "Consistent with global requirement",
            value: "1",
          },
          comment: ''
        },
        {
          id: "LIMITED_OPTION_HEADER",
          selectedBlackTextOption: {
            text: "Consistent with global requirement",
            value: "1",
          },
          comment: ''
        },
        {
          id: "LIMITED_OPTION_BODY",
          selectedBlackTextOption: {
            text: "Consistent with global requirement",
            value: "1",
          },
          comment: ''
        },
      ],
      routeArray: [],
      basicPrincipleHTMLText: '',
      bodyTextHTMLText: '',
      headerHTMLText: '',
      sectionHTMLs: [],
      stepTwoExceptionList: false,
      exceptionDropDownList: [],
      exceptionDropDownValue: [],
      exceptionData: [],
      tableRows: [],
      defaultTableRow: [],
      errMsg: "",
      error: false,
      dropDownListWithGpID: [],
      throwSuccess: false,
      successMsg: "",
      errorMsg: "",
      isError: false,
    };
  }


  getExceptionData = async (querySearch: any) => {
    try {
      console.log("this.state.editTemplate.lpId", this.state.editTemplate.lpId);
      console.log("this.state.editTemplate", this.state.editTemplate);
      const getLptExceptionMappingdata = await lptService.getLptExceptionMapping();
      const getGptExceptionMappingdata = await gptService.getGptExceptionMapping();
      const data = await lptService.getAllExceptionDataByPagination(querySearch);
      const dataException = data?.rows?.map((el: any) => {
        return { ...el, createdDate: moment(el.createdDate).format("L") };
      });


      console.log("exceptionFormData", data?.rows);

      const alreadyExceptionPresent: any[] = [];
      let alreadyLptAttachedtothisExceptions: any[] = [];
      const alreadyNotExceptionPresent: any[] = [];

      const activeExceptions: any[] = [];
      const activeExceptionsForDropDown: any[] = [];

      //All the exceptions which from  getGptExceptionMappingdata which are active and
      //lpid of template and exception gpid is same the show in the dropdown


      //In the selected table show all the Exceptions which are present in the getLptExceptionMappingdata.
      alreadyLptAttachedtothisExceptions = getLptExceptionMappingdata.allLptExceptionData.filter((item: { lpId: number | undefined; }) => item.lpId === this.state.editTemplate.lpId);
      if (alreadyLptAttachedtothisExceptions.length > 0 && dataException) {
        for (const dataItem of dataException) {
          const matched = alreadyLptAttachedtothisExceptions.some(mappingItem => mappingItem.gperId === dataItem.gperId);
          if (matched) { alreadyExceptionPresent.push(dataItem); }
        }
      }

      //finding all the active exceptions.
      for (const dataItem of dataException) {
        if (dataItem.status === "Active" && getGptExceptionMappingdata.allGptExceptionData.length > 0) {
          const index = getGptExceptionMappingdata.allGptExceptionData.findIndex((mappingItem: { gperId: number | undefined; }) => mappingItem.gperId === dataItem.gperId);
          if (index !== -1) {
            if (getGptExceptionMappingdata.allGptExceptionData[index].gpId === this.state.editTemplate.gpId) {
              activeExceptions.push(dataItem);
            }
          }
        }
      }

      //now remove the entries from the Active Exceptions which are already there in the
      //alreadyExceptionPresent
      for (const item of activeExceptions) {
        const index = alreadyExceptionPresent.findIndex((mappingItem: { gperId: number | undefined; }) => mappingItem.gperId === item.gperId);
        if (index === -1) {
          activeExceptionsForDropDown.push(item);
        }
      }

      console.log("alreadyNotExceptionPresent=", alreadyNotExceptionPresent);
      console.log("alreadyNotExceptionPresent alreadyExceptionPresent=", alreadyExceptionPresent);

      this.setState({ defaultTableRow: alreadyExceptionPresent })

      const formatedData = activeExceptionsForDropDown?.map((el: any) => {
        if (el.title) {
          return el.title;
        };
      });

      const formatedDataWithIds = activeExceptionsForDropDown?.map((el: any) => {
        if (el.title) {
          return { title: el.title, gperId: el.gperId };
        };
      });

      this.setState({ dropDownListWithGpID: formatedDataWithIds });

      if (alreadyExceptionPresent.length > 0) {
        this.setState({ tableRows: [...alreadyExceptionPresent] });
      }

      const exceptionData = data?.rows.map((el: any) => {
        return { ...el, createdDate: moment(el.createdDate).format("L") };
      });

      console.log("omit all tabs ==", formatedData);

      this.setState({ exceptionData: exceptionData });


      console.log("formatedData==", formatedData);

      if (formatedData && formatedData.length > 0) {
        this.setState({ exceptionDropDownList: formatedData });
      }

    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message:", error.message);
        return error.message;
      } else {
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }

    }
  };

  async componentDidMount(): Promise<void> {


    const isAdminUser = AuthorizationService.isAdminUser(this.context);
    if (isAdminUser) {
      this.setState({ isAdminUser: true });
    }

    this.setState({ exceptionDropDownValue: [] });
    this.setState({ exceptionDropDownList: [] });
    this.setState({ ActiveExceptinID: 0 });
    const querySearch = {
      page: 0,
      limit: 0,
      searchtext: '',
      order: '',
      orderBy: '',
      status: "",
    }

    await this.getExceptionData(querySearch);
    const searchParams = new URLSearchParams(this.props.location.search);

    if (this.props?.location?.state?.stepTwoExceptionList) {
      this.setState({ stepTwoExceptionList: this.props?.location?.state?.stepTwoExceptionList });
    }
    if (this.props?.location?.state?.createExceptionBack) {
      this.setState({ createExceptionBack: this.props?.location?.state?.createExceptionBack });
    }

    if (this.props?.location?.state?.exception) {
      this.setState({
        mode: "edit",
        isLoading: false,
        editTemplate: this.props?.location?.state?.editTemplate!
      })
    } else {
      this.setState({
        lpId: Number(this.props.match.params.id),
        mode: searchParams.get("mode") as ScreenMode,

      }, async () => {
        const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

        if (isReadonlyUser) {
          if (!(this.state.mode.toLocaleLowerCase() === 'edit')) {
            await this.getTemplateDataByLPId();
          }
        } else {
          await this.getTemplateDataByLPId();
        }
      });
    }
    // this.setState({breadCrumbValue: `PCoE_Pro_${this.state.editTemplate.templateName}_${this.state.editTemplate.geoGraphyName}(v${this.state.editTemplate.versionNumber})` })
    // console.log("bread",this.state.breadCrumbValue);
  }

  componentWillUnmount(): void {
    console.log("unmounted")
  }

  async componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    _snapshot?: any
  ): Promise<void> {
    if (this.state.exceptionDropDownValue !== prevState.exceptionDropDownValue) {
      let tableRow: { title: any; }[] = [];
      this.state.exceptionDropDownValue.forEach((item: any) => {
        this.state.exceptionData.forEach((row: { title: any; }) => {
          if (item === row.title) {
            // tableRow.push(row);
          }
        })
      })
      // this.setState({ tableRows: [...this.state.defaultTableRow, ...tableRow] });
      console.log("tableRows=", tableRow);

      //Select the Active Id and set the state.
      if (this.state.exceptionDropDownValue.length) {
        const activeIndex = this.state.dropDownListWithGpID.findIndex((row: any) => this.state.exceptionDropDownValue[this.state.exceptionDropDownValue.length - 1] === row.title)
        this.setState({ ActiveExceptinID: this.state.dropDownListWithGpID[activeIndex].gperId })
        // for (const item of  this.state.exceptionDropDownValue) {
        //   const activeIndex = this.state.dropDownListWithGpID.findIndex((row: any) => item === row.title)
        //   if(activeIndex !== -1)
        //   {
        //     this.setState({ActiveExceptinID : this.state.dropDownListWithGpID[activeIndex].gperId})
        //   }else{
        //     this.setState({ActiveExceptinID : 0})
        //   }
        // }

      } else {
        this.setState({ ActiveExceptinID: 0 })
      }


      // if (prevState.lpId !== this.state.lpId) {
      //   await this.getTemplateDataByLPId();
      // }

      if (this.state.mode === "edit") {
        const dataUpdate = await this.handleUrltoFile(
          this.state.editTemplate.headerImageFile
        );
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(dataUpdate);
        const inputField: any = document.getElementById("headerImageFile");
        inputField.files = dataTransfer.files;
        const footerDataUpdate = await this.handleUrltoFile(
          this.state.editTemplate.footerImageFile
        );
        const dataTransferforFooter = new DataTransfer();
        dataTransferforFooter.items.add(footerDataUpdate);
        const inputFieldForFooter: any =
          document.getElementById("footerImageFile");
        inputFieldForFooter.files = dataTransferforFooter.files;
      }
    }
  }

  getTemplateDataByLPId = async () => {
    try {
      this.setState({ isLoading: true });


      let data: any = null;

      let selectedLimitedOptions: IBlackTextOption[] =
        this.state.selectedLimitedOptions;
      let deviationOn = this.state.deviationOn;
      const backFromList = ['/exception', '/deviation'];

      if (this.props.location.state && backFromList.includes(this.props.location.state.from)) {
        let localProcedure = this.props.location.state.editTemplate;
        localProcedure._basicPrinciples = Utils.getEditorState();
        localProcedure._bodyText = Utils.getEditorState();
        localProcedure._header = Utils.getEditorState();
        data = { localProcedure: this.props.location.state.editTemplate, localProcedureSections: this.props.location.state.sections };
        selectedLimitedOptions = this.props.location.state.selectedLimitedOptions;
        deviationOn = this.props.location.state.deviationOn;
      } else {
        data = await lptService.getByLPId(this.state.lpId!);

      }

      if (data?.localProcedure) {
        const globalData = await gptService.getByGPId(data.localProcedure.gpId!);
        const globalHistoryData = await gptService.getHistoryByGPId(data.localProcedure.gpId!);
        let parentData: GlobalProcedureTemplate;
        let parentSectionsData: GlobalProcedureTemplateSection[];
        if(globalData?.globalProcedureTemplate === null) {
          parentData = globalHistoryData.globalProcedureTemplate;
          parentSectionsData = globalHistoryData?.globalProcedureTemplateSections;
        } else {
          parentData = globalData!.globalProcedureTemplate;
          parentSectionsData = globalData!.globalProcedureTemplateSections;
        }
        console.log("globalData", globalData);
        console.log("globalHistoryData", globalHistoryData);

        //Check for the geography type of the lpt and make the dropdown values accordingly.

        if (data?.localProcedure.geoGraphyType !== "" && data?.localProcedure.gpId) {

          //get the setGeography;

          const geographyData = await gptService.getGeoGraphy(data?.localProcedure.gpId);

          const geoGraphydataAgainstgpID = geographyData.geographyMappingsOfGpId;

          this.setState({ geoGraphydataAgainstgpID });

          console.log("geoGraphydataAgainstgpID=", geoGraphydataAgainstgpID);

          const allGeography = geographyData.allGeography;

          this.setState({ dropDownData: [...allGeography] });
          console.log("allGeography =",allGeography);
          console.log("dropDownData =",allGeography);


          this.setState({ geoGraphyType: data.localProcedure.geoGraphyType });

          //get the geoGraphy here:

          if (data.localProcedure.geoGraphyType === "subregion") {
            //get all the subregion dropdown values.

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const subregionAllEntries: Item[] = Array.from(new Set(allGeography.map((item: { subRegionHubName: any; }) => item.subRegionHubName)))
              .map(subRegionHubName => ({ value: subRegionHubName, text: subRegionHubName }));

            const splitGeography = data.localProcedure.geoGraphyName.split('_');

            const defaultSubRegion = splitGeography.map((item: any) => ({ value: item, text: item }));

            console.log("defaultSubRegion=", defaultSubRegion);

            this.setState({ defaultSubRegion: defaultSubRegion });

            this.setState({ allSubRegion: subregionAllEntries });

            this.setState({ setGeographyAllValues: subregionAllEntries.sort((a, b) => a.text.localeCompare(b.text)) });

            this.setState({ setGeographyValues: defaultSubRegion });

            this.setState({ setGeographyDefault: defaultSubRegion });

            //get all country against the subregion.
            let allCountryAgaintSubRegion: any[] = [];
            const affiliteDropDownOptions = this.filterEntriesForAffiliate(allGeography, defaultSubRegion);
            const affiliteDropDownOptionsUnique = Array.from(new Set(affiliteDropDownOptions.map(item => item.affiliateName)))
              .map((affiliateName) => {
                return {
                  value: affiliateName,
                  text: affiliateName,
                }
              })
      
            const countryAgainstAffiliate = this.filterEntriesForCountry(allGeography, affiliteDropDownOptionsUnique);
            const countryDropDownOptionsUnique = Array.from(new Set(countryAgainstAffiliate.map(item => item.countryName)))
              .map((countryName) => {
                return {
                  value: countryName,
                  text: countryName,
                }
              })

              countryDropDownOptionsUnique.map((item=>{
                allCountryAgaintSubRegion.push(item.value)
              }))

            this.setState({ countryAgainstAffilateorSubRegion: allCountryAgaintSubRegion.join("_") });

          }

          if (data.localProcedure.geoGraphyType === "country") {
            //get all the country for the dropdown.
            const countryAllEntries: Item[] = Array.from(new Set(allGeography.map((item: { countryName: any; }) => item.countryName)))
              .map((countryName) => {
                return {
                  value: countryName,
                  text: countryName,
                };
              });

            const splitGeography = data.localProcedure.geoGraphyName.split('_');
            const defaultCountry = splitGeography.map((item: any) => ({ value: item, text: item }));

            this.setState({ defaultCountry: defaultCountry });
            this.setState({ allCountry: countryAllEntries });

            this.setState({ setGeographyAllValues: countryAllEntries.sort((a, b) => a.text.localeCompare(b.text)) });
            this.setState({ setGeographyDefault: defaultCountry });
            this.setState({ setGeographyValues: defaultCountry });
            this.setState({ countryAgainstAffilateorSubRegion: data.localProcedure.geoGraphyName });

          }

          if (data.localProcedure.geoGraphyType === "affiliate") {
            //get all the affilaite for the dropdown.

            const affiliateAllEntries: Item[] = Array.from(new Set(allGeography.map((item: { affiliateName: any; }) => item.affiliateName)))
              .map((affiliateName) => {
                return {
                  value: affiliateName,
                  text: affiliateName,
                };
              });

            const splitGeography = data.localProcedure.geoGraphyName.split('_');
            const defaultAffiliate = splitGeography.map((item: any) => ({ value: item, text: item }));

            this.setState({ defaultAffiliate: defaultAffiliate });
            this.setState({ allAffiliate: affiliateAllEntries });

            this.setState({ setGeographyAllValues: affiliateAllEntries.sort((a, b) => a.text.localeCompare(b.text)) });
            this.setState({ setGeographyDefault: defaultAffiliate });
            this.setState({ setGeographyValues: defaultAffiliate });

             //get all the country against the affiliate.
             let allCountryAgaintAffiliate: any[] = [];
             const countryAgainstAffiliate = this.filterEntriesForCountry(allGeography, defaultAffiliate);
             const countryDropDownOptionsUnique = Array.from(new Set(countryAgainstAffiliate.map(item => item.countryName)))
               .map((countryName) => {
                 return {
                   value: countryName,
                   text: countryName,
                 }
               })
 
               countryDropDownOptionsUnique.map((item=>{
                 allCountryAgaintAffiliate.push(item.value)
               }))
 
             this.setState({ countryAgainstAffilateorSubRegion: allCountryAgaintAffiliate.join("_") });
 
          }

        }


        if (this.state.mode === 'edit') {
          this.setState({
            breadCrumbs: [
              {
                label: "Local Procedures Dashboard",
                onClick: () => {
                  this.props.history.push("/local_procedures");
                },
              },
              {
                label: 'Edit Procedure'
              },
              {
                label: `PCoE_Pro_${data?.localProcedure.templateName}_${data?.localProcedure.geoGraphyName}(v${data?.localProcedure.versionNumber})`,
              },
            ],
          })
        }
        if (this.state.mode === 'view') {
          this.setState({
            breadCrumbs: [
              {
                label: "Local Procedures Dashboard",
                onClick: () => {
                  this.props.history.push("/local_procedures");
                },
              },
              {
                label: 'View Procedure'
              },
              {
                label: `PCoE_Pro_${data?.localProcedure.templateName}_${data?.localProcedure.geoGraphyName}(v${data?.localProcedure.versionNumber})`,
              },
            ],
          })
        }

        if (data?.localProcedure?.approverName !== null) {
          this.setState({
            approverSelectedItems: {
              text: data?.localProcedure?.approverName,
              value: data?.localProcedure?.approverName,
              email: data?.localProcedure?.approverEmail,
            },
          });
        } else {
          this.setState({
            approverSelectedItems: { text: "", value: "", email: "" },
          });
        }
        if (data?.localProcedure?.ownerName !== null) {
          this.setState({
            ownerSelectedItems: {
              text: data?.localProcedure?.ownerName,
              value: data?.localProcedure?.ownerName,
              email: data?.localProcedure?.ownerEmail,
            },
          });
        } else {
          this.setState({
            ownerSelectedItems: { text: "", value: "", email: "" },
          });
        }
        const limitedOptions = await LPTService.getAllBlackTextOptions();
        const limitedSelectOptions: SelectModel[] = [];

        limitedOptions.forEach((limitedOption) => {
          limitedSelectOptions.push({
            text: limitedOption.type,
            value: limitedOption.id.toString(),
          });
        });

        // setting the comments
        let selectedOptionIndexPrinciple = selectedLimitedOptions.findIndex(
          (p) => p.id === "LIMITED_OPTION_BASICPRINCIPLE"
        );
        let selectedOptionIndexHeader = selectedLimitedOptions.findIndex(
          (p) => p.id === "LIMITED_OPTION_HEADER"
        );
        let selectedOptionIndexBody = selectedLimitedOptions.findIndex(
          (p) => p.id === "LIMITED_OPTION_BODY"
        );

        if (selectedOptionIndexPrinciple !== -1) {
          console.log("selectedOptionIndex==", selectedLimitedOptions[selectedOptionIndexPrinciple]);
          selectedLimitedOptions[selectedOptionIndexPrinciple].comment = data?.localProcedure?.basicPrinciples_blackTextChangeType_Comment;
          this.setState({ selectedLimitedOptions })
        }
        if (selectedOptionIndexHeader !== -1) {
          console.log("selectedOptionIndex==", selectedLimitedOptions[selectedOptionIndexHeader]);
          selectedLimitedOptions[selectedOptionIndexHeader].comment = data?.localProcedure?.header_blackTextChangeType_Comment;
          this.setState({ selectedLimitedOptions })
        }
        if (selectedOptionIndexBody !== -1) {
          console.log("selectedOptionIndex==", selectedLimitedOptions[selectedOptionIndexBody]);
          selectedLimitedOptions[selectedOptionIndexBody].comment = data?.localProcedure?.bodyText_blackTextChangeType_Comment;
          this.setState({ selectedLimitedOptions });
        }


        data.localProcedureSections.forEach((section: any) => {
          const headerBlackTextOption = limitedSelectOptions.find(p => p.text === section.header_blackTextChangeType);
          // const headerBlackTextComment = limitedSelectOptions.find(p => p.text === section.header_blackTextChangeType_Comment);
          const bodyBlackTextOption = limitedSelectOptions.find(p => p.text === section.bodyText_blackTextChangeType);
          // const bodyBlackTextComment = limitedSelectOptions.find(p => p.text === section.bodyText_blackTextChangeType_Comment);

          const headerIndex = selectedLimitedOptions.findIndex((item) => item.id === `LIMITED_OPTION_HEADER_${section.sectionId}`)
          const bodyIndex = selectedLimitedOptions.findIndex((item) => item.id === `LIMITED_OPTION_BODY_${section.sectionId}`)
          if (headerIndex === -1) {
            selectedLimitedOptions.push({
              id: `LIMITED_OPTION_HEADER_${section.sectionId}`,
              selectedBlackTextOption: headerBlackTextOption ? headerBlackTextOption : {
                text: "Consistent with global requirement",
                value: "1"
              },
              comment: section.header_blackTextChangeType_Comment,
            });
          }
          if (bodyIndex === -1) {
            selectedLimitedOptions.push({
              id: `LIMITED_OPTION_BODY_${section.sectionId}`,
              selectedBlackTextOption: bodyBlackTextOption ? bodyBlackTextOption : {
                text: "Consistent with global requirement",
                value: "1"
              },
              comment: section.bodyText_blackTextChangeType_Comment,
            });
          }
        });

        // console.log("selectedLimitedOptions new==", selectedLimitedOptions);

        // let deviationOn = true;
        // const restrictives = selectedLimitedOptions.filter(
        //   (p) => (p.selectedBlackTextOption?.value === "4")
        // );

        // if (restrictives.length > 0) {
        //   deviationOn = false;
        //   this.setState({ deviationOn });
        // }


        if (data?.localProcedure?.geoGraphyName) {
          this.setState({
            countryValue: [data?.localProcedure?.geoGraphyName],
          });
        }
        if (data?.localProcedure?.status === 'Approved' && this.state.mode === 'edit') {
          data.localProcedure.versionNumber = (parseFloat(data.localProcedure.versionNumber) + 0.01).toFixed(2)
        }

        if (data?.localProcedure?.basicPrinciples_blackTextChangeType) {
          const basicPrincipleBlackTextIndex = selectedLimitedOptions.findIndex(
            (p) => p.id === "LIMITED_OPTION_BASICPRINCIPLE"
          );

          const basicPrincipleBlackTextOption = limitedSelectOptions.find(p => p.text === data?.localProcedure?.basicPrinciples_blackTextChangeType);
          selectedLimitedOptions[basicPrincipleBlackTextIndex].selectedBlackTextOption = basicPrincipleBlackTextOption;
        }


        if (data?.localProcedure?.header_blackTextChangeType) {
          const headerBlackTextIndex = selectedLimitedOptions.findIndex(
            (p) => p.id === "LIMITED_OPTION_HEADER"
          );

          const headerBlackTextOption = limitedSelectOptions.find(p => p.text === data?.localProcedure?.header_blackTextChangeType);
          selectedLimitedOptions[headerBlackTextIndex].selectedBlackTextOption = headerBlackTextOption;

        }


        if (data?.localProcedure?.bodyText_blackTextChangeType) {
          const bodyBlackTextIndex = selectedLimitedOptions.findIndex(
            (p) => p.id === "LIMITED_OPTION_BODY"
          );

          const bodyBlackTextOption = limitedSelectOptions.find(p => p.text === data?.localProcedure?.bodyText_blackTextChangeType);
          selectedLimitedOptions[bodyBlackTextIndex].selectedBlackTextOption = bodyBlackTextOption;
        }
        this.setState(
          {
            editTemplate: data.localProcedure as any,
            sections: data.localProcedureSections.sort(
              (a: any, b: any) => (a.sectionId ?? 0) - (b.sectionId ?? 0)
            ),
            templateData: parentData,
            previousSections: parentSectionsData.sort(
              (a: any, b: any) => (a.sectionId ?? 0) - (b.sectionId ?? 0)
            ),
            limitedOptions,
            limitedSelectOptions,
            selectedLimitedOptions,
            isLoading: true,
            deviationOn,
          },
          async () => {
            try {
              console.log("selectedLimitedOptions new==", selectedLimitedOptions);

              let deviationOn = true;
              const restrictives = selectedLimitedOptions.filter(
                (p) => (p.selectedBlackTextOption?.value === "4")
              );

              if (restrictives.length > 0) {
                deviationOn = false;
                this.setState({ deviationOn });
              }
              
              const querySearch = {
                page: 0,
                limit: 0,
                searchtext: '',
                order: '',
                orderBy: '',
                status: "",
              }
              await this.getExceptionData(querySearch);
              const bodyText = await Utils.getHTML(
                data.localProcedure.bodyText, true
              );
              const header = await Utils.getHTML(data.localProcedure.header, true);
              const basicPrinciples = await Utils.getHTML(
                data.localProcedure.basicPrinciples, true
              );

              const dataDeepCopy = cloneDeep(data.localProcedure);
              const _bodyText = Utils.getEditorState(dataDeepCopy.bodyText);
              const _header = Utils.getEditorState(dataDeepCopy.header);
              const _basicPrinciples = Utils.getEditorState(
                dataDeepCopy.basicPrinciples
              );

              let copiedSections: LocalProcedureTemplateSection[] = [];

              data.localProcedureSections.forEach((el: any) => {
                let sectionData = el.bodyText!;
                let sectionHeader = el.header;
                let editorConversion = Utils.getEditorState(sectionData);
                let headerConversion = Utils.getEditorState(sectionHeader);
                copiedSections.push({
                  ...el,
                  _bodyText: editorConversion,
                  _header: headerConversion,
                });
              });

              const sectionHTMLs: any[] = [];

              for await (const el of copiedSections) {
                const sectionHeaderHTMLText = await Utils.getHTML(el.header, true);
                const sectionBodyHTMLText = await Utils.getHTML(el.bodyText, true);

                const sectionHTML = {
                  sectionId: el.sectionId,
                  sectionHeaderHTMLText,
                  sectionBodyHTMLText
                };
                sectionHTMLs.push(sectionHTML);
              }

              this.setState({ sections: copiedSections, bodyTextHTMLText: bodyText, headerHTMLText: header, basicPrincipleHTMLText: basicPrinciples, sectionHTMLs });

              const headerImageType = FileType[data.localProcedure.headerImageFile.split('.').pop().toLocaleUpperCase() as keyof typeof FileType];
              const footerImageType = FileType[data.localProcedure.footerImageFile.split('.').pop().toLocaleUpperCase() as keyof typeof FileType];
              const headerFile = await ProcedureFileService.getFile(
                data!.localProcedure.headerImageFile!,
                headerImageType
              );

              const headerFileData = await this.handleUrltoFile(headerFile!);
              var headerFileModel = await this.buildFileModel(headerFileData, data.localProcedure.versionNumber);


              const footerFile = await ProcedureFileService.getFile(
                data!.localProcedure.footerImageFile!,
                footerImageType
              );
              const footerFileData = await this.handleUrltoFile(footerFile!);
              var footerFileModel = await this.buildFileModel(footerFileData, data.localProcedure.versionNumber);


              this.setState({
                editTemplate: {
                  ...this.state.editTemplate,
                  _bodyText: _bodyText,
                  _basicPrinciples: _basicPrinciples,
                  _header: _header,
                  _headerImageFile: headerFileModel,
                  _footerImageFile: footerFileModel,
                },
                headerFile: headerFile!,
                footerFile: footerFile!,
                isLoading: true,
                
              }, () => {
                this.setState({ isLoading: false });
              });
            } catch (error) {
              this.setState({ isLoading: false });
              console.log("err", error);
            }
          }
        );
        console.log('parentsData',parentData,parentSectionsData)
       
        //this.setState({ isLoading: false });
      }

      // this.setState({ isLoading: false });
      // setTimeout(() => {
      //   this.setState({ isLoading: false });
      // }, 5000)
    } catch (error) {
      this.setState({ isLoading: false });
      if (axios.isAxiosError(error)) {
        console.log("error message:", error.message);
        return error.message;
      } else {
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }
    }
  };

  buildFileModel = async (file: File, versionNumber: string): Promise<FileUploadModelForLP> => {
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = async () => {
        const fileModel = {} as FileUploadModelForLP;

        //fileReader.onload
        const { name, size, type } = file;

        let contentType = type as FileType;
        fileModel.name = name;
        fileModel.sizeInKB = Math.round(size / 1024);
        fileModel.sizeInMB = Math.round(size / 1024 / 1024);
        fileModel.type = contentType;
        fileModel.ext = type.replace(/(.*)\//g, "");
        fileModel.file = file;
        fileModel.fileversion = versionNumber;
        return resolve(fileModel);
      }
    });
  };

  handlePreviewCancelClickHeader = () => {
    this.setState({
      editTemplate: {
        ...this.state.editTemplate,
        _headerImageFile: {} as FileUploadModelForLP,
      },
    });
  };
  handlePreviewCancelClickFooter = () => {
    this.setState({
      editTemplate: {
        ...this.state.editTemplate,
        _footerImageFile: {} as FileUploadModelForLP,
      },
    });
  };

  handleSaveAsDraftSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (this.validationForSaveAsDraft()) {
      this.setState({ isLoading: true });
      const { editTemplate, sections, selectedLimitedOptions } = this.state;
      const basicPrincipleBlackTextChange = selectedLimitedOptions.find(
        (p) => p.id === "LIMITED_OPTION_BASICPRINCIPLE"
      );
      const headerBlackTextChange = selectedLimitedOptions.find(
        (p) => p.id === "LIMITED_OPTION_HEADER"
      );
      const bodyBlackTextChange = selectedLimitedOptions.find(
        (p) => p.id === "LIMITED_OPTION_BODY"
      );
      //Hardcoding the Drafted status;
      editTemplate.status = "Drafted";
      const _basicPrinciples = Utils.getEditorState(
        this.state.editTemplate.basicPrinciples
      );

      this.setState({
        editTemplate: { ...editTemplate, _basicPrinciples: _basicPrinciples },
      });

      editTemplate.bodyText = JSON.stringify(editTemplate._bodyText.toJSON());
      editTemplate.header = JSON.stringify(editTemplate._header.toJSON());
      editTemplate.basicPrinciples = JSON.stringify(
        editTemplate._basicPrinciples.toJSON()
      );
      editTemplate.basicPrinciples_blackTextChangeType =
        basicPrincipleBlackTextChange?.selectedBlackTextOption?.text ?? "";
      editTemplate.header_blackTextChangeType =
        headerBlackTextChange?.selectedBlackTextOption?.text ?? "";
      editTemplate.bodyText_blackTextChangeType =
        bodyBlackTextChange?.selectedBlackTextOption?.text ?? "";
        //for comments
      editTemplate.basicPrinciples_blackTextChangeType_Comment =
      basicPrincipleBlackTextChange?.comment ?? "";
    editTemplate.header_blackTextChangeType_Comment =
      headerBlackTextChange?.comment ?? "";
    editTemplate.bodyText_blackTextChangeType_Comment =
      bodyBlackTextChange?.comment ?? "";

      let copiedSections: LocalProcedureTemplateSection[] = [];

      sections.forEach((el) => {
        const headerBlackTextChange = selectedLimitedOptions.find(
          (p) => p.id === `LIMITED_OPTION_HEADER_${el.sectionId}`
        );
        const bodyBlackTextChange = selectedLimitedOptions.find(
          (p) => p.id === `LIMITED_OPTION_BODY_${el.sectionId}`
        );
        const header_blackTextChangeType =
          headerBlackTextChange?.selectedBlackTextOption?.text ?? "";
        const bodyText_blackTextChangeType =
          bodyBlackTextChange?.selectedBlackTextOption?.text ?? "";
           //for comments
        const header_blackTextChangeType_Comment =
          headerBlackTextChange?.comment ?? "";
        const bodyText_blackTextChangeType_Comment =
          bodyBlackTextChange?.comment ?? "";

        let sectionBodyText = el._bodyText;
        let sectionHeader = el._header;
        const bodyText = JSON.stringify(sectionBodyText.toJSON());
        const header = JSON.stringify(sectionHeader?.toJSON());
        copiedSections.push({ ...el, bodyText: bodyText, header: header, header_blackTextChangeType, bodyText_blackTextChangeType , header_blackTextChangeType_Comment,
          bodyText_blackTextChangeType_Comment});
      });
      this.setState({
        sections: copiedSections,
      });

      const lptresult = await lptService.saveAsDraft({
        localProcedure: editTemplate,
        localProcedureSections: copiedSections,
        localProcedureFiles: [],
      });
      const lpId = lptresult?.localProcedure.lpId;
      if (lpId) {
        const blobData = await this.handleGenerate();
        const s3FileKey = `localproceduretemplate/${lpId}/${DocumentType.GPT_HEADER}/${editTemplate.versionNumber}/${editTemplate._headerImageFile.name}`;
        const s3FileKeyForFooter = `localproceduretemplate/${lpId}/${DocumentType.GPT_FOOTER}/${editTemplate.versionNumber}/${editTemplate._footerImageFile.name}`;
        editTemplate._headerImageFile.s3filekey = s3FileKey;
        editTemplate._footerImageFile.s3filekey = s3FileKeyForFooter;
        const fileName = editTemplate.templateName.replace(/\s/g, "_");
        const file = new File(
          [blobData],
          `${fileName}(v${editTemplate.versionNumber}).docx`,
          {
            type: blobData.type,
          }
        );

        const documents3FileKey = `localproceduretemplate/${lpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate.versionNumber}/${file.name}`;
        this.setState({
          editTemplate: {
            ...editTemplate,
            headerImageFile: s3FileKey,
            footerImageFile: s3FileKeyForFooter,
            ipFilePath: documents3FileKey,
          },
          templateBlob: blobData,
        });
        await ProcedureFileService.uploadHeaderLogoForLP(
          editTemplate._headerImageFile
        );
        await ProcedureFileService.uploadFooterLogoForLP(
          editTemplate._footerImageFile
        );

        editTemplate._ipFilePath = {} as FileUploadModelForLP;
        editTemplate._ipFilePath.s3filekey = documents3FileKey;
        editTemplate._ipFilePath.file = file;
        editTemplate._ipFilePath.name = editTemplate.templateName;
        editTemplate._ipFilePath.fileversion = editTemplate.versionNumber;
        editTemplate._ipFilePath.type = file.type as FileType;
        editTemplate._ipFilePath.ext = "docx";
        editTemplate._ipFilePath.lpId = lpId!;
        editTemplate._ipFilePath.sizeInKB = Math.round(file.size / 1024);
        editTemplate._ipFilePath.sizeInMB = Math.round(file.size / 1024 / 1024);
        await ProcedureFileService.uploadDocumentForLP(editTemplate._ipFilePath);



        this.props.history.push(`/eco_localization/${lpId}?mode=view`, { from: `/eco_localization/${lpId}?mode=edit` })
        // this.props.history.go(0)

        this.setState(
          {
            mode: "view",
            afterUpdateButtons: true,
            lpId: lpId,
            draftSuccess: true
          },
          async () => {
            await this.getTemplateDataByLPId();
          }
        );
      }
    }
  };

  validationChecker = (): boolean => {
    const { editTemplate } = this.state;
    let final: boolean = true;
    if (editTemplate.templateName === "") {
      final = false;
      this.setState({ templateNameValidation: true });
    } else if (Object.keys(editTemplate._headerImageFile).length === 0) {
      final = false;
      this.setState({ headerImageValidation: true });
    } else if (Object.keys(editTemplate._footerImageFile).length === 0) {
      final = false;
      this.setState({
        footerImageValidation: true,
      });
    } else if (editTemplate.procedureType === "") {
      final = false;
      this.setState({ procedureTypeValidation: true });
    } else if (editTemplate.versionNumber === "") {
      final = false;
      this.setState({ versionNumberValidation: true });
    } else if (editTemplate.effectiveDate === null) {
      final = false;
      this.setState({ effectiveDateValidation: true });
    }
    else if (editTemplate.owner === "") {
      final = false;
      this.setState({ ownerValidation: true });
    }
    else if (editTemplate.approver === "") {
      final = false;
      this.setState({ approverValidation: true });
    }
    // else if (editTemplate.owner === "") {
    //   final = false;
    //   this.setState({ ownerValidation: true });
    // }
    // else if (editTemplate.approver === "") {
    //   final = false;
    //   this.setState({ approverValidation: true });
    // }
    // } else if (editTemplate.ownerName === "" || editTemplate.ownerName === null) {
    //   final = false;
    //   this.setState({ ownerNameValidation: true });
    // }
    else if (editTemplate.approverName === "" || editTemplate.approverName === null) {
      final = false;
      this.setState({ approverNameValidation: true });
    }
    else if (editTemplate.footer === "") {
      final = false;
      this.setState({ footerValidation: true });
    } else if (
      Utils.isEditorStateEmpty(editTemplate._basicPrinciples) === true
    ) {
      final = false;
      this.setState({ basicPrincValidation: true });
    } else if (Utils.isEditorStateEmpty(editTemplate._header) === true) {
      final = false;
      this.setState({ headerValidation: true });
    } else if (Utils.isEditorStateEmpty(editTemplate._bodyText) === true) {
      final = false;
      this.setState({ bodyTextValidation: true });
    }
    return final;
  };

  filterEntriesForAffiliate(dropdown: any[], state: any[]) {
    return dropdown.filter((dropdownItem: { subRegionHubName: any; }) => {
      return state.some((subregionItem: { text: any; label: any; }) => {
        return dropdownItem.subRegionHubName === subregionItem.text;
      });
    });
  }

  filterEntriesForCountry(dropdown: any[], state: any[]) {
    return dropdown.filter((dropdownItem: { affiliateName: any; }) => {
      return state.some((affiliateItem: { text: any; label: any; }) => {
        return dropdownItem.affiliateName === affiliateItem.text;
      });
    });
  }


  getValidationOtherRationaleError = () => {
    const { selectedLimitedOptions } = this.state;
    console.log("selectedLimitedOptions=", selectedLimitedOptions);
    let validationOtherRationaleError = false;
    const getOtherRationobj = selectedLimitedOptions.filter((item: any) => item.selectedBlackTextOption.value === "5");
    console.log("selectedLimitedOptions= getOtherRationobj=", getOtherRationobj);
    if (getOtherRationobj.length > 0) {
      getOtherRationobj.map((item: any) => {
        if (item.comment === '') {
          validationOtherRationaleError = true;
          this.setState({ showOtherRationalePopup: true }, () => {
            return validationOtherRationaleError
          });
        }
      })
    }


    return validationOtherRationaleError;
  }

  arraysAreEqual(array1: any[], array2: any[]) {

    // Sort the arrays by their value property to ensure elements are in the same order
    array1.sort((a, b) => (a.value > b.value) ? 1 : -1);
    array2.sort((a, b) => (a.value > b.value) ? 1 : -1);

    // Loop through the elements and compare them.
    if (array1.length === array2.length) {
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].value !== array2[i].value || array1[i].text !== array2[i].text) {
          return false;
        }
      }
    } else {
      return false;
    }

    return true;
  }

  setGeographyValidationAndPayload = () => {
    const { geoGraphyType, geoGraphydataAgainstgpID, defaultSubRegion,
      setGeographyValues, defaultAffiliate, defaultCountry, editTemplate
    } = this.state;

    let updatedName = setGeographyValues.map((item) => item.value).join("_");

    // this.setState({ editTemplate: { ...editTemplate, geoGraphyName: updatedName } });

    let deletedPayload: any[] = [];

    let addedPayload: any[] = [];

    let validationError = false;

    if (setGeographyValues.length === 0) {
      validationError = true;
      this.setState({ geoGraphyBlankError: true });
      return { deletedPayload, addedPayload, updatedName, validationError };
    }


    if (geoGraphyType === "subregion") {
      let otherThanThisLpIdValue: any[] = [];
      console.log("setGeographyValues=", setGeographyValues);



      //Check for the duplicate if any and raise the msg and return the function.

      const allSubRegionDataofGpId = geoGraphydataAgainstgpID.filter((item: any) => item.geographyType === "SubRegion");

      allSubRegionDataofGpId.forEach((row: any) => {
        const findIndex = defaultSubRegion.findIndex((item: any) => item.value === row.geographyName);
        if (findIndex === -1) {
          otherThanThisLpIdValue.push(row);
        }
      })


      //now check if already attached  is selected to some other lpid.
      otherThanThisLpIdValue.forEach((item: any) => {
        const index = setGeographyValues.findIndex((row: any) => row.value === item.geographyName)
        if (index !== -1) {
          validationError = true;
          this.setState({ showGeoGrahpyPopupValue: setGeographyValues[index].value });
          this.setState({ showGeoGrahpyPopup: true });
        }
      })

      //If already set was removed and some are added then make the payload for toBedeleted 
      //And toBeAdded.
      const deleteSubRegion: any[] = [];
      const addedSubRegion: any[] = [];

      const deletedPayload: any[] = [];

      //For what are the deleted subRegion.
      defaultSubRegion.forEach((row: any) => {
        const findIndex = setGeographyValues.findIndex((item: any) => item.value === row.value);
        if (findIndex === -1) {
          deleteSubRegion.push(row);
        }
      })

      //For what are the added subRegion.
      setGeographyValues.forEach((row: any) => {
        const findIndex = defaultSubRegion.findIndex((item: any) => item.value === row.value);
        if (findIndex === -1) {
          addedSubRegion.push(row);
        }
      })


      /* --- Addition paylaod making --- */

      addedSubRegion.forEach((item: any) => {
        let subRegionValue = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "SubRegion"
        }
        addedPayload.push(subRegionValue);
      })


      const affiliteDropDownOptions = this.filterEntriesForAffiliate(this.state.dropDownData, addedSubRegion);
      const affiliteDropDownOptionsUnique = Array.from(new Set(affiliteDropDownOptions.map(item => item.affiliateName)))
        .map((affiliateName) => {
          return {
            value: affiliateName,
            text: affiliateName,
          }
        })

      affiliteDropDownOptionsUnique.forEach((item: any) => {
        let affiliateEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Affiliate"
        }
        addedPayload.push(affiliateEntry);
      })

      const countryAgainstAffiliate = this.filterEntriesForCountry(this.state.dropDownData, affiliteDropDownOptionsUnique);

      const countryDropDownOptionsUnique = Array.from(new Set(countryAgainstAffiliate.map(item => item.countryName)))
        .map((countryName) => {
          return {
            value: countryName,
            text: countryName,
          }
        })

      console.log("affiliteDropDownOptionsUnique=", affiliteDropDownOptionsUnique, "countryDropDownOptionsUnique=", countryDropDownOptionsUnique);

      countryDropDownOptionsUnique.forEach((item: any) => {
        let countryEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Country"
        }
        addedPayload.push(countryEntry);
      })


      /* Deletion payload */

      deleteSubRegion.forEach((item: any) => {
        let subRegionValue = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "SubRegion"
        }
        deletedPayload.push(subRegionValue);
      })

      const affiliteDropDownOptionsDelete = this.filterEntriesForAffiliate(this.state.dropDownData, deleteSubRegion);
      const affiliteDropDownOptionsUniqueDelete = Array.from(new Set(affiliteDropDownOptionsDelete.map(item => item.affiliateName)))
        .map((affiliateName) => {
          return {
            value: affiliateName,
            text: affiliateName,
          }
        })

      affiliteDropDownOptionsUniqueDelete.forEach((item: any) => {
        let affiliateEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Affiliate"
        }
        deletedPayload.push(affiliateEntry);
      })

      const countryAgainstAffiliateDelete = this.filterEntriesForCountry(this.state.dropDownData, affiliteDropDownOptionsUniqueDelete);

      const countryDropDownOptionsUniqueDelete = Array.from(new Set(countryAgainstAffiliateDelete.map(item => item.countryName)))
        .map((countryName) => {
          return {
            value: countryName,
            text: countryName,
          }
        })

      countryDropDownOptionsUniqueDelete.forEach((item: any) => {
        let countryEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Country"
        }
        deletedPayload.push(countryEntry);
      })
      const isSameArray = this.arraysAreEqual(defaultSubRegion, setGeographyValues);

      if (isSameArray) {
        validationError = true;
        this.setState({ isError: true, errorMsg: "No changes made to the geography" });
      }
      return { deletedPayload, addedPayload, updatedName, validationError };
    }

    if (geoGraphyType === "affiliate") {
      let otherThanThisLpIdValue: any[] = [];

      const isSameArray = this.arraysAreEqual(defaultAffiliate, setGeographyValues);

      if (isSameArray) {
        validationError = true;
        this.setState({ isError: true, errorMsg: "No changes made to the geography" });
      }

      //Check for the duplicate if any and raise the msg and return the function.

      const allAffiliateDataofGpId = geoGraphydataAgainstgpID.filter((item: any) => item.geographyType === "Affiliate");

      allAffiliateDataofGpId.forEach((row: any) => {
        const findIndex = defaultAffiliate.findIndex((item: any) => item.value === row.geographyName);
        if (findIndex === -1) {
          otherThanThisLpIdValue.push(row);
        }
      })


      //now check if already attached  is selected.
      otherThanThisLpIdValue.forEach((item: any) => {
        const index = setGeographyValues.findIndex((row: any) => row.value === item.geographyName)
        if (index !== -1) {
          validationError = true;
          this.setState({ showGeoGrahpyPopupValue: setGeographyValues[index].value });
          this.setState({ showGeoGrahpyPopup: true });
        }
      })

      //If already set was removed and some are added then make the payload for toBedeleted 
      //And toBeAdded.
      const deleteAffiliate: any[] = [];
      const addedAffiliate: any[] = [];

      const addedPayload: any[] = [];
      const deletedPayload: any[] = [];

      //For what are the deleted affiliate.
      defaultAffiliate.forEach((row: any) => {
        const findIndex = setGeographyValues.findIndex((item: any) => item.value === row.value);
        if (findIndex === -1) {
          deleteAffiliate.push(row);
        }
      })

      //For what are the added affiliate.
      setGeographyValues.forEach((row: any) => {
        const findIndex = defaultAffiliate.findIndex((item: any) => item.value === row.value);
        if (findIndex === -1) {
          addedAffiliate.push(row);
        }
      })

      /* --- Addition paylaod making --- */

      addedAffiliate.forEach((item: any) => {
        let affiliateValue = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Affiliate"
        }
        addedPayload.push(affiliateValue);
      })

      const countryAgainstAffiliate = this.filterEntriesForCountry(this.state.dropDownData, addedAffiliate);

      const countryDropDownOptionsUnique = Array.from(new Set(countryAgainstAffiliate.map(item => item.countryName)))
        .map((countryName) => {
          return {
            value: countryName,
            text: countryName,
          }
        })

      countryDropDownOptionsUnique.forEach((item: any) => {
        let countryEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Country"
        }
        addedPayload.push(countryEntry);
      })

      /* Deletion payload */

      deleteAffiliate.forEach((item: any) => {
        let countryValue = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Affiliate"
        }
        deletedPayload.push(countryValue);
      })

      const countryAgainstAffiliatedelete = this.filterEntriesForCountry(this.state.dropDownData, deleteAffiliate);

      const countryDropDownOptionsUniquedelete = Array.from(new Set(countryAgainstAffiliatedelete.map(item => item.countryName)))
        .map((countryName) => {
          return {
            value: countryName,
            text: countryName,
          }
        })

      countryDropDownOptionsUniquedelete.forEach((item: any) => {
        let countryEntry = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Country"
        }
        deletedPayload.push(countryEntry);
      })
      return { deletedPayload, addedPayload, updatedName, validationError };
    }

    if (geoGraphyType === "country") {
      console.log("default country =", defaultCountry, "setGeographyValues=", setGeographyValues);

      const isSameArray = this.arraysAreEqual(defaultCountry, setGeographyValues);

      if (isSameArray) {
        validationError = true;
        this.setState({ isError: true, errorMsg: "No changes made to the geography" });
      }

      let otherThanThisLpIdValue: any[] = [];

      //Check for the duplicate if any and raise the msg and return the function.

      const allCountryDataofGpId = geoGraphydataAgainstgpID.filter((item: any) => item.geographyType === "Country");

      allCountryDataofGpId.forEach((row: any) => {
        const findIndex = defaultCountry.findIndex((item: any) => item.value === row.geographyName);
        if (findIndex === -1) {
          otherThanThisLpIdValue.push(row);
        }
      })


      //now check if already attached  is selected;
      otherThanThisLpIdValue.forEach((item: any) => {
        const index = setGeographyValues.findIndex((row: any) => row.value === item.geographyName)
        if (index !== -1) {
          validationError = true;
          this.setState({ showGeoGrahpyPopupValue: setGeographyValues[index].value });
          this.setState({ showGeoGrahpyPopup: true });
        }
      })

      //If already set was removed and some are added then make the payload for toBedeleted 
      //And toBeAdded. 
      const deleteCountry: any[] = [];
      const addedCountry: any[] = [];

      const addedPayload: any[] = [];
      const deletedPayload: any[] = [];

      //For what are the deleted country.
      defaultCountry.forEach((row: any) => {
        const findIndex = setGeographyValues.findIndex((item: any) => item.value === row.value);
        if (findIndex === -1) {
          deleteCountry.push(row);
        }
      })

      //For what are the added country.
      setGeographyValues.forEach((row: any) => {
        const findIndex = defaultCountry.findIndex((item: any) => item.value === row.value);
        if (findIndex === -1) {
          addedCountry.push(row);
        }
      })

      /* --- Addition paylaod making --- */

      addedCountry.forEach((item: any) => {
        let countryValue = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Country"
        }
        addedPayload.push(countryValue);
      })

      /* --- Delete paylaod making --- */

      deleteCountry.forEach((item: any) => {
        let countryValue = {
          "gpId": this.state.editTemplate.gpId,
          "geographyName": item.value,
          "geographyType": "Country"
        }
        deletedPayload.push(countryValue);
      })

      console.log("addedPayload=", addedPayload, "deletedPayload=", deletedPayload);
      return { deletedPayload, addedPayload, updatedName, validationError };
    }
   


    return { deletedPayload, addedPayload, updatedName, validationError };
  }

  handleCreateFormSubmit = async (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event?.preventDefault();
    let { editTemplate, sections, selectedLimitedOptions } = this.state;

    //Check here the validation for the edit setGeography.

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const { deletedPayload, addedPayload, updatedName, validationError } = this.setGeographyValidationAndPayload();
    // editTemplate = { ...editTemplate, geoGraphyName: updatedName };
    // console.log("validationError", validationError)
    // if (validationError) {
    //   console.log("validationError", validationError);
    //   return
    // }

    // console.log("deletedPayload=", deletedPayload, "addedPayload=", addedPayload, "updatedName=", updatedName);

    const querySearch = {
      page: 0,
      limit: 0,
      searchtext: '',
      order: '',
      orderBy: '',
      status: "",
    }
    await this.getExceptionData(querySearch);

    //check  for the other-ration option validation;
    const validationOtherRationaleError = this.getValidationOtherRationaleError();
    if (validationOtherRationaleError) {
      console.log("validationError", validationOtherRationaleError);
      return
    }


    if (this.validationChecker()) {
      this.setState({ isLoading: true });
      const basicPrincipleBlackTextChange = selectedLimitedOptions.find(
        (p) => p.id === "LIMITED_OPTION_BASICPRINCIPLE"
      );
      const headerBlackTextChange = selectedLimitedOptions.find(
        (p) => p.id === "LIMITED_OPTION_HEADER"
      );
      const bodyBlackTextChange = selectedLimitedOptions.find(
        (p) => p.id === "LIMITED_OPTION_BODY"
      );
      editTemplate.bodyText = JSON.stringify(editTemplate._bodyText.toJSON());
      editTemplate.header = JSON.stringify(editTemplate._header.toJSON());
      editTemplate.basicPrinciples = JSON.stringify(
        editTemplate._basicPrinciples.toJSON()
      );

      editTemplate.basicPrinciples_blackTextChangeType =
        basicPrincipleBlackTextChange?.selectedBlackTextOption?.text ?? "";
      editTemplate.header_blackTextChangeType =
        headerBlackTextChange?.selectedBlackTextOption?.text ?? "";
      editTemplate.bodyText_blackTextChangeType =
        bodyBlackTextChange?.selectedBlackTextOption?.text ?? "";
      //for comments
      editTemplate.basicPrinciples_blackTextChangeType_Comment =
        basicPrincipleBlackTextChange?.comment ?? "";
      editTemplate.header_blackTextChangeType_Comment =
        headerBlackTextChange?.comment ?? "";
      editTemplate.bodyText_blackTextChangeType_Comment =
        bodyBlackTextChange?.comment ?? "";


      let copiedSections: LocalProcedureTemplateSection[] = [];

      sections.forEach((el) => {
        const headerBlackTextChange = selectedLimitedOptions.find(
          (p) => p.id === `LIMITED_OPTION_HEADER_${el.sectionId}`
        );
        const bodyBlackTextChange = selectedLimitedOptions.find(
          (p) => p.id === `LIMITED_OPTION_BODY_${el.sectionId}`
        );
        const header_blackTextChangeType =
          headerBlackTextChange?.selectedBlackTextOption?.text ?? "";
        const bodyText_blackTextChangeType =
          bodyBlackTextChange?.selectedBlackTextOption?.text ?? "";
        //for comments
        const header_blackTextChangeType_Comment =
          headerBlackTextChange?.comment ?? "";
        const bodyText_blackTextChangeType_Comment =
          bodyBlackTextChange?.comment ?? "";

        let sectionBodyText = el._bodyText;
        let sectionHeader = el._header;
        const bodyText = JSON.stringify(sectionBodyText.toJSON());
        const header = JSON.stringify(sectionHeader?.toJSON());
        copiedSections.push({
          ...el,
          bodyText: bodyText,
          header: header,
          header_blackTextChangeType,
          bodyText_blackTextChangeType,
          header_blackTextChangeType_Comment,
          bodyText_blackTextChangeType_Comment
        });
      });

      this.setState({
        sections: copiedSections,
      });
      if (editTemplate.lpUniqueId === null && editTemplate.status === 'Approved') {
        editTemplate.lpUniqueId = editTemplate.lpId
      }
      let lptresult;

      if (editTemplate.status === 'Approved') {
        lptresult = await lptService.updatePreviousLPT({
          localProcedure: editTemplate,
          localProcedureSections: copiedSections,
          localProcedureFiles: [],
        });
      } else {
        lptresult = await lptService.update({
          localProcedure: editTemplate,
          localProcedureSections: copiedSections,
          localProcedureFiles: [],
        });
      }
      const lpId = lptresult?.localProcedure.lpId;
      if (lpId) {
        this.setState({ stepTwoExceptionList: false });
        const blobData = await this.handleGenerate();
        const s3FileKey = `localproceduretemplate/${lpId}/${DocumentType.GPT_HEADER}/${editTemplate.versionNumber}/${editTemplate._headerImageFile.name}`;
        const s3FileKeyForFooter = `localproceduretemplate/${lpId}/${DocumentType.GPT_FOOTER}/${editTemplate.versionNumber}/${editTemplate._footerImageFile.name}`;
        editTemplate._headerImageFile.s3filekey = s3FileKey;
        editTemplate._footerImageFile.s3filekey = s3FileKeyForFooter;
        const fileName = editTemplate.templateName.replace(/\s/g, "_");
        const file = new File(
          [blobData],
          `${fileName}(v${editTemplate.versionNumber}).docx`,
          {
            type: blobData.type,
          }
        );

        const documents3FileKey = `localproceduretemplate/${lpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate.versionNumber}/${file.name}`;
        this.setState({
          editTemplate: {
            ...editTemplate,
            headerImageFile: s3FileKey,
            footerImageFile: s3FileKeyForFooter,
            ipFilePath: documents3FileKey,
          },
          templateBlob: blobData,
        });
        await ProcedureFileService.uploadHeaderLogoForLP(
          editTemplate._headerImageFile
        );
        await ProcedureFileService.uploadFooterLogoForLP(
          editTemplate._footerImageFile
        );

        editTemplate._ipFilePath = {} as FileUploadModelForLP;
        editTemplate._ipFilePath.s3filekey = documents3FileKey;
        editTemplate._ipFilePath.file = file;
        editTemplate._ipFilePath.name = editTemplate.templateName;
        editTemplate._ipFilePath.fileversion = editTemplate.versionNumber;
        editTemplate._ipFilePath.type = file.type as FileType;
        editTemplate._ipFilePath.ext = "docx";
        editTemplate._ipFilePath.lpId = lpId!;
        editTemplate._ipFilePath.sizeInKB = Math.round(file.size / 1024);
        editTemplate._ipFilePath.sizeInMB = Math.round(file.size / 1024 / 1024);
        await ProcedureFileService.uploadDocumentForLP(
          editTemplate._ipFilePath
        );
        await lptService.updateFilesPath({
          footerImageFile: s3FileKeyForFooter,
          headerImageFile: s3FileKey,
          ipFilePath: documents3FileKey,
          lpId: lpId,
        });
        this.props.history.push(`/eco_localization/${lpId}?mode=view`, { from: `/eco_localization/${lpId}?mode=edit` })
        // this.props.history.go(0)

        this.setState(
          {
            mode: "view",
            afterUpdateButtons: true,
            lpId: lpId,
            openSuceessMessage: true
          },
          async () => {
            await this.getTemplateDataByLPId();

          }
        );
      }



      this.setState({ isLoading: false });
    }
  };

  handleKey = () => {
    console.log("handleKey is calling");
    this.keypressed1 = true;
  }

  handleTextSelected = (showPopup: boolean) => {
    this.showBlackTextPopup = showPopup;
  }

  handleUrltoFile = async (url: string) => {
    const extension = this.state.editTemplate.headerImageFile.substring(
      this.state.editTemplate.headerImageFile.lastIndexOf(".") + 1
    );
    let blob = await fetch(url).then((r) => r.blob());
    let file = new File(
      [blob],
      this.state.editTemplate.headerImageFile.split("/").pop()!,
      { type: extension }
    );

    return file;
  };

  handleBodyTextEditorChange = (
    _bodyText: EditorState,
    _index: number | undefined,
    editor: LexicalEditor | undefined
  ) => {
    const bodyBlackText = this.state.selectedLimitedOptions.find(
      (p) => p.id === `LIMITED_OPTION_BODY`
    );

    if (bodyBlackText?.selectedBlackTextOption?.value === "1") {
      if (this.showBlackTextPopup) {
        this.setState({ revertBPDialog: true }, () => {
          if (this.keypressed1) {
            editor?.dispatchCommand(UNDO_COMMAND, undefined);
            this.keypressed1 = false;
          }
        });
      } else {
        this.setState({
          editTemplate: {
            ...this.state.editTemplate,
            _bodyText,
          },
        });
      }
    } else {
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          _bodyText,
        },
      });
    }
  };

  // handleBodyTextEditorChange = (
  //   _bodyText: EditorState,
  //   index: number | undefined,
  //   editor: LexicalEditor | undefined
  // ) => {
  //   const bodyBlackText = this.state.selectedLimitedOptions.find(
  //     (p) => p.id === `LIMITED_OPTION_BODY`
  //   );

  //   editor?.update(() => {
  //     const selection = $getSelection();
  //     let Array_data = JSON.parse(JSON.stringify((selection as RangeSelection).getNodes()));
  //     const newArray_data = Array_data.filter((item: { __type: any; }) => item.__type === 'text');
  //     newArray_data.map((item: { __style: string | string[]; }) => {
  //       if (item.__style.includes('RESTRICTED')) {
  //         if (bodyBlackText?.selectedBlackTextOption?.value === "1") {
  //           setTimeout(() => {
  //             this.setState({ revertBPDialog: true }, () => {
  //               if (this.keypressed1) {
  //                 editor.dispatchCommand(UNDO_COMMAND, undefined);
  //                 this.keypressed1 = false;
  //               }
  //             });
  //           }, 200);
  //         }

  //       }
  //     })
  //     console.log("style=", (selection as RangeSelection).style);
  //     if (
  //       ((selection as RangeSelection).style.includes("NON_EDITABLE_TEXT") || (selection as RangeSelection).style === "font-family: DIN-Regular;")
  //     ) {
  //       if (bodyBlackText?.selectedBlackTextOption?.value === "1") {
  //         setTimeout(() => {
  //           this.setState({ revertBPDialog: true }, () => {
  //             if (this.keypressed1) {
  //               editor.dispatchCommand(UNDO_COMMAND, undefined);
  //               this.keypressed1 = false;
  //             }
  //           });
  //         }, 200);
  //       }
  //       else {
  //         this.setState({
  //           editTemplate: {
  //             ...this.state.editTemplate,
  //             _bodyText: _bodyText,
  //           },
  //         });
  //       }
  //     } else {
  //       this.setState({
  //         editTemplate: {
  //           ...this.state.editTemplate,
  //           _bodyText: _bodyText,
  //         },
  //       });
  //     }
  //   });
  // };

  undoChange = () => {
    this.setState({ toggleUndo: !this.state.toggleUndo });
  };
  undoChangeHeader = () => {
    this.setState({ toogleUndoHeader: !this.state.toogleUndoHeader });
  };
  undoChangeBodyText = () => {
    this.setState({ toogleUndoBodyText: !this.state.toogleUndoBodyText });
  };

  redoChange = () => {
    this.setState({ toggleRedo: !this.state.toggleRedo });
  };
  redoChangeHeader = () => {
    this.setState({
      toggleRedoHeader: !this.state.toggleRedoHeader,
      revertHTDialog: false,
    });
  };
  redoChangeBodyText = () => {
    this.setState({
      toggleRedoBodyText: !this.state.toggleRedoBodyText,
      revertBTDialog: false,
    });
  };

  handleBasicPrinciplesChange = (
    _basicPrinciples: EditorState,
    _index: number | undefined,
    editor: LexicalEditor | undefined
  ) => {
    const basicPrincipleBlackText = this.state.selectedLimitedOptions.find(
      (p) => p.id === "LIMITED_OPTION_BASICPRINCIPLE"
    );

    if (basicPrincipleBlackText?.selectedBlackTextOption?.value === "1") {
      if (this.showBlackTextPopup) {
        this.setState({ revertBPDialog: true }, () => {
          if (this.keypressed1) {
            editor?.dispatchCommand(UNDO_COMMAND, undefined);
            this.keypressed1 = false;
          }
        });
      } else {
        this.setState({
          editTemplate: {
            ...this.state.editTemplate,
            _basicPrinciples,
          },
        });
      }
    } else {
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          _basicPrinciples,
        },
      });
    }
  };


  handleTemplateFormChange = (template: LocalProcedureTemplate) => {
    this.setState({
      editTemplate: template,
    });
  };

  handleHeaderEditorChange = (
    _header: EditorState,
    _index: number | undefined,
    editor: LexicalEditor | undefined
  ) => {
    const headerBlackText = this.state.selectedLimitedOptions.find(
      (p) => p.id === "LIMITED_OPTION_HEADER"
    );

    if (headerBlackText?.selectedBlackTextOption?.value === "1") {
      if (this.showBlackTextPopup) {
        this.setState({ revertBPDialog: true }, () => {
          if (this.keypressed1) {
            editor?.dispatchCommand(UNDO_COMMAND, undefined);
            this.keypressed1 = false;
          }
        });
      } else {
        this.setState({
          editTemplate: {
            ...this.state.editTemplate,
            _header,
          },
        });
      }
    } else {
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          _header,
        },
      });
    }
  };

  // handleHeaderEditorChange = (
  //   _header: EditorState,
  //   index: number | undefined,
  //   editor: LexicalEditor | undefined
  // ) => {
  //   const headerBlackText = this.state.selectedLimitedOptions.find(
  //     (p) => p.id === "LIMITED_OPTION_HEADER"
  //   );

  //   editor?.update(() => {
  //     const selection = $getSelection();

  //     let Array_data = JSON.parse(JSON.stringify((selection as RangeSelection).getNodes()));
  //     const newArray_data = Array_data.filter((item: { __type: any; }) => item.__type === 'text');
  //     newArray_data.map((item: { __style: string | string[]; }) => {
  //       if (item.__style.includes('RESTRICTED')) {
  //         if (headerBlackText?.selectedBlackTextOption?.value === "1") {
  //           setTimeout(() => {
  //             this.setState({ revertBPDialog: true }, () => {
  //               if (this.keypressed1) {
  //                 editor.dispatchCommand(UNDO_COMMAND, undefined);
  //                 this.keypressed1 = false;
  //               }
  //             });
  //           }, 200);
  //         }
  //       }
  //     })
  //     console.log("style=", (selection as RangeSelection).style);
  //     if (
  //       ((selection as RangeSelection).style.includes("NON_EDITABLE_TEXT") || (selection as RangeSelection).style === "font-family: DIN-Regular;")
  //     ) {
  //       if (headerBlackText?.selectedBlackTextOption?.value === "1") {
  //         setTimeout(() => {
  //           this.setState({ revertBPDialog: true }, () => {
  //             if (this.keypressed1) {
  //               editor.dispatchCommand(UNDO_COMMAND, undefined);
  //               this.keypressed1 = false;
  //             }
  //           });
  //         }, 200);
  //       }
  //       else {
  //         this.setState({
  //           editTemplate: {
  //             ...this.state.editTemplate,
  //             _header: _header,
  //           },
  //         });
  //       }
  //     } else {
  //       this.setState({
  //         editTemplate: {
  //           ...this.state.editTemplate,
  //           _header: _header,
  //         },
  //       });
  //     }
  //   });
  // };

  onDropDownChange = (selected: SelectModel) => {
    const { editTemplate } = this.state;
    const value = selected.value;
    this.setState({
      editTemplate: { ...editTemplate, procedureType: value },
    });
  };

  handleEffectiveDateChange = (value: Date | null) => {
    this.setState({
      editTemplate: { ...this.state.editTemplate, effectiveDate: value },
    });
  };

  handleSectionBodyTextChange = (
    _bodyText: EditorState,
    index: number,
    editor: LexicalEditor | undefined
  ) => {
    const { sections } = this.state;
    const bodyBlackText = this.state.selectedLimitedOptions.find(
      (p) => p.id === `LIMITED_OPTION_BODY_${index + 1}`
    );

    if (bodyBlackText?.selectedBlackTextOption?.value === "1") {
      if (this.showBlackTextPopup) {
        this.setState({ revertBPDialog: true }, () => {
          if (this.keypressed1) {
            editor?.dispatchCommand(UNDO_COMMAND, undefined);
            this.keypressed1 = false;
          }
        });
      } else {
        const updatedSections = sections.map((section, i) => {
          if (i === index) {
            return { ...section, _bodyText: _bodyText };
          }
          return section;
        });
        this.setState({ sections: updatedSections });
      }
    } else {
      const updatedSections = sections.map((section, i) => {
        if (i === index) {
          return { ...section, _bodyText: _bodyText };
        }
        return section;
      });
      this.setState({ sections: updatedSections });
    }
  };
  // handleSectionBodyTextChange = (
  //   _bodyText: EditorState,
  //   index: number,
  //   editor: LexicalEditor | undefined
  // ) => {
  //   const { sections } = this.state;
  //   const bodyBlackText = this.state.selectedLimitedOptions.find(
  //     (p) => p.id === `LIMITED_OPTION_BODY_${index + 1}`
  //   );

  //   editor?.update(() => {
  //     const selection = $getSelection();

  //     let Array_data = JSON.parse(JSON.stringify((selection as RangeSelection).getNodes()));
  //     const newArray_data = Array_data.filter((item: { __type: any; }) => item.__type === 'text');
  //     newArray_data.map((item: { __style: string | string[]; }) => {
  //       if (item.__style.includes('RESTRICTED')) {
  //         if (bodyBlackText?.selectedBlackTextOption?.value === "1") {
  //           setTimeout(() => {
  //             this.setState({ revertBPDialog: true }, () => {
  //               if (this.keypressed1) {
  //                 editor.dispatchCommand(UNDO_COMMAND, undefined);
  //                 this.keypressed1 = false;
  //               }

  //             });

  //           }, 200);
  //         }
  //       }
  //     })

  //     console.log("style=", (selection as RangeSelection).style);
  //     if (
  //       ((selection as RangeSelection).style.includes("NON_EDITABLE_TEXT") || (selection as RangeSelection).style === "font-family: DIN-Regular;")
  //     ) {
  //       if (bodyBlackText?.selectedBlackTextOption?.value === "1") {
  //         setTimeout(() => {
  //           this.setState({ revertBPDialog: true }, () => {
  //             if (this.keypressed1) {
  //               editor.dispatchCommand(UNDO_COMMAND, undefined);
  //               this.keypressed1 = false;
  //             }

  //           });

  //         }, 200);
  //       } else {
  //         const updatedSections = sections.map((section, i) => {
  //           if (i === index) {
  //             return { ...section, _bodyText: _bodyText };
  //           }
  //           return section;
  //         });
  //         console.log("updatedSect", updatedSections);
  //         this.setState({ sections: updatedSections });
  //       }
  //     } else {
  //       const updatedSections = sections.map((section, i) => {
  //         if (i === index) {
  //           return { ...section, _bodyText: _bodyText };
  //         }
  //         return section;
  //       });
  //       console.log("updatedSect", updatedSections);
  //       this.setState({ sections: updatedSections });
  //     }
  //   });
  // };

  // handleSectionBodyTextChange = (
  //   _bodyText: EditorState,
  //   index: number,
  //   editor: LexicalEditor | undefined
  // ) => {
  //   const { sections } = this.state;
  //   const bodyBlackText = this.state.selectedLimitedOptions.find(
  //     (p) => p.id === `LIMITED_OPTION_BODY_${index + 1}`
  //   );

  //   editor?.update(() => {
  //     const selection = $getSelection();

  //     let Array_data = JSON.parse(JSON.stringify((selection as RangeSelection).getNodes()));
  //     const newArray_data = Array_data.filter((item: { __type: any; }) => item.__type === 'text');
  //     newArray_data.map((item: { __style: string | string[]; }) => {
  //       if (item.__style.includes('RESTRICTED')) {
  //         if (bodyBlackText?.selectedBlackTextOption?.value === "1") {
  //           setTimeout(() => {
  //             this.setState({ revertBPDialog: true }, () => {
  //               if (this.keypressed1) {
  //                 editor.dispatchCommand(UNDO_COMMAND, undefined);
  //                 this.keypressed1 = false;
  //               }

  //             });

  //           }, 200);
  //         }
  //       }
  //     })

  //     console.log("style=", (selection as RangeSelection).style);
  //     if (
  //       ((selection as RangeSelection).style.includes("NON_EDITABLE_TEXT") || (selection as RangeSelection).style === "font-family: DIN-Regular;")
  //     ) {
  //       if (bodyBlackText?.selectedBlackTextOption?.value === "1") {
  //         setTimeout(() => {
  //           this.setState({ revertBPDialog: true }, () => {
  //             if (this.keypressed1) {
  //               editor.dispatchCommand(UNDO_COMMAND, undefined);
  //               this.keypressed1 = false;
  //             }

  //           });

  //         }, 200);
  //       } else {
  //         const updatedSections = sections.map((section, i) => {
  //           if (i === index) {
  //             return { ...section, _bodyText: _bodyText };
  //           }
  //           return section;
  //         });
  //         console.log("updatedSect", updatedSections);
  //         this.setState({ sections: updatedSections });
  //       }
  //     } else {
  //       const updatedSections = sections.map((section, i) => {
  //         if (i === index) {
  //           return { ...section, _bodyText: _bodyText };
  //         }
  //         return section;
  //       });
  //       console.log("updatedSect", updatedSections);
  //       this.setState({ sections: updatedSections });
  //     }
  //   });
  // };

  addFormFields = () => {
    const { sections } = this.state;
    let maxSectionId = 0;

    if (sections.length > 0) {
      const sectionIds = sections.map((p) => p.sectionId as number);
      maxSectionId = Math.max(...sectionIds);
    }

    const sectionsData: LocalProcedureTemplateSection = {
      _bodyText: Utils.getEditorState(),
      _header: Utils.getEditorState(),
      sectionId: maxSectionId + 1,
      header_blackTextChangeType: "1",
      bodyText_blackTextChangeType: "1",
    };

    const newSelectedOptionHeader: IBlackTextOption = {
      id: `LIMITED_OPTION_HEADER_${sectionsData.sectionId}`,
      selectedBlackTextOption: {
        text: "Consistent with global requirement",
        value: "1",
      },
      comment: '',
    };

    const newSelectedOptionBody: IBlackTextOption = {
      id: `LIMITED_OPTION_BODY_${sectionsData.sectionId}`,
      selectedBlackTextOption: {
        text: "Consistent with global requirement",
        value: "1",
      },
      comment: '',
    };

    this.setState((prevState) => ({
      sections: [...prevState.sections, sectionsData],
      selectedLimitedOptions: [
        ...prevState.selectedLimitedOptions,
        newSelectedOptionHeader,
        newSelectedOptionBody,
      ],
    }));
  };

  removeFormFields = (sectionId: number) => {
    this.setState({
      sections: this.state.sections.filter(
        (_el) => _el.sectionId !== sectionId
      ),
      selectedLimitedOptions: this.state.selectedLimitedOptions.filter(
        (_el) => {
          return !(
            _el.id === `LIMITED_OPTION_HEADER_${sectionId}` ||
            _el.id === `LIMITED_OPTION_BODY_${sectionId}`
          );
        }
      ),
    });
  };

  handleHeaderImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      let { editTemplate } = this.state;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      const fileModel = {} as FileUploadModelForLP;

      fileReader.onload = async (_e) => {
        const { name, size, type } = file;
        fileModel.name = name;
        fileModel.sizeInKB = Math.round(size / 1024);
        fileModel.sizeInMB = Math.round(size / 1024 / 1024);
        fileModel.type = type as FileType;
        fileModel.ext = type.replace(/(.*)\//g, "");
        fileModel.file = file;
        fileModel.fileversion = editTemplate.versionNumber;
        this.setState({
          editTemplate: { ...editTemplate, _headerImageFile: fileModel },
        });
      };
    }
  };

  handleFooterImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log("footerfile", file);
      let { editTemplate } = this.state;
      const fileReader = new FileReader();
      const fileModel = {} as FileUploadModelForLP;
      fileReader.readAsDataURL(file);
      fileReader.onload = (_e) => {
        const { name, size, type } = file;
        fileModel.name = name;
        fileModel.sizeInKB = Math.round(size / 1024);
        fileModel.sizeInMB = Math.round(size / 1024 / 1024);
        fileModel.type = type as FileType;
        fileModel.ext = type.replace(/(.*)\//g, "");
        fileModel.file = file;
        fileModel.fileversion = editTemplate.versionNumber;
        this.setState({
          editTemplate: { ...editTemplate, _footerImageFile: fileModel },
        });
      };
    }
  };

  handleSectionHeaderChange = (
    _header: EditorState,
    index: number,
    editor: LexicalEditor | undefined
  ) => {
    const { sections } = this.state;
    const headerBlackText = this.state.selectedLimitedOptions.find(
      (p) => p.id === `LIMITED_OPTION_HEADER_${index + 1}`
    );

    if (headerBlackText?.selectedBlackTextOption?.value === "1") {
      if (this.showBlackTextPopup) {
        this.setState({ revertBPDialog: true }, () => {
          if (this.keypressed1) {
            editor?.dispatchCommand(UNDO_COMMAND, undefined);
            this.keypressed1 = false;
          }
        });
      } else {
        const updatedSections = sections.map((section, i) => {
          if (i === index) {
            return { ...section, _header: _header };
          }
          return section;
        });
        this.setState({ sections: updatedSections });
      }
    } else {
      const updatedSections = sections.map((section, i) => {
        if (i === index) {
          return { ...section, _header: _header };
        }
        return section;
      });
      this.setState({ sections: updatedSections });
    }
  };
  // handleSectionHeaderChange = (
  //   _header: EditorState,
  //   index: number,
  //   editor: LexicalEditor | undefined
  // ) => {
  //   const { sections } = this.state;
  //   const headerBlackText = this.state.selectedLimitedOptions.find(
  //     (p) => p.id === `LIMITED_OPTION_HEADER_${index + 1}`
  //   );

  //   editor?.update(() => {
  //     const selection = $getSelection();

  //     let Array_data = JSON.parse(JSON.stringify((selection as RangeSelection).getNodes()));
  //     const newArray_data = Array_data.filter((item: { __type: any; }) => item.__type === 'text');
  //     newArray_data.map((item: { __style: string | string[]; }) => {
  //       if (item.__style.includes('RESTRICTED')) {
  //         if (headerBlackText?.selectedBlackTextOption?.value === "1") {
  //           setTimeout(() => {
  //             this.setState({ revertBPDialog: true }, () => {
  //               if (this.keypressed1) {
  //                 editor.dispatchCommand(UNDO_COMMAND, undefined);
  //                 this.keypressed1 = false;
  //               }
  //             });
  //           }, 200);
  //         }
  //       }
  //     })

  //     console.log("style=", (selection as RangeSelection).style);
  //     if (
  //       ((selection as RangeSelection).style.includes("NON_EDITABLE_TEXT") || (selection as RangeSelection).style === "font-family: DIN-Regular;")
  //     ) {
  //       if (headerBlackText?.selectedBlackTextOption?.value === "1") {
  //         setTimeout(() => {
  //           this.setState({ revertBPDialog: true }, () => {
  //             if (this.keypressed1) {
  //               editor.dispatchCommand(UNDO_COMMAND, undefined);
  //               this.keypressed1 = false;
  //             }

  //           });

  //         }, 200);
  //       } else {
  //         const updatedSections = sections.map((section, i) => {
  //           if (i === index) {
  //             return { ...section, _header: _header };
  //           }
  //           return section;
  //         });
  //         this.setState({ sections: updatedSections });
  //       }
  //     } else {
  //       const updatedSections = sections.map((section, i) => {
  //         if (i === index) {
  //           return { ...section, _header: _header };
  //         }
  //         return section;
  //       });
  //       this.setState({ sections: updatedSections });
  //     }
  //   });
  // };

  setApproverInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] }, async () => {
      const isData = this.state.approverNames.find(
        (p: any) => p.text === inputValue
      );
      if (inputValue) {
        this.setState({ ApproverNameLoading: true })
      } else {
        this.setState({ ApproverNameLoading: false })
      }
      if (inputValue.length > 3 && isData === undefined) {
        let response = await LPTService.getApprover({
          searchText: inputValue,
        });
        console.log("response", response);
        console.log('isDataa', isData)
        if (response) {
          const approverValues: SelectModel[] = response.map((el) => {
            const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null;
            return {
              text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
              value: `${el.FirstName} ${el.LastName}`,
              email: `${el.EmailAddress}`,
            };
          });

          // Sort the approverValues array based on the 'value' property
          approverValues.sort((a, b) => a.value.localeCompare(b.value));
          console.log('apprval', approverValues)
          this.setState({ approverNames: approverValues, ApproverNameLoading: false });
        }
      }
    });
    console.log("input value", inputValue);

  };

  onApproverNameSelect = (event: any) => {
    this.setState({ ApproverNameLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          approverName: event.value,
          approverEmail: event.email.toLowerCase(),
        },
        approverSelectedItems: event,
      });
      this.setState({ approverSelectedItems: event });
    }
  };

  onOwnerNameSelect = (event: any) => {
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          ownerName: event.value,
          ownerEmail: event.email,
        },
        ownerSelectedItems: event,
      });
      this.setState({ ownerSelectedItems: event });
    }
  };

  setOwnerInputValue = async (inputValue: any) => {
    this.setState({ ownerNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.ownerNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue.length > 3 && isData === undefined) {
      let response = await LPTService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const ownerValues: SelectModel[] = response.map((el) => {
          return {
            text: `${el.FirstName} ${el.LastName} (${el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ ownerNames: ownerValues });
      }
    }
  };

  onSelectChangeRegionHub = (selected: any) => {
    this.setState({ setGeographyDefault: [...selected] });
  }

  FieldsValidationaction = (<></>);

  action = (
    <>
      {/* <Button
        size="small"
        style={{ textDecoration: "underline", color: "#0075A2" }}
        onClick={() => { }}
      >
        VIEW
      </Button> */}
      {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ openSuceessMessage: false, draftSuccess: false });
        }}
      />
    </>
  );

  onSelectChangeAll = (target: any) => {
    console.log("target=", target);
    this.setState({ exceptionDropDownValue: [...target] });
  }

  onChangePage = () => {

  }


  clickId = (_fieldClicked: string, record: any) => {
    // window.open(`/raised_exception/${record.gperId}?mode=view`, '_blank');
    this.setState({ ActiveExceptinID: record.gperId })
  };

  onChangeRow = () => {

  }

  handleAttachException = () => {
    let tableRow: { title: any; }[] = [];
    this.state.exceptionDropDownValue.forEach((item: any) => {
      this.state.exceptionData.forEach((row: { title: any; }) => {
        if (item === row.title) {
          tableRow.push(row);
        }
      })
    })
    this.setState({ tableRows: [...this.state.defaultTableRow, ...tableRow] });
    this.setState({ attachRows: [...tableRow] });
  }

  handleSetLptExceptionMapping = async () => {
    try {


      if (this.state.createExceptionBack === "fromCreate" || this.state.attachRows.length > 0) {
        let filterGspid: { title: any; }[] = [];

        this.state.exceptionDropDownValue.forEach((element: any) => {
          this.state.dropDownListWithGpID.forEach((item: { title: any; }) => {
            if (element === item.title) {
              filterGspid.push(item);
            }
          });
        });

        const payload = filterGspid.map((item: any) => { return { lpId: this.state.editTemplate.lpId, gperId: item.gperId } });


        const data = await lptService.setLptException(payload);

        if (data.success) {

          this.setState({ exceptionDropDownValue: [], linkExceptionMsg: true });
          this.setState({ exceptionDropDownList: [] });
          this.setState({ ActiveExceptinID: 0 });

          // eslint-disable-next-line no-restricted-globals
          this.handleCreateFormSubmit();
        }

      }
      else {
        if (this.state.defaultTableRow.length > 0) {
          this.setState({ exceptionDropDownValue: [], linkExceptionMsg: true });
          this.setState({ exceptionDropDownList: [] });
          this.setState({ ActiveExceptinID: 0 });
          // eslint-disable-next-line no-restricted-globals
          this.handleCreateFormSubmit();
        } else {
          this.setState({ error: true, errMsg: "Please attach exception from active exception dropdown or create the exception manually by `Create Exception` button." });
        }
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ error: true, errMsg: "Error from the database." });
    }
  }

  renderExceptionActivelist = () => {

    console.log("ActiveId=", this.state.tableRows);
    let headerData = [
      {
        key: "title",
        label: "Exception Title",
        linkTo: 'title',
        orderBy: "DESC"
      },

      {
        key: "procedure",
        label: "Procedure Template",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "exceptionTypeForApproval",
        label: "Exception Type",
        linkTo: null,
        orderBy: "DESC"
      }, {
        key: "geographyScopeException",
        label: "Geographic Scope",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "exceptionRequestBy",
        label: "Requested By",
        linkTo: null,
        orderBy: "DESC"
      },

      {
        key: "exceptionCompletedBy",
        label: "Completed By",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "createdDate",
        label: "Created On",
        linkTo: null,
        orderBy: "DESC"
      },
      {
        key: "status",
        label: "Status",
        linkTo: null,
        orderBy: "DESC"
      }
    ]

    return (
      <>
        <Box
          style={{
            marginTop: "0.5em",
          }}
        >

          <MyCustomSnackbar
            message={this.state.errMsg}
            severity="warning"
            actions={this.errorAction}
            open={this.state.error}
          />

          <MyCustomSnackbar
            message={this.state.successMsg}
            severity="success"
            actions={this.actionsuccess}
            open={this.state.throwSuccess}
            onClose={() => {
              this.setState({ throwSuccess: false });
            }}
          />

          <Grid container
            direction={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              justifyContent: "space-between",
            }}>
            <Grid item>
              <Grid container spacing={2} style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <Grid item>
                  <BackLinkButton
                    onClick={() => {
                      this.setState({ stepTwoExceptionList: false, ActiveExceptinID: 0, exceptionDropDownValue: [] }, () => {
                        // window.location.assign(`/eco_localization/${lpId}?mode=edit`);
                        // http://localhost:3000/eco_localization/705?mode=view
                      });
                    }}
                  />
                </Grid>
                <Grid style={{ display: "flex", justifyContent: "flex-start" }} item sx={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-all',
                  maxWidth: '42em'
                }}>
                  <PageHeader label={`Procedure Exception List`} />

                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <WhiteButton label="Cancel" onClick={
                    () => {
                      this.setState({ stepTwoExceptionList: false, ActiveExceptinID: 0, exceptionDropDownValue: [] }, () => {
                        // window.location.assign(`/eco_localization/${lpId}?mode=edit`);
                        // http://localhost:3000/eco_localization/705?mode=view
                      });
                    }
                  } />
                </Grid>

                <Grid item>
                  <RedButton label="Create Exception" onClick={() => {
                    this.handleRedTextEditedFlow()
                    //  this.props.history.push({ pathname: 'exceptionpage', state: { editTemplate, sections, routeArray: arrayOfRoutes, selectedLimitedOptions, deviationOn } });
                  }} />
                </Grid>

                <Grid item>
                  <RedButton label="Link Exception(s) to Procedure" onClick={() => {
                    this.handleSetLptExceptionMapping()
                  }} />
                </Grid>
              </Grid>

            </Grid>


          </Grid>
          <Divider />

          {this.state.tableRows.length > 0 &&
            <>
              <Box style={{ "color": "rgb(93, 93, 93)", "fontWeight": "600", "fontFamily": "Roboto", "fontSize": "16px", "marginTop": "13px", "marginBottom": "25px", "marginLeft": "6px" }}>Below are the attached exceptions to this local procedure:</Box>
              <Table
                data={this.state.tableRows}
                totalRecordsCount={10 || 0}
                headCellData={headerData}
                onChangePage={this.onChangePage}
                onChangeRow={this.onChangeRow}
                sortConfig={null}
                onClickLink={this.clickId}
                tableMode="ActiveException"
              />
            </>}
          <Box sx={{ color: "red", display: "flex ", marginBottom: '2rem', marginTop: '1rem', justifyContent: 'space-between' }}>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputLabel sx={{ fontWeight: "bold" }}>Active Exception List</InputLabel>
                <Box style={{ marginBottom: "7%" }}>
                  <MultiSelectCheckBox
                    options={this.state.exceptionDropDownList}
                    values={this.state.exceptionDropDownValue}
                    id={1}
                    onSelectChange={this.onSelectChangeAll}
                    mode={"setGeoGraphy"}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} style={{ marginTop: 24 }}>
                <RedButton label="Add Exception" onClick={() => {
                  this.handleAttachException();
                  // this.setState({activeExceptionButton:true});
                  // this.props.history.push(`/eco_localization/${this.props.history?.location?.state?.editTemplate?.lpId}/mode=edit`)
                }} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        {this.state.ActiveExceptinID > 0 &&
          <ActiveExceptionViewPage gperId={this.state.ActiveExceptinID} />
        }


      </>


    )
  }

  handleDeviationButton = () => {
    this.setState({ deviationOn: false, tab: 3 });
  };
  handleExceptionButton = () => {
    this.setState({ deviationOn: false, tab: 2 });
  };

  onGenerateButtonActions = async (selected: string) => {

    this.props.history.push({
      pathname: "/eco_doc_viewer",
      state: {
        editTemplate: JSON.parse(JSON.stringify(this.state.editTemplate)),
        templateBlob: this.state.templateBlob,
        generateSelectedValue: selected,
        sections: JSON.parse(JSON.stringify(this.state.sections)),
        lpId: this.state.lpId,
      },
    });

    // creating a clean doc,pdf and storing in s3 so eco can download, approver can view clean doc
    await ProcedureFileService.cleandocument(
      this.state?.editTemplate?.ipFilePath!,
      FileType.DOCX
    )
    const cleanDocPath = this.state?.editTemplate?.ipFilePath!.split('.docx')
    const filePath = cleanDocPath[0] + '_clean.docx'
    console.log("clean doc path", filePath);


    await ProcedureFileService.docx2pdf(filePath, FileType.DOCX)

    // this.props.history.go(0);
  };

  handleSetGeographyChange = (selected: any) => {
    this.setState({ setGeographyValues: [...selected] })
  }

  handleSaveGeography = async () => {
    let { editTemplate } = this.state;
    let lpId = editTemplate.lpId;
    const { deletedPayload, addedPayload, updatedName, validationError } = this.setGeographyValidationAndPayload();
    if (!validationError) {
      //Api to update the geography.
      let lptSaveGeographyResult = await lptService.updateGeography({
        deletedPayload,
        addedPayload,
        updatedName,
        lpId: lpId ? lpId : 0
      });
      console.log("lptSaveGeographyResult=", lptSaveGeographyResult);
      if (lptSaveGeographyResult.success) {
        this.getTemplateDataByLPId();
        // this.setState({editTemplate:{...eitTemplate ,geoGraphyName:updatedName},throwSuccess:true,successMsg:'Geography updated successfully.'})
        this.setState({ throwSuccess: true, successMsg: 'Geography updated successfully.' })
        // this.setState({throwSuccess:true,successMsg:'Geography updated successfully.'})
      }
    }
  }


  errorAction = (
    <>
      <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => {
          this.setState({ error: false });
        }}>
        Ok
      </Button>
    </>
  );

  actionsuccess = (
    <>

      <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        Ok
      </Button>
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ throwSuccess: false });
        }}
      />
    </>
  );
  render() {
    const { mode, editTemplate, previousSections, sections, templateData } = this.state;
    console.log("this.state.stepTwoExceptionList", templateData);
    const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);



    if (isReadonlyUser) {
      if (!(mode && mode.toLocaleLowerCase() === 'view')) {
        return <UnauthorizedRoute />;
      }
    }

    console.log("Data By ID", this.state.editTemplate);
    const headerFileName = this.state.editTemplate.headerImageFile
      .split("/")
      .pop();
    const footerFileName = this.state.editTemplate.footerImageFile
      .split("/")
      .pop();

    if (this.state.isLoading) {
      if (this.state.isLoading && this.state.mode === "edit") {
        return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
      }
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }




    if (this.state.stepTwoExceptionList) {
      // eslint-disable-next-line no-lone-blocks
      { return this.renderExceptionActivelist() }
    }
    return (
      <>
        <MyCustomSnackbar
          message={this.state.successMsg}
          severity="success"
          actions={<></>}
          open={this.state.throwSuccess}
          onClose={() => {
            this.setState({ throwSuccess: false });
          }}
        />

        <MyCustomSnackbar
          message={this.state.errorMsg}
          severity="error"
          actions={<></>}
          open={this.state.isError}
          onClose={() => {
            this.setState({ isError: false });
          }}
        />

        <Dialog open={this.state.showGeoGrahpyPopup}>
          <Grid container>
            <Grid item>
              <DialogTitle color={'red'}>Edit Geography</DialogTitle>
            </Grid>
            <Grid item>
              <FontAwesomeIcon icon={faXmark} size='lg' onClick={() => {
                this.setState({ showGeoGrahpyPopup: false })
              }} style={{ marginTop: 22, marginLeft: 350, color: 'red' }} />
            </Grid>
          </Grid>
          <DialogContent dividers>
            <DialogContentText marginTop={2} color={'black'}>Please Attach some other {this.state.geoGraphyType} ,{this.state.showGeoGrahpyPopupValue} is already attach to some other procedure</DialogContentText>
            <DialogContent>
              <Grid container mt={3} ml={-3}>
                <Grid item mr={2}>
                  <RedButton label='Ok' onClick={() => { this.setState({ showGeoGrahpyPopup: false }) }} />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.geoGraphyBlankError}>
          <Grid container>
            <Grid item>
              <DialogTitle color={'red'}>Edit Geography</DialogTitle>
            </Grid>
            <Grid item>
              <FontAwesomeIcon icon={faXmark} size='lg' onClick={() => {
                this.setState({ geoGraphyBlankError: false })
              }} style={{ marginTop: 22, marginLeft: 300, color: 'red', marginRight: 20 }} />
            </Grid>
          </Grid>
          <DialogContent dividers>
            <DialogContentText marginTop={2} color={'black'}>Geography cannot be empty.</DialogContentText>
            <DialogContent>
              <Grid container mt={3} ml={-3}>
                <Grid item mr={2}>
                  <RedButton label='Ok' onClick={() => { this.setState({ geoGraphyBlankError: false }) }} />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.confirmLimitedPopup}>
          <Grid container>
            <Grid item>
              <DialogTitle color={"red"}>
              Revert to Consistent with global requirement.
              </DialogTitle>
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                icon={faXmark}
                size="lg"
                onClick={() => {
                  this.setState({ confirmLimitedPopup: false });
                }}
                style={{ marginTop: 22, marginLeft: 100, }}
              />
            </Grid>
          </Grid>
          <DialogContent dividers>
            <DialogContentText marginTop={2} color={"black"}>
              Switching back to this selection will revert this
              section's content to the global template's procedure language.
              <br></br>
              <div style={{ color: "red" }}>
                You will lose any changes made to black text and yellow highlighted text.
              </div>
              <p>To proceed select <b>"I understand".</b></p>
              <p>To keep your existing selection and the changes made to this section,
                <br></br> select <b>"Cancel".</b></p>
            </DialogContentText>
            <DialogContent>
              <Grid container mt={3} ml={-3}>
                <Grid item mr={2}>
                  <WhiteButton
                    label="Cancel"
                    onClick={() => {
                      this.setState({ confirmLimitedPopup: false });
                    }}
                  />
                </Grid>
                <Grid item>
                  <RedButton
                    label="I Understand"
                    onClick={() => {
                      this.setState({
                        confirmLimitedPopup: false
                      },
                      );
                      this.handleRevertBlackTextToDefault(this.state.selectedLimitedDropdown, this.state.dropDownTargetId)
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.submitDialog}>
          <Grid
            container
            direction={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              // marginBottom: "1em",
              justifyContent: "space-between"
            }}>
            <Grid item>
              <DialogTitle color={"red"}>
                Do you want to upload a translation?
              </DialogTitle>
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                icon={faXmark}
                size="lg"
                onClick={() => {
                  this.setState({ submitDialog: false });
                }}
                style={{ marginRight: "1em", cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <DialogContent dividers>
            <DialogContentText marginTop={2} color={"black"}>
              Upload the local translation document for this procedure, if applicable.
            </DialogContentText>
            <DialogContentText marginTop={1} color={"black"}>
              This translation will be routed for approval.
            </DialogContentText>
            <DialogContentText marginTop={1} color={"black"}>
              Any previously uploaded translation will be submitted.
            </DialogContentText>
            <DialogContent>
              <Grid container mt={2} ml={-3}>
                <Grid item mr={2}>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="error"
                    style={{
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      this.props.history.push({
                        pathname: `/file_upload`, state: {
                          editTemplate: this.state.editTemplate,
                          lpId: this.state.lpId,
                          // isUploaded: this.props.location.state.isUploaded,
                          // backButtonPressed: this.props.location.state.backButtonPressed,
                        }
                      });

                    }}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item>
                  <RedButton
                    label="No"
                    onClick={this.onNoSelect}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
        </Dialog>




        {

          this.state.linkExceptionMsg ?
            <MyCustomSnackbar
              message="Exception has been linked to the procedure."
              severity="success"
              actions={this.action}
              open={this.state.openSuceessMessage}
              onClose={() => {
                this.setState({ openSuceessMessage: false });
              }}
            /> :

            <MyCustomSnackbar
              message="Local Procedure has been generated successfully."
              severity="success"
              actions={this.action}
              open={this.state.openSuceessMessage}
              onClose={() => {
                this.setState({ openSuceessMessage: false });
              }}
            />
        }
        <MyCustomSnackbar
          message={this.state.errMsg}
          severity="warning"
          actions={this.errorAction}
          open={this.state.error}
        />
        <MyCustomSnackbar
          message="Local Procedure has been drafted successfully."
          severity="success"
          actions={this.action}
          open={this.state.draftSuccess}
          onClose={() => {
            this.setState({ draftSuccess: false });
          }}
        />
        <MyCustomSnackbar
          message="Local Procedure sent for approval successfully."
          severity="success"
          // actions={this.action}
          open={this.state.isSentForApproval}
          onClose={() => {
            this.setState({ isSentForApproval: false });
          }}
        />
        <MyCustomSnackbar
          message="Error submitting procedure."
          severity="warning"
          // actions={this.action}
          open={this.state.isEmailError}
          onClose={() => {
            this.setState({ isEmailError: false });
          }}
        />
        {this.state.mode === "view" && !this.state.isLoading ? (
          // <Box style={{ marginLeft: "1em", marginTop: "0.5em" }}>
          <Box>
            {/* <Grid marginBottom={"2em"} container direction="row">
              <BreadCrumb breadCrumbs={this.state.breadCrumbs} />
            </Grid> */}
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Grid
                container
                direction={"row"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1em",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item>
                      <BackLinkButton
                        onClick={() => {
                          // window.location.assign(
                          //   `/eco_localization/${this.state.lpId}?mode=edit`
                          // );
                          const locationState = this.props.location.state;

                          if (locationState && locationState.from) {
                            window.location.assign(locationState.from);
                            // this.props.history.push(locationState.from);
                          } else {
                            // window.location.assign(`/local_procedures`);
                            this.props.history.push(`/local_procedures`);

                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item xs={10.5}
                      sx={{
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                        // maxWidth: "55em",
                      }}
                    >
                      <PageHeader
                        label={`PCoE_Pro_${this.state.editTemplate.templateName}_${this.state.editTemplate.geoGraphyName}(v${this.state.editTemplate.versionNumber})`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <WhiteButton
                        label="Cancel"
                        onClick={() => {
                          const { state } = this.props.location;
                          if (state && state.from) {

                            this.props.history.push(state.from);
                          } else {
                            this.props.history.push("/local_procedures");
                          }

                          // this.props.history.go(0);
                        }}
                      />
                    </Grid>
                    {this.state.editTemplate.lpUniqueId && (this.state.editTemplate.lpUniqueId !== this.state.editTemplate.lpId) ?
                      <Grid item>
                        <WhiteButton label="Compare" onClick={() => {
                          this.props.history.push({
                            pathname: `/local_document_compare/${this.state.editTemplate.lpId}`,
                            state: {
                              editTemplate: this.state.editTemplate
                            }
                          });
                        }} />
                      </Grid>
                      : null
                    }
                    {!this.state.isLoading && (<Grid item>
                      <RedButton
                        label="Edit"
                        onClick={() => {
                          if (this.state.editTemplate.status === 'Approved') {
                            this.setState({ mode: "edit", editTemplate: { ...this.state.editTemplate, versionNumber: String((parseFloat(this.state.editTemplate.versionNumber) + 0.01).toFixed(2)) } });
                          } else {
                            this.setState({ mode: "edit" });
                          }
                          this.setState({
                            breadCrumbs: [
                              {
                                label: "Local Procedures Dashboard",
                                onClick: () => {
                                  this.props.history.push("/local_procedures");
                                },
                              },
                              {
                                label: 'Edit Procedure'
                              },
                              {
                                label: `PCoE_Pro_${this.state.editTemplate.templateName}_${this.state.editTemplate.geoGraphyName}(v${this.state.editTemplate.versionNumber})`,
                              },
                            ],
                          })

                          this.props.history.push(
                            `/eco_localization/${this.state?.editTemplate.lpId}?mode=edit`,

                            { from: `/eco_localization/${this.state?.editTemplate.lpId}?mode=view` }

                          );
                        }}
                      />
                    </Grid>)}
                    {this.state.editTemplate.status !== "Initiation Pending" &&
                      this.state.editTemplate.status !== "Drafted" && (
                        <>
                          <Grid item>
                            <GenerateButton
                              handleGenerate={this.handleGenerate}
                              onClick={(selected) =>
                                this.onGenerateButtonActions(selected)
                              }
                            />
                          </Grid>
                          <Grid item>
                            <RedButton
                              label="Adherence Report"
                              onClick={() => {
                                this.handleAdherenceReportGenerate();
                                this.setState({ reportDownload: true, isLoading: true });
                              }}
                            />
                          </Grid>
                          {isReadonlyUser ? null : <Grid item>
                            <RedButton label="Submit for Approval" onClick={this.onSubmit} />
                          </Grid>}
                        </>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box>
              <>
                <Grid container mt={2} ml={2}>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Procedure Name
                    </Typography>
                    <Typography>
                      {this.state.editTemplate.templateName}
                    </Typography>
                    <Chip
                      label={headerFileName}
                      avatar={<img src={this.state.headerFile} alt="" />}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Procedure Type
                    </Typography>
                    <Typography>
                      {this.state.editTemplate.procedureType}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Version
                    </Typography>
                    <Typography>
                      {this.state.editTemplate.versionNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Effective Date
                    </Typography>
                    <Typography>
                      {moment(this.state.editTemplate.effectiveDate).format(
                        "ll"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={2} ml={2} mb={1.5}>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Owner
                    </Typography>
                    <Typography>{this.state.editTemplate.owner}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Approver
                    </Typography>
                    <Typography>
                      {this.state.editTemplate.approver}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Footer
                    </Typography>
                    <Typography>{this.state.editTemplate.footer}</Typography>
                    <Chip
                      label={footerFileName}
                      avatar={<img src={this.state.footerFile} alt="" />}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container mt={2} ml={2}>
                  <Grid style={{ width: "99%" }} item>
                    <Typography style={{ fontWeight: "bold" }}>
                      Basic Principles
                    </Typography>
                    <Typography
                      style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }}
                    >
                      <div dangerouslySetInnerHTML={{
                        __html: this.state.basicPrincipleHTMLText
                      }}></div>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={2} ml={2}>
                  <Grid style={{ width: "99%" }} item>
                    <Typography
                      style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }}
                    >
                      <div dangerouslySetInnerHTML={{
                        __html: this.state.headerHTMLText
                      }}></div>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={2} ml={2}>
                  <Grid style={{ width: "99%" }} item>
                    <Typography
                      style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }}
                    >
                      <div dangerouslySetInnerHTML={{
                        __html: this.state.bodyTextHTMLText
                      }}></div>
                    </Typography>
                  </Grid>
                </Grid>
                {this.state.sectionHTMLs.map((_el) => {
                  return (
                    <>
                      <Grid container mt={2} ml={2}>
                        <Grid style={{ width: "99%" }} item>
                          <Typography
                            style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }}
                          >
                            <div dangerouslySetInnerHTML={{
                              __html: _el.sectionHeaderHTMLText
                            }}></div>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container mt={2} ml={2}>
                        <Grid style={{ width: "99%" }} item>
                          <Typography
                            style={{ width: "99%", wordWrap: "break-word", zoom: "130%", lineHeight: 1 }}
                          >
                            <div dangerouslySetInnerHTML={{
                              __html: _el.sectionBodyHTMLText
                            }}></div>
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </>
            </Box>
          </Box>
        ) : !this.state.isLoading && (
          <div>
            {/* <Grid marginBottom={"1em"} container direction="row">
              <BreadCrumb breadCrumbs={this.state.breadCrumbs} />
            </Grid> */}
            {this.state.linkExceptionMsg ?
              <MyCustomSnackbar
                message="Exception has been linked to the procedure."
                severity="success"
                actions={this.action}
                open={this.state.openSuceessMessage}
                onClose={() => {
                  this.setState({ openSuceessMessage: false });
                }}
              /> :
              <MyCustomSnackbar
                message="Local Procedure has been generated successfully."
                severity="success"
                actions={this.action}
                open={this.state.openSuceessMessage}
                onClose={() => {
                  this.setState({ openSuceessMessage: false });
                }}
              />
            }

            <MyCustomSnackbar
              message="Local Procedure has been drafted successfully."
              severity="success"
              actions={this.action}
              open={this.state.draftSuccess}
              onClose={() => {
                this.setState({ draftSuccess: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide logo for Procedure Name."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.headerImageValidation}
              onClose={() => {
                this.setState({ headerImageValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Procedure Name."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.templateNameValidation}
              onClose={() => {
                this.setState({ templateNameValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide logo for Footer."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.footerImageValidation}
              onClose={() => {
                this.setState({ footerImageValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Procedure Type."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.procedureTypeValidation}
              onClose={() => {
                this.setState({ procedureTypeValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Version Number."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.versionNumberValidation}
              onClose={() => {
                this.setState({ versionNumberValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Effective Date."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.effectiveDateValidation}
              onClose={() => {
                this.setState({ effectiveDateValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Owner Title."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.ownerValidation}
              onClose={() => {
                this.setState({ ownerValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please Provide Rationale Comment for the Other - provide rationale."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.showOtherRationalePopup}
              onClose={() => {
                this.setState({ showOtherRationalePopup: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Owner Name."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.ownerNameValidation}
              onClose={() => {
                this.setState({ ownerNameValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Approver Name."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.approverNameValidation}
              onClose={() => {
                this.setState({ approverNameValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Approver Title."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.approverValidation}
              onClose={() => {
                this.setState({ approverValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Footer data."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.footerValidation}
              onClose={() => {
                this.setState({ footerValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Basic Principles data."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.basicPrincValidation}
              onClose={() => {
                this.setState({ basicPrincValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Header data for the procedure."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.headerValidation}
              onClose={() => {
                this.setState({ headerValidation: false });
              }}
            />
            <MyCustomSnackbar
              message="Please provide Body Text data for the procedure."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={this.state.bodyTextValidation}
              onClose={() => {
                this.setState({ bodyTextValidation: false });
              }}
            />
            <Grid
              container
              direction={"row"}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1em",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item>
                    <BackLinkButton
                      onClick={() => {
                        // window.location.assign(
                        //   `/eco_localization/${this.state.lpId}?mode=view`
                        // );
                        const locationState = this.props.location.state;

                        if (locationState && locationState.from) {
                          window.location.assign(locationState.from);
                          // this.props.history.push(locationState.from)
                        } else {
                          // window.location.assign(
                          //   `/local_procedures`
                          // );
                          this.props.history.push(`/local_procedures`)


                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item xs={11}
                    sx={{
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      // maxWidth: "48em",
                    }}
                  >
                    {/* //this is the template header */}
                    <PageHeader
                      label={`PCoE_Pro_${this.state.editTemplate.templateName}_${this.state.editTemplate.geoGraphyName}(v${this.state.editTemplate.versionNumber})`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={0.5}>
                  <Grid item>
                    <WhiteButton
                      label="Open Reference Procedure"
                      onClick={() => {
                        window.open('/proceduretool', '_blank');
                      }}
                    />
                  </Grid>
                 {this.state.isAdminUser && <Grid item>
                    <WhiteButton
                    disabled={this.state.editTemplate.status === "Approved"}
                      label="Save Geography"
                      onClick={() => {
                       this.handleSaveGeography();
                      }}
                    />
                  </Grid>}
                  <Grid item>
                    <WhiteButton
                      label="Cancel"
                      onClick={
                        () => {

                          const { state } = this.props.location;
                          if (state && state.from) {
                            this.props.history.push(state.from);
                          } else {
                            this.props.history.push("/local_procedures");
                          }
                        }

                      }
                    />
                  </Grid>
                  {this.state.deviationOn ? (
                    <Grid item>
                      <WhiteButton
                        label="Save as Draft"
                        onClick={this.handleSaveAsDraftSubmit}
                      />
                    </Grid>
                  ) : (
                    <Grid item>
                      <WhiteButton
                        label="Save as Draft"
                        // onClick={this.handleRedTextEditedFlow}
                        onClick={this.handleSaveAsDraftSubmit}
                      />
                    </Grid>
                  )}

                  {this.state.deviationOn ? (
                    <Grid item>
                      <RedButton
                        label="Generate"
                        onClick={this.handleCreateFormSubmit}
                      />
                    </Grid>
                  ) : (
                    <Grid item>
                      <RedButton
                        label="Generate"
                        onClick={this.handleRedTextEditedFlow}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <MyCustomSnackbar
              message="You can't edit Black Text."
              severity="warning"
              actions={this.FieldsValidationaction}
              open={!this.state.cantEditBlackTextWarning}
              onClose={() => {
                this.setState({ cantEditBlackTextWarning: true });
              }}
            />
            <Divider />
            {/* <Grid container spacing={1} mt={"0.3rem"}>
              <Grid item><Button variant={this.state.tab == 1 ? "contained" : "outlined"} onClick={this.onNotApplicableCick}>Prohibited</Button></Grid>
              <Grid item><Button variant={this.state.tab == 2 ? "contained" : "outlined"} onClick={this.handleExceptionButton}>Less Restrictive</Button></Grid>
              <Grid item><Button variant={this.state.tab == 3 ? "contained" : "outlined"} onClick={this.handleDeviationButton}>More Restrictive</Button></Grid>
            </Grid>
            {this.state.tab != 1 &&
              <Box sx={{ mt: 2 }}>
                <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                <span style={{ color: "#E34236", marginLeft: "5px" }}>
                  Please Note :  Under Less Restrictive and More Restrictive you can edit black text.
                </span>
              </Box>
            } */}
            {this.state.editTemplate._basicPrinciples !== undefined && (
              <>
                <Dialog open={this.state.revertBPDialog}>
                  <Grid container>
                    <Grid item>
                      <DialogTitle color={"red"}>
                        Black Text Cannot Be Edited
                      </DialogTitle>
                    </Grid>
                    <Grid item>
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="lg"
                        onClick={() => {
                          this.setState({ revertBPDialog: false });
                        }}
                        style={{ marginTop: 22, marginLeft: 145 }}
                      />
                    </Grid>
                  </Grid>
                  <DialogContent dividers>
                    <DialogContentText marginTop={2} color={"black"}>
                      You can only edit yellow highlighted text and limited black text.
                      {/* <Box sx={{color:"red"}}>Enter is not allowed for the changable text !</Box> */}
                      {/* <Box sx={{color:"red"}}>Any Prohibited Text Change will leads to previous state !</Box> */}
                      <br></br>

                    </DialogContentText>
                    <DialogContent>
                      <Grid container mt={3} ml={-3}>
                        <Grid item mr={2}>
                          <WhiteButton
                            label="Close"
                            onClick={() => {
                              this.setState({ revertBPDialog: false });
                            }}
                          />
                        </Grid>
                        {/* <Grid item>
                          <RedButton
                            label="Ok"
                            onClick={() => {
                              this.setState({ revertBPDialog: false }, () => {
                                this.forceUpdate();
                              });
                            }}
                          />
                        </Grid> */}
                      </Grid>
                    </DialogContent>
                  </DialogContent>
                </Dialog>

                <Dialog open={this.state.revertHTDialog}>
                  <Grid container>
                    <Grid item>
                      <DialogTitle color="red">
                        {" "}
                        You Can't Edit Black Text{" "}
                      </DialogTitle>
                    </Grid>
                    <Grid item>
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="lg"
                        onClick={() => {
                          this.setState({ revertHTDialog: false });
                        }}
                        style={{ marginTop: 22, marginLeft: 290 }}
                      />
                    </Grid>
                  </Grid>
                  <DialogContent dividers>
                    <DialogContentText marginTop={2} color={"black"}>
                      ECO can update yellow text and limited black text only and
                      not black text, please try updating the yellow text and
                      limited black text again.
                    </DialogContentText>
                    <DialogContent>
                      <Grid container mt={3} ml={-3}>
                        <Grid item mr={2}>
                          <Button
                            size="medium"
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              this.setState({ revertHTDialog: false });
                            }}
                          >
                            Close
                          </Button>
                        </Grid>
                        <Grid item>
                          <RedButton
                            label="Ok"
                            onClick={() => {
                              this.setState({ revertHTDialog: false });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </DialogContent>
                </Dialog>
                <Dialog open={this.state.revertBTDialog}>
                  <Grid container>
                    <Grid item>
                      <DialogTitle color={"red"}>
                        You Can't Edit Black Text
                      </DialogTitle>
                    </Grid>
                    <Grid item>
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="lg"
                        onClick={() => {
                          this.setState({ revertBTDialog: false });
                        }}
                        style={{ marginTop: 22, marginLeft: 290 }}
                      />
                    </Grid>
                  </Grid>
                  <DialogContent dividers>
                    <DialogContentText marginTop={2} color={"black"}>
                      ECO can update yellow text and limited black text only and
                      not black text, please try updating the yellow text and
                      limited black text again.
                    </DialogContentText>
                    <DialogContent>
                      <Grid container mt={3} ml={-3}>
                        <Grid item mr={2}>
                          <Button
                            size="medium"
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              this.setState({ revertBTDialog: false });
                            }}
                          >
                            Close
                          </Button>
                        </Grid>
                        <Grid item>
                          <RedButton
                            label="Ok"
                            onClick={() => {
                              this.setState({ revertBTDialog: false });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </DialogContent>
                </Dialog>
                <FormComponentECO
                 countryAgainstAffilateorSubRegion  ={this.state.countryAgainstAffilateorSubRegion}
                  isAdminUser={this.state.isAdminUser}
                  handleSetGeographyChange={this.handleSetGeographyChange}
                  setGeographyAllValues={this.state.setGeographyAllValues}
                  setGeographyDefault={this.state.setGeographyDefault}
                  setGeographyValues={this.state.setGeographyValues}
                  handleKey={this.handleKey}
                  onHeaderCancel={this.handlePreviewCancelClickHeader}
                  onFooterCancel={this.handlePreviewCancelClickFooter}
                  toggleUndo={this.state.toggleUndo}
                  toggleUndoHeader={this.state.toogleUndoHeader}
                  toggleRedo={this.state.toggleRedo}
                  toggleRedoHeader={this.state.toggleRedoHeader}
                  toggleRedoBodyText={this.state.toggleRedoBodyText}
                  toggleUndoBodyText={this.state.toogleUndoBodyText}
                  template={this.state.editTemplate}
                  imageUrlHeader={this.state.headerFile}
                  DeviationOn={this.state.deviationOn}
                  imageUrlFooter={this.state.footerFile}
                  sections={this.state.sections}
                  editorState={this.state.editorState}
                  onHeaderEditorChange={this.handleHeaderEditorChange}
                  onSectionHeaderChange={this.handleSectionHeaderChange}
                  onTemplateChange={this.handleTemplateFormChange}
                  onBodyTextEditorChange={this.handleBodyTextEditorChange}
                  onBasicPrinciplesEditorChange={
                    this.handleBasicPrinciplesChange
                  }
                  onSectionBodyTextChange={this.handleSectionBodyTextChange}
                  onEffectiveDateChange={this.handleEffectiveDateChange}
                  onProcedureTypeChange={this.onDropDownChange}
                  onFooterImageUpload={this.handleFooterImage}
                  onHeaderImageUpload={this.handleHeaderImage}
                  onBodyTextAdd={this.addFormFields}
                  onBodyTextRemove={this.removeFormFields}
                  handleCountryDropDown={this.handleCountryDropDown}
                  countryValue={this.state.countryValue}
                  limitedOptions={this.state.limitedOptions}
                  limitedSelectOptions={this.state.limitedSelectOptions}
                  onLimitedOptionChange={this.handleLimitedOptionChange}
                  handleCommentChange={this.handleCommentChange}
                  selectedLimitedOptions={this.state.selectedLimitedOptions}
                  setApproverInputValue={this.setApproverInputValue}
                  onApproverNameSelect={this.onApproverNameSelect}
                  approverNames={this.state.approverNames}
                  approverSelectedItems={this.state.approverSelectedItems}
                  setOwnerInputValue={this.setOwnerInputValue}
                  onOwnerNameSelect={this.onOwnerNameSelect}
                  ownerNames={this.state.ownerNames}
                  ownerSelectedItems={this.state.ownerSelectedItems}
                  ApproverNameLoading={this.state.ApproverNameLoading}
                  onTextSelected={this.handleTextSelected}
                />
              </>
            )}
          </div>
        )}
      </>
    );
  }

  handleCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("event value=", event.target.value);
    console.log("event id=", event.target.id);
    const { selectedLimitedOptions } = this.state;
    let selectedOptionIndex = selectedLimitedOptions.findIndex(
      (p) => p.id === event.target.id
    );

    if (selectedOptionIndex !== -1) {
      console.log("selectedOptionIndex==", selectedLimitedOptions[selectedOptionIndex]);
      selectedLimitedOptions[selectedOptionIndex].comment = event.target.value;
      this.setState({ selectedLimitedOptions }, () => {
        console.log("selectedLimitedOptions==", this.state.selectedLimitedOptions);
      });
    }

  }

  handleRevertBlackTextToDefault = (selectedLimitedDropdown: SelectModel, targetID: string | undefined) => {
    const { selectedLimitedOptions, sections } = this.state;
    let selectedOptionIndex = selectedLimitedOptions.findIndex(
      (p) => p.id === targetID
    );
    if (selectedOptionIndex !== -1) {
      selectedLimitedOptions[selectedOptionIndex].selectedBlackTextOption =
        selectedLimitedDropdown;
      if (selectedLimitedDropdown.value !== "5") {
        selectedLimitedOptions[selectedOptionIndex].comment = '';
      }
      this.setState({ selectedLimitedOptions }, () => {

        let deviationOn = true;
        const restrictives = selectedLimitedOptions.filter(
          (p) => (p.selectedBlackTextOption?.value === "4")
        );

        if (restrictives.length > 0) {
          deviationOn = false;
          this.setState({ deviationOn });
        }

        else {
          this.setState({ deviationOn });
        }

      });
    }

    if (targetID === "LIMITED_OPTION_BASICPRINCIPLE") {
      const _basicPrinciples = Utils.getEditorState(this.state.editTemplate.basicPrinciples);

      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          _basicPrinciples: _basicPrinciples,
        },
        isLoading: true,
      }, () => {
        this.setState({ isLoading: false });
      });

    }
    else if (targetID === "LIMITED_OPTION_HEADER") {
      const _header = Utils.getEditorState(this.state.editTemplate.header);
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          _header: _header
        },
        isLoading: true,
      }, () => {
        this.setState({ isLoading: false });
      });
    }
    else if (targetID === "LIMITED_OPTION_BODY") {
      const _bodyText = Utils.getEditorState(this.state.editTemplate.bodyText);
      this.setState({
        editTemplate: {
          ...this.state.editTemplate,
          _bodyText: _bodyText
        },
        isLoading: true,
      }, () => {
        this.setState({ isLoading: false });
      });
    }
    else if (targetID === `LIMITED_OPTION_HEADER_${selectedLimitedOptions[selectedOptionIndex].id.split('_')[3]}`) {
      let copiedSections: LocalProcedureTemplateSection[] = []
      sections.forEach(el => {
        const header = Utils.getEditorState(el.header)
        copiedSections.push({ ...el, _header: header })
      });
      this.setState({
        sections: copiedSections,
        isLoading: true,
      }, () => {
        this.setState({ isLoading: false });
      })

    }
    else if (targetID === `LIMITED_OPTION_BODY_${selectedLimitedOptions[selectedOptionIndex].id.split('_')[3]}`) {
      let copiedSections: LocalProcedureTemplateSection[] = []
      sections.forEach(el => {
        const bodyText = Utils.getEditorState(el.bodyText)
        copiedSections.push({ ...el, _bodyText: bodyText })
      });
      this.setState({
        sections: copiedSections,
        isLoading: true,
      }, () => {
        this.setState({ isLoading: false });
      })
    }
  }

  handleLimitedOptionChange = (
    selected: SelectModel,
    targetId?: string | undefined
  ) => {
    console.log("selected=", selected);
    console.log("selected targetId=", targetId);
    if (targetId && selected.value !== "1") {
      const { selectedLimitedOptions } = this.state;
      let selectedOptionIndex = selectedLimitedOptions.findIndex(
        (p) => p.id === targetId
      );

      if (selectedOptionIndex < 0) {
        const newSelectedOption: IBlackTextOption = {
          id: targetId,
          selectedBlackTextOption: selected,
          comment: ''
        };

        this.setState(
          (prevState) => ({
            selectedLimitedOptions: [
              ...prevState.selectedLimitedOptions,
              newSelectedOption,
            ],
          }),
          () => {
            let deviationOn = true;
            const restrictives = selectedLimitedOptions.filter(
              (p) => (p.selectedBlackTextOption?.value == "4")
            );
            if (restrictives.length > 0) {
              deviationOn = false;
            }

            this.setState({ deviationOn });
          }
        );
      } else {

        selectedLimitedOptions[selectedOptionIndex].selectedBlackTextOption =
          selected;
        if (selected.value !== "5") {
          selectedLimitedOptions[selectedOptionIndex].comment = '';
        }
        this.setState({ selectedLimitedOptions }, () => {
          let deviationOn = true;
          const restrictives = selectedLimitedOptions.filter(
            (p) => (p.selectedBlackTextOption?.value === "4")
          );

          if (restrictives.length > 0) {
            deviationOn = false;
            this.setState({ deviationOn });
          }

          else {
            this.setState({ deviationOn });
          }
        });
      }
    } else {
      console.log("restrictives== thselectedLimitedOptions==", this.state.selectedLimitedOptions);
      /*open the popup for the confirmation when the dropdown value is
       Consistent with global requirement*/
      let deviationOn = true;
      const restrictives = this.state.selectedLimitedOptions.filter(
        (p) => (p.selectedBlackTextOption?.value === "4")
      );
      console.log("restrictives==", restrictives, "thselectedLimitedOptions==", this.state.selectedLimitedOptions);
      if (restrictives.length > 0) {
        deviationOn = false;
      }




      this.setState({ selectedLimitedDropdown: selected, deviationOn, dropDownTargetId: targetId, confirmLimitedPopup: true, });
    }
  };


  handleGenerateDropDown = (selected: SelectModel) => {
    const value = selected.value;
    this.setState({ generateSelectedValue: value });
  };

  handleCountryDropDown = (selected: SelectModel) => {
    console.log("selected = ", selected);
    // this.props.history.push({
    //   pathname:
    //   state: {}
    // })
  };

  onNotApplicableCick = () => {
    this.setState({ deviationOn: true, tab: 1 });
  };

  handleRedTextEditedFlow = () => {
    const { editTemplate, selectedLimitedOptions, sections, deviationOn, templateData, previousSections } = this.state;
    console.log("deviationOn==", deviationOn);
    const lessRestrictives = selectedLimitedOptions.filter(p => (p.selectedBlackTextOption?.value === '4'));

    let arrayOfRoutes: string[] = []
    let route = '/exception'

    console.log("routeArray", arrayOfRoutes)
    if (lessRestrictives.length > 0) {
      arrayOfRoutes.push('/exception')
      route = '/exception';
    }

    editTemplate.bodyText = JSON.stringify(editTemplate._bodyText.toJSON());
    editTemplate.header = JSON.stringify(editTemplate._header.toJSON());
    editTemplate.basicPrinciples = JSON.stringify(
      editTemplate._basicPrinciples.toJSON()
    );
    const basicPrincipleBlackTextChange = selectedLimitedOptions.find(
      (p) => p.id === "LIMITED_OPTION_BASICPRINCIPLE"
    );
    const headerBlackTextChange = selectedLimitedOptions.find(
      (p) => p.id === "LIMITED_OPTION_HEADER"
    );
    const bodyBlackTextChange = selectedLimitedOptions.find(
      (p) => p.id === "LIMITED_OPTION_BODY"
    );
    editTemplate.bodyText = JSON.stringify(editTemplate._bodyText.toJSON());
    editTemplate.header = JSON.stringify(editTemplate._header.toJSON());
    editTemplate.basicPrinciples = JSON.stringify(
      editTemplate._basicPrinciples.toJSON()
    );

    editTemplate.basicPrinciples_blackTextChangeType =
      basicPrincipleBlackTextChange?.selectedBlackTextOption?.text ?? "";
    editTemplate.header_blackTextChangeType =
      headerBlackTextChange?.selectedBlackTextOption?.text ?? "";
    editTemplate.bodyText_blackTextChangeType =
      bodyBlackTextChange?.selectedBlackTextOption?.text ?? "";
    //for comments
    editTemplate.basicPrinciples_blackTextChangeType_Comment =
      basicPrincipleBlackTextChange?.comment ?? "";
    editTemplate.header_blackTextChangeType_Comment =
      headerBlackTextChange?.comment ?? "";
    editTemplate.bodyText_blackTextChangeType_Comment =
      bodyBlackTextChange?.comment ?? "";
    let copiedSections: LocalProcedureTemplateSection[] = [];
    sections.forEach((el) => {
      const headerBlackTextChange = selectedLimitedOptions.find(
        (p) => p.id === `LIMITED_OPTION_HEADER_${el.sectionId}`
      );
      const bodyBlackTextChange = selectedLimitedOptions.find(
        (p) => p.id === `LIMITED_OPTION_BODY_${el.sectionId}`
      );
      const header_blackTextChangeType =
        headerBlackTextChange?.selectedBlackTextOption?.text ?? "";
      const bodyText_blackTextChangeType =
        bodyBlackTextChange?.selectedBlackTextOption?.text ?? "";
      //for comments
      const header_blackTextChangeType_Comment =
        headerBlackTextChange?.comment ?? "";
      const bodyText_blackTextChangeType_Comment =
        bodyBlackTextChange?.comment ?? "";
      let sectionBodyText = el._bodyText;
      let sectionHeader = el._header;
      const bodyText = JSON.stringify(sectionBodyText.toJSON());
      const header = JSON.stringify(sectionHeader?.toJSON())
      copiedSections.push({
        ...el, bodyText: bodyText, header: header, header_blackTextChangeType,
        bodyText_blackTextChangeType,
        header_blackTextChangeType_Comment,
        bodyText_blackTextChangeType_Comment
      });
    });
    this.setState({
      sections: copiedSections,

    })
    if (editTemplate.lpUniqueId === null) {
      this.setState({ editTemplate: { ...editTemplate, lpUniqueId: editTemplate.lpId! } });
    }
    //check  for the other-ration option validation;
    const validationOtherRationaleError = this.getValidationOtherRationaleError();
    if (validationOtherRationaleError) {
      console.log("validationError", validationOtherRationaleError);
      return
    }
    if (this.validationChecker()) {
      //if route is for exception then check for the step as well
      if (!this.state.stepTwoExceptionList && route === '/exception') {
        this.setState({ stepTwoExceptionList: true });
      } else if (this.state.stepTwoExceptionList && route === '/exception') {
        console.log('else if called');
        const stateData = {
          editTemplate :JSON.parse(JSON.stringify(editTemplate)),
          previousSections:JSON.parse(JSON.stringify(previousSections)),
          sections :JSON.parse(JSON.stringify(sections)),
          routeArray: arrayOfRoutes,
          selectedLimitedOptions :JSON.parse(JSON.stringify(selectedLimitedOptions)),
          deviationOn,
          stepTwoExceptionList: true,
          createException: true,
          templateData: JSON.parse(JSON.stringify(templateData))
        };

        this.props.history.push({ pathname: route, state: stateData});
      }
      else {
        this.props.history.push({ pathname: route, state: { editTemplate, previousSections, templateData, sections, routeArray: arrayOfRoutes, selectedLimitedOptions, deviationOn } });
      }

    }
  }

  lessRestrictiveSectionCheck = (arr: any) => {
    // for (const obj of arr) {
    //   if (obj.header_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || obj.bodyText_blackTextChangeType === "Less Restrictive than global requirement (exception required)") {
    //     return true;
    //   }
    //   return false
    // }

    const lessResCount = arr.find((el: any) => {
      if (el.header_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || el.bodyText_blackTextChangeType === "Less Restrictive than global requirement (exception required)") {
        return true;
      } else {
        return false
      }
    })
    if (lessResCount) {
      return true
    } else {
      return false
    }
  }


  handleRichTextJsonToPara = (json: any) => {
    const textArray: [] = [];
    console.log('jsonn', json)
    function extractText(node: any) {
      console.log('nodee', node)
      if (node.text && !node.style?.includes('rule: EDITABLE') && !node.style?.includes('background-color: cyan')) {
        textArray.push(node.text as unknown as never);
      }

      if (node.children && Array.isArray(node.children)) {
        for (const child of node.children) {
          extractText(child);
        }
      }
    }

    if (json.root && json.root.children && Array.isArray(json.root.children)) {
      for (const child of json.root.children) {
        extractText(child);
      }
    }
    console.log('TextArray', textArray)
    return textArray.join('\n').trim()
  }

  handleSectionParser = (obj: any) => {
    obj.header = typeof obj.header === "string" ? JSON.parse(obj.header) : obj.header
    obj.bodyText = typeof obj.bodyText === "string" ? JSON.parse(obj.bodyText) : obj.bodyText
    return obj
  }

  handleAdherenceReportGenerate = async () => {
    const { editTemplate, tableRows, sections, templateData, previousSections } = this.state;

    const configs: DocumentConfigModel[] = [];
    try {
      /* --------------------- Template Parsing and Comparison -------------------- */
      const basicPsNewjson = editTemplate._basicPrinciples.toJSON()
      const basicPsNewjsonText = this.handleRichTextJsonToPara(basicPsNewjson)
      const basicPsOldjson = typeof templateData.basicPrinciples === 'string' ? JSON.parse(templateData.basicPrinciples) : templateData.basicPrinciples
      const basicPsOldjsonText = this.handleRichTextJsonToPara(basicPsOldjson)
      const headerNewjson = editTemplate._header.toJSON()
      const headerNewjsonText = this.handleRichTextJsonToPara(headerNewjson)
      const headerOldjson = typeof templateData.header === 'string' ? JSON.parse(templateData.header) : templateData.header
      const headerOldjsonText = this.handleRichTextJsonToPara(headerOldjson)
      const bodyTextNewjson = editTemplate._bodyText.toJSON()
      const bodyTextNewjsonText = this.handleRichTextJsonToPara(bodyTextNewjson)
      const bodyTextOldjson = typeof templateData.bodyText === 'string' ? JSON.parse(templateData.bodyText) : templateData.bodyText
      const bodyTextOldjsonText = this.handleRichTextJsonToPara(bodyTextOldjson)
      const basicPrincipleChangedText = await lptService.compareSections({ paragraph1: basicPsOldjsonText, paragraph2: basicPsNewjsonText });
      const headerChangedText = await lptService.compareSections({ paragraph1: headerOldjsonText, paragraph2: headerNewjsonText });
      const bodyTextChangedText = await lptService.compareSections({ paragraph1: bodyTextOldjsonText, paragraph2: bodyTextNewjsonText });

      /* --------------------- Section Parsing and Comparison --------------------- */

      const allRationals = ['Less Restrictive than global requirement (exception required)', 'Prohibited locally', 'More Restrictive than global requirement', "Other - provide rationale"]
      const rationalWiseData: any = {};
      const oldSectionParsed = previousSections.map((section: any) => {
        return this.handleSectionParser(section)
      });
      const newSectionParsed: any = sections.map((section: any) => {
        return this.handleSectionParser(section)
      });
      const fetchComment = (section: any) => {
        const comment = section.header_blackTextChangeType_Comment || section.bodyText_blackTextChangeType_Comment
        return comment;
      }
      for (const rational of allRationals) {
        const newSectionHeaderPara = newSectionParsed.filter((obj: any) => {
          return obj.header_blackTextChangeType === rational
        }).map((section: any) => {
          return { sectionId: section.sectionId, header: this.handleRichTextJsonToPara(section.header), comment: fetchComment(section) || null }
        });
        const newSectionBodyTextPara = newSectionParsed.filter((obj: any) => {
          return obj.bodyText_blackTextChangeType === rational
        }).map((section: any) => {
          return { sectionId: section.sectionId, body: this.handleRichTextJsonToPara(section.bodyText), comment: fetchComment(section) || null }
        });
        const difffHPromise = newSectionHeaderPara.map((para: any, index: number) => {
          const d = oldSectionParsed.find((sec: any) => sec.sectionId === para.sectionId);
          return lptService.compareSections({
            paragraph1:
              this.handleRichTextJsonToPara(d.header), paragraph2: para.header
          }, para.comment, this.handleRichTextJsonToPara(d.header))
        });
        const difffBTPromise = newSectionBodyTextPara.map((para: any, index: number) => {
          const d = oldSectionParsed.find((sec: any) => sec.sectionId === para.sectionId);
          return lptService.compareSections({
            paragraph1: this.handleRichTextJsonToPara(d.bodyText), paragraph2: para.body
          }, para.comment, this.handleRichTextJsonToPara(d.header))
        });
        const diffH = await Promise.all(difffHPromise);
        const diffBT = await Promise.all(difffBTPromise);
        // add diffH an difffBTFinal to rationalWiseData
        // find which has more length and fill other with empty
        const diffHLength = diffH.length;
        const difffBTFinalLength = diffBT.length;
        const finalDiffh = [];
        const finalDiffBT = [];
        if (diffHLength > difffBTFinalLength) {
          // find difffBTFinal with same header property as diffH and match the index and rest fill empty
          for (let i = 0; i < diffHLength; i++) {
            const d = diffBT.find((obj: any) => obj.header === diffH[i].header);
            finalDiffh.push(diffH[i]);
            if (d) {
              finalDiffBT.push(d)

            } else {
              finalDiffBT.push({ added: '[]', deleted: '[]', difference: '', global: '', header: '' })
            }
          }
        } else if (difffBTFinalLength > diffHLength) {
          // find diffH with same header property as difffBTFinal and match the index and rest fill empty
          for (let i = 0; i < difffBTFinalLength; i++) {
            const d = diffH.find((obj: any) => obj.header === diffBT[i].header);
            finalDiffBT.push(diffBT[i]);
            if (d) {
              finalDiffh.push(d)
            } else {
              finalDiffh.push({ added: '[]', deleted: '[]', difference: '', global: '', header: '' })
            }
          }
        }
        rationalWiseData[rational] = { headers: finalDiffh, bodyTexts: finalDiffBT }
      }

      configs.push({
        type: "TEXT",
        value: "Summary of Local Changes to Global Requirements",
        textTypes: [
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
          { key: 'text-align', value: 'center' },

        ],
        sectionType: 'CHILDREN',
      });
      const headerTitle = typeof templateData.header === 'string' ? JSON.parse(templateData.header) : templateData.header
      const headerTitlePara = this.handleRichTextJsonToPara(headerTitle)
      configs.push({
        type: "TEXT",
        value: moment().format('LL'),
        textTypes: [
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
          { key: 'text-align', value: 'right' },

        ],
        sectionType: 'FOOTER',
      });
      configs.push({
        type: 'TEXT',
        value: `${editTemplate.templateName}`,
        textTypes: [
          { key: 'break', value: 1 },
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
      })
      configs.push({
        type: 'TEXT',
        value: `Version ${editTemplate.versionNumber}`,
        textTypes: [
          { key: 'break', value: 1 },
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
      })
      configs.push({
        type: 'TEXT',
        value: `${editTemplate.geoGraphyName.split('_').join(',')}`,
        textTypes: [
          { key: 'break', value: 1 },
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
      });
      configs.push({
        type: 'TEXT',
        value: '',
        textTypes: [
          { key: 'break', value: 1 },
        ],
        sectionType: 'CHILDREN',
      });

      if (editTemplate.basicPrinciples_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || editTemplate.header_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || editTemplate.bodyText_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || this.lessRestrictiveSectionCheck(sections)) {
        tableRows.forEach(async (row: any) => {
          configs.push({
            type: 'TEXT',
            value: '',
            textTypes: [
              { key: 'break', value: 1 }
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',

          })
          configs.push({
            type: 'TEXT',
            value: 'Exception Title: ',
            textTypes: [
              { key: 'break', value: 1 },
              {
                key: 'FONT_SIZE', value: '11'
              },

              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: 'exceptiontitle'
          });
          configs.push({
            type: 'TEXT',
            value: row.title,
            textTypes: [

              {
                key: 'FONT_SIZE', value: '11'
              },

              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: 'exceptiontitle'
          });
          configs.push({
            type: 'TEXT',
            value: 'Exception Rationale: ',
            textTypes: [
              { key: 'break', value: 2 },
              {
                key: 'FONT_SIZE', value: '11'
              },

              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: 'exceptionrationale'
          });
          configs.push({
            type: 'TEXT',
            value: row.exceptionRationale,
            textTypes: [

              {
                key: 'FONT_SIZE', value: '11'
              },

              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: 'exceptionrationale'
          });
          configs.push({
            type: 'TEXT',
            value: 'Date of Exception Approval: ',
            textTypes: [
              { key: 'break', value: 2 },
              {
                key: 'FONT_SIZE', value: '11'
              },

              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: 'exceptionappr'
          });
          configs.push({
            type: 'TEXT',
            value: row.approvalTimeStamp ? `${moment(row.approvalTimeStamp).format('MMMM DD, YYYY')}` : 'Not Yet Approved',
            textTypes: [

              {
                key: 'FONT_SIZE', value: '11'
              },

              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: 'exceptionappr'
          });
          configs.push({
            type: 'TEXT',
            value: 'Exception Requested by: ',
            textTypes: [
              { key: 'break', value: 2 },
              {
                key: 'FONT_SIZE', value: '11'
              },

              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: 'exceptionreq'
          });
          configs.push({
            type: 'TEXT',
            value: row.exceptionRequestBy,
            textTypes: [

              {
                key: 'FONT_SIZE', value: '11'
              },

              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: 'exceptionreq'
          });

        });
      }
      console.log('rationalWiseData', rationalWiseData);
      Object.keys(rationalWiseData).forEach((rational: any) => {
        console.log("rational Length", rationalWiseData[rational].headers.length, rationalWiseData[rational].bodyTexts.length)
        if ((rationalWiseData[rational].headers.length !== 0 && rationalWiseData[rational].bodyTexts.length !== 0) || editTemplate.basicPrinciples_blackTextChangeType === rational || editTemplate.header_blackTextChangeType === rational || editTemplate.bodyText_blackTextChangeType === rational) {
        configs.push({
          type: 'TEXT',
          value: ``,
          textTypes: [
            {
              key: 'HORIZONTAL_LINE'
            },
          ],
          sectionType: 'CHILDREN',
        });
        configs.push({
          type: 'TEXT',
          value: `Rationale: `,
          textTypes: [
            { key: 'break', value: 1 },
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: `${rational} rationale`,
        });
        configs.push({
          type: 'TEXT',
          value: rational,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: `${rational} rationale`,
        });
      }
        let lessRestCount = 1;
        if (editTemplate.basicPrinciples_blackTextChangeType === rational) {
          if (rational === 'Other - provide rationale') {
            configs.push({
              type: 'TEXT',
              value: `${editTemplate.basicPrinciples_blackTextChangeType_Comment}`,
              textTypes: [
                { key: 'break', value: 1 },
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

              ],
              sectionType: 'CHILDREN',
            });
          }
          configs.push({
            type: 'TEXT',
            value: `Change #${lessRestCount} to Basic Principles`,
            textTypes: [
              { key: 'break', value: 1 },
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
          });
          configs.push({
            type: 'TEXT',
            value: '',
            textTypes: [
            ],
            sectionType: 'CHILDREN',
          });
          lessRestCount++;
          const splitedData = basicPrincipleChangedText.difference.split(/\s+/)
          const globalData = basicPrincipleChangedText.global.split(/\s+/)
          const addedWord = JSON.parse(basicPrincipleChangedText.added)
          const delWords = JSON.parse(basicPrincipleChangedText.deleted)
          if (splitedData.length > 0) {

            configs.push({
              type: 'TEXT',
              value: `Local Requirement`,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational} localreq`,
            });

            configs.push({
              type: 'TEXT',
              value: ``,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'break', value: 1 },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
            })


            splitedData.forEach((el: any, index: number) => {
              const wordPos = addedWord.find((words: any) => {
                return (words === index + 1)
              })

              configs.push({
                type: 'TEXT',
                value: el,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                  { key: 'BG_COLOR', value: wordPos ? 'green' : '' },


                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProb`
              })


              if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
                configs.push({
                  type: 'TEXT',
                  value: '',
                  textTypes: [
                    { key: 'break', value: 1 }
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProbBody`
                })
              } else {
                configs.push({
                  type: 'TEXT',
                  value: ' ',
                  textTypes: [

                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProb`
                })
              }
            })
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [

                { key: 'break', value: 1 },


              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
            })

            
          }
          if(globalData.length > 0){
            configs.push({
              type: 'TEXT',
              value: `Global Requirement`,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational} localreq`,
            });

            configs.push({
              type: 'TEXT',
              value: ``,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'break', value: 1 },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
            })
            globalData.forEach((el: any, index: number) => {
              const wordDel = delWords.find((words: any) => {
                return (words === index + 1)
              })
              configs.push({
                type: 'TEXT',
                value: el,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                  wordDel ? { key: 'STRIKETHROUGH' } : { key: '' },
                  wordDel ? wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' } : { key: '' },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProb`
              })
              if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
                configs.push({
                  type: 'TEXT',
                  value: '',
                  textTypes: [
                    { key: 'break', value: 1 }
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProbBody`
                })
              } else {
                configs.push({
                  type: 'TEXT',
                  value: ' ',
                  textTypes: [
  
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProb`
                })
              }
            })
          }
         
        }
        if (editTemplate.header_blackTextChangeType === rational) {
          if (rational === 'Other - provide rationale') {
            configs.push({
              type: 'TEXT',
              value: `${editTemplate.header_blackTextChangeType_Comment}`,
              textTypes: [
                { key: 'break', value: 1 },
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

              ],
              sectionType: 'CHILDREN',
            });
          }
          configs.push({
            type: 'TEXT',
            value: `Change #${lessRestCount} to ${headerTitlePara}`,
            textTypes: [
              { key: 'break', value: 1 },
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
          });
          configs.push({
            type: 'TEXT',
            value: '',
            textTypes: [
            ],
            sectionType: 'CHILDREN',
          });
          lessRestCount++;
          const splitedData = headerChangedText.difference.split(/\s+/)
          const globalData = headerChangedText.global.split(/\s+/)
          const addedWord = JSON.parse(headerChangedText.added)
          const delWords = JSON.parse(headerChangedText.deleted)
          if(splitedData.length > 0){
            configs.push({
              type: 'TEXT',
              value: `Local Requirement`,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational} localreq`,
            });
  
            configs.push({
              type: 'TEXT',
              value: ``,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'break', value: 1 },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
  
            })
            
            splitedData.forEach((el: any, index: number) => {
              const wordPos = addedWord.find((words: any) => {
                return (words === index + 1)
              })
              configs.push({
                type: 'TEXT',
                value: el,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                  { key: 'BG_COLOR', value: wordPos ? 'green' : '' },
  
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbHead`
              })
              configs.push({
                type: 'TEXT',
                value: ' ',
                textTypes: [
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbHead`
              })
            })
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [
                { key: 'break', value: 1 }
              ],
              sectionType: 'CHILDREN',
            });
           
          }
         if(globalData.length > 0) {
          configs.push({
            type: 'TEXT',
            value: `Global Requirement`,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational} localreq`,
          });

          configs.push({
            type: 'TEXT',
            value: ``,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'break', value: 1 },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',

          })
          globalData.forEach((el: any, index: number) => {

            const wordDel = delWords.find((words: any) => {
              return (words === index + 1)
            })
            configs.push({
              type: 'TEXT',
              value: el,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' },
                wordDel ? { key: 'STRIKETHROUGH' } : { key: '' }

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProbHead`
            })


            if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
              configs.push({
                type: 'TEXT',
                value: '',
                textTypes: [
                  { key: 'break', value: 1 }
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbBody`
              })
            } else {
              configs.push({
                type: 'TEXT',
                value: ' ',
                textTypes: [

                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbHead`
              })
            }
          })
         }
          
        }
        if (editTemplate.bodyText_blackTextChangeType === rational) {
          if (rational === 'Other - provide rationale') {
            configs.push({
              type: 'TEXT',
              value: `${editTemplate.bodyText_blackTextChangeType_Comment}`,
              textTypes: [
                { key: 'break', value: 1 },
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

              ],
              sectionType: 'CHILDREN',
            });
          }
          configs.push({
            type: 'TEXT',
            value: `Change #${lessRestCount} to ${headerTitlePara}`,
            textTypes: [
              { key: 'break', value: 1 },
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
          });
          configs.push({
            type: 'TEXT',
            value: '',
            textTypes: [
            ],
            sectionType: 'CHILDREN',
          });
          lessRestCount++;
          const splitedData = bodyTextChangedText.difference.split(/\s+/)
          const globalData = bodyTextChangedText.global.split(/\s+/)
          const addedWord = JSON.parse(bodyTextChangedText.added)
          const delWords = JSON.parse(bodyTextChangedText.deleted)
          if(splitedData.length > 0){
            configs.push({
              type: 'TEXT',
              value: `Local Requirement`,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational} localreq`,
            });
  
  
            configs.push({
              type: 'TEXT',
              value: ``,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'break', value: 1 },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
  
            })
           
            splitedData.forEach((el: any, index: number) => {
              const wordPos = addedWord.find((words: any) => {
                return (words === index + 1)
              })
  
              configs.push({
                type: 'TEXT',
                value: el,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                  { key: 'BG_COLOR', value: wordPos ? 'green' : '' },
  
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbBody`
              })
              if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
                configs.push({
                  type: 'TEXT',
                  value: '',
                  textTypes: [
                    { key: 'break', value: 1 }
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProbBody`
                })
              } else {
                configs.push({
                  type: 'TEXT',
                  value: ' ',
                  textTypes: [
  
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProbBody`
                })
              }
            })
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [
                { key: 'break', value: 1 }
              ],
              sectionType: 'CHILDREN',
            });
  
           
          }
         if(globalData.length > 0) {
          configs.push({
            type: 'TEXT',
            value: `Global Requirement`,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational} localreq`,
          });


          configs.push({
            type: 'TEXT',
            value: ``,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'break', value: 1 },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',

          })
          globalData.forEach((el: any, index: number) => {

            const wordDel = delWords.find((words: any) => {
              return (words === index + 1)
            })
            configs.push({
              type: 'TEXT',
              value: el,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' },
                wordDel ? { key: 'STRIKETHROUGH' } : { key: '' }

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProbBody`
            })


            if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
              configs.push({
                type: 'TEXT',
                value: '',
                textTypes: [
                  { key: 'break', value: 1 }
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbBody`
              })
            } else {
              configs.push({
                type: 'TEXT',
                value: ' ',
                textTypes: [

                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbBody`
              })
            }
          })
         }
          

        }
        const headers = rationalWiseData[rational].headers;
        const bodyTexts = rationalWiseData[rational].bodyTexts;
        headers.forEach((header: any, index: number) => {
          if (JSON.parse(header.added).length || JSON.parse(header.deleted).length) {
            if (rational === 'Other - provide rationale') {
              configs.push({
                type: 'TEXT',
                value: `${header.comment}`,
                textTypes: [
                  { key: 'break', value: 1 },
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

                ],
                sectionType: 'CHILDREN',
              });
            }

            configs.push({
              type: 'TEXT',
              value: `Change #${lessRestCount} to ${header.header}`,
              textTypes: [
                { key: 'break', value: 1 },
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

              ],
              sectionType: 'CHILDREN',
            });
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [
              ],
              sectionType: 'CHILDREN',
            });
            lessRestCount++
            const splitedData = header.difference.split(/\s+/)
              const globalData = header.global.split(/\s+/)
              const addedWord = JSON.parse(header.added)
              const delWords = JSON.parse(header.deleted)
            if(splitedData.length > 0) {
              configs.push({
                type: 'TEXT',
                value: `Local Requirement`,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational} localreq`,
              });
  
  
              configs.push({
                type: 'TEXT',
                value: ``,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'break', value: 1 },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
  
              })
              
              splitedData.forEach((el: any, spltInd: number) => {
                const wordPos = addedWord.find((words: any) => {
                  return (words === spltInd + 1)
                })
                configs.push({
                  type: 'TEXT',
                  value: el,
                  textTypes: [
                    {
                      key: 'FONT_SIZE', value: '11'
                    },
                    { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                    { key: 'BG_COLOR', value: wordPos ? 'green' : '' },
  
  
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProb`
                })
  
                if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
                  configs.push({
                    type: 'TEXT',
                    value: '',
                    textTypes: [
                      { key: 'break', value: 1 }
                    ],
                    sectionType: 'CHILDREN',
                    elementType: 'ROW',
                    uid: `${rational}basicProbBody`
                  })
                } else {
                  configs.push({
                    type: 'TEXT',
                    value: ' ',
                    textTypes: [
  
                    ],
                    sectionType: 'CHILDREN',
                    elementType: 'ROW',
                    uid: `${rational}basicProb`
                  })
                }
  
              })
  
              configs.push({
                type: 'TEXT',
                value: ``,
                textTypes: [
  
                  { key: 'break', value: 1 },
  
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
  
              })
              configs.push({
                type: 'TEXT',
                value: `Global Requirement`,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational} localreq`,
              });
  
  
              configs.push({
                type: 'TEXT',
                value: ``,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'break', value: 1 },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
  
              })
            }
           if(globalData.length > 0) {
            globalData.forEach((el: any, glblInd: number) => {

              const wordDel = delWords.find((words: any) => {
                return (words === glblInd + 1)
              })
              configs.push({
                type: 'TEXT',
                value: el,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                  wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' },
                  wordDel ? { key: 'STRIKETHROUGH' } : { key: '' }

                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProb`
              })
              if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
                configs.push({
                  type: 'TEXT',
                  value: '',
                  textTypes: [
                    { key: 'break', value: 1 }
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProbBody`
                })
              }
              configs.push({
                type: 'TEXT',
                value: ' ',
                textTypes: [

                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProb`
              })

            })
           }
            
          }
          console.log('bodyTexts- ind', bodyTexts[index], index, 'type', typeof bodyTexts[index].added);
          if (JSON.parse(bodyTexts[index].added).length || JSON.parse(bodyTexts[index].deleted).length) {
            if (rational === 'Other - provide rationale') {
              configs.push({
                type: 'TEXT',
                value: bodyTexts[index].comment,
                textTypes: [
                  { key: 'break', value: 1 },
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

                ],
                sectionType: 'CHILDREN',
              });
            }
            configs.push({
              type: 'TEXT',
              value: `Change #${lessRestCount} to ${bodyTexts[index].header}`,
              textTypes: [
                { key: 'break', value: 1 },
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

              ],
              sectionType: 'CHILDREN',
            });
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [
              ],
              sectionType: 'CHILDREN',
            });
            lessRestCount++
            const splitedBodyData = bodyTexts[index].difference.split(/\s+/)
            const globalBodyData = bodyTexts[index].global.split(/\s+/)
            const addedBodyWord = JSON.parse(bodyTexts[index].added)
            const delBodyWords = JSON.parse(bodyTexts[index].deleted)
            if(splitedBodyData.length > 0) {
              configs.push({
                type: 'TEXT',
                value: `Local Requirement`,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational} localreq`,
              });
  
  
              configs.push({
                type: 'TEXT',
                value: ``,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'break', value: 1 },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
  
              })
              
              splitedBodyData.forEach((el: any, spltBdyInd: number) => {
                const wordPos = addedBodyWord.find((words: any) => {
                  return (words === spltBdyInd + 1)
                })
                configs.push({
                  type: 'TEXT',
                  value: el,
                  textTypes: [
                    {
                      key: 'FONT_SIZE', value: '11'
                    },
                    { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                    { key: 'BG_COLOR', value: wordPos ? 'green' : '' },
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProb`
                })
                if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
                  configs.push({
                    type: 'TEXT',
                    value: '',
                    textTypes: [
                      { key: 'break', value: 1 }
                    ],
                    sectionType: 'CHILDREN',
                    elementType: 'ROW',
                    uid: `${rational}basicProbBody`
                  })
                } else {
                  configs.push({
                    type: 'TEXT',
                    value: ' ',
                    textTypes: [
  
                    ],
                    sectionType: 'CHILDREN',
                    elementType: 'ROW',
                    uid: `${rational}basicProb`
                  })
                }
              })
              configs.push({
                type: 'TEXT',
                value: '',
                textTypes: [
                  { key: 'break', value: 1 }
                ],
                sectionType: 'CHILDREN',
              });
            
            }
            if(globalBodyData.length > 0) {
              configs.push({
                type: 'TEXT',
                value: `Global Requirement`,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational} localreq`,
              });
              configs.push({
                type: 'TEXT',
                value: ``,
                textTypes: [
                  {
                    key: 'FONT_SIZE', value: '11'
                  },
                  { key: 'break', value: 1 },
                  { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
  
              })
              globalBodyData.forEach((el: any, glblBdyInd: number) => {
                const wordDel = delBodyWords.find((words: any) => {
                  return (words === glblBdyInd + 1)
                })
                configs.push({
                  type: 'TEXT',
                  value: el,
                  textTypes: [
                    {
                      key: 'FONT_SIZE', value: '11'
                    },
                    { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                    wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' },
                    wordDel ? { key: 'STRIKETHROUGH' } : { key: '' }
  
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProb`
                })
                if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
                  configs.push({
                    type: 'TEXT',
                    value: '',
                    textTypes: [
                      { key: 'break', value: 1 }
                    ],
                    sectionType: 'CHILDREN',
                    elementType: 'ROW',
                    uid: `${rational}basicProbBody`
                  })
                }
                configs.push({
                  type: 'TEXT',
                  value: ' ',
                  textTypes: [
  
                  ],
                  sectionType: 'CHILDREN',
                  elementType: 'ROW',
                  uid: `${rational}basicProb`
                })
              })
            }
          }


        });
        // }
      })


      console.log('congigsss', configs)
      const blob = await DocxService.createDocument(configs);
      const file = new File([blob], "AdherenceReport.docx", {
        type: blob.type,
      });
      const documents3FileKey = `localproceduretemplate/${editTemplate.lpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate.versionNumber}/${file.name}`;
      editTemplate._adherenceReport = {} as FileUploadModelForLP;
      editTemplate._adherenceReport.s3filekey = documents3FileKey;
      editTemplate._adherenceReport.file = file;
      editTemplate._adherenceReport.name = "AdherenceReport";
      // editTemplate._adherenceReport.fileversion = editTemplate.versionNumber;
      editTemplate._adherenceReport.type = file.type as FileType;
      editTemplate._adherenceReport.ext = "docx";
      editTemplate._adherenceReport.lpId = editTemplate.lpId!;
      editTemplate._adherenceReport.sizeInKB = Math.round(file.size / 1024);
      editTemplate._adherenceReport.sizeInMB = Math.round(file.size / 1024 / 1024);
      const upload = await ProcedureFileService.uploadDocumentForLP(editTemplate._adherenceReport);
      if (upload) {
        await ProcedureFileService.docx2pdf(documents3FileKey, FileType.DOCX)
      }

      if (this.state.reportDownload) {
        const baseName = file.name.split(".docx")
        const pdfName = baseName[0] + '.pdf'
        const pdfFile = await ProcedureFileService.getPresignedUrl(`localproceduretemplate/${editTemplate.lpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate.versionNumber}/${pdfName}`, FileType.PDF)

        fetch(pdfFile!)
          .then((response) => {
            if (response.ok) {
              this.setState({ isLoading: false });
              return response.blob();
            }
            throw new Error('Network response was not OK.');
          })
          .then((blob) => {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Adherence Report.pdf`;
            document.body.appendChild(link); link.click();
            document.body.removeChild(link);
          }).catch((error) => {
            this.setState({ isLoading: false, error: true, errMsg: "Error generating Procedure Adherence Report." });
            console.error('Error:', error);
          });
      }
    } catch (error) {
      console.error('err during report generation', error);
      this.setState({ isLoading: false, error: true, errMsg: "Error generating Procedure Adherence Report." });
    }

  }

  // handleAdherenceReportGenerate = async () => {
  //   const { editTemplate, tableRows, sections, templateData, previousSections } = this.state;

  //   const configs: DocumentConfigModel[] = [];
  //   try {
  //     /* --------------------- Template Parsing and Comparison -------------------- */
  //     // const basicPsNewjson = editTemplate._basicPrinciples.toJSON()
  //     // const basicPsNewjsonText = this.handleRichTextJsonToPara(basicPsNewjson)
  //     // const basicPsOldjson = typeof templateData.basicPrinciples === 'string' ? JSON.parse(templateData.basicPrinciples) : templateData.basicPrinciples
  //     // const basicPsOldjsonText = this.handleRichTextJsonToPara(basicPsOldjson)
  //     // const headerNewjson = editTemplate._header.toJSON()
  //     // const headerNewjsonText = this.handleRichTextJsonToPara(headerNewjson)
  //     // const headerOldjson = typeof templateData.header === 'string' ? JSON.parse(templateData.header) : templateData.header
  //     // const headerOldjsonText = this.handleRichTextJsonToPara(headerOldjson)
  //     // const bodyTextNewjson = editTemplate._bodyText.toJSON()
  //     // const bodyTextNewjsonText = this.handleRichTextJsonToPara(bodyTextNewjson)
  //     // const bodyTextOldjson = typeof templateData.bodyText === 'string' ? JSON.parse(templateData.bodyText) : templateData.bodyText
  //     // const bodyTextOldjsonText = this.handleRichTextJsonToPara(bodyTextOldjson)
  //     const basicPSNewHtml = Utils.getHTML(JSON.stringify(editTemplate._basicPrinciples.toJSON()));
  //     const basicPSOldHTml = Utils.getHTML(templateData.basicPrinciples);
  //     const basicPrincipleChangedText = await lptService.compareSections({ paragraph1: basicPSNewHtml, paragraph2:  basicPSOldHTml});
  //     // const headerChangedText = await lptService.compareSections({ paragraph1: headerOldjsonText, paragraph2: headerNewjsonText });
  //     // const bodyTextChangedText = await lptService.compareSections({ paragraph1: bodyTextOldjsonText, paragraph2: bodyTextNewjsonText });

  //     /* --------------------- Section Parsing and Comparison --------------------- */
  //     const oldSectionParsed = previousSections.map((section: any) => {
  //       return this.handleSectionParser(section)
  //     });
  //     const oldSectionHeaderPara = oldSectionParsed.map((section: any) => {
  //       return this.handleRichTextJsonToPara(section.header)
  //     });
  //     const oldSectionBodyTextPara = oldSectionParsed.map((section: any) => {
  //       return this.handleRichTextJsonToPara(section.bodyText)
  //     });
  //     const newSectionParsed: any = sections.map((section: any) => {
  //       return this.handleSectionParser(section)
  //     });
  //     const newSectionHeaderPara = newSectionParsed.map((section: any) => {
  //       return this.handleRichTextJsonToPara(section.header)
  //     });
  //     const newSectionBodyTextPara = newSectionParsed.map((section: any) => {
  //       return this.handleRichTextJsonToPara(section.bodyText)
  //     });

  //     /* ------- loop over the sections and compare the header and body text ------ */
  //     const difffH = oldSectionHeaderPara.map((para: any, index: number) => {
  //       return lptService.compareSections({ paragraph1: para, paragraph2: newSectionHeaderPara[index] })
  //     });
  //     const diffH = await Promise.all(difffH);
  //     const difffBT = oldSectionBodyTextPara.map((para: any, index: number) => {
  //       return lptService.compareSections({ paragraph1: para, paragraph2: newSectionBodyTextPara[index] })
  //     });
  //     const difffBTFinal = await Promise.all(difffBT);
  //     const finalH = diffH.map((diff: any) => {
  //       return diff.difference
  //     });
  //     const finalBT = difffBTFinal.map((diff: any) => {
  //       return diff.difference
  //     });
  //     /* ------------------------ Sections Header Title map ----------------------- */
  //     const sectionHeadersForLessRes = sections.filter((obj) => obj.header_blackTextChangeType === "Less Restrictive than global requirement (exception required)")
  //       .map((section: any) => {
  //         const headerTitlePara = this.handleRichTextJsonToPara(section.header)
  //         return headerTitlePara
  //       });
  //     const sectionHeadersBTForLessRes = sections.filter((obj) => obj.bodyText_blackTextChangeType === "Less Restrictive than global requirement (exception required)")
  //       .map((section: any) => {
  //         const headerTitlePara = this.handleRichTextJsonToPara(section.header)
  //         return headerTitlePara
  //       });
  //     const sectionHeadersForMoreRes = sections.filter((obj) => obj.header_blackTextChangeType === "More Restrictive than global requirement")
  //       .map((section: any) => {
  //         const headerTitlePara = this.handleRichTextJsonToPara(section.header)
  //         return headerTitlePara
  //       });
  //     const sectionHeadersBTForMoreRes = sections.filter((obj) => obj.bodyText_blackTextChangeType === "More Restrictive than global requirement")
  //       .map((section: any) => {
  //         const headerTitlePara = this.handleRichTextJsonToPara(section.header)
  //         console.log('headerTitlePara', headerTitlePara)
  //         return headerTitlePara
  //       });
  //     const sectionHeadersForProhibited = sections.filter((obj) => obj.header_blackTextChangeType === "Prohibited locally")
  //       .map((section: any) => {
  //         const headerTitlePara = this.handleRichTextJsonToPara(section.header)
  //         return headerTitlePara
  //       });
  //     const sectionHeadersBTForProhibited = sections.filter((obj) => obj.bodyText_blackTextChangeType === "Prohibited locally")
  //       .map((section: any) => {
  //         const headerTitlePara = this.handleRichTextJsonToPara(section.header)
  //         return headerTitlePara
  //       });
  //     const sectionHeadersForOther = sections.filter((obj) => obj.header_blackTextChangeType === "Other - provide rationale")
  //       .map((section: any) => {
  //         const headerTitlePara = this.handleRichTextJsonToPara(section.header)
  //         return headerTitlePara
  //       });
  //     const sectionHeadersBTForOther = sections.filter((obj) => obj.bodyText_blackTextChangeType === "Other - provide rationale")
  //       .map((section: any) => {
  //         const headerTitlePara = this.handleRichTextJsonToPara(section.header)
  //         return headerTitlePara
  //       });
  //     const sectionHeadersComment = sections.filter((obj) => obj.header_blackTextChangeType === "Other - provide rationale")
  //       .map((section: any) => {
  //         return section.header_blackTextChangeType_Comment
  //       });
  //     const sectionBodyTextComment = sections.filter((obj) => obj.bodyText_blackTextChangeType === "Other - provide rationale")
  //       .map((section: any) => {
  //         return section.bodyText_blackTextChangeType_Comment
  //       });
  //     configs.push({
  //       type: "TEXT",
  //       value: "Summary of Local Changes to Global Requirements",
  //       textTypes: [
  //         {
  //           key: 'FONT_SIZE', value: '11'
  //         },
  //         { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  //         { key: 'text-align', value: 'center' },

  //       ],
  //       sectionType: 'CHILDREN',
  //     });
  //     const headerTitle = typeof templateData.header === 'string' ? JSON.parse(templateData.header) : templateData.header
  //     const headerTitlePara = this.handleRichTextJsonToPara(headerTitle)
  //     configs.push({
  //       type: "TEXT",
  //       value: moment().format('LL'),
  //       textTypes: [
  //         {
  //           key: 'FONT_SIZE', value: '11'
  //         },
  //         { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
  //         { key: 'text-align', value: 'right' },

  //       ],
  //       sectionType: 'FOOTER',
  //     });
  //     configs.push({
  //       type: 'TEXT',
  //       value: `${editTemplate.templateName}`,
  //       textTypes: [
  //         { key: 'break', value: 1 },
  //         {
  //           key: 'FONT_SIZE', value: '11'
  //         },
  //         { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //       ],
  //       sectionType: 'CHILDREN',
  //     })
  //     configs.push({
  //       type: 'TEXT',
  //       value: `Version ${editTemplate.versionNumber}`,
  //       textTypes: [
  //         { key: 'break', value: 1 },
  //         {
  //           key: 'FONT_SIZE', value: '11'
  //         },
  //         { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //       ],
  //       sectionType: 'CHILDREN',
  //     })
  //     configs.push({
  //       type: 'TEXT',
  //       value: `${editTemplate.geoGraphyName.split('_').join(',')}`,
  //       textTypes: [
  //         { key: 'break', value: 1 },
  //         {
  //           key: 'FONT_SIZE', value: '11'
  //         },
  //         { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //       ],
  //       sectionType: 'CHILDREN',
  //     });
  //     configs.push({
  //       type: 'TEXT',
  //       value: '',
  //       textTypes: [
  //         { key: 'break', value: 1 },
  //       ],
  //       sectionType: 'CHILDREN',
  //     });
  //     let lessRestCount = 1;
  //     if (editTemplate.basicPrinciples_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || editTemplate.header_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || editTemplate.bodyText_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || this.lessRestrictiveSectionCheck(sections)) {
  //       tableRows.forEach(async (row: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: 'Exception Title:',
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },

  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'exceptiontitle'
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: row.title,
  //           textTypes: [

  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },

  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'exceptiontitle'
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: 'Exception Rationale:',
  //           textTypes: [
  //             { key: 'break', value: 2 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },

  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'exceptionrationale'
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: row.exceptionRationale,
  //           textTypes: [

  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },

  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'exceptionrationale'
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: 'Date of Exception Approval:',
  //           textTypes: [
  //             { key: 'break', value: 2 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },

  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'exceptionappr'
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: row.approvalTimeStamp ? `${moment(row.approvalTimeStamp).format('MMMM DD, YYYY')}` : 'Not Yet Approved',
  //           textTypes: [

  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },

  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'exceptionappr'
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: 'Exception Requested by:',
  //           textTypes: [
  //             { key: 'break', value: 2 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },

  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'exceptionreq'
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: row.exceptionRequestBy,
  //           textTypes: [

  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },

  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'exceptionreq'
  //         });

  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: ``,
  //         textTypes: [
  //           {
  //             key: 'HORIZONTAL_LINE'
  //           },
  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Rationale: `,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited rationale',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Less Restrictive than Global Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited rationale',
  //       });
  //     }

  //     if (editTemplate.basicPrinciples_blackTextChangeType === "Less Restrictive than global requirement (exception required)") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${lessRestCount} to Basic Principles`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: '',
  //         textTypes: [
  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       lessRestCount++;
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });
  //       basicPrincipleChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })

  //     }
  //     if (editTemplate.header_blackTextChangeType === "Less Restrictive than global requirement (exception required)") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${lessRestCount} to ${headerTitlePara}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: '',
  //         textTypes: [
  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       lessRestCount++;
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });
  //       headerChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })


  //     }
  //     if (editTemplate.bodyText_blackTextChangeType === "Less Restrictive than global requirement (exception required)") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${lessRestCount} to ${headerTitlePara}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: '',
  //         textTypes: [
  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       lessRestCount++;
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });

  //       bodyTextChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })


  //     }
  //     const maxLengthHeaders = Math.max(sectionHeadersForLessRes.length, finalH.length);
  //     const maxLengthBodyText = Math.max(sectionHeadersBTForLessRes.length, finalBT.length);
  //     for (let i = 0; i < Math.max(maxLengthHeaders, maxLengthBodyText); i++) {
  //       if (i < sectionHeadersForLessRes.length && i < finalH.length) {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Change #${lessRestCount} to ${sectionHeadersForLessRes[i]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: '',
  //           textTypes: [
  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         lessRestCount++
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Local Requirement`,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         });
  //         const nonEmptySubarrays = finalH.filter(subarray => subarray.length > 0)

  //         nonEmptySubarrays.length > 0 && nonEmptySubarrays[i].forEach((el: any) => {
  //           configs.push({
  //             type: 'TEXT',
  //             value: `           `,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'break', value: 1 },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',

  //           })
  //           configs.push({
  //             type: 'TEXT',
  //             value: el,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',
  //           })
  //         })


  //       }

  //       if (i < sectionHeadersBTForLessRes.length && i < finalBT.length) {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Change #${lessRestCount} to ${headerTitlePara}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: '',
  //           textTypes: [
  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         lessRestCount++
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Local Requirement`,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         });
  //         const nonEmptySubarrays = finalBT.filter(subarray => subarray.length > 0)

  //         nonEmptySubarrays.length > 0 && nonEmptySubarrays[i].forEach((el: any) => {
  //           configs.push({
  //             type: 'TEXT',
  //             value: `           `,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'break', value: 1 },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',

  //           })
  //           configs.push({
  //             type: 'TEXT',
  //             value: el,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',
  //           })
  //         })
  //       }
  //     }

  //     /* ----------------------- Config for Prohibited locally ---------------------- */
  //     if (editTemplate.basicPrinciples_blackTextChangeType === "Prohibited locally" || editTemplate.header_blackTextChangeType === "Prohibited locally" || this.prohibitedLocallySectionCheck(sections)) {
  //       configs.push({
  //         type: 'TEXT',
  //         value: ``,
  //         textTypes: [
  //           {
  //             key: 'HORIZONTAL_LINE'
  //           },


  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Rationale: `,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited rationale',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Prohibited locally`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited rationale',
  //       });
  //     }

  //     let prohibitedCount = 1;
  //     if (editTemplate.basicPrinciples_blackTextChangeType === "Prohibited locally") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${prohibitedCount} to Basic Principles`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       prohibitedCount++;

  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });
  //       basicPrincipleChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })

  //     }
  //     if (editTemplate.header_blackTextChangeType === "Prohibited locally") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${prohibitedCount} to ${headerTitlePara}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       prohibitedCount++;
  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });
  //       headerChangedText.difference.forEach((el: any) => {

  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })
  //     }

  //     if (editTemplate.bodyText_blackTextChangeType === "Prohibited locally") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${prohibitedCount} to ${headerTitlePara}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });

  //       prohibitedCount++;
  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });
  //       bodyTextChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })

  //     }
  //     const maxLengthHeadersPL = Math.max(sectionHeadersForProhibited.length, finalH.length);
  //     const maxLengthBodyTextPL = Math.max(sectionHeadersBTForProhibited.length, finalBT.length);

  //     for (let i = 0; i < Math.max(maxLengthHeadersPL, maxLengthBodyTextPL); i++) {

  //       if (i < sectionHeadersForProhibited.length && i < finalH.length) {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Change #${prohibitedCount} to ${sectionHeadersForProhibited[i]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         prohibitedCount++;
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         })

  //         configs.push({
  //           type: 'TEXT',
  //           value: `Local Requirement`,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         });
  //         const nonEmptySubarrays = finalH.filter(subarray => subarray.length > 0)

  //         nonEmptySubarrays.length > 0 && nonEmptySubarrays[i].forEach((el: any) => {
  //           configs.push({
  //             type: 'TEXT',
  //             value: `           `,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'break', value: 1 },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',

  //           })
  //           configs.push({
  //             type: 'TEXT',
  //             value: el,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',
  //           })
  //         })

  //       }

  //       if (i < sectionHeadersBTForProhibited.length) {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Change #${prohibitedCount} to ${sectionHeadersBTForProhibited[i]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: '',
  //           textTypes: [
  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         prohibitedCount++;
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         })

  //         configs.push({
  //           type: 'TEXT',
  //           value: `Local Requirement`,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         });

  //         const nonEmptySubarrays = finalBT.filter(subarray => subarray.length > 0)

  //         nonEmptySubarrays.length > 0 && nonEmptySubarrays[i].forEach((el: any) => {
  //           configs.push({
  //             type: 'TEXT',
  //             value: `           `,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'break', value: 1 },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',

  //           })
  //           configs.push({
  //             type: 'TEXT',
  //             value: el,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',
  //           })
  //         })


  //       }

  //     }
  //     /* ----------------------- Config for More Restrictive ---------------------- */
  //     if (editTemplate.basicPrinciples_blackTextChangeType === "More Restrictive than global requirement" || editTemplate.header_blackTextChangeType === "More Restrictive than global requirement" || editTemplate.bodyText_blackTextChangeType === 'More Restrictive than global requirement' || this.moreRestrictiveSectionCheck(sections)) {
  //       configs.push({
  //         type: 'TEXT',
  //         value: ``,
  //         textTypes: [
  //           {
  //             key: 'HORIZONTAL_LINE'
  //           },
  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Rationale: `,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited rationale',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `More Restrictive than global requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited rationale',
  //       });
  //     }

  //     let moreResCount = 1;
  //     if (editTemplate.basicPrinciples_blackTextChangeType === "More Restrictive than global requirement") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${moreResCount} to Basic Principles`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       moreResCount++;

  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });

  //       basicPrincipleChangedText.difference.forEach((el: any) => {
  //         console.log('el', el)
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })

  //     }
  //     if (editTemplate.header_blackTextChangeType === "More Restrictive than global requirement") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${moreResCount} to ${headerTitlePara}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       moreResCount++;
  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });

  //       headerChangedText.difference.forEach((el: any) => {

  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },


  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })
  //     }

  //     if (editTemplate.bodyText_blackTextChangeType === "More Restrictive than global requirement") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${moreResCount} to ${headerTitlePara}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });

  //       moreResCount++;
  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });

  //       bodyTextChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })

  //     }
  //     const maxLengthHeadersMR = Math.max(sectionHeadersForMoreRes.length, finalH.length);
  //     const maxLengthBodyTextMR = Math.max(sectionHeadersBTForMoreRes.length, finalBT.length);

  //     for (let i = 0; i < Math.max(maxLengthHeadersMR, maxLengthBodyTextMR); i++) {

  //       if (i < sectionHeadersForMoreRes.length && i < finalH.length) {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Change #${moreResCount} to ${sectionHeadersForMoreRes[i]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         moreResCount++;
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         })

  //         configs.push({
  //           type: 'TEXT',
  //           value: `Local Requirement`,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         });
  //         console.log('finalH', finalH, sectionHeadersForMoreRes)
  //         const nonEmptySubarrays = finalH.filter(subarray => subarray.length > 0)

  //         nonEmptySubarrays.length > 0 && nonEmptySubarrays[i].forEach((el: any) => {
  //           configs.push({
  //             type: 'TEXT',
  //             value: `           `,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'break', value: 1 },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',

  //           })
  //           configs.push({
  //             type: 'TEXT',
  //             value: el,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',
  //           })
  //         })

  //       }

  //       if (i < sectionHeadersBTForMoreRes.length) {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Change #${moreResCount} to ${sectionHeadersBTForMoreRes[i]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: '',
  //           textTypes: [
  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         moreResCount++;
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         })

  //         configs.push({
  //           type: 'TEXT',
  //           value: `Local Requirement`,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         });
  //         console.log(finalBT, 'finalBTTT')
  //         const nonEmptySubarrays = finalBT.filter(subarray => subarray.length > 0)

  //         nonEmptySubarrays.length > 0 && nonEmptySubarrays[i].forEach((el: any) => {
  //           configs.push({
  //             type: 'TEXT',
  //             value: `           `,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'break', value: 1 },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',

  //           })
  //           configs.push({
  //             type: 'TEXT',
  //             value: el,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',
  //           })
  //         })


  //       }

  //     }

  //     /* ----------------------- Config for Others ---------------------- */
  //     if (editTemplate.basicPrinciples_blackTextChangeType === "Other - provide rationale" || editTemplate.header_blackTextChangeType === "Other - provide rationale" || editTemplate.bodyText_blackTextChangeType === "Other - provide rationale" || this.otherRationaleSectionCheck(sections)) {
  //       configs.push({
  //         type: 'TEXT',
  //         value: ``,
  //         textTypes: [
  //           {
  //             key: 'HORIZONTAL_LINE'
  //           },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Rationale: `,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited rationale',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Other`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited rationale',
  //       });
  //     }
  //     let otherCount = 1
  //     if (editTemplate.basicPrinciples_blackTextChangeType === "Other - provide rationale") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `${editTemplate.basicPrinciples_blackTextChangeType_Comment}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${otherCount} to Basic Principles`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });

  //       otherCount++;

  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });
  //       basicPrincipleChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })

  //     }
  //     if (editTemplate.header_blackTextChangeType === "Other - provide rationale") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `${editTemplate.header_blackTextChangeType_Comment}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${otherCount} to ${headerTitlePara}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });

  //       otherCount++;

  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });
  //       headerChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })

  //     }
  //     if (editTemplate.bodyText_blackTextChangeType === "Other - provide rationale") {
  //       configs.push({
  //         type: 'TEXT',
  //         value: `${editTemplate.bodyText_blackTextChangeType_Comment}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });
  //       configs.push({
  //         type: 'TEXT',
  //         value: `Change #${otherCount} to ${headerTitlePara}`,
  //         textTypes: [
  //           { key: 'break', value: 1 },
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //       });

  //       otherCount++;

  //       configs.push({
  //         type: 'TEXT',
  //         value: `           `,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'break', value: 1 },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       })

  //       configs.push({
  //         type: 'TEXT',
  //         value: `Local Requirement`,
  //         textTypes: [
  //           {
  //             key: 'FONT_SIZE', value: '11'
  //           },
  //           { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //         ],
  //         sectionType: 'CHILDREN',
  //         elementType: 'ROW',
  //         uid: 'prohibited localreq',
  //       });
  //       bodyTextChangedText.difference.forEach((el: any) => {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',

  //         })
  //         configs.push({
  //           type: 'TEXT',
  //           value: el,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //         })
  //       })

  //     }
  //     const maxLengthHeadersOT = Math.max(sectionHeadersForOther.length, finalH.length);
  //     const maxLengthBodyTextOT = Math.max(sectionHeadersBTForOther.length, finalBT.length);

  //     for (let i = 0; i < Math.max(maxLengthHeadersOT, maxLengthBodyTextOT); i++) {

  //       if (i < sectionHeadersForOther.length && i < finalH.length) {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `${[sectionHeadersComment[i]]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Change #${otherCount} to ${sectionHeadersForOther[i]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });

  //         otherCount++;

  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         })

  //         configs.push({
  //           type: 'TEXT',
  //           value: `Local Requirement`,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         });
  //         const nonEmptySubarrays = finalH.filter(subarray => subarray.length > 0)

  //         nonEmptySubarrays.length > 0 && nonEmptySubarrays[i].forEach((el: any) => {
  //           configs.push({
  //             type: 'TEXT',
  //             value: `           `,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'break', value: 1 },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',

  //           })
  //           configs.push({
  //             type: 'TEXT',
  //             value: el,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',
  //           })
  //         })

  //       }
  //       if (i < sectionHeadersBTForOther.length) {
  //         configs.push({
  //           type: 'TEXT',
  //           value: `${[sectionBodyTextComment[i]]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });
  //         configs.push({
  //           type: 'TEXT',
  //           value: `Change #${otherCount} to ${sectionHeadersBTForOther[i]}`,
  //           textTypes: [
  //             { key: 'break', value: 1 },
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //         });

  //         otherCount++;

  //         configs.push({
  //           type: 'TEXT',
  //           value: `           `,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'break', value: 1 },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         })

  //         configs.push({
  //           type: 'TEXT',
  //           value: `Local Requirement`,
  //           textTypes: [
  //             {
  //               key: 'FONT_SIZE', value: '11'
  //             },
  //             { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //           ],
  //           sectionType: 'CHILDREN',
  //           elementType: 'ROW',
  //           uid: 'prohibited localreq',
  //         });
  //         const nonEmptySubarrays = finalBT.filter(subarray => subarray.length > 0)

  //         nonEmptySubarrays.length > 0 && nonEmptySubarrays[i].forEach((el: any) => {
  //           configs.push({
  //             type: 'TEXT',
  //             value: `           `,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'break', value: 1 },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',

  //           })
  //           configs.push({
  //             type: 'TEXT',
  //             value: el,
  //             textTypes: [
  //               {
  //                 key: 'FONT_SIZE', value: '11'
  //               },
  //               { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

  //             ],
  //             sectionType: 'CHILDREN',
  //             elementType: 'ROW',
  //           })
  //         })

  //       }

  //     }
  //     const blob = await DocxService.createDocument(configs);
  //     const file = new File([blob], "AdherenceReport.docx", {
  //       type: blob.type,
  //     });
  //     const documents3FileKey = `localproceduretemplate/${editTemplate.lpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate.versionNumber}/${file.name}`;
  //     editTemplate._adherenceReport = {} as FileUploadModelForLP;
  //     editTemplate._adherenceReport.s3filekey = documents3FileKey;
  //     editTemplate._adherenceReport.file = file;
  //     editTemplate._adherenceReport.name = "AdherenceReport";
  //     // editTemplate._adherenceReport.fileversion = editTemplate.versionNumber;
  //     editTemplate._adherenceReport.type = file.type as FileType;
  //     editTemplate._adherenceReport.ext = "docx";
  //     editTemplate._adherenceReport.lpId = editTemplate.lpId!;
  //     editTemplate._adherenceReport.sizeInKB = Math.round(file.size / 1024);
  //     editTemplate._adherenceReport.sizeInMB = Math.round(file.size / 1024 / 1024);
  //     const upload = await ProcedureFileService.uploadDocumentForLP(editTemplate._adherenceReport);
  //     if (upload) {
  //       await ProcedureFileService.docx2pdf(documents3FileKey, FileType.DOCX)
  //     }

  //     if (this.state.reportDownload) {
  //       const baseName = file.name.split(".docx")
  //       const pdfName = baseName[0] + '.pdf'
  //       const pdfFile = await ProcedureFileService.getPresignedUrl(`localproceduretemplate/${editTemplate.lpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate.versionNumber}/${pdfName}`, FileType.PDF)

  //       fetch(pdfFile!)
  //         .then((response) => {
  //           if (response.ok) {
  //             this.setState({ isLoading: false });
  //             return response.blob();
  //           }
  //           throw new Error('Network response was not OK.');
  //         })
  //         .then((blob) => {
  //           var link = document.createElement('a');
  //           link.href = window.URL.createObjectURL(blob);
  //           link.download = `Adherence Report.pdf`;
  //           document.body.appendChild(link); link.click();
  //           document.body.removeChild(link);
  //         }).catch((error) => {
  //           this.setState({ isLoading: false, error: true, errMsg: "Error generating Procedure Adherence Report." });
  //           console.error('Error:', error);
  //         });
  //     }
  //   } catch (error) {
  //     console.error('err during report generation', error);
  //     this.setState({ isLoading: false, error: true, errMsg: "Error generating Procedure Adherence Report." });
  //   }

  // }

  handleGenerate = async () => {
    let type = 'local';
    const { editTemplate, sections } = this.state;

    const configs: DocumentConfigModel[] = [];

    const gptHeaderConfigs = await DocumentService.getGPTHeaderConfigs(
      editTemplate
    );
    configs.push(...gptHeaderConfigs);

    const basicPrincipleTextConfigs =
      DocumentService.getGPTBasicPrincipleTextConfigs();
    configs.push(...basicPrincipleTextConfigs);

    const basicPricipleConfigs = RTEditorService.getDocConfigs(
      editTemplate._basicPrinciples,
      "basicPrinciple"
    );
    configs.push(...basicPricipleConfigs);

    const headerConfigs = RTEditorService.getDocConfigs(
      editTemplate._header,
      "header"
    );
    configs.push(...headerConfigs);
    configs.push({
      type: "TEXT",
      value: "",
      textTypes: [],
      sectionType: "CHILDREN",
    });
    const bodyTextConfigs = RTEditorService.getDocConfigs(
      editTemplate._bodyText,
      "bodyText"
    );
    configs.push(...bodyTextConfigs);
    configs.push({
      type: "TEXT",
      value: "",
      textTypes: [],
      sectionType: "CHILDREN",
    });

    sections.forEach((section) => {
      const headerSectionConfigs = RTEditorService.getDocConfigs(
        section._header!,
        `section-header-${section.sectionId}`
      );
      configs.push(...headerSectionConfigs);
      configs.push({
        type: "TEXT",
        value: "",
        textTypes: [],
        sectionType: "CHILDREN",
      });
      const bodyTextSectionConfigs = RTEditorService.getDocConfigs(
        section._bodyText,
        `section-body-${section.sectionId}`
      );
      configs.push(...bodyTextSectionConfigs);
      configs.push({
        type: "TEXT",
        value: "",
        textTypes: [],
        sectionType: "CHILDREN",
      });
    });

    const ownerApproverTableConfig =
      DocumentService.getOwnerApproverTableConfig(editTemplate, type);
    configs.push(...ownerApproverTableConfig);

    const footerConfigs = await DocumentService.getGPTFooterConfigs(
      editTemplate
    );
    configs.push(...footerConfigs);

    console.log("configs", configs);
    const blob = await DocxService.createDocument(configs);
    return blob;
  };

  onSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({ reportDownload: false })
    this.handleAdherenceReportGenerate()
    event.preventDefault();
    console.log("on submit ", this.props);
    console.log("send for approval clicked");
    this.setState({ submitDialog: true })

  }
  prohibitedLocallySectionCheck = (arr: any) => {

    const prohLocaCount = arr.find((el: any) => {
      if (el.header_blackTextChangeType === "Prohibited locally" || el.bodyText_blackTextChangeType === "Prohibited locally") {
        return true;
      } else {
        return false
      }
    })
    if (prohLocaCount) {
      return true
    } else {
      return false
    }
  }
  moreRestrictiveSectionCheck = (arr: any) => {
    const moreResCount = arr.find((el: any) => {
      if (el.header_blackTextChangeType === "More Restrictive than global requirement" || el.bodyText_blackTextChangeType === "More Restrictive than global requirement") {
        return true;
      } else {
        return false
      }
    })
    if (moreResCount) {
      return true
    } else {
      return false
    }
  }
  otherRationaleSectionCheck = (arr: any) => {
    const otherResCount = arr.find((el: any) => {
      if (el.header_blackTextChangeType === "Other - provide rationale" || el.bodyText_blackTextChangeType === "Other - provide rationale") {
        return true;
      } else {
        return false
      }
    })
    if (otherResCount) {
      return true
    } else {
      return false
    }
  }

  validationForSaveAsDraft = () => {
    const { editTemplate } = this.state;
    let final: boolean = true
    if (!editTemplate.templateName) {
      final = false
      this.setState({ templateNameValidation: true });
    }

    return final
  }


  onNoSelect = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    this.setState({ submitDialog: false })
    console.log("on submit ", this.state);
    console.log("send for approval clicked");
    // this.setState({ submitDialog: true })

    const { editTemplate } = this.state
    const result = await lptService.sendForApproval(editTemplate?.lpId!);
    if (result) {
      const env = Utils.getEnvVars();
      // const approverEmail = editTemplate?.modifyByEmail!
      const emailObj: EmailDTO = {
        to: editTemplate?.approverEmail!,
        cc: editTemplate?.modifyByEmail!,
        subject: `ACTION REQUIRED: ${editTemplate?.templateName} Procedure Review`,
        body: ` <p><a href="${env.UI_URL}/approver_doc_viewer/${editTemplate?.lpId}">Review Draft Procedure</a></p>
                <p>Hi ${editTemplate?.approverName},</p>
                <p>
                  <b>${editTemplate?.templateName}</b> procedure, version ${editTemplate?.versionNumber}, for ${editTemplate?.geoGraphyName} requires your attention.
                  As the approver, you are receiving this message with a link to procedure documents for your review and approval. We request your review within
                  one week of receipt of the approval request.
                </p>
                <p>
                  In order to access the ${editTemplate?.templateName}, click on the "Review Draft Procedure” link at the top of this message.
                </p>
                <p>
                  Please contact your Ethics and Compliance liaison <b>${editTemplate?.modifyBy}</b> with any questions regarding this procedure localization or the process. To view all procedures pending your review, you can access your <a href="${env.UI_URL}/approve_procedure">dashboard</a>.
                </p>
                <p>
                  Thank you for your time and diligence in completing review of this procedure.
                </p>
                <p>
                  Regards,
                </p>
                <p>
                  Ethics and Compliance Policy Center of Expertise.
                  <br>
                  <b><i>DO NOT RESPOND TO THE SENDER OF THIS MESSAGE</i></b>
                </p>`
        // body: "testing"
      };
      const emailSent = await EmailService.sendEmail(emailObj);
      console.log("email sent", emailSent);

      if (emailSent) {
        this.setState({ isSentForApproval: true }, () => {
          setTimeout(async () => {
            this.props.history.push(`/local_procedures`)
            // creating a clean doc,pdf and storing in s3 so eco can download, approver can view clean doc
            let filePath;
            if (editTemplate?.ipFilePath && editTemplate.ipFilePath.endsWith('.docx')) {
              ProcedureFileService.docx2pdf(editTemplate.ipFilePath, FileType.DOCX);
              await ProcedureFileService.cleandocument(
                editTemplate.ipFilePath,
                FileType.DOCX
              );

              const cleanDocPath = editTemplate.ipFilePath.split('.docx');
              filePath = cleanDocPath[0] + '_clean.docx';
              console.log("clean doc path", filePath);
            } else {
              console.log("Invalid input file path");
            }

            if (filePath) {
              ProcedureFileService.docx2pdf(filePath, FileType.DOCX);
            } else {
              console.log("File path is undefined");
            }
          }, 2000)
        }
        )
      } else {
        this.setState({ isEmailError: true })

      }





    }

    // this.setState({ isUploaded: true }, () => {
    // setTimeout( () => {
    // // this.props.history.push({pathname: `/eco_localization/${this.props.location.state.editTemplate?.lpId}`, state:{editTemplate: this.props.location.state.editTemplate, lpId: this.props.location.state.editTemplate?.lpId, isUploaded: true} })
    // this.props.history.push({pathname: `/local_procedures`})
    // // window.location.assign(
    // //                       `/eco_localization/${this.props.location.state.editTemplate?.lpId}?mode=view`
    // //                     );
    // // this.props.history.go(0);
    // },3000)
    // }
    // );

  }


}


export default withRouter(EcoLocalization);

