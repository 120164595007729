import { UserRole } from "../Common/Enums";

class LocalStorageService {
    private readonly _UserLoggedIn = 'userloggedin';
    private readonly _UserRoles = 'userRoles';

    isUserLoggedIn() {
        const isLoggedIn = localStorage.getItem(this._UserLoggedIn);
        return isLoggedIn === 'true';
    }

    setUserLoggedIn() {
        localStorage.setItem(this._UserLoggedIn, "true");
    }

    resetUserLoggedIn() {
        localStorage.setItem(this._UserLoggedIn, "false");
    }
}

const localStorageService = new LocalStorageService();
export default localStorageService;