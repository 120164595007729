import { Box } from "@mui/material";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import TabPanel from "../../../Components/Tab/TabPanel";
import PageHeader from "../../../Components/Text/PageHeader";
import TabBar from "./TabBar";
import { TabModel } from "./TabModel";
import ApprovedTab from  './Tabs/ApprovedTab'
import axios from "axios";
import gptService from "../../../Services/GPTService";
import lptService from "../../../Services/LPTService";
import { EcoViewCountTab } from "../../../Models/GlobalProcedureTemplateModel";
import GraphicalScope from "./Tabs/GraphicalScope";
import LocalProcedureTab from "./Tabs/LocalProcedureTab";
import DraftedTab from "./Tabs/DraftedTab";
import ApprovalPending from "./Tabs/ApprovalPending";
import RejectedTab from "./Tabs/RejectedTab";
import { LPTService } from "../../../Services";
import { LocalProcedureStatusType } from "../../../Common/Enums";

interface Props extends RouteComponentProps<any, any, any> { }
interface State {
    currentTab: TabModel;
    tabs: TabModel[];
}
class EcoView extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: "Initiate Localization",
            type: "Batch Generated",
            index: 0,
            count: 0,
        },
        // {
        //     name: "Localization In-Progress",
        //     type: "Drafted",
        //     index: 1,
        //     count: 0,
        // },
        {
            name: "Localization In Progress",
            type: "Procedure Generated",
            index: 1,
            count: 0,
        },
        {
            name: "Pending Approval",
            type: "Approval Pending",
            index: 2,
            count: 0,
        },
        {
            name: "Approved",
            type: "Approved",
            index: 3,
            count: 0,
        },
       
        {
            name: "Rejected",
            type: "Rejected",
            index: 4,
            count: 0,
        }
    ];
    constructor(props: Props) {
        super(props);
        this.state = {
            currentTab: this.Tabs[0],
            tabs: this.Tabs,
        };
    }

    getEcoTabCount = async () => {
        try {
            let GlobalCount = 0;
            let LocalCount = 0;
            let DraftCount = 0;
            let ApprovalPendingCount = 0;
            let ApprovedCount = 0;
            let RejectedCount = 0;

            const countWiseData = await LPTService.getEcoTabCountLocalProcedure();

            countWiseData.forEach((countData) => {
                if (countData.status === LocalProcedureStatusType.DRAFTED) {
                    DraftCount = countData.count;
                } else if (countData.status === LocalProcedureStatusType.BATCH_GENERATED) {
                    GlobalCount = countData.count;
                } else if (countData.status === LocalProcedureStatusType.APPROVAL_PENDING) {
                    ApprovalPendingCount = countData.count;
                } else if (countData.status === LocalProcedureStatusType.APPROVED) {
                    ApprovedCount = countData.count;
                } else if (countData.status === LocalProcedureStatusType.PROCEDURE_GENERATED) {
                    LocalCount = countData.count;
                } else if (countData.status === LocalProcedureStatusType.REJECTED) {
                    RejectedCount = countData.count;
                }
            });

            const updatedTabs = [...this.state.tabs];
            updatedTabs[0].count = GlobalCount;
            updatedTabs[1].count = DraftCount + LocalCount;
            updatedTabs[2].count = ApprovalPendingCount;
            updatedTabs[3].count = ApprovedCount;
            updatedTabs[4].count = RejectedCount;
            // updatedTabs[5].count = RejectedCount;

            this.setState({ tabs: updatedTabs });

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message:", error.message);
                return error.message;
            } else {
                console.log("unexpected error during fecthing:", error);
                return "An unexpected error occured";
            }
        }
    };

    componentDidMount(): void {
        // let currentTab = localStorage.getItem("currentTab");
        let currentTab = sessionStorage.getItem("currentTab");
        if(currentTab)
        {
            this.setState({ currentTab: JSON.parse(currentTab) });
        }
        this.getEcoTabCount()
    }
    handleProcedureDeleteCountUpdate = () => {
        //After delete get counts also;
        this.getEcoTabCount();
    }

    handleTabChange = (selectedTab: TabModel) => {
        this.setState({ currentTab: selectedTab });
        // localStorage.setItem("currentTab", JSON.stringify(selectedTab));
        sessionStorage.setItem("currentTab", JSON.stringify(selectedTab));
    }

    render(): React.ReactNode {
        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <PageHeader label="Local Procedures" />
                </Box>
                <TabBar
                    currentTab={this.state.currentTab}
                    tabs={this.Tabs}
                    onTabChange={this.handleTabChange}
                />
                <TabPanel value={this.state.currentTab.index} index={0}>
                    <GraphicalScope  handleProcedureDeleteCountUpdate={this.handleProcedureDeleteCountUpdate}/>
                </TabPanel>
                {/* <TabPanel value={this.state.currentTab.index} index={1}>
                    <DraftedTab />
                </TabPanel> */}
                <TabPanel value={this.state.currentTab.index} index={1}>
                    <LocalProcedureTab handleProcedureDeleteCountUpdate={this.handleProcedureDeleteCountUpdate} />
                </TabPanel>
                <TabPanel value={this.state.currentTab.index} index={2}>
                    <ApprovalPending handleProcedureDeleteCountUpdate={this.handleProcedureDeleteCountUpdate}/>
                </TabPanel>
                <TabPanel value={this.state.currentTab.index} index={3}>
                    <ApprovedTab handleProcedureDeleteCountUpdate={this.handleProcedureDeleteCountUpdate}/>
                </TabPanel>
                <TabPanel value={this.state.currentTab.index} index={4}>
                    <RejectedTab handleProcedureDeleteCountUpdate={this.handleProcedureDeleteCountUpdate}/>
                </TabPanel>

            </>
        );
    }

}

export default EcoView;
