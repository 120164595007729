import axios from "axios";
import { omit } from "lodash";
import { Component, Context } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import gptService from '../../../../Services/GPTService'
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel } from "@mui/material";
import SearchFilter from "../../../../Components/Search/SearchFilter";
import { IconType } from "../../../../Components/Button/IconButton";
import moment from "moment";
import DataLoaderEcoView from "../../../../Components/Table/DataLoaderEcoView";
import DataLoaderApproverView from "../../../../Components/Table/DataLoaderApproverView";
import lptService from "../../../../Services/LPTService";
import MyCustomSnackbar from "../../../../Components/SnackBar/MyCustomSnackbar";
import { AuthorizationService } from "../../../../Services";
import { RoleContext } from "../../../../Contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RedButton from "../../../../Components/Button/RedButton";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface Props extends RouteComponentProps<any, any, any> {
  handleProcedureDeleteCountUpdate: () => void;
 }

type SortType = {
  key: string;
  direction: string;
}

interface State {
  deleteSuccessPopup:boolean;
  templateData: any;
  page: number;
  order: string;
  orderBy: string;
  rows: number;
  limit: number;
  gptId?: number;
  search: string;
  searchedData: any
  isloading: boolean;
  searchDebounce: string;
  sortConfig: SortType | null;
  rowTotalCount: number | null;
  tab: number;
  isAdminUser:boolean,
  deleteLptPopup:boolean;
  deleteId:number;
}

class ApprovedTab extends Component<Props, State> {
  private searchTimeout: number | null = null;
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteSuccessPopup:false,
      tab: 4,
      sortConfig: null,
      templateData: [],
      page: 1,
      rows: 5,
      limit: 5,
      search: "",
      searchDebounce: "",
      order: "",
      orderBy: "",
      searchedData: [],
      isloading: false,
      rowTotalCount: 0,
      isAdminUser:false,
      deleteId:0,
      deleteLptPopup:false,
    };
  }
  componentDidMount(): void {
    const isAdminUser = AuthorizationService.isAdminUser(this.context);
    if(isAdminUser)
    {
      this.setState({isAdminUser:true});
    }
    const querySearch = {
      page: this.state.page,
      limit: this.state.limit,
      searchtext: this.state.searchDebounce,
      order: this.state.order,
      orderBy: this.state.orderBy,
      status: "Approved",
    }
    this.getLocalProcedure(querySearch);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      (prevState.page !== this.state.page ||
        prevState.limit !== this.state.limit ||
        prevState.searchDebounce !== this.state.searchDebounce
        || prevState.sortConfig !== this.state.sortConfig)
    ) {
      const querySearch = {
        page: this.state.page,
        limit: this.state.limit,
        searchtext: this.state.searchDebounce,
        order: this.state.sortConfig?.key,
        orderBy: this.state.sortConfig?.direction,
        status: "Approved",
      }
      this.getLocalProcedure(querySearch);
    }
    // if (prevState.search !== this.state.search) { this.searchfield() }
  }

  searchfield = () => {
    const { templateData, search } = this.state
    let searcher = templateData.filter((el: any) =>
      el.templateName.startsWith(search.toString().toUpperCase()))

    if (search.length > 0) {
      this.setState({ searchedData: searcher })
    } else {
      console.log(searcher, 'else'); this.setState({ searchedData: templateData })
    }
  };


  handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ search: event.target.value }, () => {
      console.log("search", this.state.search);
      if (this.state.search.length === 0) {
        this.setState({ searchDebounce: this.state.search });
      }
    })
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.setState({ searchDebounce: this.state.search });
    }
  };

  mappingLocalProcedureData(localProcedures: any, localProcedureFileUpload: any, count: number) {
    // Iterate over templateDataArray and update properties 
    localProcedures.forEach((localProcedure: { s3FilePathKey: any; fileName: any; lpId: any }) => {
      const matchingMapping = localProcedureFileUpload.filter((mapping: { lpId: any; }) => mapping.lpId === localProcedure.lpId);
      console.log("match", matchingMapping);

      if (matchingMapping.length > 0) {
        // Get the highest lpfuid from matchingMapping
        const highestLpfuid = matchingMapping.reduce((prev: any, current: any) => (prev.lpfuid > current.lpfuid ? prev : current));
        localProcedure.fileName = highestLpfuid.fileName;
        localProcedure.s3FilePathKey = highestLpfuid.s3FilePathKey;
        // localProcedure.fileName = matchingMapping[0].fileName
        // localProcedure.s3FilePathKey = matchingMapping[0].s3FilePathKey
      }
      else {
        localProcedure.fileName = ""
        localProcedure.s3FilePathKey = ''
      }


      //   const templateName = matchingMapping.filter(((country: { geographyType: string; }) => country.geographyType === "Country")).map((item: { geographyName: any; }) => item.geographyName).join(',')
      //   const uniqueCountries = Array.from(new Set(allCountry.split(','))).join(',')
      //   const allRegions = matchingMapping.filter(((country: { geographyType: string; }) => country.geographyType === "SubRegion")).map((item: { geographyName: any; }) => item.geographyName).join(',')
      //   const uniqueRegions = Array.from(new Set(allRegions.split(','))).join(',')
      //   const allAffiliate = matchingMapping.filter(((country: { geographyType: string; }) => country.geographyType === "Affiliate")).map((item: { geographyName: any; }) => item.geographyName).join(',')
      //   const uniqueAffilaite = Array.from(new Set(allAffiliate.split(','))).join(',')
      //   template.subregion = uniqueRegions;
      //   template.country = uniqueCountries;
      //   template.affiliate = uniqueAffilaite;
      // } else {
      //   template.subregion = "";
      //   template.country = "";
      //   template.affiliate = "";
      // }
    });
    console.log("loacl peocedure", localProcedures);
    this.setState({ templateData: localProcedures });
  }

  getLocalProcedure = async (querySearch: any) => {
    this.setState({ isloading: true });
    try {
      const data = await gptService.getLocalProcedure(querySearch);
      // console.log("here____",data);
      const formatedData = data?.localProcedures.map((el) => {
        return { ...el, modifiedDate: moment(el.modifiedDate).format("L"), approvalDate: moment(el.approvalDate).format("L"), templateName: `${el.templateName}_${el.geoGraphyName ? el.geoGraphyName : ""}(v${el.versionNumber})` };
      });
      const omitData = formatedData?.map((el) =>
        omit(el, [
          "headerImageFile",
          "effectiveDate",
          "footer",
          "footerImageFile",
          "basicPrinciples",
          "header",
          "bodyText",
          "gpTemplateFile",
          "gpUniqueId",
          "createdBy",
          "_bodyText",
          "_basicPrinciples",
          "_headerImageFile",
          "_footerImageFile",
        ])
      );
      this.mappingLocalProcedureData(omitData, data?.localProcedureFileUpload, data?.count!);

      this.setState({ isloading: false });
      if (data) {
        this.setState({ rowTotalCount: data.count });
      }
    } catch (error) {
      this.setState({ isloading: true });
      if (axios.isAxiosError(error)) {
        this.setState({ isloading: false });
        console.log("error message:", error.message);
        return error.message;
      } else {
        this.setState({ isloading: false });
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }

    }
  };



  requestSort = (key: string) => {
    const { sortConfig } = this.state;
    let direction = 'ASC';
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === 'ASC') {
        direction = 'DESC';
      }
    }
    this.setState({ sortConfig: { key, direction } });
    // this.sorter()
  }

  onChangePage = (page: number) => {
    if (page == 0) {
      this.setState({ page: 1 });
    } else {
      this.setState({ page });
    }
  };

  onChangeRow = (row: number) => {
    this.setState({ limit: row });
  };

  clickId = (_fieldClicked: string, record: any) => {
    console.log("field clicked", record);
    if (_fieldClicked === 'fileName') {
      this.props.history.push({
        pathname: '/translated_file_view', state: {
          templateData: record
        }
      })
    }
    else {
      this.props.history.push({
        pathname: '/eco_doc_viewer', state: {
          templateData: record,
          view: "EcoView"
        }
      });
      // this.props.history.go(0);
    }

  };

  handleDeleteLpt = async () => {
    let deleteLpt = await lptService.deleteLocalProcedure(this.state.deleteId);
    console.log("deleteLpt=", deleteLpt);
    if (deleteLpt?.deleted) {
      this.setState({ deleteLptPopup: false, deleteSuccessPopup: true });
      const querySearch = {
        page: this.state.page,
        limit: this.state.limit,
        searchtext: this.state.searchDebounce,
        order: this.state.order,
        orderBy: this.state.orderBy,
        status: "Approved",
      }
      this.props.handleProcedureDeleteCountUpdate();
      this.getLocalProcedure(querySearch);
    }
  }

  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        // window.location.assign(`/eco_localization/${record.lpId}?mode=edit`);
        this.props.history.push(`/eco_localization/${record.lpId}?mode=edit`);
        break;
      case "Delete":
        this.setState({ deleteLptPopup: true, deleteId: record.lpId });
        break;
    }
  };

  render() {

    if (this.state.isloading) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    return (
      <>
        <Dialog open={this.state.deleteLptPopup}>
          <Grid container>
            <Grid item>
              <DialogTitle color="red">
                {" "}
              Delete Procedure.
              </DialogTitle>
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                icon={faXmark}
                size="lg"
                onClick={() => {
                  this.setState({ deleteLptPopup: false });
                }}
                style={{ marginTop: 22, marginLeft: 134 }}
              />
            </Grid>
          </Grid>
          <DialogContent dividers>
            <DialogContentText marginTop={2} color={"black"}>
              Are you sure you want to delete this procedure.
            </DialogContentText>
            <DialogContent>
              <Grid container mt={3} ml={-3}>
                <Grid item mr={2}>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      this.setState({ deleteLptPopup: false });
                    }}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <RedButton
                    label="Yes"
                    onClick={() => {
                      this.handleDeleteLpt()
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </DialogContent>
        </Dialog>
        <MyCustomSnackbar
          message="Local Procedure Template has been deleted successfully."
          severity="success"
          actions={<></>}
          open={this.state.deleteSuccessPopup}
          onClose={() => {
            this.setState({ deleteSuccessPopup: false });
          }}
        />
        <Grid container spacing={2}>
          <Grid item>
            <InputLabel>Search Procedure or Geography</InputLabel>
            <SearchFilter value={this.state.search} onChange={this.handleSearch}  onKeyDown={this.onKeyDown}/>
          </Grid>

        </Grid>
        <DataLoaderEcoView
          tab={this.state.tab}
          sortConfig={this.state.sortConfig}
          requestSort={this.requestSort}
          rowsPerPage={this.state.limit}
          tableData={this.state.templateData}
          page={this.state.page}
          actionArray={this.state.isAdminUser ? ["Edit","Delete"] :["Edit"]}
          onChangePage={this.onChangePage}
          onChangeRow={this.onChangeRow}
          onClickLink={this.clickId}
          rowTotalCount={this.state.rowTotalCount ? this.state.rowTotalCount : 0}
          onClickCheckBox={() => { }}
          onActionClick={this.onActionClick}
        />
      </>
    );
  }
}

export default withRouter(ApprovedTab);