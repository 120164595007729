import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Grid, InputAdornment } from "@mui/material";
import React from "react";
import TextInputFieldDelay from "../TextInputField/TextInputFieldDelay";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  label?: "";
  value: any;
  placeholder?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface State {

}

class SearchFilter extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, onChange, value, placeholder, onKeyDown } = this.props;

    return (
      <Grid container>
        <Grid item>
          <TextInputFieldDelay
            id="searchFilter"
            label={label ?? ''}
            value={value}
            useDelay={true}
            endAdornment={(
              <InputAdornment position="end">
                <FontAwesomeIcon
                  color="red"
                  icon={faMagnifyingGlass}
                  cursor="pointer"
                  onClick={() => { 
                    if (onKeyDown) {
                      onKeyDown({ key: "Enter"} as React.KeyboardEvent<HTMLInputElement>);
                    }
                  }}
                />{" "}
              </InputAdornment>
            )}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </Grid>{" "}
      </Grid>
    );
  }
}
export default SearchFilter;
