import React, { Context } from "react";
import { AppBar, Toolbar, Divider, Box, Typography, Grid } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EliLillyLogo from "../../Assets/Lilly_Logo.svg";
import Menu from "./Menu/Index";
import { RoleContext } from "../../Contexts";

interface Props { }
interface State { }
class Header extends React.Component<Props, State> {
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;

  render() {
    const { userRoles, userInfo } = this.context;

    return (
      <AppBar position="sticky" color="transparent">
        <Box
          display="flex"
          style={{ backgroundImage: "linear-gradient(to right, #D52B1E,#78105B,#273E69)", cursor: "pointer" }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item>
                  <Toolbar>
                    <img
                      alt="Lilly Logo"
                      src={EliLillyLogo}
                      height="42px"
                      width="79px"
                      style={{ margin: 10, filter: "brightness(0)invert(1)" }}
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ margin: 10, backgroundColor: "#ffffff" }}
                    />
                    <Typography
                      style={{ color: "#ffffff", fontSize: 20, fontWeight: 500 }}
                    >
                      Procedure Management Portal
                    </Typography>
                  </Toolbar>
                </Grid>
                <Grid item>
                  <Grid container>
                    {userRoles.length > 0 ? <Menu /> : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={1}
                style={{ marginRight: 10, color: '#FFFFFF' }}>
                <Grid item>
                  <AccountCircleIcon
                    style={{
                      height: "2.4rem",
                      width: "2.4rem",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>{userInfo?.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    );
  }

  handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ accountEl: event.currentTarget });
  }

  handleAccountClose = () => {
    this.setState({ accountEl: null });
  }
}

export default Header;
