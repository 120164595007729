import { faEarth, faFile, faFolder, faHouse } from "@fortawesome/free-solid-svg-icons";
import { UserRole } from "../../../Common/Enums";
import MenuModel from "./MenuModel";

const Menus: MenuModel[] = [
    // {
    //     name: "Home",
    //     path: "/home",
    //     icon: faHouse,
    //     userRoles: [UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER],
    // },
    // {
    //     name: "Library",
    //     path: "/library",
    //     icon: faFolder,
    //     userRoles: [UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER],
    // },
    {
        name: "Procedure Templates",
        path: "/procedure_templates",
        icon: faEarth,
        userRoles: [UserRole.ADMIN_USER, UserRole.READONLY_USER],
    },
    {
        name: "Procedure Exceptions",
        path: "/procedure_exception" ,
        icon: faFile,
        userRoles: [UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER, UserRole.GLOBAL_USER],
    },
    {
        name: "Local Procedures",
        path: "/local_procedures",
        icon: faFile,
        userRoles: [UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER],
    },
    {
        name: "Procedure Approval",
        path: "/approve_procedure",
        icon: faFile,
        userRoles: [UserRole.GLOBAL_USER],
    },
];

export default Menus;