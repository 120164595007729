import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, FormLabel, Grid, InputLabel, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';

import { Component } from 'react'
import MultiAutoComplete from '../../Components/AutoComplete';
import CollapsibleCard from '../../Components/Card/CollapsibleCard';
import RadioButtons from '../../Components/RadioButtons/RadioButtons';
import SingleSelect from '../../Components/Select/SingleSelect';
import TextAreaInputField from '../../Components/TextInputField/TextAreaInput';
import TextInputField from '../../Components/TextInputField/TextInputField';
import DatePicker from '../../Components/DatePicker/DatePicker';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ExceptionForm } from '../../Models/ExceptionForm';
import SelectModel from '../../Components/Select/SelectModel';
import { RenewalForm } from '../../Models/RenewalForm';
import SearchableAutoComplete from '../../Components/SearchableAutoComplete';

interface Props extends RouteComponentProps<any, any, any> {
    exceptionForm: ExceptionForm | RenewalForm;
    showPreviousVersion?: boolean;
    disableProcedure?: boolean;
    onTextInputChange: ((event: any) => void);
    onSelectProcedure: ((selected: any) => void);
    onSelectBusinessScope: ((selected: any) => void);
    onSelectGeography: ((selected: any) => void);
    handleEcAssociateVpOpRadioButtons: ((event: any) => void);
    handleexceptionClassificationRadioButtons: ((event: any) => void);
    handleEcAssociateVPDateChange: ((value: Date | null) => void);
    handleEcGeographicDateChange: ((value: Date | null) => void);
    handleEcBlConsultDateChange: ((value: Date | null) => void);
    handleExceptionEffDateChange: ((value: Date | null) => void);
    handleExceptionExpiryDateChange: ((value: Date | null) => void);
    handleLegalConsultDateChange: ((value: Date | null) => void);
    handleProcedureOwnerDateChange: ((value: Date | null) => void);
    load?: boolean;
    onDropDownChange: ((selected: SelectModel) => void);
    procedureNames: any[];
    businessScope: any[];
    geographyScope: any[];
    exceptionBusinesData: any[];
    geographyScopeData: any[];
    procedureNameData: any[];
    approverNames: any[];
    m4LegalAttrLoading: boolean;
    gmRegionalLoading: boolean;
    ibuHubGmLoading: boolean;
    assocVpECOLoading: boolean;
    m4EcoBlLoading: boolean;
    m4LegalAttrFRAPLoading: boolean;
    gmFuncVPLoading: boolean;
    assocVpIBULoading: boolean;
    chiefAccOffLoading: boolean;
    chiefProcOfcLoading: boolean;
    localBusOwnerLoading: boolean;
    localChiefFinOfcLoading: boolean;
    SenrEntrLoading: boolean;
    SeniorVPCFOLoading: boolean;
    localCFOLoading: boolean;
    m5F12BLLoading: boolean;
    m4LegalAttorneySpEcoBlVal: any;
    gmRegionalAffiliateVpVal: any;
    ibuHubGmVal: any;
    associateVpEcoIbuVal: any;
    associateVpLegalIbuVal: any;
    m4EcoBlVal: any;
    m4LegalAttrEcoBlFrapVal: any;
    gmFunctionVpr8Val: any;
    chiefAccOfficerVal: any;
    chiefProcOfficerVal: any;
    localBusinessOwnerVal: any;
    localChiefFinanOwnerVal: any;
    seniorDirEnterTravExpenVal: any;
    seniorVpChiefFinanOffcVal: any;
    localCfoVal: any;
    m5F12BusinessLeaderVal: any;
    setM5F12BusinessLeaderValue: ((InputValue: any) => void);
    setSeniorDirEnterTravExpenValue: ((InputValue: any) => void);
    setSeniorVpChiefFinanOffcValue: ((InputValue: any) => void);
    setM4LegalAttrEcoBlFrapValue: ((InputValue: any) => void);
    setM4LegalAttorneySpEcoBlValue: ((InputValue: any) => void);
    setM4EcoBlValue: ((InputValue: any) => void);
    setLocalChiefFinanOwnerValue: ((InputValue: any) => void);
    setLocalCfoValue: ((InputValue: any) => void);
    setIbuHubGmValue: ((InputValue: any) => void);
    setGmRegionalAffiliateVpValue: ((InputValue: any) => void);
    setGmFunctionVpr8Value: ((InputValue: any) => void);
    setChiefProcOfficerValue: ((InputValue: any) => void);
    setChiefAccOfficerValue: ((InputValue: any) => void);
    setAssociateVpLegalIbuValue: ((InputValue: any) => void);
    setAssociateVpEcoIbuValue: ((InputValue: any) => void);
    setLocalBusinessOwnerValue: ((InputValue: any) => void);
    onAssociateVpEcoIbuSelect: ((InputValue: any) => void);
    onAssociateVpLegalIbuSelect: ((InputValue: any) => void);
    onChiefAccOfficerSelect: ((InputValue: any) => void);
    onChiefProcOfficerSelect: ((InputValue: any) => void);
    onGmFunctionVpr8Select: ((InputValue: any) => void);
    onGmRegionalAffiliateVpSelect: ((InputValue: any) => void);
    onIbuHubGmSelect: ((InputValue: any) => void);
    onLocalBusinessOwnerSelect: ((InputValue: any) => void);
    onLocalCfoSelect: ((InputValue: any) => void);
    onLocalChiefFinanOwnerSelect: ((InputValue: any) => void);
    onM4EcoBlSelect: ((InputValue: any) => void);
    onM4LegalAttorneySpEcoBlSelect: ((InputValue: any) => void);
    onM4LegalAttrEcoBlFrapSelect: ((InputValue: any) => void);
    onM5F12BusinessLeaderSelect: ((InputValue: any) => void);
    onSeniorDirEnterTravExpenSelect: ((InputValue: any) => void);
    onSeniorVpChiefFinanOffcSelect: ((InputValue: any) => void);
}

interface State {

}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 15,
        maxWidth: "none",
        cursor: "pointer",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "white",
        fontSize: 20,
    },
}));

const ExceptionType = [
    { text: "Global Ethics & Compliance Procedure", value: "Global Ethics & Compliance Procedure" },
    { text: "Global Procedure on Following FRAP", value: "Global Procedure on Following FRAP" },
    { text: "Global Travel and Expense Procedure", value: "Global Travel and Expense Procedure" },
    { text: "Lilly International Affiliate/Hub Local Procedure Requirements", value: "Lilly International Affiliate/Hub Local Procedure Requirements" },
    { text: "Recognition Expense", value: "Recognition Expense" },
];

class ExceptionFormComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { exceptionForm, associateVpEcoIbuVal, associateVpLegalIbuVal, chiefAccOfficerVal, chiefProcOfficerVal, gmFunctionVpr8Val, gmRegionalAffiliateVpVal, ibuHubGmVal, localBusinessOwnerVal, localCfoVal, localChiefFinanOwnerVal, m4EcoBlVal, m4LegalAttrEcoBlFrapVal, m5F12BusinessLeaderVal, seniorDirEnterTravExpenVal, seniorVpChiefFinanOffcVal, showPreviousVersion, onTextInputChange, handleEcAssociateVPDateChange, handleEcAssociateVpOpRadioButtons, businessScope, geographyScope, onDropDownChange, procedureNames, handleEcBlConsultDateChange, handleEcGeographicDateChange, handleExceptionEffDateChange, handleExceptionExpiryDateChange, handleLegalConsultDateChange, handleProcedureOwnerDateChange, handleexceptionClassificationRadioButtons, onSelectBusinessScope, onSelectGeography, onSelectProcedure
    , } = this.props
        console.log("geographyScope",geographyScope)
        return (
            <Box
                style={{
                    marginTop: "0.5em",
                }}
            >
                <Grid container>
                    <Grid item xs={showPreviousVersion ? 6 : 12}>
                        <CollapsibleCard
                            title='Procedure Details'
                            divider
                            initiallyOpen
                            required
                            subTitle="Provide information the approvers needs to evaluate the exception"
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
                        >
                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={showPreviousVersion ? 4 : 6}>
                                    <Grid item xs={8} sm={4} md={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <InputLabel sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'red',
                                                    },
                                                }} required>Title</InputLabel>
                                            </Grid>
                                            <Grid item xs={6} justifyContent={"flex-end"} container>
                                                <LightTooltip
                                                    placement="bottom"
                                                    title="This is the title of exception"
                                                    arrow
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        <TextInputField
                                            value={exceptionForm.title}
                                            placeholder="Enter Title"
                                          
                                            onChange={onTextInputChange}
                                            name="title"
                                        />
                                    </Grid>
                                    <Grid item xs={2.85}>
                                        <InputLabel> Exception Effective Date </InputLabel>
                                        <DatePicker
                                            name="exceptionEffectiveDate"
                                            onChange={handleExceptionEffDateChange}
                                            value={exceptionForm.exceptionEffectiveDate}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={showPreviousVersion ? 11.3 : 6}>
                                                <InputLabel sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'red',
                                                    },
                                                }} required>Exception Requested By</InputLabel>
                                            </Grid>
                                            <Grid item xs={showPreviousVersion ? 0.1 : 6} justifyContent={"flex-end"} container>
                                                <LightTooltip
                                                    placement="bottom"
                                                    title="This is the business contact who has requested the exception."
                                                    arrow
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        <TextInputField
                                            value={exceptionForm.exceptionRequestBy}
                                            placeholder="Requestor Name"
                                            onChange={onTextInputChange}
                                            name="exceptionRequestBy"
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={showPreviousVersion ? 11.3 : 7}>
                                                <InputLabel sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'red',
                                                    },
                                                }} required>Exception Form Completed By</InputLabel>
                                            </Grid>
                                            <Grid item xs={showPreviousVersion ? 0.1 : 5} justifyContent={"flex-end"} container>
                                                <LightTooltip
                                                    placement="bottom"
                                                    title="This is the individual submitting this form."
                                                    arrow
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        <TextInputField
                                            isDisabled
                                            value={exceptionForm.exceptionCompletedBy}
                                            placeholder="Completed By"
                                            onChange={onTextInputChange}
                                            name="exceptionCompletedBy"
                                        />
                                    </Grid>
                                   
                                    <Grid item xs={1.86}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={showPreviousVersion ? 11.3 : 6.5}>
                                                <InputLabel sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'red',
                                                    },
                                                }} required>Exception Expiration Date </InputLabel>
                                            </Grid>
                                            <Grid item xs={showPreviousVersion ? 0.1 : 5.5} justifyContent={"flex-end"} container>
                                                <LightTooltip
                                                    placement="bottom"
                                                    title="This is the expiration date of exception"
                                                    arrow
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        <DatePicker
                                            name="exceptionExpirationDate"
                                            onChange={handleExceptionExpiryDateChange}
                                            
                                            minDate={Date.now()}
                                            value={exceptionForm.exceptionExpirationDate}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={showPreviousVersion ? 7 : 6}>
                                                <FormLabel sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'red',
                                                    },
                                                }} required>Exception Classification</FormLabel>
                                            </Grid>
                                            <Grid item xs={0.1} justifyContent={"flex-end"} container>
                                                <LightTooltip
                                                    placement="bottom"
                                                    title="Exceptions classified as New are active, ongoing exceptions with a typical expiration of two years. One-Time Events are exceptions for a single meeting or activity expiring commensurate with the activity."
                                                    arrow
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                        <Box>
                                            <RadioButtons name="exceptionClassfication" value={exceptionForm.exceptionClassification} onChange={handleexceptionClassificationRadioButtons} direction="row" buttonValues={[{
                                                value: "New",
                                                label: "New"
                                            }, {
                                                value: "One - Time Event",
                                                label: "One - Time Event"
                                            }]} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={3.89}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <InputLabel sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'red',
                                                    },
                                                }} required>Procedure(s)</InputLabel>
                                            </Grid>
                                            <Grid item xs={8} justifyContent={"flex-end"} container>
                                                <Typography color={"GrayText"}>(Select all that apply)</Typography>
                                            </Grid>
                                        </Grid>
                                        <MultiAutoComplete
                                            selectedItems={[...procedureNames]}
                                            options={this.props.procedureNameData}
                                            onChange={onSelectProcedure}
                                            disabled={this.props.disableProcedure}
                                        
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={showPreviousVersion ? 9 : 7.5}>
                                                <InputLabel sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'red',
                                                    },
                                                }} required>Geography scope for the exception</InputLabel>
                                            </Grid>
                                            <Grid item xs={showPreviousVersion ? 3 : 4.5} justifyContent={"flex-end"} container>
                                                <Typography color={"GrayText"}>(Select all that apply)</Typography>
                                            </Grid>
                                        </Grid>
                                        <MultiAutoComplete
                                            selectedItems={(geographyScope.length ===  1 &&  geographyScope[0].text === '') ? [] : geographyScope}
                                            options={this.props.geographyScopeData}
                                            onChange={onSelectGeography}

                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={3.89}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={7.5}>
                                                <InputLabel sx={{
                                                    '& .MuiFormLabel-asterisk': {
                                                        color: 'red',
                                                    },
                                                }} required>Business area scope for the exception</InputLabel>
                                            </Grid>
                                            <Grid item xs={4.5} justifyContent={"flex-end"} container>
                                                <Typography color={"GrayText"}>(Select all that apply)</Typography>
                                            </Grid>
                                        </Grid>
                                        <MultiAutoComplete
                                            
                                            selectedItems={(businessScope.length ===  1 &&  businessScope[0].text === '') ? [] : businessScope}
                                            options={this.props.exceptionBusinesData}
                                            onChange={onSelectBusinessScope}

                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={6}>
                                        <InputLabel sx={{
                                            '& .MuiFormLabel-asterisk': {
                                                color: 'red',
                                            },
                                        }} required>Describe the exception you are requesting</InputLabel>
                                        <TextAreaInputField name='exceptionDescription' value={exceptionForm.exceptionDescription} onChange={onTextInputChange} />
                                    </Grid>
                                   
                                    <Grid item xs={8} sm={4} md={6}>
                                        <InputLabel sx={{
                                            '& .MuiFormLabel-asterisk': {
                                                color: 'red',
                                            },
                                        }} required>What is the impact on the business if the exception is granted? If not granted?</InputLabel>
                                        <TextAreaInputField name='exceptionImpactAfterGrant' value={exceptionForm.exceptionImpactAfterGrant} onChange={onTextInputChange} />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={6}>
                                        <InputLabel sx={{
                                            '& .MuiFormLabel-asterisk': {
                                                color: 'red',
                                            },
                                        }} required>What is the rationale for this exception?</InputLabel>
                                        <TextAreaInputField name='exceptionRationale' value={exceptionForm.exceptionRationale} onChange={onTextInputChange} />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={6}>
                                        <InputLabel sx={{
                                            '& .MuiFormLabel-asterisk': {
                                                color: 'red',
                                            },
                                        }} required>Insert text from the procedure for which you are requesting an exception</InputLabel>
                                        <TextAreaInputField name='requestedTextFromProcedure' value={exceptionForm.requestedTextFromProcedure} onChange={onTextInputChange} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CollapsibleCard>
                        <CollapsibleCard title='Consultation: Ethics and Compliance'
                            divider
                            required
                            subTitle="Summarize the advice you received on this potential exception"
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>

                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={showPreviousVersion ? 4 : 6}>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 2 : 3.7}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <InputLabel>ECO/BL</InputLabel>
                                            </Grid>
                                            <Grid item xs={6} justifyContent={"flex-end"} container>
                                                <LightTooltip
                                                    placement="bottom"
                                                    title="ECO/BL consult applies to IBU exceptions and is optional for other exceptions."
                                                    arrow
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                                                </LightTooltip>
                                            </Grid>

                                        </Grid>
                                        <TextInputField
                                            value={exceptionForm.ecoBl}
                                            placeholder="ECO/BL"
                                            onChange={onTextInputChange}
                                            name="ecoBl"
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={2}>
                                        <InputLabel>ECO/BL Consult Date</InputLabel>
                                        <DatePicker
                                            name="ecoBlConsultDate"
                                            onChange={handleEcBlConsultDateChange}
                                            width={showPreviousVersion ? '20.3rem' : '28rem'}
                                           
                                            value={exceptionForm.ecoBlConsultDate}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 3.7}>
                                        {showPreviousVersion ?
                                            <InputLabel sx={{
                                                wordWrap: 'break-word',
                                                wordBreak: 'break-all',
                                                maxWidth: '40em'
                                            }}>Per ECO/BL: What do applicable international, regional or local industry association <br></br> code(s) say about the subject?
                                            </InputLabel>
                                            : <InputLabel sx={{
                                                wordWrap: 'break-word',
                                                wordBreak: 'break-all',
                                                maxWidth: '55em'
                                            }}>Per ECO/BL: What do applicable international, regional or local industry association code(s) say about the subject?
                                            </InputLabel>}
                                        <TextInputField
                                            value={exceptionForm.ecoBlAssociationCodeSubjectDescription}
                                            onChange={onTextInputChange}
                                            name="ecoBlAssociationCodeSubjectDescription"
                                        />

                                    </Grid>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 2 : 3.7}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <InputLabel>E&C Geographic M4+</InputLabel>
                                            </Grid>
                                            <Grid item xs={6} justifyContent={"flex-end"} container>
                                                <LightTooltip
                                                    placement="bottom"
                                                    title="For IBU: M4+/Associate VP+ approval is obtained after LIIG - these fields can remain blank."
                                                    arrow
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} color="#00749E" />
                                                </LightTooltip>
                                            </Grid>

                                        </Grid>
                                        <TextInputField
                                            value={exceptionForm.ecGeographicm4}

                                            onChange={onTextInputChange}
                                            name="ecGeographicm4"
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={2}>
                                        <InputLabel>E&C Geographic M4+ Consult Date</InputLabel>
                                        <DatePicker
                                            name="ecGeographicm4ConsultDate"
                                            onChange={handleEcGeographicDateChange}
                                            width={showPreviousVersion ? '20.3rem' : '28rem'}
                                           
                                            value={exceptionForm.ecGeographicm4ConsultDate}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 2 : 3.7}>
                                        <InputLabel>E&C Geographic M4+ Comments</InputLabel>
                                        <TextAreaInputField
                                            value={exceptionForm.ecGeographicm4Comments}
                                            name='ecGeographicm4Comments'
                                            onChange={onTextInputChange}

                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormLabel>E&C Associate Vice President Operations </FormLabel>
                                        <Box>
                                            <RadioButtons name="ecAssociateVpOp" value={exceptionForm.ecAssociateVpOp} onChange={handleEcAssociateVpOpRadioButtons} direction="row" buttonValues={[{
                                                value: "Damon Strickland",
                                                label: "Damon Strickland"
                                            }]} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 3 : 4}>
                                        <InputLabel>E&C Associate Vice President Operations Consult Date</InputLabel>
                                        <DatePicker
                                            name="ecAssociateVpOpConsultDate"
                                            onChange={handleEcAssociateVPDateChange}
                                            width={showPreviousVersion ? '42rem' : '38rem'}
                                         
                                            value={exceptionForm.ecAssociateVpOpConsultDate}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 6 : 3.7}>
                                        <InputLabel>E&C Associate Vice President Operations Comments</InputLabel>
                                        <TextAreaInputField
                                            value={exceptionForm.ecAssociateVpOpComments}
                                            name='ecAssociateVpOpComments'
                                            onChange={onTextInputChange}
                                        />

                                    </Grid>
                                </Grid>
                            </Box>
                        </CollapsibleCard>
                        <CollapsibleCard title='Consultation: Legal'
                            divider
                            required
                            subTitle="Summarize the advice you received on this potential exception. Legal consult applies to
                        IBU exceptions and is optional for other exceptions."
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={showPreviousVersion ? 4 : 6}>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 2 : 3.7}>
                                        <InputLabel>Assigned Legal Counsel</InputLabel>
                                        <TextInputField
                                            value={exceptionForm.assignedLegalCounsel}

                                            onChange={onTextInputChange}
                                            name="assignedLegalCounsel"
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 2 : 2}>
                                        <InputLabel>Legal Consult Date </InputLabel>
                                        <DatePicker
                                            name="legalConsultDate"
                                            onChange={handleLegalConsultDateChange}
                                            width={showPreviousVersion ? '20.3rem' : '32rem'}
                                         
                                            value={exceptionForm.legalConsultDate}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 3.7}>
                                        <InputLabel>Per Legal: What are the risks associated with the exception?</InputLabel>
                                        <TextInputField
                                            value={exceptionForm.legalRiskWithException}

                                            onChange={onTextInputChange}
                                            name="legalRiskWithException"
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={6}>
                                        <InputLabel>Per Legal:How is the exception compliant with applicable law(s),local and foreign laws applied <br></br> to the jurisdiction (e.g., U.S, FCPA,trade sanctions,other countries with extraterritorial reach)?</InputLabel>
                                        <TextInputField
                                            value={exceptionForm.exceptionCompliantWithLaws}

                                            onChange={onTextInputChange}
                                            name="exceptionCompliantWithLaws"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CollapsibleCard>
                        <CollapsibleCard title='Consultation: Procedure Owner'
                            divider
                            required
                            subTitle="Summarize the advice you received on this potential exception"
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={showPreviousVersion ? 4 : 6}>
                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 2 : 4}>
                                        <InputLabel>Procedure Owner</InputLabel>
                                        <TextInputField
                                            value={exceptionForm.procedureOwner}

                                            onChange={onTextInputChange}
                                            name="procedureOwner"
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={2}>
                                        <InputLabel>Procedure Owner Consult Date</InputLabel>
                                        <DatePicker
                                            name="procedureOwnerConsultDate"
                                            onChange={handleProcedureOwnerDateChange}
                                            width={showPreviousVersion ? '20.3rem' : '28rem'}
                                            
                                            value={exceptionForm.procedureOwnerConsultDate}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4} md={4}>
                                        <InputLabel>Procedure Owner Comments</InputLabel>
                                        <TextAreaInputField
                                            value={exceptionForm.procedureOwnerComments}
                                            name='procedureOwnerComments'
                                            onChange={onTextInputChange}

                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CollapsibleCard>
                        <CollapsibleCard title='Approval: Global Ethics and Compliance Procedure'
                            divider
                            required
                            subTitle="Summarize the advice you received on this potential exception"
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={showPreviousVersion ? 4 : 6}>
                                    <Grid item xs={8} sm={4} md={4}>
                                        <InputLabel sx={{
                                            '& .MuiFormLabel-asterisk': {
                                                color: 'red',
                                            },
                                        }} required>Exception Type for Approval </InputLabel>
                                        <SingleSelect value={exceptionForm.exceptionTypeForApproval} name='exceptionTypeForApproval' onChange={onDropDownChange} values={ExceptionType} />
                                    </Grid>
                                    {exceptionForm.exceptionTypeForApproval === "Global Ethics & Compliance Procedure" ? <><Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                        <InputLabel sx={{
                                            '& .MuiFormLabel-asterisk': {
                                                color: 'red',
                                            },
                                        }} required>M4+ Legal Attorney Supporting ECO/BL (for E&C Procedure)</InputLabel>
                                        <SearchableAutoComplete
                                            setInputValue={this.props.setM4LegalAttorneySpEcoBlValue}
                                            onSelect={this.props.onM4LegalAttorneySpEcoBlSelect}
                                            options={this.props.approverNames}
                                            selectedItems={this.props.m4LegalAttorneySpEcoBlVal}
                                            loading={this.props.m4LegalAttrLoading}
                                            placeholder="Search by First/Last Name or Email"
                                        />
                                    </Grid><Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                            <InputLabel sx={{
                                                '& .MuiFormLabel-asterisk': {
                                                    color: 'red',
                                                },
                                            }} required>Regional or Affiliate GM or Business VP at M5/R8+ Level </InputLabel>
                                            <SearchableAutoComplete
                                                setInputValue={this.props.setGmRegionalAffiliateVpValue}
                                                onSelect={this.props.onGmRegionalAffiliateVpSelect}
                                                options={this.props.approverNames}
                                                selectedItems={gmRegionalAffiliateVpVal}
                                                loading={this.props.gmRegionalLoading}
                                                placeholder="Search by First/Last Name or Email"
                                            />
                                        </Grid></> :
                                        exceptionForm.exceptionTypeForApproval === "Lilly International Affiliate/Hub Local Procedure Requirements" ?
                                            <>
                                                <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                    <InputLabel sx={{
                                                        '& .MuiFormLabel-asterisk': {
                                                            color: 'red',
                                                        },
                                                    }} required>IBU Hub GM</InputLabel>
                                                    <SearchableAutoComplete
                                                        setInputValue={this.props.setIbuHubGmValue}
                                                        onSelect={this.props.onIbuHubGmSelect}
                                                        options={this.props.approverNames}
                                                        selectedItems={ibuHubGmVal}
                                                        loading={this.props.ibuHubGmLoading}
                                                        placeholder="Search by First/Last Name or Email"
                                                    />
                                                </Grid>
                                                <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                    <InputLabel sx={{
                                                        '& .MuiFormLabel-asterisk': {
                                                            color: 'red',
                                                        },
                                                    }} required>Associate VP or Above, Ethics and Compliance, IBU</InputLabel>
                                                    <SearchableAutoComplete
                                                        setInputValue={this.props.setAssociateVpEcoIbuValue}
                                                        onSelect={this.props.onAssociateVpEcoIbuSelect}
                                                        options={this.props.approverNames}
                                                        selectedItems={associateVpEcoIbuVal}
                                                        loading={this.props.assocVpECOLoading}
                                                        placeholder="Search by First/Last Name or Email"
                                                    />
                                                </Grid>
                                                <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                    <InputLabel sx={{
                                                        '& .MuiFormLabel-asterisk': {
                                                            color: 'red',
                                                        },
                                                    }} required>Associate VP or Above, Legal, IBU</InputLabel>
                                                    <SearchableAutoComplete
                                                        setInputValue={this.props.setAssociateVpLegalIbuValue}
                                                        onSelect={this.props.onAssociateVpLegalIbuSelect}
                                                        options={this.props.approverNames}
                                                        selectedItems={associateVpLegalIbuVal}
                                                        loading={this.props.assocVpIBULoading}
                                                        placeholder="Search by First/Last Name or Email"
                                                    />
                                                </Grid>
                                            </>
                                            :
                                            exceptionForm.exceptionTypeForApproval === "Global Procedure on Following FRAP" ?
                                                <>
                                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                        <InputLabel sx={{
                                                            '& .MuiFormLabel-asterisk': {
                                                                color: 'red',
                                                            },
                                                        }} required>M4+ ECO/BL</InputLabel>
                                                        <SearchableAutoComplete
                                                            setInputValue={this.props.setM4EcoBlValue}
                                                            onSelect={this.props.onM4EcoBlSelect}
                                                            options={this.props.approverNames}
                                                            selectedItems={m4EcoBlVal}
                                                            loading={this.props.m4EcoBlLoading}
                                                            placeholder="Search by First/Last Name or Email"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                        <InputLabel sx={{
                                                            '& .MuiFormLabel-asterisk': {
                                                                color: 'red',
                                                            },
                                                        }} required>M4+ Legal Attorney Supporting ECO/BL (for FRAP)</InputLabel>
                                                        <SearchableAutoComplete
                                                            setInputValue={this.props.setM4LegalAttrEcoBlFrapValue}
                                                            onSelect={this.props.onM4LegalAttrEcoBlFrapSelect}
                                                            options={this.props.approverNames}
                                                            selectedItems={m4LegalAttrEcoBlFrapVal}
                                                            loading={this.props.m4LegalAttrFRAPLoading}
                                                            placeholder="Search by First/Last Name or Email"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                        <InputLabel sx={{
                                                            '& .MuiFormLabel-asterisk': {
                                                                color: 'red',
                                                            },
                                                        }} required>GM or Functional VP at M5/R8 Level</InputLabel>
                                                        <SearchableAutoComplete
                                                            setInputValue={this.props.setGmFunctionVpr8Value}
                                                            onSelect={this.props.onGmFunctionVpr8Select}
                                                            options={this.props.approverNames}
                                                            selectedItems={gmFunctionVpr8Val}
                                                            loading={this.props.gmFuncVPLoading}
                                                            placeholder="Search by First/Last Name or Email"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                        <InputLabel sx={{
                                                            '& .MuiFormLabel-asterisk': {
                                                                color: 'red',
                                                            },
                                                        }} required>Chief Accounting Officer </InputLabel>
                                                        <SearchableAutoComplete
                                                            setInputValue={this.props.setChiefAccOfficerValue}
                                                            onSelect={this.props.onChiefAccOfficerSelect}
                                                            options={this.props.approverNames}
                                                            selectedItems={chiefAccOfficerVal}
                                                            loading={this.props.chiefAccOffLoading}
                                                            placeholder="Search by First/Last Name or Email"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                        <InputLabel sx={{
                                                            '& .MuiFormLabel-asterisk': {
                                                                color: 'red',
                                                            },
                                                        }} required>Chief Procurement Officer</InputLabel>
                                                        <SearchableAutoComplete
                                                            setInputValue={this.props.setChiefProcOfficerValue}
                                                            onSelect={this.props.onChiefProcOfficerSelect}
                                                            options={this.props.approverNames}
                                                            selectedItems={chiefProcOfficerVal}
                                                            loading={this.props.chiefProcOfcLoading}
                                                            placeholder="Search by First/Last Name or Email"
                                                        />
                                                    </Grid>
                                                </>
                                                : exceptionForm.exceptionTypeForApproval === "Global Travel and Expense Procedure" ?
                                                    <>
                                                        <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                            <InputLabel sx={{
                                                                '& .MuiFormLabel-asterisk': {
                                                                    color: 'red',
                                                                },
                                                            }} required>Local Business Owner</InputLabel>
                                                            <SearchableAutoComplete
                                                                setInputValue={this.props.setLocalBusinessOwnerValue}
                                                                onSelect={this.props.onLocalBusinessOwnerSelect}
                                                                options={this.props.approverNames}
                                                                selectedItems={localBusinessOwnerVal}
                                                                loading={this.props.localBusOwnerLoading}
                                                                placeholder="Search by First/Last Name or Email"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                            <InputLabel sx={{
                                                                '& .MuiFormLabel-asterisk': {
                                                                    color: 'red',
                                                                },
                                                            }} required>Local Chief Financial Officer (CFO)</InputLabel>
                                                            <SearchableAutoComplete
                                                                setInputValue={this.props.setLocalChiefFinanOwnerValue}
                                                                onSelect={this.props.onLocalChiefFinanOwnerSelect}
                                                                options={this.props.approverNames}
                                                                selectedItems={localChiefFinanOwnerVal}
                                                                loading={this.props.localCFOLoading}
                                                                placeholder="Search by First/Last Name or Email"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                            <InputLabel sx={{
                                                                '& .MuiFormLabel-asterisk': {
                                                                    color: 'red',
                                                                },
                                                            }} required>Senior Director, Enterprise Travel and Expense</InputLabel>
                                                            <SearchableAutoComplete
                                                                setInputValue={this.props.setSeniorDirEnterTravExpenValue}
                                                                onSelect={this.props.onSeniorDirEnterTravExpenSelect}
                                                                options={this.props.approverNames}
                                                                selectedItems={seniorDirEnterTravExpenVal}
                                                                loading={this.props.SenrEntrLoading}
                                                                placeholder="Search by First/Last Name or Email"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                            <InputLabel sx={{
                                                                '& .MuiFormLabel-asterisk': {
                                                                    color: 'red',
                                                                },
                                                            }} required>Senior Vice President and Chief Financial Officer</InputLabel>
                                                            <SearchableAutoComplete
                                                                setInputValue={this.props.setSeniorVpChiefFinanOffcValue}
                                                                onSelect={this.props.onSeniorVpChiefFinanOffcSelect}
                                                                options={this.props.approverNames}
                                                                selectedItems={seniorVpChiefFinanOffcVal}
                                                                loading={this.props.SeniorVPCFOLoading}
                                                                placeholder="Search by First/Last Name or Email"
                                                            />
                                                        </Grid>

                                                    </> :
                                                    exceptionForm.exceptionTypeForApproval === "Recognition Expense" ?
                                                        <>
                                                            <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                                <InputLabel sx={{
                                                                    '& .MuiFormLabel-asterisk': {
                                                                        color: 'red',
                                                                    },
                                                                }} required>Local CFO</InputLabel>
                                                                <SearchableAutoComplete
                                                                    setInputValue={this.props.setLocalCfoValue}
                                                                    onSelect={this.props.onLocalCfoSelect}
                                                                    options={this.props.approverNames}
                                                                    selectedItems={localCfoVal}
                                                                    loading={this.props.localCFOLoading}
                                                                    placeholder="Search by First/Last Name or Email"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={8} sm={4} md={showPreviousVersion ? 4 : 2}>
                                                                <InputLabel sx={{
                                                                    '& .MuiFormLabel-asterisk': {
                                                                        color: 'red',
                                                                    },
                                                                }} required>M5/R12 Business Leader</InputLabel>
                                                                <SearchableAutoComplete
                                                                    setInputValue={this.props.setM5F12BusinessLeaderValue}
                                                                    onSelect={this.props.onM5F12BusinessLeaderSelect}
                                                                    options={this.props.approverNames}
                                                                    selectedItems={m5F12BusinessLeaderVal}
                                                                    loading={this.props.m5F12BLLoading}
                                                                    placeholder="Search by First/Last Name or Email"
                                                                />
                                                            </Grid>


                                                        </> : <></>
                                    }
                                </Grid>
                            </Box>
                        </CollapsibleCard>
                    </Grid>

                     {/* {this.props.load && !showPreviousVersion ? <></> :
                        <Grid item xs={6}>
                            <DocumentPreview height="700px" documentUrl='' />
                        </Grid>}  */}
                </Grid>
            </Box>
        )
    }
}
export default withRouter(ExceptionFormComponent)