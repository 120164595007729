import { Box, Button, Chip, CircularProgress, Divider, FormLabel, Grid, Typography } from '@mui/material'
import { Component, Context } from 'react'
import CollapsibleCard from '../../Components/Card/CollapsibleCard'
import BackLinkButton from '../../Components/Button/BackLinkButton'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import PageHeader from '../../Components/Text/PageHeader'
import WhiteButton from '../../Components/Button/WhiteButton'
import RedButton from '../../Components/Button/RedButton'
import moment from 'moment';
import SelectModel from '../../Components/Select/SelectModel'
import { AuthorizationService, ProcedureFileService } from '../../Services'
import lptService from '../../Services/LPTService'
import { ExceptionForm } from '../../Models/ExceptionForm'
import { FileType } from '../../Common/Enums'
// import ExceptionFormComponent from './ExceptionFormComponent'
import { omit } from 'lodash'
import UnauthorizedRoute from '../../Components/Route/UnauthorizedRoute'
import { RoleContext } from '../../Contexts'
import gptService from '../../Services/GPTService'
import StepperComponent from '../StepperComponent'
import exceptionRenewService from '../../Services/ExceptionRenewal'
import IconButton from "../../Components/Button/IconButton";
import MyCustomSnackbar from "../../Components/SnackBar/MyCustomSnackbar";

type ScreenMode = "view" | "edit";

interface Props extends RouteComponentProps<any, any, any> {
  gperId:number

}

interface State {
  loading:boolean;
  exceptionFormData: ExceptionForm;
  mode: ScreenMode;
  showPreviousVersion: boolean;
  exceptionType: string;
  page: number;
  order: string;
  orderBy: string;
  rows: number;
  limit: number;
  searchDebounce: string;
  procedureNames: any[];
  geographyScope: any[];
  businessScope: any[];
  search: string;
  errMsg: string;
  throwErr: boolean;
  successMsg: string;
  throwSuccess: boolean;
  RenewalthrowSuccess: boolean;
  documentUrl: string;
  load: boolean;
  gperId: number;
  exceptiongeography: any[];
  exceptionBusinessArea: any[];
  proceduresData: any[];
  approverNames: any[];
  m4LegalAttrLoading: boolean;
  gmRegionalLoading: boolean;
  ibuHubGmLoading: boolean;
  assocVpECOLoading: boolean;
  m4EcoBlLoading: boolean;
  m4LegalAttrFRAPLoading: boolean;
  gmFuncVPLoading: boolean;
  assocVpIBULoading: boolean;
  chiefAccOffLoading: boolean;
  chiefProcOfcLoading: boolean;
  localBusOwnerLoading: boolean;
  localChiefFinOfcLoading: boolean;
  SenrEntrLoading: boolean;
  SeniorVPCFOLoading: boolean;
  localCFOLoading: boolean;
  m5F12BLLoading: boolean;
  m4LegalAttorneySpEcoBlVal: any;
  gmRegionalAffiliateVpVal: any;
  ibuHubGmVal: any;
  associateVpEcoIbuVal: any;
  associateVpLegalIbuVal: any;
  m4EcoBlVal: any;
  m4LegalAttrEcoBlFrapVal: any;
  gmFunctionVpr8Val: any;
  chiefAccOfficerVal: any;
  chiefProcOfficerVal: any;
  localBusinessOwnerVal: any;
  localChiefFinanOwnerVal: any;
  seniorDirEnterTravExpenVal: any;
  seniorVpChiefFinanOffcVal: any;
  localCfoVal: any;
  m5F12BusinessLeaderVal: any;
}


class ActiveExceptionViewPage extends Component<Props, State> {
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading:false,
      mode: "view",
      showPreviousVersion: false,
      exceptionType: '',
      searchDebounce: "",
      page: 1,
      rows: 5,
      limit: 5,
      search: "",
      order: "",
      orderBy: "",
      gperId: 0,
      procedureNames: [],
      businessScope: [],
      geographyScope: [],
      exceptionFormData: {
        title: "",
        businessAreaScope: "",
        ecGeographicm4: "",
        exceptionClassification: "",
        exceptionCompletedBy: "",
        exceptionDescription: "",
        exceptionExpirationDate: null,
        exceptionImpactAfterGrant: "",
        exceptionRationale: "",
        exceptionRequestBy: "",
        exceptionTypeForApproval: "",
        geographyScopeException: "",
        procedure: "",
        requestedTextFromProcedure: "",
        ecAssociateVpOpConsultDate: null,
        ecoBlConsultDate: null,
        ecGeographicm4ConsultDate: null,
        exceptionEffectiveDate: null,
        legalConsultDate: null,
        procedureOwnerConsultDate: null,
      },
      errMsg: "",
      throwErr: false,
      documentUrl: "",
      load: true,
      successMsg: "",
      throwSuccess: false,
      RenewalthrowSuccess: false,
      exceptionBusinessArea: [],
      exceptiongeography: [],
      proceduresData: [],
      approverNames: [],
      m4LegalAttrLoading: false,
      assocVpECOLoading: false,
      assocVpIBULoading: false,
      chiefAccOffLoading: false,
      chiefProcOfcLoading: false,
      gmFuncVPLoading: false,
      gmRegionalLoading: false,
      ibuHubGmLoading: false,
      localBusOwnerLoading: false,
      localCFOLoading: false,
      localChiefFinOfcLoading: false,
      m4EcoBlLoading: false,
      m4LegalAttrFRAPLoading: false,
      m5F12BLLoading: false,
      SeniorVPCFOLoading: false,
      SenrEntrLoading: false,
      associateVpEcoIbuVal: {},
      associateVpLegalIbuVal: {},
      chiefAccOfficerVal: {},
      chiefProcOfficerVal: {},
      gmFunctionVpr8Val: {},
      gmRegionalAffiliateVpVal: {},
      ibuHubGmVal: {},
      localBusinessOwnerVal: {},
      localCfoVal: {},
      localChiefFinanOwnerVal: {},
      m4EcoBlVal: {},
      m4LegalAttorneySpEcoBlVal: {},
      m4LegalAttrEcoBlFrapVal: {},
      m5F12BusinessLeaderVal: {},
      seniorDirEnterTravExpenVal: {},
      seniorVpChiefFinanOffcVal: {}
    }
  }
  exceptionTypeChange = (selected: SelectModel) => {
    this.setState({ exceptionType: selected.value });
  };

  onDropDownChange = (selected: SelectModel) => {
    const { exceptionFormData } = this.state;
    const value = selected.value;
    this.setState({
      exceptionFormData: { ...exceptionFormData, exceptionTypeForApproval: value },
    });
  };

  handleEcAssociateVPDateChange = (value: Date | null) => {
    this.setState({
      exceptionFormData: { ...this.state.exceptionFormData, ecAssociateVpOpConsultDate: value! },
    });
  };
  handleEcGeographicDateChange = (value: Date | null) => {
    this.setState({
      exceptionFormData: { ...this.state.exceptionFormData, ecGeographicm4ConsultDate: value! },
    });
  };
  handleEcBlConsultDateChange = (value: Date | null) => {
    this.setState({
      exceptionFormData: { ...this.state.exceptionFormData, ecoBlConsultDate: value! },
    });
  };
  handleExceptionEffDateChange = (value: Date | null) => {
    this.setState({
      exceptionFormData: { ...this.state.exceptionFormData, exceptionEffectiveDate: value! },
    });
  };
  handleExceptionExpiryDateChange = (value: Date | null) => {
    this.setState({
      exceptionFormData: { ...this.state.exceptionFormData, exceptionExpirationDate: value },
    });
  };
  handleLegalConsultDateChange = (value: Date | null) => {
    this.setState({
      exceptionFormData: { ...this.state.exceptionFormData, legalConsultDate: value! },
    });
  };
  handleProcedureOwnerDateChange = (value: Date | null) => {
    this.setState({
      exceptionFormData: { ...this.state.exceptionFormData, procedureOwnerConsultDate: value! },
    });
  };



  getExceptionGeographyData = async () => {
    try {
      const data = await lptService.getAllExceptionGeography();
      // const sortedData = [...data].sort((a: any, b: any) => {return a - b})
      const sortedData = [...data].sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropdowndata = sortedData.map((el) => {
        return {
          id: el.id,
          text: el.text,
          value: el.value
        }
      })
      console.log("data of exceptiongeography", dropdowndata);
      this.setState({ exceptiongeography: [...dropdowndata] })
    } catch (error) {

    }
  }
  getExceptionBusinessAreaData = async () => {
    try {
      const data = await lptService.getAllExceptionBusinessArea();
      const sortedData = [...data].sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropdowndata = sortedData.map((el) => {
        return {
          id: el.id,
          text: el.text,
          value: el.value
        }
      })
      console.log("data of exceptionBusinessArea", dropdowndata);
      this.setState({ exceptionBusinessArea: [...dropdowndata] })
    } catch (error) {

    }
  };

  async componentDidMount(): Promise<void> {
    // const searchParams = new URLSearchParams(this.props.location.search);
    const mode = "view";
    let loadData = true;
    const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

    // if (isReadonlyUser && mode === 'edit') {
    //   loadData = false;
    // }
    await this.getExceptionDataById();

    this.setState({
      gperId: Number(this.props.gperId),
      mode,
      
    });

    // const querySearchGlobal = {
    //   page: this.state.page,
    //   limit: 2000,
    //   searchtext: "",
    //   order: "",
    //   orderBy: "",
    //   status: "Submitted",
    // }
    // const querySearchLocal = {
    //   page: this.state.page,
    //   limit: 2000,
    //   searchtext: "",
    //   order: "",
    //   orderBy: "",
    //   status: "Batch Generated",
    // }
    // if (loadData) {
    //   if (this.state.exceptionFormData.formType === "GlobalForm") {
    //     await this.getProcedureNamesGlobal(querySearchGlobal);
    //   } else {
    //     await this.getProcedureNamesLocal(querySearchLocal);
    //   }
    //   await this.getExceptionBusinessAreaData();
    //   await this.getExceptionGeographyData();
    // }
  }

  async componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    _snapshot?: any
  ): Promise<void> {
    if (_prevProps.gperId !== this.props.gperId) {
      let loadData = true;
      const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

      if (isReadonlyUser && this.state.mode === 'edit') {
        loadData = false;
      }

      // if (loadData) {
        await this.getExceptionDataById();
      // }
    }
  }

  // gettingDocUrl = async () => {
  //   try {
  //     const data = await ProcedureFileService.docx2pdf(this.props.location.state?.globalTemplateData?.gpTemplateFile!, FileType.DOCX);
  //     console.log("url", data);
  //     this.setState({ documentUrl: data! });
  //   } catch (error) {
  //     this.setState({ load: false });
  //     console.error("error getting DocURL", error);
  //   }
  // };

  // handleSubmit = async () => {
  //   const { exceptionFormData, procedureNames, geographyScope, businessScope } = this.state;
  //   let procedure = (procedureNames.map((item: any) => item.text)).join(',');
  //   let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
  //   let businessSp = (businessScope.map((item: any) => item.text)).join(',');
  //   exceptionFormData.procedure = procedure;
  //   exceptionFormData.geographyScopeException = geographySp;
  //   exceptionFormData.businessAreaScope = businessSp;
  //   if (this.validationChecker()) {
  //     await lptService.updateException(exceptionFormData).then((res) => {
  //       if (res.isSuccess) {
  //         this.setState({ throwSuccess: true, successMsg: 'The exception form has been submitted for approval successfully.' });
  //         setTimeout(() => {
  //           this.props.history.push('/procedure_exception');
  //         }, 1500)
  //       } else {
  //         this.setState({
  //           throwErr: true,
  //           errMsg: 'Something went wrong.'
  //         });
  //       }
  //     })
  //   }
  // }

  handleRenewalNotification = async () => {
    try {
      const { exceptionFormData } = this.state;
      console.log("exceptionFormData=", exceptionFormData);
      const data = await exceptionRenewService.setRenewalNotificationOff(exceptionFormData.gperId);
      if (data.success) {
        this.setState({ RenewalthrowSuccess: true, successMsg: "Renewal notification set to off." });
      }
    } catch (error) {
      console.log("error==", error);
    }
  }

  handleSaveAsDraft = async () => {
    const { exceptionFormData, procedureNames, geographyScope, businessScope } = this.state;

    let procedure = (procedureNames.map((item: any) => item.text)).join(',');
    let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
    let businessSp = (businessScope.map((item: any) => item.text)).join(',');

    exceptionFormData.procedure = procedure;
    exceptionFormData.geographyScopeException = geographySp;
    exceptionFormData.businessAreaScope = businessSp;
    const omitId = omit(exceptionFormData, ["gperId"])
    await lptService.saveAsDraftException(omitId).then((res) => {
      if (res.isSuccess) {
        this.setState({ mode: "view", throwSuccess: true, successMsg: 'The exception form has been drafted successfully.' });
        this.props.history.push(`/raised_exception/${res.data.gperId}?mode=view`);

      } else {
        this.setState({ errMsg: 'Something went Wrong.', throwErr: true })
      }
    })
  };

  validationChecker = (): boolean => {
    const { exceptionFormData } = this.state;
    let final: boolean = true;
    if (exceptionFormData.title === "") {
      final = false;
      this.setState({ errMsg: "Please fill Title field." });
    }else if (exceptionFormData.exceptionExpirationDate === null || undefined) {
      final = false;
      this.setState({ errMsg: "Please provide expiration date for exception" });
    }
    else if (exceptionFormData.exceptionClassification === "") {
      final = false;
      this.setState({ errMsg: "Please select one option from Exception Classification field." });
    } else if (exceptionFormData.exceptionRequestBy === "") {
      final = false;
      this.setState({ errMsg: "Please fill Exception Requested By field." });
    }
    else if (exceptionFormData.exceptionCompletedBy === "") {
      final = false;
      this.setState({ errMsg: "Please fill exception completed by field" });
    }
    else if (exceptionFormData.procedure === "") {
      final = false;
      this.setState({ errMsg: "Please select options from Procedure(s) field." });
    } else if (exceptionFormData.businessAreaScope === "") {
      final = false;
      this.setState({ errMsg: "Please fill business area scope for exception field" });
    }  else if (exceptionFormData.geographyScopeException === "") {
      final = false;
      this.setState({ errMsg: "Please select options from Geography scope for the exception field." });
    } else if (exceptionFormData.exceptionDescription === "") {
      final = false;
      this.setState({ errMsg: "Please fill Exception Description field." });
    } else if (exceptionFormData.exceptionImpactAfterGrant === "") {
      final = false;
      this.setState({ errMsg: "Please fill impact of exception if granted field." });
    } else if (exceptionFormData.exceptionRationale === "") {
      final = false;
      this.setState({ errMsg: "Please fill Exception Rationale field." });
   
    } else if (exceptionFormData.exceptionTypeForApproval === "") {
      final = false;
      this.setState({ errMsg: "Please fill Exception Type for Approval field." });
     } else if (exceptionFormData.requestedTextFromProcedure === "") {
      final = false;
      this.setState({ errMsg: "Please fill text for which you are requesting exception from procedure field." });
    } else {
      // Check fields based on exceptionTypeForApproval
      switch (exceptionFormData.exceptionTypeForApproval) {
        case "Global Ethics & Compliance Procedure":
          if (this.areObjectValuesEmpty(this.state.gmRegionalAffiliateVpVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Regional or Affiliate GM or Business VP at M5/R8+ Level field." });
          } else if (this.areObjectValuesEmpty(this.state.m4LegalAttorneySpEcoBlVal)) {
            final = false;
            this.setState({ errMsg: "Please fill M4+ Legal Attorney Supporting ECO/BL field." });
          }
          break;
        case "Lilly International Affiliate/Hub Local Procedure Requirements.":
          if (this.areObjectValuesEmpty(this.state.ibuHubGmVal)) {
            final = false;
            this.setState({ errMsg: "Please fill IBU Hub GM field." });
          } else if (this.areObjectValuesEmpty(this.state.associateVpEcoIbuVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Associate VP or Above, Ethics and Compliance, IBU field." });
          } else if (this.areObjectValuesEmpty(this.state.associateVpLegalIbuVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Associate VP or Above, Legal, IBU field." });
          }
          break;
        case "Global Procedure on Following FRAP":
          if (this.areObjectValuesEmpty(this.state.m4EcoBlVal)) {
            final = false;
            this.setState({ errMsg: "Please fill M4+ ECO/BL field." });
          } else if (this.areObjectValuesEmpty(this.state.m4LegalAttrEcoBlFrapVal)) {
            final = false;
            this.setState({ errMsg: "Please fill M4+ Legal Attorney Supporting ECO/BL (for FRAP) field." });
          } else if (this.areObjectValuesEmpty(this.state.gmFunctionVpr8Val)) {
            final = false;
            this.setState({ errMsg: "Please fill GM or Functional VP at M5/R8 Level field." });
          } else if (this.areObjectValuesEmpty(this.state.chiefAccOfficerVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Chief Accounting Officer field." });
          } else if (this.areObjectValuesEmpty(this.state.chiefProcOfficerVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Chief Procurement Officer field." });
          }
          break;
        case "Global Travel and Expense Procedure":
          if (this.areObjectValuesEmpty(this.state.localBusinessOwnerVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Local Business Owner field." });
          } else if (this.areObjectValuesEmpty(this.state.localChiefFinanOwnerVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Local Chief Financial Officer (CFO) field." });
          } else if (this.areObjectValuesEmpty(this.state.seniorDirEnterTravExpenVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Senior Director, Enterprise Travel and Expense field." });
          } else if (this.areObjectValuesEmpty(this.state.seniorVpChiefFinanOffcVal)) {
            final = false;
            this.setState({ errMsg: "Please fill Senior Vice President and Chief Financial Officer field." });
          }
          break;
        case "Recognition Expense":
          console.log("switch case")
          if (this.areObjectValuesEmpty(this.state.localCfoVal)) {
            console.log("approver not added")
            final = false;
            this.setState({ errMsg: "Please fill Local CFO field." });
          } else if (this.areObjectValuesEmpty(this.state.m5F12BusinessLeaderVal)) {
            final = false;
            this.setState({ errMsg: "Please fill M5/R12 Business Leader field." });
          }
          break;
        default:
          // Handle other exceptionTypeForApproval values if needed
          break;
      }
    }

    console.log("final", final);
    this.setState({ throwErr: !final });

    return final;
  }

  areObjectValuesEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)) {
          return true; // Found an empty value
        }
      }
    }
    return false; // No empty values found
  };


  handleTextInputChange = (event: any) => {
    console.log("ecent", [event.target.name], event.target.value)
    this.setState({
      exceptionFormData: {
        ...this.state.exceptionFormData, [event.target.name]: event.target.value
      }
    })
  };

  handleexceptionClassificationRadioButtons = (event: any) => {
    this.setState({
      exceptionFormData: {
        ...this.state.exceptionFormData, exceptionClassification: event.target.value
      }
    });
  }
  handleEcAssociateVpOpRadioButtons = (event: any) => {
    this.setState({
      exceptionFormData: {
        ...this.state.exceptionFormData, ecAssociateVpOp: event.target.value
      }
    });
  }
  getActiveStep = () => {
    const { exceptionFormData,procedureNames,geographyScope,businessScope } = this.state;
    let procedure = (procedureNames.map((item: any) => item.text)).join(',');
    let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
    let businessSp = (businessScope.map((item: any) => item.text)).join(',');
    let activeStep = -1;

    if (exceptionFormData.title && exceptionFormData.exceptionRequestBy && exceptionFormData.exceptionCompletedBy && exceptionFormData.exceptionExpirationDate && exceptionFormData.exceptionClassification && procedure && geographySp && businessSp && exceptionFormData.exceptionDescription && exceptionFormData.exceptionImpactAfterGrant && exceptionFormData.exceptionRationale && exceptionFormData.requestedTextFromProcedure) { // add all mandatory fields from Procedure Details section
      activeStep = 0;

      if (exceptionFormData.ecoBl || exceptionFormData.ecoBlConsultDate || exceptionFormData.ecoBlAssociationCodeSubjectDescription || exceptionFormData.ecGeographicm4 || exceptionFormData.ecGeographicm4ConsultDate || exceptionFormData.ecGeographicm4Comments || exceptionFormData.ecAssociateVpOp || exceptionFormData.ecAssociateVpOpConsultDate || exceptionFormData.ecAssociateVpOpComments) { // check all fields in OR condition from Consultation: Ethics and Compliance section
        activeStep = 1;

        if (exceptionFormData.assignedLegalCounsel || exceptionFormData.legalConsultDate || exceptionFormData.legalRiskWithException || exceptionFormData.exceptionCompliantWithLaws) { // check all fields in OR condition from Consultation: Legal section
          activeStep = 2;

          if (exceptionFormData.procedureOwner || exceptionFormData.procedureOwnerConsultDate || exceptionFormData.procedureOwnerComments) { // check all fields in OR condition from Consultation: Procedure Owner section
            activeStep = 3;

            if (exceptionFormData.exceptionTypeForApproval) {  // add all mandatory fields from Approval: Global Ethics and Compliance Procedure Section (based on exceptionTypeForApproval)
              activeStep = 4;
            }
          }
        }
      }
    }
    if(exceptionFormData.title && exceptionFormData.exceptionRequestBy && exceptionFormData.exceptionCompletedBy && exceptionFormData.exceptionExpirationDate && exceptionFormData.exceptionClassification && procedure && geographySp && businessSp && exceptionFormData.exceptionDescription && exceptionFormData.exceptionImpactAfterGrant && exceptionFormData.exceptionRationale && exceptionFormData.requestedTextFromProcedure && exceptionFormData.exceptionTypeForApproval) {
      activeStep = 4;
    }
    
    return activeStep;
  }
  onSelectProcedure = (selected: any) => {
    this.setState({ procedureNames: [...selected] })
  }
  onSelectBusinessScope = (selected: any) => {
    this.setState({ businessScope: [...selected] })
  }
  onSelectGeographyScope = (selected: any) => {
    this.setState({ geographyScope: [...selected] })
  }

  onShowPreviousVersion = async () => {
    this.setState({ showPreviousVersion: !this.state.showPreviousVersion });
    // await this.gettingDocUrl();
  }

  actionsuccess = (
    <>
      <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        Ok
      </Button>
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ RenewalthrowSuccess: false });
        }}
      />
    </>
  );
  render() {
    const { exceptionFormData, mode, showPreviousVersion, errMsg, throwErr, throwSuccess, successMsg } = this.state;

    const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);

    if (isReadonlyUser) {
      if (!(mode && mode.toLocaleLowerCase() === 'view')) {
        return <UnauthorizedRoute />;
      }
    } 
    if(this.state.loading){
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
const stepsCompleted = this.getActiveStep()
    return (
      <Box>
        {mode === "view" && !this.state.loading?
          <Grid
            container
            direction={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "center" }}
              >
               
                <Grid
                  item
                  sx={{
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    maxWidth: "28em",
                  }}
                >
                 
                      <PageHeader label={`${exceptionFormData.title}`} />
                  
                </Grid>
                <Grid item>
                  <Chip color='warning' label={`${exceptionFormData.status}`} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                {/* <Grid item>
                  <WhiteButton
                    label="Cancel"
                    onClick={() => {
                      // const { state } = this.props.location;
                      // if (state && state.from) {
                      //   this.props.history.push(state.from);
                      // } else {
                      //   this.props.history.push("/procedure_exception");
                      // }
                      // this.props.history.go(0);
                    }}

                  />
                </Grid> */}

                {/* {exceptionFormData.status !== "Approval Pending" && exceptionFormData.status !== "Drafted" && exceptionFormData.status !== "Rejected" ? <Grid item>
                  <RedButton label="Exception Renewal" onClick={() => {
                    // this.props.history.push({
                    //   pathname: '/exception_renewal', state: {
                    //     renewalForm: this.state.exceptionFormData,
                    //     from: `/raised_exception/${this.state.exceptionFormData.gperId}?mode=view`
                    //   }
                    // });
                  }} />
                </Grid> : <></>}
                {exceptionFormData.status !== "Approved" && exceptionFormData.status !== "Approval Pending" ? <Grid item>
                  <RedButton label="Edit" onClick={() => {
                    this.setState({ mode: "edit" })
                    // this.props.history.push(
                    //   `/raised_exception/${exceptionFormData.gperId}?mode=edit`, { from: `/raised_exception/${exceptionFormData.gperId}?mode=view` }
                    // );
                  }} />
                </Grid> : <></>} */}
              </Grid>
            </Grid>
          </Grid> :
          <Grid
            container
            direction={"row"}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1em",
              justifyContent: "space-between",
            }}
          >
            <MyCustomSnackbar
              message={this.state.successMsg}
              severity="success"
              actions={this.actionsuccess}
              open={this.state.RenewalthrowSuccess}
              onClose={() => {
                this.setState({ RenewalthrowSuccess: false });
              }}
            />
            <Grid item>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item>
                  <BackLinkButton onClick={() => {
                    // this.props.history.push("/procedure_exception")
                    // this.props.history.go(0);
                  }
                  } />
                </Grid>
                <Grid
                  item
                  sx={{
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    maxWidth: "28em",
                  }}
                >
                  
                      <PageHeader label={`${exceptionFormData.title}`} />
                  
                </Grid>
                <Grid item>
                  <Chip color='warning' label={`${exceptionFormData.status}`} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <WhiteButton
                    label="Cancel"
                    onClick={() => {
                      // const { state } = this.props.location;
                      // if (state && state.from) {
                      //   this.props.history.push(state.from);
                      // } else {
                      //   this.props.history.push("/procedure_exception");
                      // }
                      // this.props.history.go(0);
                    }}
                  />
                </Grid>
                <Grid item>
                  <WhiteButton label="Save as Draft" onClick={this.handleSaveAsDraft} />
                </Grid>
                {/* <Grid item>
                  <RedButton label="Submit for Approval" onClick={this.handleSubmit} />
                </Grid> */}
                <Grid item>
                  <RedButton label="Renewal Notification Off" onClick={this.handleRenewalNotification} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid container ml={"3.2em"} spacing={2} mb={"0.8em"}>
          <Grid item>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Created By :</span> {this.state.exceptionFormData.createdBy + " " + moment(this.state.exceptionFormData.createdDate).format("L hh:mm a")}
            </Typography>
          </Grid>
          {
            this.state.exceptionFormData.modifyBy ?
              <Grid item>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Last Modified By :</span> {this.state.exceptionFormData.modifyBy + " " + moment(this.state.exceptionFormData.modifiedDate).format("L hh:mm a")}
                </Typography>
              </Grid> :
              <></>

          }
        </Grid>
        <Divider style={{ marginBottom: '1em' }} />
        {mode === "edit" ? <><Box sx={{ color: "red", display: "flex ", marginBottom: '2rem', marginTop: '1rem', justifyContent: 'flex-end' }}>
          <Grid item>
            <Button disabled color='secondary' variant={showPreviousVersion ? 'text' : 'contained'} style={{ textTransform: 'capitalize' }} onClick={this.onShowPreviousVersion}>{showPreviousVersion ? `Hide Previous Version` : `Show Previous Version`}</Button>
          </Grid>
        </Box><StepperComponent activesteps={stepsCompleted} /></>
          : <></>}
        {this.state.mode === "view" ?
          <>
            <CollapsibleCard
              title='Procedure Details'
              divider
              initiallyOpen
              subTitle="Provide information the approvers needs to evaluate the exception"
              titleStyle={{ fontSize: '26px' }}
              subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
            >
              <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}>Title</Typography>
                  <Typography>{exceptionFormData.title}</Typography>
                </Grid>
                {/* <Grid item xs={2}  >
                  <Typography>Exception Scope</Typography>
                  <Typography>{exceptionFormData.exceptionScope}</Typography>
                </Grid> */}
               
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}> Exception Effective Date </Typography>
                  {
                    exceptionFormData.exceptionEffectiveDate ?
                      <Typography>{moment(exceptionFormData.exceptionEffectiveDate).format("L")}</Typography> :
                      <Typography>__</Typography>

                  }
                </Grid>
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}>Exception Requested By</Typography>
                  <Typography>{exceptionFormData.exceptionRequestBy}</Typography>
                </Grid>
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}>Exception Form Completed By</Typography>
                  <Typography>{exceptionFormData.exceptionCompletedBy}</Typography>
                </Grid>
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}>Exception Classification</Typography>
                  <Typography>{exceptionFormData.exceptionClassification}</Typography>
                </Grid>

                <Grid item xs={2}  >

                  <Typography style={{fontWeight:'bolder'}}>Exception Expiration Date </Typography>
                  {exceptionFormData.exceptionExpirationDate ?

                    <Typography>{moment(exceptionFormData.exceptionExpirationDate).format("L")}</Typography> :
                    <Typography>__</Typography>

                  }

                </Grid>
                <Grid item xs={2}  >

                  <Typography style={{fontWeight:'bolder'}}>Procedure(s)</Typography>
                  <Typography>{exceptionFormData.procedure}</Typography>

                </Grid>
                <Grid item xs={2}  >

                  <Typography style={{fontWeight:'bolder'}}>Geography scope for the exception</Typography>
                  <Typography>{exceptionFormData.geographyScopeException}</Typography>

                </Grid>
                <Grid item xs={2}  >

                  <Typography style={{fontWeight:'bolder'}}>Business area scope for the exception</Typography>

                  <Typography>{exceptionFormData.businessAreaScope}</Typography>

                </Grid>
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}>Describe the exception you are requesting</Typography>
                  <Typography>{exceptionFormData.exceptionDescription}</Typography>
                </Grid>
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}>What is the impact on the business if the <br></br> exception is granted? If not granted?</Typography>
                  <Typography>{exceptionFormData.exceptionImpactAfterGrant}</Typography>
                </Grid>
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}>What is the rationale for this exception?</Typography>
                  <Typography>{exceptionFormData.exceptionRationale}</Typography>
                </Grid>
                <Grid item xs={2}  >
                  <Typography style={{fontWeight:'bolder'}}>Insert text from the procedure for which you <br></br>are requesting an exception</Typography>
                  <Typography>{exceptionFormData.requestedTextFromProcedure}</Typography>
                </Grid>
              </Grid>
            </CollapsibleCard>
            <CollapsibleCard
              title='Consultation: Ethics and Compliance'
              divider

              subTitle="Summarize the advice you received on this potential exception"
              titleStyle={{ fontSize: '26px' }}
              subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
            >
              <Box>
                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>ECO/BL</Typography>
                    <Typography>{exceptionFormData.ecoBl}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>ECO/BL Consult Date</Typography>
                    {exceptionFormData.ecoBlConsultDate ?

                      <Typography>{moment(exceptionFormData.ecoBlConsultDate).format("L")}</Typography>
                      : <Typography>____</Typography>
                    }
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Per ECO/BL: What do applicable international, regional or local industry association <br></br> code(s) say about the subject?</Typography>
                    <Typography>{exceptionFormData.ecoBlAssociationCodeSubjectDescription}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+</Typography>
                    <Typography>{exceptionFormData.ecGeographicm4}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+ Consult Date</Typography>
                    {exceptionFormData.ecGeographicm4ConsultDate ? <Typography>{moment(exceptionFormData.ecGeographicm4ConsultDate).format("L")}</Typography> :
                      <Typography>_____</Typography>}
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+ Comments</Typography>
                    <Typography>{exceptionFormData.ecGeographicm4Comments}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations</Typography>
                    <Typography>{exceptionFormData.ecAssociateVpOp}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations Consult Date</Typography>
                    {exceptionFormData.ecAssociateVpOpConsultDate ? <Typography>{moment(exceptionFormData.ecAssociateVpOpConsultDate).format("L")}</Typography>
                      : <Typography>__</Typography>}
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations Comments</Typography>
                    <Typography>{exceptionFormData.ecAssociateVpOpComments}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </CollapsibleCard>
            <CollapsibleCard title='Consultation: Legal'
              divider
              subTitle="Summarize the advice you received on this potential exception. Legal consult applies to
                        IBU exceptions and is optional for other exceptions."
              titleStyle={{ fontSize: '26px' }}
              subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>

              <Box>
                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Assigned Legal Counsel</Typography>
                    <Typography>{exceptionFormData.assignedLegalCounsel}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Legal Consult Date</Typography>
                    {exceptionFormData.legalConsultDate ?
                      <Typography>{moment(exceptionFormData.legalConsultDate).format('L')}</Typography> :
                      <Typography>__</Typography>

                    }
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Per Legal: What are the risks associated with the exception?</Typography>
                    <Typography>{exceptionFormData.legalRiskWithException}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Per Legal:How is the exception compliant with applicable law(s),local and foreign laws applied <br></br> to the jurisdiction (e.g., U.S, FCPA,trade sanctions,other countries with extraterritorial reach)?</Typography>
                    <Typography>{exceptionFormData.exceptionCompliantWithLaws}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </CollapsibleCard>
            <CollapsibleCard title='Consultation: Procedure Owner'
              divider

              subTitle="Summarize the advice you received on this potential exception"
              titleStyle={{ fontSize: '26px' }}
              subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
              <Box>
                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Procedure Owner</Typography>
                    <Typography>{exceptionFormData.procedureOwner}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Procedure Owner Consult Date</Typography>
                    {
                      exceptionFormData.procedureOwnerConsultDate ?
                        <Typography>{moment(exceptionFormData.procedureOwnerConsultDate).format('L')}</Typography>
                        : <Typography>___</Typography>
                    }
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Procedure Owner Comments</Typography>
                    <Typography>{exceptionFormData.procedureOwnerComments}</Typography>
                  </Grid>

                </Grid>
              </Box>
            </CollapsibleCard>
            <CollapsibleCard title='Approval: Global Ethics and Compliance Procedure'
              divider

              subTitle="Summarize the advice you received on this potential exception"
              titleStyle={{ fontSize: '26px' }}
              subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
              <Box>
                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                  <Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>Exception Type for Approval</Typography>
                    <Typography>{exceptionFormData.exceptionTypeForApproval}</Typography>
                  </Grid>
                  {exceptionFormData.exceptionTypeForApproval === "Global Ethics & Compliance Procedure" ? <><Grid item xs={2}>
                    <Typography style={{fontWeight:'bolder'}}>M4+ Legal Attorney Supporting ECO/BL (for E&C Procedure)</Typography>
                    <Typography>{exceptionFormData.m4LegalAttorneySpEcoBlName}</Typography>
                  </Grid><Grid item xs={2}>
                      <Typography style={{fontWeight:'bolder'}}>Regional or Affiliate GM or Business VP at M5/R8+ Level</Typography>
                      <Typography>{exceptionFormData.gmRegionalAffiliateVpName}</Typography>
                    </Grid></> : exceptionFormData.exceptionTypeForApproval === "Lilly International Affiliate/Hub Local Procedure Requirements" ?
                    <><Grid item xs={2}>
                      <Typography style={{fontWeight:'bolder'}}>IBU Hub GM</Typography>
                      <Typography>{exceptionFormData.ibuHubGmName}</Typography>
                    </Grid><Grid item xs={2}>
                        <Typography>Associate VP or Above, Ethics and Compliance, IBU</Typography>
                        <Typography>{exceptionFormData.associateVpEcoIbuName}</Typography>
                      </Grid><Grid item xs={2}>
                        <Typography style={{fontWeight:'bolder'}}>Associate VP or Above, Legal, IBU</Typography>
                        <Typography>{exceptionFormData.associateVpLegalIbuName}</Typography>
                      </Grid></> : exceptionFormData.exceptionTypeForApproval === "Global Procedure on Following FRAP" ?
                      <><Grid item xs={2}>
                        <Typography style={{fontWeight:'bolder'}}>M4+ ECO/BL</Typography>
                        <Typography>{exceptionFormData.m4EcoBlName}</Typography>
                      </Grid><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>M4+ Legal Attorney Supporting ECO/BL (for FRAP)</Typography>
                          <Typography>{exceptionFormData.m4LegalAttorneySpEcoBlName}</Typography>
                        </Grid><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>GM or Functional VP at M5/R8 Level</Typography>
                          <Typography>{exceptionFormData.gmFunctionVpr8Name}</Typography>
                        </Grid><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>Chief Accounting Officer </Typography>
                          <Typography>{exceptionFormData.chiefAccOfficerName}</Typography>
                        </Grid><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>Chief Procurement Officer </Typography>
                          <Typography>{exceptionFormData.chiefProcOfficerName}</Typography>
                        </Grid></>
                      : exceptionFormData.exceptionTypeForApproval === "Global Travel and Expense Procedure" ?
                        <><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>Local Business Owner</Typography>
                          <Typography>{exceptionFormData.localBusinessOwnerName}</Typography>
                        </Grid><Grid item xs={2}>
                            <Typography style={{fontWeight:'bolder'}}>Local Chief Financial Officer (CFO)</Typography>
                            <Typography>{exceptionFormData.localChiefFinanOwnerName}</Typography>
                          </Grid><Grid item xs={2}>
                            <Typography style={{fontWeight:'bolder'}}>Senior Director, Enterprise Travel and Expenser</Typography>
                            <Typography>{exceptionFormData.seniorDirEnterTravExpenName}</Typography>
                          </Grid><Grid item xs={2}>
                            <Typography style={{fontWeight:'bolder'}}>Senior Vice President and Chief Financial Officer</Typography>
                            <Typography>{exceptionFormData.seniorVpChiefFinanOffcName}</Typography>
                          </Grid></>
                        : <><Grid item xs={2}>
                          <Typography style={{fontWeight:'bolder'}}>Local CFO</Typography>
                          <Typography>{exceptionFormData.localCfoName}</Typography>
                        </Grid><Grid item xs={2}>
                            <Typography style={{fontWeight:'bolder'}}>M5/R12 Business Leader</Typography>
                            <Typography>{exceptionFormData.m5F12BusinessLeaderName}</Typography>
                          </Grid></>
                  }
                </Grid>
              </Box>
            </CollapsibleCard>
          </>
          :
          <></>
          // <ExceptionFormComponent
          //   businessScope={this.state.businessScope}
          //   exceptionForm={this.state.exceptionFormData}
          //   geographyScope={this.state.geographyScope}
          //   handleEcAssociateVPDateChange={this.handleEcAssociateVPDateChange}
          //   handleEcAssociateVpOpRadioButtons={this.handleEcAssociateVpOpRadioButtons}
          //   handleEcBlConsultDateChange={this.handleEcBlConsultDateChange}
          //   handleEcGeographicDateChange={this.handleEcGeographicDateChange}
          //   handleExceptionEffDateChange={this.handleExceptionEffDateChange}
          //   handleExceptionExpiryDateChange={this.handleExceptionExpiryDateChange}
          //   handleLegalConsultDateChange={this.handleLegalConsultDateChange}
          //   handleProcedureOwnerDateChange={this.handleProcedureOwnerDateChange}
          //   handleexceptionClassificationRadioButtons={this.handleexceptionClassificationRadioButtons}
          //   onDropDownChange={this.onDropDownChange}
          //   onSelectBusinessScope={this.onSelectBusinessScope}
          //   onSelectGeography={this.onSelectGeographyScope}
          //   onSelectProcedure={this.onSelectProcedure}
          //   onTextInputChange={this.handleTextInputChange}
          //   procedureNames={this.state.procedureNames}
          //   showPreviousVersion={this.state.showPreviousVersion}
          //   load={this.state.load}
          //   exceptionBusinesData={this.state.exceptionBusinessArea}
          //   geographyScopeData={this.state.exceptiongeography}
          //   procedureNameData={this.state.proceduresData}
          //   ibuHubGmLoading={this.state.ibuHubGmLoading}
          //   localBusOwnerLoading={this.state.localBusOwnerLoading}
          //   localCFOLoading={this.state.localCFOLoading}
          //   localChiefFinOfcLoading={this.state.localChiefFinOfcLoading}
          //   m4EcoBlLoading={this.state.m4EcoBlLoading}
          //   m4LegalAttrFRAPLoading={this.state.m4LegalAttrFRAPLoading}
          //   m4LegalAttrLoading={this.state.m4LegalAttrLoading}
          //   SeniorVPCFOLoading={this.state.SeniorVPCFOLoading}
          //   SenrEntrLoading={this.state.SenrEntrLoading}
          //   assocVpECOLoading={this.state.assocVpECOLoading}
          //   assocVpIBULoading={this.state.assocVpIBULoading}
          //   chiefAccOffLoading={this.state.chiefAccOffLoading}
          //   chiefProcOfcLoading={this.state.chiefProcOfcLoading}
          //   gmRegionalLoading={this.state.gmRegionalLoading}
          //   gmFuncVPLoading={this.state.gmFuncVPLoading}
          //   m5F12BLLoading={this.state.m5F12BLLoading}
          //   associateVpEcoIbuVal={this.state.associateVpEcoIbuVal}
          //   associateVpLegalIbuVal={this.state.associateVpLegalIbuVal}
          //   chiefAccOfficerVal={this.state.chiefAccOfficerVal}
          //   chiefProcOfficerVal={this.state.chiefProcOfficerVal}
          //   gmFunctionVpr8Val={this.state.gmFunctionVpr8Val}
          //   gmRegionalAffiliateVpVal={this.state.gmRegionalAffiliateVpVal}
          //   ibuHubGmVal={this.state.ibuHubGmVal}
          //   approverNames={this.state.approverNames}
          //   localBusinessOwnerVal={this.state.localBusinessOwnerVal}
          //   localCfoVal={this.state.localCfoVal}
          //   localChiefFinanOwnerVal={this.state.localChiefFinanOwnerVal}
          //   m4EcoBlVal={this.state.m4EcoBlVal}
          //   m4LegalAttorneySpEcoBlVal={this.state.m4LegalAttorneySpEcoBlVal}
          //   m4LegalAttrEcoBlFrapVal={this.state.m4LegalAttrEcoBlFrapVal}
          //   m5F12BusinessLeaderVal={this.state.m5F12BusinessLeaderVal}
          //   seniorDirEnterTravExpenVal={this.state.seniorDirEnterTravExpenVal}
          //   seniorVpChiefFinanOffcVal={this.state.seniorVpChiefFinanOffcVal}
          //   setAssociateVpEcoIbuValue={this.setAssociateVpEcoIbuInputValue}
          //   setAssociateVpLegalIbuValue={this.setAssociateVpLegalIbuInputValue}
          //   setChiefAccOfficerValue={this.setChiefAccOfficerInputValue}
          //   setChiefProcOfficerValue={this.setChiefProcOfficerInputValue}
          //   setGmFunctionVpr8Value={this.setGmFunctionVpr8InputValue}
          //   setGmRegionalAffiliateVpValue={this.setGmRegionalAffiliateVpInputValue}
          //   setIbuHubGmValue={this.setIbuHubGmInputValue}
          //   setLocalBusinessOwnerValue={this.setLocalBusinessOwnerValue}
          //   setLocalCfoValue={this.setLocalCfoValue}
          //   setLocalChiefFinanOwnerValue={this.setLocalChiefFinanOwnerValue}
          //   setM4EcoBlValue={this.setM4EcoBlValue}
          //   setSeniorDirEnterTravExpenValue={this.setSeniorDirEnterTravExpenValue}
          //   setM4LegalAttorneySpEcoBlValue={this.setM4LegalAttorneySpEcoBlValue}
          //   setM4LegalAttrEcoBlFrapValue={this.setM4LegalAttrEcoBlFrapValue}
          //   setM5F12BusinessLeaderValue={this.setM5F12BusinessLeaderValue}
          //   setSeniorVpChiefFinanOffcValue={this.setSeniorVpChiefFinanOffcValue}
          //   onAssociateVpEcoIbuSelect={this.onAssociateVpEcoIbuSelect}
          //   onAssociateVpLegalIbuSelect={this.onAssociateVpLegalIbuSelect}
          //   onChiefAccOfficerSelect={this.onChiefAccOfficerSelect}
          //   onChiefProcOfficerSelect={this.onChiefProcOfficerSelect}
          //   onGmFunctionVpr8Select={this.onGmFunctionVpr8Select}
          //   onGmRegionalAffiliateVpSelect={this.onGmRegionalAffiliateVpSelect}
          //   onIbuHubGmSelect={this.onIbuHubGmSelect}
          //   onLocalBusinessOwnerSelect={this.onLocalBusinessOwnerSelect}
          //   onLocalCfoSelect={this.onLocalCfoSelect}
          //   onLocalChiefFinanOwnerSelect={this.onLocalChiefFinanOwnerSelect}
          //   onM4EcoBlSelect={this.onM4EcoBlSelect}
          //   onM4LegalAttorneySpEcoBlSelect={this.onM4LegalAttorneySpEcoBlSelect}
          //   onM4LegalAttrEcoBlFrapSelect={this.onM4LegalAttrEcoBlFrapSelect}
          //   onM5F12BusinessLeaderSelect={this.onM5F12BusinessLeaderSelect}
          //   onSeniorDirEnterTravExpenSelect={this.onSeniorDirEnterTravExpenSelect}
          //   onSeniorVpChiefFinanOffcSelect={this.onSeniorVpChiefFinanOffcSelect}

          // />
          }
      </Box>
    )
  }

  getProcedureNamesLocal = async (querySearch: any) => {
    try {
      const data = await gptService.getLocalProcedure(querySearch);
      const sortedData = data!.localProcedures.sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropDownData = sortedData.map((el) => {
        return {
          id: el.lpId,
          text: `${el.templateName}(v${el.versionNumber})`,
          value: `${el.templateName}(v${el.versionNumber})`
        }
      });
      console.log("Procedure Names dropdowndata", dropDownData);
      this.setState({ proceduresData: dropDownData });
      return dropDownData;
    } catch (error) {
      console.log("error", error)
    }
  }
  getProcedureNamesGlobal = async (querySearch: any) => {
    try {
      const data = await gptService.getAllWithPagination(querySearch);
      const sortedData = data!.globalProcedures.sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropDownData = sortedData.map((el) => {
        return {
          id: el.gpId,
          text: `${el.templateName}(v${el.versionNumber})`,
          value: `${el.templateName}(v${el.versionNumber})`
        }
      });
      console.log("Procedure Names dropdowndata", dropDownData);
      this.setState({ proceduresData: dropDownData });
      return dropDownData;
    } catch (error) {
      console.log("error", error)
    }
  };
  setAssociateVpEcoIbuInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ assocVpECOLoading: true })
    } else {
      this.setState({ assocVpECOLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, assocVpECOLoading: false });
      }
    }
  };
  setAssociateVpLegalIbuInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ assocVpIBULoading: true })
    } else {
      this.setState({ assocVpIBULoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, assocVpIBULoading: false });
      }
    }
  };
  setChiefAccOfficerInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ chiefAccOffLoading: true })
    } else {
      this.setState({ chiefAccOffLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, chiefAccOffLoading: false });
      }
    }
  };
  setChiefProcOfficerInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ chiefProcOfcLoading: true })
    } else {
      this.setState({ chiefProcOfcLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, chiefProcOfcLoading: false });
      }
    }
  };
  setGmFunctionVpr8InputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ gmFuncVPLoading: true })
    } else {
      this.setState({ gmFuncVPLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, gmFuncVPLoading: false });
      }
    }
  };
  setGmRegionalAffiliateVpInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ gmRegionalLoading: true })
    } else {
      this.setState({ gmRegionalLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, gmRegionalLoading: false });
      }
    }
  };
  setIbuHubGmInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ ibuHubGmLoading: true })
    } else {
      this.setState({ ibuHubGmLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, ibuHubGmLoading: false });
      }
    }
  };
  setLocalBusinessOwnerValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localBusOwnerLoading: true })
    } else {
      this.setState({ localBusOwnerLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localBusOwnerLoading: false });
      }
    }
  };
  setLocalCfoValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localCFOLoading: true })
    } else {
      this.setState({ localCFOLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localCFOLoading: false });
      }
    }
  };
  setLocalChiefFinanOwnerValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localChiefFinOfcLoading: true })
    } else {
      this.setState({ localChiefFinOfcLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localChiefFinOfcLoading: false });
      }
    }
  };
  setM4EcoBlValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4EcoBlLoading: true })
    } else {
      this.setState({ m4EcoBlLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4EcoBlLoading: false });
      }
    }
  };
  setM4LegalAttorneySpEcoBlValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4LegalAttrLoading: true })
    } else {
      this.setState({ m4LegalAttrLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4LegalAttrLoading: false });
      }
    }
  };
  setM4LegalAttrEcoBlFrapValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4LegalAttrFRAPLoading: true })
    } else {
      this.setState({ m4LegalAttrFRAPLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4LegalAttrFRAPLoading: false });
      }
    }
  };
  setM5F12BusinessLeaderValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m5F12BLLoading: true })
    } else {
      this.setState({ m5F12BLLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m5F12BLLoading: false });
      }
    }
  };
  setSeniorDirEnterTravExpenValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ SenrEntrLoading: true })
    } else {
      this.setState({ SenrEntrLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, SenrEntrLoading: false });
      }
    }
  };
  setSeniorVpChiefFinanOffcValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ SeniorVPCFOLoading: true })
    } else {
      this.setState({ SeniorVPCFOLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, SeniorVPCFOLoading: false });
      }
    }
  };

  onAssociateVpEcoIbuSelect = (event: any) => {
    this.setState({ assocVpECOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          associateVpEcoIbuName: event.value,
          associateVpEcoIbuEmail: event.email,
        },
        associateVpEcoIbuVal: event,
      });
      this.setState({ associateVpEcoIbuVal: event });
    }
  };
  onAssociateVpLegalIbuSelect = (event: any) => {
    this.setState({ assocVpIBULoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          associateVpLegalIbuName: event.value,
          associateVpLegalIbuEmail: event.email,
        },
        associateVpLegalIbuVal: event,
      });
      this.setState({ associateVpLegalIbuVal: event });
    }
  };
  onChiefAccOfficerSelect = (event: any) => {
    this.setState({ chiefAccOffLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          chiefAccOfficerName: event.value,
          chiefAccOfficerEmail: event.email,
        },
        chiefAccOfficerVal: event,
      });
      this.setState({ chiefAccOfficerVal: event });
    }
  };
  onChiefProcOfficerSelect = (event: any) => {
    this.setState({ chiefProcOfcLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          chiefProcOfficerName: event.value,
          chiefProcOfficerEmail: event.email,
        },
        chiefProcOfficerVal: event,
      });
      this.setState({ chiefProcOfficerVal: event });
    }
  };
  onGmFunctionVpr8Select = (event: any) => {
    this.setState({ gmFuncVPLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          gmFunctionVpr8Name: event.value,
          gmFunctionVpr8Email: event.email,
        },
        gmFunctionVpr8Val: event,
      });
      this.setState({ gmFunctionVpr8Val: event });
    }
  };
  onGmRegionalAffiliateVpSelect = (event: any) => {
    this.setState({ gmRegionalAffiliateVpVal: false, gmRegionalLoading:false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          gmRegionalAffiliateVpName: event.value,
          gmRegionalAffiliateVpEmail: event.email,
        },
        gmRegionalAffiliateVpVal: event,
      });
      this.setState({ gmRegionalAffiliateVpVal: event });
    }
  };
  onIbuHubGmSelect = (event: any) => {
    this.setState({ ibuHubGmLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          ibuHubGmName: event.value,
          ibuHubGmEmail: event.email,
        },
        ibuHubGmVal: event,
      });
      this.setState({ ibuHubGmVal: event });
    }
  };
  onLocalBusinessOwnerSelect = (event: any) => {
    this.setState({ localBusOwnerLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          localBusinessOwnerName: event.value,
          localBusinessOwnerEmail: event.email,
        },
        localBusinessOwnerVal: event,
      });
      this.setState({ localBusinessOwnerVal: event });
    }
  };
  onLocalCfoSelect = (event: any) => {
    this.setState({ localCFOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          localCfoName: event.value,
          localCfoEmail: event.email,
        },
        localCfoVal: event,
      });
      this.setState({ localCfoVal: event });
    }
  };
  onLocalChiefFinanOwnerSelect = (event: any) => {
    this.setState({ localChiefFinOfcLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          localChiefFinanOwnerName: event.value,
          localChiefFinanOwnerEmail: event.email,
        },
        localChiefFinanOwnerVal: event,
      });
      this.setState({ localChiefFinanOwnerVal: event });
    }
  };
  onM4EcoBlSelect = (event: any) => {
    this.setState({ m4EcoBlLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          m4EcoBlName: event.value,
          m4EcoBlEmail: event.email,
        },
        m4EcoBlVal: event,
      });
      this.setState({ m4EcoBlVal: event });
    }
  };
  onM4LegalAttorneySpEcoBlSelect = (event: any) => {
    this.setState({ m4LegalAttrLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          m4LegalAttorneySpEcoBlName: event.value,
          m4LegalAttorneySpEcoBlEmail: event.email,
        },
        m4LegalAttorneySpEcoBlVal: event,
      });
      this.setState({ m4LegalAttorneySpEcoBlVal: event });
    }
  };
  onM4LegalAttrEcoBlFrapSelect = (event: any) => {
    this.setState({ m4LegalAttrFRAPLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          m4LegalAttrEcoBlFrapName: event.value,
          m4LegalAttrEcoBlFrapEmail: event.email,
        },
        m4LegalAttrEcoBlFrapVal: event,
      });
      this.setState({ m4LegalAttrEcoBlFrapVal: event });
    }
  };
  onM5F12BusinessLeaderSelect = (event: any) => {
    this.setState({ m5F12BLLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          m5F12BusinessLeaderName: event.value,
          m5F12BusinessLeaderEmail: event.email,
        },
        m5F12BusinessLeaderVal: event,
      });
      this.setState({ m5F12BusinessLeaderVal: event });
    }
  };
  onSeniorDirEnterTravExpenSelect = (event: any) => {
    this.setState({ SenrEntrLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          seniorDirEnterTravExpenName: event.value,
          seniorDirEnterTravExpenEmail: event.email,
        },
        seniorDirEnterTravExpenVal: event,
      });
      this.setState({ seniorDirEnterTravExpenVal: event });
    }
  };
  onSeniorVpChiefFinanOffcSelect = (event: any) => {
    this.setState({ SeniorVPCFOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionFormData: {
          ...this.state.exceptionFormData,
          seniorVpChiefFinanOffcName: event.value,
          seniorVpChiefFinanOffcEmail: event.email,
        },
        seniorVpChiefFinanOffcVal: event,
      });
      this.setState({ seniorVpChiefFinanOffcVal: event });
    }
  };

  getExceptionDataById = async () => {
    try {
      this.setState({loading:true});
      const data = await lptService.getExceptionDataById(this.props.gperId);
      this.setState({loading:false});
      const procedure = data?.procedure.split(",").map((str) => { return { text: str, value: str } })
      const geography = data?.geographyScopeException.split(",").map((str) => { return { text: str, value: str } })
      const businessScope = data?.businessAreaScope.split(",").map((str) => { return { text: str, value: str } })
      if (data?.associateVpEcoIbuName !== null) {
        this.setState({
          associateVpEcoIbuVal: {
            text: data?.associateVpEcoIbuName,
            value: data?.associateVpEcoIbuName,
            email: data?.associateVpEcoIbuEmail,
          },
        });
      } else {
        this.setState({
          associateVpEcoIbuVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.associateVpLegalIbuName !== null) {
        this.setState({
          associateVpLegalIbuVal: {
            text: data?.associateVpLegalIbuName,
            value: data?.associateVpLegalIbuName,
            email: data?.associateVpLegalIbuEmail,
          },
        });
      } else {
        this.setState({
          associateVpLegalIbuVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.m4LegalAttrEcoBlFrapName !== null) {
        this.setState({
          m4LegalAttrEcoBlFrapVal: {
            text: data?.m4LegalAttrEcoBlFrapName,
            value: data?.m4LegalAttrEcoBlFrapName,
            email: data?.m4LegalAttrEcoBlFrapEmail,
          },
        });
      } else {
        this.setState({
          m4LegalAttrEcoBlFrapVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.chiefAccOfficerName !== null) {
        this.setState({
          chiefAccOfficerVal: {
            text: data?.chiefAccOfficerName,
            value: data?.chiefAccOfficerName,
            email: data?.chiefAccOfficerEmail,
          },
        });
      } else {
        this.setState({
          chiefAccOfficerVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.chiefProcOfficerName !== null) {
        this.setState({
          chiefProcOfficerVal: {
            text: data?.chiefProcOfficerName,
            value: data?.chiefProcOfficerName,
            email: data?.chiefProcOfficerEmail,
          },
        });
      } else {
        this.setState({
          chiefProcOfficerVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.gmFunctionVpr8Name !== null) {
        this.setState({
          gmFunctionVpr8Val: {
            text: data?.gmFunctionVpr8Name,
            value: data?.gmFunctionVpr8Name,
            email: data?.gmFunctionVpr8Email,
          },
        });
      } else {
        this.setState({
          gmFunctionVpr8Val: { text: "", value: "", email: "" },
        });
      }
      if (data?.gmRegionalAffiliateVpName !== null) {
        this.setState({
          gmRegionalAffiliateVpVal: {
            text: data?.gmRegionalAffiliateVpName,
            value: data?.gmRegionalAffiliateVpName,
            email: data?.gmRegionalAffiliateVpEmail,
          },
        });
      } else {
        this.setState({
          gmRegionalAffiliateVpVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.ibuHubGmName !== null) {
        this.setState({
          ibuHubGmVal: {
            text: data?.ibuHubGmName,
            value: data?.ibuHubGmName,
            email: data?.ibuHubGmEmail,
          },
        });
      } else {
        this.setState({
          ibuHubGmVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.localBusinessOwnerName !== null) {
        this.setState({
          localBusinessOwnerVal: {
            text: data?.localBusinessOwnerName,
            value: data?.localBusinessOwnerName,
            email: data?.localBusinessOwnerEmail,
          },
        });
      } else {
        this.setState({
          localBusinessOwnerVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.localCfoName !== null) {
        this.setState({
          localCfoVal: {
            text: data?.localCfoName,
            value: data?.localCfoName,
            email: data?.localCfoEmail,
          },
        });
      } else {
        this.setState({
          localCfoVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.localChiefFinanOwnerName !== null) {
        this.setState({
          localChiefFinanOwnerVal: {
            text: data?.localChiefFinanOwnerName,
            value: data?.localChiefFinanOwnerName,
            email: data?.localChiefFinanOwnerEmail,
          },
        });
      } else {
        this.setState({
          localChiefFinanOwnerVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.m4EcoBlName !== null) {
        this.setState({
          m4EcoBlVal: {
            text: data?.m4EcoBlName,
            value: data?.m4EcoBlName,
            email: data?.m4EcoBlEmail,
          },
        });
      } else {
        this.setState({
          m4EcoBlVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.m4LegalAttorneySpEcoBlName !== null) {
        this.setState({
          m4LegalAttorneySpEcoBlVal: {
            text: data?.m4LegalAttorneySpEcoBlName,
            value: data?.m4LegalAttorneySpEcoBlName,
            email: data?.m4LegalAttorneySpEcoBlEmail,
          },
        });
      } else {
        this.setState({
          m4LegalAttorneySpEcoBlVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.m5F12BusinessLeaderName !== null) {
        this.setState({
          m5F12BusinessLeaderVal: {
            text: data?.m5F12BusinessLeaderName,
            value: data?.m5F12BusinessLeaderName,
            email: data?.m5F12BusinessLeaderEmail,
          },
        });
      } else {
        this.setState({
          m5F12BusinessLeaderVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.seniorDirEnterTravExpenName !== null) {
        this.setState({
          seniorDirEnterTravExpenVal: {
            text: data?.seniorDirEnterTravExpenName,
            value: data?.seniorDirEnterTravExpenName,
            email: data?.seniorDirEnterTravExpenEmail,
          },
        });
      } else {
        this.setState({
          seniorDirEnterTravExpenVal: { text: "", value: "", email: "" },
        });
      }
      if (data?.seniorVpChiefFinanOffcName !== null) {
        this.setState({
          seniorVpChiefFinanOffcVal: {
            text: data?.seniorVpChiefFinanOffcName,
            value: data?.seniorVpChiefFinanOffcName,
            email: data?.seniorVpChiefFinanOffcEmail,
          },
        });
      } else {
        this.setState({
          seniorVpChiefFinanOffcVal: { text: "", value: "", email: "" },
        });
      };
      console.log("procedure", procedure)
      this.setState({ exceptionFormData: data!, procedureNames: procedure!, geographyScope: geography!, businessScope: businessScope! });
    } catch (error) {
      this.setState({loading:false});
      console.error("error while getting by id", error)
    }
  }
}

export default withRouter(ActiveExceptionViewPage);