import React from "react";
import { RouteProps } from "react-router-dom";
import { Typography } from "@mui/material";

interface Props extends RouteProps { }
interface State { }
class UnauthorizedRoute extends React.Component<Props, State> {
    render() {
        return <Typography>User is not authorized to access this page</Typography>;
    }
}

export default UnauthorizedRoute;