import { Component, Context } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Button, CircularProgress, Divider, Grid, InputLabel, Typography } from "@mui/material";
import PageHeader from '../../Components/Text/PageHeader';
import SelectModel from '../../Components/Select/SelectModel';
import BackLinkButton from '../../Components/Button/BackLinkButton';
import WhiteButton from '../../Components/Button/WhiteButton';
import RedButton from '../../Components/Button/RedButton';
import { ExceptionForm } from '../../Models/ExceptionForm';
import lptService from '../../Services/LPTService';
import MyCustomSnackbar from '../../Components/SnackBar/MyCustomSnackbar';
import { DocumentConfigModel, LocalProcedureTemplate } from '../../Models';
import { AuthProviderService, DocxService, ProcedureFileService } from '../../Services';
import StepperComponent from '../StepperComponent';
import IconButton from '../../Components/Button/IconButton';
import FileUploadModelForLP from '../../Models/FileUploadModelForLP';
import Utils from '../../Common/Utils';
import { LocalProcedureTemplateSection } from '../../Models/LocalProcedureTemplateSection';
import BreadCrumbModel from '../../Components/BreadCrumb/BreadCrumbModel';
import gptService from '../../Services/GPTService';
import ExceptionFormComponent from './ExceptionFormComponent';
import SingleSelect from '../../Components/Select/SingleSelect';
import { RoleContext } from '../../Contexts';
import { DocumentType, FileType, UserRole } from '../../Common/Enums';
import Constants from '../../Common/Constants';
import moment from 'moment';

type ScreenMode = "view" | "edit";

const approvalTypes = [
  { text: "Route for Approval", value: "Route for Approval" },
  { text: "No Approval Required", value: "No Approval Required" }
]

interface Props extends RouteComponentProps<any, any, {
  from?: any;
  routeArray?: any,
  editTemplate?: LocalProcedureTemplate,
  sections?: LocalProcedureTemplateSection[],
  exception?: boolean,
  templateData: any,
  previousSections: any,
  createException?: boolean,
}> {
  handleLocalizeSubmit: () => void;
}
interface State {
  tab: string;
  showDeviation: boolean;
  showPreviousVersion: boolean;
  existingException: any;
  exceptionForm: ExceptionForm;
  page: number;
  order: string;
  orderBy: string;
  mode: ScreenMode;
  rows: number;
  limit: number;
  searchDebounce: string;
  breadCrumbs: BreadCrumbModel[];
  procedureNames: any[];
  geographyScope: any[];
  businessScope: any[];
  search: string;
  reportDownload: boolean;
  errMsg: string;
  throwErr: boolean;
  successMsg: string;
  throwSuccess: boolean;
  documentUrl: string;
  load: boolean;
  localizeTemplateData: LocalProcedureTemplate;
  localizeSections: LocalProcedureTemplateSection[];
  exceptiongeography: any[];
  exceptionBusinessArea: any[];
  proceduresData: any[];
  approverNames: any[];
  m4LegalAttrLoading: boolean;
  gmRegionalLoading: boolean;
  ibuHubGmLoading: boolean;
  assocVpECOLoading: boolean;
  m4EcoBlLoading: boolean;
  m4LegalAttrFRAPLoading: boolean;
  gmFuncVPLoading: boolean;
  assocVpIBULoading: boolean;
  chiefAccOffLoading: boolean;
  chiefProcOfcLoading: boolean;
  localBusOwnerLoading: boolean;
  localChiefFinOfcLoading: boolean;
  SenrEntrLoading: boolean;
  SeniorVPCFOLoading: boolean;
  localCFOLoading: boolean;
  m5F12BLLoading: boolean;
  m4LegalAttorneySpEcoBlVal: any;
  gmRegionalAffiliateVpVal: any;
  ibuHubGmVal: any;
  associateVpEcoIbuVal: any;
  associateVpLegalIbuVal: any;
  m4EcoBlVal: any;
  m4LegalAttrEcoBlFrapVal: any;
  gmFunctionVpr8Val: any;
  chiefAccOfficerVal: any;
  chiefProcOfficerVal: any;
  localBusinessOwnerVal: any;
  localChiefFinanOwnerVal: any;
  seniorDirEnterTravExpenVal: any;
  seniorVpChiefFinanOffcVal: any;
  localCfoVal: any;
  m5F12BusinessLeaderVal: any;
  activeStep: number | null;
  approvalType: any;
  stepsCompleted: boolean;
  exceptionApprovers: any;
  disableProcedure: boolean;
  loading: boolean;
  enableSubmit:boolean;
}
class ExceptionPage extends Component<Props, State> {

  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      mode: 'edit',
      activeStep: 0,
      enableSubmit:false,
      tab: '3',
      loading: this.props?.history?.location?.state?.from ? false : true,
      showDeviation: false,
      showPreviousVersion: false,
      reportDownload: false,
      existingException: [],
      searchDebounce: "",
      stepsCompleted: false,
      disableProcedure: this.props?.history?.location?.state?.from ? false : true,
      page: 1,
      rows: 5,
      limit: 5,
      search: "",
      order: "",
      orderBy: "",
      procedureNames: [],
      businessScope: [],
      geographyScope: [],
      exceptionForm: {
        businessAreaScope: "",
        ecGeographicm4: "",
        exceptionClassification: "",
        exceptionCompletedBy: "",
        exceptionDescription: "",
        exceptionImpactAfterGrant: "",
        exceptionRationale: "",
        exceptionRequestBy: "",
        exceptionTypeForApproval: "",
        geographyScopeException: "",
        procedure: "",
        requestedTextFromProcedure: "",
        exceptionExpirationDate: null
      },
      breadCrumbs: [
        {
          label: 'Procedure Exception',
          onClick: () => {
            this.props.history.push('/procedure_exception');
          }
        },
        {
          label: 'Exception Request Form'
        }
      ],
      errMsg: "",
      throwErr: false,
      documentUrl: "",
      load: true,
      successMsg: "",
      throwSuccess: false,
      localizeTemplateData: {
        templateName: "",
        basicPrinciples: "",
        basicPrinciples_blackTextChangeType: "",
        bodyText: "",
        bodyText_blackTextChangeType: "",
        approver: "",
        approverEmail: "",
        approverName: "",
        effectiveDate: null,
        footer: "",
        footerImageFile: "",
        header: "",
        header_blackTextChangeType: "",
        headerImageFile: "",
        owner: "",
        ownerName: "",
        ownerEmail: "",
        procedureType: "",
        versionNumber: "",
        ipFilePath: "",
        _basicPrinciples: Utils.getEditorState(),
        _bodyText: Utils.getEditorState(),
        _headerImageFile: {} as FileUploadModelForLP,
        _footerImageFile: {} as FileUploadModelForLP,
        _ipFilePath: {} as FileUploadModelForLP,
        _header: Utils.getEditorState(),
        createdBy: ""
      },
      localizeSections: [],
      exceptionBusinessArea: [],
      exceptiongeography: [],
      proceduresData: [],
      approverNames: [],
      m4LegalAttrLoading: false,
      assocVpECOLoading: false,
      assocVpIBULoading: false,
      chiefAccOffLoading: false,
      chiefProcOfcLoading: false,
      gmFuncVPLoading: false,
      gmRegionalLoading: false,
      ibuHubGmLoading: false,
      localBusOwnerLoading: false,
      localCFOLoading: false,
      localChiefFinOfcLoading: false,
      m4EcoBlLoading: false,
      m4LegalAttrFRAPLoading: false,
      m5F12BLLoading: false,
      SeniorVPCFOLoading: false,
      SenrEntrLoading: false,
      associateVpEcoIbuVal: { text: "", value: "", email: "" },
      associateVpLegalIbuVal: { text: "", value: "", email: "" },
      chiefAccOfficerVal: { text: "", value: "", email: "" },
      chiefProcOfficerVal: { text: "", value: "", email: "" },
      gmFunctionVpr8Val: { text: "", value: "", email: "" },
      gmRegionalAffiliateVpVal: { text: "", value: "", email: "" },
      ibuHubGmVal: { text: "", value: "", email: "" },
      localBusinessOwnerVal: { text: "", value: "", email: "" },
      localCfoVal: { text: "", value: "", email: "" },
      localChiefFinanOwnerVal: { text: "", value: "", email: "" },
      m4EcoBlVal: { text: "", value: "", email: "" },
      m4LegalAttorneySpEcoBlVal: { text: "", value: "", email: "" },
      m4LegalAttrEcoBlFrapVal: { text: "", value: "", email: "" },
      m5F12BusinessLeaderVal: { text: "", value: "", email: "" },
      seniorDirEnterTravExpenVal: { text: "", value: "", email: "" },
      seniorVpChiefFinanOffcVal: { text: "", value: "", email: "" },
      approvalType: 'Route for Approval',
      exceptionApprovers: [],
    }
  };


  async componentDidMount(): Promise<void> {
    const { accessToken } = await AuthProviderService.getAccessToken();
    const loggedInUserToken = Utils.decodeJWT<any>(accessToken);

    console.log("loggedInUserToken=", this.context.userRoles);
    console.log("loggedInUserToken accessToken=", accessToken);
    console.log("location stete=",this.props.location.state);
    const querySearchGlobal = {
      page: this.state.page,
      limit: 2000,
      searchtext: "",
      order: "",
      orderBy: "",
      status: "Submitted",
    }
    const querySearchLocal = {
      page: this.state.page,
      limit: 2000,
      searchtext: "",
      order: "",
      orderBy: "",
      status: "Initiation Pending",
    }
    let data: any;
    let state: any = this.props.location.state;
    if (state?.from) {
      this.setState({ exceptionForm: { ...this.state.exceptionForm, formType: 'GlobalForm', exceptionCompletedBy: loggedInUserToken.name ? loggedInUserToken.name : "" } });
      data = await this.getProcedureNamesGlobal(querySearchGlobal);
    } else {
      this.setState({ exceptionForm: { ...this.state.exceptionForm, formType: 'LocalForm' } })
      data = await this.getProcedureNamesLocal(querySearchLocal);
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          exceptionCompletedBy: loggedInUserToken.name ? loggedInUserToken.name : ""
        },
        localizeTemplateData: this.props.location?.state?.editTemplate!,
        localizeSections: this.props.location?.state?.sections!!,
        proceduresData: data!,
        procedureNames: this.props.location?.state?.editTemplate ? [
          { id: this.props.location?.state?.editTemplate?.lpId, text: this.props.location?.state?.editTemplate?.templateName, value: this.props.location?.state?.editTemplate?.templateName }
        ] : [],
        loading: false
      });
    }
    await this.getExceptionBusinessAreaData()
    await this.getExceptionGeographyData()

  }

  getActiveStep = () => {
    const { exceptionForm,procedureNames,geographyScope,businessScope } = this.state;
    let procedure = (procedureNames.map((item: any) => item.text)).join(',');
    let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
    let businessSp = (businessScope.map((item: any) => item.text)).join(',');
    let activeStep = -1;

    if (exceptionForm.title && exceptionForm.exceptionRequestBy && exceptionForm.exceptionCompletedBy && exceptionForm.exceptionExpirationDate && exceptionForm.exceptionClassification && procedure && geographySp && businessSp && exceptionForm.exceptionDescription && exceptionForm.exceptionImpactAfterGrant && exceptionForm.exceptionRationale && exceptionForm.requestedTextFromProcedure) { // add all mandatory fields from Procedure Details section
      activeStep = 0;

      if (exceptionForm.ecoBl || exceptionForm.ecoBlConsultDate || exceptionForm.ecoBlAssociationCodeSubjectDescription || exceptionForm.ecGeographicm4 || exceptionForm.ecGeographicm4ConsultDate || exceptionForm.ecGeographicm4Comments || exceptionForm.ecAssociateVpOp || exceptionForm.ecAssociateVpOpConsultDate || exceptionForm.ecAssociateVpOpComments) { // check all fields in OR condition from Consultation: Ethics and Compliance section
        activeStep = 1;

        if (exceptionForm.assignedLegalCounsel || exceptionForm.legalConsultDate || exceptionForm.legalRiskWithException || exceptionForm.exceptionCompliantWithLaws) { // check all fields in OR condition from Consultation: Legal section
          activeStep = 2;

          if (exceptionForm.procedureOwner || exceptionForm.procedureOwnerConsultDate || exceptionForm.procedureOwnerComments) { // check all fields in OR condition from Consultation: Procedure Owner section
            activeStep = 3;

            if (exceptionForm.exceptionTypeForApproval) {  // add all mandatory fields from Approval: Global Ethics and Compliance Procedure Section (based on exceptionTypeForApproval)
              activeStep = 4;
            }
          }
        }
      }
    }
    if(exceptionForm.title && exceptionForm.exceptionRequestBy && exceptionForm.exceptionCompletedBy && exceptionForm.exceptionExpirationDate && exceptionForm.exceptionClassification && procedure && geographySp && businessSp && exceptionForm.exceptionDescription && exceptionForm.exceptionImpactAfterGrant && exceptionForm.exceptionRationale && exceptionForm.requestedTextFromProcedure && exceptionForm.exceptionTypeForApproval) {
      activeStep = 4;
    }
    
    return activeStep;
  }

  exceptionTypeChange = (selected: SelectModel) => {
    this.setState({ existingException: selected.value });
  };
  approvalTypeChange = (selected: SelectModel) => {
    this.setState({ approvalType: selected.value });
  };

  onDropDownChange = (selected: SelectModel) => {
    const { exceptionForm } = this.state;
    const value = selected.value;
    this.setState({
      exceptionForm: { ...exceptionForm, exceptionTypeForApproval: value },
    });
  };

  handleEcAssociateVPDateChange = (value: Date | null) => {
    this.setState({
      exceptionForm: { ...this.state.exceptionForm, ecAssociateVpOpConsultDate: value },
    });
  };
  handleEcGeographicDateChange = (value: Date | null) => {
    this.setState({
      exceptionForm: { ...this.state.exceptionForm, ecGeographicm4ConsultDate: value! },
    });
  };
  handleEcBlConsultDateChange = (value: Date | null) => {
    this.setState({
      exceptionForm: { ...this.state.exceptionForm, ecoBlConsultDate: value },
    });
  };
  handleExceptionEffDateChange = (value: Date | null) => {
    this.setState({
      exceptionForm: { ...this.state.exceptionForm, exceptionEffectiveDate: value },
    });
  };
  handleExceptionExpiryDateChange = (value: Date | null) => {
    this.setState({
      exceptionForm: { ...this.state.exceptionForm, exceptionExpirationDate: value },
    });
  };
  handleLegalConsultDateChange = (value: Date | null) => {
    this.setState({
      exceptionForm: { ...this.state.exceptionForm, legalConsultDate: value },
    });
  };
  handleProcedureOwnerDateChange = (value: Date | null) => {
    this.setState({
      exceptionForm: { ...this.state.exceptionForm, procedureOwnerConsultDate: value },
    });
  };
  getExceptionGeographyData = async () => {
    try {
      const data = await lptService.getAllExceptionGeography();
      // const sortedData = [...data].sort((a: any, b: any) => {return a - b})
      const sortedData = [...data].sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropdowndata = sortedData.map((el) => {
        return {
          id: el.id,
          text: el.text,
          value: el.value
        }
      })
      console.log("data of exceptiongeography", dropdowndata);
      this.setState({ exceptiongeography: [...dropdowndata], loading: false })
    } catch (error) {

    }
  }
  getExceptionBusinessAreaData = async () => {
    try {
      const data = await lptService.getAllExceptionBusinessArea();
      const sortedData = [...data].sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropdowndata = sortedData.map((el) => {
        return {
          id: el.id,
          text: el.text,
          value: el.value
        }
      })
      console.log("data of exceptionBusinessArea", dropdowndata);
      this.setState({ exceptionBusinessArea: [...dropdowndata], loading: false })
    } catch (error) {

    }
  };

  getProcedureNamesLocal = async (querySearch: any) => {
    try {
      const data = await gptService.getLocalProcedure(querySearch);
      const sortedData = data!.localProcedures.sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropDownData = sortedData.map((el) => {
        return {
          id: el.lpId,
          text: `${el.templateName}(v${el.versionNumber})`,
          value: `${el.templateName}(v${el.versionNumber})`
        }
      });
      console.log("Procedure Names dropdowndata", dropDownData);
      this.setState({ proceduresData: dropDownData });
      return dropDownData;
    } catch (error) {
      console.log("error", error)
    }
  }
  getProcedureNamesGlobal = async (querySearch: any) => {
    try {
      const data = await gptService.getAllWithPagination(querySearch);
      const sortedData = data!.globalProcedures.sort((a: any, b: any) => {
        if (a.value < b.value)
          return -1;
        if (a.value > b.value)
          return 1;
        return 0;
      });
      const dropDownData = sortedData.map((el) => {
        return {
          id: el.gpId,
          text: `${el.templateName}(v${el.versionNumber})`,
          value: `${el.templateName}(v${el.versionNumber})`
        }
      });
      dropDownData.sort((a, b) => a.text.localeCompare(b.text));
      console.log("Procedure Names dropdowndata", dropDownData);
      this.setState({ proceduresData: dropDownData });
      return dropDownData;
    } catch (error) {
      console.log("error", error)
    }
  };

  handleCreateException = async () => {
    const { exceptionForm, procedureNames, geographyScope, businessScope } = this.state;
    let procedure = (procedureNames.map((item: any) => item.text)).join(',');
    let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
    let businessSp = (businessScope.map((item: any) => item.text)).join(',');
    exceptionForm.procedure = procedure;
    exceptionForm.geographyScopeException = geographySp;
    exceptionForm.businessAreaScope = businessSp;

    if (this.validationCheckerNoAppr()) {
      if (this.props.location?.state?.routeArray && this.props.location?.state?.routeArray.length > 0) {

        await lptService.createExceptionNoAppr({ exceptionForm: exceptionForm }).then(async (res) => {
          if (res.isSuccess) {
            const modifiedProcedure = procedureNames.map(el => {
              return {
                lpId: el.id,
                gperId: res.data.gperId
              }
            });
            console.log('modi', modifiedProcedure);
            await lptService.setLptException(modifiedProcedure)
            this.setState({ throwSuccess: true, successMsg: "Your Procedure Exception Request form has been submitted successfully." })
            setTimeout(() => {
              // this.props.history.push(`/raised_exception/${res?.data?.gperId}?mode=view`);
              // this.props.history.go(0);
              // this.props.history.push('/procedure_exception');
              let state: any = this.props.location.state;
              state?.from ?
                this.props.history.push(state?.from) :
                this.props.history.push(`/eco_localization/${this.props.history?.location?.state?.editTemplate?.lpId}/mode=edit`, { ...state, "from": "/exception", "createExceptionBack": "fromCreate" });
            }, 2000);

          } else {
            if (res.statusCode === 400 && res.message !== "") {
              this.setState({
                throwErr: true,
                errMsg: res.message
              })
              return;
            }
            this.setState({
              throwErr: true,
              errMsg: 'Something went wrong.'
            })
          }
        })
      }
      else {
        await lptService.createExceptionNoAppr({ exceptionForm: exceptionForm }).then(async (res) => {
          if (res.isSuccess) {
            const modifiedProcedure = procedureNames.map(el => {
              return {
                gpId: el.id,
                gperId: res.data.gperId
              }
            });
            await gptService.createGlobalExceptionMapping(modifiedProcedure)
            this.setState({ throwSuccess: true, successMsg: "Your Procedure Exception request form has been submitted successfully." })
            setTimeout(() => {
              this.props.history.push('/procedure_exception');
            }, 2000)

            this.setState({
              throwErr: true,
              errMsg: 'Something went wrong while setting procedure(s).'
            })


          } else {
            if (res.statusCode === 400 && res.message !== "") {
              this.setState({
                throwErr: true,
                errMsg: res.message
              })
              return;
            }
            this.setState({
              throwErr: true,
              errMsg: 'Something went wrong.'
            })
          }
        })
      }
    }
  }


  approversException = () => {
    const { exceptionForm } = this.state;
    let approvers = [];
    if (exceptionForm.exceptionTypeForApproval === "Global Ethics & Compliance Procedure") {
      approvers.push(exceptionForm.gmRegionalAffiliateVpEmail);
      approvers.push(exceptionForm.m4LegalAttorneySpEcoBlEmail);
    } else if (exceptionForm.exceptionTypeForApproval === "Lilly International Affiliate/Hub Local Procedure Requirements") {
      approvers.push(exceptionForm.ibuHubGmEmail);
      approvers.push(exceptionForm.associateVpEcoIbuEmail)
      approvers.push(exceptionForm.associateVpLegalIbuEmail)
    } else if (exceptionForm.exceptionTypeForApproval === "Global Procedure on Following FRAP") {
      approvers.push(exceptionForm.m4EcoBlEmail)
      approvers.push(exceptionForm.m4LegalAttrEcoBlFrapEmail)
      approvers.push(exceptionForm.gmFunctionVpr8Email)
      approvers.push(exceptionForm.chiefAccOfficerEmail)
      approvers.push(exceptionForm.chiefProcOfficerEmail)
    } else if (exceptionForm.exceptionTypeForApproval === "Global Travel and Expense Procedure") {
      approvers.push(exceptionForm.localBusinessOwnerEmail)
      approvers.push(exceptionForm.localChiefFinanOwnerEmail)
      approvers.push(exceptionForm.seniorDirEnterTravExpenEmail)
      approvers.push(exceptionForm.seniorVpChiefFinanOffcEmail)
    } else {
      approvers.push(exceptionForm.localCfoEmail)
      approvers.push(exceptionForm.m5F12BusinessLeaderEmail)
    }
    return approvers;
  }


  handleSubmitForApproval = async () => {
    const { exceptionForm, procedureNames, geographyScope, businessScope } = this.state;

    let procedure = (procedureNames.map((item: any) => item.text)).join(',');
    let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
    let businessSp = (businessScope.map((item: any) => item.text)).join(',');
    exceptionForm.procedure = procedure;
    exceptionForm.geographyScopeException = geographySp;
    exceptionForm.businessAreaScope = businessSp;
    exceptionForm.title = exceptionForm.title!.trim();
    if (this.validationChecker()) {
      this.setState({ loading: true, reportDownload: true })
      if (this.props.location?.state?.routeArray && this.props.location?.state?.routeArray.length > 0) {
        // await this.handleLocalizeUpdate()
        await this.handleAdherenceReportGenerate()
        await lptService.createExceptionForm({ exceptionForm: exceptionForm }).then(async (res) => {
          if (res.isSuccess) {
            const modifiedProcedure = procedureNames.map(el => {
              return {
                lpId: el.id,
                gperId: res.data.gperId
              }
            })
            console.log('modi', modifiedProcedure);
            await lptService.setLptException(modifiedProcedure)

            this.setState({ loading: false, throwSuccess: true, successMsg: "Your Procedure Exception request form has been submitted successfully." })
            setTimeout(() => {
              // this.props.history.push(`/raised_exception/${res?.data?.gperId}?mode=view`);
              // this.props.history.go(0);

              // this.props.history.push('/procedure_exception');
              let state: any = this.props.location.state;
              state?.from ?
                this.props.history.push(state?.from) :
                this.props.history.push(`/eco_localization/${this.props.history?.location?.state?.editTemplate?.lpId}/mode=edit`, { ...state, "from": "/exception", "createExceptionBack": "fromCreate" });
            }, 2000);

          } else {
            if (res.statusCode === 400 && res.message !== "") {
              this.setState({
                loading: false,
                throwErr: true,
                errMsg: res.message
              })
              return;
            }
            this.setState({
              throwErr: true,
              errMsg: 'Something went wrong.'
            })
          }
        })
      }
      else {
        await lptService.createExceptionForm({ exceptionForm: exceptionForm }).then(async (res) => {
          if (res.isSuccess) {
            const modifiedProcedure = procedureNames.map(el => {
              return {
                gpId: el.id,
                gperId: res.data.gperId
              }
            });
            await gptService.createGlobalExceptionMapping(modifiedProcedure)
            this.setState({ loading: false, throwSuccess: true, successMsg: "Your Procedure Exception request form has been submitted successfully." })
            setTimeout(() => {
              this.props.history.push('/procedure_exception');
            }, 2000)

            this.setState({
              throwErr: true,
              errMsg: 'Something went wrong while setting procedure(s).'
            })


          } else {
            if (res.statusCode === 400 && res.message !== "") {
              this.setState({
                loading: false,
                throwErr: true,
                errMsg: res.message
              })
              return;
            }
            this.setState({
              loading: false,
              throwErr: true,
              errMsg: 'Something went wrong.'
            })
          }
        })
      }
    }
  }
  handleLocalizeUpdate = async () => {
    const { localizeTemplateData, localizeSections } = this.state;
    await lptService.update({
      localProcedure: localizeTemplateData,
      localProcedureSections: localizeSections,
      localProcedureFiles: [],
    });

  }

  handleSaveAsDraft = async () => {
    const { exceptionForm, procedureNames, geographyScope, businessScope } = this.state;

    let procedure = (procedureNames.map((item: any) => item.text)).join(',');
    let geographySp = (geographyScope.map((item: any) => item.text)).join(',');
    let businessSp = (businessScope.map((item: any) => item.text)).join(',');

    exceptionForm.procedure = procedure;
    exceptionForm.geographyScopeException = geographySp;
    exceptionForm.businessAreaScope = businessSp;
    exceptionForm.title = exceptionForm.title!.trim();
    if (this.validationforDraft()) {
if(this.props.location?.state?.routeArray && this.props.location?.state?.routeArray.length > 0) {

  await lptService.saveAsDraftException(exceptionForm).then(async (res) => {
    if (res.isSuccess) {
      const modifiedProcedure = procedureNames.map(el => {
        return {
          lpId: el.id,
          gperId: res.data.gperId
        }
      })
      console.log('modi', modifiedProcedure);
      await lptService.setLptException(modifiedProcedure)
      this.setState({ throwSuccess: true, successMsg: "Your Procedure Exception request form has been drafted successfully." })
      setTimeout(() => {
        // this.props.history.push(`/raised_exception/${res?.data?.gperId}?mode=view`);
        // this.props.history.go(0);

        // this.props.history.push('/procedure_exception');
        let state: any = this.props.location.state;
        state?.from ?
          this.props.history.push(state?.from) :
          this.props.history.push(`/eco_localization/${this.props.history?.location?.state?.editTemplate?.lpId}/mode=edit`, { ...state, "from": "/exception", "createExceptionBack": "fromCreate" });
      }, 2000);
    } else {
      this.setState({
        errMsg: 'Something went wrong.',
        throwErr: true
      })
    }
  })
} else {
  await lptService.saveAsDraftException(exceptionForm).then(async (res) => {
  if (res.isSuccess) {
    const modifiedProcedure = procedureNames.map(el => {
      return {
        gpId: el.id,
        gperId: res.data.gperId
      }
    });
    await gptService.createGlobalExceptionMapping(modifiedProcedure)
    this.setState({ throwSuccess: true, successMsg: "Your Procedure Exception request form has been drafted successfully." })
    setTimeout(() => {
      this.props.history.push(`/raised_exception/${res.data.gperId}?mode=view`);
      this.props.history.go(0);
    }, 2000)
  } else {
    this.setState({
      errMsg: 'Something went wrong.',
      throwErr: true
    })
  }
  })

    }
    }}


  validationChecker = (): boolean => {
    const { exceptionForm } = this.state;
    let final: boolean = true;
    let emptyFields = [];
    if (!exceptionForm.title) {
      final = false;
      emptyFields.push("Title");

    }
    if (!exceptionForm.exceptionExpirationDate) {
      final = false;
      emptyFields.push("Exception Expiration Date");

    }
    if (!exceptionForm.exceptionClassification) {
      final = false;
      emptyFields.push("Exception Classification");

    }
    if (!exceptionForm.exceptionRequestBy) {
      final = false;
      emptyFields.push("Exception Requested By");

    }
    if (!exceptionForm.exceptionCompletedBy) {
      final = false;
      emptyFields.push("Exception Completed By");

    }
    if (!exceptionForm.procedure) {
      final = false;
      emptyFields.push("Procedure(s)");

    }
    if (!exceptionForm.businessAreaScope) {
      final = false;
      emptyFields.push("Business Area Scope for Exception");

    }
    if (!exceptionForm.geographyScopeException) {
      final = false;
      emptyFields.push("Geography Scope for Exception");

    }
    if (!exceptionForm.exceptionDescription) {
      final = false;
      emptyFields.push("Exception Description");

    }
    if (!exceptionForm.exceptionImpactAfterGrant) {
      final = false;
      emptyFields.push("Impact of Exception if Granted");

    }
    if (!exceptionForm.exceptionRationale) {
      final = false;
      emptyFields.push("Exception Rationale");
    }
    if (!exceptionForm.exceptionTypeForApproval) {
      final = false;
      emptyFields.push("Exception Type for Approval");

    }
    if (!exceptionForm.requestedTextFromProcedure) {
      final = false;
      emptyFields.push("Text from Procedure for which Exception is Requested");

    }
    if (exceptionForm.exceptionTypeForApproval) {
      // Check fields based on exceptionTypeForApproval
      switch (exceptionForm.exceptionTypeForApproval) {
        case "Global Ethics & Compliance Procedure":
          if (this.areObjectValuesEmpty(this.state.gmRegionalAffiliateVpVal)) {
            final = false;
            emptyFields.push("GM or Regional/Affiliate VP at M5/R8+ Level");

          }
          if (this.areObjectValuesEmpty(this.state.m4LegalAttorneySpEcoBlVal)) {
            final = false;
            emptyFields.push("M4+ Legal Attorney Supporting ECO/BL");

          }
          break;
        case "Lilly International Affiliate/Hub Local Procedure Requirements":
          if (this.areObjectValuesEmpty(this.state.ibuHubGmVal)) {
            final = false;
            emptyFields.push("IBU Hub GM");

          }
          if (this.areObjectValuesEmpty(this.state.associateVpEcoIbuVal)) {
            final = false;
            emptyFields.push("Associate VP or Above, Ethics and Compliance, IBU");

          }
          if (this.areObjectValuesEmpty(this.state.associateVpLegalIbuVal)) {
            final = false;
            emptyFields.push("Associate VP or Above, Legal, IBU");

          }
          break;
        case "Global Procedure on Following FRAP":
          if (this.areObjectValuesEmpty(this.state.m4EcoBlVal)) {
            final = false;
            emptyFields.push("M4+ ECO/BL");

          }
          if (this.areObjectValuesEmpty(this.state.m4LegalAttrEcoBlFrapVal)) {
            final = false;
            emptyFields.push("M4+ Legal Attorney Supporting ECO/BL (for FRAP)");

          }
          if (this.areObjectValuesEmpty(this.state.gmFunctionVpr8Val)) {
            final = false;
            emptyFields.push("GM or Functional VP at M5/R8+ Level");

          }
          if (this.areObjectValuesEmpty(this.state.chiefAccOfficerVal)) {
            final = false;
            emptyFields.push("Chief Accounting Officer");

          }
          if (this.areObjectValuesEmpty(this.state.chiefProcOfficerVal)) {
            final = false;
            emptyFields.push("Chief Procurement Officer");

          }
          break;
        case "Global Travel and Expense Procedure":
          if (this.areObjectValuesEmpty(this.state.localBusinessOwnerVal)) {
            final = false;
            emptyFields.push("Local Business Owner");

          }
          if (this.areObjectValuesEmpty(this.state.localChiefFinanOwnerVal)) {
            final = false;
            emptyFields.push("Local Chief Financial Officer (CFO)");

          }
          if (this.areObjectValuesEmpty(this.state.seniorDirEnterTravExpenVal)) {
            final = false;
            emptyFields.push("Senior Director, Enterprise Travel and Expense");

          }
          if (this.areObjectValuesEmpty(this.state.seniorVpChiefFinanOffcVal)) {
            final = false;
            emptyFields.push("Senior Vice President and Chief Financial Officer");

          }
          break;
        case "Recognition Expense":
          console.log("switch case")
          if (this.areObjectValuesEmpty(this.state.localCfoVal)) {

            final = false;
            emptyFields.push("Local CFO");

          }
          if (this.areObjectValuesEmpty(this.state.m5F12BusinessLeaderVal)) {
            final = false;
            emptyFields.push("M5/R12 Business Leader");

          }
          break;
        default:
          // Handle other exceptionTypeForApproval values if needed
          break;
      }
    }

    const errMsg = `Please enter the mandatory data for ${emptyFields.join(", ")}.`;

    this.setState({ errMsg, throwErr: !final });

    return final;
  }
  validationCheckerNoAppr = (): boolean => {
    const { exceptionForm } = this.state;
    let final: boolean = true;
    let emptyFields = [];
    if (!exceptionForm.title) {
      final = false;
      emptyFields.push("Title");

    }
    if (!exceptionForm.exceptionExpirationDate) {
      final = false;
      emptyFields.push("Exception Expiration Date");

    }
    if (!exceptionForm.exceptionClassification) {
      final = false;
      emptyFields.push("Exception Classification");

    }
    if (!exceptionForm.exceptionRequestBy) {
      final = false;
      emptyFields.push("Exception Requested By");

    }
    if (!exceptionForm.exceptionCompletedBy) {
      final = false;
      emptyFields.push("Exception Completed By");

    }
    if (!exceptionForm.procedure) {
      final = false;
      emptyFields.push("Procedure(s)");

    }
    if (!exceptionForm.businessAreaScope) {
      final = false;
      emptyFields.push("Business Area Scope for Exception");

    }
    if (!exceptionForm.geographyScopeException) {
      final = false;
      emptyFields.push("Geography Scope for Exception");

    }
    if (!exceptionForm.exceptionDescription) {
      final = false;
      emptyFields.push("Exception Description");

    }
    if (!exceptionForm.exceptionImpactAfterGrant) {
      final = false;
      emptyFields.push("Impact of Exception if Granted");

    }
    if (!exceptionForm.exceptionRationale) {
      final = false;
      emptyFields.push("Exception Rationale");
    }
    if (!exceptionForm.exceptionTypeForApproval) {
      final = false;
      emptyFields.push("Exception Type for Approval");

    }
    if (!exceptionForm.requestedTextFromProcedure) {
      final = false;
      emptyFields.push("Text from Procedure for which Exception is Requested");

    }


    const errMsg = `Please enter the mandatory data for ${emptyFields.join(", ")}.`;

    this.setState({ errMsg, throwErr: !final });

    return final;
  }


  handleTextInputChange = (event: any) => {
    console.log("ecent", [event.target.name], event.target.value)
    this.setState({
      exceptionForm: {
        ...this.state.exceptionForm, [event.target.name]: event.target.value
      }
    })
  };

  handleexceptionClassificationRadioButtons = (event: any) => {
    this.setState({
      exceptionForm: {
        ...this.state.exceptionForm, exceptionClassification: event.target.value
      }
    });
  }
  handleEcAssociateVpOpRadioButtons = (event: any) => {
    this.setState({
      exceptionForm: {
        ...this.state.exceptionForm, ecAssociateVpOp: event.target.value
      }
    });
  }
  onSelectProcedure = (selected: any) => {
    this.setState({ procedureNames: [...selected] }, () => {
      console.log("proced", this.state.procedureNames)
    });
  }
  onSelectBusinessScope = (selected: any) => {
    this.setState({ businessScope: [...selected] })
  }
  onSelectGeographyScope = (selected: any) => {
    this.setState({ geographyScope: [...selected] })
  }



  action = (
    <>
      <Button
        size="small"
        style={{ textDecoration: "underline", color: "#0075A2" }}
        onClick={() => {
          this.props.history.push('/procedure_exception');
          this.props.history.go(0);
        }}
      >
        VIEW
      </Button>
      {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ throwSuccess: false });
        }}
      />
    </>
  );

  render() {
    const { showPreviousVersion, successMsg, throwSuccess, throwErr, errMsg, loading } = this.state;
    console.log('props', this.props)
    if (loading) {
      return (<><Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box></>)
    }
    const stepsCompleted = this.getActiveStep();
    console.log('stepsCompleted',stepsCompleted)
    // console.log("state", this.state)
    
    return (
      <Box
        style={{
          marginTop: "0.5em",
        }}
      >
        <MyCustomSnackbar
          message={errMsg}
          severity="warning"
          actions={<></>}
          open={throwErr}
          onClose={() => {
            this.setState({ throwErr: false });
          }}
        />
        <MyCustomSnackbar
          message={successMsg}
          severity="success"
          actions={<></>}
          open={throwSuccess}
          onClose={() => {
            this.setState({ throwSuccess: false });
          }}
        />
        {/* <Grid marginBottom={"2em"} container direction="row">
          <BreadCrumb breadCrumbs={this.state.breadCrumbs} />
        </Grid> */}
        <Grid container
          direction={"row"}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1em",
            justifyContent: "space-between",
          }}>
          <Grid item>
            <Grid container spacing={2} style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <Grid item>
                <BackLinkButton
                  onClick={() => {
                    let state: any = this.props.location.state;


                    state?.from ?
                      this.props.history.push(state?.from) :
                      this.props.history.push(`/eco_localization/${this.props.history?.location?.state?.editTemplate?.lpId}/mode=edit`, { ...state, "from": "/exception", "createExceptionBack": "fromBack" });

                  }}
                />
              </Grid>
              <Grid style={{ display: "flex", justifyContent: "flex-start" }} item sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                maxWidth: '42em'
              }}>
                <PageHeader label={`Procedure Exception Request Form`} />

              </Grid>

            </Grid>
          </Grid>
          <Grid item style={{ display: "flex", justifyContent: 'flex-end' }}>
            <Grid container spacing={1}>
              <Grid item>
                <WhiteButton label="Cancel" onClick={() => {
                  let state: any = this.props.location.state;

                  state?.from ?
                    this.props.history.push('/procedure_exception')
                    :
                    this.props.history.push(`/eco_localization/${this.props.history?.location?.state?.editTemplate?.lpId}/mode=edit`)

                }} />
              </Grid>
              {
                this.state.approvalType === 'Route for Approval' ?
                  <>
                    {
                      this.props.location.state.createException ? <>
                      </> : <Grid item>
                        <WhiteButton label="Save as Draft" onClick={this.handleSaveAsDraft} />
                      </Grid>
                    }
                  <Grid item>
                      <RedButton label="Submit for Approval"  onClick={this.handleSubmitForApproval} />
                    </Grid></> :
                  <Grid item>
                    <RedButton label='Create' onClick={this.handleCreateException} />
                  </Grid>
              }
            </Grid>
          </Grid>
          <Grid item container marginLeft='4.1rem'>
            <Typography style={{ fontSize: '12px' }}>Complete the following form to request an exception to an E&C Procedure</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
          {this.props.location.state?.from && this.context.userRoles.includes('ADMIN_USER' as UserRole) ? <Grid item xs={3}>
            <InputLabel style={{ fontWeight: 'bold' }}>Approval Type</InputLabel>
            <SingleSelect defaultValue='Route for Approval' values={approvalTypes} value={this.state.approvalType} name='approvalType' onChange={this.approvalTypeChange} />
          </Grid> : <></>}
        </Grid>
      

        <ExceptionFormComponent
          exceptionForm={this.state.exceptionForm}
          disableProcedure={this.state.disableProcedure}
          businessScope={this.state.businessScope}
          geographyScope={this.state.geographyScope}
          handleEcAssociateVPDateChange={this.handleEcAssociateVPDateChange}
          handleEcAssociateVpOpRadioButtons={this.handleEcAssociateVpOpRadioButtons}
          handleEcBlConsultDateChange={this.handleEcBlConsultDateChange}
          handleEcGeographicDateChange={this.handleEcGeographicDateChange}
          handleExceptionEffDateChange={this.handleExceptionEffDateChange}
          handleExceptionExpiryDateChange={this.handleExceptionExpiryDateChange}
          handleLegalConsultDateChange={this.handleLegalConsultDateChange}
          handleProcedureOwnerDateChange={this.handleProcedureOwnerDateChange}
          handleexceptionClassificationRadioButtons={this.handleexceptionClassificationRadioButtons}
          onDropDownChange={this.onDropDownChange}
          onSelectBusinessScope={this.onSelectBusinessScope}
          onSelectGeography={this.onSelectGeographyScope}
          onSelectProcedure={this.onSelectProcedure}
          load={this.state.load}
          onTextInputChange={this.handleTextInputChange}
          procedureNames={this.state.procedureNames}
          exceptionBusinesData={this.state.exceptionBusinessArea}
          geographyScopeData={this.state.exceptiongeography}
          procedureNameData={this.state.proceduresData}
          m4LegalAttrLoading={this.state.m4LegalAttrLoading}
          SeniorVPCFOLoading={this.state.SeniorVPCFOLoading}
          SenrEntrLoading={this.state.SenrEntrLoading}
          assocVpECOLoading={this.state.assocVpECOLoading}
          assocVpIBULoading={this.state.assocVpIBULoading}
          chiefAccOffLoading={this.state.chiefAccOffLoading}
          chiefProcOfcLoading={this.state.chiefProcOfcLoading}
          gmRegionalLoading={this.state.gmRegionalLoading}
          gmFuncVPLoading={this.state.gmFuncVPLoading}
          m5F12BLLoading={this.state.m5F12BLLoading}
          associateVpEcoIbuVal={this.state.associateVpEcoIbuVal}
          associateVpLegalIbuVal={this.state.associateVpLegalIbuVal}
          chiefAccOfficerVal={this.state.chiefAccOfficerVal}
          chiefProcOfficerVal={this.state.chiefProcOfficerVal}
          gmFunctionVpr8Val={this.state.gmFunctionVpr8Val}
          gmRegionalAffiliateVpVal={this.state.gmRegionalAffiliateVpVal}
          ibuHubGmVal={this.state.ibuHubGmVal}
          approverNames={this.state.approverNames}
          localBusinessOwnerVal={this.state.localBusinessOwnerVal}
          localCfoVal={this.state.localCfoVal}
          localChiefFinanOwnerVal={this.state.localChiefFinanOwnerVal}
          m4EcoBlVal={this.state.m4EcoBlVal}
          m4LegalAttorneySpEcoBlVal={this.state.m4LegalAttorneySpEcoBlVal}
          m4LegalAttrEcoBlFrapVal={this.state.m4LegalAttrEcoBlFrapVal}
          m5F12BusinessLeaderVal={this.state.m5F12BusinessLeaderVal}
          seniorDirEnterTravExpenVal={this.state.seniorDirEnterTravExpenVal}
          seniorVpChiefFinanOffcVal={this.state.seniorVpChiefFinanOffcVal}
          setAssociateVpEcoIbuValue={this.setAssociateVpEcoIbuInputValue}
          setAssociateVpLegalIbuValue={this.setAssociateVpLegalIbuInputValue}
          setChiefAccOfficerValue={this.setChiefAccOfficerInputValue}
          setChiefProcOfficerValue={this.setChiefProcOfficerInputValue}
          setGmFunctionVpr8Value={this.setGmFunctionVpr8InputValue}
          setGmRegionalAffiliateVpValue={this.setGmRegionalAffiliateVpInputValue}
          setIbuHubGmValue={this.setIbuHubGmInputValue}
          setLocalBusinessOwnerValue={this.setLocalBusinessOwnerValue}
          setLocalCfoValue={this.setLocalCfoValue}
          setLocalChiefFinanOwnerValue={this.setLocalChiefFinanOwnerValue}
          setM4EcoBlValue={this.setM4EcoBlValue}
          setSeniorDirEnterTravExpenValue={this.setSeniorDirEnterTravExpenValue}
          setM4LegalAttorneySpEcoBlValue={this.setM4LegalAttorneySpEcoBlValue}
          setM4LegalAttrEcoBlFrapValue={this.setM4LegalAttrEcoBlFrapValue}
          setM5F12BusinessLeaderValue={this.setM5F12BusinessLeaderValue}
          setSeniorVpChiefFinanOffcValue={this.setSeniorVpChiefFinanOffcValue}
          onAssociateVpEcoIbuSelect={this.onAssociateVpEcoIbuSelect}
          onAssociateVpLegalIbuSelect={this.onAssociateVpLegalIbuSelect}
          onChiefAccOfficerSelect={this.onChiefAccOfficerSelect}
          onChiefProcOfficerSelect={this.onChiefProcOfficerSelect}
          onGmFunctionVpr8Select={this.onGmFunctionVpr8Select}
          onGmRegionalAffiliateVpSelect={this.onGmRegionalAffiliateVpSelect}
          onIbuHubGmSelect={this.onIbuHubGmSelect}
          onLocalBusinessOwnerSelect={this.onLocalBusinessOwnerSelect}
          onLocalCfoSelect={this.onLocalCfoSelect}
          onLocalChiefFinanOwnerSelect={this.onLocalChiefFinanOwnerSelect}
          onM4EcoBlSelect={this.onM4EcoBlSelect}
          onM4LegalAttorneySpEcoBlSelect={this.onM4LegalAttorneySpEcoBlSelect}
          onM4LegalAttrEcoBlFrapSelect={this.onM4LegalAttrEcoBlFrapSelect}
          onM5F12BusinessLeaderSelect={this.onM5F12BusinessLeaderSelect}
          onSeniorDirEnterTravExpenSelect={this.onSeniorDirEnterTravExpenSelect}
          onSeniorVpChiefFinanOffcSelect={this.onSeniorVpChiefFinanOffcSelect}
          ibuHubGmLoading={this.state.ibuHubGmLoading}
          localBusOwnerLoading={this.state.localBusOwnerLoading}
          localCFOLoading={this.state.localCFOLoading}
          localChiefFinOfcLoading={this.state.localChiefFinOfcLoading}
          m4EcoBlLoading={this.state.m4EcoBlLoading}
          m4LegalAttrFRAPLoading={this.state.m4LegalAttrFRAPLoading}
        />
      </Box>
    )
  }

  areObjectValuesEmpty(obj: any) {
    return Object.values(obj).every(x => (x === null || x === '' || x === undefined));
  }


  validationforDraft = () => {
    const { exceptionForm } = this.state;
    let final: boolean = true;
    if (exceptionForm.title === "") {
      final = false;
      this.setState({ errMsg: "Please fill Title field." });
    } else if (exceptionForm.procedure === "") {
      final = false;
      this.setState({ errMsg: "Please select options from Procedure(s) field." });
    }
    this.setState({ throwErr: !final });

    return final;

  }

  handleAdherenceReportGenerate = async () => {
    const { exceptionForm } = this.state;
    const { editTemplate, sections, previousSections, templateData } = this.props?.history?.location?.state;

    const configs: DocumentConfigModel[] = [];
    console.log('editTemplate', editTemplate, sections, previousSections, templateData)
    /* --------------------- Template Parsing and Comparison -------------------- */
    const basicPsNewjson = typeof editTemplate!.basicPrinciples == "string" ? JSON.parse(editTemplate!.basicPrinciples) : editTemplate!.basicPrinciples
    const basicPsNewjsonText = this.handleRichTextJsonToPara(basicPsNewjson)
    const basicPsOldjson = typeof templateData.basicPrinciples === 'string' ? JSON.parse(templateData.basicPrinciples) : templateData.basicPrinciples
    const basicPsOldjsonText = this.handleRichTextJsonToPara(basicPsOldjson)
    const headerNewjson = typeof editTemplate!.header === 'string' ? JSON.parse(editTemplate!.header) : editTemplate!.header
    const headerNewjsonText = this.handleRichTextJsonToPara(headerNewjson)
    const headerOldjson = typeof templateData.header === 'string' ? JSON.parse(templateData.header) : templateData.header
    const headerOldjsonText = this.handleRichTextJsonToPara(headerOldjson)
    const bodyTextNewjson = typeof editTemplate!.bodyText === 'string' ? JSON.parse(editTemplate!.bodyText) : editTemplate!.bodyText
    const bodyTextNewjsonText = this.handleRichTextJsonToPara(bodyTextNewjson)
    const bodyTextOldjson = typeof templateData.bodyText === 'string' ? JSON.parse(templateData.bodyText) : templateData.bodyText
    const bodyTextOldjsonText = this.handleRichTextJsonToPara(bodyTextOldjson)
    const basicPrincipleChangedText = await lptService.compareSections({ paragraph1: basicPsOldjsonText, paragraph2: basicPsNewjsonText });
    const headerChangedText = await lptService.compareSections({ paragraph1: headerOldjsonText, paragraph2: headerNewjsonText });
    const bodyTextChangedText = await lptService.compareSections({ paragraph1: bodyTextOldjsonText, paragraph2: bodyTextNewjsonText });

    const allRationals = ['Less Restrictive than global requirement (exception required)','Prohibited locally','More Restrictive than global requirement',"Other - provide rationale"]
    const rationalWiseData: any= {};
    const oldSectionParsed = previousSections.map((section: any) => {
      return this.handleSectionParser(section)
    });
    const newSectionParsed: any = sections!.map((section: any) => {
      return this.handleSectionParser(section)
    });
    const fetchComment = (section: any) => {
      const comment = section.header_blackTextChangeType_Comment || section.bodyText_blackTextChangeType_Comment
      return comment;
    }
    for (const rational of allRationals) {
      const newSectionHeaderPara = newSectionParsed.filter((obj: any) => {
        return obj.header_blackTextChangeType === rational
      }).map((section: any) => {
        return {sectionId: section.sectionId, header: this.handleRichTextJsonToPara(section.header), comment: fetchComment(section) || null}
      });
      const newSectionBodyTextPara = newSectionParsed.filter((obj: any) => {
        return obj.bodyText_blackTextChangeType === rational
      }).map((section: any) => {
        return {sectionId: section.sectionId, body: this.handleRichTextJsonToPara(section.bodyText), comment: fetchComment(section) || null}
      });
      const difffHPromise = newSectionHeaderPara.map((para: any, index: number) => {
        const d = oldSectionParsed.find((sec: any) => sec.sectionId === para.sectionId);
        return lptService.compareSections({ paragraph1: 
          this.handleRichTextJsonToPara(d.header), paragraph2: para.header }, para.comment, this.handleRichTextJsonToPara(d.header))
      });
      const difffBTPromise = newSectionBodyTextPara.map((para: any, index: number) => {
        const d = oldSectionParsed.find((sec: any) => sec.sectionId === para.sectionId);
        return lptService.compareSections({ 
          paragraph1: this.handleRichTextJsonToPara(d.bodyText), paragraph2: para.body }, para.comment,   this.handleRichTextJsonToPara(d.header))
      });
      const diffH = await Promise.all(difffHPromise);
      const diffBT = await Promise.all(difffBTPromise);
      // add diffH an difffBTFinal to rationalWiseData
      // find which has more length and fill other with empty
      const diffHLength = diffH.length;
      const difffBTFinalLength = diffBT.length;
      const finalDiffh = [];
      const finalDiffBT = [];
      if(diffHLength > difffBTFinalLength) {
        // find difffBTFinal with same header property as diffH and match the index and rest fill empty
        for(let i=0; i<diffHLength; i++) {
          const d = diffBT.find((obj: any) => obj.header === diffH[i].header);
          finalDiffh.push(diffH[i]);
          if(d) {
            finalDiffBT.push(d)
            
          } else {
            finalDiffBT.push({added: '[]', deleted: '[]', difference: '', global: '', header: ''})
          }
        }
      } else if(difffBTFinalLength > diffHLength) {
        // find diffH with same header property as difffBTFinal and match the index and rest fill empty
        for(let i=0; i<difffBTFinalLength; i++) {
          const d = diffH.find((obj: any) => obj.header === diffBT[i].header);
          finalDiffBT.push(diffBT[i]);
          if(d) {
            finalDiffh.push(d)
          } else {
            finalDiffh.push({added: '[]', deleted: '[]', difference: '', global: '', header: ''})
          }
        }
      }
      rationalWiseData[rational] = {headers: finalDiffh, bodyTexts: finalDiffBT}
    }
    
    configs.push({
      type: "TEXT",
      value: "Summary of Local Changes to Global Requirements",
      textTypes: [
        {
          key: 'FONT_SIZE', value: '11'
        },
        { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
        { key: 'text-align', value: 'center' },

      ],
      sectionType: 'CHILDREN',
    });
    const headerTitle = typeof templateData.header === 'string' ? JSON.parse(templateData.header) : templateData.header
    const headerTitlePara = this.handleRichTextJsonToPara(headerTitle)
    configs.push({
      type: "TEXT",
      value: moment().format('LL'),
      textTypes: [
        {
          key: 'FONT_SIZE', value: '11'
        },
        { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
        { key: 'text-align', value: 'right' },

      ],
      sectionType: 'FOOTER',
    });
    configs.push({
      type: 'TEXT',
      value: `${editTemplate!.templateName}`,
      textTypes: [
        { key: 'break', value: 1 },
        {
          key: 'FONT_SIZE', value: '11'
        },
        { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

      ],
      sectionType: 'CHILDREN',
    })
    configs.push({
      type: 'TEXT',
      value: `Version ${editTemplate!.versionNumber}`,
      textTypes: [
        { key: 'break', value: 1 },
        {
          key: 'FONT_SIZE', value: '11'
        },
        { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

      ],
      sectionType: 'CHILDREN',
    })
    configs.push({
      type: 'TEXT',
      value: `${editTemplate!.geoGraphyName.split('_').join(',')}`,
      textTypes: [
        { key: 'break', value: 1 },
        {
          key: 'FONT_SIZE', value: '11'
        },
        { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

      ],
      sectionType: 'CHILDREN',
    });
    configs.push({
      type: 'TEXT',
      value: '',
      textTypes: [
        { key: 'break', value: 1 },
      ],
      sectionType: 'CHILDREN',
    });
   
    if (editTemplate!.basicPrinciples_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || editTemplate!.header_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || editTemplate!.bodyText_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || this.lessRestrictiveSectionCheck(sections)) {

        configs.push({
          type:'TEXT',
          value: '',
          textTypes:[
            {key:'break', value:1}
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          
        })
        configs.push({
          type: 'TEXT',
          value: 'Exception Title:',
          textTypes: [
            { key: 'break', value: 1 },
            {
              key: 'FONT_SIZE', value: '11'
            },

            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: 'exceptiontitle'
        });
        configs.push({
          type: 'TEXT',
          value: exceptionForm.title,
          textTypes: [

            {
              key: 'FONT_SIZE', value: '11'
            },

            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: 'exceptiontitle'
        });
        configs.push({
          type: 'TEXT',
          value: 'Exception Rationale:',
          textTypes: [
            { key: 'break', value: 2 },
            {
              key: 'FONT_SIZE', value: '11'
            },

            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: 'exceptionrationale'
        });
        configs.push({
          type: 'TEXT',
          value: exceptionForm.exceptionRationale,
          textTypes: [

            {
              key: 'FONT_SIZE', value: '11'
            },

            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: 'exceptionrationale'
        });
        configs.push({
          type: 'TEXT',
          value: 'Date of Exception Approval:',
          textTypes: [
            { key: 'break', value: 2 },
            {
              key: 'FONT_SIZE', value: '11'
            },

            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: 'exceptionappr'
        });
        configs.push({
          type: 'TEXT',
          value: exceptionForm.approvalTimeStamp ? `${moment(exceptionForm.approvalTimeStamp).format('MMMM DD, YYYY')}` : 'Not Yet Approved',
          textTypes: [

            {
              key: 'FONT_SIZE', value: '11'
            },

            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: 'exceptionappr'
        });
        configs.push({
          type: 'TEXT',
          value: 'Exception Requested by:',
          textTypes: [
            { key: 'break', value: 2 },
            {
              key: 'FONT_SIZE', value: '11'
            },

            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: 'exceptionreq'
        });
        configs.push({
          type: 'TEXT',
          value: exceptionForm.exceptionRequestBy,
          textTypes: [

            {
              key: 'FONT_SIZE', value: '11'
            },

            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: 'exceptionreq'
        });

     
    }
   console.log('rationalWiseData', rationalWiseData);
   Object.keys(rationalWiseData).forEach((rational: any) => {
    if ((rationalWiseData[rational].headers.length !== 0 && rationalWiseData[rational].bodyTexts.length !== 0) || editTemplate!.basicPrinciples_blackTextChangeType === rational || editTemplate!.header_blackTextChangeType === rational || editTemplate!.bodyText_blackTextChangeType === rational) {
    configs.push({
      type: 'TEXT',
      value: ``,
      textTypes: [
        {
          key: 'HORIZONTAL_LINE'
        },
      ],
      sectionType: 'CHILDREN',
    });
    configs.push({
      type: 'TEXT',
      value: `Rationale: `,
      textTypes: [
        { key: 'break', value: 1 },
        {
          key: 'FONT_SIZE', value: '11'
        },
        { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

      ],
      sectionType: 'CHILDREN',
      elementType: 'ROW',
      uid: `${rational} rationale`,
    });
    configs.push({
      type: 'TEXT',
      value: rational,
      textTypes: [
        {
          key: 'FONT_SIZE', value: '11'
        },
        { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

      ],
      sectionType: 'CHILDREN',
      elementType: 'ROW',
      uid: `${rational} rationale`,
    });
  }
    let lessRestCount = 1;
    if (editTemplate!.basicPrinciples_blackTextChangeType === rational) {
      if (rational === 'Other - provide rationale') {
        configs.push({
          type: 'TEXT',
          value: `${editTemplate!.basicPrinciples_blackTextChangeType_Comment}`,
          textTypes: [
            { key: 'break', value: 1 },
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

          ],
          sectionType: 'CHILDREN',
        });
      }
      configs.push({
        type: 'TEXT',
        value: `Change #${lessRestCount} to Basic Principles`,
        textTypes: [
          { key: 'break', value: 1 },
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
      });
      configs.push({
        type: 'TEXT',
        value: '',
        textTypes: [
        ],
        sectionType: 'CHILDREN',
      });
      lessRestCount++;
      const splitedData = basicPrincipleChangedText.difference.split(/\s+/)
      const globalData = basicPrincipleChangedText.global.split(/\s+/)
      const addedWord = JSON.parse(basicPrincipleChangedText.added)
      const delWords = JSON.parse(basicPrincipleChangedText.deleted)
      if (splitedData.length > 0) {

        configs.push({
          type: 'TEXT',
          value: `Local Requirement`,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },
          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: `${rational} localreq`,
        });

        configs.push({
          type: 'TEXT',
          value: ``,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'break', value: 1 },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
        })


        splitedData.forEach((el: any, index: number) => {
          const wordPos = addedWord.find((words: any) => {
            return (words === index + 1)
          })

          configs.push({
            type: 'TEXT',
            value: el,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
              { key: 'BG_COLOR', value: wordPos ? 'green' : '' },


            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProb`
          })


          if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [
                { key: 'break', value: 1 }
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProbBody`
            })
          } else {
            configs.push({
              type: 'TEXT',
              value: ' ',
              textTypes: [

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProb`
            })
          }
        })
        configs.push({
          type: 'TEXT',
          value: '',
          textTypes: [

            { key: 'break', value: 1 },


          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
        })

        
      }
      if(globalData.length > 0){
        configs.push({
          type: 'TEXT',
          value: `Global Requirement`,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: `${rational} localreq`,
        });

        configs.push({
          type: 'TEXT',
          value: ``,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'break', value: 1 },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
        })
        globalData.forEach((el: any, index: number) => {
          const wordDel = delWords.find((words: any) => {
            return (words === index + 1)
          })
          configs.push({
            type: 'TEXT',
            value: el,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
              wordDel ? { key: 'STRIKETHROUGH' } : { key: '' },
              wordDel ? wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' } : { key: '' },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProb`
          })


          if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [
                { key: 'break', value: 1 }
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProbBody`
            })
          } else {
            configs.push({
              type: 'TEXT',
              value: ' ',
              textTypes: [

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProb`
            })
          }
        })
      }
     
    }
    if (editTemplate!.header_blackTextChangeType === rational) {
      if (rational === 'Other - provide rationale') {
        configs.push({
          type: 'TEXT',
          value: `${editTemplate!.header_blackTextChangeType_Comment}`,
          textTypes: [
            { key: 'break', value: 1 },
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

          ],
          sectionType: 'CHILDREN',
        });
      }
      configs.push({
        type: 'TEXT',
        value: `Change #${lessRestCount} to ${headerTitlePara}`,
        textTypes: [
          { key: 'break', value: 1 },
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
      });
      configs.push({
        type: 'TEXT',
        value: '',
        textTypes: [
        ],
        sectionType: 'CHILDREN',
      });
      lessRestCount++;
      const splitedData = headerChangedText.difference.split(/\s+/)
      const globalData = headerChangedText.global.split(/\s+/)
      const addedWord = JSON.parse(headerChangedText.added)
      const delWords = JSON.parse(headerChangedText.deleted)
      if(splitedData.length > 0){
        configs.push({
          type: 'TEXT',
          value: `Local Requirement`,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: `${rational} localreq`,
        });

        configs.push({
          type: 'TEXT',
          value: ``,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'break', value: 1 },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',

        })
        
        splitedData.forEach((el: any, index: number) => {
          const wordPos = addedWord.find((words: any) => {
            return (words === index + 1)
          })
          configs.push({
            type: 'TEXT',
            value: el,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
              { key: 'BG_COLOR', value: wordPos ? 'green' : '' },


            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProbHead`
          })
          configs.push({
            type: 'TEXT',
            value: ' ',
            textTypes: [

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProbHead`
          })
        })
        configs.push({
          type: 'TEXT',
          value: '',
          textTypes: [
            { key: 'break', value: 1 }
          ],
          sectionType: 'CHILDREN',
        });
       
      }
     if(globalData.length > 0) {
      configs.push({
        type: 'TEXT',
        value: `Global Requirement`,
        textTypes: [
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
        elementType: 'ROW',
        uid: `${rational} localreq`,
      });

      configs.push({
        type: 'TEXT',
        value: ``,
        textTypes: [
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'break', value: 1 },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
        elementType: 'ROW',

      })
      globalData.forEach((el: any, index: number) => {

        const wordDel = delWords.find((words: any) => {
          return (words === index + 1)
        })
        configs.push({
          type: 'TEXT',
          value: el,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
            wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' },
            wordDel ? { key: 'STRIKETHROUGH' } : { key: '' }

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: `${rational}basicProbHead`
        })


        if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
          configs.push({
            type: 'TEXT',
            value: '',
            textTypes: [
              { key: 'break', value: 1 }
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProbBody`
          })
        } else {
          configs.push({
            type: 'TEXT',
            value: ' ',
            textTypes: [

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProbHead`
          })
        }
      })
     }
      
    }
    if (editTemplate!.bodyText_blackTextChangeType === rational) {
      if (rational === 'Other - provide rationale') {
        configs.push({
          type: 'TEXT',
          value: `${editTemplate!.bodyText_blackTextChangeType_Comment}`,
          textTypes: [
            { key: 'break', value: 1 },
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

          ],
          sectionType: 'CHILDREN',
        });
      }
      configs.push({
        type: 'TEXT',
        value: `Change #${lessRestCount} to ${headerTitlePara}`,
        textTypes: [
          { key: 'break', value: 1 },
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
      });
      configs.push({
        type: 'TEXT',
        value: '',
        textTypes: [
        ],
        sectionType: 'CHILDREN',
      });
      lessRestCount++;
      const splitedData = bodyTextChangedText.difference.split(/\s+/)
      const globalData = bodyTextChangedText.global.split(/\s+/)
      const addedWord = JSON.parse(bodyTextChangedText.added)
      const delWords = JSON.parse(bodyTextChangedText.deleted)
      if(splitedData.length > 0){
        configs.push({
          type: 'TEXT',
          value: `Local Requirement`,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: `${rational} localreq`,
        });


        configs.push({
          type: 'TEXT',
          value: ``,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'break', value: 1 },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',

        })
       
        splitedData.forEach((el: any, index: number) => {
          const wordPos = addedWord.find((words: any) => {
            return (words === index + 1)
          })

          configs.push({
            type: 'TEXT',
            value: el,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
              { key: 'BG_COLOR', value: wordPos ? 'green' : '' },


            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProbBody`
          })
          if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [
                { key: 'break', value: 1 }
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProbBody`
            })
          } else {
            configs.push({
              type: 'TEXT',
              value: ' ',
              textTypes: [

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProbBody`
            })
          }
        })
        configs.push({
          type: 'TEXT',
          value: '',
          textTypes: [
            { key: 'break', value: 1 }
          ],
          sectionType: 'CHILDREN',
        });

       
      }
     if(globalData.length > 0) {
      configs.push({
        type: 'TEXT',
        value: `Global Requirement`,
        textTypes: [
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
        elementType: 'ROW',
        uid: `${rational} localreq`,
      });


      configs.push({
        type: 'TEXT',
        value: ``,
        textTypes: [
          {
            key: 'FONT_SIZE', value: '11'
          },
          { key: 'break', value: 1 },
          { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

        ],
        sectionType: 'CHILDREN',
        elementType: 'ROW',

      })
      globalData.forEach((el: any, index: number) => {

        const wordDel = delWords.find((words: any) => {
          return (words === index + 1)
        })
        configs.push({
          type: 'TEXT',
          value: el,
          textTypes: [
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
            wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' },
            wordDel ? { key: 'STRIKETHROUGH' } : { key: '' }

          ],
          sectionType: 'CHILDREN',
          elementType: 'ROW',
          uid: `${rational}basicProbBody`
        })


        if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
          configs.push({
            type: 'TEXT',
            value: '',
            textTypes: [
              { key: 'break', value: 1 }
            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProbBody`
          })
        } else {
          configs.push({
            type: 'TEXT',
            value: ' ',
            textTypes: [

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProbBody`
          })
        }
      })
     }
      

    }
    const headers = rationalWiseData[rational].headers;
    const bodyTexts = rationalWiseData[rational].bodyTexts;
    headers.forEach((header: any, index: number) => {
      if (JSON.parse(header.added).length || JSON.parse(header.deleted).length) {
        if (rational === 'Other - provide rationale') {
          configs.push({
            type: 'TEXT',
            value: `${header.comment}`,
            textTypes: [
              { key: 'break', value: 1 },
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

            ],
            sectionType: 'CHILDREN',
          });
        }

        configs.push({
          type: 'TEXT',
          value: `Change #${lessRestCount} to ${header.header}`,
          textTypes: [
            { key: 'break', value: 1 },
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
        });
        configs.push({
          type: 'TEXT',
          value: '',
          textTypes: [
          ],
          sectionType: 'CHILDREN',
        });
        lessRestCount++
        const splitedData = header.difference.split(/\s+/)
          const globalData = header.global.split(/\s+/)
          const addedWord = JSON.parse(header.added)
          const delWords = JSON.parse(header.deleted)
        if(splitedData.length > 0) {
          configs.push({
            type: 'TEXT',
            value: `Local Requirement`,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational} localreq`,
          });


          configs.push({
            type: 'TEXT',
            value: ``,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'break', value: 1 },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',

          })
          
          splitedData.forEach((el: any, spltInd: number) => {
            const wordPos = addedWord.find((words: any) => {
              return (words === spltInd + 1)
            })
            configs.push({
              type: 'TEXT',
              value: el,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                { key: 'BG_COLOR', value: wordPos ? 'green' : '' },


              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProb`
            })

            if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
              configs.push({
                type: 'TEXT',
                value: '',
                textTypes: [
                  { key: 'break', value: 1 }
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbBody`
              })
            } else {
              configs.push({
                type: 'TEXT',
                value: ' ',
                textTypes: [

                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProb`
              })
            }

          })

          configs.push({
            type: 'TEXT',
            value: ``,
            textTypes: [

              { key: 'break', value: 1 },


            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',

          })
          configs.push({
            type: 'TEXT',
            value: `Global Requirement`,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational} localreq`,
          });


          configs.push({
            type: 'TEXT',
            value: ``,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'break', value: 1 },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',

          })
        }
       if(globalData.length > 0) {
        globalData.forEach((el: any, glblInd: number) => {

          const wordDel = delWords.find((words: any) => {
            return (words === glblInd + 1)
          })
          configs.push({
            type: 'TEXT',
            value: el,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
              wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' },
              wordDel ? { key: 'STRIKETHROUGH' } : { key: '' }

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProb`
          })
          if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
            configs.push({
              type: 'TEXT',
              value: '',
              textTypes: [
                { key: 'break', value: 1 }
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProbBody`
            })
          }
          configs.push({
            type: 'TEXT',
            value: ' ',
            textTypes: [

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational}basicProb`
          })

        })
       }
        
      }
      console.log('bodyTexts- ind', bodyTexts[index], index, 'type', typeof bodyTexts[index].added);
      if (JSON.parse(bodyTexts[index].added).length || JSON.parse(bodyTexts[index].deleted).length) {
        if (rational === 'Other - provide rationale') {
          configs.push({
            type: 'TEXT',
            value: bodyTexts[index].comment,
            textTypes: [
              { key: 'break', value: 1 },
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },

            ],
            sectionType: 'CHILDREN',
          });
        }
        configs.push({
          type: 'TEXT',
          value: `Change #${lessRestCount} to ${bodyTexts[index].header}`,
          textTypes: [
            { key: 'break', value: 1 },
            {
              key: 'FONT_SIZE', value: '11'
            },
            { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

          ],
          sectionType: 'CHILDREN',
        });
        configs.push({
          type: 'TEXT',
          value: '',
          textTypes: [
          ],
          sectionType: 'CHILDREN',
        });
        lessRestCount++
        const splitedBodyData = bodyTexts[index].difference.split(/\s+/)
        const globalBodyData = bodyTexts[index].global.split(/\s+/)
        const addedBodyWord = JSON.parse(bodyTexts[index].added)
        const delBodyWords = JSON.parse(bodyTexts[index].deleted)
        if(splitedBodyData.length > 0) {
          configs.push({
            type: 'TEXT',
            value: `Local Requirement`,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational} localreq`,
          });


          configs.push({
            type: 'TEXT',
            value: ``,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'break', value: 1 },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',

          })
          
          splitedBodyData.forEach((el: any, spltBdyInd: number) => {
            const wordPos = addedBodyWord.find((words: any) => {
              return (words === spltBdyInd + 1)
            })
            configs.push({
              type: 'TEXT',
              value: el,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                { key: 'BG_COLOR', value: wordPos ? 'green' : '' },
              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProb`
            })
            if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
              configs.push({
                type: 'TEXT',
                value: '',
                textTypes: [
                  { key: 'break', value: 1 }
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbBody`
              })
            } else {
              configs.push({
                type: 'TEXT',
                value: ' ',
                textTypes: [

                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProb`
              })
            }
          })
          configs.push({
            type: 'TEXT',
            value: '',
            textTypes: [
              { key: 'break', value: 1 }
            ],
            sectionType: 'CHILDREN',
          });
        
        }
        if(globalBodyData.length > 0) {
          configs.push({
            type: 'TEXT',
            value: `Global Requirement`,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',
            uid: `${rational} localreq`,
          });
          configs.push({
            type: 'TEXT',
            value: ``,
            textTypes: [
              {
                key: 'FONT_SIZE', value: '11'
              },
              { key: 'break', value: 1 },
              { key: 'FONT_FAMILY', value: Constants.DocFontFamilyBold },

            ],
            sectionType: 'CHILDREN',
            elementType: 'ROW',

          })
          globalBodyData.forEach((el: any, glblBdyInd: number) => {
            const wordDel = delBodyWords.find((words: any) => {
              return (words === glblBdyInd + 1)
            })
            configs.push({
              type: 'TEXT',
              value: el,
              textTypes: [
                {
                  key: 'FONT_SIZE', value: '11'
                },
                { key: 'FONT_FAMILY', value: Constants.DocFontFamilyMedium },
                wordDel ? { key: 'COLOR', value: '#FF0000' } : { key: '' },
                wordDel ? { key: 'STRIKETHROUGH' } : { key: '' }

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProb`
            })
            if (el.endsWith('.') || el.endsWith('. ') || el === '.') {
              configs.push({
                type: 'TEXT',
                value: '',
                textTypes: [
                  { key: 'break', value: 1 }
                ],
                sectionType: 'CHILDREN',
                elementType: 'ROW',
                uid: `${rational}basicProbBody`
              })
            }
            configs.push({
              type: 'TEXT',
              value: ' ',
              textTypes: [

              ],
              sectionType: 'CHILDREN',
              elementType: 'ROW',
              uid: `${rational}basicProb`
            })
          })
        }
      }


    });
    // }
  })



    console.log('configddds', configs);
    const blob = await DocxService.createDocument(configs);
    const file = new File([blob], "AdherenceReport.docx", {
      type: blob.type,
    });
    const documents3FileKey = `localproceduretemplate/${editTemplate!.lpId}/${DocumentType.GPT_DOCUMENT}/${editTemplate!.versionNumber}/${file.name}`;
    editTemplate!._adherenceReport = {} as FileUploadModelForLP;
    editTemplate!._adherenceReport.s3filekey = documents3FileKey;
    editTemplate!._adherenceReport.file = file;
    editTemplate!._adherenceReport.name = "AdherenceReport";
    // editTemplate!._adherenceReport.fileversion = editTemplate!.versionNumber;
    editTemplate!._adherenceReport.type = file.type as FileType;
    editTemplate!._adherenceReport.ext = "docx";
    editTemplate!._adherenceReport.lpId = editTemplate!.lpId!;
    editTemplate!._adherenceReport.sizeInKB = Math.round(file.size / 1024);
    editTemplate!._adherenceReport.sizeInMB = Math.round(file.size / 1024 / 1024);
    const upload = await ProcedureFileService.uploadDocumentForLP(editTemplate!._adherenceReport);
    if (upload) {
      await ProcedureFileService.docx2pdf(documents3FileKey, FileType.DOCX)
    }

  }
  
  setAssociateVpEcoIbuInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ assocVpECOLoading: true })
    } else {
      this.setState({ assocVpECOLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, assocVpECOLoading: false });
      }
    }
  };
  setAssociateVpLegalIbuInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ assocVpIBULoading: true })
    } else {
      this.setState({ assocVpIBULoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, assocVpIBULoading: false });
      }
    }
  };
  setChiefAccOfficerInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ chiefAccOffLoading: true })
    } else {
      this.setState({ chiefAccOffLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, chiefAccOffLoading: false });
      }
    }
  };
  setChiefProcOfficerInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ chiefProcOfcLoading: true })
    } else {
      this.setState({ chiefProcOfcLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, chiefProcOfcLoading: false });
      }
    }
  };
  setGmFunctionVpr8InputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ gmFuncVPLoading: true })
    } else {
      this.setState({ gmFuncVPLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, gmFuncVPLoading: false });
      }
    }
  };
  setGmRegionalAffiliateVpInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ gmRegionalLoading: true })
    } else {
      this.setState({ gmRegionalLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, gmRegionalLoading: false });
      }
    }
  };
  setIbuHubGmInputValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ ibuHubGmLoading: true })
    } else {
      this.setState({ ibuHubGmLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, ibuHubGmLoading: false });
      }
    }
  };
  setLocalBusinessOwnerValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localBusOwnerLoading: true })
    } else {
      this.setState({ localBusOwnerLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localBusOwnerLoading: false });
      }
    }
  };
  setLocalCfoValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localCFOLoading: true })
    } else {
      this.setState({ localCFOLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localCFOLoading: false });
      }
    }
  };
  setLocalChiefFinanOwnerValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ localChiefFinOfcLoading: true })
    } else {
      this.setState({ localChiefFinOfcLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, localChiefFinOfcLoading: false });
      }
    }
  };
  setM4EcoBlValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4EcoBlLoading: true })
    } else {
      this.setState({ m4EcoBlLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4EcoBlLoading: false });
      }
    }
  };
  setM4LegalAttorneySpEcoBlValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4LegalAttrLoading: true })
    } else {
      this.setState({ m4LegalAttrLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4LegalAttrLoading: false });
      }
    }
  };
  setM4LegalAttrEcoBlFrapValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m4LegalAttrFRAPLoading: true })
    } else {
      this.setState({ m4LegalAttrFRAPLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m4LegalAttrFRAPLoading: false });
      }
    }
  };
  setM5F12BusinessLeaderValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ m5F12BLLoading: true })
    } else {
      this.setState({ m5F12BLLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, m5F12BLLoading: false });
      }
    }
  };
  setSeniorDirEnterTravExpenValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ SenrEntrLoading: true })
    } else {
      this.setState({ SenrEntrLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, SenrEntrLoading: false });
      }
    }
  };
  setSeniorVpChiefFinanOffcValue = async (inputValue: any) => {
    this.setState({ approverNames: [] });
    console.log("input value", inputValue);

    const isData = this.state.approverNames.find(
      (p: any) => p.text === inputValue
    );

    if (inputValue) {
      this.setState({ SeniorVPCFOLoading: true })
    } else {
      this.setState({ SeniorVPCFOLoading: false })
    }
    if (inputValue.length > 3 && isData === undefined) {
      let response = await lptService.getApprover({
        searchText: inputValue,
      });
      console.log("response", response);

      if (response) {
        const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

        this.setState({ approverNames: approverValues, SeniorVPCFOLoading: false });
      }
    }
  };

  onAssociateVpEcoIbuSelect = (event: any) => {
    this.setState({ assocVpECOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          associateVpEcoIbuName: event.value,
          associateVpEcoIbuEmail: event.email,
        },
        associateVpEcoIbuVal: event,
      });
      this.setState({ associateVpEcoIbuVal: event });
    } else {
      this.setState({ associateVpEcoIbuVal: { text: "", value: "", email: "" } })
    }
  };
  onAssociateVpLegalIbuSelect = (event: any) => {
    this.setState({ assocVpIBULoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          associateVpLegalIbuName: event.value,
          associateVpLegalIbuEmail: event.email,
        },
        associateVpLegalIbuVal: event,
      });
      this.setState({ associateVpLegalIbuVal: event });
    } else {
      this.setState({ associateVpLegalIbuVal: { text: "", value: "", email: "" } })
    }
  };
  onChiefAccOfficerSelect = (event: any) => {
    this.setState({ chiefAccOffLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          chiefAccOfficerName: event.value,
          chiefAccOfficerEmail: event.email,
        },
        chiefAccOfficerVal: event,
      });
      this.setState({ chiefAccOfficerVal: event });
    } else {
      this.setState({ chiefAccOfficerVal: { text: "", value: "", email: "" } })
    }
  };
  onChiefProcOfficerSelect = (event: any) => {
    this.setState({ chiefProcOfcLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          chiefProcOfficerName: event.value,
          chiefProcOfficerEmail: event.email,
        },
        chiefProcOfficerVal: event,
      });
      this.setState({ chiefProcOfficerVal: event });
    } else {
      this.setState({ chiefProcOfficerVal: { text: "", value: "", email: "" } })
    }
  };
  onGmFunctionVpr8Select = (event: any) => {
    this.setState({ gmFuncVPLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          gmFunctionVpr8Name: event.value,
          gmFunctionVpr8Email: event.email,
        },
        gmFunctionVpr8Val: event,
      });
      this.setState({ gmFunctionVpr8Val: event });
    } else {
      this.setState({ gmFunctionVpr8Val: { text: "", value: "", email: "" } })
    }
  };
  onGmRegionalAffiliateVpSelect = (event: any) => {
    this.setState({ gmRegionalAffiliateVpVal: false, gmRegionalLoading:false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          gmRegionalAffiliateVpName: event.value,
          gmRegionalAffiliateVpEmail: event.email,
        },
        gmRegionalAffiliateVpVal: event,
      });
      this.setState({ gmRegionalAffiliateVpVal: event });
    } else {
      this.setState({ gmRegionalAffiliateVpVal: { text: "", value: "", email: "" } })
    }
  };
  onIbuHubGmSelect = (event: any) => {
    this.setState({ ibuHubGmLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          ibuHubGmName: event.value,
          ibuHubGmEmail: event.email,
        },
        ibuHubGmVal: event,
      });
      this.setState({ ibuHubGmVal: event });
    } else {
      this.setState({ ibuHubGmVal: { text: "", value: "", email: "" } })
    }
  };
  onLocalBusinessOwnerSelect = (event: any) => {
    this.setState({ localBusOwnerLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          localBusinessOwnerName: event.value,
          localBusinessOwnerEmail: event.email,
        },
        localBusinessOwnerVal: event,
      });
      this.setState({ localBusinessOwnerVal: event });
    } else {
      this.setState({ localBusinessOwnerVal: { text: "", value: "", email: "" } })
    }
  };
  onLocalCfoSelect = (event: any) => {
    this.setState({ localCFOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          localCfoName: event.value,
          localCfoEmail: event.email,
        },
        localCfoVal: event,
      });
      this.setState({ localCfoVal: event });
    } else {
      this.setState({ localCfoVal: { text: "", value: "", email: "" } })
    }
  };
  onLocalChiefFinanOwnerSelect = (event: any) => {
    this.setState({ localChiefFinOfcLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          localChiefFinanOwnerName: event.value,
          localChiefFinanOwnerEmail: event.email,
        },
        localChiefFinanOwnerVal: event,
      });
      this.setState({ localChiefFinanOwnerVal: event });
    } else {
      this.setState({ localChiefFinanOwnerVal: { text: "", value: "", email: "" } })
    }
  };
  onM4EcoBlSelect = (event: any) => {
    this.setState({ m4EcoBlLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          m4EcoBlName: event.value,
          m4EcoBlEmail: event.email,
        },
        m4EcoBlVal: event,
      });
      this.setState({ m4EcoBlVal: event });
    } else {
      this.setState({ m4EcoBlVal: { text: "", value: "", email: "" } })
    }
  };
  onM4LegalAttorneySpEcoBlSelect = (event: any) => {
    this.setState({ m4LegalAttrLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          m4LegalAttorneySpEcoBlName: event.value,
          m4LegalAttorneySpEcoBlEmail: event.email,
        },
        m4LegalAttorneySpEcoBlVal: event,
      });
      this.setState({ m4LegalAttorneySpEcoBlVal: event });
    } else {
      this.setState({ m4LegalAttorneySpEcoBlVal: { text: "", value: "", email: "" } })
    }
  };
  onM4LegalAttrEcoBlFrapSelect = (event: any) => {
    this.setState({ m4LegalAttrFRAPLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          m4LegalAttrEcoBlFrapName: event.value,
          m4LegalAttrEcoBlFrapEmail: event.email,
        },
        m4LegalAttrEcoBlFrapVal: event,
      });
      this.setState({ m4LegalAttrEcoBlFrapVal: event });
    } else {
      this.setState({ m4LegalAttrEcoBlFrapVal: { text: "", value: "", email: "" } })
    }
  };
  onM5F12BusinessLeaderSelect = (event: any) => {
    this.setState({ m5F12BLLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          m5F12BusinessLeaderName: event.value,
          m5F12BusinessLeaderEmail: event.email,
        },
        m5F12BusinessLeaderVal: event,
      });
      this.setState({ m5F12BusinessLeaderVal: event });
    } else {
      this.setState({ m5F12BusinessLeaderVal: { text: "", value: "", email: "" } })
    }
  };
  onSeniorDirEnterTravExpenSelect = (event: any) => {
    this.setState({ SenrEntrLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          seniorDirEnterTravExpenName: event.value,
          seniorDirEnterTravExpenEmail: event.email,
        },
        seniorDirEnterTravExpenVal: event,
      });
      this.setState({ seniorDirEnterTravExpenVal: event });
    } else {
      this.setState({ seniorDirEnterTravExpenVal: { text: "", value: "", email: "" } })
    }
  };
  onSeniorVpChiefFinanOffcSelect = (event: any) => {
    this.setState({ SeniorVPCFOLoading: false })
    console.log("select event", event);
    // console.log("email",event.email);
    if (event) {
      this.setState({
        exceptionForm: {
          ...this.state.exceptionForm,
          seniorVpChiefFinanOffcName: event.value,
          seniorVpChiefFinanOffcEmail: event.email,
        },
        seniorVpChiefFinanOffcVal: event,
      });
      this.setState({ seniorVpChiefFinanOffcVal: event });
    } else {
      this.setState({ seniorVpChiefFinanOffcVal: { text: "", value: "", email: "" } })
    }
  };

  lessRestrictiveSectionCheck = (arr: any) => {
    const lessResCount = arr.find((el: any) => {
      if (el.header_blackTextChangeType === "Less Restrictive than global requirement (exception required)" || el.bodyText_blackTextChangeType === "Less Restrictive than global requirement (exception required)") {
        return true;
      } else {
        return false
      }
    })
    if(lessResCount) {
      return true 
    } else {
      return false
    }
  }
  moreRestrictiveSectionCheck = (arr: any) => {
    const moreResCount = arr.find((el: any) => {
      if (el.header_blackTextChangeType === "More Restrictive than global requirement" || el.bodyText_blackTextChangeType === "More Restrictive than global requirement") {
        return true;
      } else {
        return false
      }
    })
    if(moreResCount) {
      return true 
    } else {
      return false
    }
  }
  prohibitedSectionCheck = (arr: any) => {
    const prohLocaCount = arr.find((el: any) => {
      if (el.header_blackTextChangeType === "Prohibited locally" || el.bodyText_blackTextChangeType === "Prohibited locally") {
        return true;
      } else {
        return false
      }
    })
    if(prohLocaCount) {
      return true 
    } else {
      return false
    }
  }
  otherRationaleSectionCheck = (arr: any) => {
    const otherResCount = arr.find((el: any) => {
      if (el.header_blackTextChangeType === "Other - provide rationale" || el.bodyText_blackTextChangeType === "Other - provide rationale") {
        return true;
      } else {
        return false
      }
    })
    if(otherResCount) {
      return true 
    } else {
      return false
    }
  }

  handleRichTextJsonToPara = (json: any) => {
    const textArray: [] = [];
    console.log('jsonn', json)
    function extractText(node: any) {
      console.log('nodee', node)
      if (node.text && !node.style?.includes('rule: EDITABLE') && !node.style?.includes('background-color: cyan')) {
        textArray.push(node.text as unknown as never);
      }

      if (node.children && Array.isArray(node.children)) {
        for (const child of node.children) {
          extractText(child);
        }
      }
    }

    if (json.root && json.root.children && Array.isArray(json.root.children)) {
      for (const child of json.root.children) {
        extractText(child);
      }
    }
    console.log('TextArray', textArray)
    return textArray.join('\n').trim()
  }




  handleSectionParser = (obj: any) => {
    obj.header = typeof obj.header === "string" ? JSON.parse(obj.header) : obj.header
    obj.bodyText = typeof obj.bodyText === "string" ? JSON.parse(obj.bodyText) : obj.bodyText
    return obj
  }



}

export default withRouter(ExceptionPage);