import { ExternalHyperlink, ParagraphChild } from "docx";
import DocxUniqueIdAttribute from "./DocxUniqueIdAttribute";

export default class DocxExternalLink extends ExternalHyperlink {
    constructor(options: { readonly children: readonly ParagraphChild[]; readonly link: string; }, uid: string | null) {
        super(options);

        if (uid) {
            this.root.push(new DocxUniqueIdAttribute({ uid }));
        }
    }
}