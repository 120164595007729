import { Component, ReactNode } from 'react';
import { Card, CardContent, IconButton, Collapse, Divider, Typography, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faCircleChevronDown, faCircleChevronUp } from '@fortawesome/free-solid-svg-icons';


interface Props {
    title: string
    children?: ReactNode;
    divider?: boolean;
    required?: boolean;
    subTitle?: ReactNode;
    titleStyle?: React.CSSProperties;
    subTitleStyle?:React.CSSProperties;
    initiallyOpen?:boolean;
}

interface State {
    expanded: boolean;
}

class CollapsibleCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            expanded: this.props.initiallyOpen || false,
        };
    }

    toggleExpand = () => {
        this.setState((prevState) => ({
            expanded: !prevState.expanded
        }));
    };

    render() {
        const { title, children, divider, required, subTitle, titleStyle,subTitleStyle } = this.props;
        const { expanded } = this.state;

        return (
            <Card>
                {divider ? <Divider sx={{ backgroundColor: '#D52B1E', height: 5 }} /> : <></>}
                <Box>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Box style={{ marginLeft: "1em", marginTop: "0.5em", ...titleStyle, fontFamily: 'Roboto', fontWeight: 750 }}>{title}</Box>
                        <Box style={{ marginRight: "1em", marginTop: "0.5em", color: '#D52B1E' }}>
                            <IconButton
                                aria-expanded={expanded}
                                aria-label="show more"
                                onClick={this.toggleExpand}
                            >

                                {expanded ? <FontAwesomeIcon icon={faCircleChevronUp} size='sm' color='#D52B1E' /> : <FontAwesomeIcon icon={faCircleChevronDown} size='sm' color='#D52B1E' />}
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
               
                <Typography style={{ ...subTitleStyle, fontFamily: 'Roboto' }}>{subTitle}</Typography>
                {required ? <Box style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1rem' }}><Typography variant='caption' color='error' style={{  fontFamily: 'Roboto' }}><FontAwesomeIcon icon={faAsterisk} size='xs' color='#D52B1E' />Fields Required</Typography></Box> : <></>}

                <Collapse in={expanded}>
                    <CardContent style={{ fontFamily: 'Roboto' }}>{children}</CardContent>
                </Collapse>
            </Card>
        );
    }
}

export default CollapsibleCard;